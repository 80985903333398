﻿import { GraphEditorInputMode } from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { EdgeCreationNodeSelectorInputMode } from '@/core/services/graph/input-modes/EdgeCreationNodeSelectorInputMode';
import { PaletteItemCancelCallback } from './IPaletteBehaviour';
import Vue from 'vue';
import {
  GET_SELECTED_ITEM,
  PALETTE_NAMESPACE,
  SET_SELECTED_ITEM
} from '@/core/services/store/palette.module';

import { CSSProperties } from 'vue/types/jsx';

export default class ThemeElementEdgeBehaviour extends PaletteItemBehaviourBase {
  private static _instance: ThemeElementEdgeBehaviour = null;

  get style(): CSSProperties {
    return {
      cursor: 'default'
    };
  }

  public static get INSTANCE(): ThemeElementEdgeBehaviour {
    return (
      ThemeElementEdgeBehaviour._instance ??
      (ThemeElementEdgeBehaviour._instance = new ThemeElementEdgeBehaviour())
    );
  }

  private getInputMode(
    graphService: IGraphService
  ): EdgeCreationNodeSelectorInputMode {
    return (graphService.graphComponent.inputMode as GraphEditorInputMode)
      .getSortedModes()
      .find(
        (d) => d instanceof EdgeCreationNodeSelectorInputMode
      ) as EdgeCreationNodeSelectorInputMode;
  }

  isSelected(item: IDocumentPaletteItem, graphService: IGraphService): boolean {
    const selectedItem: IDocumentPaletteItem =
      Vue.$globalStore.getters[`${PALETTE_NAMESPACE}/${GET_SELECTED_ITEM}`];

    return selectedItem === item;
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): PaletteItemCancelCallback {
    const inputMode = this.getInputMode(graphService);
    const isSelected = this.isSelected(item, graphService);
    if (inputMode.isActive && isSelected) {
      inputMode.stopNodeSelection();
      Vue.$globalStore.commit(
        `${PALETTE_NAMESPACE}/${SET_SELECTED_ITEM}`,
        null
      );
    } else {
      inputMode.beginNodeSelection(item);
      Vue.$globalStore.commit(
        `${PALETTE_NAMESPACE}/${SET_SELECTED_ITEM}`,
        item
      );
    }
    graphService.graphComponent.div.focus();

    return (item: IDocumentPaletteItem, graphService: IGraphService): void => {
      if (inputMode.item == item) {
        return;
      }
      inputMode.stopNodeSelection();
      Vue.$globalStore.commit(
        `${PALETTE_NAMESPACE}/${SET_SELECTED_ITEM}`,
        null
      );
    };
  }
}
