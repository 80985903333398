import type Suggestion from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import type { CommentThread } from '@ckeditor/ckeditor5-comments';
import type { Marker } from '@ckeditor/ckeditor5-engine';
import CollaborationDataType from './CollaborationDataType';

export default class MarkerData {
  public readonly marker?: Marker;
  public readonly data?: Suggestion | CommentThread;

  public get dataType(): CollaborationDataType {
    if ('type' in this.data) {
      return CollaborationDataType.Suggestion;
    }
    return CollaborationDataType.CommentThread;
  }

  constructor(marker?: Marker, data?: Suggestion | CommentThread) {
    this.marker = marker;
    this.data = data;
  }
}
