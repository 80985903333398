import { destroyObject } from '@/core/utils/common.utils';
import BackgroundDomService from '../BackgroundDomService';
import { Editor } from '@ckeditor/ckeditor5-core';
import Mutex from '@/core/common/Mutex';
import type TrackChangesEditing from '@ckeditor/ckeditor5-track-changes/src/trackchangesediting';
import { CommentsRepository } from '@ckeditor/ckeditor5-comments';
import type { SuggestionJSON } from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import type { CommentThreadDataJSON } from '@ckeditor/ckeditor5-comments/src/comments/commentsrepository';
import { CollaborationDataDto } from '@/api/models';
import Integration from './collaboration/Integration';
import CKEditorUtils from '@/core/utils/CKEditorUtils';
import ExportConfig from '@/core/config/ExportConfig';

const ckEditorImport = (): Promise<any> =>
  import('@jigsaw/ckeditor5-custom-build/build/ckeditor.js');

export default class BackgroundCkEditorService {
  public get editor(): Editor {
    return this.ckEditor;
  }

  public get content(): string {
    return this.editor?.getData();
  }

  public set content(value: string) {
    this.editor?.setData(value);
  }

  public get contentWithHighlights(): string {
    return this.editor?.getData({ showSuggestionHighlights: true });
  }

  private ckEditor: Editor;
  private ckEditorContainer: HTMLElement;
  private ckEditorConfig: any;
  private initCkEditorMutex = new Mutex();
  private destroyCkEditorMutex = new Mutex();
  private commentsRepository: CommentsRepository;
  private trackChanges: TrackChangesEditing;

  constructor(config: any) {
    this.ckEditorConfig = config;
  }

  public async init(): Promise<void> {
    await this.initCkEditor();
  }

  public reset(): void {
    CKEditorUtils.resetEditor(this.editor);
    this.content = '';
  }

  public async destroy(): Promise<void> {
    await this.destroyCkEditor();
  }

  public setCollaborationData(data: CollaborationDataDto): void {
    if (!data?.suggestions || !data?.comments) {
      return;
    }
    const collaborationIntegration = this.ckEditor.plugins.get(
      Integration.pluginName
    ) as unknown as Integration;
    collaborationIntegration.setCommentData(data.comments);
    collaborationIntegration.setSuggestionData(data.suggestions);
  }

  public getSuggestionData(): SuggestionJSON[] {
    if (!this.ckEditor) {
      return;
    }

    return this.trackChanges.getSuggestions({
      skipNotAttached: true,
      toJSON: true
    });
  }

  public getCommentData(): CommentThreadDataJSON[] {
    if (!this.ckEditor) {
      return;
    }

    return this.commentsRepository.getCommentThreads({
      skipNotAttached: true,
      skipEmpty: true,
      toJSON: true
    });
  }

  public getCollaborationData(): CollaborationDataDto {
    if (!this.editor) {
      throw 'Editor not ready';
    }

    const suggestionData = this.getSuggestionData();
    const commentData = this.getCommentData();

    if (!suggestionData.length && !commentData.length) {
      return null;
    }

    return {
      suggestions: JSON.stringify(suggestionData),
      suggestionsCount: suggestionData?.length ?? 0,
      comments: JSON.stringify(commentData),
      commentsCount: commentData?.length ?? 0
    };
  }

  private async initCkEditor(): Promise<void> {
    const unlock = await this.initCkEditorMutex.lock();
    try {
      if (this.ckEditor) {
        return;
      }
      this.ckEditorContainer = this.initCkEditorContainer();
      const ckEditorModule = await ckEditorImport();
      this.ckEditor = await ckEditorModule.default.create(
        this.ckEditorContainer,
        this.ckEditorConfig
      );
      this.trackChanges = this.ckEditor.plugins.get(
        'TrackChanges'
      ) as unknown as TrackChangesEditing;
      this.commentsRepository = this.ckEditor.plugins.get('CommentsRepository');
    } catch (ex) {
      console.error(ex);
    } finally {
      unlock();
    }
  }

  private async destroyCkEditor(): Promise<void> {
    const unlock = await this.destroyCkEditorMutex.lock();
    try {
      if (!this.ckEditor) {
        return;
      }
      const ckEditorRef = this.ckEditor;
      this.ckEditor = null;
      await ckEditorRef.destroy();
      // Give time to run all ckEditor events before destroy object
      setTimeout(() => {
        destroyObject(ckEditorRef);
      });
      this.ckEditorContainer.remove();
    } catch (ex) {
      console.error(ex);
    } finally {
      unlock();
    }
  }

  private initCkEditorContainer(): HTMLElement {
    const container = BackgroundDomService.createElement('div');
    container.style.position = 'absolute';
    container.classList.add(ExportConfig.pageContentClass);
    return container;
  }

  public fireFontFamilyCorrection(): void {
    this.editor.fire('fontFamilyCorrectionNeeded');
  }
}
