import { StorageType } from './StorageType';

export function getItem(key: any, storageType: StorageType) {
  const jsonString = window[storageType].getItem(key);
  if (!jsonString) {
    return null;
  }
  const obj = JSON.parse(jsonString);
  if (!obj.type) {
    return obj.value;
  }

  switch (obj.type) {
    case 'string':
      return obj.value;
    case 'number':
      return parseInt(obj.value);
    case 'boolean':
      return obj.value;
    case 'date':
      return new Date(obj.value);
    case 'array':
      return obj.value;
  }

  return obj.value;
}

export function setItem(key: any, value: any, storageType: StorageType) {
  let type = typeof value as any;
  if (value === null) {
    type = null;
  } else {
    if (type === 'object') {
      if (value.constructor.toString().indexOf('Date') >= 1) {
        type = 'date';
      } else if (Array.isArray(value)) {
        type = 'array';
      }
    }
  }

  const dataItem = {
    type: type,
    value: value
  };
  window[storageType].setItem(key, JSON.stringify(dataItem));
}
