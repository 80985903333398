import { PageElementPosition } from '@/api/models';
import { setElementHtmlByQuerySelector } from '@/core/utils/html.utils';
import moment from 'moment';
import IDateLayoutItemOptions from './IDateLayoutItemOptions';
import { LayoutItemType } from './LayoutItemType';
import WidgetLayoutItem from './WidgetLayoutItem';

export default class DateLayoutItem extends WidgetLayoutItem {
  public static readonly padding: number = 2;
  public date: Date;

  constructor(options?: IDateLayoutItemOptions) {
    super(LayoutItemType.Date);
    this.hidden = options?.hidden ?? false;
    this.position = options?.position ?? PageElementPosition.TopRight;
    this.html = options?.html ?? 'YYYY-MM-DD';
    this.presetId = options?.presetId ?? 1;
    this.format = options?.format ?? 'YYYY-MM-DD';
    this.date = options?.date ? new Date(options?.date) : new Date();
  }

  public refreshHtml(): void {
    const value = this.getFormattedDate();
    this.html = setElementHtmlByQuerySelector(
      this.html,
      "[name='date']",
      value
    );
  }

  private getFormattedDate(): string {
    return moment(this.date ?? new Date())
      .format(this.format)
      .toString();
  }

  public override serialize(): Object {
    const obj = super.serialize();
    obj['date'] = this.date;
    return obj;
  }
}
