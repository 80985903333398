import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import InputModeContext from '../InputModes/InputModeContext';
import LayoutEditorCommandHandler from '../LayoutEditorCommandHandler';
import LayoutEditorService from '../LayoutEditorService';
import LayoutItem from './LayoutItem';
import { ContextActivated, ContextDeactivated } from '../Events/EventManager';

class LayoutEditorServiceManager {
  private layoutEditorServices: LayoutEditorService[] = [];

  private activateContextByContentAreaListener =
    this.activateContextByContentArea.bind(this);
  private deactivateContextListener = this.activateDefaultContext.bind(this);

  public add(layoutEditorService: LayoutEditorService): void {
    this.layoutEditorServices.push(layoutEditorService);
    layoutEditorService.context.eventManager.addEventListener(
      ContextActivated,
      this.activateContextByContentAreaListener
    );
    layoutEditorService.context.eventManager.addEventListener(
      ContextDeactivated,
      this.deactivateContextListener
    );
  }

  public remove(layoutEditorService: LayoutEditorService): void {
    layoutEditorService.context.eventManager.removeEventListener(
      ContextActivated,
      this.activateContextByContentAreaListener
    );
    layoutEditorService.context.eventManager.removeEventListener(
      ContextDeactivated,
      this.deactivateContextListener
    );
    this.layoutEditorServices = this.layoutEditorServices.filter(
      (s) => s !== layoutEditorService
    );
  }

  public reset(): void {
    this.layoutEditorServices = [];
  }

  /**
   * ResetLayoutEditorServices can be called in cases when need to correctly stop input modes
   * and LayoutEditor component or it's service are not reachable in the code.
   * For example when textBox is editing and user selects another page - need to stop editing correctly, before selecting new page
   */
  public resetLayoutEditorServices(): void {
    this.layoutEditorServices.forEach((s) => s.reset());
  }

  public getActiveService(): LayoutEditorService {
    return this.layoutEditorServices.find(
      (s) => s.context.focusTracker.isFocused
    );
  }

  public getDefaultService(): LayoutEditorService {
    return (
      this.getServiceByContentArea(DocumentContentArea.Background) ||
      this.getServiceByContentArea(DocumentContentArea.BodyContent) ||
      this.getServiceByContentArea(DocumentContentArea.BodyLayout)
    );
  }

  public getActiveServiceOrDefault(): LayoutEditorService {
    return this.getActiveService() ?? this.getDefaultService();
  }

  public getActiveContext(): InputModeContext | null {
    return this.getActiveService()?.context;
  }

  public getDefaultContext(): InputModeContext {
    return this.getDefaultService()?.context;
  }

  public getActiveContextOrDefault(): InputModeContext {
    return this.getActiveServiceOrDefault()?.context;
  }

  public getContextByItem(item: LayoutItem): InputModeContext | null {
    return this.layoutEditorServices.find((s) => s.context.items.includes(item))
      ?.context;
  }

  public getServiceByContentArea(
    contentArea: DocumentContentArea
  ): LayoutEditorService {
    return this.layoutEditorServices.find(
      (s) => s.context.contentArea === contentArea
    );
  }

  public getContextByContentArea(
    contentArea: DocumentContentArea
  ): InputModeContext {
    return this.getServiceByContentArea(contentArea)?.context;
  }

  public getServiceByCommandHandler(
    commandHandler: LayoutEditorCommandHandler
  ): LayoutEditorService {
    return this.layoutEditorServices.find(
      (s) => s.context.focusTracker.commandHandler === commandHandler
    );
  }

  public deactivateAllContexts(): void {
    for (const service of this.layoutEditorServices) {
      service.context.deactivate();
    }
  }

  public activateContextByContentArea(args): void {
    this.deactivateAllContexts();
    if (!args.contentArea) {
      this.activateDefaultContext();
      return;
    }
    const context = this.getContextByContentArea(args.contentArea);
    if (context) {
      context.activate();
      this.layoutEditorServices.forEach((s) => {
        if (s.context.contentArea !== args.contentArea) {
          s.context.shaded = true;
        }
      });
    }
  }

  public activateDefaultContext(): void {
    this.deactivateAllContexts();
    const context = this.getDefaultContext();

    if (context) {
      context.activate();
      // since activating the default context can be done by clicking outside an active context, we should focus it here
      context.focusTracker.focus();
    }
    this.layoutEditorServices.forEach((s) => {
      s.context.shaded = false;
    });
  }
}

const instance = new LayoutEditorServiceManager();
export default instance;
