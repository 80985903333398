import {
  BaseClass,
  IHandle,
  ILabel,
  IInputModeContext,
  Point,
  ICanvasObject,
  HandleTypes,
  Cursor,
  IPoint,
  ClickEventArgs,
  IOrientedRectangle,
  ILabelModelParameter
} from 'yfiles';
import JigsawEdgeLabelModelParameter from './JigsawEdgeLabelModelParameter';
import JigsawEdgeLabelModel from './JigsawEdgeLabelModel';
import { toRadians } from '@/core/utils/math.utils';
const HALF_PI_ANGLE_OFFSET: number = 1.5707963268;

export default class JigsawEdgeLabelRotateHandle
  extends BaseClass(IHandle)
  implements IHandle
{
  handleClick(evt: ClickEventArgs): void {} // doesn't do anything but needs implementing
  private readonly _label: ILabel;
  public _inputModeContext: IInputModeContext;
  public _dummyLocation: Point = null!;

  private _angle: number = 0;
  private _rotationOrigin: Point = null!;
  private _rotationBoxVisualCanvasObject: ICanvasObject | null = null;
  private _originalParameter: ILabelModelParameter = null;

  constructor(label: ILabel, context: IInputModeContext) {
    super();
    this._label = label;
    this._inputModeContext = context;
    this._angle = (
      label.layoutParameter as JigsawEdgeLabelModelParameter
    ).angle;
  }

  get type(): HandleTypes {
    return HandleTypes.ROTATE | HandleTypes.VARIANT2;
  }

  get cursor(): Cursor {
    return new Cursor('media/rotate.cur', Cursor.CROSSHAIR);
  }

  get location(): IPoint {
    return this.getLocation();
  }

  getLocation(): Point {
    const orientedRectangle = this._label.layout;
    const anchor = orientedRectangle.anchorLocation;
    const size = orientedRectangle.toSize();
    const up = new Point(orientedRectangle.upX, orientedRectangle.upY);
    // calculate the location of the handle from the anchor, the size and the orientation
    const offset = 20;
    return anchor
      .add(up.multiply(size.height + offset))
      .add(new Point(-up.y, up.x).multiply(size.width * 0.5));
  }

  initializeDrag(context: IInputModeContext): void {
    this._inputModeContext = context;
    const labelLayout = this._label.layout;
    this._originalParameter = this._label.layoutParameter.clone();
    this._rotationOrigin = labelLayout.orientedRectangleCenter;
    this._dummyLocation = this._rotationOrigin;
  }

  /**
   * Invoked when an element has been dragged and its position should be updated.
   * @param context The context to retrieve information
   * @param originalLocation The value of the location property at the time of initializeDrag
   * @param newLocation The new location in the world coordinate system
   */
  handleMove(
    context: IInputModeContext,
    originalLocation: Point,
    newLocation: Point
  ): void {
    const upVector = newLocation.subtract(this._rotationOrigin).normalized;
    this._angle = -Math.atan2(upVector.y, upVector.x) - HALF_PI_ANGLE_OFFSET;

    this.setLabelParameter(context);
  }

  /**
   * Invoked when dragging has canceled.
   * @param context The context to retrieve information
   * @param originalLocation The value of the location property at the time of initializeDrag
   */
  cancelDrag(context: IInputModeContext, originalLocation: Point): void {
    // use the normal label bounds if the drag gesture is over

    context.graph.setLabelLayoutParameter(this._label, this._originalParameter);
    // remove the visual size indicator
    this._rotationBoxVisualCanvasObject?.remove();
    this._rotationBoxVisualCanvasObject = null;
  }

  /**
   * Invoked when dragging has finished.
   * @param context The context to retrieve information
   * @param originalLocation The value of the location property at the time of initializeDrag
   * @param newLocation The new location in the world coordinate system
   */
  dragFinished(
    context: IInputModeContext,
    originalLocation: Point,
    newLocation: Point
  ): void {
    this.setLabelParameter(context);
    this._rotationBoxVisualCanvasObject?.remove();
    this._rotationBoxVisualCanvasObject = null;
  }

  private setLabelParameter(context: IInputModeContext): void {
    const model = new JigsawEdgeLabelModel();
    let positionedLabelParameter = model.findBestParameter(
      this._label,
      model,
      this._label.layout
    ) as JigsawEdgeLabelModelParameter;

    positionedLabelParameter = new JigsawEdgeLabelModelParameter(
      this._label.layoutParameter.model as JigsawEdgeLabelModel,
      {
        angle: this.snapToRightAngle(this._angle),
        distance: positionedLabelParameter.distance,
        left: positionedLabelParameter.left,
        ratio: positionedLabelParameter.ratio,
        segmentIndex: positionedLabelParameter.segmentIndex
      }
    );
    context.graph.setLabelLayoutParameter(
      this._label,
      positionedLabelParameter
    );
  }

  /**
   * Returns the current label layout.
   */
  getCurrentLabelLayout(): IOrientedRectangle {
    return this._label.layout;
  }

  private snapToRightAngle(angle: number): number {
    const degreePoints = [-135, -90, -45, 0, 45, 90, 135, 180];
    const snapPoints = degreePoints.map(toRadians);
    const normalizeAngle = (angle: number): number => {
      const twoPi = 2 * Math.PI;
      return ((angle % twoPi) + twoPi) % twoPi;
    };

    let closestPoint = snapPoints[0];
    let smallestDifference = Math.abs(normalizeAngle(angle - closestPoint));
    snapPoints.forEach((point) => {
      const difference = Math.abs(normalizeAngle(angle - point));
      if (difference < smallestDifference) {
        closestPoint = point;
        smallestDifference = difference;
      }
    });
    return closestPoint;
  }
}
