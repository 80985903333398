import {
  DocumentDto,
  PageDesignDto,
  DocumentPageDto,
  DocumentPageType,
  DocumentPageContentType,
  DocumentPageLayoutType,
  DiagramPosition,
  DeloitteSection,
  DeloitteContent,
  DeloittePageType
} from '@/api/models';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import TextBoxLayoutItem from '@/components/LayoutEditor/Items/TextBoxLayoutItem';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import DocumentCustomPageType from '@/core/services/document/DocumentCustomPageType';
import DocumentEditor from '@/core/services/document/DocumentEditor';
import ExportUtils from '@/core/services/export/ExportUtils';
import { stripHtml, mergeTextIntoParent } from '@/core/utils/html.utils';
import DeloittePageConfigurator from './DeloittePageConfigurator';
import DeloittePlugin from './DeloittePlugin';
import { DeloitteMergeTagsRegex } from './DeloitteMergeTagsRegex';
import ExportConfig from '@/core/config/ExportConfig';
import { generateUuid } from '@/core/utils/common.utils';
import { ContentTableItem } from '@/components/LayoutEditor/Plugins/ContentTable/ContentTableItemType';
import ContentTableLayoutItem from '@/components/LayoutEditor/Plugins/ContentTable/ContentTableLayoutItem';
import ContentTableUtils from '@/components/LayoutEditor/Plugins/ContentTable/ContentTableUtils';
import LayoutItemFactory from '@/components/LayoutEditor/Items/LayoutItemFactory';
import JRect from '@/core/common/JRect';
import IContentTableLayoutItemOptions from '@/components/LayoutEditor/Plugins/ContentTable/IContentTableLayoutItemOptions';
import JSize from '@/core/common/JSize';

class DeloittePageService {
  public async mergePages(
    sections: DeloitteSection[],
    document: DocumentDto,
    layoutPageDesigns: PageDesignDto[],
    externalReportName: string
  ): Promise<DocumentPageDto[]> {
    const pagesMerged: DocumentPageDto[] = [];
    const configurator = new DeloittePageConfigurator(layoutPageDesigns);
    for (const section of sections) {
      const options = configurator.getPageConfiguration(section.pageType);
      if (options === null) {
        console.error(
          `Page configuration not found for page type: ${section.pageType}`
        );
        continue;
      }
      const page = document.pages.find((page) => page.name === section.name);
      await this.mergePage({
        document: document,
        page: page,
        pageDesign: options.pageDesign,
        pageContent: section.content,
        header: section.header,
        subHeader: section.subHeader,
        externalReportName: externalReportName
      });
      pagesMerged.push(page);
    }
    return pagesMerged;
  }

  public async createPages(
    sections: DeloitteSection[],
    document: DocumentDto,
    layoutPageDesigns: PageDesignDto[],
    externalReportName: string
  ): Promise<DocumentPageDto[]> {
    const pagesCreated: DocumentPageDto[] = [];
    const configurator = new DeloittePageConfigurator(layoutPageDesigns);
    for (const section of sections) {
      const options = configurator.getPageConfiguration(section.pageType);
      if (options === null) {
        console.error(
          `Page configuration not found for page type: ${section.pageType}`
        );
        continue;
      }
      const newPage = await this.addNewPage({
        document: document,
        pageDesign: options.pageDesign,
        pageType: options.pageType,
        contentType: options.contentType,
        contentPageLayoutType: options.contentPageLayoutType,
        contentColumns: options.contentColumns,
        diagramPosition: options.diagramPosition,
        pageName: section.name,
        pageContent: section.content,
        header: section.header,
        subHeader: section.subHeader,
        externalReportName: externalReportName
      });
      pagesCreated.push(newPage);
    }
    return pagesCreated;
  }

  // Reorder pages based on the section order
  public reorderPages(
    sections: DeloitteSection[],
    document: DocumentDto
  ): void {
    let extraPageOrder = sections.length;
    for (const page of document.pages) {
      const section = sections.find((s) => s.name === page.name);
      if (section) {
        page.order = sections.indexOf(section);
      } else {
        page.order = ++extraPageOrder;
      }
    }
    document.pages.sort((a, b) => a.order - b.order);
  }

  public updateContentPages(
    sections: DeloitteSection[],
    document: DocumentDto,
    layoutPageDesigns: PageDesignDto[]
  ): void {
    const contentTablePages = document.pages.filter(
      (p) => p.layoutType === DocumentPageLayoutType.ContentTable
    );
    const chaptersSections = sections.filter(
      (s) => s.pageType === DeloittePageType.CHAPTER && s.title
    );

    const maxPageOrder = document.pages.reduce(
      (max, page) => (page.order > max ? page.order : max),
      0
    );
    const contentPageItems: { [name: string]: ContentTableItem } = {};
    chaptersSections.forEach((section) => {
      const sectionPageOrder = contentTablePages.find(
        (page) => page.name === section.name
      )?.order;
      // The page number should be the next page after the chapter page
      const pageNumber =
        sectionPageOrder === maxPageOrder
          ? sectionPageOrder + 1
          : sectionPageOrder + 2;
      contentPageItems[section.name] = {
        id: 'chapter',
        title: stripHtml(section.title),
        pageNumber: pageNumber,
        edited: false,
        originalTitle: stripHtml(section.title),
        hidden: false,
        deleted: false,
        fontStyle: structuredClone(ExportConfig.defaultContentFontStyle),
        indentLevel: 0,
        showPrefix: true,
        showPageNumbers: true,
        prefix: '',
        type: 'chapter',
        column: 1
      };
    });

    const pageDesign = new DeloittePageConfigurator(
      layoutPageDesigns
    ).getPageConfiguration(DeloittePageType.CHAPTER).pageDesign;
    const width = this.maxPageWidth(pageDesign);

    for (const contentPage of contentTablePages) {
      const contentTableLayoutItem =
        LayoutItemFactory.getItem<ContentTableLayoutItem>(
          LayoutItemType.ContentTable,
          new JRect(
            ContentTableLayoutItem.defaultX,
            ContentTableLayoutItem.defaultY,
            width - ContentTableLayoutItem.defaultPadding * 2,
            0
          ),
          {
            size: new JSize(width, 0),
            showPageNumbers: true,
            showPrefix: false,
            deletable: false,
            autoUpdate: true,
            prefixType: 'none',
            spacing: 2,
            itemTypeFilter: 'chapter',
            chapterTitle: contentPageItems[contentPage.name]?.title,
            items: Object.values(contentPageItems).map((item) => ({ ...item }))
          } as IContentTableLayoutItemOptions
        );
      contentTableLayoutItem.html = ContentTableUtils.generateContentTableHtml(
        contentTableLayoutItem
      );
      contentPage.bodyLayout = LayoutSerializer.serializeToJson([
        contentTableLayoutItem
      ]);
    }
  }

  async deleteContentPages(
    sections: DeloitteSection[],
    document: DocumentDto
  ): Promise<void> {
    const chaptersName = new Set(
      sections
        .filter(
          (section) =>
            section.pageType === DeloittePageType.CHAPTER ||
            section.pageType === DeloittePageType.CONTENTS
        )
        .map((section) => section.name)
    );
    for (const page of document.pages) {
      if (
        page.layoutType === DocumentPageLayoutType.ContentTable &&
        !chaptersName.has(page.name)
      ) {
        await DocumentEditor.deletePage(document, page);
      }
    }
  }

  //TODO: Create utils methods to avoid code duplication on others addPages methods (DocumentPageList.mixin and createDocument, and first pages)
  private async addNewPage(options: {
    document: DocumentDto;
    pageDesign: PageDesignDto;
    pageType: DocumentPageType;
    contentType: DocumentPageContentType;
    contentPageLayoutType?: DocumentPageLayoutType;
    contentColumns?: number;
    diagramPosition?: DiagramPosition;
    layoutId?: number;
    customPageType?: DocumentCustomPageType;
    pageName?: string;
    pageContent?: DeloitteContent;
    header?: string;
    subHeader?: string;
    externalReportName?: string;
  }): Promise<DocumentPageDto> {
    const {
      pageDesign,
      pageType,
      contentType,
      contentPageLayoutType = DocumentPageLayoutType.None,
      contentColumns = 0,
      diagramPosition = DiagramPosition.Right,
      layoutId,
      customPageType,
      pageName,
      pageContent,
      header,
      subHeader,
      externalReportName
    } = options;
    let content = null;
    if (
      (pageType == DocumentPageType.Split ||
        pageType == DocumentPageType.Content) &&
      contentType == DocumentPageContentType.Html
    ) {
      content =
        options.pageContent?.body ?? ExportUtils.getInitialPageContent();
    }

    const showDivider =
      (options.pageType == DocumentPageType.Split &&
        pageDesign?.splitDividerVisibility) ||
      (options.pageType == DocumentPageType.Content &&
        options.contentType == DocumentPageContentType.Html &&
        options.contentColumns == 2 &&
        pageDesign?.htmlContentDividerVisibility);

    const pageTitle =
      pageDesign?.pageTitle ?? LayoutUtils.getDefaultPageTitle();

    const notLayoutPageType =
      options.contentType === DocumentPageContentType.Html ||
      options.contentType === DocumentPageContentType.None ||
      options.contentType === DocumentPageContentType.MasterLegend;
    const showTitle =
      notLayoutPageType &&
      options.contentType !== DocumentPageContentType.MasterLegend &&
      pageTitle.show;
    const titleHeight = notLayoutPageType ? pageTitle.height : 0;
    let titleLayout = notLayoutPageType ? pageTitle.titleLayout : null;

    const showDate = !!LayoutUtils.getContentAreaByItemType(
      pageDesign,
      LayoutItemType.Date,
      true
    );
    const showPageNumber = !!LayoutUtils.getContentAreaByItemType(
      pageDesign,
      LayoutItemType.PageNumber,
      true
    );

    const backgroundLayout = pageDesign?.backgroundLayout;

    const width = this.maxPageWidth(pageDesign);
    const originalDocumentHeaderLayout = options.document.headerLayout;
    options.document.headerLayout = this.replaceHeaderSubHeaderLayoutItems(
      options.document.headerLayout,
      header,
      subHeader,
      DeloitteMergeTagsRegex.HEADER,
      DeloitteMergeTagsRegex.SUB_HEADER,
      width
    );

    titleLayout = this.replaceHeaderSubHeaderLayoutItems(
      titleLayout,
      header,
      subHeader,
      DeloitteMergeTagsRegex.HEADER,
      DeloitteMergeTagsRegex.SUB_HEADER,
      width
    );

    let bodyLayout = this.replaceTagBodyLayoutItems(
      pageDesign?.bodyLayout,
      pageContent,
      width
    );

    if (options.document.footerLayout) {
      options.document.footerLayout = options.document.footerLayout.replace(
        DeloitteMergeTagsRegex.PROJECT_TITLE,
        externalReportName
      );
    }

    const newPageAdded = await DocumentEditor.addNewPage({
      document: options.document,
      pageType: pageType,
      contentType: contentType,
      layoutType: contentPageLayoutType,
      contentColumns: contentColumns,
      content: content,
      diagramPosition: diagramPosition,
      layoutId,
      customPageType,
      showDate: showDate,
      showPageNumber: showPageNumber,
      showDivider: showDivider,
      showTitle: showTitle,
      titleHeight: titleHeight,
      maxTitleHeight: 0,
      titleLayout: titleLayout,
      bodyLayout: bodyLayout,
      backgroundLayout,
      isPristine: true,
      pageName: pageName,
      pageIndex: options.document?.pages.length ?? 0
    });

    options.document.headerLayout = originalDocumentHeaderLayout;

    return newPageAdded;
  }

  private replaceHeaderSubHeaderLayoutItems(
    headerLayout: string,
    header: string,
    subHeader: string,
    headerExpression: RegExp,
    subHeaderExpression: RegExp,
    width: number
  ): string {
    if (!headerLayout) {
      return headerLayout;
    }

    const headerLayoutItems =
      LayoutSerializer.deserializeFromJson(headerLayout);

    if (header) {
      this.processLayoutItems(
        headerLayoutItems,
        headerExpression,
        (item): void => {
          item.html = item.html.replace(headerExpression, stripHtml(header));
          item.layout.width = width;
        }
      );
    }

    if (subHeader !== undefined) {
      this.processLayoutItems(
        headerLayoutItems,
        subHeaderExpression,
        (item): void => {
          item.html = item.html.replace(
            subHeaderExpression,
            stripHtml(subHeader)
          );
          item.defaultText = item.html;
          item.layout.width = width;
        }
      );
    }

    return LayoutSerializer.serializeToJson(headerLayoutItems);
  }

  private replaceTagBodyLayoutItems(
    bodyLayout: string,
    pageContent: DeloitteContent,
    width: number
  ): string {
    if (!bodyLayout) {
      return bodyLayout;
    }

    const regexTag = new RegExp(`%(.*?)%`, 'i');
    const bodyLayoutItems = LayoutSerializer.deserializeFromJson(bodyLayout);

    this.processLayoutItems(bodyLayoutItems, regexTag, (item): void => {
      const tagName = item.html.match(regexTag);
      if (tagName) {
        const tag = pageContent?.tags.find(
          (tag) => stripHtml(tagName[1]) === tag.name
        );
        if (tag) {
          item.html = item.html.replace(/%/g, '');
          if (tag.name !== tagName[1]) {
            //if tag is formatted use the format of first text element
            item.html = mergeTextIntoParent(item.html);
          }
          item.html = item.html.replace(tag.name, tag.value);
          item.layout.width = width;
        }
      }
    });

    return LayoutSerializer.serializeToJson(bodyLayoutItems);
  }

  private processLayoutItems(
    layoutItems: LayoutItem[],
    tagPattern: RegExp,
    processItem: any
  ): void {
    const textBoxLayoutItemsMatch = layoutItems.filter(
      (item) =>
        item instanceof TextBoxLayoutItem &&
        item.html &&
        tagPattern.test(item.html)
    );
    textBoxLayoutItemsMatch.forEach((item) => {
      processItem(item);
    });
  }

  private maxPageWidth(pageDesign: PageDesignDto): number {
    const leftMargin =
      pageDesign.pageStyle.fullMargins != null
        ? pageDesign.pageStyle.fullMargins.left
        : 0;
    const rightMargin =
      pageDesign.pageStyle.fullMargins != null
        ? pageDesign.pageStyle.fullMargins.right
        : 0;
    const width = pageDesign.pageStyle.width - (leftMargin + rightMargin);
    return width;
  }

  private async mergePage(options: {
    document: DocumentDto;
    page: DocumentPageDto;
    pageDesign: PageDesignDto;
    pageContent?: DeloitteContent;
    header?: string;
    subHeader?: string;
    externalReportName?: string;
  }): Promise<void> {
    const {
      document,
      page,
      pageDesign,
      pageContent,
      header,
      subHeader,
      externalReportName
    } = options;
    if (page.layoutType === DocumentPageLayoutType.ContentTable) {
      return;
    }

    if (page.content && pageContent) {
      page.content = pageContent.body;
    }

    const width = this.maxPageWidth(pageDesign);
    if (page.headerLayout && document.headerLayout) {
      page.headerLayout = this.replaceHeaderSubHeaderLayoutItems(
        document.headerLayout,
        header,
        subHeader,
        DeloitteMergeTagsRegex.HEADER,
        DeloitteMergeTagsRegex.SUB_HEADER,
        width
      );
    }

    if (page.titleLayout && pageDesign?.pageTitle?.titleLayout) {
      page.titleLayout = this.replaceHeaderSubHeaderLayoutItems(
        pageDesign.pageTitle.titleLayout,
        header,
        subHeader,
        DeloitteMergeTagsRegex.HEADER,
        DeloitteMergeTagsRegex.SUB_HEADER,
        width
      );
    }

    if (pageContent && page?.bodyLayout) {
      page.bodyLayout = this.replaceTagBodyLayoutItems(
        pageDesign.bodyLayout,
        pageContent,
        width
      );
    }

    if (page.footerLayout && externalReportName) {
      page.footerLayout = document.footerLayout.replace(
        DeloitteMergeTagsRegex.PROJECT_TITLE,
        externalReportName
      );
    }
  }
}

const instance = new DeloittePageService();
export default instance;
