import Vue from 'vue';

import { IGraph, INode } from 'yfiles';

import DiagramUtils from '@/core/utils/DiagramUtils';

import { FontDto, ThemeDto } from '@/api/models';

import {
  DOCUMENT_NAMESPACE,
  GET_CURRENT_THEME
} from '@/core/services/store/document.module';

export enum NodeLabelType {
  IndirectOwnershipPercentage = 'indirect-ownership-percentage',
  Jurisdiction = 'jurisdiction',
  State = 'state'
}

export class NodeDecoratorUtils {
  private static get currentTheme(): ThemeDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_CURRENT_THEME}`
    ];
  }

  /**
   * Gets attribute selector
   * @param type
   * @returns
   */
  static getAttributeSelector(type: NodeLabelType): string {
    return `[${type}="true"]`;
  }

  /**
   * Gets the font for element from current theme
   * @param node
   * @returns
   */
  public static getElementThemeFont(node: INode): FontDto {
    const element = this.currentTheme.elements.find(
      (e) => e.name === node.tag.name
    );

    return element ? element.style.labelStyle.font : null;
  }

  /**
   * Gets the text color from the current label. This is a best guess as the label could contain multiple colors
   * @param node
   * @returns
   */
  public static getTextColor(node: INode): string {
    let color = '#000000';

    // try get color from the current labe
    if (node.labels.size > 0) {
      const labelText = DiagramUtils.getLabelValue(node);
      if (labelText) {
        const colorMatches = Array.from(
          labelText.matchAll(/(;|"|')(\s*color:\s*)(.*?)(;|"|')/gm),
          (d) => d[3]
        );
        if (colorMatches.length > 0) {
          color = colorMatches[0];
        }
      }
    }

    return color;
  }

  /**
   * Ensures the nodes label has a <p> containing the @param labelValue wrapped in a <span>
   * If the label already has a <p type="true"> then the content of this will be updated
   * @param graph
   * @param node
   * @param labelValue
   * @param type
   * @returns
   */
  public static setLabelElement(
    graph: IGraph,
    node: INode,
    labelValue: string,
    type: NodeLabelType
  ): void {
    if (!node) {
      return;
    }
    let label = DiagramUtils.getLabel(node);

    if (!label) {
      label = DiagramUtils.getOrAddLabel(graph, node);
    }

    // create a temporary container that we can use for element manipulation
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;

    // creates a selector for the given type e.g [jurisdiction="true"]
    const attributeSelector = NodeDecoratorUtils.getAttributeSelector(type);

    // attempted to locate an element matching the selector or create one
    const paragraph: HTMLElement =
      tempContainer.querySelector(attributeSelector) ??
      document.createElement('p');

    // element was found and not created
    // clear any existing elements
    if (paragraph.childElementCount > 1) {
      var nodes = paragraph.childNodes;
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        paragraph.removeChild(element);
      }
    }

    // find or create a span to use that we can style appropriately
    const span: HTMLElement =
      (paragraph.firstElementChild as HTMLElement) ??
      document.createElement('span');

    // span was created and not found, it needs to be configured
    if (!paragraph.getAttribute(type)) {
      const themeElementFont = NodeDecoratorUtils.getElementThemeFont(node);

      paragraph.appendChild(span);
      paragraph.setAttribute(type, 'true');
      paragraph.style.textAlign = 'center';
      paragraph.style.pointerEvents = 'none';
      span.style.fontSize = '8pt';
      span.style.color = NodeDecoratorUtils.getTextColor(node);
      span.style.fontFamily = themeElementFont?.fontFamily || 'Arial';

      tempContainer.appendChild(paragraph);
    }
    span.innerHTML = labelValue;

    const containerHtml = tempContainer.innerHTML.replaceAll('&quot;', "'");
    graph.setLabelText(label, containerHtml);
  }

  /**
   * removes the <p> tag from the nodes label, based on @param attribute
   * @param graph
   * @param node
   * @param type
   * @returns
   */
  public static removeLabelElement(
    graph: IGraph,
    node: INode,
    type: NodeLabelType
  ): void {
    if (!node) {
      return;
    }

    const label = DiagramUtils.getLabel(node);
    if (!label) {
      return;
    }

    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;

    const paragraph = tempContainer.querySelector(
      NodeDecoratorUtils.getAttributeSelector(type)
    );
    if (!paragraph) {
      return;
    }

    tempContainer.removeChild(paragraph);
    graph.setLabelText(label, tempContainer.innerHTML);
  }
}
