import { IEdge, INode } from 'yfiles';

import i18n from '@/core/plugins/vue-i18n';

import EntityTypeParameter from '../parameters/EntityTypeParameter';
import { EntityTypeParameterBaseType } from '../parameters/EntityTypeParameterBaseType';

import { ITypedMatcher } from './IMatcher';

export default class EntityTypeMatcher
  implements ITypedMatcher<IEdge | INode, EntityTypeParameter>
{
  public canMatch(candidate: any, parameter: EntityTypeParameter): boolean {
    return candidate instanceof INode || candidate instanceof IEdge;
  }

  public isMatch(
    candidate: IEdge | INode,
    parameter: EntityTypeParameter
  ): boolean {
    // entityType === 'number' if Features.DataFull is true.
    const candidateValue =
      typeof parameter.entityType === 'number'
        ? candidate.tag?.entityTypeId
        : i18n.t(candidate.tag?.name);

    return (
      ((candidate instanceof INode &&
        parameter.baseType === EntityTypeParameterBaseType.Node) ||
        (candidate instanceof IEdge &&
          parameter.baseType == EntityTypeParameterBaseType.Edge)) &&
      candidateValue === parameter.entityType
    );
  }
}
