import Vue from 'vue';
import Router, { Route, RouterOptions } from 'vue-router';
import AnalyticsService from './core/services/analytics/AnalyticsService';
import routes, { RouteNames } from './routes';
import FeaturesService from './core/services/FeaturesService';
import {
  GET_CURRENT_USER,
  GET_USER_PERMISSIONS
} from './core/services/store/user.module';
import { GrantType } from './core/common/ApplicationRoles';
import { Features } from './core/common/Features';
import {
  ApplicationPermissions,
  IPermission
} from './api/ApplicationPermissions';
import AccessCondition from './AccessCondition';
import TutorialsApiService from './api/TutorialsApiService';
import store from './core/services/store';
import appConfig from './core/config/appConfig';

Vue.use(Router);

enum CheckForAccessType {
  Features = 0,
  Roles = 1,
  Permissions = 2
}
interface ICheck {
  name: string;
  grantType?: GrantType;
  redirect?: string;
}

type FeatureCheck = ICheck | string;
type RoleCheck = ICheck | string;
type PermissionCheck = (ICheck & IPermission) | string;

interface IAccessCheckResult {
  succeeded: boolean;
  redirect?: string;
}
const SucceededResult: IAccessCheckResult = { succeeded: true };
class JigsawVueRouter extends Router {
  constructor(options: RouterOptions) {
    super(options);
  }

  get routeNames(): Record<string, string> {
    return RouteNames;
  }

  resolve(to, current, append): any {
    let resolved = super.resolve(to, current, append);
    return resolved;
  }
}

const router = new JigsawVueRouter({
  routes: routes
});

if (import.meta.env.DEV) {
  const playgroundRoutesLoader = import('./playgrounds/routes');

  playgroundRoutesLoader.then(({ playgroundRoutes }) => {
    router.addRoute(playgroundRoutes);
  });
}

router.beforeEach(async (to: Route, from: Route, next) => {
  const matched = to.matched.find((d) => d.name === to.name);

  if (!matched) {
    next();
    return;
  }

  if (
    from.path === '/' &&
    to.matched.some((record) => record.path === '/hub')
  ) {
    if (
      FeaturesService.hasFeature(Features.Onboarding) &&
      appConfig.tutorialSettings.isEnabled &&
      !store.getters[GET_USER_PERMISSIONS]?.includes(
        ApplicationPermissions.Administration.key
      )
    ) {
      const { data } = await TutorialsApiService.getAll({
        ignoreError: true
      });
      const tutorialStatus = data.result?.find((t) => t.complete === false);
      if (tutorialStatus) {
        next({ path: `/documents/${tutorialStatus.documentId}` });
        return;
      } else if (
        !tutorialStatus &&
        store.getters[GET_CURRENT_USER].documentCounts.totalCount === 0
      ) {
        next({ path: RouteNames.welcome });
        return;
      }
    }
  }

  const featureCheck = !!(
    matched.meta.features && matched.meta.features.value?.length
  );

  const roleCheck = !!(matched.meta.roles && matched.meta.roles.value?.length);

  const permissionCheck = !!(
    matched.meta.permissions && matched.meta.permissions.value?.length
  );

  if (!featureCheck && !roleCheck && !permissionCheck) {
    next();
    return;
  }
  const featureResult = featureCheck
    ? checkForAccess(
        CheckForAccessType.Features,
        matched.meta.features.value,
        matched.meta.features.condition ?? AccessCondition.And
      )
    : SucceededResult;

  const rolesResult = roleCheck
    ? checkForAccess(
        CheckForAccessType.Roles,
        matched.meta.roles.value,
        matched.meta.roles.condition ?? AccessCondition.And
      )
    : SucceededResult;

  const permissionsResult = permissionCheck
    ? checkForAccess(
        CheckForAccessType.Permissions,
        matched.meta.permissions.value,
        matched.meta.permissions.condition ?? AccessCondition.And
      )
    : SucceededResult;
  if (
    featureResult.succeeded &&
    rolesResult.succeeded &&
    permissionsResult.succeeded
  ) {
    next();
    return;
  }

  console.warn('No permission to the route: ', to.path);
  const redirect =
    [featureResult, rolesResult, permissionsResult].find(
      (d) => d.redirect != null
    )?.redirect ?? RouteNames.home;
  next({ name: redirect });
});

router.afterEach((to: Route, from: Route) => {
  AnalyticsService.trackPageView(to.name);
});

export default router;

type AccessCheckPredicate = (
  value: FeatureCheck | RoleCheck | PermissionCheck
) => boolean;

const featureAccessCheckPredicate: AccessCheckPredicate = (
  value: FeatureCheck
) => {
  if (typeof value === 'object') {
    FeaturesService.hasFeature(value.name as Features);
  }

  return FeaturesService.hasFeature(value as Features);
};

const roleAccessCheckPredicate: AccessCheckPredicate = (value: RoleCheck) => {
  const user = Vue.$globalStore.getters[GET_CURRENT_USER];

  if (typeof value === 'object') {
    return user.roles.includes(value.name);
  }

  return user.roles.includes(value);
};

const permissionAccessCheckPredicate: AccessCheckPredicate = (
  value: PermissionCheck
) => {
  const permissions = Vue.$globalStore.getters[GET_USER_PERMISSIONS];

  if (typeof value === 'object') {
    return permissions.includes(value.key);
  }

  return permissions.includes(value);
};

function getAccessCheckPredicate(
  accessType: CheckForAccessType
): AccessCheckPredicate {
  switch (accessType) {
    case CheckForAccessType.Features: {
      return featureAccessCheckPredicate;
    }
    case CheckForAccessType.Roles: {
      return roleAccessCheckPredicate;
    }
    case CheckForAccessType.Permissions: {
      return permissionAccessCheckPredicate;
    }
  }

  throw 'Unknown access type';
}

function getGrantType(check: ICheck): GrantType {
  return check?.grantType ?? GrantType.Allow;
}

function checkForAccess(
  accessType: CheckForAccessType,
  values: Array<ICheck>,
  condition: AccessCondition
): IAccessCheckResult {
  const accessCheckPredicate: AccessCheckPredicate =
    getAccessCheckPredicate(accessType);

  if (condition == AccessCondition.Or) {
    for (const check of values) {
      const canAccess =
        accessCheckPredicate(check) && getGrantType(check) == GrantType.Allow;
      if (canAccess) {
        return {
          succeeded: true
        };
      }
    }
    return {
      succeeded: false
    };
  }

  for (const check of values) {
    const canAccess = accessCheckPredicate(check);
    const grantType = getGrantType(check);
    if (
      // we have the value but shouldn't or we have the don't have the value, but should.
      (canAccess && grantType == GrantType.Deny) ||
      (!canAccess && grantType == GrantType.Allow)
    ) {
      return {
        succeeded: false,
        redirect: check.redirect
      };
    }
  }

  return {
    succeeded: true
  };
}

function normalizePath(path: string): string {
  return path?.replace(/\/$/, '');
}
