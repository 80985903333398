import { RouteConfig } from 'vue-router';

export default class RouterUtils {
  public static findRouteConfigParent(
    name: string,
    routeConfigs: RouteConfig[],
    parent: RouteConfig
  ): RouteConfig {
    if (routeConfigs) {
      for (const routeConfig of routeConfigs) {
        if (routeConfig.name === name) {
          return parent;
        }
        let child = this.findRouteConfigParent(
          name,
          routeConfig.children,
          routeConfig
        );
        if (child) {
          return child;
        }
      }
    }
    return null;
  }

  /**
   * Tries to get a route's true "name" from a "commonName" defined on the route configs meta object
   * @param commonName the common name to look for
   * @param currentRoute the current route
   * @param routeConfigs all routes $router.options.routes
   * @returns the routes "name" property, if it cannot be found, @param commonName will be returned
   */
  public static getRouteName(
    commonName: string,
    currentRoute: RouteConfig,
    routeConfigs: RouteConfig[]
  ): string {
    const parent = RouterUtils.findRouteConfigParent(
      currentRoute.name,
      routeConfigs,
      null
    );
    if (!parent || !parent.children || parent.children.length <= 0) {
      return commonName;
    }
    return (
      parent.children.find((x) => x.meta?.commonName === commonName)?.name ??
      commonName
    );
  }
}
