import { PluginObject } from 'vue';

export type TNotificationTypes = 'info' | 'warning' | 'error' | 'success';

export interface INotificationRaw {
  type?: TNotificationTypes;
  title?: string;
  text?: string;
  duration?: number;
  component?: Function;
}

export type Notification = (arg: INotificationRaw) => Promise<boolean>;

export const list = {
  list: [],
  displayCount: 6
};

export const removeById = (id: string | number) => {
  const idx = list.list.findIndex((x) => x.id === id);
  list.list.splice(idx, 1);
};

export const notify = (
  notifyOptions: INotificationRaw
): Promise<boolean | void> => {
  const id = Date.now();
  return new Promise<boolean | void>((resolve) => {
    const notify = {
      ...notifyOptions,
      ...(!notifyOptions.type && { type: 'info' }),
      promise: {
        resolve
      },
      id
    };

    list.list.push(notify);

    console.debug('NotificationPlugin list: ', list);
  }).then((res) => {
    console.debug('NotificationPlugin: resolve', id);
    removeById(id);

    return res;
  });
};

export const NotificationPlugin: PluginObject<any> = {
  /**
   *
   * @param Vue
   * @param options
   */
  install(Vue, options: { displayCount: number } = { displayCount: 5 }) {
    const { displayCount } = options;
    console.debug('Installing notifications... options', options);
    list.displayCount = displayCount;
    Vue.prototype.$notify = notify;
  }
};
