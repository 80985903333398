import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT
} from '@/core/services/store/document.module';
import { TutorialStep } from '@/core/services/tutorial/ITutorial';
import ExportConfig from '@/core/config/ExportConfig';
import TutorialService from '@/core/services/tutorial/TutorialService';
import ZoomService from '@/core/services/graph/ZoomService';

export type VisibleLayoutItems = {
  sidebar: boolean;
  actionToolbar: boolean;
  ribbon: boolean;
  footerToolbar: boolean;
  diagramTools: boolean;
  quickStartButton: boolean;
};

const hiddenCssClass = 'force-hide';

function toggleElementForSelector(selector: string, show: boolean): void {
  const el = selector.startsWith('.')
    ? document.querySelector(selector)
    : document.getElementById(selector);
  if (el && el.classList.contains(hiddenCssClass) === show) {
    if (show) {
      setTimeout(() => {
        el.classList.remove(hiddenCssClass);
        TutorialService.graphService
          ?.getService<ZoomService>(ZoomService.$class)
          .fitCurrentDiagram({ force: true });
      }, TutorialService.tourDelay);
    } else {
      el.classList.add(hiddenCssClass);
    }
  }
}

function toggleElement(el: Element, show: boolean): void {
  if (el) {
    if (show) {
      el?.classList.remove(hiddenCssClass);
    } else {
      el?.classList.add(hiddenCssClass);
    }
  }
}

export default class TutorialLayoutService {
  private static selectors: Record<keyof VisibleLayoutItems, string> = {
    sidebar: '.sidebar',
    actionToolbar: '.vc-ribbon-flex',
    ribbon: '.vc-ribbon-tabs',
    footerToolbar: '.diagram-controls',
    diagramTools: '.right-toolbar-container',
    quickStartButton: '.qs-btn-main-container'
  };

  private static get selectorKeys(): (keyof VisibleLayoutItems)[] {
    return Object.keys(
      TutorialLayoutService.selectors
    ) as (keyof VisibleLayoutItems)[];
  }

  public static init(): void {
    EventBus.$on(
      EventBusActions.DOCUMENT_INIT_STARTING,
      this.onDocumentInitStarting
    );

    EventBus.$on(
      EventBusActions.DOCUMENT_INIT_COMPLETE,
      this.onDocumentInitComplete
    );
  }

  public static destroy(): void {
    EventBus.$off(
      EventBusActions.DOCUMENT_INIT_STARTING,
      this.onDocumentInitStarting
    );

    EventBus.$off(
      EventBusActions.DOCUMENT_INIT_COMPLETE,
      this.onDocumentInitComplete
    );
  }

  public static toggleActionToolbarButtons(show: boolean): void {
    const topLeftButtonsSelector = '.vc-ribbon-inline-list';
    const buttonsSelectors = [
      '.vc-ribbon-quick-launch-toolbar-status',
      '[data-automation-id="edit-document-title"]',
      '[data-automation-id="rb-version-history-button"]',
      '.ribbon-canvas-search',
      '[data-automation-id="rb-share-button"]',
      '[data-automation-id="rb-profile"]',
      topLeftButtonsSelector
    ];

    const parent = document.querySelector(
      TutorialLayoutService.selectors.actionToolbar
    );
    if (parent) {
      buttonsSelectors.forEach((selector) => {
        const el = parent.querySelector(selector);
        if (!el) return;
        if (selector === topLeftButtonsSelector) {
          el.querySelectorAll('li').forEach((li, index) => {
            if (index > 0) {
              toggleElement(li, show);
            }
          });
        } else {
          toggleElement(el, show);
        }
      });
    }
  }

  public static toggleQSButton(show: boolean): void {
    toggleElementForSelector(
      TutorialLayoutService.selectors.quickStartButton,
      show
    );
  }

  public static toggleLayoutItems(step?: TutorialStep): void {
    TutorialLayoutService.selectorKeys.forEach((s) => {
      let show = true;
      if (step?.visibleLayoutItems) {
        show = step.visibleLayoutItems[s];
      }

      toggleElementForSelector(TutorialLayoutService.selectors[s], show);
    });

    if (step?.visibleLayoutItems) {
      toggleElementForSelector(
        ExportConfig.appSidebarId,
        step.visibleLayoutItems.sidebar
      );
    }
  }

  private static onDocumentInitStarting(): void {
    TutorialLayoutService.selectorKeys.forEach((s) => {
      toggleElementForSelector(TutorialLayoutService.selectors[s], false);
    });
    toggleElementForSelector(ExportConfig.appSidebarId, false);
  }

  private static onDocumentInitComplete(): void {
    const document =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
    if (document && (!document.tutorial || document.tutorial.complete)) {
      TutorialLayoutService.selectorKeys.forEach((s) => {
        toggleElementForSelector(TutorialLayoutService.selectors[s], true);
      });
      toggleElementForSelector(ExportConfig.appSidebarId, true);
    }
  }
}
