export const stepActionSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      repeat: { type: 'boolean' },
      repeatCount: { type: 'integer', minimum: 0 },
      progress: { type: 'integer', minimum: 0 },
      event: {
        type: 'object',
        properties: {
          name: { type: 'string', minLength: 1 },
          label: { type: 'string' },
          condition: {
            type: 'object',
            nullable: true,
            properties: {
              value: { type: ['string', 'integer'] },
              label: { type: 'string', minLength: 1 }
            }
          }
        },
        required: ['name']
      },
      category: {
        type: 'object',
        properties: {
          label: { type: 'string', minLength: 1 },
          value: { type: 'string', minLength: 1 },
          component: { type: 'string' }
        },
        required: ['label', 'value']
      }
    }
  }
};

const attachToSchema = {
  type: 'object',
  properties: {
    element: { type: 'string', minLength: 1 },
    side: { type: 'string', minLength: 1 },
    category: { type: 'integer', minimum: 0 },
    isWithinCanvas: { type: 'boolean' },
    borderRadius: { type: 'integer' },
    edgeSection: { type: 'integer', minimum: 0 }
  },
  required: ['element', 'side', 'category']
};

export default {
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1 },
    title: { type: 'string', minLength: 1 },
    dialogWidth: { type: 'integer', minimum: 50, maximum: 800 },
    attachTo: attachToSchema,
    stepActions: stepActionSchema,
    translations: {
      type: 'object',
      nullable: true,
      properties: {
        title: { type: 'string', minLength: 1 },
        text: { type: 'string' }
      }
    },
    text: { type: 'string' },
    delay: { type: 'integer', minimum: 0 },
    hideRibbon: { type: 'boolean' },
    hideSidebar: { type: 'boolean' },
    hideHighlight: { type: 'boolean' },
    arrow: { type: 'boolean' },
    restrictTo: { type: 'string' },
    promptOffset: {
      type: 'object',
      properties: {
        xAxisOffset: { type: 'integer' },
        yAxisOffset: { type: 'integer' }
      },
      required: ['xAxisOffset', 'yAxisOffset']
    }
  },
  required: ['id', 'title', 'dialogWidth', 'attachTo', 'stepActions']
};
