import {
  ArrowStyleDto,
  CompositeNodeStyleDto,
  CompositePathItemStyleDto,
  CompositeShapeItemStyleDto,
  DataPropertyDefinitionItemDto,
  DividingLineNodeStyleDto,
  DividingLineType,
  EdgeStyleDto,
  EdgeVisualType,
  FillType,
  GroupNodeStyleDto,
  IFillDto,
  ImageNodeStyleDto,
  InsetsDto,
  JigsawPathShapeNodeStyleDto,
  LayoutDto,
  LinearGradientDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  SolidColorFillDto,
  StrokeDto,
  TextBoxNodeStyleDto
} from '@/api/models';
import { INode, IEdge, IModelItem, Insets, Rect } from 'yfiles';
import DiagramWriter from '../services/graph/serialization/diagram-writer.service';
import { AnnotationType } from '../common/AnnotationType';
import { calculateHash, calculateHashOld } from './common.utils';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import appConsts from '@/core/config/appConsts';
import CompositeItem from '@/components/ShapeBuilder/CompositeItem';
import { CompositeItemType } from '@/api/models';
import DiagramUtils from './DiagramUtils';
import CompositeNodeStyle from '@/components/ShapeBuilder/CompositeNodeStyle';

interface IHashDataNode {
  groupUuid: string;
  isGroupNode: boolean;
  style: IHashDataNodeStyle;
  isAnnotation: boolean;
  name: string;
  annotationType: AnnotationType;
  isDataPropertyStyleActive?: boolean;
  dataProperties?: {
    jurisdiction: any;
  };
}

interface IHashDataEdge {
  name: string;
  style: IHashDataEdgeStyle;
}

interface IHashDataDataPropertyDefinitionItem {
  definitionId: number;
  imageData: string;
  itemValue: string;
}

interface IHashDataEdgeStyle {
  stroke: StrokeDto;
  sourceArrow: ArrowStyleDto;
  targetArrow: ArrowStyleDto;
  visualType: EdgeVisualType;
}

interface IHashDataNodeStyle {
  visualType: NodeVisualType;
  shape?: NodeShape;
  fill?: IFillDto;
  stroke?: StrokeDto;
  image?: string;
  definitions?: CompositeItem[];
  lineType?: DividingLineType;
}

export default class GraphElementsHashGenerator {
  public static getElementHashKey(item: IModelItem): string {
    if (item instanceof INode) {
      return this.getNodeHashKey(item);
    } else if (item instanceof IEdge) {
      return this.getEdgeHashKey(item);
    }
    return null;
  }

  public static getNodeHashKey(
    node: INode,
    useLegacyStyleOrder = false,
    useOldHashingLib = false,
    ignoreStyle = false
  ): string {
    const jurisdiction = DataPropertyUtils.getDataPropertyFromNode(
      node,
      appConsts.JURISDICTION_DEFINITION_ID
    );

    const data: IHashDataNode = {
      groupUuid:
        node.tag.isGroupNode && node.tag.groupUuid ? node.tag.groupUuid : null,
      isGroupNode: node.tag.isGroupNode ?? null,
      style: ignoreStyle
        ? undefined
        : this.getNodeStyle(node, useLegacyStyleOrder),
      isAnnotation: node.tag.isAnnotation ?? null,
      name: node.tag?.name?.toLowerCase(),
      annotationType: node.tag.annotationType,
      isDataPropertyStyleActive: node.tag?.dataPropertyStyle?.isActive ?? false
    };

    if (data.isDataPropertyStyleActive) {
      data.dataProperties = {
        jurisdiction: jurisdiction?.value
      };
    }

    const cacheKey = useOldHashingLib
      ? calculateHashOld(data)
      : calculateHash(data);

    return cacheKey;
  }

  public static getEdgeHashKey(edge: IEdge, useOldHashingLib = false): string {
    const data: IHashDataEdge = {
      style: this.getEdgeStyle(edge),
      name: edge.tag?.name?.toLowerCase()
    };

    return useOldHashingLib ? calculateHashOld(data) : calculateHash(data);
  }

  public static getDataPropertyDefinitionItemHash(
    definition: DataPropertyDefinitionItemDto,
    useOldHashingLib = false
  ): string {
    const data: IHashDataDataPropertyDefinitionItem = {
      definitionId: definition.dataPropertyDefinitionId,
      imageData: definition.imageData,
      itemValue: definition.itemValue
    };

    return useOldHashingLib ? calculateHashOld(data) : calculateHash(data);
  }

  private static getNodeStyle(
    node: INode,
    useLegacyStyleOrder = false
  ): IHashDataNodeStyle {
    const nodeStyleDto = DiagramWriter.convertNodeStyle(node);

    if (
      nodeStyleDto instanceof ShapeNodeStyleDto ||
      nodeStyleDto instanceof JigsawPathShapeNodeStyleDto
    ) {
      return {
        fill: this.getFill(nodeStyleDto.fill),
        stroke: this.getStroke(nodeStyleDto.stroke, useLegacyStyleOrder),
        visualType: nodeStyleDto.visualType,
        shape: Number(nodeStyleDto.shape)
      };
    } else if (nodeStyleDto instanceof ImageNodeStyleDto) {
      return {
        image: nodeStyleDto.imageUrl,
        visualType: nodeStyleDto.visualType
      };
    } else if (nodeStyleDto instanceof CompositeNodeStyleDto) {
      // We're converting the layout back to insets only so the generated hash will be the same so the legend defintion will not change.
      const layoutToInsets = (layout: LayoutDto): InsetsDto => {
        const top = layout.y * 100;
        const left = layout.x * 100;

        const bottom = 100 - (top + layout.height * 100);
        const right = 100 - (left + layout.width * 100);
        const insets = {
          bottom: bottom,
          left: left,
          right: right,
          top: top
        };
        return insets;
      };

      const definitions = [];
      for (const item of nodeStyleDto.items) {
        // initialize base definition

        const def = {
          insets: null,
          style: null
        };

        if (
          item.layout.x != 0 ||
          item.layout.y != 0 ||
          item.layout.width != 0 ||
          item.layout.height != 1
        ) {
          def.insets = layoutToInsets(item.layout);
        }
        if (item.type == CompositeItemType.Shape) {
          const itemStyle = item.style as CompositeShapeItemStyleDto;
          def.style = {
            fill: this.getFill(itemStyle.fill),
            stroke: this.getStroke(itemStyle.stroke),
            shape: Number(itemStyle.shape)
          };
        } else if (item.type == CompositeItemType.Path) {
          const itemStyle = item.style as CompositePathItemStyleDto;
          def.style = {
            fill: this.getFill(itemStyle.fill),
            stroke: this.getStroke(itemStyle.stroke),
            path: itemStyle.path
          };
        } else {
          throw `Unknown type ${item.type}`;
        }

        definitions.push(def);
      }
      return {
        shape: Number(
          (DiagramUtils.unwrapNodeStyle(node).baseStyle as CompositeNodeStyle)
            .shape
        ),
        definitions: definitions,
        visualType: nodeStyleDto.visualType
      };
    } else if (nodeStyleDto instanceof DividingLineNodeStyleDto) {
      return {
        lineType: nodeStyleDto.lineType,
        stroke: this.getStroke(nodeStyleDto.stroke),
        visualType: nodeStyleDto.visualType
      };
    } else if (nodeStyleDto instanceof TextBoxNodeStyleDto) {
      return {
        fill: this.getFill(nodeStyleDto.fill),
        stroke: this.getStroke(nodeStyleDto.stroke, useLegacyStyleOrder),
        visualType: nodeStyleDto.visualType
      };
    } else if (nodeStyleDto instanceof GroupNodeStyleDto) {
      return {
        visualType: nodeStyleDto.visualType
      };
    }
    throw new Error('Unsupported node style: ' + JSON.stringify(nodeStyleDto));
  }

  private static getEdgeStyle(edge: IEdge): IHashDataEdgeStyle {
    const edgeStyleDto: EdgeStyleDto = DiagramWriter.convertEdgeStyle(edge);

    return {
      stroke: this.getStroke(edgeStyleDto.stroke),
      sourceArrow: this.getArrowStyle(edgeStyleDto.sourceArrow),
      targetArrow: this.getArrowStyle(edgeStyleDto.targetArrow),
      visualType: edgeStyleDto.visualType
    };
  }

  private static getStroke(stroke: StrokeDto, legacyOrder = false): StrokeDto {
    if (!stroke) {
      return stroke;
    }
    if (legacyOrder) {
      // For legacy keys, node stroke properties need to be reversed to calculate the correct hash
      return {
        thickness: stroke.thickness,
        fill: this.getFill(stroke.fill),
        dashStyle: stroke.dashStyle
      };
    } else {
      return {
        dashStyle: stroke.dashStyle,
        fill: this.getFill(stroke.fill),
        thickness: stroke.thickness
      };
    }
  }

  private static getArrowStyle(style: ArrowStyleDto): ArrowStyleDto {
    if (!style) {
      return style;
    }
    return {
      type: style.type,
      scale: style.scale,
      fill: this.getFill(style.fill)
    };
  }

  private static getFill(
    fill: IFillDto
  ): SolidColorFillDto | LinearGradientDto {
    if (!fill) {
      return fill;
    }
    if (fill.type === FillType.SolidColor) {
      const solidFill = fill as SolidColorFillDto;
      return {
        color: solidFill.color
      };
    } else if (fill.type === FillType.LinearGradient) {
      const gradientFill = fill as LinearGradientDto;
      return {
        type: gradientFill.type,
        startPoint: gradientFill.startPoint,
        endPoint: gradientFill.endPoint,
        spreadMethod: gradientFill.spreadMethod,
        gradientStops: gradientFill.gradientStops
      };
    }
    throw new Error('Unsupported fill: ' + JSON.stringify(fill));
  }
}
