import { GET_USER_PERMISSIONS } from '@/core/services/store/user.module';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class UserMixin extends Vue {
  getUserInitials(user: { name: string; surname: string }): string {
    let initials = '';
    if (user?.name) {
      initials += user.name[0];
    }
    if (user?.surname) {
      initials += user.surname[0];
    }
    return initials.toUpperCase();
  }
}
