import { DropTarget, QueryContinueDragEventArgs } from 'yfiles';

export function createDragPreview(event: Event): HTMLElement {
  const dragPreview = document.createElement('div');
  dragPreview.setAttribute('style', 'z-index: 1000');
  dragPreview.appendChild((event.target as HTMLElement).cloneNode(true));

  return dragPreview;
}

export function queryContinueDragHandler(
  dropTarget: DropTarget | null,
  dragPreview: HTMLElement
): void {
  if (dropTarget === null) {
    dragPreview.classList.remove('hidden');
  } else {
    dragPreview.classList.add('hidden');
  }
}
