import axios, {
    AxiosResponse
} from 'axios';
import {
    GetDocumentPageForViewDto,
    ZadarResponse,
    CreateOrEditDocumentPageOutput,
    CreateOrEditDocumentPageDto,
    StepsPagePropertiesDto,
    DocumentPageDto
} from './models'
export default class DocumentPagesApiService {
    public static getDocumentPageForView(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentPageForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/GetDocumentPageForView`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditDocumentPageDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentPageOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static insertNewPage(body: CreateOrEditDocumentPageDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentPageOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/InsertNewPage`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateStepsPageProperties(body: StepsPagePropertiesDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/UpdateStepsPageProperties`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static clone(body: DocumentPageDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentPageOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/Clone`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentPages/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
}