import Vue from 'vue';
import { GraphComponent, IEdge, ILabel, IModelItem } from 'yfiles';
import StyleCreator from './StyleCreator';
import { firstUndefined } from './common.utils';
import { stripHtml } from './html.utils';
import { DefaultColors } from '../common/DefaultColors';
import CKEditorUtils, { ZERO_WIDTH_SPACE } from './CKEditorUtils';
import i18n from '../plugins/vue-i18n';
import JigsawEdgeLabelModel from '../services/graph/label-models/JigsawEdgeLabelModel';
import JigsawEdgeLabelModelParameter from '../services/graph/label-models/JigsawEdgeLabelModelParameter';
import {
  FontDto,
  ThemeElementDto,
  SolidColorFillDto,
  FillType
} from '@/api/models';
import config from '@/core/config/diagram.definition.config';
import {
  DOCUMENT_NAMESPACE,
  GET_THEME_ELEMENT_BY_NAME,
  GET_EDGE_WITH_LABEL_PLACEHOLDER,
  SET_EDGE_WITH_LABEL_PLACEHOLDER,
  GET_EDGE_LABEL_PLACEHOLDER_ENABLED
} from '@/core/services/store/document.module';
import DiagramUtils from '@/core/utils/DiagramUtils';

export default class EdgeLabelUtils {
  private static getEdgeThemeElement(edge: IEdge): ThemeElementDto | null {
    if (edge.tag && edge.tag.name) {
      return Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
      ](edge.tag.name) as ThemeElementDto | undefined;
    }
    return null;
  }

  private static createEdgeLabelModelParameter(
    edge: IEdge
  ): JigsawEdgeLabelModelParameter {
    const themeElement = EdgeLabelUtils.getEdgeThemeElement(edge);
    if (themeElement) {
      return DiagramUtils.getEdgeLabelParameter(
        edge,
        themeElement.edgeLabelPosition
      ) as JigsawEdgeLabelModelParameter;
    }

    return new JigsawEdgeLabelModel().createParameterForSegment(
      0.5,
      0.5,
      0,
      false,
      0
    );
  }

  public static tryRemoveEdgeLabelPlaceholder(gc: GraphComponent): void {
    const edge =
      Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_EDGE_WITH_LABEL_PLACEHOLDER}`
      ];

    if (!edge?.tag.labelIsPlaceholder) {
      return;
    }

    const label = edge?.labels?.at(0);
    if (!label) {
      return;
    }

    const text = stripHtml(label.text);
    if (!text || text === i18n.t('LABEL')) {
      gc.graph.remove(label);
    }

    edge.tag.labelIsPlaceholder = false;

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_WITH_LABEL_PLACEHOLDER}`,
      null
    );
  }

  public static hasPlaceholderContent(label: ILabel): boolean {
    if (!IEdge.isInstance(label.owner)) {
      return false;
    }

    const placeholderContent = EdgeLabelUtils.getHtmlTextForEdgeLabel(
      label.owner,
      `${i18n.t('LABEL')}`,
      DefaultColors.GREY,
      null,
      { fontStyle: config.supportedFontStyles[1] }
    );

    return placeholderContent === label.text;
  }

  public static getHtmlTextForEdgeLabel(
    item?: IModelItem,
    text?: string,
    color?: string,
    backgroundColor?: string,
    fontStyle?: Partial<FontDto>
  ): string {
    let themeFontStyle: Partial<FontDto> = {};
    let themeColor: string = DefaultColors.BLACK;

    if (item && item.tag && item.tag.name) {
      const themeElement = Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
      ](item.tag.name) as ThemeElementDto | undefined;
      if (themeElement?.style?.labelStyle) {
        themeFontStyle = { ...themeElement.style.labelStyle.font };
        themeColor = themeElement.style.labelStyle.fill.color;
      }
    }

    return CKEditorUtils.createHtmlStringFromStyle(
      new SolidColorFillDto(
        FillType.SolidColor,
        firstUndefined(color, themeColor)
      ),
      backgroundColor
        ? new SolidColorFillDto(FillType.SolidColor, backgroundColor)
        : null,
      new FontDto(
        firstUndefined(
          fontStyle?.fontSize,
          themeFontStyle.fontSize,
          config.defaultFontSize
        ),
        firstUndefined(
          fontStyle?.fontFamily,
          themeFontStyle.fontFamily,
          config.defaultFontFamily
        ),
        firstUndefined(
          fontStyle?.fontStyle,
          themeFontStyle.fontStyle,
          config.supportedFontStyles[0]
        ),
        firstUndefined(
          fontStyle?.fontWeight,
          themeFontStyle.fontWeight,
          config.supportedFontWeights[0]
        ),
        firstUndefined(
          fontStyle?.textDecoration,
          themeFontStyle.textDecoration,
          config.defaultTextDecoration
        )
      ),
      text ? text : ZERO_WIDTH_SPACE
    );
  }

  public static tryAddDefaultLabelFromTheme(
    gc: GraphComponent,
    edge: IEdge
  ): ILabel {
    EdgeLabelUtils.tryRemoveEdgeLabelPlaceholder(gc);

    if (edge.labels.size) {
      return;
    }

    const themeElement = EdgeLabelUtils.getEdgeThemeElement(edge);
    if (!themeElement || !themeElement.defaultLabel) {
      return null;
    }

    edge.tag.labelIsPlaceholder = false;

    return gc.graph.addLabel(
      edge,
      EdgeLabelUtils.getHtmlTextForEdgeLabel(edge, themeElement.defaultLabel),
      EdgeLabelUtils.createEdgeLabelModelParameter(
        edge
      ) as JigsawEdgeLabelModelParameter,
      StyleCreator.createLabelStyle(),
      null,
      DiagramUtils.createNewLabelTag()
    );
  }

  public static getDefaultHtmlLabelForEdge(edge: IEdge): string {
    const themeElement = EdgeLabelUtils.getEdgeThemeElement(edge);
    if (!themeElement || !themeElement.defaultLabel) {
      return EdgeLabelUtils.getHtmlTextForEdgeLabel(edge);
    }
    return EdgeLabelUtils.getHtmlTextForEdgeLabel(
      edge,
      themeElement.defaultLabel
    );
  }

  public static tryAddEdgeLabelPlaceholder(
    gc: GraphComponent,
    edge: IEdge,
    withText = true
  ): ILabel {
    EdgeLabelUtils.tryRemoveEdgeLabelPlaceholder(gc);

    if (edge.labels.size) {
      return null;
    }

    if (
      !Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_EDGE_LABEL_PLACEHOLDER_ENABLED}`
      ]
    ) {
      return;
    }

    edge.tag.labelIsPlaceholder = true;

    let labelContent = ZERO_WIDTH_SPACE;
    if (withText) {
      labelContent = EdgeLabelUtils.getHtmlTextForEdgeLabel(
        edge,
        `${i18n.t('LABEL')}`,
        DefaultColors.GREY,
        null,
        { fontStyle: config.supportedFontStyles[1] }
      );
    }

    const label = gc.graph.addLabel(
      edge,
      labelContent,
      EdgeLabelUtils.createEdgeLabelModelParameter(edge),
      StyleCreator.createLabelStyle(),
      null,
      DiagramUtils.createNewLabelTag()
    );

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_WITH_LABEL_PLACEHOLDER}`,
      edge
    );

    return label;
  }

  public static tryAddEdgeLabel(
    gc: GraphComponent,
    edge: IEdge,
    withText = true
  ): ILabel {
    return EdgeLabelUtils.tryAddEdgeLabelPlaceholder(gc, edge, withText);
  }
}
