import { SizeDto } from '@/api/models';
import { CSSProperties } from 'vue/types/jsx';
import { Size } from 'yfiles';
export default class JSize {
  constructor(
    public width: number,
    public height: number
  ) {}

  public static get EMPTY(): JSize {
    return new JSize(0, 0);
  }
  public static fromHtmlElement(element: HTMLElement): JSize {
    let rect = element.getBoundingClientRect();
    return new JSize(rect.width, rect.height);
  }

  public get area(): number {
    return this.width * this.height;
  }

  public get isEmpty(): boolean {
    return this.width == JSize.EMPTY.width && this.height == JSize.EMPTY.height;
  }
  public multiply(factor: number): JSize {
    return new JSize(this.width * factor, this.height * factor);
  }

  public divide(factor: number): JSize {
    return new JSize(this.width / factor, this.height / factor);
  }

  public round(): JSize {
    return new JSize(Math.round(this.width), Math.round(this.height));
  }

  public clone(): JSize {
    return new JSize(this.width, this.height);
  }

  public equals(other: JSize): boolean {
    return this.width == other.width && this.height == other.height;
  }

  public toYFiles(): Size {
    return new Size(this.width, this.height);
  }

  public toCss(unit: string): CSSProperties {
    return { width: this.width + unit, height: this.height + unit };
  }

  public static fromYFiles(size: Size): JSize {
    return new JSize(size.width, size.height);
  }

  public static fromDto(size: SizeDto): JSize {
    if (!size) {
      return JSize.EMPTY;
    }
    return new JSize(size.width, size.height);
  }

  toString(): string {
    return `JSize(${this.width}, ${this.height})`;
  }
}
