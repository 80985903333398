import IManageApiService from '@/api/IManageApiService';
import appConfig from '@/core/config/appConfig';
import httpStatusCodes from '@/core/config/httpStatusCodes';
import { appendUrl } from '@/core/utils/common.utils';

class IManageAuthService {
  public async login(): Promise<void> {
    const redirect = appendUrl(
      appConfig.apiBaseUrl,
      appConfig.iManage.loginUrl
    );
    window.location.href = redirect;
  }
  public async checkToken(): Promise<void> {
    var result = await IManageApiService.refreshToken();
    if (result.status == httpStatusCodes.UNAUTHORIZED) {
      this.login();
    }
  }
  public async logout(): Promise<Boolean> {
    var result = await IManageApiService.logoutUser();
    return result.status == httpStatusCodes.OK;
  }
}

const instance = new IManageAuthService();
export default instance;
