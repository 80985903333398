import { LayoutGraph, PortCalculator } from 'yfiles';

export default class JigsawPortCalculator extends PortCalculator {
  applyLayout(graph: LayoutGraph): void {
    const coreLayout = this.coreLayout;
    if (coreLayout) {
      coreLayout.applyLayout(graph);
    }
    this.calculatePorts(graph);
  }
}
