import { stringContainsCI } from '@/core/utils/common.utils';
import { IEdge } from 'yfiles';
import TextParameter from '../parameters/TextParameter';
import { ITypedMatcher } from './IMatcher';

export default class EdgeTextMatcher
  implements ITypedMatcher<IEdge, TextParameter>
{
  public canMatch(candidate: any, parameter: TextParameter): boolean {
    return candidate instanceof IEdge;
  }

  public isMatch(candidate: IEdge, parameter: TextParameter): boolean {
    return (
      stringContainsCI(candidate?.tag?.name, parameter?.text) ||
      candidate.labels?.some((l) => stringContainsCI(l.text, parameter?.text))
    );
  }
}
