<template>
  <div>
    <slot name="activator" :is-open="valueSync" :toggle="toggle"></slot>

    <AppTransitionExpand>
      <div v-show="valueSync">
        <slot />
      </div>
    </AppTransitionExpand>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppCollapse'
})
export default class AppCollapse extends Vue {
  @Prop({ default: null })
  value: boolean;

  private localValue = false;

  get valueSync(): boolean {
    if (this.value == null) {
      return this.localValue;
    }
    return this.value;
  }

  set valueSync(value) {
    this.localValue = value;
  }

  toggle(): void {
    this.valueSync = !this.valueSync;
    this.$emit('input', this.localValue);
  }
}
</script>
