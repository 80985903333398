import { CommandHandlerType } from '@/core/services/CommandManager/CommandHandlerType';
import { CommandType } from '@/core/services/CommandManager/CommandType';
import CommandHandlerBase from '@/core/services/CommandManager/CommandHandlerBase';
import IClipboardAction from '@/v2/services/interfaces/IClipboardAction';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import CommandManager from '@/core/services/CommandManager/CommandManager';
import CkEditorCommandHandler from '@/core/services/editor/CkEditorCommandHandler';
import MultiNodeCkEditorCommandHandler from '@/core/services/graph/MultiNodeCKEditorCommandHandler';

export default class DiagramCommandHandler extends CommandHandlerBase {
  public readonly type: CommandHandlerType = CommandHandlerType.Diagram;
  public static readonly $class: string = 'DiagramCommandHandler';

  constructor(
    public graphService: IGraphService,
    ckEditorCommandHandler: CkEditorCommandHandler,
    multiNodeCkEditorCommandHandler: MultiNodeCkEditorCommandHandler
  ) {
    super();
    this.addChild(ckEditorCommandHandler);
    this.addChild(multiNodeCkEditorCommandHandler);
  }

  public override dispose(): void {
    if (this.isDisposed) {
      return;
    }

    if (CommandManager.INSTANCE.isCommandHandlerActive(this)) {
      CommandManager.INSTANCE.unsetCommandHandler();
    }

    // Do not call super.dispose() and don't dispose child command handlers because
    // they are handled by DiagramCKEditorService and MultiNodeCKEditorService and will be disposed on destroy of DocumentDetails.vue
    this.children = [];
    this.isDisposed = true;
  }

  public release(nextCommandHandler: CommandHandlerBase): CommandHandlerBase {
    if (nextCommandHandler?.isChildOf(this)) {
      return this;
    }

    // If next commandHandler is not related to current one - clear selection and end editing
    this.graphService.graphComponent.selection.clear();
    return this;
  }

  public executeCommand(commandType: CommandType, value: any): void {
    const clipboardAction = this.graphService.getService<IClipboardAction>(
      IClipboardAction.$class
    );
    switch (commandType) {
      case CommandType.copy:
        clipboardAction.executeCommand('copy');
        break;
      case CommandType.cut:
        clipboardAction.executeCommand('cut');
        break;
      case CommandType.paste:
        clipboardAction.executeCommand('paste');
        break;
      case CommandType.undo:
        clipboardAction.executeCommand('undo');
        break;
      case CommandType.redo:
        clipboardAction.executeCommand('redo');
        break;
    }
  }

  public publishAllValues(): void {}

  isChildOf(parent: CommandHandlerBase): boolean {
    return false;
  }

  setParent(parent: CommandHandlerBase): void {}
}
