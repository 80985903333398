import {
  DataExportDataPropertyDto,
  DataPropertyDto,
  DataPropertyTagContext,
  DataPropertyTagDto,
  DataPropertyValueScope,
  FileAttachmentDto
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import { IGraph } from 'yfiles';
import { EventBus, EventBusActions } from '../../events/eventbus.service';
import CompositeFilter from '../../search/filters/CompositeFilter';
import DataPropertyTagFilter from '../../search/filters/DataPropertyTagFilter';
import { FilterScope } from '../../search/filters/FilterScope';
import { FilterType } from '../../search/filters/FilterType';
import SearchResults from '../../search/SearchResults';
import ExportOptions from '../ExportOptions';
import EdgeDataExporter from './EdgeDataExporter';
import NodeDataExporter from './NodeDataExporter';
import DiagramData from './DiagramData';
import IDisposable from '@/core/common/IDisposable';
import DataPropertyDataExporter from './DataPropertyDataExporter';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import i18n from '@/core/plugins/vue-i18n';

export default class DiagramDataExportService implements IDisposable {
  public currentTagFilters: DataPropertyTagFilter[] = [];

  constructor() {
    EventBus.$on(
      EventBusActions.SEARCH_RESULTS_CHANGED,
      this.onSearchResultsChanged.bind(this)
    );
  }

  public isDisposed: boolean;
  public dispose(): void {
    if (this.isDisposed) return;
    this.isDisposed = true;
  }

  public export(graph: IGraph, options: ExportOptions): DiagramData {
    const graphData = new DiagramData();

    for (let node of graph.nodes) {
      const attachments = this.filterAttachments(
        node.tag.attachments,
        node.tag.dataPropertyTags,
        options
      );
      const nodeData = NodeDataExporter.getData(
        node,
        this.filterDataProperties(
          node.tag.dataProperties as DataPropertyDto[],
          node.tag.dataPropertyTags,
          attachments,
          options
        ),
        attachments
      );
      graphData.nodes.push(nodeData);
    }

    for (let edge of graph.edges) {
      if (!edge.tag) {
        continue;
      }

      const attachments = this.filterAttachments(
        edge.tag.attachments,
        edge.tag.dataPropertyTags,
        options
      );
      const edgeData = EdgeDataExporter.getData(
        edge,
        this.filterDataProperties(
          edge.tag.dataProperties as DataPropertyDto[],
          edge.tag.dataPropertyTags,
          attachments,
          options
        ),
        attachments
      );
      graphData.edges.push(edgeData);
    }

    return graphData;
  }

  private onSearchResultsChanged(results: SearchResults) {
    const filters =
      results.criteria.filters != null
        ? CompositeFilter.decompose(results.criteria.filters)
        : [];
    this.currentTagFilters = filters.filter(
      (f) => f.scope == FilterScope.DataPropertyTag
    ) as DataPropertyTagFilter[];
  }

  private filterDataProperties(
    dataProperties: DataPropertyDto[],
    dataPropertyTags: DataPropertyTagDto[],
    attachments: FileAttachmentDto[],
    options: ExportOptions
  ): DataExportDataPropertyDto[] {
    if (!dataProperties) {
      return [];
    }

    let filteredDataProperties = dataProperties.filter((dp) => {
      const definition = DataPropertyUtils.getDefinition(
        dp.dataPropertyDefinitionId
      );
      if (
        !definition ||
        definition.valueScope == DataPropertyValueScope.Decorator
      ) {
        return false;
      }
      return true;
    });

    if (
      this.currentTagFilters.length > 0 &&
      dataPropertyTags &&
      options.withFilters
    ) {
      filteredDataProperties = dataProperties.filter((dp) =>
        this.isDataPropertyIncluded(dp, dataPropertyTags)
      );
    }

    const exportDataProperties = [];
    for (let dp of filteredDataProperties) {
      const definition = DataPropertyUtils.getDefinition(
        dp.dataPropertyDefinitionId
      );
      if (
        !definition ||
        definition.valueScope === DataPropertyValueScope.Decorator
      ) {
        continue;
      }

      const displayValues = dp.value
        ? DataPropertyUtils.getDisplayValueAsArray(dp.value, definition)
        : [null];

      if (displayValues.length > 0) {
        for (let i = 0; i < displayValues.length; i++) {
          displayValues[i] = i18n.t(displayValues[i]);
        }
      }
      let filteredDisplayValues = displayValues;

      if (
        this.currentTagFilters.length > 0 &&
        dataPropertyTags &&
        options.withFilters
      ) {
        filteredDisplayValues = [];
        for (let value of displayValues) {
          const isIncluded = this.isDataPropertyValueIncluded(
            dp,
            value,
            dataPropertyTags
          );
          if (isIncluded) {
            filteredDisplayValues.push(value);
          }
        }
      }

      if (filteredDisplayValues.length > 0) {
        const displayValue = filteredDisplayValues.join(', ');
        const dto = DataPropertyDataExporter.getData(
          dp,
          displayValue,
          attachments
        );
        exportDataProperties.push(dto);
      }
    }
    return exportDataProperties;
  }

  private isDataPropertyIncluded(
    dataProperty: DataPropertyDto,
    dataPropertyTags: DataPropertyTagDto[]
  ): boolean {
    const tagNames = dataPropertyTags
      .filter(
        (t) =>
          t.context == DataPropertyTagContext.Property &&
          t.dataPropertyDefinitionId == dataProperty.dataPropertyDefinitionId
      )
      .map((t) => t.name);

    return this.allTagNamesIncluded(tagNames);
  }

  private isDataPropertyValueIncluded(
    dataProperty: DataPropertyDto,
    value: string,
    dataPropertyTags: DataPropertyTagDto[]
  ) {
    const tagNames = dataPropertyTags
      .filter(
        (t) =>
          t.context == DataPropertyTagContext.Value &&
          t.dataPropertyUuid == dataProperty.uuid &&
          t.dataPropertyValue == value
      )
      .map((t) => t.name);

    return this.allTagNamesIncluded(tagNames);
  }

  private filterAttachments(
    attachments: FileAttachmentDto[],
    dataPropertyTags: DataPropertyTagDto[],
    options: ExportOptions
  ): FileAttachmentDto[] {
    if (!attachments) {
      return [];
    }
    if (
      this.currentTagFilters.length === 0 ||
      !dataPropertyTags ||
      !options.withFilters
    ) {
      return attachments;
    }

    return attachments.filter((att) =>
      this.isAttachmentIncluded(att, dataPropertyTags)
    );
  }

  private isAttachmentIncluded(
    fileAttachment: FileAttachmentDto,
    dataPropertyTags: DataPropertyTagDto[]
  ): boolean {
    const tagNames = dataPropertyTags
      .filter(
        (t) =>
          t.context == DataPropertyTagContext.Attachment &&
          t.fileAttachmentId &&
          t.fileAttachmentId == fileAttachment.id
      )
      .map((t) => t.name);

    return this.allTagNamesIncluded(tagNames);
  }

  private allTagNamesIncluded(tagNames: string[]): boolean {
    return this.currentTagFilters.every(
      (f) =>
        (f.type == FilterType.Include &&
          tagNames.includes(f.parameter.tagName)) ||
        (f.type == FilterType.Exclude &&
          !tagNames.includes(f.parameter.tagName))
    );
  }
}
