import JRect from './JRect';
import { Point as YPoint } from 'yfiles';
export default class JPoint {
  constructor(
    public x: number = 0,
    public y: number = 0
  ) {}

  public static get ORIGIN(): JPoint {
    return new JPoint(0, 0);
  }

  public equals(p: JPoint): boolean {
    return this.x === p.x && this.y === p.y;
  }

  public distanceTo(point: JPoint): number {
    return Math.sqrt(
      Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2)
    );
  }

  public constrainTo(rect: JRect): JPoint {
    let x = this.x;
    let y = this.y;

    if (this.x > rect.maxX) {
      x = rect.maxX;
    }
    if (this.y > rect.maxY) {
      y = rect.maxY;
    }
    return new JPoint(x, y);
  }

  public subtract(point: JPoint): JPoint {
    return new JPoint(this.x - point.x, this.y - point.y);
  }

  public add(point: JPoint): JPoint {
    return new JPoint(this.x + point.x, this.y + point.y);
  }

  public multiply(factor: number): JPoint {
    return new JPoint(this.x * factor, this.y * factor);
  }

  public divide(factor: number): JPoint {
    return new JPoint(this.x / factor, this.y / factor);
  }

  public round(): JPoint {
    return new JPoint(Math.round(this.x), Math.round(this.y));
  }

  public clone(): JPoint {
    return new JPoint(this.x, this.y);
  }

  public toYFiles(): YPoint {
    return new YPoint(this.x, this.y);
  }
  public static fromYFiles(point: YPoint): JPoint {
    return new JPoint(point.x, point.y);
  }
}
