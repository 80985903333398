import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import ExportService from '../ExportService';
import b64toBlob from '../../graph/b64ToBlob';

export default class ExcelExportProvider implements IExportProvider {
  private _fileExtension = 'xlsx';
  private _mimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const payload = ExportService.prepareDataExportPayload(
      options,
      null,
      this._fileExtension,
      this._mimeType,
      true
    );

    const response = await DiagramExportApiService.documentDataExport(payload);
    return {
      mimeType: this._mimeType,
      fileExtension: this._fileExtension,
      result: b64toBlob(response.data.result)
    };
  }
}
