import IDisposable from '@/core/common/IDisposable';
import { Mapper } from 'yfiles';
import IContainer from './IContainer';

export default class DefaultDiContainer implements IContainer, IDisposable {
  private serviceMap: Mapper<string, any>;
  constructor() {
    this.serviceMap = new Mapper<string, any>();
  }
  isDisposed: boolean;

  register(lookup: string, instanceOrFactory: any): void {
    this.serviceMap.set(lookup, instanceOrFactory);
  }

  resolve<T>(lookup: string): T {
    let service = this.serviceMap.get(lookup);
    //Factory construction
    if (service?.constructor === Function) {
      service = service();
    }
    return service;
  }

  dispose(): void {
    if (this.isDisposed) {
      return;
    }
    this.isDisposed = true;
    this.serviceMap.entries.forEach((entry) => {
      const service = entry.value;
      if (!service || typeof service.dispose !== 'function') {
        return;
      }
      service.dispose();
    });
  }
}
