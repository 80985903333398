import { DataExportFileAttachmentDto, FileAttachmentDto } from '@/api/models';

export default class FileAttachmentDataExporter {
  public static getData(
    attachment: FileAttachmentDto
  ): DataExportFileAttachmentDto {
    const dto = new DataExportFileAttachmentDto(
      attachment.id,
      attachment.isConfidential,
      attachment.fileId,
      attachment.filename,
      attachment.mimeType
    );
    return dto;
  }
}
