import cloneDeep from 'lodash/cloneDeep';

/** Default debounce duration (in ms) */
export const DEFAULT_DEBOUNCE_DURATION = 500;

/** Decorates a class method so that it is debounced by the specified duration
 *  Function that uses this decorator must get args as array that contains args
 *  Example decorator:
 *  @debounceAccumulator(200)
 *  public onDebounce(accumulatedArgs: { arg1: Type; arg2: Type }[] { ... }
 *  Usage:
 *  this.onDebounce({ arg1, arg2 } as any);
 */
export function debounceAccumulator(
  duration: number,
  cloneArgs = false,
  onlyFirstAndLast = false
) {
  return function innerDecorator(target: any, key: any, descriptor: any): any {
    return {
      configurable: true,
      enumerable: descriptor.enumerable,
      get: function getter(): any {
        // Attach this function to the instance (not the class)
        Object.defineProperty(this, key, {
          configurable: true,
          enumerable: descriptor.enumerable,
          value: debounceFunction(
            descriptor.value,
            this,
            duration,
            cloneArgs,
            onlyFirstAndLast
          )
        });

        return (this as any)[key];
      }
    };
  };
}

/** Debounce the specified function and returns a wrapper function
 *  that accumulates all arguments which were passed during debounced calls
 * */
function debounceFunction(
  method: any,
  that: any,
  duration = DEFAULT_DEBOUNCE_DURATION,
  cloneArgs = false,
  onlyFirstAndLast = false
): Function {
  let timeoutId: ReturnType<typeof setTimeout>;
  let accumulatedArgs = [];

  const updateAccumulatedArgs = (args: any): void => {
    if (args.length !== 1) {
      throw Error(
        'Only a single argument should be passed to the debounce handler function'
      );
    }
    if (cloneArgs) {
      accumulatedArgs.push(cloneDeep(args[0]));
    } else {
      accumulatedArgs.push(args[0]);
    }
  };

  function debounceWrapper(...args: any): void {
    debounceWrapper.clear();

    // Update accumulated args for the first call if onlyFirstAndLast is set to true
    // Otherwise update on each call
    if (!onlyFirstAndLast || accumulatedArgs.length === 0) {
      updateAccumulatedArgs(args);
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;

      // Update accumulated args for the last call
      // (if onlyFirstAndLast is set to false it will be updated outside the setTimeout)
      if (onlyFirstAndLast) {
        updateAccumulatedArgs(args);
      }

      method.call(that, accumulatedArgs);
      accumulatedArgs = [];
    }, duration);
  }

  debounceWrapper.clear = function (): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debounceWrapper;
}
