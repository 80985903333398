import axios, {
    AxiosResponse
} from 'axios';
import {
    GetHubDetailModel,
    ZadarResponse,
    GetUserInformationModel,
    GetAvailableLibrariesModel,
    GetRecentFoldersModel,
    GetMyMattersModel,
    SearchMatterModel,
    GetWorkspaceFoldersModel,
    GetFolderContentsModel,
    GetDialogTokenModel,
    SaveDocumentResponseModel,
    SaveDocumentModel,
    LockDocumentModel,
    UpdateDocumentModel,
    GetDocumentCheckoutModel,
    GetDocumentProfileModel,
    GetDocumentPathModel,
    HttpStatusCode
} from './models'
export default class IManageApiService {
    public static getHubDetails(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetHubDetailModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetHubDetails`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getUserInformation(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetUserInformationModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetUserInformation`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static deleteDocument(params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/DeleteDocument`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getAvailableLibraries(params ? : {
        user_id ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetAvailableLibrariesModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetAvailableLibraries`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getRecentFolders(params ? : {
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetRecentFoldersModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetRecentFolders`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getMattersByUserId(params ? : {
        user_id ? : string,
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetMyMattersModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetMattersByUserId`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static searchMatter(params ? : {
        clientId ? : string,
        matterId ? : string,
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < SearchMatterModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/SearchMatter`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getWorkspaceFolders(params ? : {
        folder_id ? : string,
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetWorkspaceFoldersModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetWorkspaceFolders`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getFolderContents(params ? : {
        folder_id ? : string,
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetFolderContentsModel[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetFolderContents`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDialogToken(params ? : {
        filename ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDialogTokenModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetDialogToken`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static logoutUser(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/LogoutUser`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static saveDocument(body: SaveDocumentModel, params ? : {
        documentId ? : number,
        folder_id ? : string,
        library ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < SaveDocumentResponseModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/SaveDocument`,
            method: 'post',
            data: body,
            params: params,
            clientConfig: clientConfig
        })
    }
    public static removeExternalIdFromDocument(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/RemoveExternalIdFromDocument`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static lockDocument(body: LockDocumentModel, params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/LockDocument`,
            method: 'post',
            data: body,
            params: params,
            clientConfig: clientConfig
        })
    }
    public static unlockDocument(params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/UnlockDocument`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static updateDocument(body: UpdateDocumentModel, params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < boolean >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/UpdateDocument`,
            method: 'put',
            data: body,
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentCheckoutDetails(params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentCheckoutModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetDocumentCheckoutDetails`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentProfile(params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentProfileModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetDocumentProfile`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentPath(params ? : {
        documentId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentPathModel >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/GetDocumentPath`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static refreshToken(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/RefreshToken`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static checkToken(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < HttpStatusCode >>> {
        return axios.request( < any > {
            url: `/api/services/app/IManage/CheckToken`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
}