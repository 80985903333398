import defaultTheme from 'tailwindcss/defaultTheme';
import plugin from 'tailwindcss/plugin';

export default {
  content: ['./public/index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  theme: {
    extend: {
      screens: {
        '2xl': '1536px',
        '3xl': '1900px',
        'tall': { raw: '(min-height: 840px)' }
      },
      transitionProperty: {
        height: 'height',
        width: 'width',
        minWidth: 'min-width, width'
      },
      height: {
        min: 'min-content',
        max: 'max-content'
      },
      animation: {
        'skeleton-pulse':
          'skeleton-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite'
      },
      keyframes: {
        'skeleton-pulse': {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.5 }
        }
      },
      ringWidth: {
        3: '3px'
      }
    },

    colors: {
      'transparent': 'transparent',
      'black': '#000000',
      'white': '#ffffff',
      'brown': '#6f3e00',
      // main dark
      'main-dark': '#464E5F',
      'main-dark-90': '#4E566A',
      'main-dark-80': '#5A6275',
      'main-dark-60': '#90949F',
      'main-dark-40': '#B5B8BF',
      'main-dark-20': '#DADBDF',
      'main-dark-10': '#ECEDEF',
      'main-dark-05': '#F5F6F7',
      'main-dark-01': '#FBFBFC',
      // accent purple
      'accent-purple': '#4747FF',
      'accent-purple-90': '#5959FF',
      'accent-purple-80': '#6B6BFF',
      'accent-purple-40': '#B5B5FF',
      'accent-purple-20': '#DADAFF',
      'accent-purple-05': '#F5F5FF',
      // accent cyan
      'accent-cyan': '#00B5AB',
      'accent-cyan-90': '#19BCB3',
      'accent-cyan-80': '#33C3BB',
      'accent-cyan-05': '#F2FBFB',
      // success green
      'success-green': '#00AE1D',
      'success-green-90': '#19B633',
      'success-green-80': '#33BE4A',
      'success-green-05': '#F2FBF3',
      // warning orange
      'warning-orange': '#DB7C00',
      // danger red
      'danger-red': '#AE3030',
      'danger-red-90': '#B64444',
      'danger-red-80': '#BE5959',
      'danger-red-60': '#CE8282',
      'danger-red-40': '#DFACAC',
      'danger-red-20': '#EFD5D5',
      'danger-red-10': '#F7EAEA',
      'danger-red-05': '#FBF4F4',

      'current': 'currentColor',

      'charade': '#3A4044',
      'perrywinkle': '#8E85EB',
      'primrose-garden': '#F59699',
      'cornflower': '#4E74D5',
      'north-wind': '#47C5BE',
      'palatinate-blue': '#3433E1',
      'qing-dynasty-dragon': '#4948E4',
      'pinkinity': '#E13396',
      'cerebral-grey': '#CCCCCC',
      'cotton-boll': '#E5EEFF',
      'alto': '#DEDEDE',
      'wild-sand': '#F4F4F4',
      'liver': '#4D4D4D',
      'cornflower-blue': '#6366F5',
      'cornflower-blue-100': '#8083F7',
      'blue-ribbon': '#4044FF',
      'midnight-iris': '#464E60',
      'sacred-spring': '#C6CACE',
      'smoky-studio': '#7D838F',
      'orochimaru': '#D9D9D9',
      'dr-white': '#FAFAFA',
      'masterpiece': '#A2A2AB',
      'let-it-rain': '#B4B8BF',
      'sky-dancer': '#46a0fc',
      'stoic-white': '#E0E0FB',
      'christmas-silver': '#E0E0E0',
      'gray-chateau': '#A2A6AF',
      'punch-out-glove': '#7295FE',
      'brilliant-white': '#E3ECFF',

      'white-backdrop': 'rgba(255, 255, 255, 0.7)'
    },
    spacing: {
      0: '0',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      7: '7px',
      8: '8px',
      9: '9px',
      10: '10px',
      11: '11px',
      12: '12px',
      13: '13px',
      14: '14px',
      15: '15px',
      16: '16px',
      17: '17px',
      18: '18px',
      20: '20px',
      22: '22px',
      24: '24px',
      28: '28px',
      30: '30px',
      32: '32px',
      34: '34px',
      36: '36px',
      40: '40px',
      44: '44px',
      48: '48px',
      50: '50px',
      52: '52px',
      56: '56px',
      60: '60px',
      64: '64px',
      66: '66px',
      68: '68px',
      72: '72px',
      80: '80px',
      82: '82px',
      84: '84px',
      88: '88px',
      90: '90px',
      96: '96px',
      100: '100px',
      110: '110px',
      114: '114px',
      120: '120px',
      132: '132px',
      140: '140px',
      145: '145px',
      160: '160px',
      168: '168px',
      174: '174px',
      180: '180px',
      200: '200px',
      220: '220px',
      230: '230px',
      240: '240px',
      250: '250px',
      254: '254px',
      260: '260px',
      280: '280px',
      300: '300px',
      310: '310px',
      320: '320px',
      330: '330px',
      360: '360px',
      380: '380px',
      400: '400px',
      445: '445px',
      466: '466px',
      500: '500px'
    },

    minWidth: (theme) => ({
      full: '100%',
      max: 'max-content',
      ...theme('spacing')
    }),
    maxWidth: (theme) => ({
      full: '100%',
      none: 'none',
      max: 'max-content',
      ...theme('spacing')
    }),

    minHeight: (theme) => ({
      full: '100%',
      ...theme('spacing')
    }),
    maxHeight: (theme) => ({
      'full': '100%',
      '4/10': '40%',
      '6/10': '60%',
      'none': 'none',
      'min': 'min-content',
      ...theme('spacing')
    }),

    opacity: {
      0: '0',
      3: '0.03',
      10: '0.10',
      20: '0.20',
      40: '0.4',
      50: '0.5',
      60: '0.6',
      100: '1'
    },

    zIndex: {
      '-1': -1,
      '0': 0,
      '1': 1,
      '2': 2,
      '4': 4,
      '5': 5,
      '10': 10,
      '12': 12,
      '20': 20,
      '30': 30,
      '40': 40,
      '50': 50,
      '60': 60,
      '70': 70,
      '80': 80,
      '90': 90,
      '100': 100,
      'notification': 2801
    },

    backgroundOpacity: {
      10: '0.1',
      20: '0.2',
      30: '0.3',
      40: '0.4',
      50: '0.5',
      60: '0.6',
      70: '0.7',
      100: '1'
    },

    fontSize: {
      4: '4px',
      7: '7px',
      8: '8px',
      9: '9px',
      10: '10px',
      base: '12px',
      13: '13px',
      14: '14px',
      15: '15px',
      16: '16px',
      18: '18px',
      20: '20px',
      22: '22px',
      24: '24px',
      26: '26px',
      28: '28px',
      30: '30px',
      32: '32px',
      40: '40px'
    },

    borderRadius: {
      none: '0',
      full: '9999px',
      2: '2px',
      3: '3px',
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      48: '48px',
      72: '72px'
    },

    borderWidth: {
      DEFAULT: '1px',
      none: '0',
      1: '1px',
      2: '2px'
    },

    boxShadow: {
      'sm': '0px 3px 6px #00000029',
      'md': '0px 3px 8px #464E5F33',
      'full': '0px 0px 20px #464E5F33;',
      'round-purple':
        '1px 1px 15px 0px rgba(71, 71, 255, 0.5), -1px -1px 15px 0px rgba(71, 71, 255, 0.5)',
      'none': '0 0 transparent'
    },

    ringOffsetWidth: {
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px'
    },

    fontFamily: {
      base: ['Inter', ...defaultTheme.fontFamily.sans]
    }
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        '.scrollbar-hide': {
          /* IE and Edge */
          '-ms-overflow-style': 'none',

          /* Firefox */
          'scrollbar-width': 'none',

          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        },
        '.force-overflow-visible': {
          overflow: 'visible !important'
        },
        '.flex-force': {
          display: 'flex !important'
        },

        '.scrollbar-default': {
          /* IE and Edge */
          '-ms-overflow-style': 'auto',

          /* Firefox */
          'scrollbar-width': 'auto',

          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'block'
          }
        },

        '.overflow-overlay': {
          overflow: 'overlay'
        },

        '.overflow-x-overlay': {
          'overflow-x': 'overlay'
        },

        '.overflow-y-overlay': {
          'overflow-y': 'overlay'
        },
        '.fill-height': {
          height: '-webkit-fill-available'
        }
      };

      addUtilities(newUtilities, ['responsive', 'hover']);
    })
  ]
};
