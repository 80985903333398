import { Features } from '@/core/common/Features';
import { HAS_LICENSE_FEATURE } from '@/core/services/store/licence.module';
import Vue from 'vue';
import appConfig from '../config/appConfig';

export default class FeaturesService {
  public static hasFeature(feature: Features): boolean {
    return (
      Vue.$globalStore.getters[HAS_LICENSE_FEATURE]({
        featureName: feature
      }) || appConfig.featureFlags?.includes(feature)
    );
  }
}
