import { ECondition } from './OwnershipParameterType';
import ISearchParameter from './ISearchParameter';

export default class OwnershipParameter implements ISearchParameter {
  public nodeUuid: string;
  public percentage: number;
  public condition: ECondition;

  constructor(nodeUuid: string, percentage?: number, condition?: ECondition) {
    this.nodeUuid = nodeUuid;

    if (percentage) {
      this.percentage = percentage;
    }
    if (condition) {
      this.condition = condition;
    }
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof OwnershipParameter && other.nodeUuid == this.nodeUuid
    );
  }
}
