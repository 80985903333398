import { HandleInputMode, IHandle, Point, Rect, Insets, Size } from 'yfiles';
import JigsawEdgeLabelRotateHandle from '../label-models/JigsawEdgeLabelRotateHandle';

export default class JigsawEdgeLabelHandleInputMode extends HandleInputMode {
  public static handleWidth = 20;
  protected handleIsHit(
    handle: IHandle,
    location: Point,
    distance: Point
  ): boolean {
    if (handle instanceof JigsawEdgeLabelRotateHandle) {
      const viewLocation =
        this.inputModeContext.canvasComponent.toViewCoordinates(
          handle.location
        );
      return Rect.fromCenter(
        viewLocation,
        new Size(
          JigsawEdgeLabelHandleInputMode.handleWidth,
          JigsawEdgeLabelHandleInputMode.handleWidth
        )
      ).contains(location);
    } else {
      return super.handleIsHit(handle, location, distance);
    }
  }
}
