import axios, {
    AxiosResponse
} from 'axios';
import {
    UserDto,
    ZadarResponse
} from './models'
export default class CollaborationApiService {
    public static getUsers(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UserDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/Collaboration/GetUsers`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
}