import DeloittePlugin from '@/plugins/Deloitte/DeloittePlugin';
import appConfig from '../config/appConfig';

export default class PluginsService {
  public static hasPlugin(pluginName: string): boolean {
    return (
      appConfig.plugins[pluginName] && appConfig.plugins[pluginName].enabled
    );
  }

  public static initPlugins(): void {
    DeloittePlugin.init();
  }
}
