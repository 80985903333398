import IDisposable from '@/core/common/IDisposable';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { GraphComponent, ScrollBarVisibility } from 'yfiles';
import isNil from 'lodash/isNil';
export default class ScrollBarService implements IDisposable {
  public static readonly $class: string = 'ScrollBarService';
  private readonly graphComponent: GraphComponent;
  public isDisposed: boolean;
  private isDisabled: boolean;

  constructor(graphService: IGraphService) {
    this.graphComponent = graphService.graphComponent;
    this.setScrollBarsVisibility();
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.isDisposed = true;
  }

  public setScrollBarsVisibility(
    verticalScrollBarVisibility?: ScrollBarVisibility,
    horizontalScrollBarVisibility?: ScrollBarVisibility
  ): void {
    if (this.isDisabled) return;
    if (!isNil(verticalScrollBarVisibility)) {
      this.graphComponent.verticalScrollBarPolicy = verticalScrollBarVisibility;
    }
    if (!isNil(horizontalScrollBarVisibility)) {
      this.graphComponent.horizontalScrollBarPolicy =
        horizontalScrollBarVisibility;
    }
  }

  public disableScrollBars(): void {
    this.setScrollBarsVisibility(ScrollBarVisibility.NEVER);
    this.isDisabled = true;
  }

  public enableScrollBars(): void {
    this.isDisabled = false;
    this.setScrollBarsVisibility(
      ScrollBarVisibility.AS_NEEDED,
      ScrollBarVisibility.AS_NEEDED
    );
  }
}
