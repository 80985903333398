//@ts-nocheck
import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  INode,
  Point,
  OrientedRectangle,
  ILookup,
  IEnumerable,
  List,
  BaseClass,
  Size
} from 'yfiles';

import clamp from 'lodash/clamp';
import JigsawExteriorNodeLabelModelParameter from './JigsawExteriorNodeLabelModelParameter';
import diagramConfig from '@/core/config/diagram.definition.config';
export default class JigsawExteriorNodeLabelModel extends BaseClass(
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
) {
  private distanceFromNode: number = 15;
  /**
   * When true, will ensure the label is not allowed outside the bounds of the node
   */
  private constrainToNode: boolean = true;
  /**
   * These are all position vectors, they are used to anchor the labels
   */
  static get NORTH(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(0, -1)
    );
  }
  static get NORTH_EAST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(1, -1)
    );
  }
  static get EAST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(1, 0)
    );
  }
  static get SOUTH_EAST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(1, 1)
    );
  }
  static get SOUTH(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(0, 1)
    );
  }

  static get SOUTH_WEST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, 1)
    );
  }
  static get WEST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, 0)
    );
  }
  static get NORTH_WEST(): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, -1)
    );
  }
  /**
   * Returns instances of the support interfaces (which are actually the model instance itself)
   */
  lookup<T>(type: Class<T>): T {
    if (type === ILabelModelParameterProvider.$class) {
      // If we request a ILabelModelParameterProvider AND we use discrete label candidates, we return the label model
      // itself, otherwise, null is returned, which means that continuous label positions are supported.
      return this;
    } else if (type === ILabelModelParameterFinder.$class) {
      // If we request a ILabelModelParameterProvider, we return the label model itself, so we can always retrieve a
      // matching parameter for a given actual position.
      return this;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const ownerNode = label.owner;
    if (
      ownerNode instanceof INode &&
      layoutParameter instanceof JigsawExteriorNodeLabelModelParameter
    ) {
      const nodeLayout = ownerNode.layout;
      const labelSize = label.preferredSize;

      // try calculate the base offset from the parameters position vector
      // if we have no vector, default to the center
      let offset: Point = this.getAnchorPosition(
        layoutParameter.positionVector,
        ownerNode,
        labelSize
      );
      if (layoutParameter.offset) {
        offset = offset.add(layoutParameter.offset);
      }

      const anchor = new Point(
        nodeLayout.x + offset.x,
        nodeLayout.y + offset.y
      );

      const labelHeight = labelSize.height;
      const labelWidth = labelSize.width;
      const rect = new OrientedRectangle(
        anchor.x,
        anchor.y,
        labelWidth,
        labelHeight
      );
      return rect;
    }
    return IOrientedRectangle.EMPTY;
  }

  createDefaultParameter(): ILabelModelParameter {
    return JigsawExteriorNodeLabelModel.SOUTH as unknown as ILabelModelParameter;
  }

  createParameterFromOffset(
    point: Point
  ): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModelParameter(this, {
      offset: point,
      positionVector: JigsawExteriorNodeLabelModel.SOUTH.positionVector
    });
  }

  createParameterFromPositionVector(
    positionVector: Point
  ): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModelParameter(this, {
      positionVector: positionVector
    });
  }

  createParameterFromVectorAndOffset(
    vector: Point,
    offset: Point
  ): JigsawExteriorNodeLabelModelParameter {
    return new JigsawExteriorNodeLabelModelParameter(this, {
      offset: offset,
      positionVector: vector
    });
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }

  /**
   * This will clamp both the x and y positions to the node
   * @param anchor the absolute position for the label to be clamped
   * @param node the node to have the anchor clamped too
   * @param labelSize the visual size of the label
   * @returns the absolute clamped point of the label
   */
  private clamp(anchor: Point, node: INode, labelSize: Size): Point {
    let y = clamp(
      anchor.y,
      node.layout.y - this.distanceFromNode,
      node.layout.maxY + labelSize.height + this.distanceFromNode
    );
    let x = 0;

    // Not allow label to be inside of node
    if (
      anchor.y >= node.layout.maxY + labelSize.height + this.distanceFromNode ||
      anchor.y <= node.layout.y - this.distanceFromNode
    ) {
      x = clamp(
        anchor.x,
        node.layout.x - labelSize.width - this.distanceFromNode,
        node.layout.maxX + this.distanceFromNode
      );
    } else if (anchor.x < node.layout.center.x) {
      x = node.layout.x - labelSize.width - this.distanceFromNode;
    } else {
      x = node.layout.maxX + this.distanceFromNode;
    }

    return new Point(x, y);
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    const labelModel = model as unknown as JigsawExteriorNodeLabelModel;
    const parameter =
      label.layoutParameter as JigsawExteriorNodeLabelModelParameter;
    const node = label.owner as INode;

    let allowedPosition = new Point(
      layout.anchorLocation.x,
      layout.anchorLocation.y
    );

    if (this.constrainToNode) {
      const labelSize = label.layout.toSize();
      allowedPosition = this.clamp(allowedPosition, node, labelSize);
    }

    const labelLayout = allowedPosition
      .subtract(label.layout.anchorLocation)
      .add(parameter.offset ?? Point.ORIGIN);

    const newParameter = labelModel.createParameterFromOffset(labelLayout);
    return newParameter as unknown as ILabelModelParameter;
  }

  /**
   * Takes a direction vector relative to the node's center and returns a position
   * @param vector
   * @param node
   * @returns
   */
  public getAnchorPosition(vector: Point, node: INode, labelSize: Size): Point {
    const nodeLayout = node.layout;
    // translates the vector into a position relative to the nodes center
    let anchor: Point = null;
    // North
    if (vector.x == 0 && vector.y == -1) {
      anchor = new Point(
        nodeLayout.width / 2 - labelSize.width / 2,
        -labelSize.height - this.distanceFromNode
      );
    }

    // North East
    else if (vector.x == 1 && vector.y == -1) {
      anchor = new Point(
        node.layout.width + this.distanceFromNode,
        -labelSize.height - this.distanceFromNode
      );
    }
    // East
    else if (vector.x == 1 && vector.y == 0) {
      anchor = new Point(
        node.layout.width + this.distanceFromNode,
        nodeLayout.height / 2 - labelSize.height / 2
      );
    }

    //South East
    else if (vector.x == 1 && vector.y == 1) {
      anchor = new Point(
        node.layout.width + this.distanceFromNode,
        nodeLayout.height + this.distanceFromNode
      );
    }

    // South West
    else if (vector.x == -1 && vector.y == 1) {
      anchor = new Point(
        -labelSize.width - this.distanceFromNode,
        nodeLayout.height + this.distanceFromNode
      );
    }

    // West
    else if (vector.x == -1 && vector.y == 0) {
      anchor = new Point(
        -labelSize.width - this.distanceFromNode,
        nodeLayout.height / 2 - labelSize.height / 2
      );
    }

    // North West
    else if (vector.x == -1 && vector.y == -1) {
      anchor = new Point(
        -labelSize.width - this.distanceFromNode,
        -labelSize.height - this.distanceFromNode
      );
    } else {
      // Default to south
      anchor = new Point(
        nodeLayout.width / 2 - labelSize.width / 2,
        nodeLayout.height + this.distanceFromNode
      );
    }

    // labels should be anchored bottom left of the node, not top left as normal.
    // see https://docs.yworks.com/yfileshtml/#/dguide/view_item-layout#view_item-layout_labels
    return anchor.add(new Point(0, labelSize.height));
  }

  getMaxWidth(label: ILabel): number {
    const ownerNode = label.owner;

    if (!(ownerNode instanceof INode)) {
      throw 'owner is not an node';
    }

    return ownerNode.layout.width + diagramConfig.label.defaultLabel.inset * 2;
  }
}
