import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class JigsawTranslateMixin extends Vue {
  $tIf(key, condition): TranslateResult {
    if (condition) {
      return this.$t(key);
    }
    return key;
  }
}
