import RouterUtils from '@/core/utils/Router.utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class CommonNameRouteMixin extends Vue {
  getRouteName(commonName: string): string {
    return RouterUtils.getRouteName(
      commonName,
      this.$route,
      this.$router.options.routes
    );
  }
}
