import { CSSProperties } from 'vue/types/jsx';
import Vue from 'vue';
import isNil from 'lodash/isNil';
import {
  DocumentDto,
  DocumentPageContentType,
  DocumentView,
  PageElementPosition
} from '@/api/models';
import { ILegendLayoutOptionParams } from './ILegendLayoutOptionParams';
import JSize from '@/core/common/JSize';
import JPoint from '@/core/common/JPoint';
import ILegendOptionsDefinition from '@/components/DiagramLegend/ILegendOptionsDefinition';
import ExportConfig from '@/core/config/ExportConfig';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  GET_DOCUMENT_VIEW,
  GET_SHOW_LOGO
} from '@/core/services/store/document.module';
import JRect from '@/core/common/JRect';
import {
  LayoutForView,
  LayoutForViewOptions
} from '@/components/DiagramLegend/LayoutForViewOptions';
import diagramDefinitionConfig from '@/core/config/diagram.definition.config';
import LogoAsImageProvider from '@/core/services/export/additional-element-providers/LogoAsImageProvider';
import ThemeService from '@/core/services/graph/ThemeService';
import LegendOptions from '@/components/DiagramLegend/LegendOptions';
import LegendSerializer from '@/components/DiagramLegend/LegendSerializer';
import ExportUtils from '@/core/services/export/ExportUtils';
import DiagramChangeHandler from '@/core/services/graph/DiagramChangeHandler';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import LegendHtmlUtils from '@/components/DiagramLegend/LegendHtmlUtils';

type LegendPositionEntry = {
  point: JPoint;
  static: PageElementPosition;
};

export type LayoutElementDetails = {
  bounds: JRect;
  staticPosition: PageElementPosition;
};

export default class LegendLayoutUtils {
  private static parentSize = JSize.EMPTY;
  private static logoDetails?: LayoutElementDetails;

  public static setParentSize(size: JSize): void {
    this.parentSize = size;
  }

  public static ensureValidLayout(
    args?: Partial<ILegendOptionsDefinition>,
    params?: ILegendLayoutOptionParams
  ): LayoutForViewOptions {
    const options: Partial<LayoutForViewOptions> = {};

    Object.keys(DocumentView).forEach((view) => {
      if (Number.isNaN(parseInt(view))) return;

      let layout: LayoutForView | undefined;
      if (args?.layout && args?.layout[view]) {
        layout = args.layout[view];
      }

      let position = layout?.position;
      if (layout && (layout.position?.x > 1 || layout.position?.y > 1)) {
        layout.position = undefined;
      }
      let staticPosition = layout?.staticPosition;
      let scale = layout?.scale;
      if (params?.isMasterLegend && !scale) {
        scale =
          ExportConfig.diagramLegendScale.medium *
          ExportConfig.masterLegendScaleMultiplier;
      }

      if (!scale) {
        scale = ExportConfig.diagramLegendScale.medium;
      }

      if (layout && (layout.size?.width > 1 || layout.size?.height > 1)) {
        layout.size = undefined;
      }

      let size = layout?.size;
      if (params?.isMasterLegend && !size) {
        size = new JSize(1, 1);
      }

      const defaultPosition = LegendLayoutUtils.getDefaultPosition(
        parseInt(view),
        params?.isMasterLegend
      );

      if (!position) position = defaultPosition.point;
      if (!staticPosition) staticPosition = defaultPosition.static;

      options[view] = {
        ...diagramDefinitionConfig.legend.defaultLayout,
        size,
        scale,
        position,
        staticPosition,
        itemWidth: layout
          ? layout.itemWidth
          : diagramDefinitionConfig.legend.defaultLayout.itemMinWidth
      } as LayoutForView;
    });

    return options as LayoutForViewOptions;
  }

  public static setPositionFromTheme(
    options: LegendOptions,
    isMasterLegend: boolean,
    isPrintView: boolean,
    legendSize?: JSize,
    parentSize?: JSize
  ): void {
    if (isMasterLegend) return;

    Object.keys(DocumentView).forEach((view) => {
      if (Number.isNaN(parseInt(view))) return;

      const layout: LayoutForView = options.layout[view];
      const themePosition = ThemeService.legendPosition;

      if (
        themePosition !== null &&
        themePosition !== PageElementPosition.Unset
      ) {
        if (isPrintView) {
          layout.position = LegendLayoutUtils.legendPositionToPoint(
            themePosition,
            legendSize,
            parentSize
          );
        }
        layout.staticPosition = themePosition;
      }
    });
  }

  public static ensureValidPosition(
    options: ILegendOptionsDefinition,
    params?: ILegendLayoutOptionParams
  ): void {
    if (!options.layout) {
      options.layout = LegendLayoutUtils.ensureValidLayout(options, params);
    }

    Object.keys(options.layout).forEach((view) => {
      if (Number.isNaN(parseInt(view))) return;

      const layout = options.layout[view];
      layout.position = LegendLayoutUtils.legendPositionToPoint(
        layout.position
      );

      if (parseInt(view) === DocumentView.Web) {
        layout.staticPosition =
          layout.staticPosition ?? PageElementPosition.TopLeft;
      } else {
        layout.staticPosition =
          layout.staticPosition ?? PageElementPosition.Unset;
      }
    });
  }

  public static getDefaultPosition(
    documentView: DocumentView,
    isMasterLegend?: boolean
  ): LegendPositionEntry {
    let pointPos = JPoint.ORIGIN;
    let staticPos =
      documentView === DocumentView.Web
        ? PageElementPosition.TopLeft
        : PageElementPosition.Unset;

    const themePosition = ThemeService.legendPosition;
    if (
      !isMasterLegend &&
      themePosition !== null &&
      themePosition !== PageElementPosition.Unset
    ) {
      pointPos = LegendLayoutUtils.legendPositionToPoint(themePosition);
      staticPos = themePosition;
    }

    return {
      point: pointPos,
      static: staticPos
    };
  }

  private static resolveImageSize(img: HTMLImageElement): JSize {
    const { width, height, naturalWidth, naturalHeight } = img;

    if (!naturalWidth || !naturalHeight) {
      return new JSize(width, height);
    }

    const naturalRatio = naturalWidth / naturalHeight;
    const ratio = width / height;

    if (naturalRatio >= ratio) {
      return new JSize(width, width / naturalRatio);
    }

    return new JSize(height * naturalRatio, height);
  }

  public static getRenderedImageSize(img: HTMLImageElement): Promise<JSize> {
    if (img?.complete) {
      return Promise.resolve(this.resolveImageSize(img));
    }

    if (img) {
      return new Promise((resolve) => {
        img.onload = (): void => resolve(this.resolveImageSize(img));
        img.onerror = (): void => resolve(JSize.EMPTY);
      });
    }
  }

  public static async getLogoDetails(
    parentSize: JSize,
    isExport: boolean
  ): Promise<LayoutElementDetails> {
    const img = window.document.querySelector(
      '.diagram-logo-container img'
    ) as HTMLImageElement;

    if (!img) {
      this.logoDetails = {
        bounds: JRect.EMPTY,
        staticPosition: PageElementPosition.Unset
      };
    }

    const isLogoShown: boolean =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_SHOW_LOGO}`];
    let logoDetails: LayoutElementDetails;
    if (!isLogoShown) {
      logoDetails = {
        bounds: JRect.EMPTY,
        staticPosition: PageElementPosition.Unset
      };
    } else {
      const document: DocumentDto =
        Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];

      let size: JSize;
      if (isExport) {
        size = await LogoAsImageProvider.getExportLogoSize();
      } else {
        size = await LegendLayoutUtils.getRenderedImageSize(img);
      }

      const position = LegendLayoutUtils.pageElementPositionToPoint(
        document.logoPosition,
        size
      );

      const bounds = new JRect(
        position.x,
        position.y,
        size?.width / parentSize.width,
        size?.height / parentSize.height
      );

      logoDetails = {
        bounds,
        staticPosition: document.logoPosition
      };
    }

    this.logoDetails = logoDetails;
    return logoDetails;
  }

  public static getMaxLegendHeightInPx(
    layout: LayoutForView,
    newPosition: JPoint
  ): number | null {
    const legendDetails = LegendLayoutUtils.getLegendDetails(layout);
    const logoHeightPx =
      this.logoDetails.bounds.height * this.parentSize.height;

    let shouldShrink = true;
    const documentView: DocumentView =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`];
    if (documentView === DocumentView.Web) {
      shouldShrink = LegendLayoutUtils.isPositionSameSideVertical(
        legendDetails.staticPosition,
        this.logoDetails.staticPosition
      );
    } else {
      switch (this.logoDetails.staticPosition) {
        case PageElementPosition.TopLeft:
        case PageElementPosition.BottomLeft:
          shouldShrink = newPosition.x < this.logoDetails.bounds.maxX;
          break;
        case PageElementPosition.BottomRight:
        case PageElementPosition.TopRight:
          shouldShrink =
            newPosition.x + legendDetails.bounds.width >
            this.logoDetails.bounds.x;
          break;
      }
    }

    if (
      legendDetails.bounds.height + this.logoDetails.bounds.height > 0.99 &&
      shouldShrink
    ) {
      const legendHeightPx =
        legendDetails.bounds.height * this.parentSize.height;
      const diff = legendHeightPx + logoHeightPx - this.parentSize.height;

      return Math.floor(legendHeightPx - diff);
    }

    return null;
  }

  public static getLegendDetails(layout: LayoutForView): LayoutElementDetails {
    return {
      staticPosition: layout.staticPosition,
      bounds: new JRect(
        layout.position?.x ?? 0,
        layout.position?.y ?? 0,
        layout.size?.width ?? 0,
        layout.size?.height ?? 0
      )
    };
  }

  public static preventLogoOverlap(layout: LayoutForView): JPoint {
    const legendDetails = LegendLayoutUtils.getLegendDetails(layout);
    const logoDetails = this.logoDetails;

    let { x, y } = legendDetails.bounds;
    if (
      logoDetails.bounds.toYFiles().intersects(legendDetails.bounds.toYFiles())
    ) {
      switch (logoDetails.staticPosition) {
        case PageElementPosition.TopLeft: {
          if (legendDetails.bounds.x < 0.01 && legendDetails.bounds.y < 0.01) {
            x = 0;
            y = logoDetails.bounds.maxY;
          } else {
            const xDiff = logoDetails.bounds.maxX - legendDetails.bounds.x;
            const yDiff = logoDetails.bounds.maxY - legendDetails.bounds.y;

            if (xDiff > yDiff) {
              y = logoDetails.bounds.maxY;
            } else {
              x = logoDetails.bounds.maxX;
            }
          }
          break;
        }
        case PageElementPosition.TopRight: {
          const xDiff = legendDetails.bounds.maxX - logoDetails.bounds.x;
          const yDiff = logoDetails.bounds.maxY - legendDetails.bounds.y;

          if (
            legendDetails.bounds.maxX - logoDetails.bounds.maxX <
              1 / this.parentSize.width &&
            legendDetails.bounds.y * this.parentSize.width -
              logoDetails.bounds.y * this.parentSize.width <
              1 / this.parentSize.height
          ) {
            x = logoDetails.bounds.maxX - legendDetails.bounds.width;
            y = logoDetails.bounds.maxY;
          } else {
            if (xDiff > yDiff) {
              y = logoDetails.bounds.maxY;
            } else {
              x = logoDetails.bounds.x - legendDetails.bounds.width;
            }
          }
          break;
        }
        case PageElementPosition.BottomRight: {
          if (
            legendDetails.bounds.height + logoDetails.bounds.height >= 1 ||
            legendDetails.bounds.maxX >= logoDetails.bounds.center.x
          ) {
            x = logoDetails.bounds.maxX - legendDetails.bounds.width;
            y = logoDetails.bounds.y - legendDetails.bounds.height;
          } else {
            const xDiff = legendDetails.bounds.maxX - logoDetails.bounds.x;
            const yDiff = legendDetails.bounds.maxY - logoDetails.bounds.y;

            if (xDiff < yDiff) {
              x = logoDetails.bounds.x - legendDetails.bounds.width;
            } else {
              y = logoDetails.bounds.y - legendDetails.bounds.height;
            }
          }
          break;
        }
        case PageElementPosition.BottomLeft: {
          if (
            legendDetails.bounds.height + logoDetails.bounds.height >= 1 ||
            legendDetails.bounds.x <= logoDetails.bounds.center.x
          ) {
            x = 0;
            y = logoDetails.bounds.y - legendDetails.bounds.height;
          } else {
            const xDiff = logoDetails.bounds.maxX - legendDetails.bounds.x;
            const yDiff = legendDetails.bounds.maxY - logoDetails.bounds.y;

            if (xDiff > yDiff) {
              y = logoDetails.bounds.y - legendDetails.bounds.height;
            } else {
              x = logoDetails.bounds.maxX;
            }
          }
          break;
        }
      }

      layout.staticPosition = PageElementPosition.Free;
    }

    return new JPoint(x, y);
  }

  public static pageElementPositionToPoint(
    position: PageElementPosition | JPoint,
    elementSize: JSize,
    parentSize?: JSize
  ): JPoint {
    if (position instanceof JPoint) {
      return position;
    } else {
      parentSize = parentSize ?? this.parentSize;

      switch (position) {
        case PageElementPosition.TopLeft:
          return JPoint.ORIGIN;
        case PageElementPosition.Top:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width / 2,
            0
          );
        case PageElementPosition.Right: {
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            (parentSize.height - elementSize.height) / parentSize.height / 2
          );
        }
        case PageElementPosition.TopRight:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            0
          );
        case PageElementPosition.BottomRight:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.Bottom:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width / 2,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.BottomLeft:
          return new JPoint(
            0,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.Left: {
          return new JPoint(
            0,
            (parentSize.height - elementSize.height) / 2 / parentSize.height
          );
        }
        default:
          return JPoint.ORIGIN;
      }
    }
  }

  public static isPositionLeftSide(position: PageElementPosition): boolean {
    return (
      position === PageElementPosition.TopLeft ||
      position === PageElementPosition.Left ||
      position === PageElementPosition.BottomLeft
    );
  }

  public static isPositionRightSide(position: PageElementPosition): boolean {
    return (
      position === PageElementPosition.TopRight ||
      position === PageElementPosition.Right ||
      position === PageElementPosition.BottomRight
    );
  }

  public static isPositionSameSideVertical(
    positionA: PageElementPosition,
    positionB: PageElementPosition
  ): boolean {
    return (
      (LegendLayoutUtils.isPositionLeftSide(positionA) &&
        LegendLayoutUtils.isPositionLeftSide(positionB)) ||
      (LegendLayoutUtils.isPositionRightSide(positionA) &&
        LegendLayoutUtils.isPositionRightSide(positionB))
    );
  }

  private static getFallbackPosition(): JPoint {
    switch (ThemeService.legendPosition) {
      case PageElementPosition.TopRight:
        return new JPoint(1, 0);
      case PageElementPosition.BottomRight:
        return new JPoint(1, 1);
      case PageElementPosition.BottomLeft:
        return new JPoint(0, 1);
      default:
        return new JPoint(0, 0);
    }
  }

  public static legendPositionToPoint(
    position: PageElementPosition | JPoint,
    legendSize = JSize.EMPTY,
    parentSize?: JSize
  ): JPoint {
    const fallbackPosition = LegendLayoutUtils.getFallbackPosition();
    if (!position) {
      return fallbackPosition;
    }

    if ((position as any)?.x !== 1 || (position as any)?.y !== 1) {
      if (position instanceof JPoint) {
        return position;
      } else if (!isNil((position as any).x) && !isNil((position as any).y)) {
        // De-serialized JPoint becomes an object - convert it back to JPoint
        return new JPoint((position as any).x, (position as any).y);
      }
    }

    if (legendSize.isEmpty) {
      const legendEl = LegendHtmlUtils.getDiagramLegend();
      if (legendEl) {
        legendSize = new JSize(
          parseInt(legendEl.dataset['elementWidth']) || 0,
          parseInt(legendEl.dataset['elementHeight']) || 0
        );
      }
    }

    let p: JPoint;
    if ((position as any)?.x === 1 || (position as any)?.y === 1) {
      p = LegendLayoutUtils.pageElementPositionToPoint(
        ThemeService.legendPosition ?? PageElementPosition.Unset,
        legendSize,
        parentSize
      );
    } else {
      p = LegendLayoutUtils.pageElementPositionToPoint(
        position,
        legendSize,
        parentSize
      );
    }

    if (Number.isNaN(p.x) || Number.isNaN(p.y)) {
      return fallbackPosition;
    }
    return p;
  }

  private static setLegendWidth(
    minWidthPx: number,
    maxWidthPx: number,
    staticPosition: PageElementPosition
  ): string {
    const logoDetails = this.logoDetails;
    const parentSize = this.parentSize;
    const logoSize = new JSize(
      logoDetails.bounds.width * parentSize.width,
      logoDetails.bounds.height * parentSize.height
    );

    switch (staticPosition) {
      case PageElementPosition.Top:
      case PageElementPosition.TopLeft:
      case PageElementPosition.TopRight:
        if (
          (logoDetails.staticPosition === PageElementPosition.TopLeft ||
            logoDetails.staticPosition === PageElementPosition.TopRight) &&
          maxWidthPx + logoSize.width >= parentSize.width
        ) {
          return `${parentSize.width - logoSize.width}px`;
        }
        break;
      case PageElementPosition.Bottom:
      case PageElementPosition.BottomRight:
      case PageElementPosition.BottomLeft:
        if (
          (logoDetails.staticPosition === PageElementPosition.BottomRight ||
            logoDetails.staticPosition === PageElementPosition.BottomLeft) &&
          maxWidthPx + logoSize.width >= parentSize.width
        ) {
          return `${parentSize.width - logoSize.width}px`;
        }
        break;
      case PageElementPosition.Left:
      case PageElementPosition.Right:
        return `${minWidthPx}px`;
    }
    return `${maxWidthPx}px`;
  }

  private static setLegendPosition(
    minWidthPx: number,
    maxWidthPx: number,
    staticPosition: PageElementPosition,
    legendElement: HTMLElement
  ): { position: JPoint; height: number } {
    const logoDetails = this.logoDetails;
    const parentSize = this.parentSize;
    let legendHeight = Math.floor(
      Math.min(legendElement.offsetHeight, parentSize.height)
    );

    const logoSize = new JSize(
      logoDetails.bounds.width * parentSize.width,
      logoDetails.bounds.height * parentSize.height
    );

    if (
      LegendLayoutUtils.isPositionSameSideVertical(
        staticPosition,
        logoDetails.staticPosition
      ) &&
      legendHeight + logoSize.height > parentSize.height
    ) {
      legendHeight = parentSize.height - logoSize.height;
    }

    let x = 0;
    let y = 0;
    switch (staticPosition) {
      case PageElementPosition.TopLeft:
        if (logoDetails.staticPosition === PageElementPosition.TopLeft) {
          x = logoSize.width;
        }
        break;
      case PageElementPosition.Top:
        x = parentSize.width / 2 - maxWidthPx / 2;

        if (logoDetails.staticPosition === PageElementPosition.TopRight) {
          x = Math.min(
            parentSize.width - logoSize.width,
            (parentSize.width - logoSize.width) / 2 - maxWidthPx / 2
          );
        } else if (logoDetails.staticPosition === PageElementPosition.TopLeft) {
          x = Math.max(logoSize.width, x);
        }
        break;
      case PageElementPosition.TopRight:
        x = parentSize.width - maxWidthPx;

        if (logoDetails.staticPosition === PageElementPosition.TopRight) {
          x -= logoSize.width;
        } else if (logoDetails.staticPosition === PageElementPosition.TopLeft) {
          x = Math.max(logoSize.width, x);
        }
        break;
      case PageElementPosition.Right:
        y = parentSize.height / 2 - legendHeight / 2;
        x = parentSize.width - minWidthPx;

        if (logoDetails.staticPosition === PageElementPosition.TopRight) {
          y = Math.max(
            logoSize.height,
            (parentSize.height - logoSize.height) / 2 - legendHeight / 2
          );
        } else if (
          logoDetails.staticPosition === PageElementPosition.BottomRight
        ) {
          y = Math.min(
            y,
            (parentSize.height - logoSize.height) / 2 - legendHeight / 2
          );
        }

        break;
      case PageElementPosition.BottomRight:
        x = parentSize.width - maxWidthPx;
        y = parentSize.height - legendHeight;

        if (logoDetails.staticPosition === PageElementPosition.BottomRight) {
          if (maxWidthPx + logoSize.width < parentSize.width) {
            x -= logoSize.width;
          }
        } else if (
          logoDetails.staticPosition === PageElementPosition.BottomLeft
        ) {
          x = Math.max(logoSize.width, x);
        }
        break;
      case PageElementPosition.Bottom:
        y = parentSize.height - legendHeight;
        x = parentSize.width / 2 - maxWidthPx / 2;

        if (logoDetails.staticPosition === PageElementPosition.BottomRight) {
          x = Math.min(
            parentSize.width - logoSize.width,
            (parentSize.width - logoSize.width) / 2 - maxWidthPx / 2
          );
        } else if (
          logoDetails.staticPosition === PageElementPosition.BottomLeft
        ) {
          x = Math.max(logoSize.width, x);
          if (maxWidthPx + logoSize.width >= parentSize.width) {
            x = Math.max(
              logoSize.width,
              (parentSize.width - logoSize.width) / 2 - maxWidthPx / 2
            );
          }
        }
        break;
      case PageElementPosition.BottomLeft:
        y = parentSize.height - legendHeight;
        if (logoDetails.staticPosition === PageElementPosition.BottomLeft) {
          x = Math.max(logoSize.width, x);
        }
        break;
      case PageElementPosition.Left:
        y = parentSize.height / 2 - legendHeight / 2;

        if (logoDetails.staticPosition === PageElementPosition.TopLeft) {
          y = Math.max(
            logoSize.height,
            (parentSize.height - logoSize.height) / 2 - legendHeight / 2
          );
        } else if (
          logoDetails.staticPosition === PageElementPosition.BottomLeft
        ) {
          y = Math.min(
            y,
            (parentSize.height - logoSize.height) / 2 - legendHeight / 2
          );
        }

        break;
    }

    return { position: new JPoint(x, y), height: legendHeight };
  }

  private static getRenderedLegendHeight(legendEl: HTMLElement): number {
    let height = LegendHtmlUtils.getLegendHeader(legendEl)?.clientHeight ?? 0;
    const separatorEl = LegendHtmlUtils.getDiagramLegendSeparator(legendEl);
    if (separatorEl) {
      const style = getComputedStyle(separatorEl);
      const marginBottom = parseFloat(style.marginBottom);
      const borderTop = parseFloat(style.borderTop);

      if (!Number.isNaN(marginBottom)) {
        height += marginBottom;
      }

      if (!Number.isNaN(borderTop)) {
        height += borderTop;
      }
      height += separatorEl.clientHeight;
    }

    height +=
      LegendHtmlUtils.getLegendItemsContainer(legendEl)?.clientHeight ?? 0;

    const hiddenItems = LegendHtmlUtils.getHiddenLegendItems(legendEl);
    if (hiddenItems) {
      const headerEl = LegendHtmlUtils.getLegendHeader(hiddenItems);
      const style = getComputedStyle(headerEl);
      const marginTop = parseFloat(style.marginTop);
      const marginBottom = parseFloat(style.marginBottom);
      height += hiddenItems.clientHeight + marginTop + marginBottom;
    }

    return height;
  }

  public static setStyleForStaticPosition(
    staticPosition: PageElementPosition,
    legendStyle: CSSProperties,
    minWidthPx: number,
    maxWidthPx: number
  ): Promise<void> {
    const legendEl = LegendHtmlUtils.getDiagramLegend() as HTMLElement;
    if (!legendEl || !this.logoDetails || !this.parentSize) {
      return;
    }

    return new Promise((resolve) => {
      legendStyle.width = `${maxWidthPx}px`;
      legendStyle.maxHeight = '100%';
      legendStyle.height = 'auto';
      legendStyle.opacity = 0.1;

      if (
        staticPosition == PageElementPosition.Left ||
        staticPosition === PageElementPosition.Right
      ) {
        legendStyle.width = `${minWidthPx}px`;
      }

      legendStyle.width = LegendLayoutUtils.setLegendWidth(
        minWidthPx,
        maxWidthPx,
        staticPosition
      );

      const renderedHeight =
        LegendLayoutUtils.getRenderedLegendHeight(legendEl);

      legendEl.style.height = `${renderedHeight}px`;

      const data = LegendLayoutUtils.setLegendPosition(
        minWidthPx,
        maxWidthPx,
        staticPosition,
        legendEl
      );

      legendStyle.height = `${data.height}px`;
      legendStyle.transform = `translate(${Math.max(
        0,
        data.position.x
      )}px, ${Math.max(0, data.position.y)}px)`;

      legendStyle.height = `${data.height}px`;
      legendStyle.opacity = 1;

      resolve();
    });
  }

  public static getStaticPositionPoints(): LegendPositionEntry[] {
    const arr: LegendPositionEntry[] = [];
    Object.keys(PageElementPosition).forEach((position) => {
      const staticPosition = parseInt(position);
      if (!staticPosition || Number.isNaN(staticPosition)) return;

      const point = LegendLayoutUtils.legendPositionToPoint(staticPosition);
      arr.push({ point, static: staticPosition });
    });
    return arr;
  }

  public static updatePagesWithLegendPositionFromTheme(
    document: DocumentDto
  ): void {
    const diagramPages = document.pages.filter(
      (p) =>
        !!p.diagram && p.contentType !== DocumentPageContentType.MasterLegend
    );
    for (const page of diagramPages) {
      const definition = LegendSerializer.deserializeFromDiagram(page.diagram);
      if (definition) {
        const parentSize = ExportUtils.calculateBodyPartSize(
          document,
          page,
          0,
          'diagram'
        ).multiply(ExportConfig.pointToPixelFactor);

        LegendLayoutUtils.setPositionFromTheme(
          definition.options as LegendOptions,
          false,
          page.diagram.diagramViews.selectedView === DocumentView.Print,
          new JSize(
            definition.options.layout[1].size.width * parentSize.width,
            definition.options.layout[1].size.height * parentSize.height
          ),
          parentSize
        );
      }
      page.diagram.legend = LegendSerializer.serializeDefinition(definition);
      DiagramChangeHandler.invalidateDiagramCache(page.diagram);
    }
    EventBus.$emit(
      EventBusActions.DIAGRAM_LEGEND_THEME_POSITION_APPLIED,
      diagramPages
    );
  }
}
