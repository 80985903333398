export default [
  'Arial',
  'Arial Narrow',
  'Calibri',
  'Calibri Light',
  'Courier New',
  'Ebrima',
  'Minion Pro',
  'Georgia',
  'Trebuchet MS',
  'Times New Roman',
  'Verdana'
];
