import {
  INode,
  IEdge,
  ILayoutAlgorithm,
  BaseClass,
  LayoutGraph,
  LayoutStageBase,
  LayoutGraphHider,
  LayoutGraphAdapter,
  YNodeList,
  EdgeList
} from 'yfiles';

export default class HiddenObjectLayoutStage extends BaseClass<LayoutStageBase>(
  LayoutStageBase
) {
  constructor(
    coreLayout: ILayoutAlgorithm,
    options: {
      nodePredicate?: (item: INode) => boolean;
      edgePredicate?: (item: IEdge) => boolean;
    }
  ) {
    super(coreLayout);
    this.nodePredicate = options?.nodePredicate ?? null;
    this.edgePredicate = options?.edgePredicate ?? null;
  }

  private nodePredicate: (item: INode) => boolean;
  private edgePredicate: (item: IEdge) => boolean;
  public applyLayout(graph: LayoutGraph): void {
    const hider = new LayoutGraphHider(graph);
    this.hideNodes(graph, hider);
    this.hideEdges(graph, hider);
    this.applyLayoutCore(graph);
    hider.unhideAll();
  }

  private hideNodes(graph: LayoutGraph, hider: LayoutGraphHider): void {
    if (!this.nodePredicate) {
      return;
    }
    const originalNodeDpKey = graph.getDataProvider(
      LayoutGraphAdapter.ORIGINAL_NODE_DP_KEY
    );
    const hiddenNodes = graph.nodes
      .filter((node) => this.nodePredicate(originalNodeDpKey.get(node)))
      .toArray();

    if (hiddenNodes.length == 0) {
      return;
    }

    hider.hide(new YNodeList(hiddenNodes));
  }

  private hideEdges(graph: LayoutGraph, hider: LayoutGraphHider): void {
    if (!this.edgePredicate) {
      return;
    }
    const originalEdgeDpKey = graph.getDataProvider(
      LayoutGraphAdapter.ORIGINAL_EDGE_DP_KEY
    );
    const hiddenEdges = graph.edges
      .filter((edge) => this.edgePredicate(originalEdgeDpKey.get(edge)))
      .toArray();

    if (hiddenEdges.length == 0) {
      return;
    }

    hider.hide(new EdgeList(hiddenEdges));
  }
}
