import JRect from '@/core/common/JRect';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import { stripHtml } from '@/core/utils/html.utils';
import LayoutItem from './LayoutItem';
import { LayoutItemType } from './LayoutItemType';

export default class HtmlLayoutItem extends LayoutItem {
  public static readonly padding: number = 8;
  public html: string = '';
  public isTextEditable: boolean = true;
  public isTextStyleEditable: boolean = true;

  constructor(type: LayoutItemType) {
    super(type);
    this.padding = HtmlLayoutItem.padding;
  }

  public get isReadonly(): boolean {
    return !this.isTextStyleEditable && !this.isTextEditable;
  }

  public get isEmpty(): boolean {
    const itemText = stripHtml(this.html);
    return (
      !itemText ||
      itemText.length == 0 ||
      (itemText.length == 1 && itemText == ZERO_WIDTH_SPACE)
    );
  }

  public serialize(): Object {
    const obj = super.serialize();
    obj['html'] = this.html?.replaceAll(ZERO_WIDTH_SPACE, '');
    return obj;
  }

  public setLayout(layout: JRect): void {
    this.layout = layout;
  }
}
