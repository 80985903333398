import { IGraph, IModelItem, Mapper } from 'yfiles';
import Vue from 'vue';
import { IEdgeRouting } from '../IDiagramTypeHelper';
import { DOCUMENT_NAMESPACE, GET_READONLY } from '../store/document.module';
import IDisposable from '@/core/common/IDisposable';
import SystemEntityTypes from '../corporate/SystemEntityTypes';
import { ModelItemRelationship } from '@/components/DataLinks/IRelationshipProvider';
import CorporateDiagramHelperBase from '../corporate/CorporateDiagramHelperBase';
import CorporateDiagramEdgeRouting from '../corporate/CorporateDiagramEdgeRouting';
import { RelationshipType } from '@/api/models';

export class BackgroundCorporateDiagramHelper extends CorporateDiagramHelperBase {
  private objectsToDispose: IDisposable[] = [];
  private initialized = false;
  private _graph: IGraph = null;
  public routing: IEdgeRouting = null;
  public edgeNameMapping: Mapper<RelationshipType, string> = null;

  public get graph(): IGraph {
    return this._graph;
  }

  dispose(): void {
    if (this.isDisposed) return;
    this.objectsToDispose.forEach((o) => o.dispose());

    this.isDisposed = true;
  }

  public init(graph: IGraph): void {
    if (this.initialized) {
      return;
    }

    this._graph = graph;
    this.routing = new CorporateDiagramEdgeRouting(this);
    this.objectsToDispose.push(this.routing);

    this.initialized = true;

    this.edgeNameMapping = new Mapper<RelationshipType, string>();
    ('');

    this.edgeNameMapping.set(
      RelationshipType.Ownership,
      SystemEntityTypes.EDGE_OWNERSHIP
    );
    this.edgeNameMapping.set(
      RelationshipType.Contract,
      SystemEntityTypes.EDGE_CONTRACT
    );
    this.edgeNameMapping.set(
      RelationshipType.Cashflow,
      SystemEntityTypes.EDGE_CASHFLOW
    );
    const isReadonly = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;

    if (isReadonly) {
      return;
    }
  }

  getModelItemRelationships(
    primaryItem: IModelItem,
    items: IModelItem[]
  ): ModelItemRelationship[] {
    throw new Error('Method not implemented.');
  }
}
