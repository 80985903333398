import {
  ArrowStyleDto,
  CompositeItemDto,
  CompositeItemType,
  CompositeNodeStyleDto,
  CompositePathItemStyleDto,
  CompositeShapeItemStyleDto,
  DashStyleDto,
  DashStyleType,
  DividingLineNodeStyleDto,
  EdgeStyleDto,
  EdgeVisualType,
  ElementType,
  FillType,
  IFillDto,
  ImageNodeStyleDto,
  INodeStyleDto,
  JigsawPathShapeNodeStyleDto,
  LinearGradientDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  SolidColorFillDto,
  StrokeDto,
  TextBoxNodeStyleDto,
  ThemeElementDto
} from '@/api/models';
import {
  ArcEdgeStyle,
  Arrow,
  ArrowType,
  DashStyle,
  Fill,
  GradientSpreadMethod,
  GradientStop,
  IEdgeStyle,
  ILabelStyle,
  ImageNodeStyle,
  INode,
  INodeStyle,
  LinearGradient,
  PolylineEdgeStyle,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  SolidColorFill,
  Stroke
} from 'yfiles';
import { JigsawShapeNodeStyleRenderer } from '../styles/JigsawShapeNodeStyleRenderer';
import DiagramUtils from './DiagramUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import { JigsawArcEdgeStyleRenderer } from '@/core/services/graph/renderers/JigsawArcEdgeStyleRenderer';
import { JigsawPolylineEdgeStyleRenderer } from '@/core/services/graph/renderers/JigsawPolylineEdgeStyleRenderer';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import JigsawNodeDecorator from '../styles/decorators/JigsawNodeDecorator';
import { JigsawImageNodeStyleRenderer } from '@/core/styles/JigsawImageNodeStyleRenderer';
import JurisdictionDecorator from '../styles/decorators/JurisdictionDecorator';
import IndicatorDecorators from '../styles/decorators/IndicatorDecorators';
import HighlightDecorator from '../styles/decorators/HighlightDecorator';
import FilterDecorators from '../styles/decorators/FilterDecorators';
import defaultArrowStyle from '../config/defaultArrowStyle';
import JigsawRichTextLabelStyle from '../styles/JigsawRichTextLabelStyle';
import TextBoxNodeStyle from '../styles/TextBoxNodeStyle';
import DividingLineNodeStyle from '../styles/DividingLineNodeStyle';
import JigsawPathShapeNodeStyle from '../styles/JigsawPathShapeNodeStyle';
import CompositeNodeStyle from '@/components/ShapeBuilder/CompositeNodeStyle';
import CompositeItem from '@/components/ShapeBuilder/CompositeItem';
import CompositePathItemStyle from '@/components/ShapeBuilder/CompositePathItemStyle';
import CompositeShapeItemStyle from '@/components/ShapeBuilder/CompositeShapeItemStyle';

/**
 * All methods on this class should take a Jigsaw Style Dto (EdgeStyleDto, NodeStyleDto or LabelStyleDto) and create a yFiles implementaiton of those style
 *
 */
export default class StyleCreator {
  public static createElementStyle(
    themeElement: ThemeElementDto
  ): IEdgeStyle | INodeStyle {
    switch (+themeElement.elementType) {
      case ElementType.Node:
        return StyleCreator.createNodeStyle(themeElement.style);
      case ElementType.Edge:
        return StyleCreator.createEdgeStyle(themeElement.style);
    }
    throw 'Unknown Element type';
  }

  public static createLabelStyle(): ILabelStyle {
    return new JigsawRichTextLabelStyle();
  }

  public static getNodeShape(nodeShape: NodeShape): ShapeNodeShape {
    const shape = diagramConfig.nodeShapes[nodeShape];
    if (typeof shape === 'undefined') {
      throw 'Unsupported node shape ' + nodeShape;
    }
    return shape;
  }

  public static getNodeShapeFromShapeNodeShape(
    shapeNodeShape: ShapeNodeShape
  ): NodeShape {
    const shape = diagramConfig.nodeShapes[shapeNodeShape];
    if (typeof shape === 'undefined') {
      throw 'Unsupported node shape ' + shapeNodeShape;
    }
    return shape;
  }

  public static getArrowType(arrowStyle: ArrowStyleDto): ArrowType {
    try {
      return defaultArrowStyle.find(
        (x) => x.name.toLowerCase() == arrowStyle.type.toLowerCase()
      ).type;
    } catch (error) {
      // TODO:
      return ArrowType.NONE;
    }
  }

  public static createNodeStyle(
    style: INodeStyleDto,
    decorators?: JigsawNodeDecorator[]
  ): JigsawNodeStyle {
    if (!style) {
      style = DiagramUtils.getSystemDefaultNodeStyle();
    }

    switch (Number(style.visualType)) {
      case NodeVisualType.Shape: {
        const shapeNodeStyleDto = style as ShapeNodeStyleDto;
        const fill = StyleCreator.createFill(shapeNodeStyleDto.fill);
        const stroke = StyleCreator.createStroke(shapeNodeStyleDto.stroke);

        const shapeNodeStyle = new ShapeNodeStyle({
          shape: StyleCreator.getNodeShape(shapeNodeStyleDto.shape),
          fill: fill,
          stroke: stroke,
          renderer: new JigsawShapeNodeStyleRenderer(shapeNodeStyleDto.shape)
        });
        return new JigsawNodeStyle(shapeNodeStyle, decorators);
      }
      case NodeVisualType.JigsawPathShape: {
        const JigsawPathShapeNodeStyleDto =
          style as JigsawPathShapeNodeStyleDto;
        const fill = StyleCreator.createFill(JigsawPathShapeNodeStyleDto.fill);
        const stroke = StyleCreator.createStroke(
          JigsawPathShapeNodeStyleDto.stroke
        );

        const shapeNodeStyle = new JigsawPathShapeNodeStyle({
          shape: JigsawPathShapeNodeStyleDto.shape,
          fill: fill,
          stroke: stroke
        });
        return new JigsawNodeStyle(shapeNodeStyle, decorators);
      }

      case NodeVisualType.Image: {
        const imageNodeStyleDto = style as ImageNodeStyleDto;
        const imageNodeStyle = new ImageNodeStyle({
          image: imageNodeStyleDto.imageUrl,
          renderer: new JigsawImageNodeStyleRenderer()
        });

        return new JigsawNodeStyle(imageNodeStyle, decorators);
      }
      case NodeVisualType.Composite: {
        const compositeNodeStyleDto = style as CompositeNodeStyleDto;
        const items: CompositeItem[] = StyleCreator.createCompositeItems(
          compositeNodeStyleDto.items
        );
        const baseStyle = new CompositeNodeStyle(
          items,
          compositeNodeStyleDto.shape
        );
        return new JigsawNodeStyle(baseStyle, decorators);
      }
      case NodeVisualType.TextBox: {
        const textBoxNodeStyleDto = style as TextBoxNodeStyleDto;
        const fill = textBoxNodeStyleDto.fill
          ? StyleCreator.createFill(textBoxNodeStyleDto.fill)
          : TextBoxNodeStyle.defaultOptions.fill;

        const stroke = textBoxNodeStyleDto.stroke
          ? StyleCreator.createStroke(textBoxNodeStyleDto.stroke)
          : TextBoxNodeStyle.defaultOptions.stroke;

        const textBoxNodeStyle = new TextBoxNodeStyle({
          fill: fill,
          stroke: stroke
        });
        return new JigsawNodeStyle(textBoxNodeStyle, decorators);
      }

      case NodeVisualType.DividingLine: {
        const dividingLineNodeStyleDto = style as DividingLineNodeStyleDto;

        const nodeStyle = new DividingLineNodeStyle({
          type: dividingLineNodeStyleDto.lineType,
          stroke: StyleCreator.createStroke(dividingLineNodeStyleDto.stroke)
        });
        return new JigsawNodeStyle(nodeStyle, decorators);
      }
    }
    throw `Unknown node visual type ${style.visualType}`;
  }

  public static createCompositeItems(
    compositeItemDtos: CompositeItemDto[]
  ): CompositeItem[] {
    return compositeItemDtos.map((item) => {
      // create new layout rect, values ranging between 0-1 (percentage along their respective axis)
      let rect: Rect = null;
      if (item.layout == null) {
        rect = new Rect(0, 0, 1, 1);
        console.warn('CompositeItem layout null - falling back to full size');
      } else {
        rect = new Rect(
          item.layout.x,
          item.layout.y,
          item.layout.width,
          item.layout.height
        );
      }

      let style: CompositeShapeItemStyle | CompositePathItemStyle = null;
      if (item.type == CompositeItemType.Shape) {
        const itemStyle = item.style as CompositeShapeItemStyleDto;

        // CompositeShapeItemStyle
        style = {
          fill: StyleCreator.createFill(itemStyle.fill),
          stroke: StyleCreator.createStroke(itemStyle.stroke),
          shape: itemStyle.shape,
          angle: itemStyle.angle
        };
      } else if (item.type == CompositeItemType.Path) {
        const itemStyle = item.style as CompositePathItemStyleDto;

        // CompositePathItemStyle
        style = {
          fill: StyleCreator.createFill(itemStyle.fill),
          stroke: StyleCreator.createStroke(itemStyle.stroke),
          path: itemStyle.path,
          angle: itemStyle.angle
        };
      }

      //CompositeItem
      return {
        layout: rect,
        style: style,
        type: item.type
      };
    });
  }

  public static createArrow(arrowStyle: ArrowStyleDto, stroke: Stroke): Arrow {
    if (arrowStyle == null || !arrowStyle.type) {
      arrowStyle = {
        type: 'none',
        scale: 1.0,
        fill: {
          color: '#FCC238',
          type: FillType.SolidColor
        } as SolidColorFillDto
      };
    }
    const arrowType: ArrowType = StyleCreator.getArrowType(arrowStyle);

    return new Arrow({
      type: arrowType,
      fill: arrowStyle.fill ? this.createFill(arrowStyle.fill) : stroke.fill,
      // 34180 set a min scale of 1;
      // In some instances, if the theme was created before this fix, the scale
      // could be less than 1. If we force the fix here (Math.max...), it will
      // generate different keys for the legend which means the edited labels will be lost
      scale: +arrowStyle.scale
    });
  }
  public static JigsawPolylineEdgeStyleRenderer =
    new JigsawPolylineEdgeStyleRenderer();
  public static createEdgeStyle(style: EdgeStyleDto): IEdgeStyle {
    if (!style) {
      style = DiagramUtils.getSystemDefaultEdgeStyle();
    }
    const stroke = StyleCreator.createStroke(style.stroke);
    const targetArrow = StyleCreator.createArrow(style.targetArrow, stroke);
    const sourceArrow = StyleCreator.createArrow(style.sourceArrow, stroke);
    const height = style.height ?? diagramConfig.grid.size * 2;

    const visualType = style.visualType;
    switch (visualType) {
      case EdgeVisualType.Curved: {
        const style = new PolylineEdgeStyle({
          renderer: StyleCreator.JigsawPolylineEdgeStyleRenderer,
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow
        });
        style.smoothingLength = diagramConfig.grid.size * 2;
        return style;
      }
      case EdgeVisualType.Arc:
        return new ArcEdgeStyle({
          renderer: new JigsawArcEdgeStyleRenderer(),
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow,
          height: height,
          fixedHeight: true
        });
      case EdgeVisualType.Straight:
      case EdgeVisualType.Elbow:
        return new PolylineEdgeStyle({
          renderer: StyleCreator.JigsawPolylineEdgeStyleRenderer,
          stroke: stroke,
          targetArrow: targetArrow,
          sourceArrow: sourceArrow
        });
      default:
        console.warn('Unknown egde visual type');
    }
  }

  public static createFill(fill: IFillDto): Fill {
    return fill.type == FillType.SolidColor || !fill.type
      ? new SolidColorFill((fill as SolidColorFillDto).color)
      : new LinearGradient({
          startPoint: (fill as LinearGradientDto).startPoint,
          endPoint: (fill as LinearGradientDto).endPoint,
          spreadMethod: (fill as LinearGradientDto)
            .spreadMethod as unknown as GradientSpreadMethod,
          gradientStops: (fill as LinearGradientDto)?.gradientStops?.map(
            (gradientStop) =>
              new GradientStop({
                color: gradientStop.color,
                offset: gradientStop.offset
              })
          )
        });
  }

  public static createDashStyle(dashStyle: DashStyleDto): DashStyle {
    if (dashStyle.dashes) {
      return new DashStyle(dashStyle.dashes, dashStyle.offset);
    }

    return StyleCreator.getDashStyle(dashStyle.type ?? DashStyleType.Solid);
  }

  public static getDashStyle(dashStyleType: DashStyleType): DashStyle {
    //Manual DashStyles for DashDot, DashDotDot and Dot as the defaults are not supported with a flat linecap
    switch (+dashStyleType) {
      case DashStyleType.Dash:
        return DashStyle.DASH;
      case DashStyleType.DashDot:
        return new DashStyle([3, 2, 1, 2]);
      case DashStyleType.DashDotDot:
        return new DashStyle([3, 2, 1, 2, 1, 2]);
      case DashStyleType.Dot:
        return new DashStyle([1, 3]);
      case DashStyleType.Solid:
        return DashStyle.SOLID;
    }
    // this is not unreachable code
    // ignore this error
    console.warn('Unknown dashstyle ');
    return DashStyle.SOLID;
  }

  public static createStroke(stroke: StrokeDto): Stroke {
    return new Stroke({
      fill: StyleCreator.createFill(stroke.fill),
      dashStyle: StyleCreator.createDashStyle(stroke.dashStyle),
      thickness: stroke.thickness
    });
  }

  public static getNodeDecorators(node: INode): JigsawNodeDecorator[] {
    if (!node || !node.tag) return [];
    const tag = node.tag;
    if (tag.isGroupNode) {
      return [];
    }
    if (DiagramUtils.isDividingLine(node)) {
      return [];
    }
    if (tag.isAnnotation) {
      if (DiagramUtils.isHatch(node) || DiagramUtils.isCross(node)) {
        return [
          IndicatorDecorators.INSTANCE,
          FilterDecorators.INSTANCE,
          JurisdictionDecorator.INSTANCE,
          HighlightDecorator.INSTANCE
        ];
      } else if (
        DiagramUtils.isClipArt(node) ||
        DiagramUtils.isAnnotationShape(node)
      ) {
        return [
          IndicatorDecorators.INSTANCE,
          FilterDecorators.INSTANCE,
          JurisdictionDecorator.INSTANCE,
          HighlightDecorator.INSTANCE
        ];
      } else {
        return [];
      }
    }

    return [
      IndicatorDecorators.INSTANCE,
      FilterDecorators.INSTANCE,
      JurisdictionDecorator.INSTANCE,
      HighlightDecorator.INSTANCE
    ];
  }
}
