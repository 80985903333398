import Vue from 'vue';
import {
  AttachmentType,
  DocumentDto,
  DocumentPageDto,
  PageElementPosition
} from '@/api/models';
import BackgroundDomService from '../../BackgroundDomService';
import CacheType from '../../caching/CacheType';
import CachingService from '../../caching/CachingService';
import ExportOptions from '../ExportOptions';
import ExportPage from '../ExportPage';
import ExportPageElement from '../ExportPageElement';
import AdditionalElementProvider from './AdditionalElementProvider';
import IDiagramLogo from '@/components/DiagramLogo/IDiagramLogo';
import IDiagramLogoProps from '@/components/DiagramLogo/IDiagramLogoProps';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  GET_SELECTED_PAGE
} from '@/core/services/store/document.module';
import LegendLayoutUtils from '@/components/DiagramLegend/LegendLayoutUtils';
import JSize from '@/core/common/JSize';

export default class LogoAsImageProvider extends AdditionalElementProvider {
  constructor(options: ExportOptions, exportPage: ExportPage) {
    super(options, exportPage);
  }

  public async get(): Promise<ExportPageElement[]> {
    const logo = this.options.document?.attachments?.find(
      (a) => a.attachmentType == AttachmentType.Logo
    );
    if (!logo) {
      return [];
    }

    const element = await this.exportOrGetFromCache(logo.fileAttachment.path);
    return [element];
  }

  private async exportOrGetFromCache(
    logoUrl: string
  ): Promise<ExportPageElement> {
    const cacheKey = CachingService.generateKey(
      CacheType.LogoExport,
      logoUrl,
      this.options.document.logoPosition ?? PageElementPosition.Unset
    );
    return CachingService.getOrSetMutexAsync(cacheKey, async () => {
      return { data: await this.export() };
    });
  }

  private async export(): Promise<ExportPageElement> {
    return await LogoAsImageProvider.exportLogo(
      this.options.document,
      this.exportPage.page
    );
  }

  public static async getExportLogoSize(): Promise<JSize> {
    const document: DocumentDto =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
    const page =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`];

    const logoExport = await LogoAsImageProvider.exportLogo(document, page);
    return LegendLayoutUtils.getRenderedImageSize(
      logoExport.element.querySelector('img')
    );
  }

  private static async exportLogo(
    document: DocumentDto,
    page: DocumentPageDto
  ): Promise<ExportPageElement> {
    const diagramLogoImport = await import(
      '@/components/DiagramLogo/DiagramLogo.vue'
    );
    const diagramLogoClass = Vue.extend(diagramLogoImport.default);
    const DiagramLogo = new diagramLogoClass({
      store: Vue.$globalStore,
      propsData: <IDiagramLogoProps>{
        document,
        page,
        pageScale: 1,
        isExport: true
      }
    }) as IDiagramLogo;

    DiagramLogo.$mount();
    await DiagramLogo.setLogo();

    const logoExport = DiagramLogo.export();

    // Add logo to document to render & apply styles
    const logoContainer = BackgroundDomService.createElement('div');
    logoContainer.style.position = 'absolute';
    logoContainer.append(logoExport.element);
    BackgroundDomService.appendElement(logoContainer);

    logoExport.destroy = (): void => {
      BackgroundDomService.removeElement(logoContainer);
    };

    DiagramLogo.$destroy();

    return logoExport;
  }
}
