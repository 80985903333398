import i18n from '@/core/plugins/vue-i18n';
import {
  CompositionBlockType,
  ICompositionBlock
} from '@/core/services/search/filters/CompositeFilter';

export const compositionBlockTypes: ICompositionBlock[] = [
  {
    value: CompositionBlockType.Or,
    label: i18n.t('OR'),
    description: i18n.t('COMPOSITE_TYPE_OR_DESCRIPTION')
  },
  {
    value: CompositionBlockType.And,
    label: i18n.t('AND'),
    description: i18n.t('COMPOSITE_TYPE_AND_DESCRIPTION')
  }
];
