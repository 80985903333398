<template>
  <ul class="flex select-none items-center">
    <PaginationItem :disabled="isFirst || disabled" @click="navToFirst">
      {{ $t('First') }}
    </PaginationItem>
    <PaginationItem
      :disabled="isFirst || disabled"
      @click="navToPage(currentPageSync - 1)"
    >
      {{ $t('Previous') }}
    </PaginationItem>
    <PaginationItem v-if="showPrevMore" :disabled="disabled">
      ...
    </PaginationItem>
    <PaginationItem
      v-for="page in pages"
      :key="page"
      :page="page"
      :active-page="currentPageSync"
      :disabled="disabled"
      @click="currentPageSync = page"
    >
      {{ page }}
    </PaginationItem>
    <PaginationItem v-if="showNextMore" :disabled="disabled">
      ...
    </PaginationItem>
    <PaginationItem
      :disabled="isLast || disabled"
      @click="navToPage(currentPageSync + 1)"
    >
      {{ $t('Next') }}
    </PaginationItem>
    <PaginationItem :disabled="isLast || disabled" @click="navToLast">
      {{ $t('Last') }}
    </PaginationItem>
  </ul>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

import PaginationItem from './PaginationItem.vue';

@Component({
  name: 'AppPagination',
  components: { PaginationItem }
})
export default class AppPagination extends Vue {
  @Model('change', { required: true, type: Number })
  currentPage: number;

  @Prop({ required: true, type: Number })
  totalRows: number;

  @Prop({ required: true, type: Number })
  perPage: number;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  maxVisibleButtons: number = 3;

  get totalPagesLength(): number {
    return Math.ceil(+this.totalRows / +this.perPage);
  }

  get pages(): number[] {
    const pages: number[] = [];

    for (let p = this.startPage; p <= this.endPage; p += 1) {
      pages.push(p);
    }

    return pages;
  }

  get currentPageSync(): number {
    if (this.currentPage > this.totalPagesLength) {
      return this.totalPagesLength;
    }

    return this.currentPage;
  }
  set currentPageSync(value: number) {
    if (value < 1 || value > this.totalPagesLength) {
      return;
    }

    this.$emit('change', value);
  }

  get isFirst() {
    return this.currentPage === 1;
  }

  get isLast() {
    return this.currentPage >= this.totalPagesLength;
  }

  get showPrevMore() {
    if (this.totalPagesLength > this.maxVisibleButtons) {
      if (this.startPage === 1) {
        return false;
      }

      return true;
    }

    return false;
  }

  get showNextMore() {
    if (this.totalPagesLength > this.maxVisibleButtons) {
      if (this.endPage === this.totalPagesLength) {
        return false;
      }

      return true;
    }

    return false;
  }

  get startPage() {
    if (this.totalPagesLength <= this.maxVisibleButtons) {
      return 1;
    }

    if (
      this.totalPagesLength <= this.maxVisibleButtons ||
      this.currentPage <= this.maxVisibleButtons
    ) {
      return 1;
    }

    if (this.currentPage > this.totalPagesLength - this.maxVisibleButtons) {
      return this.totalPagesLength - this.maxVisibleButtons;
    }

    return this.currentPage - 1;
  }

  get endPage() {
    if (this.totalPagesLength <= this.maxVisibleButtons) {
      return this.totalPagesLength;
    }

    if (this.currentPage <= this.maxVisibleButtons) {
      return this.maxVisibleButtons + 1;
    }

    if (this.currentPage >= this.totalPagesLength - this.maxVisibleButtons) {
      return this.totalPagesLength;
    }

    return this.currentPage + 1;
  }

  navToFirst(): void {
    this.currentPageSync = 1;
  }
  navToLast(): void {
    this.currentPageSync = this.totalPagesLength;
  }

  private navToPage(page: number): void {
    this.currentPageSync = page;
  }
}
</script>
