<template>
  <AppFlyout
    ref="flyoutRef"
    :placement="placement"
    :offset="offset"
    :offsetX="offsetX"
    :trigger="trigger"
    :max-width="maxWidth"
    :append-to-body="appendToBody"
    :disabled="disabled"
    :activator-class="activatorClass"
    :block-content="blockContent"
    :propagate="propagate"
    :same-or-wider="sameOrWider"
    :target-less="targetLess"
    :fallback-placements="fallbackPlacements"
    @visibilityChanged="$emit('visibility-changed', $event)"
  >
    <template #activator="{ isVisible }">
      <slot name="activator" :isVisible="isVisible" />
    </template>

    <div
      slot="popper"
      class="flex flex-col rounded-4 bg-white text-14 text-main-dark"
      :data-automation-id="popperAutomationId"
      :class="[
        popperClass,
        {
          'py-4 shadow-sm': $slots.default
        }
      ]"
      :style="styles"
    >
      <slot />
    </div>
  </AppFlyout>
</template>

<script lang="ts">
import { Vue, Component, Prop, Provide } from 'vue-property-decorator';

import AppFlyout, { Placement, FlyoutTriggers } from '../AppFlyout.vue';
import { CSSProperties } from 'vue/types/jsx';

@Component({
  name: 'AppDropdown'
})
export default class AppDropdown extends Vue {
  @Prop({ default: 'bottom' })
  placement: Placement;

  @Prop({ default: 'click' })
  trigger: FlyoutTriggers;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: null })
  minWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: null })
  activatorClass: string;

  @Prop({ default: 10, type: Number })
  offset: number;

  @Prop({ default: 0, type: Number })
  offsetX: number;

  @Prop({ default: false, type: Boolean })
  targetLess: boolean;

  /**
   * prevent click event bubbling outside the root element of the component
   */
  @Prop({ default: false, type: Boolean })
  propagate: boolean;

  @Prop({ default: false, type: Boolean })
  blockContent: boolean;

  @Prop({ default: false, type: Boolean })
  sameOrWider: boolean;

  @Prop({ default: '', type: String })
  popperClass: string;

  @Prop({ default: () => [], type: Array })
  fallbackPlacements: Array<Placement>;

  @Prop({ default: '', type: String })
  popperAutomationId: string;

  $refs: {
    flyoutRef: AppFlyout;
  };

  get styles(): CSSProperties {
    return { minWidth: `${this.minWidth}px` };
  }

  get flyoutRef(): AppFlyout {
    return this.flyoutRef;
  }

  show(evt?: MouseEvent): void {
    this.$refs.flyoutRef.show(evt);
  }

  hide(): void {
    this.$refs.flyoutRef.hide();
  }

  @Provide() private hideDropdown(): void {
    this.$refs.flyoutRef.hide();
  }
}
</script>
