import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import b64toBlob from '../../graph/b64ToBlob';
import { WordExportDto } from '@/api/models';
import WordExportApiService from '@/api/WordExportApiService';
import { OpenXmlExportType } from './open-xml-export/OpenXmlExportType';
import { OpenXmlExportRequestBuilder } from './open-xml-export/OpenXmlExportRequestBuilder';

export default class WordExportProvider implements IExportProvider {
  private _fileExtension = 'docx';
  private _mimeType =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const request = await this.getExportRequest(options);
    const response = await WordExportApiService.exportDocumentToWord(request);
    return {
      mimeType: this._mimeType,
      fileExtension: this._fileExtension,
      result: b64toBlob(response.data.result)
    };
  }

  private async getExportRequest(
    options: ExportOptions
  ): Promise<WordExportDto> {
    const builder = new OpenXmlExportRequestBuilder(
      OpenXmlExportType.Word,
      options
    );
    return await builder.getExportRequest();
  }
}
