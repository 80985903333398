import DiagramUtils from '@/core/utils/DiagramUtils';
import i18n from '@/core/plugins/vue-i18n';
import { IGraph, ILabelOwner, IModelItem, INode, IEdge } from 'yfiles';
import { DataPropertyDefinitionDto } from '@/api/models';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  GET_ALL_DATAPROPERTYDEFINITIONS
} from '../store/datapropertydefinitions.module';
import Vue from 'vue';

export default class ConfidentialUtils {
  public static CONFIDENTIAL_STRING = 'CONFIDENTIAL_UPPERCASE';
  public static redactConfidentialLabelsFromGraph(graph: IGraph): void {
    const replaceLabelWhereConfidential = (
      item: ILabelOwner,
      checkOnEmptyLabel?: boolean
    ): void => {
      const condition = checkOnEmptyLabel
        ? item.tag?.isConfidential && item.labels.firstOrDefault()?.text
        : item.tag?.isConfidential;

      if (condition) {
        this.setLabelAsConfidential(graph, item);
      }
    };
    graph.nodes.forEach((n) => {
      replaceLabelWhereConfidential(n);
    });
    graph.edges.forEach((e) => {
      replaceLabelWhereConfidential(e, true);
    });
  }

  public static setLabelAsConfidential(graph: IGraph, item: ILabelOwner): void {
    if (item instanceof INode || item instanceof IEdge) {
      const labelStyle =
        DiagramUtils.tryExtractLabelStyleFromOwner(item) ??
        DiagramUtils.getSystemDefaultLabelStyle();
      labelStyle.font.fontWeight = 'bold';

      const newLabel = DiagramUtils.getHtmlLabelContent(
        item,
        i18n.t(ConfidentialUtils.CONFIDENTIAL_STRING),
        labelStyle
      );
      DiagramUtils.setLabelValue(graph, item, newLabel);
    }
  }

  public static graphHasConfidentialData(graph: IGraph): boolean {
    if (!graph) return false;
    const graphItems = [...graph.nodes, ...graph.edges];

    //Check node tags
    const graphItemsAreConfidential = graphItems.some(
      (x) => x.tag.isConfidential
    );
    if (graphItemsAreConfidential) {
      return true;
    }

    //Check data property definitions
    const dataPropertyDefinitions: DataPropertyDefinitionDto[] =
      Vue.$globalStore.getters[
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${GET_ALL_DATAPROPERTYDEFINITIONS}`
      ];
    const graphHasConfidentialDefinitions = graphItems.some(
      (item) =>
        item.tag?.dataProperties?.filter((x) => {
          const dpDefinition = dataPropertyDefinitions.find(
            (dpd) => dpd.id === x.dataPropertyDefinitionId
          );
          return dpDefinition?.isConfidential || false;
        }).length > 0
    );
    if (graphHasConfidentialDefinitions) {
      return true;
    }
    return;
  }
}
