import axios, {
    AxiosResponse
} from 'axios';
import {
    GlobalAttachmentDto,
    ZadarResponse,
    CustomFontWithStylesDto
} from './models'
export default class CustomFontsApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GlobalAttachmentDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/CustomFonts/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getAllWithStyles(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CustomFontWithStylesDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/CustomFonts/GetAllWithStyles`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
}