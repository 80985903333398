import HtmlLayoutItem from '../../Items/HtmlLayoutItem';
import { LayoutItemType } from '../../Items/LayoutItemType';
import IContentTableLayoutItemOptions from './IContentTableLayoutItemOptions';
import { FontDto } from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { ContentTableItem } from './ContentTableItemType';
import ContentTableUtils from './ContentTableUtils';

export default class ContentTableLayoutItem extends HtmlLayoutItem {
  public isEditing: boolean;
  public items: Array<ContentTableItem>;
  public fontSet: FontDto;
  public spacing: 1 | 2 | 3 | 4 | 5;
  public showPageNumbers?: boolean;
  public showPrefix?: boolean;
  public autoUpdate?: boolean;
  public prefixType?: 'none' | 'number' | 'disc' | 'circle' | 'square';
  public itemTypeFilter?: 'heading' | 'chapter';
  public chapterTitle?: string;
  static readonly defaultX = 25;
  static readonly defaultY = 80;
  static readonly defaultPadding = 25;
  static readonly indentLevelMultiplier = 20;

  constructor(options?: IContentTableLayoutItemOptions) {
    super(LayoutItemType.ContentTable);
    this.html = options?.html;
    this.isEditing = false;
    this.items = options?.items || ContentTableUtils.getContentTableItems();
    this.autoUpdate = options?.autoUpdate || false;
    this.fontSet =
      (options?.fontSet as FontDto) || ContentTableUtils.defaultFont();
    this.spacing = options?.spacing || 1;
    this.showPageNumbers = options?.showPageNumbers;
    this.showPrefix = options?.showPrefix;
    this.prefixType = options?.prefixType || 'number';
    this.deletable = options.deletable;
    this.itemTypeFilter = options?.itemTypeFilter || 'heading';
    this.chapterTitle = options?.chapterTitle;
  }

  public serialize(): Object {
    const obj = super.serialize();
    obj['items'] = this.items;
    obj['fontSet'] = this.fontSet;
    obj['spacing'] = this.spacing;
    obj['showPageNumbers'] = this.showPageNumbers;
    obj['showPrefix'] = this.showPrefix;
    obj['prefixType'] = this.prefixType;
    obj['autoUpdate'] = this.autoUpdate;
    obj['itemTypeFilter'] = this.itemTypeFilter;
    obj['chapterTitle'] = this.chapterTitle;
    this.generateHtml();
    return obj;
  }

  public generateHtml(): void {
    this.html = ContentTableUtils.generateContentTableHtml(this);
  }

  public reset(): void {
    this.isEditing = false;
    this.autoUpdate = false;
    this.fontSet = ContentTableUtils.defaultFont();
    this.spacing = 1;
    this.showPageNumbers = true;
    this.showPrefix = true;
    this.prefixType = 'number';
  }
}
