import { INode } from 'yfiles';
import INodeTag from '../common/INodeTag';
import DecorationState from '../styles/DecorationState';
import JigsawNodeDecorator from '../styles/decorators/JigsawNodeDecorator';
import DiagramUtils from '../utils/DiagramUtils';

/**
 * Can be used to modify and retrive node @type DecorationStates for a @type INode @type INodeTag
 */
export default class DecorationStateManager {
  /**
   * Get the state
   * @param type The node decorator or type
   * @param target target node or tag
   */
  static getState<T>(type: JigsawNodeDecorator, target: INodeTag | INode): T {
    let name = type.$class;
    let tag = DecorationStateManager.getTag(target);
    let state = tag.decorationStates[name];
    if (!state) {
      let defaultStateFn =
        type['defaultState'] ??
        (() => {
          return {};
        });
      state = tag.decorationStates[name] = defaultStateFn.bind(type)();
    }
    return state as T;
  }

  /**
   * ensures that the given tag has it's decoration states initialised
   * @param tag The tag
   */
  static ensureState(tag: INodeTag): INodeTag {
    if (!tag) {
      throw 'No tag to get state';
    }

    if (!tag.decorationStates) {
      tag.decorationStates = {};
    }
    return tag;
  }

  /**
   * Given an @type NodeTag or @type INode will always return @type NodeTag
   * @returns NodeTag
   */
  private static getTag(target: INodeTag | INode): INodeTag {
    if (INode.isInstance(target)) {
      return DecorationStateManager.ensureState(target.tag as INodeTag);
    }

    return DecorationStateManager.ensureState(target as INodeTag);
  }
}
