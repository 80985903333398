import {
  DiagramNodeDto,
  PowerPointDiagramNodeDto,
  ImageNodeStyleDto,
  ShapeNodeStyleDto,
  SolidColorFillDto,
  FillType,
  StrokeDto,
  NodeShape,
  NodeSize,
  NodeVisualType,
  DiagramEdgeDto,
  WordExportPageDto,
  DiagramDto,
  NodeGroupDto,
  PowerPointExportSlideDto,
  CompositeItemType,
  CompositeNodeStyleDto,
  InsetsDto,
  LayoutDto,
  PowerPointDiagramEdgeDto
} from '@/api/models';
import JPoint from '@/core/common/JPoint';
import DecorationStateManager from '@/core/services/DecorationStateManager';
import EdgePortGenerator from '@/core/services/EdgePortGenerator';
import GroupingVisual, {
  GroupOptions
} from '@/core/services/graph/grouping/GroupingVisual';
import DataPropertiesDecorator from '@/core/styles/decorators/DataPropertiesDecorator';
import IndicatorDecorators, {
  IndicatorState
} from '@/core/styles/decorators/IndicatorDecorators';
import JurisdictionDecorator, {
  JurisdictionDecorationState
} from '@/core/styles/decorators/JurisdictionDecorator';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  getImageSize,
  convertImageSrcToPng,
  convertSvgToImage,
  RgbaToHex
} from '@/core/utils/common.utils';
import {
  Size,
  INode,
  GraphComponent,
  ShapeNodeShape,
  IEdge,
  IGraph,
  IRenderContext,
  SvgVisualGroup,
  ILabel,
  Matrix,
  Rect,
  SimpleNode
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import ExportConfig from '@/core/config/ExportConfig';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import ExportUtils from '../../ExportUtils';
import { HtmlStylesToInlineOptions } from '../../HtmlStylesToInlineOptions';
import CompositeNodeStyle from '@/components/ShapeBuilder/CompositeNodeStyle';
import CompositePathItemStyle from '@/components/ShapeBuilder/CompositePathItemStyle';
import CompositeShapeItemStyle from '@/components/ShapeBuilder/CompositeShapeItemStyle';
import { AnnotationType } from '@/core/common/AnnotationType';
import JInsets from '@/core/common/JInsets';
import ZoomService from '@/core/services/graph/ZoomService';
import INodeLabelData from '@/core/services/graph/serialization/INodeLabelData';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import { toRadians } from '@/core/utils/math.utils';
import { cloneDeep } from 'lodash';
import { ExportFormat } from '../../ExportFormat';
import ExportOptions from '../../ExportOptions';
import { OpenXmlExportUtils } from './OpenXmlExportUtils';

export class OpenXmlExportDiagram {
  private readonly exportOptions: ExportOptions;

  constructor(exportOptions: ExportOptions) {
    this.exportOptions = exportOptions;
  }

  public async addDiagramDataToPage<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(exportPage: T, graph: IGraph, subPageIndex: number): Promise<void> {
    const document = this.exportOptions.document;
    const page = this.exportOptions.metadata.currentPage.page;

    const graphComponent = ExportUtils.copyGraphComponent(
      graph,
      this.exportOptions.withFilters,
      ExportFormat.PowerPoint,
      this.exportOptions.lowDetailDiagram
    );

    const context = graphComponent.createRenderContext();
    this.assignPortDirections(graphComponent.graph);

    const diagram = new DiagramDto(false, 0);
    diagram.groupSettings = {
      margin:
        page.diagram.groupSettings?.margin ??
        diagramConfig.groupingDefaults.margin,
      glueExtent:
        page.diagram.groupSettings?.glueExtent ??
        diagramConfig.groupingDefaults.glueExtent,
      minimumBridgeSize:
        page.diagram.groupSettings?.minimumBridgeSize ??
        diagramConfig.groupingDefaults.minimumBridgeSize
    };

    DiagramWriter.fromGraphComponent(graphComponent, diagram);

    if (diagram.nodes.some((node) => node.groupUuid)) {
      this.addGroupings(exportPage, context, diagram);
    }
    for (const diagramNodeDto of diagram.nodes) {
      const elementIndex = diagram.nodes.indexOf(diagramNodeDto);
      const graphNode = this.getNode(graphComponent, diagramNodeDto);
      (diagramNodeDto as PowerPointDiagramNodeDto).children = [];

      switch (diagramNodeDto.style.visualType) {
        case NodeVisualType.JigsawPathShape:
        case NodeVisualType.Shape:
          if (graphNode.tag.annotationType != AnnotationType.Logos) {
            (diagramNodeDto as PowerPointDiagramNodeDto).svgPath =
              graphNode.style.renderer
                .getShapeGeometry(graphNode, graphNode.style)
                .getOutline()
                .createSvgPathData();
          }
          break;
        case NodeVisualType.Composite: {
          const children = [];
          const compositeStyleDto =
            diagramNodeDto.style as CompositeNodeStyleDto;

          const compositeStyle = (graphNode.style as JigsawNodeStyle)
            .baseStyle as CompositeNodeStyle;

          compositeStyle.items.forEach((item) => {
            const currentNode = cloneDeep(diagramNodeDto);
            if (item.type == CompositeItemType.Shape) {
              const shapeStyle = item.style as CompositeShapeItemStyle;
              const fill = DiagramWriter.convertFill(shapeStyle.fill);
              const stroke = DiagramWriter.convertStroke(shapeStyle.stroke);
              const shapeNodeStyleDto: ShapeNodeStyleDto = {
                shape: shapeStyle.shape,
                fill: fill,
                size: compositeStyleDto.size,
                visualType: NodeVisualType.Shape,
                labelStyle: compositeStyleDto.labelStyle,
                stroke: stroke,
                rotation: item.style.angle
              };
              currentNode.style = shapeNodeStyleDto;
            } else if (item.type == CompositeItemType.Path) {
              const pathStyle = item.style as CompositePathItemStyle;
              const fill = DiagramWriter.convertFill(pathStyle.fill);
              const stroke = DiagramWriter.convertStroke(pathStyle.stroke);
              const shapeNodeStyleDto: ShapeNodeStyleDto = {
                fill: fill,
                stroke: stroke,
                size: compositeStyleDto.size,
                visualType: NodeVisualType.Shape,
                labelStyle: compositeStyleDto.labelStyle,
                rotation: item.style.angle
              };
              currentNode.style = shapeNodeStyleDto;
            } else {
              throw `Unknown CompositeItemType ${item.type}`;
            }
            const itemStyle = compositeStyle.createStyle(item);
            const layout = compositeStyle.getItemLayout(graphNode, item);
            const dummyNode = new SimpleNode();

            dummyNode.layout = layout;
            dummyNode.style = itemStyle;
            currentNode.layout = new LayoutDto(
              dummyNode.layout.x,
              dummyNode.layout.y,
              dummyNode.layout.width,
              dummyNode.layout.height,
              0
            );

            const matrix = new Matrix();
            matrix.rotate(toRadians(item.style.angle), dummyNode.layout.center);

            const itemPath = compositeStyle
              .createStyle(item)
              .renderer.getShapeGeometry(dummyNode, itemStyle)
              .getOutline();

            const svgPath = itemPath.createSvgPathData();
            (currentNode as PowerPointDiagramNodeDto).svgPath = svgPath;
            currentNode.id = null;
            currentNode.uuid = null;
            currentNode.groupUuid = null;
            currentNode.label = '';
            children.push(currentNode);
          });

          (diagramNodeDto as PowerPointDiagramNodeDto).children = children;

          break;
        }
        case NodeVisualType.Image:
          diagram.nodes[elementIndex] = await this.setupImageNode(
            graphNode,
            diagramNodeDto
          );

          break;
      }

      if (!diagramNodeDto.data.labelData) {
        diagramNodeDto.data.labelData = {
          textFit:
            graphNode.tag.labelData?.textFit ??
            DiagramUtils.getNodeDefaultTextFit(graphNode.tag.annotationType)
        };
      }
      const nodeLabelData: INodeLabelData = diagramNodeDto.data.labelData;

      if (nodeLabelData && graphNode.labels.size == 1) {
        const label = graphNode.labels.first();
        const geometry = label.layoutParameter.model.getGeometry(
          label,
          label.layoutParameter
        );

        diagramNodeDto.data.labelLayout = {
          anchorX: geometry.anchorX,
          anchorY: geometry.anchorY,
          upX: geometry.upX,
          upY: geometry.upY,
          width: geometry.width,
          height: geometry.height
        };
      }
    }

    for (const diagramEdgeDto of diagram.edges) {
      diagramEdgeDto.label = await this.getLabel(diagramEdgeDto);
      const graphEdge = this.getEdge(graphComponent, diagramEdgeDto);
      const path = graphEdge.style.renderer
        .getPathGeometry(graphEdge, graphEdge.style)
        .getPath()
        .createSvgPathData();
      const pptEdge = diagramEdgeDto as PowerPointDiagramEdgeDto;

      if (!diagramEdgeDto.data.labelData) {
        diagramEdgeDto.data.labelData = {
          textFit:
            graphEdge.tag.labelData?.textFit ??
            DiagramUtils.getEdgeDefaultTextFit()
        };
      }
      pptEdge.svgPath = path;
      pptEdge.layout = graphEdge.tag.layout;
      const label = graphEdge.labels.firstOrDefault();
      if (label) {
        pptEdge.labelLayout = new LayoutDto(
          this.rotateAnchorX(label),
          this.rotateAnchorY(label),
          label.layout.width,
          label.layout.height,
          this.convertRotationAngle(label.layout.toOrientedRectangle().angle)
        );
      }
    }

    await this.processNodes(diagram.nodes, graphComponent);
    await this.processLabels(diagram.nodes, graphComponent);

    const layout = ZoomService.fitDiagram(graphComponent, {
      document: this.exportOptions.document,
      page: this.exportOptions.metadata.currentPage.page
    });
    const diagramInsets = JInsets.fromDto(layout.insets).getEnlarged(
      ExportConfig.innerDiagramMargins
    );
    exportPage.diagramInsets = new InsetsDto(
      Math.round(diagramInsets.top / ExportConfig.pointToPixelFactor),
      Math.round(diagramInsets.left / ExportConfig.pointToPixelFactor),
      Math.round(diagramInsets.bottom / ExportConfig.pointToPixelFactor),
      Math.round(diagramInsets.right / ExportConfig.pointToPixelFactor)
    );

    const diagramPadding = ExportUtils.calculatePadding(
      document,
      page,
      subPageIndex,
      'diagram'
    );
    exportPage.diagramPadding = new InsetsDto(
      Math.round(diagramPadding.top),
      Math.round(diagramPadding.left),
      Math.round(diagramPadding.bottom),
      Math.round(diagramPadding.right)
    );
    exportPage.nodes = diagram.nodes.filter((x) => !x.isGroupNode);
    exportPage.edges = diagram.edges.filter(
      (x: DiagramEdgeDto & { layout: Rect }) =>
        !x.layout || (x.layout.height > 0 && x.layout.width > 0)
    );

    graphComponent.cleanUp();
  }

  private scaleSize(from: Size, maxWidth?: number, maxHeight?: number): Size {
    if (!maxWidth && !maxHeight)
      throw 'At least one scale factor (toWidth or toHeight) must not be null.';
    if (from.height == 0 || from.width == 0)
      throw 'Cannot scale size from zero.';

    let widthScale: number = null;
    let heightScale: number = null;

    if (maxWidth) {
      widthScale = maxWidth / from.width;
    }
    if (maxHeight) {
      heightScale = maxHeight / from.height;
    }

    const scale = Math.min(
      widthScale ?? heightScale,
      heightScale ?? widthScale
    );

    return new Size(
      Math.floor(from.width * scale),
      Math.ceil(from.height * scale)
    );
  }

  private async setupImageNode(
    graphNode: INode,
    diagramNodeDto: DiagramNodeDto
  ): Promise<PowerPointDiagramNodeDto> {
    const imageNode = { ...diagramNodeDto } as DiagramNodeDto;
    const imageNodeStyleDto = diagramNodeDto.style as ImageNodeStyleDto;
    diagramNodeDto.style = new ShapeNodeStyleDto(
      new SolidColorFillDto(FillType.SolidColor, null),
      new StrokeDto(0, new SolidColorFillDto(FillType.SolidColor, null), null),
      NodeShape.Rectangle,
      NodeSize.Medium,
      NodeVisualType.Shape
    );
    const imageSize = await getImageSize(imageNodeStyleDto.imageUrl);
    imageNode.layout = { ...diagramNodeDto.layout };

    // scale the image to fit
    const newSize = this.scaleSize(
      new Size(imageSize.width, imageSize.height),
      diagramNodeDto.layout.width,
      diagramNodeDto.layout.height
    );

    // apply new dimensions
    imageNode.layout.width = newSize.width;
    imageNode.layout.height = newSize.height;

    // horizontally and vertically align
    const xDiff = diagramNodeDto.layout.width - imageNode.layout.width;
    const yDiff = diagramNodeDto.layout.height - imageNode.layout.height;
    if (xDiff > 0) {
      imageNode.layout.x += xDiff / 2;
    }
    if (yDiff > 0) {
      imageNode.layout.y += yDiff / 2;
    }

    const { src } = await convertImageSrcToPng(
      imageNodeStyleDto.imageUrl,
      false
    );
    imageNodeStyleDto.imageUrl = src;

    return imageNode;
  }

  private async processNodes(
    diagramNodeDtos: DiagramNodeDto[],
    graphComponent: GraphComponent
  ): Promise<void> {
    const groupColorLookup = this.getGroupColorLookup(diagramNodeDtos);

    for (const diagramElementNode of diagramNodeDtos) {
      if (!diagramElementNode.uuid) continue;

      const node = this.getNode(graphComponent, diagramElementNode);

      if (!node || node.tag.isGroupNode) continue;

      if (node.tag.groupUuid && node.tag.groupUuid.length > 0) {
        diagramElementNode.data.groupPadding =
          diagramConfig.groupNodePaddingWidth;
        diagramElementNode.data.groupColor =
          groupColorLookup[node.tag.groupUuid];
      }

      await this.configureIndicators(node, diagramElementNode);
      await this.configureJurisdictionDecorator(node, diagramElementNode);
      await this.configureDataPropertiesDecorator(node, diagramElementNode);
    }
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has a flag attached
   * @param node the node to query for a flag
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureJurisdictionDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    //check if flag toggled as visible
    const isJurisdictionFlagVisible =
      JurisdictionDecorator.INSTANCE.isJurisdictionDecoratorVisible(node);

    //check if state initials toggled as visible
    const isStateInitialsVisible =
      JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node);

    // no jurisdiction flag or state initials, return out
    if (!isJurisdictionFlagVisible && !isStateInitialsVisible) {
      return;
    }

    // ask the decorator for the correct position
    const layout = JurisdictionDecorator.INSTANCE.getLayout(node);

    const visualWidth =
      isJurisdictionFlagVisible && isStateInitialsVisible
        ? layout.width / 2
        : layout.width;
    // get current state for the flag decorator on the given node
    const state = DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    ) as JurisdictionDecorationState;

    /*JURISDICTION FLAG*/

    if (isJurisdictionFlagVisible) {
      //create image for jurisdiction
      const imageJurisdiction = await convertSvgToImage(
        state.jurisdictionFlagImage,
        'png'
      );

      //set layout of jurisdictionFlag
      let layoutJurisdiction = {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
        rotationAngle: null
      };

      // adjust the jurisdiction flag location to accomdate for the state visual if it is toggled on
      if (JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node)) {
        layoutJurisdiction = {
          x: layout.x,
          y: layout.y,
          width: visualWidth,
          height: layout.height,
          rotationAngle: null
        };
      }

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto
      const decoratorNodeJurisdiction: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageJurisdiction.src
        ),
        isGroupNode: false,
        label: '',
        layout: layoutJurisdiction,
        data: { isAnnotation: true, annotationType: 0 },
        isConfidential: false
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeJurisdiction
      );
    }

    /*STATE INITIALS*/
    if (isStateInitialsVisible) {
      //create image
      const circleSize = 72;
      const fontSize = state.stateInitials.length === 3 ? 55 : 80;
      const circleX = 75;
      const circleY = 75;
      const strokeWidth = 6;
      const textOffsetX = 2.1;
      const textOffsetY = 4.25;
      const padding = 2;

      const imageStateSVG =
        DataPropertyUtils.createStateInitialsCircleSvgVisual(
          state.stateInitials,
          circleSize,
          fontSize,
          circleX,
          circleY,
          textOffsetX,
          textOffsetY,
          strokeWidth
        );

      const encodedSvg = encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" height="150" width="150">${imageStateSVG.svgElement.outerHTML}</svg>`
      );
      const imageState = await convertSvgToImage(
        `data:image/svg+xml;utf8,${encodedSvg}`,
        'png'
      );

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto as children
      const decoratorNodeState: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageState.src,
          null,
          150,
          150,
          null
        ),
        isGroupNode: false,
        label: '',
        layout: {
          x: layout.x + padding + (isJurisdictionFlagVisible ? visualWidth : 0),
          y: layout.y,
          width: JurisdictionDecorator.INSTANCE.stateVisualSize * 2,
          height: JurisdictionDecorator.INSTANCE.stateVisualSize * 2,
          rotationAngle: null
        },
        data: { isAnnotation: true, annotationType: 0 },
        isConfidential: false
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeState
      );
    }
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has data properties
   * @param node the node to query for a data properties
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureDataPropertiesDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    const isVisible = DataPropertiesDecorator.INSTANCE.shouldBeVisible(node);
    if (!isVisible) {
      return;
    }
    const layout = DataPropertiesDecorator.INSTANCE.getLayout(node);
    const image = await convertSvgToImage(
      DataPropertiesDecorator.INSTANCE.imageStyle.image,
      'png'
    );

    const decoratorNode: DiagramNodeDto = {
      style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
      isGroupNode: false,
      label: '',
      layout: {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
        rotationAngle: null
      },
      data: { isAnnotation: true, annotationType: 0 },
      isConfidential: false
    };

    (diagramElementNode as PowerPointDiagramNodeDto).children.push(
      decoratorNode
    );
  }

  private async configureIndicators(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    const state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    if (state.indicators && state.indicators.length > 0) {
      let offset = 0;
      for (let i = 0; i < state.indicators.length; i++) {
        const indicator = state.indicators[i];
        const layout = IndicatorDecorators.INSTANCE.getLayout(
          node.layout,
          i,
          state.indicators.length,
          DiagramUtils.getNodeShape(node) as ShapeNodeShape,
          node.tag.annotationType,
          node.tag.indicatorsPosition
        );

        const image = await convertSvgToImage(indicator, 'png');

        const decoratorNode: DiagramNodeDto = {
          style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
          isGroupNode: false,
          label: '',
          // cannot clone object;
          layout: {
            x: layout.x,
            y: layout.y,
            width: layout.width,
            height: layout.height,
            rotationAngle: null
          },
          data: { isAnnotation: true, annotationType: 0 },
          isConfidential: false
        };

        (diagramElementNode as PowerPointDiagramNodeDto).children.push(
          decoratorNode
        );

        offset = offset + 20; // TODO: find a way to remove this if statement
      }
    }
  }

  private getNode(
    graphComponent: GraphComponent,
    element: DiagramNodeDto
  ): INode {
    return graphComponent.graph.nodes.first((x) => x.tag.uuid === element.uuid);
  }

  private getEdge(
    graphComponent: GraphComponent,
    element: DiagramEdgeDto
  ): IEdge {
    return graphComponent.graph.edges.first((x) => x.tag.uuid === element.uuid);
  }

  /**
       Use path geometry to ascertain the direction that the line is coming from.
       Powerpoint needs this when constructing connectors
       */
  private assignPortDirections(graph: IGraph): void {
    const graphEdges = graph.edges;
    graphEdges.forEach((edge) => {
      const sourcePort = edge.sourcePort;
      const targetPort = edge.targetPort;

      const sourcePortSide =
        edge.bends.size === 0
          ? DiagramUtils.getSide(
              JPoint.fromYFiles(targetPort.location),
              JPoint.fromYFiles(sourcePort.location)
            )
          : DiagramUtils.getSide(
              JPoint.fromYFiles(sourcePort.location),
              JPoint.fromYFiles(edge.bends.first().location.toPoint())
            );

      const targetPortSide =
        edge.bends.size === 0
          ? DiagramUtils.getSide(
              JPoint.fromYFiles(sourcePort.location),
              JPoint.fromYFiles(targetPort.location)
            )
          : DiagramUtils.getSide(
              JPoint.fromYFiles(targetPort.location),
              JPoint.fromYFiles(edge.bends.last().location.toPoint())
            );

      edge.tag.sourcePortDirection =
        EdgePortGenerator.convertSideToDirection(sourcePortSide);
      edge.tag.targetPortDirection =
        EdgePortGenerator.convertSideToDirection(targetPortSide);
    });
  }

  private getGroupColorLookup(nodes: DiagramNodeDto[]): Record<string, string> {
    const groupColorLookup = {};
    nodes
      .filter((x) => x.isGroupNode)
      .forEach((x) => {
        const groupColor = x.data.grouping.fillColor
          .replace('rgb(', '')
          .replace(')', '')
          .split(',');

        groupColorLookup[x.groupUuid] = RgbaToHex(
          groupColor[0],
          groupColor[1],
          groupColor[2],
          255
        );
      });
    return groupColorLookup;
  }

  private addGroupings(
    exportSlide: WordExportPageDto | PowerPointExportSlideDto,
    context: IRenderContext,
    diagram: DiagramDto
  ): void {
    const groupingVisual = new GroupingVisual({
      valueProvider: (): GroupOptions => diagram.groupSettings
    });
    const groupsSvgElement = (
      groupingVisual.createVisual(context) as SvgVisualGroup
    ).svgElement;
    const groups = [];

    for (let i = 0; i < groupsSvgElement.children.length; i++) {
      const groupDto = new NodeGroupDto(0);
      const childElement = groupsSvgElement.children[i];
      const groupUuid = childElement.getAttribute('group-uuid');
      const groupNode = diagram.nodes.filter(
        (node) => node.isGroupNode && node.groupUuid == groupUuid
      )[0];

      groupDto.fillColor = groupNode.data.grouping.fillColor;
      groupDto.strokeColor =
        groupNode.data.grouping.strokeColor ??
        diagramConfig.groupingDefaults.strokeColor;

      groupDto.dashType =
        groupNode.data.grouping?.strokeDash ??
        diagramConfig.groupingDefaults.strokeDash;

      groupDto.strokeWidth =
        groupNode.data.grouping?.strokeWidth ??
        diagramConfig.groupingDefaults.strokeWidth;

      groupDto.svgPaths = childElement
        .getAttribute('d')
        .split('Z')
        .filter((d) => d)
        .map((path) => `${path} Z`);
      groups.push(groupDto);
    }
    exportSlide.groups = groups;
  }

  /**
       Composite shapes are created in powerpoint by stacking shapes and grouping.
       Keep the label layout by creating a transparent node on top and assigning the label to that one.
       */
  private async processLabels(
    nodes: DiagramNodeDto[],
    graphComponent: GraphComponent
  ): Promise<void> {
    for (const node of nodes) {
      node.label = await this.getLabel(node);
    }

    nodes
      .filter(
        (x) =>
          (x as PowerPointDiagramNodeDto).children &&
          (x as PowerPointDiagramNodeDto).children.length > 0
      )
      .forEach((node) => {
        const children = (node as PowerPointDiagramNodeDto).children;
        const nodeStyle = node.style as ShapeNodeStyleDto;
        const label = node.label;
        const diagramNode = this.getNode(graphComponent, node);
        const diagramLabel = diagramNode.labels.firstOrDefault();

        if (node.style.visualType == NodeVisualType.Composite) {
          children.push({
            style: new ShapeNodeStyleDto(
              {
                color: '#00000000',
                type: FillType.SolidColor
              } as SolidColorFillDto,
              {
                ...nodeStyle.stroke,
                fill: {
                  color: '#00000000',
                  type: FillType.SolidColor
                } as SolidColorFillDto
              },
              nodeStyle.shape,
              nodeStyle.size,
              nodeStyle.visualType
            ),
            isGroupNode: false,
            label: label,
            layout: {
              x: node.layout.x,
              y: node.layout.y,
              width: node.layout.width,
              height: node.layout.height,
              rotationAngle: null
            },
            data: {
              isAnnotation: diagramNode.tag.isAnnotation,
              annotationType: diagramNode.tag.annotationType,
              labelLayout: label
                ? {
                    anchorX: diagramLabel.layout.anchorX,
                    anchorY: diagramLabel.layout.anchorY,
                    width: diagramLabel.layout.width,
                    height: diagramLabel.layout.height
                  }
                : null
            },
            uuid: null,
            isConfidential: false
          });
          node.label = '';
        }
      });
  }

  private async getLabel(
    item: DiagramNodeDto | DiagramEdgeDto
  ): Promise<string | null> {
    const inlineOptions = new HtmlStylesToInlineOptions();
    inlineOptions.containerClassList = [
      ExportConfig.pageContentClass,
      ExportConfig.diagramContentClass
    ];
    inlineOptions.properties = OpenXmlExportUtils.inlineProperties;

    const label = await ExportUtils.htmlStylesToInline(
      item.label,
      inlineOptions
    );
    if (!label) {
      return null;
    }
    // Remove filler blocks (https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_filler.html)
    return label?.replaceAll(
      /<br[^>]+data-cke-filler[^>]+>/g,
      ZERO_WIDTH_SPACE
    );
  }

  private convertRotationAngle(radianAngle: number): number {
    if (radianAngle === Math.PI) {
      return 180;
    }
    let degrees = radianAngle * (180 / Math.PI);
    if (radianAngle < 0) {
      degrees = Math.abs(degrees);
    } else {
      degrees = 360 - degrees;
    }
    return degrees;
  }

  private rotateAnchorX(label: ILabel): number {
    const layout = label.layout;
    return layout.orientedRectangleCenter.x - layout.width * 0.5;
  }

  private rotateAnchorY(label: ILabel): number {
    const layout = label.layout;
    return layout.orientedRectangleCenter.y + layout.height * 0.5;
  }
}
