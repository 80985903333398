﻿export enum ExportFormat {
  GraphML,
  LegacyPdf,
  Pdf,
  Png,
  Visio,
  Svg,
  SvgElement,
  PowerPoint,
  Excel,
  Json,
  Word
}
