import {
  DiagramDto,
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentView
} from '@/api/models';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import ILegendItemDefinition from '@/components/DiagramLegend/ILegendItemDefinition';
import ILegendOptionsDefinition from '@/components/DiagramLegend/ILegendOptionsDefinition';
import LegendOptions from '@/components/DiagramLegend/LegendOptions';
import LegendSerializer from '@/components/DiagramLegend/LegendSerializer';
import { LegendType } from '@/components/DiagramLegend/LegendType';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import { debounce } from '@/core/common/DebounceDecorator';
import DocumentService from '../document/DocumentService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import DiagramChangeHandler from '../graph/DiagramChangeHandler';
import JSize from '@/core/common/JSize';

export default class LegendSyncService {
  private static get currentDocument(): DocumentDto {
    return DocumentService.currentDocument;
  }

  @debounce(200)
  public static sync(): void {
    const changedDefinitions: {
      page: DocumentPageDto;
      newDefinition: string;
    }[] = [];

    if (!this.currentDocument) {
      return;
    }

    for (const page of this.currentDocument.pages) {
      if (
        page.contentType === DocumentPageContentType.Layout ||
        (page.pageType === DocumentPageType.Content &&
          page.contentType === DocumentPageContentType.Html)
      ) {
        continue;
      }

      if (page.contentType == DocumentPageContentType.MasterLegend) {
        const newDefinition = this.syncMasterLegend(page);
        if (newDefinition != page.content) {
          changedDefinitions.push({
            page: page,
            newDefinition: newDefinition
          });
        }
        continue;
      }

      const definition = LegendSerializer.deserializeFromDiagram(page.diagram);
      if (definition) {
        switch (definition.options.legendType) {
          case LegendType.Page: {
            if (definition.displayItems) {
              definition.displayItems = null;
              const newDefinition =
                LegendSerializer.serializeDefinition(definition);
              changedDefinitions.push({
                page: page,
                newDefinition: newDefinition
              });
            }
            break;
          }
          case LegendType.Step: {
            const group = DocumentService.getCommonDiagramGroupFromPage(
              this.currentDocument,
              page
            );
            const groupDiagrams = group.commonDiagrams.filter(
              (d) => d != page.diagram
            );
            const newDefinition = this.syncExternalDiagramDefinitions(
              page.diagram,
              groupDiagrams
            );

            if (page.diagram.legend != newDefinition) {
              changedDefinitions.push({
                page: page,
                newDefinition: newDefinition
              });
            }
            break;
          }
          case LegendType.Document: {
            const documentDiagrams = this.currentDocument.pages
              .filter((p) => p != page && p.diagram)
              .map((p) => p.diagram);
            const newDefinition = this.syncExternalDiagramDefinitions(
              page.diagram,
              documentDiagrams
            );

            if (page.diagram.legend != newDefinition) {
              changedDefinitions.push({
                page: page,
                newDefinition: newDefinition
              });
            }
            break;
          }
        }
      }
    }

    if (changedDefinitions.length > 0) {
      for (const change of changedDefinitions) {
        if (change.page.contentType == DocumentPageContentType.MasterLegend) {
          change.page.content = change.newDefinition;
        } else {
          change.page.diagram.legend = change.newDefinition;
          DiagramChangeHandler.invalidateDiagramCache(change.page.diagram);
        }
      }

      EventBus.$emit(
        EventBusActions.DIAGRAM_LEGEND_SYNCED,
        changedDefinitions.map((d) => d.page)
      );
    }
  }

  private static syncMasterLegend(page: DocumentPageDto): string {
    const documentDiagrams = this.currentDocument.pages
      .filter(
        (p) =>
          p != page &&
          p.diagram &&
          p.diagram.legend &&
          p.contentType != DocumentPageContentType.MasterLegend
      )
      .map((p) => p.diagram);

    const definitions = documentDiagrams.map((d) =>
      LegendSerializer.deserializeFromDiagram(d)
    );
    const definitionItems = definitions.flatMap((d) => d.items);
    const sourceDefinition = LegendSerializer.deserializeDefinition(
      page.content
    );

    const newItemsList: ILegendItemDefinition[] = [];
    for (const item of definitionItems) {
      const existingItem = newItemsList.some(() =>
        LegendUtils.getSavedItemDefinition({
          itemsDefinition: newItemsList,
          type: item.type,
          name: item.name,
          key: item.key
        })
      );

      if (!existingItem) {
        const newItem = { ...item };
        newItemsList.push(newItem);
      }
    }

    let options: ILegendOptionsDefinition;
    if (sourceDefinition) {
      for (let i = sourceDefinition.items.length - 1; i >= 0; i--) {
        const sourceItem = sourceDefinition.items[i];
        const existingItem = LegendUtils.getSavedItemDefinition({
          itemsDefinition: newItemsList,
          type: sourceItem.type,
          name: sourceItem.name,
          key: sourceItem.key
        });

        // do not rewrite the label that potentially could be edited
        if (existingItem) {
          existingItem.label = sourceItem.label;
          existingItem.show = sourceItem.show;
        }

        // rearrange items based on the saved order
        const item = newItemsList.find(
          (x) =>
            x.type == sourceItem.type &&
            x.symbol == sourceItem.symbol &&
            (!x.name || !sourceItem.name || x.name == sourceItem.name)
        );
        if (item) {
          const idx = newItemsList.indexOf(item);
          if (idx != i) {
            newItemsList.splice(i, 0, newItemsList.splice(idx, 1)[0]);
          }
        }
      }
      options = sourceDefinition.options;
    } else {
      options = new LegendOptions(
        {
          legendType: LegendType.Document
        },
        { isMasterLegend: true }
      );
      options.layout[DocumentView.Print].size = new JSize(1, 1);
    }

    const definition = <ILegendDefinition>{
      options: options,
      items: newItemsList,
      displayItems: newItemsList
    };

    return LegendSerializer.serializeDefinition(definition);
  }

  private static syncExternalDiagramDefinitions(
    sourceDiagram: DiagramDto,
    externalDiagrams: DiagramDto[]
  ): string {
    const externalDefinitions = externalDiagrams.map((d) =>
      LegendSerializer.deserializeFromDiagram(d)
    );
    return this.syncExternalDefinitions(sourceDiagram, externalDefinitions);
  }

  private static syncExternalDefinitions(
    sourceDiagram: DiagramDto,
    externalDefinitions: ILegendDefinition[]
  ): string {
    const sourceDefinition =
      LegendSerializer.deserializeFromDiagram(sourceDiagram);
    const externalDefinitionItems = externalDefinitions.flatMap((d) => d.items);
    const mergedItems: ILegendItemDefinition[] = [...sourceDefinition.items];

    for (const item of externalDefinitionItems) {
      const existingItem = mergedItems.some(() =>
        LegendUtils.getSavedItemDefinition({
          itemsDefinition: mergedItems,
          type: item.type,
          name: item.name,
          key: item.key
        })
      );

      if (!existingItem) {
        const newItem = { ...item };
        newItem.isExternal = true;
        mergedItems.push(newItem);
      }
    }

    const definition = <ILegendDefinition>{
      options: sourceDefinition.options,
      items: sourceDefinition.items,
      displayItems: mergedItems
    };

    return LegendSerializer.serializeDefinition(definition);
  }
}
