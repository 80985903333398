<template>
  <button
    v-if="!to"
    class="button relative inline-flex items-center justify-center overflow-hidden text-14 leading-none transition disabled:cursor-not-allowed disabled:text-main-dark-40"
    :class="[
      !text ? variantClass : textVariantClass,
      rounded ? 'rounded-full' : roundedClass,
      { 'is-text': text, 'w-full': fullWidth }
    ]"
    :disabled="disabled || loading"
    @click="$emit('click', $event)"
    @dblclick="$emit('dblclick', $event)"
  >
    <template v-if="!icon">
      <AppIcon
        v-if="prependIcon"
        :name="prependIcon"
        :size="iconSize"
        class="mr-8"
        :class="iconClass"
      />
      <slot />
      <AppIcon
        v-if="appendIcon"
        :name="appendIcon"
        :size="iconSize"
        class="ml-8"
        :class="iconClass"
      />
    </template>

    <AppIcon v-else :name="icon" :size="iconSize" />

    <AppLoadingOverlay
      :loading="loading"
      size="20"
      default-spinner
      opacity-class="bg-opacity-40"
    />
  </button>

  <router-link v-else custom :to="to" #default="{ navigate }">
    <button
      class="button inline-flex items-center justify-center text-14 leading-none transition disabled:cursor-not-allowed disabled:text-main-dark-40"
      :class="[
        !text ? variantClass : textVariantClass,
        roundedClass,
        { 'is-text': text, 'w-full': fullWidth }
      ]"
      :disabled="disabled || loading"
      @click="navigate"
      @dblclick="$emit('dblclick')"
    >
      <template v-if="!loading">
        <template v-if="!icon">
          <AppIcon
            v-if="prependIcon"
            :name="prependIcon"
            :size="iconSize"
            class="mr-8"
            :class="iconClass"
          />
          <slot />
          <AppIcon
            v-if="appendIcon"
            :name="appendIcon"
            :size="iconSize"
            class="ml-8"
            :class="iconClass"
          />
        </template>

        <AppIcon v-else :name="icon" :size="iconSize" />
      </template>

      <AppLoadingOverlay
        :loading="loading"
        size="20"
        default-spinner
        opacity-class="bg-opacity-40"
      />
    </button>
  </router-link>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { TButtonVariants } from './types';

type buttonSizes = 'xs' | 'sm' | 'md' | 'lg' | 'inline';

const sizes = {
  xs: 'px-5 py-4',
  sm: 'px-8 py-7',
  md: 'px-12 py-9',
  lg: 'px-15 py-13',
  inline: 'px-0 py-0'
};

const sizesSquared = {
  xs: 'p-4',
  sm: 'p-7',
  md: 'p-9',
  lg: 'p-13'
};

const TButtonVariantsClasses = {
  'default':
    'text-main-dark bg-main-dark-10 hover:bg-main-dark-20 focus:bg-main-dark-20 active:bg-main-dark-40 disabled:bg-main-dark-05',
  'default-borderless':
    'text-main-dark bg-transparent hover:bg-main-dark-10 focus:bg-main-dark-10 active:bg-main-dark-20 disabled:bg-transparent disabled:text-opacity-50',
  'default-bordered':
    'text-main-dark border border-main-dark-20 hover:bg-main-dark-05 focus:bg-main-dark-05 active:bg-main-dark-05 disabled:bg-main-dark-05/50  disabled:border-opacity-50 disabled:text-opacity-50',
  'purple':
    'text-white bg-accent-purple hover:bg-accent-purple-90 focus:bg-accent-purple-80 active:bg-accent-purple-80',
  'cyan':
    'text-white bg-accent-cyan hover:bg-accent-cyan-90 focus:bg-accent-cyan-80 active:bg-accent-cyan-80',
  'transparent': 'text-main-dark bg-transparent',
  'link': 'text-accent-purple bg-transparent',
  'danger':
    'text-white bg-danger-red hover:bg-danger-red-90 focus:bg-danger-red-80 active:bg-danger-red-80',
  'warning':
    'text-danger-red bg-white hover:bg-danger-red-05 focus:bg-danger-red-05 active:bg-danger-red-05',
  'white':
    'text-main-dark bg-white hover:bg-main-dark-20 focus:bg-white active:bg-main-dark-40 disabled:bg-main-dark-05',
  'blue':
    'text-accent-purple ring-1 ring-accent-purple bg-white hover:bg-main-dark-20 focus:bg-white active:bg-main-dark-40 disabled:bg-main-dark-05',
  'white-text':
    'text-white bg-transparent border border-transparent hover:bg-[#4E566A]/[90] active:bg-[#4E566A]/90 focus:border-main-dark-60 focus:bg-[#4E566A]/90',
  'none': ''
};

const buttonTextVariants = {
  default:
    'text-accent-main-dark hover:text-accent-main-dark-60 focus:text-accent-main-dark-40 active:text-accent-main-dark-40',
  purple:
    'text-accent-purple hover:text-accent-purple-90 focus:text-accent-purple-80 active:text-accent-purple-80',
  cyan: 'text-accent-cyan hover:text-accent-cyan-90 focus:text-accent-cyan-80 active:text-accent-cyan-80',
  transparent: 'text-main-dark',
  link: 'text-accent-purple',
  danger:
    'text-danger-red hover:text-danger-red-90 focus:text-danger-red-80 active:text-danger-red-80',
  white: 'text-white'
};

@Component({
  name: 'AppButton'
})
export default class AppButton extends Vue {
  @Prop({ default: 'md' }) size: buttonSizes; // implement validator
  @Prop({ default: 'default' }) variant: TButtonVariants; // implement validator
  @Prop({ default: false, type: Boolean }) text: boolean;
  @Prop({ default: false, type: Boolean }) rounded: boolean;
  @Prop({ default: null, type: String }) prependIcon: string;
  @Prop({ default: null, type: String }) appendIcon: string;
  @Prop({ default: null, type: String }) icon: string;
  @Prop({ default: '14', type: String }) iconSize: string;
  @Prop({ default: null, type: String }) iconClass: string;
  @Prop({ default: null, type: Boolean }) square: boolean; // only if prop icon defined
  @Prop({ default: false, type: Boolean }) loading: boolean;
  @Prop({ default: false, type: Boolean }) fullWidth: boolean;
  @Prop({ default: null }) to: RawLocation;
  @Prop({ default: false, type: Boolean }) disabled: boolean;
  @Prop({ default: 'rounded-4', type: String }) roundedClass: string;

  get isIconSquared() {
    return !!this.icon && this.square;
  }

  get variantClass() {
    return [
      TButtonVariantsClasses[this.variant],
      ...[this.isIconSquared ? sizesSquared[this.size] : sizes[this.size]]
    ];
  }

  get textVariantClass() {
    return buttonTextVariants[this.variant];
  }
}
</script>

<style lang="scss" scoped>
.button {
  height: max-content;

  &:not(.is-text) {
    @apply disabled:bg-main-dark-05;
  }
}
</style>
