import {
  ClassicTreeLayout,
  ClassicTreeLayoutEdgeRoutingStyle,
  delegate,
  FilteredGraphWrapper,
  FixNodeLayoutData,
  FixNodeLayoutStage,
  FreeNodePortLocationModel,
  GraphComponent,
  GraphEditorInputMode,
  GraphStructureAnalyzer,
  HandleInputMode,
  IEdge,
  IEnumerable,
  IGraph,
  IModelItem,
  INode,
  InputModeEventArgs,
  IPortLocationModelParameter,
  ItemEventArgs,
  LayoutExecutor,
  MoveInputMode,
  Point,
  PortAdjustmentPolicy,
  PortDirections,
  Rect,
  ScrollBarVisibility,
  Size,
  TimeSpan,
  TreeAnalyzer
} from 'yfiles';

import {
  QuickStartChildMappingDto,
  QuickStartState,
  QuickStartStateDataDto
} from '@/api/models';
import diagramConfig from '@/core/config/diagram.definition.config';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import {
  AutoZoomState,
  DOCUMENT_NAMESPACE,
  GET_AUTOZOOM,
  GET_QUICK_BUILD_SETTINGS,
  GET_READONLY,
  SET_QUICK_START_STATE
} from '../store/document.module';
import Vue from 'vue';
import QuickBuildButtons from '@/v2/services/graph-service/node-button-providers/QuickBuildButtons';
import IDisposable from '@/core/common/IDisposable';
import { UndoRedoActions } from './undoEngine/units/undo-redo-actions';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import IDiagramTypeHelper from '../IDiagramTypeHelper';
import JigsawButtonInputMode from './input-modes/button/JigsawButtonInputMode';
import EdgeServiceBase from './EdgeServiceBase';
import ScrollBarService from './ScrollBarService';
import {
  isNodeInDirection,
  LayoutOptions
} from './layout/quick-start/JigsawLayoutAlgorithm';
import ZoomService from './ZoomService';
import INodeTag, { INodeQuickStartData } from '@/core/common/INodeTag';
import { BuildDirection } from './BuildDirection';
import QuickBuildLayoutAlgorithm from './layout/quick-build/QuickBuildLayoutAlgorithm';
import SystemEntityTypes from '../corporate/SystemEntityTypes';
import EdgeLabelUtils from '@/core/utils/EdgeLabelUtils';
import JigsawEdgeLabelHandleInputMode from './input-modes/JigsawEdgeLabelHandleInputMode';

export type QuickStartStateChangedArgs = {
  oldState: QuickStartState;
  newState: QuickStartState;
};
export type QuickStartStateChangedListener = (
  sender: QuickBuildService,
  args: QuickStartStateChangedArgs
) => void;
const minimumLayerDistance = 16;
const minimumNodeDistance = 12;
export default class QuickBuildService implements IDisposable {
  public static readonly $class: string = 'QuickBuildService';
  public static readonly DEFAULT_ENTITY_SPACING: number = 4;

  private quickStartStateChangedListener: QuickStartStateChangedListener = null;
  private maxId = 1;
  private parent2FirstChildBelow = new Map<INode, INode>();
  private parent2FirstChildAbove = new Map<INode, INode>();
  private parent2FirstChildLeft = new Map<INode, INode>();
  private parent2FirstChildRight = new Map<INode, INode>();
  private get graph(): IGraph {
    return this.graphService.graphComponent.graph;
  }

  private get _zoomService(): ZoomService {
    return this.graphService.getService<ZoomService>(ZoomService.$class);
  }

  private get autoZoomState(): AutoZoomState {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_AUTOZOOM}`];
  }

  private _quickStartState: QuickStartState;

  /**
   * Used to track the number of mouse clicks during quick start
   * controls the animation of the "Disengage QuickStart" button
   */
  private _quickStartMouseClickCounter = -1;

  private settings: LayoutOptions = {
    minimumLayerDistance:
      minimumLayerDistance * QuickBuildService.DEFAULT_ENTITY_SPACING,
    minimumNodeDistance:
      minimumNodeDistance * QuickBuildService.DEFAULT_ENTITY_SPACING
  };

  private setAutoZoomState(value: AutoZoomState): void {
    this._zoomService.setAutoZoom(value);
  }

  constructor(private graphService: IGraphService) {
    this.setInstanceEntitySpacingValues();
    this._quickStartState = QuickStartState.Initial;
    this.addEventListeners();
  }

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    this.isDisposed = true;
    this.removeEventListeners();
    window.document.removeEventListener(
      'mouseup',
      this.quickStartMouseUpHandler,
      true
    );

    this.graph.removeNodeCreatedListener(this.onNodeCreated);
  }

  private get graphComponent(): GraphComponent {
    return this.graphService.graphComponent;
  }

  public get quickStartState(): QuickStartState {
    return this._quickStartState;
  }
  private get buttonInputMode(): JigsawButtonInputMode {
    return this.graphService.getService<JigsawButtonInputMode>(
      JigsawButtonInputMode.$class.name
    );
  }

  public addQuickStartStateChangedListener(
    listener: QuickStartStateChangedListener
  ): void {
    this.quickStartStateChangedListener = delegate.combine(
      this.quickStartStateChangedListener,
      listener
    ) as QuickStartStateChangedListener;
  }

  public removeQuickStartStateChangedListener(
    listener: QuickStartStateChangedListener
  ): void {
    this.quickStartStateChangedListener = delegate.remove(
      this.quickStartStateChangedListener,
      listener
    ) as QuickStartStateChangedListener;
  }

  private onQuickStartStateChanged(
    oldState: QuickStartState,
    newState: QuickStartState
  ): void {
    if (!this.quickStartStateChangedListener) {
      return;
    }

    this.quickStartStateChangedListener(this, {
      oldState,
      newState
    });
  }

  private setInstanceEntitySpacingValues = (): void => {
    const entitySpacing = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_QUICK_BUILD_SETTINGS}`
    ] as LayoutOptions;

    if (entitySpacing) {
      this.settings.minimumLayerDistance =
        entitySpacing.minimumLayerDistance * minimumLayerDistance;
      this.settings.minimumNodeDistance =
        entitySpacing.minimumNodeDistance * minimumNodeDistance;
    }
  };

  private quickStartMouseUpHandler = (): void => {
    if (this._quickStartMouseClickCounter < 2) {
      this._quickStartMouseClickCounter++;
      if (this._quickStartMouseClickCounter > 1) {
        this._quickStartMouseClickCounter = -1;
        window.document.removeEventListener(
          'mouseup',
          this.quickStartMouseUpHandler,
          true
        );
      }
    }
    this.buttonInputMode.queryAllButtons();
  };

  public resetQuickStartButtonState(): void {
    this._quickStartMouseClickCounter = 0;

    window.document.addEventListener(
      'mouseup',
      this.quickStartMouseUpHandler,
      true
    );
    this.buttonInputMode.queryAllButtons();
  }

  public get showInitialQuickBuildButtons(): boolean {
    return (
      this._quickStartState !== QuickStartState.InProgress &&
      this._quickStartMouseClickCounter >= 0 &&
      this._quickStartMouseClickCounter < 2
    );
  }

  setQuickBuildVisuals(): void {
    this.graphService.graphComponent.invalidate();
  }

  private addEventListeners(): void {
    const geim = this.graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    geim.moveInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    geim.moveUnselectedInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    geim.handleInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    this.graph.addEdgeCreatedListener((sender, evt) => {
      let graphStructureAnalyzer = new GraphStructureAnalyzer(this.graph);
      if (!evt?.item?.tag?.autoCreated && !graphStructureAnalyzer.isTree()) {
        this.setQuickStartState(QuickStartState.Complete);
      }
    });
    this.onNodeCreated = this.onNodeCreated.bind(this);
    this.graph.addNodeCreatedListener(this.onNodeCreated);

    this.onQuickBuildSettingsChanged =
      this.onQuickBuildSettingsChanged.bind(this);
    this.onToggleNodeType = this.onToggleNodeType.bind(this);

    EventBus.$on(
      EventBusActions.DIAGRAM_QUICK_BUILD_SETTINGS_CHANGED,
      this.onQuickBuildSettingsChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_NODE_TYPE_CHANGED,
      this.onToggleNodeType
    );
  }

  private removeEventListeners(): void {
    EventBus.$off(
      EventBusActions.DIAGRAM_QUICK_BUILD_SETTINGS_CHANGED,
      this.onQuickBuildSettingsChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_NODE_TYPE_CHANGED,
      this.onToggleNodeType
    );
  }

  private onNodeCreated(_: IGraph, args: ItemEventArgs<INode>): void {
    if (
      (this.quickStartState === QuickStartState.Initial &&
        this.graphService.graphComponent.graph.nodes.size > 1) ||
      args.item.tag.name !== SystemEntityTypes.NODE_CORPORATE
    ) {
      this.setQuickStartState(QuickStartState.Complete);
    }
  }
  public onQuickBuildSettingsChanged(settings: LayoutOptions): void {
    // values on the settings object are between 1-10, based on a slider.

    this.settings = Object.assign(this.settings, {
      minimumLayerDistance:
        settings.minimumLayerDistance * minimumLayerDistance,
      minimumNodeDistance: settings.minimumNodeDistance * minimumNodeDistance
    });

    this.applyLayout(null);

    if (this.autoZoomState === AutoZoomState.On) {
      EventBus.$emit(EventBusActions.DIAGRAM_FIT_CURRENT_DIAGRAM, {
        animated: true,
        force: true
      });
    }
  }

  private get layoutOptions(): LayoutOptions {
    return {
      minimumNodeDistance: this.settings.minimumNodeDistance,
      minimumLayerDistance: this.settings.minimumLayerDistance * 1.5
    };
  }
  private async onToggleNodeType(): Promise<void> {
    if (!this.inProgress) {
      return;
    }
    this.applyLayout();
  }
  public applyLayout(newNode?: INode): void {
    if (!this.inProgress) {
      return;
    }

    // The minimumNodeDistance must be less than the minimumLayerDistance. This prevents
    // the layers being affecting by their child layer
    // If the minimumNodeDistance is >= minimumLayerDistance the layers above will be pushed out
    const treeLayout = new ClassicTreeLayout({
      edgeRoutingStyle: ClassicTreeLayoutEdgeRoutingStyle.ORTHOGONAL,
      minimumLayerDistance: this.layoutOptions.minimumLayerDistance,
      minimumNodeDistance: this.layoutOptions.minimumNodeDistance,
      minimumFirstSegmentLength: 0,
      minimumLastSegmentLength: 0,
      minimumBusSegmentDistance: 0.5,
      busAlignment: 0.5
    });
    const layout = new FixNodeLayoutStage(treeLayout);

    const rootNode = this.getRootNode();
    if (!rootNode) {
      console.error('Graph is not a tree');
      return;
    }
    const layoutData = new FixNodeLayoutData({ fixedNodes: rootNode });

    const filteredGraph = new FilteredGraphWrapper(
      this.graph,
      (n) => n.tag && !n.tag.isAnnotation,
      (e) => true
    );

    const layoutExecutor = new LayoutExecutor({
      graph: filteredGraph,
      graphComponent: this.graphService.graphComponent,
      layout: layout,
      layoutData: layoutData,
      portAdjustmentPolicy: PortAdjustmentPolicy.SHORTEN,
      automaticEdgeGrouping: true,
      fixPorts: true,
      duration: TimeSpan.ZERO,
      allowUserInteraction: true,
      easedAnimation: true
    });
    EventBus.$emit(EventBusActions.DIAGRAM_APPLYING_LAYOUT);
    layoutExecutor.start().then(() => filteredGraph.dispose());
  }

  private getRootNode(): INode {
    const analyzer = new GraphStructureAnalyzer(this.graph);
    if (analyzer.isTree()) {
      return new TreeAnalyzer(this.graph).getRoot();
    }
    return null;
  }

  private dragStartingListener(
    sender: MoveInputMode | HandleInputMode,
    evt: InputModeEventArgs
  ): void {
    if (sender instanceof JigsawEdgeLabelHandleInputMode) return;
    if (this.allowEdits(sender.affectedItems.toArray())) return;

    EventBus.$emit(EventBusActions.QUICK_BUILD_OPERATION_DENIED);
  }

  private setScrollbars(state: QuickStartState): void {
    const scrollBarService = this.graphService.getService<ScrollBarService>(
      ScrollBarService.$class
    );
    if (
      state == QuickStartState.Initial ||
      state == QuickStartState.InProgress
    ) {
      scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.NEVER,
        ScrollBarVisibility.NEVER
      );

      return;
    }

    if (state == QuickStartState.Complete) {
      scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.AS_NEEDED,
        ScrollBarVisibility.AS_NEEDED
      );
      return;
    }
  }

  /**
   * If no @param override is provide, the new state will be computed based on
   * the current state
   * @param override
   * @param ignoreUndoEngine
   * @returns
   */
  setQuickStartState(
    override: QuickStartState = null,
    ignoreUndoEngine = false
  ): void {
    if (this.isDisposed) return;
    const oldState = this.quickStartState;
    if (
      oldState == QuickStartState.Complete &&
      override == QuickStartState.Complete
    ) {
      return;
    }

    let newState: QuickStartState;

    if (override !== null) {
      newState = override;
    } else {
      switch (oldState) {
        case QuickStartState.Initial:
          newState = QuickStartState.InProgress;
          break;
        case QuickStartState.InProgress:
          newState = QuickStartState.Complete;
          break;
        case QuickStartState.Complete:
          newState = QuickStartState.Complete;
          break;
      }
    }
    if (newState == QuickStartState.InProgress) {
      this.resetQuickStartStateMappings();
    }
    this.setScrollbars(newState);
    const isReadonly = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;
    if (isReadonly && newState !== QuickStartState.Complete) {
      newState = QuickStartState.Initial;
    }

    const setNewQuickStartState = (): void => {
      this._quickStartState = newState;
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_QUICK_START_STATE}`,
        newState
      );
      if (
        oldState == QuickStartState.InProgress &&
        newState === QuickStartState.Complete
      ) {
        this.graphService.graphComponent.updateContentRect();
        this.setAutoZoomState(AutoZoomState.On);
        this._zoomService.fitCurrentDiagram({ force: true });
      }

      if (newState === QuickStartState.InProgress) {
        this.setInstanceEntitySpacingValues();
      }

      EventBus.$emit(EventBusActions.QUICK_BUILD_CHANGED, newState, oldState);
      this.onQuickStartStateChanged(oldState, newState);
      this.setQuickBuildVisuals();
      const geim = this.graphService.graphComponent
        .inputMode as GraphEditorInputMode;

      if (newState !== QuickStartState.InProgress && !isReadonly) {
        geim.allowClipboardOperations = true;
        // if quick build is no longer in progress and there remains only a single
        // node, we want to show the initial buttons, invoking resetQuickStartButtonState will do so.
        // setting this to 2 or higher will cause initial buttons
        // to be hidden and the event handler in quickStartMouseUpHandler to be remove
        if (this.graph.nodes.size == 1) {
          this.resetQuickStartButtonState();
        } else {
          this._quickStartMouseClickCounter = 2;
        }
      } else {
        this._quickStartMouseClickCounter = -1;
        geim.allowClipboardOperations = false;

        if (!isReadonly) {
          this.graphService.graphComponent.selection.setSelected(
            this.graph.nodes.at(0),
            true
          );
        }
        this.graphService.graphComponent.inputMode.cancel();
      }
      this.buttonInputMode.queryAllButtons();
      geim.requeryHandles();
    };

    const undoNewQuickStartState = (): void => {
      this._quickStartState = oldState;
      EventBus.$emit(
        EventBusActions.QUICK_BUILD_CHANGED,
        oldState,
        this._quickStartState
      );
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_QUICK_START_STATE}`,
        oldState
      );
      this.setQuickBuildVisuals();

      (
        this.graphService.graphComponent.inputMode as GraphEditorInputMode
      ).allowClipboardOperations = oldState !== QuickStartState.InProgress;
    };

    setNewQuickStartState();

    if (!ignoreUndoEngine) {
      this.graph.addUndoUnit(
        UndoRedoActions.UNDO_QUICK_BUILD,
        UndoRedoActions.REDO_QUICK_BUILD,
        undoNewQuickStartState,
        setNewQuickStartState
      );
    }
  }

  async doQuickBuild(
    sourceNode: INode,
    button: QuickBuildButtons
  ): Promise<INode> {
    // To avoid scrollbar flicker
    const scrollBarService = this.graphService.getService<ScrollBarService>(
      ScrollBarService.$class
    );
    scrollBarService.setScrollBarsVisibility();
    const edit = this.graph.undoEngine.beginCompoundEdit(
      'Quick Build',
      'Quick Build'
    );

    if (
      this.quickStartState != QuickStartState.InProgress &&
      !this.graphService.graphComponent.selection.isSelected(sourceNode)
    ) {
      this.graphService.graphComponent.selection.setSelected(sourceNode, true);
    }
    this._quickStartMouseClickCounter = 2;

    let newNode: INode = null;
    let newEdge: IEdge = null;
    const buildDirection = this.buttonToDirection(button);
    switch (button) {
      case QuickBuildButtons.TOP:
        if (this.inProgress) {
          newNode = await this.quickStartCreateNode(
            this.graph,
            sourceNode,
            buildDirection
          );
        } else {
          newNode = this.createQuickBuildNode(sourceNode, button);
          newEdge = this.createQuickBuildEdge(
            newNode,
            sourceNode,
            buildDirection
          );
        }
        break;
      case QuickBuildButtons.BOTTOM:
        if (this.inProgress) {
          newNode = await this.quickStartCreateNode(
            this.graph,
            sourceNode,
            buildDirection
          );
        } else {
          newNode = this.createQuickBuildNode(sourceNode, button);
          newEdge = this.createQuickBuildEdge(
            sourceNode,
            newNode,
            buildDirection
          );
        }
        break;
      case QuickBuildButtons.LEFT:
        if (this.inProgress) {
          newNode = await this.quickStartCreateNode(
            this.graph,
            sourceNode,
            buildDirection
          );
        } else {
          newNode = this.createQuickBuildNode(sourceNode, button);
          newEdge = this.createQuickBuildEdge(
            sourceNode,
            newNode,
            buildDirection
          );
        }
        break;
      case QuickBuildButtons.RIGHT:
        if (this.inProgress) {
          newNode = await this.quickStartCreateNode(
            this.graph,
            sourceNode,
            buildDirection
          );
        } else {
          newNode = this.createQuickBuildNode(sourceNode, button);
          newEdge = this.createQuickBuildEdge(
            sourceNode,
            newNode,
            buildDirection
          );
        }
        break;
      default:
        return;
    }

    if (!this.inProgress) {
      EdgeLabelUtils.tryAddEdgeLabel(this.graphComponent, newEdge);

      const layout = new QuickBuildLayoutAlgorithm(
        sourceNode,
        newNode,
        this.layoutOptions.minimumNodeDistance,
        this.layoutOptions.minimumLayerDistance,
        buildDirection
      );
      this.graph.applyLayout(layout);
      this.routeEdge(newEdge);
    }

    this.finalizeInsert();
    edit.commit();
    return newNode;
  }

  private buttonToDirection(button: QuickBuildButtons): BuildDirection {
    switch (button) {
      case QuickBuildButtons.TOP:
        return 'up';
      case QuickBuildButtons.RIGHT:
        return 'right';
      case QuickBuildButtons.BOTTOM:
        return 'down';
      case QuickBuildButtons.LEFT:
        return 'left';
    }
  }

  finalizeInsert(): void {
    if (this.quickStartState === QuickStartState.InProgress) {
      EventBus.$emit(EventBusActions.QUICK_BUILD_LAYOUT_FINISHED);
    }
  }

  hasParents(node: INode): boolean {
    const inEdges = this.graph.edgesAt(node, 'incoming');
    return inEdges.size > 0;
  }

  hasChildren(node: INode): boolean {
    const outEdges = this.graph.edgesAt(node, 'outgoing');
    return outEdges.size > 0;
  }

  getInsertionLocation(
    otherNode: INode,
    locationSide: QuickBuildButtons,
    newNodeSize: Size
  ): Point {
    let otherNodeLayout = otherNode.layout;
    let newPoint: Point = null;
    // defines the numbers of spaces that should be between the nodes

    switch (locationSide) {
      case QuickBuildButtons.TOP:
        newPoint = new Point(
          otherNode.layout.center.x - newNodeSize.width / 2,
          otherNodeLayout.y -
            newNodeSize.height -
            this.settings.minimumLayerDistance * 2
        );
        break;
      case QuickBuildButtons.LEFT:
        newPoint = new Point(
          otherNodeLayout.x -
            this.settings.minimumNodeDistance -
            newNodeSize.width,
          otherNode.layout.center.y - newNodeSize.height / 2
        );
        break;
      case QuickBuildButtons.RIGHT:
        newPoint = new Point(
          otherNodeLayout.x +
            otherNode.layout.width +
            this.settings.minimumNodeDistance,
          otherNode.layout.center.y - newNodeSize.height / 2
        );
        break;
      case QuickBuildButtons.BOTTOM:
        newPoint = new Point(
          otherNode.layout.center.x - newNodeSize.width / 2,
          otherNodeLayout.y +
            otherNode.layout.height +
            this.settings.minimumLayerDistance * 2
        );
        break;
    }

    if (this.quickStartState !== QuickStartState.InProgress) {
      const edges = this.graphService.graphComponent.graph.edgesAt(
        otherNode,
        'outgoing'
      );

      const nodecenter = otherNode.layout.center;
      switch (locationSide) {
        case QuickBuildButtons.TOP: {
          const incoming = this.graphService.graphComponent.graph.edgesAt(
            otherNode,
            'incoming'
          );
          const outgoingNorth = incoming.filter(
            (e) => e.sourcePort.location.y < nodecenter.y
          );
          let size = 1;
          if (outgoingNorth.size > 0) {
            size += outgoingNorth.size;
          }
          return newPoint.subtract(
            new Point(
              0,
              this.settings.minimumLayerDistance +
                size * diagramConfig.grid.size
            )
          );
        }

        case QuickBuildButtons.BOTTOM: {
          const outgoingSouth = edges.filter(
            (e) => e.sourcePort.location.y > nodecenter.y
          );
          let size = 1;
          if (outgoingSouth.size > 0) {
            size += outgoingSouth.size;
          }
          return newPoint.add(
            new Point(
              0,
              this.settings.minimumLayerDistance +
                size * diagramConfig.grid.size
            )
          );
        }

        case QuickBuildButtons.LEFT: {
          const outgoingLeft = edges.filter(
            (e) => e.sourcePort.location.x < nodecenter.x
          );
          if (outgoingLeft.size > 0) {
            return newPoint.subtract(
              new Point(outgoingLeft.size * diagramConfig.grid.size, 0)
            );
          }

          break;
        }

        case QuickBuildButtons.RIGHT: {
          const outgoingRight = edges.filter(
            (e) => e.sourcePort.location.x > nodecenter.x
          );
          if (outgoingRight.size > 0) {
            return newPoint.add(
              new Point(outgoingRight.size * diagramConfig.grid.size, 0)
            );
          }
          break;
        }
      }
    }
    return newPoint;
  }

  public get inProgress(): boolean {
    return this.quickStartState === QuickStartState.InProgress;
  }

  public allowEdits(items: IModelItem[]): boolean {
    return (
      !this.inProgress || (items.length == 1 && INode.isInstance(items[0]))
    );
  }

  createQuickBuildNode(
    otherNode: INode,
    locationSide: QuickBuildButtons
  ): INode {
    const tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildNodeTag();

    const size = DiagramUtils.getNodeSize(tag.style);
    const style = StyleCreator.createNodeStyle(tag.style, null);
    const location = this.getInsertionLocation(otherNode, locationSide, size);
    const node = this.graph.createNode({
      style: style,
      layout: new Rect(location, size),
      tag: tag
    });

    const labelText = DiagramUtils.getPlaceholderLabelText(node);
    DiagramUtils.setLabel(this.graph, node, labelText);

    this.scrollNodeIntoView(otherNode);

    EventBus.$emit(EventBusActions.QUICK_BUILD_NODE_CREATED, {
      node,
      locationSide
    });

    return node;
  }

  createQuickBuildNodeNewLayout(otherNode: INode, point: Point): INode {
    const tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildNodeTag();
    const size = DiagramUtils.getNodeSize(tag.style);
    const style = StyleCreator.createNodeStyle(tag.style);
    const node = this.graph.createNode({
      style: style,
      layout: new Rect(point, size),
      tag: tag
    });
    const labelText = DiagramUtils.getPlaceholderLabelText(node);
    DiagramUtils.setLabel(this.graph, node, labelText);
    return node;
  }

  createQuickBuildEdge(
    source: INode,
    target: INode,
    direction: BuildDirection
  ): IEdge {
    let tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildEdgeTag();
    tag.quickStartData = { direction: direction };

    tag.autoCreated = true;
    //fix the ports

    let sourcePortFixedDirection: PortDirections = null;
    let targetPortFixedDirection: PortDirections = null;
    let sourcePortLocationModelParameter: IPortLocationModelParameter = null;
    let targetPortLocationModelParameter: IPortLocationModelParameter = null;
    switch (direction) {
      case 'up':
        sourcePortFixedDirection = PortDirections.SOUTH;
        targetPortFixedDirection = PortDirections.NORTH;
        sourcePortLocationModelParameter =
          FreeNodePortLocationModel.NODE_BOTTOM_ANCHORED;
        targetPortLocationModelParameter =
          FreeNodePortLocationModel.NODE_TOP_ANCHORED;
        break;
      case 'down':
        sourcePortFixedDirection = PortDirections.SOUTH;
        targetPortFixedDirection = PortDirections.NORTH;
        sourcePortLocationModelParameter =
          FreeNodePortLocationModel.NODE_BOTTOM_ANCHORED;
        targetPortLocationModelParameter =
          FreeNodePortLocationModel.NODE_TOP_ANCHORED;
        break;

      case 'right':
        sourcePortFixedDirection = PortDirections.EAST;
        targetPortFixedDirection = PortDirections.WEST;
        sourcePortLocationModelParameter =
          FreeNodePortLocationModel.NODE_RIGHT_ANCHORED;
        targetPortLocationModelParameter =
          FreeNodePortLocationModel.NODE_LEFT_ANCHORED;
        break;

      case 'left':
        sourcePortFixedDirection = PortDirections.WEST;
        targetPortFixedDirection = PortDirections.EAST;
        sourcePortLocationModelParameter =
          FreeNodePortLocationModel.NODE_LEFT_ANCHORED;
        targetPortLocationModelParameter =
          FreeNodePortLocationModel.NODE_RIGHT_ANCHORED;
        break;
    }
    DiagramUtils.fixEdgePort(tag, true, sourcePortFixedDirection);
    DiagramUtils.fixEdgePort(tag, false, targetPortFixedDirection);

    const sourcePort = DiagramUtils.getOrAddPort(
      this.graph,
      source,
      sourcePortLocationModelParameter
    );
    const targetPort = DiagramUtils.getOrAddPort(
      this.graph,
      target,
      targetPortLocationModelParameter
    );

    const edgeStyle = StyleCreator.createEdgeStyle(tag.style);
    return this.graph.createEdge(sourcePort, targetPort, edgeStyle, tag);
  }

  routeEdge(edge: IEdge): void {
    this.graphService
      .getService<EdgeServiceBase>(EdgeServiceBase.$class)
      .applyEdgeRouterForEdges([edge]);
  }

  public resetQuickStartStateMappings(): void {
    this.parent2FirstChildBelow = new Map<INode, INode>();
    this.parent2FirstChildAbove = new Map<INode, INode>();
    this.parent2FirstChildLeft = new Map<INode, INode>();
    this.parent2FirstChildRight = new Map<INode, INode>();
    this.maxId = 1;
  }

  private createKeyValueMapping(
    map: Map<INode, INode>
  ): QuickStartChildMappingDto[] {
    const mapping: QuickStartChildMappingDto[] = [];
    map.forEach((value, key) => {
      mapping.push({
        parentId: key.tag.quickStartData.layoutId,
        childId: value.tag.quickStartData.layoutId
      });
    });
    return mapping;
  }

  private setQuickBuildParentChildMapping(
    map: Map<INode, INode>,
    mappings: QuickStartChildMappingDto[],
    direction: BuildDirection
  ): void {
    if (mappings) {
      mappings.forEach((mapping) => {
        const childNode = this.graph.nodes.find(
          (n) => n.tag.quickStartData.layoutId == mapping.childId
        );
        const parentNode = this.graph.nodes.find(
          (n) => n.tag.quickStartData.layoutId == mapping.parentId
        );
        if (childNode && parentNode) {
          switch (direction) {
            case 'down':
              parentNode.tag.quickStartData.firstChildDownUuid =
                childNode.tag.uuid;
              break;
            case 'up':
              parentNode.tag.quickStartData.firstChildUpUuid =
                childNode.tag.uuid;
              break;
            case 'left':
              parentNode.tag.quickStartData.firstChildLeftUuid =
                childNode.tag.uuid;
              break;
            case 'right':
              parentNode.tag.quickStartData.firstChildRightUuid =
                childNode.tag.uuid;
              break;
          }
          map.set(parentNode, childNode);
        }
      });
    }
  }

  public setQuickStartStateData(quickBuildData: QuickStartStateDataDto): void {
    this.maxId = quickBuildData.maxId;
    this.setQuickBuildParentChildMapping(
      this.parent2FirstChildAbove,
      quickBuildData.above,
      'up'
    );
    this.setQuickBuildParentChildMapping(
      this.parent2FirstChildBelow,
      quickBuildData.below,
      'down'
    );
    this.setQuickBuildParentChildMapping(
      this.parent2FirstChildLeft,
      quickBuildData.left,
      'left'
    );
    this.setQuickBuildParentChildMapping(
      this.parent2FirstChildRight,
      quickBuildData.right,
      'right'
    );
    this.maxId = quickBuildData.maxId;

    this.getQuickBuildLayout();
  }

  public getQuickBuildLayout(): any {
    const obj = {
      maxId: this.maxId,
      above: this.createKeyValueMapping(this.parent2FirstChildAbove),
      below: this.createKeyValueMapping(this.parent2FirstChildBelow),
      right: this.createKeyValueMapping(this.parent2FirstChildRight),
      left: this.createKeyValueMapping(this.parent2FirstChildLeft)
    };
    return obj;
  }

  public getQuickStartStateData(): QuickStartStateDataDto {
    return {
      maxId: this.maxId,
      above: this.createKeyValueMapping(this.parent2FirstChildAbove),
      below: this.createKeyValueMapping(this.parent2FirstChildBelow),
      right: this.createKeyValueMapping(this.parent2FirstChildRight),
      left: this.createKeyValueMapping(this.parent2FirstChildLeft),
      state: this.quickStartState
    };
  }

  private async quickStartCreateNode(
    graph: IGraph,
    selectedNode: INode,
    direction: BuildDirection
  ): Promise<INode> {
    if (
      selectedNode &&
      !(
        isNodeInDirection(selectedNode, 'left') ||
        isNodeInDirection(selectedNode, 'right')
      )
    ) {
      let targetLayer = selectedNode.tag.quickStartData.layer;
      if (direction === 'up' || direction === 'down') {
        targetLayer += direction === 'up' ? -1 : 1;
      }

      const childNode = this.createQuickBuildNodeNewLayout(
        selectedNode,
        selectedNode.layout.toPoint()
      );

      childNode.tag.quickStartData = {
        direction: direction,
        parentUuid: selectedNode.tag.uuid,
        layoutId: ++this.maxId,
        layer: targetLayer,
        successorUuid: null,
        predecessorUuid: null,
        data: null,
        firstChildDownUuid: null,
        firstChildLeftUuid: null,
        firstChildRightUuid: null,
        firstChildUpUuid: null,
        siblingRank: null
      };

      this.handleSuccession(graph, direction, selectedNode, childNode);
      switch (direction) {
        case 'up': {
          this.createQuickBuildEdge(childNode, selectedNode, direction);
          break;
        }
        case 'down': {
          this.createQuickBuildEdge(selectedNode, childNode, direction);
          break;
        }
        case 'left': {
          this.createQuickBuildEdge(selectedNode, childNode, direction);
          break;
        }
        case 'right': {
          this.createQuickBuildEdge(selectedNode, childNode, direction);
          break;
        }
      }

      // Keep this, it's useful for debugging
      // this.graph.setLabelText(
      //   childNode.labels.first(),
      //   `<p style="font-size:9pt">${childNode.tag.uuid.substring(
      //     0,
      //     2
      //   )}: ${direction} from\n${selectedNode.tag.uuid.substring(
      //     0,
      //     2
      //   )}\nlayer: ${childNode.tag.quickStartData.layer}</p>`
      // );
      this.applyLayout(childNode);
      this.scrollNodeIntoView(selectedNode);

      EventBus.$emit(EventBusActions.QUICK_START_NODE_CREATED, {
        direction,
        selectedNode,
        childNode
      });

      return childNode;
    }
  }

  public deleteNodes(selectedNodes: INode[]): void {
    selectedNodes
      .filter((d) => d.tag.quickStartData.layer !== 0)
      .sort((a, b) => {
        const aLayer = a.tag.quickStartData.layer;
        const bLayer = b.tag.quickStartData.layer;
        if (aLayer === bLayer) {
          return 0;
        }
        if (aLayer < bLayer) {
          return -1;
        }
        return 1;
      });

    for (const node of selectedNodes) {
      this.handleRemoval(this.graphComponent.graph, node);
    }
    this.applyLayout();
    this.graphComponent.invalidate();
  }

  public deleteNode(node: INode): void {
    if (node) {
      this.handleRemoval(this.graphComponent.graph, node);
    }
    this.applyLayout();
    this.graphComponent.invalidate();
  }

  private handleRemoval(graph: IGraph, node: INode): void {
    const parent = graph.nodes.find(
      (d) => d.tag.uuid == node.tag.quickStartData.parentUuid
    );
    const direction = node.tag.quickStartData.direction as BuildDirection;
    const firstChildUpUuid = node.tag.quickStartData.firstChildUpUuid;
    const firstChildDownUuid = node.tag.quickStartData.firstChildDownUuid;
    const firstChildLeftUuid = node.tag.quickStartData.firstChildLeftUuid;
    const firstChildRightUuid = node.tag.quickStartData.firstChildRightUuid;

    if (!parent) {
      //we're deleting the root => this will delete the graph unless root has exactly one child, which makes it savable
      const childNodes = this.collectChildren(graph, node);
      if (childNodes.size === 1) {
        const newRoot = childNodes.first();
        const newTag = this.copyTag(newRoot);
        newTag.quickStartData.parentUuid = null;
        newTag.quickStartData.direction = 'root';
        newRoot.tag = newTag;
        newRoot.tag.quickStartData.layer = 0;
        graph.remove(node);
      }

      return;
    }

    if (
      firstChildUpUuid ||
      firstChildDownUuid ||
      firstChildLeftUuid ||
      firstChildRightUuid
    ) {
      //there's descendants to take care of, as we cannot disconnect the graph
      const childNodes = this.collectChildren(graph, node);
      Array.from(childNodes).forEach((child) =>
        this.handleRemoval(graph, child)
      );
    }

    this.updateParentReference(graph, node, parent, direction);

    //update sibling links
    const predUuid = node.tag.quickStartData.predecessorUuid;
    const predecessor = graph.nodes.find((d) => d.tag.uuid == predUuid);
    const succUuid = node.tag.quickStartData.successorUuid;
    const successor = graph.nodes.find((d) => d.tag.uuid == succUuid);
    if (predecessor) {
      this.setSuccessorUuid(predecessor, succUuid);
    }
    if (successor) {
      this.setPredecessorUuid(successor, predUuid);
    }

    if (!predecessor && !successor) {
      //we're removing the last node of a sibling group => update ranks on this layer
      const removedRank = node.tag.quickStartData.siblingRank;
      const layer = node.tag.quickStartData.layer;
      graph.nodes
        .filter(
          (d) =>
            d.tag.quickStartData.layer == layer &&
            d.tag.quickStartData.siblingRank > removedRank
        )
        .forEach((d) => this.decSiblingRank(d));
    }

    graph.remove(node);
  }

  private updateParentReference(
    graph: IGraph,
    node: INode,
    parent: INode,
    direction: BuildDirection
  ): void {
    let firstChildUuid;
    switch (direction) {
      case 'up':
        firstChildUuid = parent.tag.quickStartData.firstChildUpUuid;
        break;
      case 'down':
        firstChildUuid = parent.tag.quickStartData.firstChildDownUuid;
        break;
      case 'left':
        firstChildUuid = parent.tag.quickStartData.firstChildLeftUuid;
        break;
      case 'right':
        firstChildUuid = parent.tag.quickStartData.firstChildRightUuid;
        break;
    }

    if (firstChildUuid == node.tag.uuid) {
      const newFirstChild = graph.nodes.find(
        (d) => d.tag.uuid == node.tag.quickStartData.successorUuid
      );
      const newFirstChildUuid = newFirstChild ? newFirstChild.tag.uuid : null;
      this.setFirstChildImpl(
        direction,
        graph,
        parent,
        newFirstChild,
        newFirstChildUuid
      );
    }
  }

  private collectChildren(graph: IGraph, node: INode): IEnumerable<INode> {
    return graph.nodes.filter(
      (d) => d.tag.quickStartData.parentUuid == node.tag.uuid
    );
  }

  private handleSuccession(
    graph: IGraph,
    direction: BuildDirection,
    parent: INode,
    childNode: INode
  ): void {
    const firstChild = this.getFirstChild(direction, parent);
    if (!firstChild) {
      this.setFirstChild(direction, graph, parent, childNode);
    } else {
      //We have to insert the new node between its existing siblings
      let siblingCount = 1;
      const newTag = this.copyTag(childNode);

      //copy the rank of an existing sibling
      newTag.quickStartData.siblingRank =
        firstChild.tag.quickStartData.siblingRank;

      const firstSibling = firstChild!;
      let sibling = firstSibling;
      while (sibling.tag.quickStartData.successorUuid) {
        sibling = graph.nodes.find(
          (d) => d.tag.uuid == sibling.tag.quickStartData.successorUuid
        )!;
        if (!sibling) {
          break;
        }
        siblingCount++;
      }
      //TODO: alternate between floor and ceil for insertion into even amount of siblings according to feedback point 3
      let insertionPosition = Math.ceil(siblingCount / 2) - 1;
      let predecessor = firstSibling;
      while (insertionPosition > 0) {
        predecessor = graph.nodes.find(
          (d) => d.tag.uuid == predecessor.tag.quickStartData.successorUuid
        )!;
        insertionPosition--;
      }
      const successor = graph.nodes.find(
        (d) => d.tag.uuid == predecessor.tag.quickStartData.successorUuid
      )!;
      if (successor) {
        this.setPredecessorUuid(successor, childNode.tag.uuid);
        newTag.quickStartData.successorUuid = successor.tag.uuid;
      }
      this.setSuccessorUuid(predecessor, childNode.tag.uuid);
      newTag.quickStartData.predecessorUuid = predecessor.tag.uuid;

      childNode.tag = newTag;
    }
  }

  private getFirstChild(
    direction: BuildDirection,
    parent: INode
  ): INode | undefined {
    return this.getParentToChildMap(direction).get(parent);
  }

  private setFirstChild(
    direction: BuildDirection,
    graph: IGraph,
    parent: INode,
    child: INode
  ): void {
    this.setFirstChildImpl(direction, graph, parent, child, child.tag.uuid);
  }

  private copyTag(node: INode): INodeTag {
    return structuredClone(node.tag);
  }

  private decSiblingRank(node: INode): void {
    this.setQuickStartData(
      node,
      'siblingRank',
      (node.tag.quickStartData.siblingRank - 1).toString()
    );
  }

  private setPredecessorUuid(node: INode, uuid: string): void {
    this.setQuickStartData(node, 'predecessorUuid', uuid);
  }

  private setSuccessorUuid(node: INode, uuid: string): void {
    this.setQuickStartData(node, 'successorUuid', uuid);
  }
  private getParentToChildMap(direction: BuildDirection): Map<INode, INode> {
    switch (direction) {
      case 'up':
        return this.parent2FirstChildAbove;
      case 'down':
        return this.parent2FirstChildBelow;
      case 'left':
        return this.parent2FirstChildLeft;
      case 'right':
        return this.parent2FirstChildRight;
    }
  }

  private setFirstChildImpl(
    direction: BuildDirection,
    graph: IGraph,
    parent: INode,
    child: INode | null,
    childUuid: string
  ): void {
    this.setQuickStartData(
      parent,
      this.getChildUuidPropertyName(direction),
      childUuid
    );
    this.setChildImpl(
      this.getParentToChildMap(direction),
      graph,
      parent,
      child
    );
  }

  private getChildUuidPropertyName(
    direction: BuildDirection
  ):
    | 'firstChildUpUuid'
    | 'firstChildDownUuid'
    | 'firstChildLeftUuid'
    | 'firstChildRightUuid' {
    switch (direction) {
      case 'up':
        return 'firstChildUpUuid';
      case 'down':
        return 'firstChildDownUuid';
      case 'left':
        return 'firstChildLeftUuid';
      case 'right':
        return 'firstChildRightUuid';
    }
  }

  private setChildImpl(
    map: Map<INode, INode>,
    graph: IGraph,
    parent: INode,
    child: INode | null
  ): void {
    const oldChild = map.get(parent);

    if (child) {
      map.set(parent, child);
    } else {
      map.delete(parent);
    }

    const undo = oldChild
      ? (): Map<INode, INode> => map.set(parent, oldChild)
      : (): boolean => map.delete(parent);
    const redo = child
      ? (): Map<INode, INode> => map.set(parent, child)
      : (): boolean => map.delete(parent);
    graph.addUndoUnit('setChild', 'setChild', undo, redo);
  }

  private setQuickStartData(
    node: INode,
    property: keyof INodeQuickStartData,
    value: string
  ): void {
    const newTag = this.copyTag(node);
    newTag.quickStartData[property as string] = value;
    node.tag = newTag;
  }

  private scrollNodeIntoView(node: INode): void {
    if (this.autoZoomState !== AutoZoomState.On) {
      this.graphService.graphComponent.zoomToAnimated(
        node.layout.center,
        this.graphService.graphComponent.canvasContext.zoom
      );
    }
  }
}
