﻿import IDisposable from '@/core/common/IDisposable';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { IGraph, INode } from 'yfiles';

export type HighestSequenceNumbers = {
  [key: string]: number;
};

export default class NodeSequencingService implements IDisposable {
  public static readonly $class: string = 'NodeSequencingService';

  private graphService: IGraphService;
  private _highestSequenceNumbers: HighestSequenceNumbers = {};
  private nodeCreatedListener = this.onNodeCreated.bind(this);
  private nodeRemovedListener = this.onNodeRemoved.bind(this);
  isDisposed: boolean;

  constructor(graphService: IGraphService) {
    this.graphService = graphService;
    this.addEventListeners();
  }

  public resync(): void {
    this._highestSequenceNumbers = {};
    const sequenceNumbers = {};

    this.graphService.graph.nodes.forEach((node: INode) => {
      const name: string = this.formatName(node.tag.name);
      this.tryAddDataName(name);
      let value: number = Math.max(
        node.tag.sequenceNumber,
        this.getCurrentValue(name)
      );

      if (!sequenceNumbers[name]) {
        sequenceNumbers[name] = [];
      }

      //If we are changing multiple node types to a single type
      //then there is a chance that sequence numbers can clash.
      //If this is the case, assign a new number
      if (sequenceNumbers[name].includes(value)) {
        value = this.getCurrentValue(name) + 1;
      }

      sequenceNumbers[name].push(value);
      this.setCurrentValue(name, value);
    });
  }

  public getShapeSequenceNumber(name: string): number {
    const formattedName: string = this.formatName(name);
    this.tryAddDataName(formattedName);
    const nextNumber: number = this.getCurrentValue(formattedName) + 1;
    return nextNumber;
  }

  public updateShape(oldNode: INode, newNode: INode, graph: IGraph): void {
    this.removeShape(oldNode);
    this.addShape(newNode);
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.removeEventListeners();
    this.isDisposed = true;
  }

  private addEventListeners(): void {
    this.graphService.graph.addNodeCreatedListener(this.nodeCreatedListener);
    this.graphService.graph.addNodeRemovedListener(this.nodeRemovedListener);
  }

  private removeEventListeners(): void {
    this.graphService.graph.removeNodeCreatedListener(this.nodeCreatedListener);
    this.graphService.graph.removeNodeRemovedListener(this.nodeRemovedListener);
  }

  private onNodeCreated(sender, evt): void {
    this.addShape(evt.item);
  }
  private onNodeRemoved(sender, evt): void {
    this.removeShape(evt.item);
  }

  private getCurrentValue(name: string): number {
    return this._highestSequenceNumbers[name];
  }

  private setCurrentValue(name: string, value: number): void {
    this._highestSequenceNumbers[name] = value;
  }

  private increment(name: string): void {
    this._highestSequenceNumbers[name]++;
  }

  private formatName(name: string): string {
    //Required for dragged nodes as their name is different
    return name.replace(/[()]/g, '').replace(/ /g, '_').toUpperCase();
    //e.g. Stacked Entities (Left) -> STACKED_ENTITIES_LEFT
  }

  private tryAddDataName(name: string): void {
    if (this._highestSequenceNumbers[name]) {
      return;
    }
    this._highestSequenceNumbers[name] = 0;
  }

  private addShape(node: INode): void {
    if (!node.tag.isPreviewNode) {
      const name: string = this.formatName(node.tag.name);
      this.tryAddDataName(name);
      this.increment(name);
    }
  }

  private removeShape(node: INode): void {
    const name: string = this.formatName(node.tag.name);
    this.setCurrentValue(name, 0);
    this.graphService.graph.nodes.forEach((shape: INode) => {
      const shapeName: string = this.formatName(shape.tag.name);
      if (shapeName === name) {
        let value: number = Math.max(
          shape.tag.sequenceNumber,
          this.getCurrentValue(name)
        );
        value === node.tag.sequenceNumber ? value-- : value; //for when a node is changed, not removed
        this.setCurrentValue(shapeName, value);
      }
    });
  }
}
