﻿import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  GraphEditorInputMode,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  Point,
  Rect,
  Size
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';

import config from '@/core/config/diagram.definition.config';
import {
  NodeSize,
  QuickStartState,
  DocumentPaletteCategory
} from '@/api/models';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import { AnnotationType } from '@/core/common/AnnotationType';
import {
  createDragPreview,
  queryContinueDragHandler
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import QuickBuildService from '@/core/services/graph/quick-build.service';
import { PaletteDropInputModeBase } from '@/components/DiagramPalette/PaletteDropInputModeBase';

export default class ClipArtPaletteBehaviour extends PaletteItemBehaviourBase {
  private static _instance: ClipArtPaletteBehaviour = null;

  public static get INSTANCE(): ClipArtPaletteBehaviour {
    return (
      ClipArtPaletteBehaviour._instance ??
      (ClipArtPaletteBehaviour._instance = new ClipArtPaletteBehaviour())
    );
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      new Point(itemPositionX, itemPositionY),
      graphService
    );

    PaletteItemOffsetHelper.updatePaletteItemInsertOffset(
      graphService.graphComponent
    );
  }

  startDrag(event, item, graphService): void {
    const itemCreator = (
      context,
      graph,
      draggedItem,
      dropTarget,
      dropLocation
    ): IDocumentPaletteItem =>
      this.itemCreator(
        context,
        graph,
        draggedItem,
        dropTarget,
        dropLocation,
        graphService
      );

    this.addDropInputMode(
      graphService.graphComponent.inputMode as GraphEditorInputMode,
      new ThemeElementDropInputMode(),
      itemCreator
    );

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = createDragPreview(event);
    const dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(INode.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);
    });
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: IDocumentPaletteItem,
    dropTarget: IModelItem | null,
    dropLocation: Point,
    graphService: IGraphService
  ): IDocumentPaletteItem | null {
    const size = getItemSize(draggedItem);

    const simpleNode = DiagramUtils.createSimpleImageNode(
      draggedItem.name,
      draggedItem.img,
      size,
      null
    );

    let layout = new Rect(
      dropLocation?.x ?? 0,
      dropLocation?.y ?? 0,
      size.width,
      size.height
    );
    simpleNode.tag.isAnnotation = true;
    if (draggedItem.category == DocumentPaletteCategory.Logos) {
      simpleNode.tag.annotationType = AnnotationType.Logos;
    } else {
      simpleNode.tag.annotationType = AnnotationType.ClipArt;
    }

    let node = graph.createNodeAt(
      layout,
      simpleNode.style.clone(),
      simpleNode.tag
    );

    node.labels.forEach((x) => graph.remove(x));
    // Need to set the initial label content to a blank space for the label layout to size correctly
    DiagramUtils.setLabel(graph, node, ZERO_WIDTH_SPACE);

    const position = new Point(
      layout.x - layout.width / 2,
      layout.y - layout.height / 2
    );

    const updatedLayout = new Rect(
      position,
      new Size(layout.width, layout.height)
    );

    graph.setNodeLayout(node, updatedLayout);

    if (!node.tag.isAnnotation) {
      graphService
        .getService<QuickBuildService>(QuickBuildService.$class)
        .setQuickStartState(QuickStartState.Complete);
    }

    this.removeDropInputMode(
      graphService.graphComponent.inputMode as GraphEditorInputMode
    );

    return draggedItem;
  }
}

class ThemeElementDropInputMode extends PaletteDropInputModeBase {
  populatePreviewGraph(previewGraph: IGraph): void {
    const graph = previewGraph;

    const item = this.dropData as IDocumentPaletteItem;
    const size = getItemSize(item);
    const simpleNode = DiagramUtils.createSimpleImageNode('', item.img, size);
    const node = graph.createNode(
      simpleNode.layout.toRect(),
      simpleNode.style,
      simpleNode.tag
    );

    DiagramUtils.setLabel(graph, node);
  }
}

function getItemSize(item: IDocumentPaletteItem): Size {
  let nodeSize: NodeSize = diagramConfig.svgPalette.sizes.default;
  if (item.category == DocumentPaletteCategory.Annotate) {
    nodeSize = diagramConfig.svgPalette.sizes.annotation;
  }
  if (
    item.name == diagramConfig.svgPalette.category.redCross ||
    item.name == diagramConfig.svgPalette.category.blackCross
  ) {
    nodeSize = diagramConfig.svgPalette.sizes.annotationCross;
  }
  const value =
    diagramConfig.grid.size * diagramConfig.svgPalette.sizeFactors[nodeSize];
  return new Size(value, value);
}
