import { TutorialType } from '@/api/models';
import { VisibleLayoutItems } from '@/core/services/tutorial/TutorialLayoutService';

export type SidePlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export const enum EventCondition {
  Equal,
  NotEqual,
  LessThan,
  LessThanOrEqual,
  GreaterThan,
  GreaterThanOrEqual,
  Between
}

export type StepCategory = {
  label: string;
  value: string;
  component: string;
};

export type StepAction = {
  repeat: boolean;
  repeatCount: number;
  progress: number;
  event?: {
    name: string;
    key: string | string[];
    value: any;
    label?: string;
    description?: string;
    condition?: {
      value: EventCondition;
      label: string;
    };
  };
  category?: StepCategory;
};

export type TutorialStepTranslation = {
  title: string;
  text?: string;
};

export interface Offset {
  xAxisOffset: number;
  yAxisOffset: number;
}

export enum AttachToCategory {
  Canvas = 0,
  Other = 5
}

export enum EdgeSection {
  SourceHandle = 0,
  TargetHandle = 1,
  EntireEdge = 2
}

export type TutorialStep = {
  id: string;
  title: string;
  text?: string;
  delay?: number;
  stepActions: StepAction[];
  dialogWidth: number;
  hideHighlight?: boolean;
  useModalOverlay?: boolean;
  visibleLayoutItems: VisibleLayoutItems;
  attachTo: {
    category?: AttachToCategory;
    element: string;
    side: SidePlacement;
    isWithinCanvas?: boolean;
    borderRadius?: number;
    edgeSection?: EdgeSection;
  };
  arrow?: boolean;
  restrictTo?: 'element' | 'canvas' | 'page';
  translations: Record<string, TutorialStepTranslation>;
  promptOffset?: Offset;
};

export type StepProgress = {
  value: number;
  title: string;
  translations: Record<string, { title: string }>;
};

export type TutorialProgressSettings = {
  initialProgress: number;
  // Map - { [stepId]: StepProgress }
  stepProgress: Record<string, StepProgress>;
};

/** Onboarding admin **/
export enum OnboardingAdminTabOptions {
  EditSteps = 0,
  EditTutorial = 1
}

export const enum OnboardingAdminStatus {
  Empty = '',
  Testing = 'Test mode active',
  Importing = 'Import mode active',
  Exporting = 'Export mode active',
  Error = 'There was an error'
}

export const enum OnboardingAdminLSKeys {
  VdrData = 'oa-vdr-data',
  OnboardingData = 'oa-tutorial-content'
}

export type SelectOption<T extends string | number | boolean = string> = {
  label: string;
  value: T;
};

export type EventSelectOption<T extends string | number | boolean = string> = {
  label: string;
  name: T;
  description?: string;
  disabled?: boolean;
  value?: T;
};

export type TutorialMetadata = {
  createdBy: {
    userId: number;
    customerId: string;
  };
  createdAt: string;
  version: string;
};

export type JSONExport = {
  stepsMap: TutorialStepsMap;
  cardsMap: TutorialCardsMap;
  progressSettingsMap: TutorialProgressSettingsMap;
  settings: any;
  metadata: TutorialMetadata;
};

export const enum Actions {
  CreateItem = 'create-item',
  QuickBuildButtonClick = 'quick-build',
  QuickStartButtonClick = 'quick-start-button-click',
  QuickStartSettings = 'quickstart-settings',
  NodeTools = 'node-tools',
  StartEditLabel = 'start-edit-label',
  LabelTextInput = 'label-text-input',
  EditLabel = 'edit-label',
  ExportDocument = 'export-document',
  OpenExportMenu = 'open-export-menu',
  SelectItem = 'select-item',
  ActivateQS = 'activate-qs',
  DeActivateQS = 'de-activate-qs',
  SidebarButtonClick = 'sidebar-button-click',
  NodeDecorator = 'node-decorator',
  OpenContextMenu = 'open-context-menu',
  GroupNodes = 'group-nodes',
  ItemColor = 'item-color',
  DiagramTools = 'diagram-tools',
  EdgePortChanged = 'edge-port-changed',
  MoveItems = 'move-items',
  ClickLinesMenuItem = 'click-lines-menu-item'
}

export type TutorialCardListItem = {
  title: string;
  text: string;
  highlighted?: boolean;
};

export type TutorialCardTranslation = {
  name: string;
  title: string;
  caption: string;
  greetingTitle: string;
  greetingText: string;
  footerText: string;
  listItems: TutorialCardListItem[];
};

export type TutorialCard = {
  name: string;
  icon: string;
  type: TutorialType;
  title: string;
  caption: string;
  completionTime: {
    value: number;
    unit: 'minutes' | 'hours';
  };
  listItems: TutorialCardListItem[];
  merchIncluded: boolean;
  footerText: string;
  highlighted: boolean;
  greetingTitle: string;
  greetingText: string;
  translations: Record<string, TutorialCardTranslation>;
};

export type MerchItem = {
  iconPath: string;
  info: string;
};

export type MerchSettings = {
  showMerch: boolean;
  includedText: string;
  notIncludedText: string;
  merchItems: MerchItem[];
};

export type OnboardingSettings = {
  merchSettings: MerchSettings;
};

type TutorialTypeUnion =
  | TutorialType.Beginner
  | TutorialType.Intermediate
  | TutorialType.Advanced;

export type TutorialCardsMap = Record<TutorialTypeUnion, TutorialCard>;

export type TutorialStepsMap = Record<TutorialTypeUnion, TutorialStep[]>;
export type TutorialProgressSettingsMap = Record<
  TutorialTypeUnion,
  TutorialProgressSettings
>;

type StepError = {
  message: string;
  stepIndex: number;
  actionIndex?: number;
  tutorialType?: TutorialType;
};

type CardError = {
  message: string;
  tutorialType: TutorialType;
};

export type OnboardingAdminError = {
  message?: string;
  steps?: StepError[];
  cards?: CardError[];
};

export type TutorialData = {
  stepsMap: TutorialStepsMap;
  progressSettingsMap: TutorialProgressSettingsMap;
  cardsMap: TutorialCardsMap;
  settings: OnboardingSettings;
  metadata: TutorialMetadata;
};

export type EditStepActionPayload = {
  tutorialType: TutorialType;
  stepIndex: number;
  actionIndex?: number;
  action?: StepAction;
};

export type EditStepPayload = {
  tutorialType: TutorialType;
  stepIndex?: number;
  step?: TutorialStep;
};

export type EditTutorialProgressSettingsPayload = {
  tutorialType: TutorialType;
  progressSettings: TutorialProgressSettings;
};
