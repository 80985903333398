import {
  BaseClass,
  Class,
  GeneralPath,
  IBoundsProvider,
  ICanvasContext,
  IHitTestable,
  IInputModeContext,
  ILookup,
  IMarqueeTestable,
  INode,
  INodeStyle,
  INodeStyleRenderer,
  IRectangle,
  IRenderContext,
  IShapeGeometry,
  IVisibilityTestable,
  IVisualCreator,
  Matrix,
  Point,
  Rect,
  SimpleNode,
  SvgVisual,
  SvgVisualGroup,
  Visual
} from 'yfiles';
import { createUnionPathFromSvgVisual } from './ShapeBuilder';

import CompositeItem from './CompositeItem';
import CompositeNodeStyle from './CompositeNodeStyle';
import { toRadians } from '@/core/utils/math.utils';

const RenderCacheKey = 'composite-render-cache';
type Cache = {
  itemCount: number;
  layout: IRectangle;
  group: SvgVisualGroup;
  outline?: GeneralPath;
};
export default class CompositeNodeStyleRenderer extends BaseClass<
  INodeStyleRenderer,
  IBoundsProvider,
  ILookup,
  IHitTestable,
  IMarqueeTestable,
  IShapeGeometry,
  IVisibilityTestable,
  IVisualCreator
>(
  INodeStyleRenderer,
  IBoundsProvider,
  ILookup,
  IHitTestable,
  IMarqueeTestable,
  IShapeGeometry,
  IVisibilityTestable,
  IVisualCreator
) {
  private node: INode;
  private style: CompositeNodeStyle;
  private dummyNode: SimpleNode = new SimpleNode();
  public static readonly INSTANCE = new CompositeNodeStyleRenderer();
  private configure(node: INode, style: INodeStyle): void {
    this.node = node;
    this.style = style as CompositeNodeStyle;
  }

  getBounds(context: ICanvasContext): Rect {
    return this.node.layout.toRect();
  }

  getBoundsProvider(node: INode, style: INodeStyle): IBoundsProvider {
    this.configure(node, style);
    return this;
  }

  getContext(node: INode, style: INodeStyle): ILookup {
    this.configure(node, style);
    return this;
  }

  getHitTestable(node: INode, style: INodeStyle): IHitTestable {
    this.configure(node, style);
    return this;
  }

  getMarqueeTestable(node: INode, style: INodeStyle): IMarqueeTestable {
    this.configure(node, style);
    return this;
  }
  getShapeGeometry(node: INode, style: INodeStyle): IShapeGeometry {
    this.configure(node, style);
    return this;
  }

  getVisibilityTestable(node: INode, style: INodeStyle): IVisibilityTestable {
    this.configure(node, style);
    return this;
  }

  getVisualCreator(node: INode, style: INodeStyle): IVisualCreator {
    this.configure(node, style);
    return this;
  }

  lookup<T extends unknown>(type: Class<T>): T {
    if (type == IBoundsProvider.$class) {
      return this as unknown as T;
    }

    if (type == IHitTestable.$class) {
      return this as unknown as T;
    }

    if (type == IMarqueeTestable.$class) {
      return this as unknown as T;
    }

    if (type == IShapeGeometry.$class) {
      return this as unknown as T;
    }

    if (type == IVisibilityTestable.$class) {
      return this as unknown as T;
    }

    if (type == IVisualCreator.$class) {
      return this as unknown as T;
    }
    return null;
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    return this.node.layout.contains(location);
  }

  isInBox(context: IInputModeContext, rectangle: Rect): boolean {
    return this.node.layout.toRect().intersects(rectangle);
  }

  getIntersection(inner: Point, outer: Point): Point {
    const linePath = new GeneralPath();
    const path = new GeneralPath();
    linePath.moveTo(inner);
    linePath.lineTo(outer);

    const a = path.findLineIntersection(inner, outer);

    if (a < Number.POSITIVE_INFINITY) {
      return inner.add(outer.subtract(inner).multiply(a));
    }

    return null;
  }

  getOutline(): GeneralPath {
    const cache = this.node[RenderCacheKey] as Cache;
    if (!cache) {
      // fallback to simple box
      const layout = this.node.layout;
      const path = new GeneralPath();
      path.moveTo(layout.topLeft);
      path.lineTo(layout.topRight);
      path.lineTo(layout.bottomRight);
      path.lineTo(layout.bottomLeft);
      path.close();

      return path;
    }

    if (
      !cache.outline ||
      cache.layout.width != this.node.layout.width ||
      cache.layout.height != this.node.layout.height
    ) {
      const path = createUnionPathFromSvgVisual(
        cache.group,
        cache.layout.toRect()
      );
      cache.outline = path;
    }

    const cloned = cache.outline.clone();
    const matrix = new Matrix();
    matrix.translate(this.node.layout.toPoint());
    matrix.scale(this.node.layout.width, this.node.layout.height);

    cloned.transform(matrix);
    return cloned;
  }

  isInside(location: Point): boolean {
    return this.getOutline().areaContains(location);
  }

  isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    return this.node.layout.toRect().intersects(rectangle);
  }

  createVisual(context: IRenderContext): Visual {
    const group = new SvgVisualGroup();
    const styles = this.style.getItemStyles();
    for (let i = 0; i < styles.length; i++) {
      const item = this.style.items[i];
      const style = styles[i];
      this.configureDummyNode(this.node, item, style);
      const visual = this.dummyNode.style.renderer
        .getVisualCreator(this.dummyNode, this.dummyNode.style)
        .createVisual(context) as SvgVisual;

      const matrix = new Matrix();

      matrix.rotate(this.getAngleInRadians(item), this.dummyNode.layout.center);
      const itemLayout = this.style.getItemLayout(this.node, item);
      if (visual.svgElement instanceof SVGPathElement) {
        visual.svgElement.setAttribute('x', '0');
        visual.svgElement.setAttribute('y', '0');
        matrix.translate(itemLayout.toPoint());
      }
      matrix.applyTo(visual.svgElement);

      group.add(visual);
    }

    const cache = this.createCache(this.node, group);
    this.node[RenderCacheKey] = cache;
    return group;
  }

  updateVisual(context: IRenderContext, oldVisual: Visual): Visual {
    const oldCache = this.node[RenderCacheKey] as Cache;
    if (
      !oldCache ||
      !oldCache.layout.toRect().equals(this.node.layout.toRect()) ||
      oldCache.itemCount != this.style.items.length
    ) {
      return this.createVisual(context);
    }

    const group = oldVisual as SvgVisualGroup;
    const styles = this.style.getItemStyles();
    for (let i = 0; i < styles.length; i++) {
      let visual = group.children.at(i);
      const item = this.style.items[i];
      const style = styles[i];
      this.configureDummyNode(this.node, item, style);
      visual = this.dummyNode.style.renderer
        .getVisualCreator(this.dummyNode, this.dummyNode.style)
        .updateVisual(context, visual) as SvgVisual;

      const matrix = new Matrix();

      matrix.rotate(this.getAngleInRadians(item), this.dummyNode.layout.center);
      const itemLayout = this.style.getItemLayout(this.node, item);
      if (visual.svgElement instanceof SVGPathElement) {
        visual.svgElement.setAttribute('x', '0');
        visual.svgElement.setAttribute('y', '0');
        matrix.translate(itemLayout.toPoint());
      }
      matrix.applyTo(visual.svgElement);

      group.children.set(i, visual);
    }
    if (
      oldCache.layout.width != this.node.layout.width ||
      oldCache.layout.height != this.node.layout.height
    ) {
      oldCache.layout = new Rect(
        this.node.layout.x,
        this.node.layout.y,
        this.node.layout.width,
        this.node.layout.height
      );
    }

    return group;
  }

  private createCache(node: INode, group: SvgVisualGroup): Cache {
    return {
      layout: new Rect(
        node.layout.x,
        node.layout.y,
        node.layout.width,
        node.layout.height
      ),
      itemCount: this.style.items.length,
      group: group
    };
  }

  private getAngleInRadians(item: CompositeItem): number {
    if (item.style.angle) {
      // flip angle to accomdate yFiles offset.
      return toRadians(item.style.angle * -1);
    }
    return 0;
  }

  private configureDummyNode(
    node: INode,
    item: CompositeItem,
    style: INodeStyle
  ): void {
    const shapeRect = this.style.getItemLayout(node, item);

    this.dummyNode.layout = shapeRect;
    this.dummyNode.style = style;
  }
}
