export default class PdfExportSubPage {
  diagramId?: number;
  graphSvg?: string;
  headerLayout?: string;
  headerDefinition?: any;
  footerLayout?: string;
  footerDefinition?: any;
  backgroundLayout?: string;
  backgroundDefinition?: any;
  subPageIndex: number;
}
