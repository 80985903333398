import { IPermission } from '@/api/ApplicationPermissions';
import { GET_USER_PERMISSIONS } from '@/core/services/store/user.module';
import store from '@/core/services/store';

export function hasPermission(permission: IPermission): boolean {
  try {
    const permissions = store.getters[GET_USER_PERMISSIONS];
    if (!permissions) return false;
    return permissions.includes(permission.key);
  } catch (error) {
    console.warn(error);
  }
}
