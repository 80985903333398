import IDocumentPaletteItem from '../IDocumentPaletteItem';
import { IElementDataProvider } from './IElementDataProvider';
import dividingLineData from './DividingLineData';
import humanizeString from 'humanize-string';
import DividingLinePaletteBehaviour from '../DividingLinePaletteBehaviour';
import { DocumentPaletteCategory } from '@/api/models';
export default class DividingLineDataProvider implements IElementDataProvider {
  getElements(): IDocumentPaletteItem[] {
    return dividingLineData.map<IDocumentPaletteItem>((e) => {
      return {
        category: DocumentPaletteCategory.Annotate,
        subcategory: humanizeString('Arrows'),
        img: e.imagePath,
        data: e,
        name: e.name,
        text: e.name,
        behaviour: DividingLinePaletteBehaviour.INSTANCE,
        canDrag: true
      };
    });
  }
}
