import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import appConfig from '../appConfig';
import userConfig from '../userConfig';

export default class ConfigurationBuilder {
  /**
   *
   * @param baseConfig The base configuration
   * @param config The overrides
   * @returns @param baseConfig + overrides from @param config
   */
  public static configure(baseConfig: any, config?: any): any {
    config = mergeWith(
      cloneDeep(baseConfig),
      config ?? {},
      (objValue, srcValue) => {
        // This will ensure that any objects in the "config" object always win when they are arrays,
        // instead of trying to merge
        // We could always tell the srcValue here to win if a value is defined but that might be a bit overkill.
        if (isArray(objValue)) {
          return srcValue;
        }
        //let default merge occur
      }
    );

    const uc = userConfig;
    if (uc.language) {
      if (
        uc.language.toLowerCase() ==
        appConfig.supportedLocales.enGb.toLowerCase()
      ) {
        // CKEditor built in translations for 'en' is using US spelling by default
        config.language = uc.language.toLowerCase();
      } else {
        config.language = uc.language.substr(0, 2);
      }
    }

    if (!config.removePlugins) {
      config.removePlugins = [];
    }

    return config;
  }
}
