import {
  GridInfo,
  EdgeRouter,
  EdgeRouterScope,
  EdgeRouterEdgeRoutingStyle,
  BusRouter
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';

export function getContractEdgeRouter(gridInfo: GridInfo): BusRouter {
  // Edge Routing stuff ...
  const busRouter = new BusRouter({
    scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES,
    rerouting: true,
    gridRouting: true,
    gridSpacing: diagramConfig.grid.size,
    //crossingCost: 3.0,
    minimumDistanceToNode: diagramConfig.grid.size * 2
    // minimumDistanceToEdge: 10,
    // minimumDistanceToNode: 50,
    //minimumBusConnectionsCount: 5,
    // crossingCost: 10,
  });

  console.debug('getContractEdgeRouter');
  busRouter['routerName'] = 'contract';
  return busRouter;
}

export function getCashflowEdgeRouter(gridInfo: GridInfo): EdgeRouter {
  //let set: PortCandidateSet = new PortCandidateSet()
  //set.

  // Edge Routing stuff ...
  const edgeRouter = new EdgeRouter({
    scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
  });
  edgeRouter.defaultEdgeLayoutDescriptor.routingStyle =
    EdgeRouterEdgeRoutingStyle.CURVED;
  edgeRouter.rerouting = true;
  edgeRouter.maximumDuration = 500;
  edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;

  console.debug('getCashflowEdgeRouter');
  //let curveFittingLayoutStage = new CurveFittingLayoutStage(edgeRouter);
  //curveFittingLayoutStage.affectedEdgesDpKey =
  //  EdgeRouterScope.ROUTE_AFFECTED_EDGES;
  edgeRouter['routerName'] = 'cashflow';
  //let nicerCurvesLayoutStage = new NicerCurvesLayoutStage(edgeRouter);
  return edgeRouter; //(nicerCurvesLayoutStage as unknown) as EdgeRouter;
}

export function getOwnershipEdgeRouter(gridInfo: GridInfo): EdgeRouter {
  const edgeRouter = new EdgeRouter({
    scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
  });

  edgeRouter.rerouting = true;
  edgeRouter.maximumDuration = 500;
  edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;

  console.debug('getOwnershipEdgeRouter');
  edgeRouter['routerName'] = 'ownership';
  return edgeRouter;
}

export function getDefaultEdgeRouter(gridInfo: GridInfo): EdgeRouter {
  const edgeRouter = new EdgeRouter({
    scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
  });

  edgeRouter.rerouting = true;
  edgeRouter.maximumDuration = 500;
  edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;

  console.debug('getDefaultEdgeRouter');
  edgeRouter['routerName'] = 'default';
  return edgeRouter;
}
