<template>
  <div class="w-full max-w-240">
    <AppUserStack
      :users="[currentUser]"
      no-border
      size="lg"
      background-color="bg-accent-cyan"
    />
    <div
      v-if="showUserDetails"
      class="flex w-full items-center justify-between truncate pl-8"
    >
      <div class="w-full truncate">
        <p class="truncate text-14 leading-none text-white">
          {{ currentUser.name }} {{ currentUser.surname }}
        </p>
        <p
          v-if="isUserAdmin"
          class="text-12 mt-4 leading-none text-white opacity-40"
        >
          {{ $t('ADMIN') }}
        </p>
      </div>
      <div class="w-auto">
        <div class="flex -rotate-90 transform">
          <AppIcon
            name="arrow-drop-down"
            size="20"
            class="text-white opacity-40"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import {
  GET_CURRENT_USER,
  GET_IS_USER_ADMIN
} from '@/core/services/store/user.module';

import UserLanguageMixin from '@/mixins/UserLanguage.mixin';
import PermissionMixin from '@/mixins/Permission.mixin';
import { CurrentUserProfileEditDto } from '@/api/models';
import { Getter } from 'vuex-class';
import {
  GET_IS_COMPACT_MENU,
  GET_IS_MENU_EXTENDED,
  GLOBAL_NAMESPACE
} from '@/core/services/store/global.module';

@Component({
  name: 'SidebarProfile'
})
export default class SidebarProfile extends mixins(
  UserLanguageMixin,
  PermissionMixin
) {
  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_COMPACT_MENU}`)
  compactMenu: boolean;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_MENU_EXTENDED}`)
  menuExtended: boolean;

  @Getter(GET_IS_USER_ADMIN)
  isUserAdmin: boolean;

  get showUserDetails(): boolean {
    return !this.compactMenu || (this.compactMenu && this.menuExtended);
  }
}
</script>
