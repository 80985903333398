<template>
  <ImportFile
    :dropzone-options="dropzoneOptions"
    :import-type="ImportType.PowerPoint"
  >
    <template v-slot="{ onClick }">
      <StartCreatingButton
        class="w-full"
        img="media/svg/icons/Files/Import_icon_ppt.svg"
        @click="onClick"
        data-automation-id="start-creating-button-powerpoint-import"
      >
        {{ $t('IMPORT_POWERPOINT') }}
      </StartCreatingButton>
    </template>
  </ImportFile>
</template>

<script lang="ts">
import Vue from 'vue';
import appConfig from '@/core/config/appConfig';
import { Component } from 'vue-property-decorator';
import ImportFile from '@/view/pages/hub/components/imports/ImportFile.vue';
import StartCreatingButton from '@/view/pages/hub/home/StartCreatingButton.vue';
import { ImportType } from '@/api/models';
import { DropzoneOptions } from 'dropzone';
@Component({
  name: 'ImportFromPowerPoint',
  components: {
    StartCreatingButton,
    ImportFile
  }
})
class ImportFromPowerPoint extends Vue {
  public ImportType = ImportType;

  get dropzoneOptions(): DropzoneOptions {
    return {
      acceptedFiles: appConfig.uploadFileType.powerPoint,
      dictDefaultMessage: this.$t(
        'CLICK_TO_BROWSE_FILES_OR_DRAG_A_FILE_HERE_TO_UPLOAD_PPTX_FILE'
      )
    };
  }
}
export default ImportFromPowerPoint;
</script>
