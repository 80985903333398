import JInsets from './JInsets';
import JPoint from './JPoint';
import JSize from './JSize';
import { Rect } from 'yfiles';

export default class JRect {
  public static get EMPTY(): JRect {
    return new JRect(0, 0, 0, 0);
  }
  constructor(
    public x: number,
    public y: number,
    public width: number = 0,
    public height: number = 0
  ) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  public get center(): JPoint {
    return new JPoint(this.x + this.width / 2, this.y + this.height / 2);
  }

  public get maxY(): number {
    return this.y + this.height;
  }

  public get maxX(): number {
    return this.x + this.width;
  }

  public get topRight(): JPoint {
    return new JPoint(this.maxX, this.y);
  }

  public get topLeft(): JPoint {
    return new JPoint(this.x, this.y);
  }

  public get bottomRight(): JPoint {
    return new JPoint(this.maxX, this.maxY);
  }

  public get bottomLeft(): JPoint {
    return new JPoint(this.x, this.maxY);
  }

  public equals(layout: JRect): boolean {
    const x = layout.x === this.x;
    const y = layout.y === this.y;
    const width = layout.width === this.width;
    const height = layout.height === this.height;
    return x && y && width && height;
  }

  public contains(location: JPoint, insets?: JInsets): boolean {
    const rect = this.getEnlarged(insets);

    return (
      location.x >= rect.x &&
      location.x <= rect.maxX &&
      location.y >= rect.y &&
      location.y <= rect.maxY
    );
  }

  public getEnlarged(insets: JInsets): JRect {
    if (!insets) {
      return this.clone();
    }
    const rect = this.clone();
    rect.x -= insets.left;
    rect.width += insets.right + insets.left;
    rect.y -= insets.bottom;
    rect.height += insets.top + insets.bottom;
    return rect;
  }

  public getReduced(insets: JInsets): JRect {
    if (!insets) {
      return this.clone();
    }
    const rect = this.clone();
    rect.x += insets.left;
    rect.width -= insets.right + insets.left;
    rect.y += insets.bottom;
    rect.height -= insets.top + insets.bottom;
    return rect;
  }

  public round(): JRect {
    return new JRect(
      Math.round(this.x),
      Math.round(this.y),
      Math.round(this.width),
      Math.round(this.height)
    );
  }

  public clone(): JRect {
    return new JRect(this.x, this.y, this.width, this.height);
  }

  public constrainTo(parent: JRect): JRect {
    let x = this.x;
    let y = this.y;
    if (this.x < parent.x) {
      x = parent.x;
    } else if (this.maxX > parent.width) {
      x = parent.width - this.width;
    }

    if (this.y < parent.y) {
      y = parent.y;
    } else if (this.maxY > parent.height) {
      y = parent.height - this.height;
    }

    return new JRect(x, y, this.width, this.height);
  }

  public toSize(): JSize {
    return new JSize(this.width, this.height);
  }

  public multiply(factor: number): JRect {
    return new JRect(
      this.x * factor,
      this.y * factor,
      this.width * factor,
      this.height * factor
    );
  }

  public divide(factor: number): JRect {
    return new JRect(
      this.x / factor,
      this.y / factor,
      this.width / factor,
      this.height / factor
    );
  }

  public merge(otherRect: JRect): JRect {
    if (
      !otherRect ||
      (this.contains(otherRect.topLeft) && this.contains(otherRect.bottomRight))
    ) {
      return this.clone();
    }

    const minTopLeft = new JPoint(
      Math.min(this.x, otherRect.x),
      Math.min(this.y, otherRect.y)
    );
    const minBottomRight = new JPoint(
      Math.max(this.maxX, otherRect.maxX),
      Math.max(this.maxY, otherRect.maxY)
    );

    return new JRect(
      minTopLeft.x,
      minTopLeft.y,
      minBottomRight.x - minTopLeft.x,
      minBottomRight.y - minTopLeft.y
    );
  }

  public toYFiles(): Rect {
    return new Rect(this.x, this.y, this.width, this.height);
  }

  public static fromYFiles(rect: Rect): JRect {
    return new JRect(rect.x, rect.y, rect.width, rect.height);
  }

  public static fromHtmlElement(element: HTMLElement): JRect {
    return new JRect(
      element.offsetLeft,
      element.offsetTop,
      element.clientWidth,
      element.clientHeight
    );
  }
}
