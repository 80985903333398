import Vue from 'vue';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { TutorialStep } from '@/core/services/tutorial/ITutorial';
import { TutorialEvents } from '@/core/services/tutorial/TutorialActionMediator';
import {
  GraphEditorInputMode,
  GraphItemTypes,
  MouseWheelBehaviors
} from 'yfiles';
import QuickBuildButtons from '@/v2/services/graph-service/node-button-providers/QuickBuildButtons';
import ScrollBarService from '@/core/services/graph/ScrollBarService';
import Shepherd from '@jigsaw/shepherd.js';
import JigsawButtonInputMode from '@/core/services/graph/input-modes/button/JigsawButtonInputMode';
import UIService, {
  RibbonElements,
  StickySidebarButtons
} from '@/core/services/UIService';
import { AutomationSelectors } from '@/core/services/analytics/AutomationUtils';
import CorporateDiagramHelper from '../corporate/CorporateDiagramHelper';
import IDiagramTypeHelper from '../IDiagramTypeHelper';
import JigsawKeyboardInputMode from '../graph/input-modes/JigsawKeyboardInputMode';

export type EnabledQuickBuildButtons = {
  [QuickBuildButtons.TOP]: boolean;
  [QuickBuildButtons.LEFT]: boolean;
  [QuickBuildButtons.BOTTOM]: boolean;
  [QuickBuildButtons.RIGHT]: boolean;
};

export type EnabledNodeTools = {
  linesButton: boolean;
  shapeCycle: boolean;
  editTextButton: boolean;
};

export type State = {
  nodeTools: EnabledNodeTools;
  quickBuildButtons: EnabledQuickBuildButtons;
  diagramRcm: boolean;
  editNodeLabel: boolean;
  editEdgeLabel: boolean;
};

function getGraphInputState(enabled: boolean): State {
  return {
    nodeTools: {
      linesButton: enabled,
      shapeCycle: enabled,
      editTextButton: enabled
    },
    quickBuildButtons: {
      [QuickBuildButtons.TOP]: enabled,
      [QuickBuildButtons.LEFT]: enabled,
      [QuickBuildButtons.BOTTOM]: enabled,
      [QuickBuildButtons.RIGHT]: enabled
    },
    diagramRcm: enabled,
    editNodeLabel: enabled,
    editEdgeLabel: enabled
  } as State;
}

export default class TutorialUserInputController {
  private static graphService: IGraphService;

  private static _state = getGraphInputState(true);

  public static get state(): State {
    return structuredClone(TutorialUserInputController._state);
  }

  public static get diagramRCMEnabled(): boolean {
    return TutorialUserInputController._state.diagramRcm;
  }

  public static get editNodeLabelEnabled(): boolean {
    return TutorialUserInputController._state.editNodeLabel;
  }

  public static get editEdgeLabelEnabled(): boolean {
    return TutorialUserInputController._state.editEdgeLabel;
  }

  private static setEnabledQBButtons(
    payload: Partial<EnabledQuickBuildButtons>
  ): void {
    TutorialUserInputController._state.quickBuildButtons = {
      ...TutorialUserInputController._state.quickBuildButtons,
      ...payload
    };
  }

  private static setEnabledNodeTools(payload: Partial<EnabledNodeTools>): void {
    TutorialUserInputController._state.nodeTools = {
      ...TutorialUserInputController._state.nodeTools,
      ...payload
    };
  }

  private static setDiagramRCMEnabled(enabled: boolean): void {
    TutorialUserInputController._state.diagramRcm = enabled;
  }

  private static setEditNodeLabelEnabled(enabled: boolean): void {
    TutorialUserInputController._state.editNodeLabel = enabled;
  }

  private static setEditEdgeLabelEnabled(enabled: boolean): void {
    TutorialUserInputController._state.editEdgeLabel = enabled;
  }

  private static setState(enabled: boolean): void {
    TutorialUserInputController._state = getGraphInputState(enabled);
  }

  private static get geim(): GraphEditorInputMode {
    return TutorialUserInputController.graphService?.graphComponent
      .inputMode as GraphEditorInputMode;
  }

  public static async init(graphService: IGraphService): Promise<void> {
    TutorialUserInputController.graphService = graphService;
    await TutorialUserInputController.disableAll();
    UIService.toggleDisabledStateForTrackedElements(true);
  }

  public static async handleStep(
    tour: Shepherd.Tour,
    step: TutorialStep
  ): Promise<void> {
    if (!step) {
      TutorialUserInputController.reset();
      UIService.toggleDisabledStateForTrackedElements(false);
      return;
    }

    UIService.toggleDisabledStateForTrackedElements(true);
    await TutorialUserInputController.disableAll();
    (tour as any).options.useModalOverlay = false;

    for (const action of step.stepActions) {
      switch (action.event.name) {
        case TutorialEvents.NODE_CREATED:
        case TutorialEvents.NODE_CREATED_CORPORATE:
        case TutorialEvents.NODE_CREATED_PARTNERSHIP:
        case TutorialEvents.NODE_CREATED_TRUST:
          TutorialUserInputController.toggleClickInputMode(true);
          break;
        case TutorialEvents.EDGE_CREATED:
        case TutorialEvents.EDGE_CREATED_OWNERSHIP:
        case TutorialEvents.EDGE_CREATED_CONTRACT:
        case TutorialEvents.EDGE_CREATED_CASHFLOW:
          TutorialUserInputController.toggleClickInputMode(true);
          TutorialUserInputController.setEnabledNodeTools({
            linesButton: true
          });
          break;
        case TutorialEvents.DIAGRAM_SELECTION_CHANGED:
        case TutorialEvents.DIAGRAM_NODE_SELECTION_CHANGED:
        case TutorialEvents.DIAGRAM_EDGE_SELECTION_CHANGED:
          (tour as any).options.useModalOverlay = true;
          TutorialUserInputController.toggleClickInputMode(true);
          TutorialUserInputController.toggleSelectableItems(true);
          break;
        case TutorialEvents.QUICK_BUILD_BUTTON_CLICK_ANY:
          TutorialUserInputController.setEnabledQBButtons({
            [QuickBuildButtons.TOP]: true,
            [QuickBuildButtons.LEFT]: true,
            [QuickBuildButtons.BOTTOM]: true,
            [QuickBuildButtons.RIGHT]: true
          });
          break;
        case TutorialEvents.QUICK_BUILD_BUTTON_CLICK_TOP:
          TutorialUserInputController.setEnabledQBButtons({
            [QuickBuildButtons.TOP]: true,
            [QuickBuildButtons.LEFT]: false,
            [QuickBuildButtons.BOTTOM]: false,
            [QuickBuildButtons.RIGHT]: false
          });
          break;
        case TutorialEvents.QUICK_BUILD_BUTTON_CLICK_RIGHT:
          TutorialUserInputController.setEnabledQBButtons({
            [QuickBuildButtons.TOP]: false,
            [QuickBuildButtons.LEFT]: false,
            [QuickBuildButtons.BOTTOM]: false,
            [QuickBuildButtons.RIGHT]: true
          });
          break;
        case TutorialEvents.QUICK_BUILD_BUTTON_CLICK_BOTTOM:
          TutorialUserInputController.setEnabledQBButtons({
            [QuickBuildButtons.TOP]: false,
            [QuickBuildButtons.LEFT]: false,
            [QuickBuildButtons.BOTTOM]: true,
            [QuickBuildButtons.RIGHT]: false
          });
          break;
        case TutorialEvents.QUICK_BUILD_BUTTON_CLICK_LEFT:
          TutorialUserInputController.setEnabledQBButtons({
            [QuickBuildButtons.TOP]: false,
            [QuickBuildButtons.LEFT]: true,
            [QuickBuildButtons.BOTTOM]: false,
            [QuickBuildButtons.RIGHT]: false
          });
          break;
        case TutorialEvents.NODE_TOOLS_SHAPE_CYCLE: {
          (tour as any).options.useModalOverlay = true;
          TutorialUserInputController.setEnabledNodeTools({
            shapeCycle: true
          });
          break;
        }
        case TutorialEvents.NODE_TOOLS_EDIT_TEXT:
          TutorialUserInputController.setEnabledNodeTools({
            editTextButton: true
          });
          break;
        case TutorialEvents.NODE_TOOLS_LINES_MENU:
          (tour as any).options.useModalOverlay = true;
          TutorialUserInputController.setEnabledNodeTools({
            linesButton: true
          });
          break;
        case TutorialEvents.CLICK_LINES_MENU_ITEM:
          (tour as any).options.useModalOverlay = true;
          TutorialUserInputController.setEnabledNodeTools({
            linesButton: true
          });
          UIService.toggleContextMenuItemsDisabledState([
            `${AutomationSelectors.EDGE_MENU}-${action.event.value}`
          ]);
          break;
        case TutorialEvents.START_EDIT_ANY_LABEL:
        case TutorialEvents.START_EDIT_EDGE_LABEL:
        case TutorialEvents.START_EDIT_NODE_LABEL:
          (tour as any).options.useModalOverlay = true;
          TutorialUserInputController.toggleClickInputMode(true);
          TutorialUserInputController.toggleEditLabel(true);
          break;
        case TutorialEvents.LABEL_TEXT_INPUT:
        case TutorialEvents.NODE_LABEL_TEXT_INPUT:
        case TutorialEvents.EDIT_ANY_LABEL:
        case TutorialEvents.EDIT_NODE_LABEL:
          TutorialUserInputController.toggleClickInputMode(true);
          TutorialUserInputController.toggleEditLabel(true);
          TutorialUserInputController.setEditNodeLabelEnabled(true);
          break;

        case TutorialEvents.EDIT_EDGE_LABEL:
        case TutorialEvents.EDGE_LABEL_TEXT_INPUT:
          TutorialUserInputController.toggleClickInputMode(true);
          TutorialUserInputController.toggleEditLabel(true);
          TutorialUserInputController.setEditEdgeLabelEnabled(true);
          break;

        case TutorialEvents.SIDEBAR_BUTTON_CLICK_TEXT:
        case TutorialEvents.SIDEBAR_BUTTON_CLICK_SHAPES:
        case TutorialEvents.SIDEBAR_BUTTON_CLICK_LINES:
        case TutorialEvents.SIDEBAR_BUTTON_CLICK_LOGO:
        case TutorialEvents.SIDEBAR_BUTTON_CLICK_THEMES:
        case TutorialEvents.SHOW_CONTEXT_MENU:
        case TutorialEvents.CHANGE_EDGE_PORT:
        case TutorialEvents.EXPORT_OPEN_MENU:
          (tour as any).options.useModalOverlay = true;
          UIService.toggleRibbonItems(true, [
            RibbonElements.MenuFileSaveExportFunctionsExport
          ]);
          break;
        case TutorialEvents.SIDEBAR_BUTTON_CLICK_LEGEND:
          (tour as any).options.useModalOverlay = true;
          UIService.toggleStickySidebarItems(true, [
            StickySidebarButtons.Legend
          ]);
          break;
      }
    }

    this.queryButtons();
  }

  private static queryButtons(): void {
    const jigsawButtonInputMode =
      TutorialUserInputController.graphService.getService<JigsawButtonInputMode>(
        JigsawButtonInputMode.$class.name
      );
    if (jigsawButtonInputMode) {
      jigsawButtonInputMode.queryAllButtons();
    }
  }

  public static reset(): void {
    TutorialUserInputController.toggle(true);
    UIService.destroy();
  }

  public static async disableAll(): Promise<void> {
    await TutorialUserInputController.toggle(false);
  }

  private static async toggle(enabled: boolean): Promise<void> {
    TutorialUserInputController.setState(enabled);

    if (!TutorialUserInputController.geim) return;

    TutorialUserInputController.geim.moveInputMode.enabled = enabled;
    TutorialUserInputController.geim.moveUnselectedInputMode.enabled = enabled;
    TutorialUserInputController.geim.moveViewportInputMode.enabled = enabled;
    TutorialUserInputController.geim.createBendInputMode.enabled = enabled;
    TutorialUserInputController.geim.handleInputMode.enabled = enabled;
    TutorialUserInputController.geim.allowClipboardOperations = enabled;
    TutorialUserInputController.geim.clickInputMode.enabled = enabled;
    TutorialUserInputController.geim.marqueeSelectionInputMode.enabled =
      enabled;
    TutorialUserInputController.graphService.graphComponent.inputModeContext.canvasComponent.autoDrag =
      enabled;
    const kim = TutorialUserInputController.geim
      .getSortedModes()
      .find(
        (d) => d instanceof JigsawKeyboardInputMode
      ) as JigsawKeyboardInputMode;
    if (kim) {
      kim.enabled = enabled;
    }

    const corporateDiagramHelper =
      TutorialUserInputController.graphService.getService<CorporateDiagramHelper>(
        IDiagramTypeHelper.$class
      );
    if (corporateDiagramHelper) {
      corporateDiagramHelper.canvasMoveBehaviour.enabled = enabled;
    }

    if (enabled) {
      TutorialUserInputController.geim.deletableItems = GraphItemTypes.ALL;
    } else {
      TutorialUserInputController.geim.deletableItems = GraphItemTypes.NONE;
      TutorialUserInputController.geim.textEditorInputMode.cancel();
    }

    TutorialUserInputController.toggleSelectableItems(enabled);
    TutorialUserInputController.toggleScrollbars(enabled);
    TutorialUserInputController.toggleEditLabel(enabled);

    TutorialUserInputController.setDiagramRCMEnabled(enabled);
    TutorialUserInputController.setEditNodeLabelEnabled(enabled);
    TutorialUserInputController.setEditEdgeLabelEnabled(enabled);
  }

  private static toggleScrollbars(enabled: boolean): void {
    const scrollBarService =
      TutorialUserInputController.graphService.getService<ScrollBarService>(
        ScrollBarService.$class
      );
    if (scrollBarService) {
      if (enabled) {
        scrollBarService.enableScrollBars();
      } else {
        scrollBarService.disableScrollBars();
      }
    }

    TutorialUserInputController.graphService.graphComponent.mouseWheelBehavior =
      enabled
        ? MouseWheelBehaviors.SCROLL | MouseWheelBehaviors.ZOOM
        : MouseWheelBehaviors.NONE;
  }

  private static toggleClickInputMode(enabled: boolean): void {
    if (TutorialUserInputController.geim) {
      TutorialUserInputController.geim.clickInputMode.enabled = enabled;
    }
  }

  private static toggleSelectableItems(enabled: boolean): void {
    if (!TutorialUserInputController.geim) return;

    let graphItemTypes = GraphItemTypes.NONE;
    if (enabled) {
      graphItemTypes =
        GraphItemTypes.NODE | GraphItemTypes.EDGE | GraphItemTypes.LABEL;
    }

    TutorialUserInputController.geim.clickSelectableItems = graphItemTypes;
  }

  private static toggleEditLabel(enabled: boolean): void {
    if (TutorialUserInputController.geim) {
      TutorialUserInputController.geim.allowEditLabel = enabled;
    }
  }
}
