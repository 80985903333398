import ISearchParameter from './ISearchParameter';

export default class TextParameter implements ISearchParameter {
  public text: string;

  constructor(text: string) {
    this.text = text;
  }

  public equals(other: ISearchParameter): boolean {
    return other instanceof TextParameter && other.text == this.text;
  }
}
