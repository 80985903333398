<template>
  <AppFlyout
    :placement="placement"
    trigger="hover"
    show-arrow
    propagate
    :content-class="contentClasses"
    :max-width="maxWidth"
    :append-to-body="appendToBody"
    :same-or-wider="sameOrWider"
    :disabled="disabled"
    :activator-class="activatorClass"
    :fallback-placements="fallbackPlacements"
    :offset="offset"
    :offsetX="offsetX"
    ref="flyout"
  >
    <template #activator>
      <slot />
    </template>

    <template #popper>
      <div name="content" class="relative" :class="popperClasses">
        <slot name="content">
          {{ content }}
        </slot>
      </div>
    </template>
  </AppFlyout>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import AppFlyout, { Placement } from './AppFlyout.vue';
import ExportConfig from '@/core/config/ExportConfig';

@Component({
  name: 'AppTooltip'
})
export default class AppTooltip extends Vue {
  @Prop({ default: 'auto' })
  placement: Placement;

  @Prop({ required: false, type: String })
  activatorClass: string;

  @Prop({ default: '' })
  content: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: true, type: Boolean })
  visible: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: false, type: Boolean })
  sameOrWider: boolean;

  @Prop({ default: 10 })
  offset: number;

  @Prop({ default: 0 })
  offsetX: number;

  @Prop({
    default: ExportConfig.tooltipCssClasses,
    type: String
  })
  contentClasses: string;

  @Prop({ default: '', type: String })
  popperClasses: string;

  @Prop({ default: () => [], type: Array })
  fallbackPlacements: Array<Placement>;

  $refs: {
    flyout: AppFlyout | null;
  };

  @Watch('visible')
  visibleChanged(newValue: boolean): void {
    if (!newValue) {
      this.$refs.flyout.show();
    } else {
      this.$refs.flyout.hide();
    }
  }
}
</script>
