<template>
  <div
    class="flex h-full w-full animate-skeleton-pulse flex-col justify-between bg-palatinate-blue"
  >
    <div class="mb-24 w-full px-24">
      <div class="block w-full max-w-160">
        <img
          v-if="!isHalloween"
          src="/resources/icons/dash/full_logo.svg"
          alt="Jigsaw Logo"
        />
        <img
          v-else
          src="/resources/icons/full_logo_halloween.svg"
          alt="Jigsaw Logo"
        />
        <!-- <img src="/resources/icons/dash/full_logo.svg" alt="Jigsaw Logo" /> -->
      </div>
    </div>

    <div class="h-full px-24">
      <div
        v-for="i in 6"
        :key="`top-${i}`"
        class="flex h-60 w-full items-center"
      >
        <div class="mr-16 h-24 w-24 flex-shrink-0 bg-main-dark-20" />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
    </div>

    <div class="flex h-full flex-col justify-end px-24">
      <div
        v-for="i in 4"
        :key="`bottom-${i}`"
        class="flex h-60 w-full items-center"
      >
        <div class="mr-16 h-24 w-24 flex-shrink-0 bg-main-dark-20" />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
      <div class="mb-24 flex h-60 w-full items-center">
        <div
          class="mr-8 h-32 w-32 flex-shrink-0 rounded-full bg-main-dark-20"
        />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  GET_HALLOWEEN_THEME,
  GLOBAL_NAMESPACE
} from '@/core/services/store/global.module';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'SidebarSkeletonLoading'
})
export default class SidebarSkeletonLoading extends Vue {
  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE
  })
  isHalloween: boolean;
}
</script>
