import isNil from 'lodash/isNil';
import { ActionContext, Module } from 'vuex';
import {
  EditStepActionPayload,
  EditStepPayload,
  EditTutorialProgressSettingsPayload,
  OnboardingAdminError,
  OnboardingAdminStatus,
  OnboardingSettings,
  TutorialCard,
  TutorialCardsMap,
  TutorialData,
  TutorialMetadata,
  TutorialProgressSettingsMap,
  TutorialStep,
  TutorialStepsMap
} from '@/core/services/tutorial/ITutorial';
import TutorialsApiService from '@/api/TutorialsApiService';
import { TutorialDto, TutorialType } from '@/api/models';
import {
  createDefaultProgressSettingsMap,
  createEmptyCards,
  createEmptyStep,
  createEmptyStepAction,
  createEmptySteps
} from '@/core/services/tutorial-data/TutorialDataUtils';
import TutorialDataService from '@/core/services/tutorial-data/TutorialDataService';
import Vue from 'vue';
import appConfig from '@/core/config/appConfig';

export const TRAINING_NAMESPACE = 'training-module';

// GETTERS
export const GET_TUTORIAL_CARDS_MAP = 'get-tutorial-cards-map';
export const GET_ONBOARDING_SETTINGS = 'get-onboarding-settings';
export const GET_TUTORIALS_STEPS_MAP = 'get-tutorials-steps-map';
export const GET_TUTORIALS_PROGRESS_SETTINGS_MAP =
  'get-tutorials-progress-settings-map';
export const GET_TUTORIALS_STATUS = 'get-tutorials-status';
export const GET_ONBOARDING_ADMIN_MINIMIZED = 'get-onboarding-admin-min';
export const GET_ONBOARDING_ADMIN_STATUS = 'get-onboarding-admin-status';
export const SHOW_ONBOARDING_ADMIN_UI = 'show-onboarding-admin-ui';
export const GET_CURRENT_TOUR_STEP = 'get-current-tour-step';
export const GET_CURRENT_TOUR_TYPE = 'get-current-tour-type';
export const IS_TOUR_IN_PROGRESS = 'is-tour-in-progress';
export const GET_ERROR_DETAILS = 'get-error-details';
export const HAS_ERROR_DETAILS = 'has-error-details';
export const GET_DISPLAY_LANGUAGE = 'get-display-language';

// ACTIONS
export const SET_TUTORIAL_STEPS = 'set-tutorial-steps';
export const SET_TUTORIAL_CARD = 'set-tutorial-card';
export const LOAD_DATA = 'load-data';
export const TOGGLE_ONBOARDING_ADMIN_UI = 'toggle-onboarding-admin-ui';
export const ONBOARDING_ADMIN_MINIMIZE = 'onboarding-admin-min';
export const ONBOARDING_ADMIN_MAXIMIZE = 'onboarding-admin-max';
export const SET_ONBOARDING_ADMIN_STATUS = 'set-onboarding-admin-status';
export const LOAD_TUTORIALS_STATUS = 'load-tutorials-status';
export const RESET_ADMIN_UI_STATE = 'reset-admin-ui-state';
export const GET_DATA = 'get-data';
export const SET_TOUR_STEP = 'set-tour-step';
export const SET_TOUR_TYPE = 'set-tour-type';
export const SET_TOUR_DETAILS = 'set-tour-details';
export const SET_ERROR_DETAILS = 'set-error-details';
export const SET_DISPLAY_LANGUAGE = 'set-display-language';
export const DELETE_STEP_ACTION = 'delete-step-action';
export const UPDATE_STEP_ACTION = 'update-step-action';
export const UPDATE_TUTORIAL_PROGRESS_SETTINGS =
  'update-tutorial-progress-settings';
export const SET_TUTORIAL_STEP = 'set-tutorial-step';
export const SET_EDIT_STEP_DETAILS = 'set-edit-step-details';
export const DELETE_STEPS = 'delete-steps';
export const ADD_STEP = 'add-step';
export const ADD_STEP_ACTION = 'add-step-action';

type TourDetails = { currentStep: TutorialStep; type: TutorialType };

type State = {
  tutorialsStatus: TutorialDto[];
  stepsMap: TutorialStepsMap;
  cardsMap: TutorialCardsMap;
  progressSettingsMap: TutorialProgressSettingsMap;
  settings: OnboardingSettings;
  metadata: TutorialMetadata;
  tour: TourDetails;
  adminUi: {
    show: boolean;
    minimized: boolean;
    status: OnboardingAdminStatus;
  };
  errorDetails: OnboardingAdminError | null;
  displayLanguage: string;
};

function getDefaultData(): TutorialData {
  return {
    stepsMap: createEmptySteps(),
    cardsMap: createEmptyCards(),
    progressSettingsMap: createDefaultProgressSettingsMap(),
    metadata: null,
    settings: {
      merchSettings: {
        showMerch: false,
        includedText: '',
        notIncludedText: '',
        merchItems: []
      }
    }
  };
}

const trainingModule: Module<State, any> = {
  namespaced: true,
  state: {
    tutorialsStatus: null,
    cardsMap: createEmptyCards(),
    stepsMap: createEmptySteps(),
    progressSettingsMap: createDefaultProgressSettingsMap(),
    settings: null,
    metadata: null,
    tour: {
      currentStep: null,
      type: null
    },
    adminUi: {
      show: false,
      minimized: false,
      status: OnboardingAdminStatus.Empty
    },
    errorDetails: null,
    displayLanguage: null
  },
  getters: {
    [GET_TUTORIALS_STATUS](state): TutorialDto[] {
      return state.tutorialsStatus;
    },
    [GET_TUTORIAL_CARDS_MAP](state): TutorialCardsMap {
      return state.cardsMap;
    },
    [GET_TUTORIALS_STEPS_MAP](state): TutorialStepsMap {
      return state.stepsMap;
    },
    [GET_TUTORIALS_PROGRESS_SETTINGS_MAP](state): TutorialProgressSettingsMap {
      return state.progressSettingsMap;
    },
    [GET_ONBOARDING_SETTINGS](state): OnboardingSettings {
      return state.settings;
    },
    [GET_DATA](state): TutorialData {
      return {
        stepsMap: state.stepsMap,
        progressSettingsMap: state.progressSettingsMap,
        cardsMap: state.cardsMap,
        settings: state.settings,
        metadata: state.metadata
      };
    },
    [SHOW_ONBOARDING_ADMIN_UI](state): boolean {
      return state.adminUi.show;
    },
    [GET_ONBOARDING_ADMIN_MINIMIZED](state): boolean {
      return state.adminUi.minimized;
    },
    [GET_ONBOARDING_ADMIN_STATUS](state): OnboardingAdminStatus {
      return state.adminUi.status;
    },
    [GET_CURRENT_TOUR_STEP](state): TutorialStep {
      return state.tour.currentStep;
    },
    [GET_CURRENT_TOUR_TYPE](state): TutorialType {
      return state.tour.type;
    },
    [IS_TOUR_IN_PROGRESS](state): boolean {
      return !isNil(state.tour.currentStep) && !isNil(state.tour.type);
    },
    [GET_ERROR_DETAILS](state): OnboardingAdminError {
      return state.errorDetails;
    },
    [HAS_ERROR_DETAILS](state: State): boolean {
      return !!(
        state.errorDetails &&
        (state.errorDetails.message ||
          state.errorDetails.steps?.length ||
          state.errorDetails.cards?.length)
      );
    },
    [GET_DISPLAY_LANGUAGE](state): string {
      return state.displayLanguage;
    }
  },
  actions: {
    async [LOAD_TUTORIALS_STATUS](
      context: ActionContext<State, any>
    ): Promise<void> {
      const tutorialsStatus: TutorialDto[] = (
        await TutorialsApiService.getAll()
      ).data.result;
      context.commit(LOAD_TUTORIALS_STATUS, tutorialsStatus);
    },

    [TOGGLE_ONBOARDING_ADMIN_UI](context: ActionContext<State, any>): void {
      context.commit(TOGGLE_ONBOARDING_ADMIN_UI, !context.state.adminUi.show);
    },

    [ONBOARDING_ADMIN_MINIMIZE](context: ActionContext<State, any>): void {
      context.commit(ONBOARDING_ADMIN_MINIMIZE, true);
    },

    [ONBOARDING_ADMIN_MAXIMIZE](context: ActionContext<State, any>): void {
      context.commit(ONBOARDING_ADMIN_MINIMIZE, false);
    },

    [RESET_ADMIN_UI_STATE](context: ActionContext<State, any>): void {
      context.commit(RESET_ADMIN_UI_STATE);
    },

    [LOAD_DATA](
      context: ActionContext<State, any>,
      data?: string | TutorialData
    ): void {
      if (!data) {
        // Admin UI not visible - load user data;
        if (!context.state.adminUi.show) {
          let userTutorialData: TutorialData;
          try {
            userTutorialData = JSON.parse(
              appConfig.tutorialSettings.tutorialData
            );
          } catch (error) {
            console.error('Error parsing tutorial data:', error);
            userTutorialData = getDefaultData();
          }
          context.commit(LOAD_DATA, userTutorialData);
          return;
        }

        const savedData = TutorialDataService.fromLocalStorage();
        if (savedData) {
          // Backward compatibility keep this a while until we
          // update all saved data
          if (Object.hasOwn(savedData, 'cards')) {
            savedData.cardsMap = savedData['cards'];
            delete savedData['cards'];
          }
          if (Object.hasOwn(savedData, 'steps')) {
            savedData.stepsMap = savedData['steps'];
            delete savedData['steps'];
          }
          context.commit(LOAD_DATA, savedData);
          return;
        }
      } else {
        TutorialDataService.toLocalStorage(data);
        if (typeof data === 'string') {
          context.commit(
            LOAD_DATA,
            TutorialDataService.deserialize(data as string)
          );
        } else {
          context.commit(LOAD_DATA, data);
        }
        return;
      }

      context.commit(LOAD_DATA, getDefaultData());
    },

    [SET_TUTORIAL_STEPS](
      context: ActionContext<State, any>,
      payload: { tutorialType: TutorialType; steps: TutorialStep[] }
    ) {
      context.commit(SET_TUTORIAL_STEPS, payload);
    },
    [SET_TUTORIAL_CARD](
      context: ActionContext<State, any>,
      payload: TutorialCard
    ) {
      context.commit(SET_TUTORIAL_CARD, payload);
    },
    [SET_ONBOARDING_ADMIN_STATUS](
      context: ActionContext<State, any>,
      payload: OnboardingAdminStatus
    ): void {
      context.commit(SET_ONBOARDING_ADMIN_STATUS, payload);
    },
    [SET_TOUR_STEP](
      context: ActionContext<State, any>,
      payload: TutorialStep
    ): void {
      context.commit(SET_TOUR_STEP, payload);
    },
    [SET_TOUR_TYPE](
      context: ActionContext<State, any>,
      payload: TutorialType
    ): void {
      context.commit(SET_TOUR_TYPE, payload);
    },
    [SET_TOUR_DETAILS](
      context: ActionContext<State, any>,
      payload: TourDetails
    ): void {
      context.commit(SET_TOUR_DETAILS, payload);
    },
    [SET_ERROR_DETAILS](
      context: ActionContext<State, any>,
      payload: OnboardingAdminError
    ): void {
      context.commit(SET_ERROR_DETAILS, payload);

      if (context.getters[HAS_ERROR_DETAILS]) {
        context.commit(
          SET_ONBOARDING_ADMIN_STATUS,
          OnboardingAdminStatus.Error
        );
      } else if (
        context.getters[GET_ONBOARDING_ADMIN_STATUS] ===
        OnboardingAdminStatus.Error
      ) {
        context.commit(SET_ONBOARDING_ADMIN_STATUS);
      }
    },
    [SET_DISPLAY_LANGUAGE](
      context: ActionContext<State, any>,
      payload: string
    ): void {
      context.commit(SET_DISPLAY_LANGUAGE, payload);
    },
    [DELETE_STEP_ACTION](
      context: ActionContext<State, any>,
      payload: EditStepActionPayload
    ): void {
      context.commit(DELETE_STEP_ACTION, payload);
    },
    [UPDATE_STEP_ACTION](
      context: ActionContext<State, any>,
      payload: EditStepActionPayload
    ): void {
      context.commit(UPDATE_STEP_ACTION, payload);
    },
    [SET_TUTORIAL_STEP](
      context: ActionContext<State, any>,
      payload: EditStepPayload
    ): void {
      context.commit(SET_TUTORIAL_STEP, payload);
    },
    [SET_EDIT_STEP_DETAILS](
      context: ActionContext<State, any>,
      payload: EditStepPayload
    ): void {
      context.commit(SET_EDIT_STEP_DETAILS, payload);
    },
    [DELETE_STEPS](
      context: ActionContext<State, any>,
      payload: EditStepPayload
    ): void {
      context.commit(DELETE_STEPS, payload);
    },
    [ADD_STEP](
      context: ActionContext<State, any>,
      payload: EditStepPayload
    ): void {
      context.commit(ADD_STEP, payload);
    },
    [ADD_STEP_ACTION](
      context: ActionContext<State, any>,
      payload: EditStepActionPayload
    ): void {
      context.commit(ADD_STEP_ACTION, payload);
    },
    [UPDATE_TUTORIAL_PROGRESS_SETTINGS](
      context: ActionContext<State, any>,
      payload: EditTutorialProgressSettingsPayload
    ): void {
      context.commit(UPDATE_TUTORIAL_PROGRESS_SETTINGS, payload);
    }
  },
  mutations: {
    [LOAD_TUTORIALS_STATUS](state: State, payload: TutorialDto[]): void {
      state.tutorialsStatus = payload;
    },
    [SET_TUTORIAL_STEPS](
      state: State,
      payload: { tutorialType: TutorialType; steps: TutorialStep[] }
    ) {
      state.stepsMap[payload.tutorialType] = payload.steps;
      TutorialDataService.toLocalStorage(state);
    },
    [SET_TUTORIAL_CARD](state: State, payload: TutorialCard) {
      state.cardsMap[payload.type] = payload;
      TutorialDataService.toLocalStorage(state);
    },
    [TOGGLE_ONBOARDING_ADMIN_UI](state: State, payload: boolean): void {
      state.adminUi.show = payload;
    },
    [ONBOARDING_ADMIN_MINIMIZE](state: State, payload: boolean): void {
      state.adminUi.minimized = payload;
      if (!payload) {
        state.adminUi.status = OnboardingAdminStatus.Empty;
      }
    },
    [SET_ONBOARDING_ADMIN_STATUS](
      state: State,
      payload?: OnboardingAdminStatus
    ): void {
      state.adminUi.status = payload ?? OnboardingAdminStatus.Empty;
    },
    [RESET_ADMIN_UI_STATE](state: State): void {
      state.adminUi.show = false;
      state.adminUi.minimized = false;
      state.adminUi.status = OnboardingAdminStatus.Empty;
    },
    [LOAD_DATA](state: State, payload: TutorialData): void {
      state.stepsMap = payload.stepsMap;
      state.cardsMap = payload.cardsMap;
      state.progressSettingsMap = payload.progressSettingsMap;
      state.settings = payload.settings;
      state.metadata = payload.metadata;
    },
    [SET_TOUR_STEP](state: State, payload: TutorialStep): void {
      state.tour.currentStep = payload;
    },
    [SET_TOUR_TYPE](state: State, payload: TutorialType): void {
      state.tour.type = payload;
    },
    [SET_TOUR_DETAILS](state: State, payload: TourDetails): void {
      state.tour = payload;
    },
    [SET_ERROR_DETAILS](state: State, payload: OnboardingAdminError): void {
      state.errorDetails = payload;
    },
    [SET_DISPLAY_LANGUAGE](state: State, payload: string): void {
      state.displayLanguage = payload;
    },
    [SET_TUTORIAL_STEP](state: State, payload: EditStepPayload): void {
      const { tutorialType, stepIndex, step } = payload;
      Vue.set(state.stepsMap[tutorialType], stepIndex, step);
    },
    [DELETE_STEPS](state: State, payload: EditStepPayload): void {
      const { tutorialType, stepIndex } = payload;

      if (Number.isInteger(stepIndex)) {
        state.stepsMap[tutorialType].splice(stepIndex, 1);
      } else {
        state.stepsMap[tutorialType] = [];
      }
    },
    [ADD_STEP](state: State, payload: EditStepPayload): void {
      const { tutorialType } = payload;
      const existingSteps = state.stepsMap[tutorialType];
      existingSteps.push(createEmptyStep(`${existingSteps.length}`));
    },
    [ADD_STEP_ACTION](state: State, payload: EditStepActionPayload): void {
      const { tutorialType, stepIndex } = payload;
      state.stepsMap[tutorialType][stepIndex].stepActions.push(
        createEmptyStepAction()
      );
    },
    [DELETE_STEP_ACTION](state: State, payload: EditStepActionPayload): void {
      const { tutorialType, stepIndex, actionIndex } = payload;
      state.stepsMap[tutorialType][stepIndex].stepActions.splice(
        actionIndex,
        1
      );
    },
    [UPDATE_STEP_ACTION](state: State, payload: EditStepActionPayload): void {
      const { tutorialType, stepIndex, actionIndex, action } = payload;

      Vue.set(
        state.stepsMap[tutorialType][stepIndex].stepActions,
        actionIndex,
        action
      );
    },
    [UPDATE_TUTORIAL_PROGRESS_SETTINGS](
      state: State,
      payload: EditTutorialProgressSettingsPayload
    ): void {
      state.progressSettingsMap[payload.tutorialType] =
        payload.progressSettings;

      TutorialDataService.toLocalStorage(state);
    }
  }
};

export default trainingModule;
