import { Module } from 'vuex';
import { INode } from 'yfiles';

interface State {
  isSearchFocused: boolean;
  searchItems: INode[];
  searchedItem: INode;
}

export const DOCUMENT_SEARCH_NAMESPACE = 'documentSearch';

export const GET_IS_SEARCH_FOCUSED = 'getIsSearchFocused';
export const SET_IS_SEARCH_FOCUSED = 'setIsSearchFocused';

export const GET_SEARCH_ITEMS = 'getSearchItems';
export const SET_SEARCH_ITEMS = 'setSearchItems';

export const GET_SEARCHED_ITEM = 'getSearchedItem';
export const SET_SEARCHED_ITEM = 'setSearchedItem';

const documentSearchModule: Module<State, any> = {
  namespaced: true,
  state: {
    isSearchFocused: false,
    searchItems: [],
    searchedItem: null
  },

  getters: {
    [GET_IS_SEARCH_FOCUSED](state) {
      return state.isSearchFocused;
    },

    [GET_SEARCH_ITEMS](state) {
      return state.searchItems;
    },

    [GET_SEARCHED_ITEM](state) {
      return state.searchedItem;
    }
  },

  mutations: {
    [SET_IS_SEARCH_FOCUSED](state, payload: boolean) {
      state.isSearchFocused = payload;
    },

    [SET_SEARCH_ITEMS](state, payload: INode[]) {
      state.searchItems = payload;
    },

    [SET_SEARCHED_ITEM](state, payload: INode) {
      state.searchedItem = payload;
    }
  }
};

export default documentSearchModule;
