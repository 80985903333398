export enum Features {
  // Licence features
  StructureDiagram = 'StructureDiagram',
  PersonnelChart = 'PersonnelChart',
  Import = 'Import',
  Steps = 'Steps',
  StepsLite = 'StepsLite',
  AutoLayout = 'AutoLayout',
  iManage = 'IManage',
  DataEnhancement = 'DataEnhancement',
  StepsHide = 'StepsHide',
  Mirroring = 'Mirroring',
  Flipbook = 'Flipbook',
  StepsDesign = 'StepsDesign',
  ImagePages = 'ImagePages',
  UnlicenceUsers = 'UnlicenceUsers',
  TextPages = 'TextPages',
  ContinuationSheets = 'ContinuationSheets',
  Demo = 'Demo',
  Timelines = 'Timelines',
  ImportExcel = 'ImportExcel',
  ImportPowerPoint = 'ImportPowerPoint',
  ImportExcelBuilder = 'ImportExcelBuilder',
  DataLite = 'DataLite',
  DataFull = 'DataFull',
  Versioning = 'Versioning',
  ConfidentialInformation = 'ConfidentialInformation',
  OwnershipCalculation = 'OwnershipCalculation',
  SeparateBackgroundPageDesign = 'SeparateBackgroundPageDesign',
  FilteringNew = 'FilteringNew',
  Onboarding = 'Onboarding',
  OnboardingAdmin = 'OnboardingAdmin',

  // Feature flags
  RemoveLinkUnderline = 'App.Export.RemoveLinkUnderline',
  PageOrientation = 'App.Document.PageOrientation',
  CanvasComments = 'App.Canvas.Comments',
  WordExport = 'App.Export.WordExport'
}
