// action types

import ElementBehavioursApiService from '@/api/ElementBehavioursApiService';
import { ElementBehaviourDto } from '@/api/models';
import { Module } from 'vuex';

// mutation types
export const LOAD_ELEMENT_BEHAVIOURS = 'loadElementBehaviours';
export const SET_ELEMENT_BEHAVIOURS = 'loadElementBehaviours';
export const GET_ELEMENT_BEHAVIOUR = 'getElementBehaviour';
export const ELEMENT_BEHAVIOURS_NAMESPACE = 'elementBehaviours';
const IS_LOADING = 'isLoading';
interface State {
  behaviours: ElementBehaviourDto[];
  isLoading: boolean;
}
const elementBehavioursModule: Module<State, any> = {
  namespaced: true,
  state: {
    behaviours: [],
    isLoading: false
  },
  getters: {
    [GET_ELEMENT_BEHAVIOUR](state) {
      return (id) => state.behaviours.find((x) => x.id == id);
    }
  },
  actions: {
    [LOAD_ELEMENT_BEHAVIOURS](state, payload) {
      state.commit(IS_LOADING, true);
      ElementBehavioursApiService.getAll({ maxResultCount: 100 })
        .then((d) => {
          state.commit(
            SET_ELEMENT_BEHAVIOURS,
            d.data.result.items.map((x) => x.elementBehaviour)
          );
        })
        .catch((e) => {
          console.error('Error loading element behaviours', e);
        })
        .finally(() => {
          state.commit(IS_LOADING, false);
        });
    }
  },
  mutations: {
    [SET_ELEMENT_BEHAVIOURS](state, payload) {
      state.behaviours = payload;
    },
    [IS_LOADING](state, payload) {
      state.isLoading = payload;
    }
  }
};

export default elementBehavioursModule;
