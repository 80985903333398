import { stringContainsCI } from '@/core/utils/common.utils';
import { IEdge, INode } from 'yfiles';
import TextParameter from '../parameters/TextParameter';
import { ITypedMatcher } from './IMatcher';

export default class DataPropertyTagTextMatcher
  implements ITypedMatcher<INode | IEdge, TextParameter>
{
  public canMatch(candidate: any, parameter: TextParameter): boolean {
    return candidate instanceof INode || candidate instanceof IEdge;
  }

  public isMatch(candidate: INode | IEdge, parameter: TextParameter): boolean {
    const dataPropertyTags = candidate.tag?.dataPropertyTags;
    if (!dataPropertyTags) {
      return false;
    }

    const stringContains = stringContainsCI;
    return dataPropertyTags.some((tag) => {
      return stringContains(tag.name, parameter?.text);
    });
  }
}
