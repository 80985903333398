<template>
  <div>
    <!-- TODO: move transition to it's own component -->
    <transition
      enter-active-class="transform duration-200 delay-100 transition"
      enter-class="opacity-0 -translate-y-24"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transform duration-100 transition"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-24"
    >
      <div
        v-show="value"
        class="pointer-events-none fixed inset-0 z-100 m-auto flex h-full w-fit items-end justify-center overflow-y-hidden"
      >
        <div
          data-automation-id="action-toasters"
          class="relative mb-10 flex flex-col items-center"
        >
          <div
            class="pointer-events-auto relative flex items-center rounded-8 bg-charade px-14 py-6 shadow-md"
            :style="modalStyles"
            @click.stop
          >
            <span class="whitespace-nowrap text-white">
              {{ text }}
            </span>

            <div class="flex w-full items-center justify-end">
              <template v-if="customButtons">
                <AppButton
                  v-for="(button, index) in customButtons"
                  :key="index"
                  size="md"
                  :variant="button.variant"
                  :full-width="button.fullWidth"
                  class="mr-12 last:mr-0"
                  @click="onActionButtonClick(button.action)"
                >
                  {{ button.text }}
                </AppButton>
              </template>

              <template v-else>
                <AppButton
                  size="md"
                  :variant="okVariant"
                  class="mr-12"
                  @click="ok"
                >
                  {{ okText }}
                </AppButton>
                <AppButton v-if="isConfirm" size="md" @click="close">
                  {{ denyText }}
                </AppButton>
              </template>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ICustomButtons } from './index';
import { isVNode } from '@/core/utils/common.utils';
import i18n from '@/core/plugins/vue-i18n';
import { CSSProperties } from 'vue/types/jsx';

const confirmButtonText = () => i18n.t('YES').toString();
const rejectButtonText = () => i18n.t('NO').toString();

@Component({
  name: 'ActionToaster'
})
export default class ActionToaster extends Vue {
  @Prop({ required: true })
  text: string | Function;

  @Prop({ default: rejectButtonText })
  denyText: string;

  @Prop({ default: confirmButtonText })
  okText: string;

  @Prop({ default: 'purple' })
  okVariant: string;

  @Prop({ default: null })
  promise: { resolve: (value: boolean | string) => void };

  @Prop({ default: 900 })
  minWidth: string | number;

  @Prop({ default: 900 })
  maxWidth: string | number;

  @Prop({ default: 560 })
  isConfirm: string | number;

  @Prop({ default: null })
  customButtons: ICustomButtons[];

  value = false;

  get isContextVNode(): boolean {
    return isVNode(this.text);
  }

  get isContextRenderFunction(): boolean {
    return typeof this.text === 'function';
  }

  get modalStyles(): CSSProperties {
    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  backdropClick(): void {
    this.close();
  }

  ok(): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(true);
    }, 100);
  }

  close(): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(false);
    }, 100);
  }

  onActionButtonClick(action: string): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(action);
    }, 100);
  }

  mounted(): void {
    this.$nextTick(() => {
      this.value = true;
    });
  }
}
</script>
