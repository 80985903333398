import DataPropertyTagsApiService from '@/api/DataPropertyTagsApiService';
import { DataPropertyTagDto } from '@/api/models';
import { Module } from 'vuex';

export const DATA_PROPERTY_TAGS_NAMESPACE = 'dataPropertyTags';
export const GET_DATA_PROPERTY_TAGS = 'getDataPropertyTags';
export const SET_DATA_PROPERTY_TAGS = 'setDataPropertyTags';
export const LOAD_DATA_PROPERTY_TAGS_BY_DIAGRAM_ID =
  'loadDataPropertyTagsByDiagramId';

interface State {
  tags: DataPropertyTagDto[];
}

const DataPropertyTagsModule: Module<State, any> = {
  namespaced: true,
  state: {
    tags: []
  },
  getters: {
    [GET_DATA_PROPERTY_TAGS](state) {
      return state.tags;
    }
  },
  actions: {
    async [LOAD_DATA_PROPERTY_TAGS_BY_DIAGRAM_ID](context, payload) {
      const response = await DataPropertyTagsApiService.getAll({
        diagramId: payload.id,
        maxResultCount: 1000
      });
      const tags = response.data.result.items.map((x) => x.dataPropertyTag);
      context.commit(SET_DATA_PROPERTY_TAGS, tags);
      return tags;
    },
    [SET_DATA_PROPERTY_TAGS](context, payload) {
      context.commit(SET_DATA_PROPERTY_TAGS, payload);
    }
  },
  mutations: {
    [SET_DATA_PROPERTY_TAGS](state, payload) {
      state.tags = payload;
    }
  }
};
export default DataPropertyTagsModule;
