// This needs to be kept in sync with styles for the document page content (_page-content.scss)
// IMPORTANT: All sizes are in points (pt), 1px = 0.75pt
export default `
body {
  margin: 0;
  line-height: 1.2;
  font-size: 12pt;
  font-family: Arial, sans-serif;
  overflow-wrap: break-word;
  color: black;
}
// Required by pagination when splitParagraphsBetweenPages is set to 'words'
// .document-page-content {
//   white-space: break-spaces;
// }
`;
