import ISpecification from './ISpecification';
import NotSpecification from './NotSpecification';

// This Specification takes all other specifications, groups them by their type and processes with specific logic
// Basically Not Specification should be processed by using AND condition
// and other specifications should be processed by using OR condition
export default class MixedSpecification implements ISpecification {
  private conditions: ISpecification[];

  constructor(conditions: ISpecification[]) {
    this.conditions = conditions;
  }

  public getCandidateScore(candidate: any): number {
    const notSpecs = this.conditions.filter(
      (c) => c instanceof NotSpecification
    );
    let notSpecsResult = null;
    if (notSpecs.length > 0) {
      let resultsSum = 0;
      const allSatisfied = notSpecs.every((spec) => {
        const result = spec.getCandidateScore(candidate);
        if (result > 0) {
          resultsSum += result;
          return true;
        }
        return false;
      });
      notSpecsResult = allSatisfied ? resultsSum : 0;
    }

    const otherSpecs = this.conditions.filter(
      (c) => !(c instanceof NotSpecification)
    );
    let otherSpecsResult = null;
    if (otherSpecs.length > 0) {
      otherSpecsResult = 0;
      otherSpecs.forEach((c) => {
        otherSpecsResult += c.getCandidateScore(candidate);
      });
    }

    if (notSpecsResult === null && otherSpecsResult === null) {
      return 1;
    }

    return notSpecsResult === null ||
      notSpecsResult > 0 ||
      otherSpecsResult === null ||
      otherSpecsResult > 0
      ? notSpecsResult + otherSpecsResult
      : 0;
  }
}
