<template>
  <div :aria-disabled="disabled" class="aria-disabled:cursor-not-allowed">
    <div
      class="relative flex items-center gap-x-4 rounded-2 aria-disabled:pointer-events-none aria-disabled:opacity-50"
      :class="wrapClasses"
      :aria-disabled="disabled"
      :style="wrapStyles"
      @click="$emit('click')"
    >
      <!-- Prepend slot -->
      <slot name="prepend" />

      <!-- Title -->
      <slot name="title">
        <span v-if="title" :class="titleClasses" class="text-12 inline-flex">
          {{ title }}
        </span>
      </slot>

      <!-- Append -->
      <slot name="append" />

      <!-- Triangle -->
      <div
        v-if="isTriangle"
        class="absolute bottom-auto right-0 top-auto rotate-90"
        :class="triangleClasses"
        :style="triangleStyles"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { VNode } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CSSProperties } from 'vue/types/jsx';

type TSizes = 'xs' | 'sm' | 'md' | 'lg';

const sizes = {
  xs: 'pl-3 pl-2 py-1 leading-4',
  sm: 'pl-6 pr-4 py-2 leading-4',
  md: 'pl-9 pr-6 py-3 leading-4',
  lg: 'pl-12 pr-8 py-4 leading-4'
};

const triangleSizes = {
  xs: 'w-18 h-6 translate-x-[11px]',
  sm: 'w-20 h-8 translate-x-[13px]',
  md: 'w-22 h-10 translate-x-[15px]',
  lg: 'w-24 h-12 translate-x-[17px]'
};

@Component({
  name: 'AppChip'
})
export default class AppChip extends Vue {
  @Prop({
    default: 'sm',
    type: String,
    validator: (prop: TSizes) => Object.keys(sizes).includes(prop)
  })
  size: TSizes;

  @Prop({ default: '', type: String })
  wrapCustomClasses: string;

  @Prop({ default: '', type: String })
  title: string;

  @Prop({ default: 'text-main-dark', type: String })
  titleColor: string;

  @Prop({ default: '', type: String })
  titleCustomClasses: string;

  @Prop({ default: '', type: String })
  backgroundColor: string;

  @Prop({ default: 'bg-main-dark-10', type: String })
  colorClass: string;

  @Prop({ default: true, type: Boolean })
  isTriangle: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  get backgroundStyle(): CSSProperties {
    return {
      backgroundColor: this.backgroundColor
    };
  }

  get wrapStyles(): CSSProperties {
    return {
      ...this.backgroundStyle
    };
  }

  get triangleStyles(): CSSProperties {
    return {
      clipPath: 'polygon(50% 0, 100% 100%, 0 100%)',
      ...this.backgroundStyle
    };
  }

  get wrapClasses(): string[] {
    return [sizes[this.size], this.colorClass, this.wrapCustomClasses];
  }

  get triangleClasses(): string[] {
    return [triangleSizes[this.size], this.colorClass];
  }

  get titleClasses(): string[] {
    return [this.titleColor, this.titleCustomClasses];
  }
}
</script>
