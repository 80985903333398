import {
  DragDropEffects,
  GraphComponent,
  NodeDropInputMode,
  Point,
  Rect,
  SimpleNode,
  Size
} from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import config from '@/core/config/diagram.definition.config';
import diagramConfig from '@/core/config/diagram.definition.config';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import {
  DashStyleType,
  DividingLineNodeStyleDto,
  DividingLineType,
  FillType,
  NodeVisualType,
  SolidColorFillDto,
  ThemeElementDto
} from '@/api/models';
import StyleCreator from '@/core/utils/StyleCreator';
import {
  createDragPreview,
  queryContinueDragHandler
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import IPaletteItem from '../Palette/IPaletteItem';
import { pointerEventsSupported } from '@/core/utils/html.utils';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import IDocumentPaletteItem from './IDocumentPaletteItem';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_THEME_ELEMENT_BY_NAME
} from '@/core/services/store/document.module';
import SystemEntityTypes from '@/core/services/corporate/SystemEntityTypes';

export default class DividingLinePaletteBehaviour extends PaletteItemBehaviourBase {
  private static _instance: DividingLinePaletteBehaviour = null;

  public static get INSTANCE(): DividingLinePaletteBehaviour {
    return (
      DividingLinePaletteBehaviour._instance ??
      (DividingLinePaletteBehaviour._instance =
        new DividingLinePaletteBehaviour())
    );
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    const simpleNode = this.createSimpleNode(
      item,
      graphService.graphComponent,
      new Point(itemPositionX, itemPositionY)
    );

    let node = graphService.graphComponent.graph.createNodeAt(
      simpleNode.layout,
      simpleNode.style,
      simpleNode.tag
    );
    graphService.graphComponent.graph.setNodeLayout(
      node,
      simpleNode.layout as Rect
    );

    PaletteItemOffsetHelper.updatePaletteItemInsertOffset(
      graphService.graphComponent,
      config.paletteItemDropOffsetStep
    );
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const simpleNode = this.createSimpleNode(item, graphService.graphComponent);

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = createDragPreview(event);

    // The core method that initiates a drag which is recognized by the GraphComponent.
    const dragSource = NodeDropInputMode.startDrag(
      event.target.parentNode, // The source of the drag gesture, i.e. the element in the drag and drop panel.
      simpleNode, // The node that is dragged. This is used to provide a preview within the GC during the drag.
      DragDropEffects.ALL, // The allowed actions for this drag.
      true, // Whether to the cursor during the drag.
      pointerEventsSupported ? dragPreview : null // The optional preview element that is shown outside of the GC during the drag.,
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);
    });
  }

  private createSimpleNode(
    item: IPaletteItem,
    graphComponent: GraphComponent,
    position?: Point
  ): SimpleNode {
    const styleDto = this.createNodeStyleDto(item);
    const size = this.getSize(graphComponent, item);
    graphComponent.updateContentRect();
    const simpleNode = DiagramUtils.createSimpleNode(
      item.data.name,
      StyleCreator.createNodeStyle(styleDto, []),
      size
    );

    if (position) {
      simpleNode.layout = new Rect(position, size);
    }

    simpleNode.style = StyleCreator.createNodeStyle(
      styleDto,
      StyleCreator.getNodeDecorators(simpleNode)
    );
    return simpleNode;
  }

  private getSize(graphComponent: GraphComponent, item: IPaletteItem): Size {
    const graphSize = DiagramUtils.getGraphSize(graphComponent);
    const themeElementSize = this.getThemeElementSize();

    if (item.data.type == DividingLineType.Horizontal) {
      return new Size(
        Math.max(
          isFinite(graphSize.width) ? graphSize.width : themeElementSize.width,
          diagramConfig.dividingLines.minimumSize
        ),
        1
      );
    }

    return new Size(
      1,
      Math.max(
        isFinite(graphSize.height) ? graphSize.height : themeElementSize.height,
        diagramConfig.dividingLines.minimumSize
      )
    );
  }

  private getThemeElementSize(): Size {
    const getThemeElementByName = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ] as (name: string) => ThemeElementDto | undefined;
    const themeElement = getThemeElementByName(
      SystemEntityTypes.NODE_CORPORATE
    );
    return DiagramUtils.getNodeSize(themeElement.style);
  }

  private createNodeStyleDto(item: IPaletteItem): DividingLineNodeStyleDto {
    return new DividingLineNodeStyleDto(
      NodeVisualType.DividingLine,
      item.data.type,
      {
        thickness: 1,
        dashStyle: {
          type: DashStyleType.Solid
        },
        fill: {
          color: '#000000',
          type: FillType.SolidColor
        } as SolidColorFillDto
      }
    );
  }
}
