export default class UrlParamsHelper {
  private static readonly returnUrlParam = 'ReturnUrl';
  private static readonly stateParam = 'state';

  public static getReturnUrl(): string {
    const urlParams = this.getSearchParams();
    return urlParams.get(this.returnUrlParam);
  }

  public static getState(url = null): string {
    const urlParams = this.getSearchParams(url);
    return urlParams.get(this.stateParam);
  }

  private static getSearchParams(url = null): URLSearchParams {
    url = url ?? window.location.hash;
    const idx = url.indexOf('?');
    if (idx >= 0) {
      return new URLSearchParams(url.substr(idx));
    }
    return new URLSearchParams();
  }
}
