import ISpecification from './ISpecification';

export default class AndSpecification implements ISpecification {
  private conditions: ISpecification[];

  constructor(conditions: ISpecification[]) {
    this.conditions = conditions;
  }

  public getCandidateScore(candidate: any): number {
    let resultsSum = 0;
    const allSatisfied = this.conditions.every((c) => {
      const result = c.getCandidateScore(candidate);
      if (result > 0) {
        resultsSum++;
        return true;
      }
      return false;
    });
    return allSatisfied ? resultsSum : 0;
  }
}
