<template>
  <div class="flex flex-col">
    <div
      :class="[fullWidthTabs ? 'justify-between' : 'flex-wrap', headerClasses]"
      class="flex border-b-1 border-main-dark-10"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="tab.name"
        class="tabs__nav"
        :class="{
          'is-active': index === activeTab,
          'is-disabled': tab.disabled,
          'w-full': fullWidthTabs
        }"
        :disabled="tab.disabled"
        @click="selectTab(index, tab.name)"
        @contextmenu="$emit('contextmenu', $event)"
      >
        <span
          v-show="tab.marked"
          class="absolute right-10 top-8 h-4 w-4 rounded-full bg-accent-purple"
        />
        {{ tab.name }}
      </button>
    </div>
    <div :class="containerClasses" class="flex w-full flex-col">
      <transition
        mode="out-in"
        enter-active-class="transform transition"
        enter-class="opacity-0 translate-x-20"
        enter-to-class="opacity-100 translate-x-0"
        leave-active-class="transform transition"
        leave-class="opacity-100 translate-x-0"
        leave-to-class="opacity-0 translate-x-20"
      >
        <div :class="itemsWrapperClasses">
          <template
            v-for="(node, i) of $slots.default.filter(
              (node) =>
                node.componentOptions &&
                node.componentOptions.tag === 'AppTabItem'
            )"
          >
            <VNodeWrapper
              v-show="i === activeTab"
              :key="node.componentOptions.propsData.name"
              :value="node"
              :class="itemsWrapperClasses"
              class="flex flex-col"
            />
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Vue,
  Component,
  Prop,
  Watch,
  ProvideReactive
} from 'vue-property-decorator';

type TTabs = {
  name: string;
  icon: null | string;
  marked: boolean;
  disabled: boolean;
};

@Component({
  name: 'AppTabs',
  inject: []
})
export default class AppTabs extends Vue {
  @Prop({ required: false })
  value: string;

  @Prop({ default: 0 })
  defaultTab: number;

  @Prop({ default: false, type: Boolean })
  fullWidthTabs: boolean;

  @Prop({ default: '', type: String })
  headerClasses: string;

  @Prop({ default: 'p-16', type: String })
  containerClasses: string;

  @Prop({ default: '', type: String })
  itemsWrapperClasses: string;

  activeTab: number = 0;

  @ProvideReactive('activeTabNameKey')
  activeTabName: string = null;
  tabs: TTabs[] = [];

  @Watch('value')
  handleActiveTab(newVal: number): void {
    if (newVal) {
      this.activeTab = newVal;
    }
  }

  selectTab(tabIndex: number, tabName: string): void {
    if (this.activeTab === tabIndex) {
      return;
    }

    this.activeTab = tabIndex;
    this.activeTabName = tabName;
    this.$emit('input', this.activeTab);
    this.$emit('change', this.activeTab);
  }

  getTabs(): void {
    if (this.$slots.default) {
      console.debug('AppTabs $slots.default: ', this.$slots);
      const tabs = this.$slots.default
        .filter(
          (vnode) =>
            vnode.tag &&
            (vnode.componentOptions?.Ctor as any)?.options.name === 'AppTabItem'
        )
        .map(({ componentOptions }) => {
          const { name, icon, marked, disabled } =
            componentOptions.propsData as TTabs;

          return {
            name,
            icon,
            marked: marked !== undefined,
            disabled
          };
        });

      this.tabs = tabs;

      if (tabs.length && !this.defaultTab) {
        this.activeTab = this.activeTab ?? 0;
        this.activeTabName = this.tabs[this.activeTab].name;
        this.$emit('input', this.activeTab);
      }
    } else if (this.tabs.length !== 0) {
      this.tabs = [];
    }
  }

  created(): void {
    this.getTabs();
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  &__nav {
    @apply relative
      ml-4 rounded-tl-4
      rounded-tr-4
      border-l-1
      border-r-1
      border-t-1
      border-transparent
      px-16
      py-13
      text-14
      leading-none
      transition
      first:ml-0
      disabled:cursor-not-allowed
      disabled:text-main-dark-40;

    &:not(.is-disabled, .is-active) {
      @apply hover:bg-main-dark-10 focus:bg-main-dark-10;
    }

    &.is-active {
      @apply z-1 border-main-dark-10 text-accent-purple;

      &::after {
        content: '';
        @apply absolute -bottom-1 left-0 h-1 w-full bg-white;
      }
    }
  }
}
</style>
