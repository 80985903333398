import axios, {
    AxiosResponse
} from 'axios';
import {
    PagedResultDtoOfGetFileAttachmentForViewDto,
    ZadarResponse,
    GetFileAttachmentForEditOutput,
    CreateOrEditFileAttachmentDto,
    UploadTimelinesFileOutput,
    FileAttachmentDto
} from './models'
export default class FileAttachmentsApiService {
    public static getAll(params ? : {
        filter ? : string,
        fileIdFilter ? : string,
        filenameFilter ? : string,
        mimeTypeFilter ? : string,
        descriptionFilter ? : string,
        diagramNameFilter ? : string,
        timelineNameFilter ? : string,
        personLastNameFilter ? : string,
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfGetFileAttachmentForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getFileAttachmentForEdit(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetFileAttachmentForEditOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/GetFileAttachmentForEdit`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditFileAttachmentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static uploadFile(params ? : {
        diagramId ? : number,
        edgeId ? : number,
        nodeId ? : number,
        themeId ? : number,
        documentId ? : number,
        dataPropertyDefinitionId ? : number,
        pageDesignId ? : number,
        attachmentType ? : number,
        timelineId ? : number,
        timelineEventId ? : number,
        timelineTextBoxId ? : string,
        timelineResourceId ? : number,
        dataPropertyUuid ? : string,
        dataPropertyValue ? : string,
        personId ? : number,
        data ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/UploadFile`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static uploadTimelineImage(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UploadTimelinesFileOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/UploadTimelineImage`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static deleteFileByFileId(params ? : {
        fileId ? : string,
        themeId ? : number,
        documentId ? : number,
        timelineId ? : number,
        timelineEventId ? : number,
        timelineTextBoxId ? : string,
        timelineResourceId ? : number,
        dataPropertyTagId ? : number,
        pageDesignId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/DeleteFileByFileId`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getFileAttachmentByFileId(params ? : {
        fileAttachmentId ? : string,
        includeFileData ? : boolean
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileAttachmentDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/FileAttachments/GetFileAttachmentByFileId`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
}