import {
  Offset,
  StepAction,
  TutorialStep,
  TutorialCard,
  TutorialCardListItem,
  TutorialCardsMap,
  TutorialStepsMap,
  Actions,
  AttachToCategory,
  TutorialProgressSettings,
  TutorialProgressSettingsMap
} from '@/core/services/tutorial/ITutorial';
import { TutorialType } from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { TutorialEvents } from '@/core/services/tutorial/TutorialActionMediator';
import { VisibleLayoutItems } from '@/core/services/tutorial/TutorialLayoutService';

export function createEmptyStepAction(): StepAction {
  return {
    repeat: false,
    repeatCount: 1,
    progress: 0,
    event: {
      name: TutorialEvents.NODE_CREATED_CORPORATE,
      label: 'Corporate',
      key: '',
      value: '',
      condition: null
    },
    category: {
      label: 'Create item',
      value: Actions.CreateItem,
      component: 'CreateItemsForm'
    }
  };
}

export function getDefaultVisibleLayoutItems(): VisibleLayoutItems {
  return {
    sidebar: true,
    ribbon: true,
    actionToolbar: true,
    footerToolbar: true,
    diagramTools: true,
    dataPanel: true,
    quickStartButton: true
  };
}

export function createEmptyProgressSettings(): TutorialProgressSettings {
  return {
    initialProgress: 0,
    stepProgress: {}
  };
}

export function createDefaultProgressSettingsMap(): TutorialProgressSettingsMap {
  return {
    [TutorialType.Beginner]: createEmptyProgressSettings(),
    [TutorialType.Intermediate]: createEmptyProgressSettings(),
    [TutorialType.Advanced]: createEmptyProgressSettings()
  };
}

export function createEmptyStep(id: string | number): TutorialStep {
  return {
    id: `${id}`,
    stepActions: [createEmptyStepAction()],
    title: 'Step title',
    text: '',
    delay: 300,
    dialogWidth: 250,
    visibleLayoutItems: getDefaultVisibleLayoutItems(),
    hideHighlight: false,
    attachTo: {
      category: AttachToCategory.Canvas,
      element: `.${ExportConfig.innerBodyContainerClass}`,
      side: 'top',
      isWithinCanvas: false,
      borderRadius: 0
    },
    arrow: false,
    translations: null,
    promptOffset: { xAxisOffset: 20, yAxisOffset: 0 } as Offset
  };
}

export function createEmptySteps(): TutorialStepsMap {
  return {
    [TutorialType.Beginner]: [],
    [TutorialType.Intermediate]: [],
    [TutorialType.Advanced]: []
  };
}

export function createEmptyCardListItem(): TutorialCardListItem {
  return {
    title: '',
    text: '',
    highlighted: false
  };
}

export function createEmptyCard(type: TutorialType): TutorialCard {
  return {
    type,
    title: '',
    caption: '',
    name: '',
    icon: '',
    completionTime: {
      value: 1,
      unit: 'minutes'
    },
    listItems: [createEmptyCardListItem()],
    footerText: '',
    highlighted: false,
    merchIncluded: false,
    greetingTitle: '',
    greetingText: '',
    translations: null
  };
}

export function createEmptyCards(): TutorialCardsMap {
  return {
    [TutorialType.Beginner]: createEmptyCard(TutorialType.Beginner),
    [TutorialType.Intermediate]: createEmptyCard(TutorialType.Intermediate),
    [TutorialType.Advanced]: createEmptyCard(TutorialType.Advanced)
  };
}
