import {
  BridgeCrossingPolicy,
  BridgeManager,
  CanvasComponent,
  Class,
  GeneralPath,
  GraphComponent,
  GraphObstacleProvider,
  IBridgeCreator,
  IRenderContext
} from 'yfiles';
import { FilteringGraphObstacleProvider } from './FilteringGraphObstacleProvider';

/**
 * This BridgeManager uses a hacky workaround to make it possible to have more than one instance.
 *
 * The problem is that the BridgeManager implementation in yFiles 2.6 stores the information about
 * its bridges in the context's lookup by creating a new wrapping context instance. Thus, any
 * previously registered managers can no longer access their information.
 *
 * The workaround is to store the context instance for each manager instance. This can be done in the
 * registerObstacles method which immediately gets the newly created wrapping instance.
 *
 * Warning: The obvious drawback is that any later changes to the context that would affect rendering
 * will not be considered. If this becomes a problem,
 *  - make sure to register the BridgeManagers with the GraphComponent last, and
 *  - one could try to add the missing information of the actual context to the stored context
 *    (in addBridges, probably).
 */
export class NonExclusiveBridgeManager extends BridgeManager {
  private context: IRenderContext | null = null;

  protected uninstall(canvas: CanvasComponent): void {
    super.uninstall(canvas);
    this.context = null;
  }

  dispose(): void {
    super.dispose();
  }

  addBridges(
    context: IRenderContext,
    path: GeneralPath,
    callback?: IBridgeCreator | null
  ): GeneralPath {
    return super.addBridges(this.context ?? context, path, callback);
  }

  getObstacleHash(context: IRenderContext): number {
    return super.getObstacleHash(this.context ?? context);
  }

  protected registerObstacles(context: IRenderContext): void {
    // yWorks note: this method is called from the base PrepareRenderContext event listener,
    //  with the newly created context, so we store the context here.
    this.context = context;
    super.registerObstacles(context);
  }
}

export class DefaultBridgeManager extends NonExclusiveBridgeManager {
  constructor(graphComponent: GraphComponent) {
    super();
    this.bridgeCrossingPolicy =
      BridgeCrossingPolicy.MORE_HORIZONTAL_BRIDGES_LESS_HORIZONTAL;
    this.canvasComponent = graphComponent;
    this.addObstacleProvider(new GraphObstacleProvider());
  }
}

export class VerticalOnOwnershipBridgeManager extends NonExclusiveBridgeManager {
  constructor(graphComponent: GraphComponent) {
    super();
    this.bridgeCrossingPolicy =
      BridgeCrossingPolicy.VERTICAL_BRIDGES_HORIZONTAL;
    this.canvasComponent = graphComponent;
    this.addObstacleProvider(
      new FilteringGraphObstacleProvider(
        (edge) => edge?.tag?.name === 'OWNERSHIP' && edge?.bends?.size <= 2
      )
    );
  }
}

Class.fixType(DefaultBridgeManager);
Class.fixType(VerticalOnOwnershipBridgeManager);
