import DiagramUtils from '@/core/utils/DiagramUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import {
  BaseClass,
  GraphComponent,
  GraphEditorInputMode,
  IEdge,
  IHandle,
  INode,
  IRenderContext,
  IVisualCreator,
  Matrix,
  Point,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  Stroke,
  SvgVisual,
  SvgVisualGroup
} from 'yfiles';
import { stripHtml } from '@/core/utils/html.utils';
import Lottie from 'lottie-web';
import JigsawTextEditorInputMode from '@/core/services/graph/input-modes/text-editor/JigsawTextEditorInputMode';

const gap =
  diagramConfig.tutorial.spotlight.padding -
  diagramConfig.tutorial.spotlight.thickness;
const handleSpotlightSize = 8;
const lottieAnimationXAdjustment = 0.7824;
const lottieAnimationYAdjustment = 3.7696;

type Target = INode | IEdge | SVGElement | IHandle;
export default class SpotlightVisual
  extends BaseClass<IVisualCreator>(IVisualCreator)
  implements IVisualCreator
{
  private readonly target: Target;
  private path: SVGPathElement;
  private bgPath: SVGPathElement;
  private readonly rect: SVGRectElement;
  private lottiePlayerGroup: SvgVisualGroup;

  constructor(target: Target) {
    super();
    this.target = target;
    this.rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }

  private getLayout(context: IRenderContext): Rect {
    if (this.target instanceof SVGElement) {
      const bbox = (this.target as SVGAElement).getBBox();

      let dx = 0,
        dy = 0;

      // Get the transform matrix of the SVGElement
      const transformList = (this.target as SVGAElement).transform.baseVal;
      if (transformList.numberOfItems > 0) {
        const matrix = transformList.getItem(0).matrix;
        dx = matrix.e;
        dy = matrix.f;
      }

      return new Rect(bbox.x + dx, bbox.y + dy, bbox.width, bbox.height);
    } else if (this.target instanceof INode) {
      return this.target.layout.toRect();
    } else if (this.target instanceof IEdge) {
      const renderer = this.target.style.renderer;
      const bounds = renderer
        .getBoundsProvider(this.target, this.target.style)
        .getBounds(context);

      return bounds;
    } else if (this.target instanceof IHandle) {
      const position = this.target.location.toPoint();

      return new Rect(
        position.x - handleSpotlightSize / 2,
        position.y - handleSpotlightSize / 2,
        handleSpotlightSize,
        handleSpotlightSize
      );
    }
  }

  private getSVGPathDataForNode(node: INode, layout: Rect): string {
    const matrix = new Matrix();
    const shape = (
      DiagramUtils.unwrapNodeStyle(node).baseStyle as ShapeNodeStyle
    ).shape;
    let gapX = diagramConfig.tutorial.spotlight.padding + 1;
    let gapY = diagramConfig.tutorial.spotlight.padding + 1;
    if (shape == ShapeNodeShape.TRIANGLE || shape == ShapeNodeShape.TRIANGLE2) {
      gapX *= 2;
    }
    const scaleX = (layout.width + gapX) / layout.width;
    const scaleY = (layout.height + gapY) / layout.height;

    // The matrix scale origin is 0,0, so adjust it to the SVG's top-left corner
    matrix.translate(
      new Point(
        -layout.x * (scaleX - 1) - gapX / 2,
        -layout.y * (scaleY - 1) - gapY / 2
      )
    );
    matrix.scale(scaleX, scaleY);

    return node.style.renderer
      .getShapeGeometry(node, node.style)
      .getOutline()
      .createSvgPathData(matrix);
  }

  createVisual(context: IRenderContext): SvgVisual {
    if (this.target instanceof INode) {
      const layout = this.getLayout(context);
      const pathData = this.getSVGPathDataForNode(this.target, layout);
      this.path.setAttribute('d', pathData);
      this.path.setAttribute('fill', 'none');
      this.path.setAttribute('stroke', diagramConfig.tutorial.spotlight.color);
      this.path.setAttribute(
        'stroke-width',
        diagramConfig.tutorial.spotlight.thickness.toString()
      );
      this.path.setAttribute('stroke-linejoin', 'miter');
      this.path.setAttribute('stroke-linecap', 'butt');
      this.path.setAttribute('stroke-miterlimit', '10');
      return new SvgVisual(this.path);
    } else if (this.target instanceof IEdge) {
      let g = new SvgVisualGroup();
      if (
        !(context.canvasComponent as GraphComponent).graph.contains(this.target)
      )
        return g;

      const edgePath = this.target.style.renderer
        .getPathGeometry(this.target, this.target.style)
        .getPath();

      const borderStroke = new Stroke({
        thickness:
          diagramConfig.tutorial.spotlight.thickness * 2 +
          diagramConfig.tutorial.spotlight.padding,
        fill: diagramConfig.tutorial.spotlight.color
      });
      this.path = edgePath.createSvgPath();
      this.path.setAttribute('fill', 'none');
      borderStroke.applyTo(this.path, context);

      const bgStroke = new Stroke({
        thickness: diagramConfig.tutorial.spotlight.padding,
        fill: 'white'
      });
      this.bgPath = edgePath.createSvgPath();
      this.bgPath.setAttribute('fill', 'none');
      bgStroke.applyTo(this.bgPath, context);

      g.add(new SvgVisual(this.path));
      g.add(new SvgVisual(this.bgPath));

      g.add(
        this.target.style.renderer
          .getVisualCreator(this.target, this.target.style)
          .createVisual(context) as SvgVisual
      );

      const edgeLabel = this.target.labels.at(0);
      const textEditorInputMode = (
        context.canvasComponent.inputMode as GraphEditorInputMode
      )?.textEditorInputMode as JigsawTextEditorInputMode;
      if (
        edgeLabel &&
        textEditorInputMode?.label !== edgeLabel &&
        stripHtml(edgeLabel.text).length
      ) {
        g.add(
          edgeLabel.style.renderer
            .getVisualCreator(edgeLabel, edgeLabel.style)
            .createVisual(context) as SvgVisual
        );
      }
      return g;
    } else {
      const g = new SvgVisualGroup();
      const layout = this.getLayout(context);
      this.positionRect(layout);
      this.rect.setAttribute('fill', 'none');
      this.rect.setAttribute('stroke', diagramConfig.tutorial.spotlight.color);
      this.rect.setAttribute(
        'stroke-width',
        diagramConfig.tutorial.spotlight.thickness.toString()
      );

      let borderRadius = 8;
      if (
        this.target instanceof SVGElement &&
        this.target
          .getAttribute('data-automation-id')
          ?.startsWith('node-quickbuild-decorator')
      ) {
        borderRadius = 100;

        this.lottiePlayerGroup = new SvgVisualGroup();
        Lottie.loadAnimation({
          container: this.lottiePlayerGroup.svgElement,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '/media/training/spotlight-lottie.json'
        });
        this.positionLottiePlayer(layout);
        g.add(this.lottiePlayerGroup);
      }
      this.rect.setAttribute('rx', borderRadius.toString());
      this.rect.setAttribute('ry', borderRadius.toString());
      g.add(new SvgVisual(this.rect));

      return g;
    }
  }

  updateVisual(context: IRenderContext, oldVisual: SvgVisual): SvgVisual {
    if (this.target instanceof INode) {
      const layout = this.getLayout(context);
      const pathData = this.getSVGPathDataForNode(this.target, layout);
      this.path.setAttribute('d', pathData);
    } else if (this.target instanceof IEdge) {
      return this.createVisual(context);
    } else {
      const layout = this.getLayout(context);

      this.positionRect(layout);
      this.positionLottiePlayer(layout);
    }
    return oldVisual;
  }

  private positionRect(layout: Rect) {
    let stepGap = gap;
    if (
      this.target instanceof SVGElement &&
      this.target
        .getAttribute('data-automation-id')
        ?.startsWith('node-quickbuild-decorator')
    ) {
      stepGap /= 2;
    }

    this.rect.x.baseVal.value = layout.x - stepGap;
    this.rect.y.baseVal.value = layout.y - stepGap;
    this.rect.width.baseVal.value = layout.width + stepGap * 2;
    this.rect.height.baseVal.value = layout.height + stepGap * 2;
  }

  private positionLottiePlayer(layout: Rect) {
    if (this.lottiePlayerGroup) {
      this.lottiePlayerGroup.svgElement.setAttribute(
        'transform',
        `translate(${
          layout.x -
          96 / 2 +
          layout.width / 2 -
          gap / 2 +
          lottieAnimationXAdjustment
        }, ${
          layout.y -
          96 / 2 +
          layout.height / 2 -
          gap / 2 +
          lottieAnimationYAdjustment
        })`
      );
    }
  }
}
