﻿import { GraphComponent } from 'yfiles';

export default class SvgDefs {
  static register(graphComponent: GraphComponent): void {
    //Break this up if we end up with more than one
    const blurFilter = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'filter'
    );
    blurFilter.id = this.definitions.SEARCH_BLUR;
    const feGaussianBlur = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'feGaussianBlur'
    );
    feGaussianBlur.setAttributeNS(null, 'in', 'SourceGraphic');
    feGaussianBlur.setAttributeNS(null, 'stdDeviation', '8');
    blurFilter.appendChild(feGaussianBlur);

    const defs =
      graphComponent.canvasContext.canvasComponent.svgDefsManager.defs;

    defs.appendChild(blurFilter);
  }

  static definitions = {
    SEARCH_BLUR: 'searchblur'
  };
}
