import { Module } from 'vuex';

// namespace
export const COMMENT_PANEL_NAMESPACE = 'comment-panel';

// getters
export const GET_COMMENT_PANEL_OPEN = 'getCommentPanelOpen';

// mutations
export const SET_COMMENT_PANEL_OPEN = 'setCommentPanelOpen';

// actions ...

interface State {
  isCommentPanelOpen: boolean;
}

const commentPanelModule: Module<State, any> = {
  namespaced: true,

  state: {
    isCommentPanelOpen: false
  },

  getters: {
    [GET_COMMENT_PANEL_OPEN](state) {
      return state.isCommentPanelOpen;
    }
  },

  mutations: {
    [SET_COMMENT_PANEL_OPEN](state, payload) {
      state.isCommentPanelOpen = payload;
    }
  },

  actions: {}
};

export default commentPanelModule;
