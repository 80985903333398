import { getItem, setItem } from './storage.utils';
import { StorageType } from './StorageType';

export default function LocalStorage(
  name?
): (target: any, key: string) => void {
  function LocalStorageDecorator(target: any, key: string) {
    const getter = function () {
      return getItem(name || key, StorageType.localStorage);
    };

    const setter = function (newVal) {
      setItem(name || key, newVal, StorageType.localStorage);
    };

    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  }
  return LocalStorageDecorator;
}
