import {
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentSubPageDto
} from '@/api/models';
import {
  convertHtmlElementToSvgElement,
  convertSvgElementToDataUrl,
  convertUrlToDataUrl,
  fixSvgElementStyles
} from '@/core/utils/common.utils';

import CacheType from '../../caching/CacheType';
import CachingService from '../../caching/CachingService';
import ContentPagination from '../ContentPagination';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { ExportCachePolicy } from '../ExportCachePolicy';
import ExportConfig from '@/core/config/ExportConfig';
import { ExportFormat } from '../ExportFormat';
import ExportOptions from '../ExportOptions';
import ExportPagePart from '../ExportPagePart';
import ExportUtils from '../ExportUtils';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import LegendSerializer from '@/components/DiagramLegend/LegendSerializer';
import ThumbnailBuilder from './ThumbnailBuilder';
import ThumbnailStyles from './ThumbnailStyles';
import appConfig from '@/core/config/appConfig';
import { htmlToElement } from '@/core/utils/html.utils';
import i18nService from '../../i18n.service';
import { toPng } from 'html-to-image';
import DocumentHashHelper from '../../document/DocumentHashHelper';
import CachedThumbnail from './CachedThumbnail';

export default class ThumbnailService {
  private static readonly disableCaching = false;
  private static readonly thumbPlaceholderUrl =
    '/media/svg/thumb-placeholder.svg';
  private static readonly thumbBlankPlaceholderUrl =
    '/media/svg/thumb-blank-placeholder.svg';
  private static readonly currentLanguage = i18nService.getActiveLanguage();
  private static readonly masterLegendThumbnailPlaceholders = {
    [appConfig.supportedLocales.enGb]:
      '/media/svg/master_legend_thumb_with_margin.svg',
    [appConfig.supportedLocales.enUS]:
      '/media/svg/master_legend_thumb_with_margin.svg',
    [appConfig.supportedLocales.frCA]: '/media/svg/master_legend_thumb_fr.svg'
  };
  private static emptyThumbnailPlaceholder: string;

  public static getThumbCacheKey(pageId: number, subPageIndex: number): string {
    return CachingService.generateKey(
      CacheType.PageThumbElement,
      pageId,
      subPageIndex ?? 0
    );
  }

  public static async generateThumbnail(
    options: ExportOptions
  ): Promise<string | SVGElement> {
    try {
      const exportPage = options.metadata.currentPage;
      const page = exportPage.page;
      const thumbCacheKey = this.getThumbCacheKey(
        exportPage.page.id,
        exportPage.subPageIndex
      );

      if (
        page.contentType == DocumentPageContentType.MasterLegend &&
        page.content != null &&
        options.format != ExportFormat.SvgElement &&
        this.hasLessThanTwoLegendItems(page)
      ) {
        CachingService.removeByKey(thumbCacheKey);
        return await convertUrlToDataUrl(this.getMasterLegendThumbnailUrl());
      }

      const pageHash = DocumentHashHelper.getCurrentPageHash(page, false);
      const cachedThumbnail = CachingService.getOrSet<CachedThumbnail>(
        thumbCacheKey,
        () => ({ data: new CachedThumbnail() })
      );
      if (
        !this.disableCaching &&
        cachedThumbnail.contains(options.format) &&
        (options.cachePolicy === ExportCachePolicy.Reuse ||
          (options.cachePolicy !== ExportCachePolicy.Ignore &&
            cachedThumbnail.pageHash === pageHash))
      ) {
        return cachedThumbnail.get(options.format);
      }

      let thumbnailElement: SVGElement;
      if (
        options.cachePolicy == ExportCachePolicy.Ignore ||
        this.disableCaching ||
        (page.getSubPageType(exportPage.subPageIndex) ==
          DocumentPageType.Content &&
          page.contentType != DocumentPageContentType.MasterLegend)
      ) {
        const builder = new ThumbnailBuilder(options);
        thumbnailElement = await builder.getThumbnail();
        builder.dispose();
      } else {
        const templateElement = await this.getTemplateElement(options);
        await this.modifyTemplateElement(templateElement, options);
        thumbnailElement = templateElement;
      }

      const thumbnail = await this.getThumbnail(thumbnailElement, options);
      cachedThumbnail.set(
        options.format,
        thumbnailElement,
        thumbnail,
        pageHash
      );
      return thumbnail;
    } catch (e) {
      console.error(e);
      return await convertUrlToDataUrl(this.thumbPlaceholderUrl);
    }
  }

  public static async generateEmptyPlaceholderThumbnail(): Promise<string> {
    if (!this.emptyThumbnailPlaceholder) {
      this.emptyThumbnailPlaceholder = await convertUrlToDataUrl(
        this.thumbBlankPlaceholderUrl
      );
    }

    return this.emptyThumbnailPlaceholder;
  }

  private static getSubPageRef(
    page: DocumentPageDto,
    subPageIndex: number
  ): DocumentSubPageDto {
    if (subPageIndex === undefined || subPageIndex === null) {
      return null;
    }
    return page.subPageRefs?.find((sp) => sp.subPageIndex === subPageIndex);
  }

  private static async getTemplateElement(
    options: ExportOptions
  ): Promise<SVGElement> {
    const page = options.metadata.currentPage.page;
    const subPageIndex = options.metadata.currentPage.subPageIndex;
    const includeLogo = ExportUtils.shouldIncludeLogo(options.document, page);
    const subPageRef = this.getSubPageRef(page, subPageIndex);

    const titleLayout = subPageRef?.titleLayout ?? page.titleLayout;
    const titleHeight = subPageRef?.titleHeight ?? page.titleHeight;
    const showTitle = subPageRef?.showTitle ?? page.showTitle;
    const maxTitleHeight = subPageRef?.maxTitleHeight ?? page.maxTitleHeight;
    const backgroundLayout =
      page.contentType === DocumentPageContentType.MasterLegend
        ? page.backgroundLayout
        : null;

    const cacheKey = CachingService.generateKey(
      CacheType.TemplateThumbElement,
      options.document.lastSelectedThemeId,
      options.document.headerStyle,
      options.document.footerStyle,
      page.getSubPageType(subPageIndex),
      page.diagramPosition,
      page.contentType,
      page.showHeader,
      page.showFooter,
      page.headerLayout,
      page.footerLayout,
      titleLayout,
      titleHeight,
      maxTitleHeight,
      showTitle,
      includeLogo,
      backgroundLayout
    );

    const thumbElement = await CachingService.getOrSetMutexAsync<SVGElement>(
      cacheKey,
      async () => {
        // Currently this would only work if header/footer/background are the same for each page type
        // Need to change in later versions when we add customization support
        const optionsAll = { ...options };
        optionsAll.pagePart = ExportPagePart.All;
        const builder = new ThumbnailBuilder(optionsAll);
        const thumbnail = await builder.getThumbnail();
        builder.dispose();
        return { data: thumbnail };
      }
    );
    return thumbElement.cloneNode(true) as SVGElement;
  }

  private static async modifyTemplateElement(
    templateThumbElement: SVGElement,
    options: ExportOptions
  ): Promise<void> {
    const exportPage = options.metadata.currentPage;
    const page = exportPage.page;
    const subPageType = page.getSubPageType(exportPage.subPageIndex);
    const builder = new ThumbnailBuilder(options);
    if (page.contentType == DocumentPageContentType.MasterLegend) {
      const newBodyHtml = this.appendStyles(await builder.buildBody());
      const newBodyElement = htmlToElement(newBodyHtml);
      let paddingTop = 0;
      if (
        page.showHeader ||
        LayoutWidgetUtils.contentAreaContainsWidgets(
          page,
          DocumentContentArea.Header,
          true
        )
      ) {
        paddingTop += options.document.headerStyle.height;
      }
      newBodyElement.style.paddingTop = paddingTop + 'pt';
      const newBodySvgElement =
        convertHtmlElementToSvgElement(newBodyElement).querySelector('g#body');
      const oldBodySvgElement = templateThumbElement.querySelector(`g#body`);
      oldBodySvgElement.replaceWith(newBodySvgElement);
    } else {
      if (
        subPageType == DocumentPageType.Diagram ||
        subPageType == DocumentPageType.Split
      ) {
        const oldDiagramSvgElement = templateThumbElement.querySelector(
          `svg#diagram`
        ) as SVGElement;
        const xOffset = Number(oldDiagramSvgElement.getAttribute('x'));
        const newDiagramSvg = await builder.buildDiagramSvg(xOffset);
        oldDiagramSvgElement.outerHTML = this.ensureValidSvgXml(newDiagramSvg);
        fixSvgElementStyles(templateThumbElement);
      }
      if (subPageType == DocumentPageType.Split) {
        const oldContentSvgElement =
          templateThumbElement.querySelector(`g#content`);
        const oldContentRectElement =
          oldContentSvgElement.querySelector('rect[x]');
        const xOffset = Number(oldContentRectElement.getAttribute('x'));
        const yOffset = Number(oldContentRectElement.getAttribute('y'));
        const newContentHtml = this.appendStyles(
          await builder.buildHtmlContent(false)
        );
        const newContentElement = htmlToElement(newContentHtml);
        newContentElement.style.marginLeft = `${xOffset}px`;
        newContentElement.style.marginTop = `${yOffset}px`;
        const newContentSvgElement =
          convertHtmlElementToSvgElement(newContentElement).querySelector(
            'g#content'
          );
        oldContentSvgElement.replaceWith(newContentSvgElement);
      }

      if (page.showPageNumber || page.showHeader || page.showFooter) {
        // Assign correct page numbers
        const placeholder = templateThumbElement.querySelector(
          'g[name="pageNumber"] > text > tspan'
        );
        if (placeholder) {
          placeholder.innerHTML = (
            ContentPagination.getPageNumber(options.document, page) +
            exportPage.subPageIndex
          ).toString();
          placeholder.removeAttribute('textLength');
        }
      }
    }

    builder.dispose();
  }

  private static async getThumbnail(
    thumbElement: SVGElement,
    options: ExportOptions
  ): Promise<string | SVGElement> {
    switch (options.format) {
      case ExportFormat.SvgElement:
        return thumbElement;
      case ExportFormat.Svg:
        return this.getThumbnailSvg(thumbElement);
      default:
        return await this.getThumbnailPng(thumbElement, options);
    }
  }

  private static getThumbnailSvg(thumbElement: SVGElement): string {
    return convertSvgElementToDataUrl(thumbElement);
  }

  private static async getThumbnailPng(
    thumbElement: SVGElement,
    options: ExportOptions
  ): Promise<string> {
    return await toPng(thumbElement as unknown as HTMLElement, {
      backgroundColor: 'white',
      width: options.document.pageStyle.width * ExportConfig.pageThumbScale,
      height: options.document.pageStyle.height * ExportConfig.pageThumbScale
    });
  }

  private static getMasterLegendThumbnailUrl(): string {
    if (this.masterLegendThumbnailPlaceholders[this.currentLanguage]) {
      return this.masterLegendThumbnailPlaceholders[this.currentLanguage];
    }
    return this.masterLegendThumbnailPlaceholders[i18nService.defaultLanguage];
  }

  private static hasLessThanTwoLegendItems(page: DocumentPageDto): boolean {
    const pageLegend = LegendSerializer.deserializeDefinition(page.content);
    return pageLegend.items.length < 2;
  }

  private static ensureValidSvgXml(svg: string): string {
    return svg.replaceAll('&nbsp;', '');
  }

  private static appendStyles(html: string): string {
    return `
      <div>
        <style>${ThumbnailStyles}</style>
        ${html}
      </div>
    `;
  }
}
