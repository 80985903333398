<template>
  <div class="inline-flex items-center" :class="widthClasses">
    <BaseButton
      v-for="item in formattedOptions"
      :key="item.value"
      :is-active="item.value === value"
      :disabled="item.disabled || disabled"
      :icon="item.icon"
      :label="item.label"
      :variant-class="typeColorClass"
      :full-width="autoWidth"
      :size="buttonSize"
      @click="handleSelect(item.value)"
      :data-automation-id="item.automationSelector"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

type TVariant = 'default' | 'green' | 'red';

const variantClasses = {
  default: 'text-accent-purple hover:bg-accent-purple-05',
  green: 'text-success-green hover:bg-success-green-05',
  red: 'text-danger-red hover:bg-danger-red-05'
};

@Component({
  name: 'AppButtonGroup',
  components: { BaseButton }
})
export default class AppButtonGroup extends Vue {
  @Prop({ required: true })
  value: string | number;

  @Prop({ required: true })
  options: any[];

  @Prop({ default: 'md' })
  buttonSize: 'sm' | 'md' | 'lg';

  @Prop({ default: 'default' })
  variant: TVariant;

  @Prop({ default: false, type: Boolean })
  autoWidth: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  disabledOptions: Array<string | number>;

  @Prop({ default: 'value' })
  valueKey: string;

  @Prop({ default: 'label' })
  labelKey: string;

  @Prop({ default: 'icon' })
  iconKey: string;

  @Prop({ default: false, type: Boolean })
  automationBindDataElement: boolean;

  @Prop({ default: 'automationSelector' })
  automationSelectorKey: string;

  get formattedOptions() {
    return this.options.map((option) => {
      if (typeof option === 'string') {
        return {
          value: option,
          label: option,
          icon: null,
          automationSelector: null,
          disabled:
            this.disabledOptions && this.disabledOptions.includes(option)
        };
      } else {
        // TODO implement validation
        // if (!Object.prototype.hasOwnProperty.call(option, this.valueKey)) {
        //   throw new Error(
        //     `valueKey ${this.valueKey} doesn't exist in option interface`
        //   );
        // }

        return {
          value: option[this.valueKey],
          label: option[this.labelKey],
          icon: option[this.iconKey],
          automationSelector: this.automationBindDataElement
            ? option[this.automationSelectorKey]
            : null,
          disabled:
            this.disabledOptions &&
            this.disabledOptions.includes(option[this.valueKey])
        };
      }
    });
  }

  get widthClasses() {
    return { 'w-full': this.autoWidth };
  }

  get typeColorClass() {
    return variantClasses[this.variant];
  }

  handleSelect(value) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
</script>
