<template>
  <div class="inline-flex w-full flex-col" :class="!fullWidth && 'max-w-280'">
    <label v-if="label" :for="fieldId">
      {{ label }}
    </label>
    <div
      class="inline-flex items-center rounded-4 ring-1 transition focus-within:ring-2"
      :class="[
        containerOffsetClass,
        backgroundColor,
        disabled && disabledInputClass,
        errorText ? 'ring-danger-red' : borderClass
      ]"
    >
      <slot v-if="isPrependSlot" name="prepend"></slot>
      <span
        v-if="!isPrependSlot && prependIconClass"
        class="pl-12 text-20 text-main-dark-40"
        :class="prependIconClass"
      />

      <!-- Input -->
      <textarea
        v-model="modelValue"
        ref="textarea"
        v-mask="mask"
        :id="fieldId"
        :style="textareaHeightStyle"
        :autocomplete="autocomplete ? 'on' : 'off'"
        :class="[
          padding,
          fontSizeClass,
          heightClass,
          disabled && disabledTextClass,
          contentResize ? 'resize-none' : 'resize',
          textareaCustomClasses,
          placeholderClass
        ]"
        class="flex-grow rounded-4 bg-transparent outline-none"
        v-bind="$attrs"
        v-on="listeners"
        :disabled="disabled"
      />

      <template>
        <slot v-if="isAppendSlot" name="append"></slot>
        <span
          v-if="!isAppendSlot && appendIconClass"
          class="pr-12 text-20 text-main-dark-40"
          :class="appendIconClass"
        />
      </template>
    </div>

    <p v-if="errorText" class="mb-0 ml-16 mt-4 text-base text-danger-red">
      {{ errorText }}
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { generateUuid } from '@/core/utils/common.utils';
import tailwindConfig from '../../../tailwind.config';

@Component({
  name: 'AppTextarea',
  inheritAttrs: false
})
export default class AppTextarea extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ type: String, required: false })
  label: string;

  @Prop({ type: String, required: false })
  id: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: null })
  mask: string | null;

  @Prop({ default: false, type: Boolean })
  contentResize: boolean;

  @Prop({ default: false, type: Boolean })
  autocomplete: boolean;

  @Prop({ default: null })
  maxResizeHeightClass: string;

  @Prop({ default: 'p-16', type: String })
  padding: string;

  @Prop({ default: 'text-14', type: String })
  fontSizeClass: string;

  @Prop({ default: 'focus-within:ring-accent-purple', type: String })
  borderClass: string;

  @Prop({ default: 'bg-transparent', type: String })
  backgroundColor: string;

  @Prop({ default: 'p-4 m-2', type: String })
  containerOffsetClass: string;

  @Prop({ default: 'h-auto', type: String })
  heightClass: string;

  @Prop({ default: '', type: String })
  textareaCustomClasses: string;

  @Prop({
    default: 'text-main-dark-20 cursor-not-allowed select-none',
    type: String
  })
  disabledTextClass: string;

  @Prop({
    default: 'ring-main-dark-10 cursor-not-allowed',
    type: String
  })
  disabledInputClass: string;

  @Prop({ default: null })
  appendIcon: string;

  @Prop({ default: null })
  prependIcon: string;

  @Prop({
    default: '',
    type: String
  })
  placeholderClass: string;

  $refs: {
    textarea: HTMLInputElement;
  };

  get maxResizeHeightClassNumber(): number {
    const heigthClassValue = this.maxResizeHeightClass?.split('-')[1];
    const heigthClassValueOnPx = tailwindConfig.theme.spacing[heigthClassValue];
    return parseInt(heigthClassValueOnPx, 10);
  }

  textareaHeight: number = null;

  get shouldResizeContent(): boolean {
    if (!this.contentResize) return false;

    if (this.textareaHeight > this.maxResizeHeightClassNumber) {
      return false;
    }

    return true;
  }

  get fieldId(): string {
    return this.id ?? generateUuid();
  }

  // v-model
  get modelValue() {
    return this.value;
  }

  set modelValue(value: string) {
    this.$emit('input', value);
  }

  get textareaHeightStyle() {
    if (!this.textareaHeight) {
      return { height: 'auto' };
    }

    if (this.textareaHeight > this.maxResizeHeightClassNumber) {
      return { height: this.maxResizeHeightClassNumber.toString() + 'px' };
    }
    return {
      height: this.textareaHeight + 'px'
    };
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners;
    return listeners;
  }

  get isPrependSlot() {
    return this.$slots.prepend;
  }

  get isAppendSlot() {
    return this.$slots.append;
  }

  get appendIconClass() {
    return this.appendIcon && `icon-${this.appendIcon}`;
  }
  get prependIconClass() {
    return this.prependIcon && `icon-${this.prependIcon}`;
  }

  @Watch('modelValue')
  modelValueChanged(): void {
    if (this.contentResize) {
      this.updateHeight();
    }
  }

  mounted(): void {
    this.updateHeight();
  }

  focus(): void {
    this.$nextTick(() => {
      this.updateHeight();
      this.$refs.textarea?.focus();
    });
  }

  blur(): void {
    this.$nextTick(() => {
      this.$refs.textarea?.blur();
    });
  }

  select(): void {
    this.$nextTick(() => {
      this.$refs.textarea?.select();
    });
  }

  updateHeight(): void {
    if (!this.contentResize) {
      return;
    }

    this.textareaHeight = 1;
    this.$nextTick(() => {
      this.textareaHeight = this.$refs?.textarea?.scrollHeight;
    });
  }
}
</script>
