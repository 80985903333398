import axios, {
    AxiosResponse
} from 'axios';
import {
    PagedResultDtoOfGetElementBehaviourForViewDto,
    ZadarResponse
} from './models'
export default class ElementBehavioursApiService {
    public static getAll(params ? : {
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfGetElementBehaviourForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/ElementBehaviours/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
}