import Filter from './Filter';
import FilterFactory from './FilterFactory';
import { FilterScope } from './FilterScope';
import IFilterDefinition from './IFilterDefinition';

export default class FilterSerializer {
  public static serialize(filters: Filter[]): string {
    if (!filters || filters.length == 0) return null;
    const definitions = filters.map((f) => f.serialize());
    return JSON.stringify({ filters: definitions });
  }

  public static deserialize(data: string): Filter[] {
    if (!data) return [];
    const definitions = JSON.parse(data).filters as IFilterDefinition[];
    return definitions.map((d) =>
      FilterFactory.getFilter(FilterScope[d.scope], d.parameter, d.type)
    );
  }
}
