import {
  GraphObstacleProvider,
  IEdge,
  IRenderContext,
  IGraph,
  FilteredGraphWrapper,
  GeneralPath
} from 'yfiles';

/**
 * A custom GraphObstacleProvider that allows filtering the edges under consideration.
 */
export class FilteringGraphObstacleProvider extends GraphObstacleProvider {
  // Need to cache to avoid memory leaks
  // See remarks section in https://docs.yworks.com/yfileshtml/#/api/FilteredGraphWrapper
  private filteredGraphWrapper: FilteredGraphWrapper;

  constructor(private edgePredicate?: ((edge: IEdge) => boolean) | undefined) {
    super();
  }

  protected getGraph(context: IRenderContext): IGraph | null {
    const graph = super.getGraph(context);
    if (!this.filteredGraphWrapper) {
      this.filteredGraphWrapper = new FilteredGraphWrapper(
        graph,
        () => true,
        this.edgePredicate
      );
    }
    return this.filteredGraphWrapper;
  }

  getObstacles(context: IRenderContext): GeneralPath | null {
    return super.getObstacles(context);
  }
}
