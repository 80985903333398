import {
  delegate,
  MarqueeSelectionInputMode,
  MarqueeSelectionEventArgs,
  MultiplexingInputMode,
  ConcurrencyController,
  IInputModeContext,
  CanvasComponent,
  MouseEventArgs,
  EventArgs,
  Cursor,
  Size,
  Rect,
  KeyEventArgs,
  Key
} from 'yfiles';
import { CreateTextBoxArgs } from '@/core/services/graph/TextBoxService';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';

enum State {
  Idle,
  Active,
  CreationStarted
}

type Listener = (sender: MultiplexingTextBoxInputMode, args: EventArgs) => void;

type CreateTextBoxListener = (
  sender: MultiplexingTextBoxInputMode,
  args: CreateTextBoxArgs
) => void;

export default class MultiplexingTextBoxInputMode extends MultiplexingInputMode {
  /* Event Listeners */
  private startedListener: Listener | null = null;
  private stoppedListener: Listener | null = null;
  private creationStartedListener: Listener | null;
  private createItemListener: CreateTextBoxListener | null = null;
  private marqueeDragFinishedListener = this.onMarqueeDragFinished.bind(this);
  private marqueeDragStartedListener = this.onMarqueeDragStarted.bind(this);
  private keyDownListener = this.onKeyDown.bind(this);

  /* Input Modes */
  private marqueeSelectionInputMode: MarqueeSelectionInputMode;

  private state: State = State.Idle;

  /**
   * Cursor while this input mode is active
   */
  public activeCursor: Cursor = Cursor.CROSSHAIR;

  /**
   * Cursor used when idling
   */
  public idleCursor: Cursor = Cursor.DEFAULT;

  // The selected item from the palette
  public item: IDocumentPaletteItem | null = null;

  public get isActive(): boolean {
    return this.state == State.Active || this.state == State.CreationStarted;
  }

  public get isCreationStarted(): boolean {
    return this.state == State.CreationStarted;
  }

  constructor() {
    super();
    this.add(
      (this.marqueeSelectionInputMode = this.createMarqueeSelectionInputMode())
    );
  }

  private createMarqueeSelectionInputMode(): MarqueeSelectionInputMode {
    const inputMode = new MarqueeSelectionInputMode();
    inputMode.enabled = false;
    inputMode.marqueeCursor = this.activeCursor;
    return inputMode;
  }

  public install(
    context: IInputModeContext,
    controller: ConcurrencyController
  ): void {
    context.canvasComponent.addMouseClickListener(this.onMouseClick.bind(this));
    context.canvasComponent.addKeyDownListener(this.keyDownListener);
    this.marqueeSelectionInputMode.addDragFinishedListener(
      this.marqueeDragFinishedListener
    );
    this.marqueeSelectionInputMode.addDragStartedListener(
      this.marqueeDragStartedListener
    );

    super.install(context, controller);
  }

  public uninstall(context: IInputModeContext): void {
    context.canvasComponent.removeMouseClickListener(
      this.onMouseClick.bind(this)
    );
    context.canvasComponent.removeKeyDownListener(this.keyDownListener);
    this.marqueeSelectionInputMode.removeDragFinishedListener(
      this.marqueeDragFinishedListener
    );
    this.marqueeSelectionInputMode.removeDragStartedListener(
      this.marqueeDragStartedListener
    );

    super.uninstall(context);
  }

  private updateCursor(): void {
    this.controller.preferredCursor = this.isActive
      ? this.activeCursor
      : this.idleCursor;
  }

  private resetState(): void {
    this.item = null;
    this.state = State.Idle;
    this.marqueeSelectionInputMode.enabled = false;
    this.updateCursor();
  }

  private onMouseClick(sender: CanvasComponent, args: MouseEventArgs): void {
    if (!this.isActive) {
      return;
    }
    if (this.createItemListener) {
      this.createItemListener(this, {
        wrapTextInShape: false,
        rect: new Rect(args.location, Size.ZERO),
        inputModeContext: this.inputModeContext,
        labelStyle: this.item?.style
      });
    }
    this.stop();
  }

  private onMarqueeDragFinished(
    sender: MarqueeSelectionInputMode,
    evt: MarqueeSelectionEventArgs
  ): void {
    if (this.createItemListener) {
      this.createItemListener(this, {
        wrapTextInShape: true,
        rect: evt.rectangle,
        inputModeContext: this.inputModeContext,
        labelStyle: this.item?.style
      });
    }

    this.stop();
  }

  private onMarqueeDragStarted(
    sender: MarqueeSelectionInputMode,
    evt: MarqueeSelectionEventArgs
  ): void {
    this.state = State.CreationStarted;
    this.creationStartedListener(this, new EventArgs());
  }

  private onKeyDown(
    sender: MultiplexingTextBoxInputMode,
    args: KeyEventArgs
  ): void {
    if (args.key == Key.ESCAPE) {
      this.stop();
    }
  }

  public start(item: IDocumentPaletteItem): boolean {
    if (this.controller.hasMutex()) {
      this.controller.releaseMutex();
    }

    if (!this.controller.canRequestMutex()) {
      return;
    }
    this.controller.requestMutex();
    this.item = item;
    this.state = State.Active;
    this.marqueeSelectionInputMode.enabled = true;
    this.updateCursor();
    if (this.startedListener) {
      this.startedListener(this, new EventArgs());
    }
    return true;
  }

  public stop(): boolean {
    if (!this.isActive) {
      return true;
    }
    if (this.controller.hasMutex()) {
      this.controller.releaseMutex();
    }
    this.resetState();
    if (this.stoppedListener) {
      this.stoppedListener(this, new EventArgs());
    }
    return true;
  }

  public tryStop(): boolean {
    if (this.isCreationStarted) {
      return false;
    }

    return this.stop();
  }

  public addStartedListener(listener: Listener): void {
    this.startedListener = delegate.combine(this.startedListener, listener);
  }

  public removeStartedListener(listener: Listener): void {
    this.startedListener = delegate.remove(this.startedListener, listener);
  }

  public addStoppedListener(listener: Listener): void {
    this.stoppedListener = delegate.combine(this.stoppedListener, listener);
  }

  public removeStoppedListener(listener: Listener): void {
    this.stoppedListener = delegate.remove(this.stoppedListener, listener);
  }

  public addCreateItemListener(listener: CreateTextBoxListener): void {
    this.createItemListener = delegate.combine(
      this.createItemListener,
      listener
    );
  }

  public removeCreateItemListener(listener: CreateTextBoxListener): void {
    this.createItemListener = delegate.remove(
      this.createItemListener,
      listener
    );
  }

  public addCreationStartedListener(listener: Listener): void {
    this.creationStartedListener = delegate.combine(
      this.creationStartedListener,
      listener
    );
  }

  public removeCreationStartedListener(listener: Listener): void {
    this.creationStartedListener = delegate.remove(
      this.creationStartedListener,
      listener
    );
  }
}
