import { Module } from 'vuex';

// namespace
export const OWNERSHIP_NAMESPACE = 'ownership-namespace';

// getters
export const GET_OWNERSHIP_OF = 'getOwnershipOf';
export const GET_INDIRECT_OWNERSHIP_PERCENTAGE =
  'getIndirectOwnershipPercentage';

// mutations
export const SET_OWNERSHIP_OF = 'setOwnershipOf';
export const SET_INDIRECT_OWNERSHIP_PERCENTAGE =
  'setIndirectOwnershipPercentage';

// actions ...

interface State {
  ownershipOf: boolean;
  indirectOwnershipPercentage: boolean;
}

const getInitialState = (): State => {
  return {
    ownershipOf: false,
    indirectOwnershipPercentage: false
  };
};

const ownershipModule: Module<State, any> = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    [GET_OWNERSHIP_OF](state) {
      return state.ownershipOf;
    },

    [GET_INDIRECT_OWNERSHIP_PERCENTAGE](state) {
      return state.indirectOwnershipPercentage;
    }
  },

  mutations: {
    [SET_OWNERSHIP_OF](state, payload) {
      state.ownershipOf = payload;
    },

    [SET_INDIRECT_OWNERSHIP_PERCENTAGE](state, payload) {
      state.indirectOwnershipPercentage = payload;
    }
  },

  actions: {}
};

export default ownershipModule;
