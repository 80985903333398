import {
  EdgeRouterEdgeLayoutDescriptor,
  EdgeRouterEdgeRoutingStyle,
  PenaltySettings,
  CurveConnectionStyle,
  BusRouterBusDescriptor
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
export function getDefaultEdgeDescriptor(): EdgeRouterEdgeLayoutDescriptor {
  console.debug('getOwnershipEdgeDescriptor');
  const descriptor = new EdgeRouterEdgeLayoutDescriptor();

  descriptor.routingStyle = EdgeRouterEdgeRoutingStyle.OCTILINEAR;
  descriptor.minimumEdgeToEdgeDistance = 0;
  descriptor.minimumNodeCornerDistance = 3.0;
  descriptor.minimumNodeCornerDistance = 50;
  descriptor.minimumFirstSegmentLength = 5.0;
  descriptor.minimumLastSegmentLength = 5.0;

  let penaltySettings: PenaltySettings = new PenaltySettings();
  penaltySettings.edgeLengthPenalty = 100;
  penaltySettings.bendPenalty = 100;
  penaltySettings.edgeCrossingPenalty = 0;

  penaltySettings.bendsInNodeToEdgeDistancePenalty;
  penaltySettings.edgeLabelCrossingPenalty;

  descriptor.penaltySettings = penaltySettings;

  return descriptor;
}

export function getOwnershipEdgeDescriptor(): EdgeRouterEdgeLayoutDescriptor {
  console.debug('getOwnershipEdgeDescriptor');
  const descriptor = new EdgeRouterEdgeLayoutDescriptor();

  descriptor.routingStyle = EdgeRouterEdgeRoutingStyle.ORTHOGONAL;
  descriptor.minimumEdgeToEdgeDistance = 0;
  //descriptor.minimumNodeCornerDistance = 3.0;
  descriptor.minimumNodeCornerDistance = diagramConfig.grid.size * 2;
  descriptor.minimumFirstSegmentLength = diagramConfig.grid.size;
  descriptor.minimumLastSegmentLength = diagramConfig.grid.size;

  let penaltySettings: PenaltySettings = new PenaltySettings();
  penaltySettings.edgeLengthPenalty = 10;
  penaltySettings.bendPenalty = 100;
  penaltySettings.edgeCrossingPenalty = 0;
  penaltySettings.nodeCrossingPenalty = 400;

  descriptor.penaltySettings = penaltySettings;

  return descriptor;
}

export function getContractEdgeDescriptor(busId): BusRouterBusDescriptor {
  return new BusRouterBusDescriptor(busId);
}

export function getCashflowEdgeDescriptor(): EdgeRouterEdgeLayoutDescriptor {
  console.debug('getCashflowEdgeDescriptor');
  let curveStyle: CurveConnectionStyle = CurveConnectionStyle.KEEP_PORT;
  let penaltySettings: PenaltySettings =
    PenaltySettings.OPTIMIZATION_EDGE_LENGTHS;

  let edgeRouterEdgeRoutingStyle: EdgeRouterEdgeRoutingStyle =
    EdgeRouterEdgeRoutingStyle.ORTHOGONAL;

  const descriptor = new EdgeRouterEdgeLayoutDescriptor();
  //descriptor.createControlPoints = true;
  descriptor.routingStyle = edgeRouterEdgeRoutingStyle;
  descriptor.minimumEdgeToEdgeDistance = diagramConfig.grid.size;
  descriptor.minimumNodeCornerDistance = diagramConfig.grid.size;
  descriptor.minimumFirstSegmentLength = diagramConfig.grid.size;
  descriptor.minimumLastSegmentLength = diagramConfig.grid.size;

  //descriptor.segmen
  descriptor.penaltySettings = penaltySettings;
  descriptor.sourceCurveConnectionStyle = curveStyle;
  descriptor.targetCurveConnectionStyle = curveStyle;

  return descriptor;
}
