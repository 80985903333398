<template>
  <AppModal
    :value="value"
    :title="$t('CHANGE_PASSWORD')"
    :min-width="500"
    :max-width="500"
    @input="onValueChangeHandler"
  >
    <div
      class="mb-16 flex items-center gap-16 rounded-4 bg-warning-orange px-16 py-8 text-white"
    >
      <AppIcon name="notification-warning" />
      <span>
        {{ $t('CHANGE_PASSWORD_WARNING') }}
      </span>
    </div>

    <div class="w-full">
      <AppInput
        name="currentPassword"
        v-model.trim="$v.currentPassword.$model"
        :placeholder="$t('CURRENT_PASSWORD')"
        full-width
        type="password"
        class="mb-20"
        :error-text="currentPasswordErrors"
        @input="() => (passwordMismatchError = false)"
      />

      <AppInput
        name="newPassword"
        v-model.trim="$v.newPassword.$model"
        :placeholder="$t('NEW_PASSWORD')"
        full-width
        type="password"
        class="mb-20"
        :is-error="$v.newPassword.$anyError"
      >
        <div
          v-if="$v.newPassword.$anyError"
          slot="error-text"
          class="mb-0 ml-16 mt-4 text-base text-danger-red"
        >
          <p v-for="error in validationErrorList" :key="error">
            {{ error }}
          </p>
        </div>
      </AppInput>

      <AppInput
        name="repeatNewPassword"
        v-model.trim="$v.repeatNewPassword.$model"
        :placeholder="$t('PLEASE_REENTER_YOUR_NEW_PASSWORD')"
        full-width
        type="password"
        :is-error="$v.repeatNewPassword.$anyError"
      >
        <div
          v-if="$v.repeatNewPassword.$anyError"
          slot="error-text"
          class="mb-0 ml-16 mt-4 text-base text-danger-red"
        >
          <p v-for="error in validationErrorList" :key="error">
            {{ error }}
          </p>
        </div>
      </AppInput>
    </div>

    <template #footer="{ close }">
      <div class="flex w-full items-center justify-end">
        <AppButton
          size="lg"
          variant="purple"
          class="mr-12"
          :disabled="$v.$anyError"
          :loading="loading"
          @click="onSubmitHandler"
        >
          {{ $t('CHANGE_PASSWORD') }}
        </AppButton>
        <AppButton size="lg" :disabled="loading" @click="close">
          {{ $t('CANCEL') }}
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation, validationMixin } from 'vuelidate';
import { Validations } from 'vuelidate-property-decorators';
import { required, minLength, sameAs, not } from 'vuelidate/lib/validators';

import { ChangePasswordInput } from '@/api/models';
import ProfileApiService from '@/api/ProfileApiService';
import appConfig from '@/core/config/appConfig';
import {
  getPasswordValidationRules,
  getValidationErrorList
} from '@/core/utils/FormValidationUtils';

@Component({
  name: 'AppUpdatePasswordModal'
})
export default class AppUpdatePasswordModal extends mixins(validationMixin) {
  @Prop({ required: true })
  value: boolean;

  @Validations()
  validations = {
    currentPassword: { required },
    newPassword: {
      required,
      notOldPassword: not(sameAs('currentPassword')),
      minLength: minLength(appConfig.passwordConfig.requiredLength),
      ...this.passwordValidationRules
    },
    repeatNewPassword: {
      required,
      minLength: minLength(appConfig.passwordConfig.requiredLength),
      sameAs: sameAs('newPassword'),
      ...this.passwordValidationRules
    }
  };

  currentPassword: string = '';
  newPassword: string = '';
  repeatNewPassword: string = '';
  passwordMismatchError: boolean = false;

  loading: boolean = false;

  get passwordValidationRules() {
    return getPasswordValidationRules(appConfig.passwordConfig);
  }

  get validationErrorList() {
    return getValidationErrorList(this.$v.newPassword);
  }

  get currentPasswordErrors(): string {
    if (this.$v.currentPassword.$invalid && this.$v.currentPassword.$dirty) {
      return this.$t('CURRENT_PASSWORD_IS_REQUIRED');
    }

    if (this.passwordMismatchError) {
      return this.$t('CURRENT_PASSWORD_MISMATCHED');
    }

    return '';
  }

  onSubmitHandler() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    this.loading = true;

    const updatePasswordData = new ChangePasswordInput(
      this.$v.currentPassword.$model,
      this.$v.newPassword.$model
    );

    ProfileApiService.changePassword(updatePasswordData)
      .then((response) => {
        if (response.status == 200) {
          this.$notify({
            title: this.$t('PASSWORD_SUCCESSFULLY_UPDATED').toString(),
            type: 'success'
          });

          this.onValueChangeHandler(false);
          this.$router.push({
            name: 'logout'
          });
        } else {
          this.$notify({
            title: this.$t('UNABLE_TO_UPDATE_PASSWORD').toString(),
            type: 'error'
          });
        }
      })
      .catch((error) => {
        this.passwordMismatchError =
          error.response.data.error.message === '[Identity.Password mismatch]';
        this.$notify({
          title: this.$t('ERROR_UPDATING_PASSWORD').toString(),
          type: 'error'
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onValueChangeHandler(value: boolean) {
    this.$emit('input', value);
    this.resetForm();
  }

  resetForm() {
    this.currentPassword = '';
    this.newPassword = '';
    this.repeatNewPassword = '';

    this.$v.$reset();
  }
}
</script>
