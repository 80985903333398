import { Module } from 'vuex';
import SessionStorageService from '../sessionstorage.service';

export const SET_QS_BTN_BEGINNER_MODE = 'setQsBtnBeginnerMode';
export const GET_QS_BTN_BEGINNER_MODE = 'getQsBtnBeginnerMode';

const QS_BTN_BEGINNER_MODE = 'qsBtnBeginnerMode';

interface State {
  qsBtnBeginnerMode: boolean;
}

const getInitialState = (): State => {
  const qsBtnBeginnerMode: boolean = SessionStorageService.hasKey(
    QS_BTN_BEGINNER_MODE
  )
    ? SessionStorageService.getItem(QS_BTN_BEGINNER_MODE)
    : true;
  return {
    qsBtnBeginnerMode
  };
};

const userActivityModule: Module<State, any> = {
  namespaced: false,
  state: getInitialState(),
  getters: {
    [GET_QS_BTN_BEGINNER_MODE](state) {
      return state.qsBtnBeginnerMode;
    }
  },
  actions: {
    [SET_QS_BTN_BEGINNER_MODE](state, payload) {
      SessionStorageService.setItem(QS_BTN_BEGINNER_MODE, payload);
      state.commit(SET_QS_BTN_BEGINNER_MODE, payload);
    }
  },
  mutations: {
    [SET_QS_BTN_BEGINNER_MODE](state, payload) {
      state.qsBtnBeginnerMode = payload;
    }
  }
};

export default userActivityModule;
