import { LayoutItemRotation, PageElementPosition } from '@/api/models';
import JRect from '@/core/common/JRect';
import JSize from '@/core/common/JSize';
import HtmlLayoutItem from './HtmlLayoutItem';
import { LayoutItemType } from './LayoutItemType';

export default abstract class WidgetLayoutItem extends HtmlLayoutItem {
  public format: string;
  public presetId = 1;
  public position: PageElementPosition = PageElementPosition.BottomRight;
  public rotation: LayoutItemRotation = LayoutItemRotation.Default;

  constructor(type: LayoutItemType, minSize?: JSize) {
    super(type);
    this.padding = WidgetLayoutItem.padding;
    this.resizable = false;
    this.draggable = true;
    if (minSize) {
      this.minSize = minSize;
    } else {
      this.minSize = new JSize(10, 10);
    }
    this.isTextEditable = false;
    this.isTextStyleEditable = true;
  }

  public override serialize(): Object {
    const obj = super.serialize();
    obj['format'] = this.format;
    obj['presetId'] = this.presetId;
    obj['position'] = this.position;
    obj['rotation'] = this.rotation;
    return obj;
  }

  public override setLayout(layout: JRect): void {
    if (layout.width < this.minSize.width) {
      layout.width = this.minSize.width;
    }

    if (layout.height < this.minSize.height) {
      layout.height = this.minSize.height;
    }
    this.layout = layout;
  }
}
