import {
  DefaultGraph,
  GeneralPath,
  GeneralPathNodeStyle,
  GraphComponent,
  ICanvasContext,
  INode,
  INodeStyle,
  IRenderContext,
  MutableRectangle,
  Point,
  Rect,
  ShapeNodeStyle,
  Size,
  SvgExport,
  SvgVisual
} from 'yfiles';
import CompositeItem from './CompositeItem';
import CompositeNodeStyle from './CompositeNodeStyle';
import CompositePathItemStyle from './CompositePathItemStyle';
import CompositeShapeItemStyle from './CompositeShapeItemStyle';
import { convertPathToAbsolute, parsePath } from './ShapeBuilderUtils';
import { CompositeItemType, PresetCompositeShapeType } from '@/api/models';
import { RotatableNodeStyleDecorator } from './RotatableNodes';
import config from '@/core/config/diagram.definition.config';
import paper from 'paper';

export function createUnionPathFromSvgVisual(
  svgVisual: SvgVisual,
  bounds: Rect
): GeneralPath {
  const canvasElement = document.createElement('canvas');
  try {
    document.body.appendChild(canvasElement);
    canvasElement.style.position = 'absolute';
    canvasElement.style.top = '50px';
    canvasElement.style.left = '50px';
    canvasElement.style.zIndex = '999999';
    canvasElement.setAttribute('width', '300');
    canvasElement.setAttribute('height', '300');

    paper.setup(canvasElement);
    const elementHtml = svgVisual.svgElement.outerHTML;
    const item = paper.project.importSVG(`<svg>${elementHtml}</svg>`);

    const allPaths = item
      .getItems({
        match: function (item) {
          return item instanceof paper.Path || item instanceof paper.Shape;
        }
      })
      .map((item) => {
        if (item instanceof paper.Shape) {
          // Convert ellipse or rectangle to path
          return item.toPath();
        } else {
          return item;
        }
      });

    var compoundPath = new paper.CompoundPath({
      children: allPaths
    });

    allPaths.forEach(function (path) {
      compoundPath.addChild(path.clone());
      path.remove();
    });

    var unitedPath = compoundPath.reduce(null) as paper.CompoundPath;

    const unitedPathStr = unitedPath.unite(null).pathData;
    const unitedPathStrAbs = convertPathToAbsolute(unitedPathStr);

    return parsePath(unitedPathStrAbs, {
      normalize: true,
      box: bounds
    });
  } finally {
    canvasElement.remove();
  }
}
/**
 * Creates a union path of all of the items of the CompositeNodeStyle.items
 * @param node the node, the `style` property must be of type `CompositeNodeStyle`
 * @returns
 */
export function createUnionPathFromNode(
  context: IRenderContext,
  node: INode
): GeneralPath {
  if (!(node.style instanceof CompositeNodeStyle)) {
    throw `Node style must be CompositeNodeStyle`;
  }
  const style: CompositeNodeStyle = node.style;

  const svgVisual = style.renderer
    .getVisualCreator(node, node.style)
    .createVisual(context) as SvgVisual;
  const bounds = node.layout.toRect();
  return createUnionPathFromSvgVisual(svgVisual, bounds);
}

export function union(graphComponent: GraphComponent): void {
  const canvasElement = document.createElement('canvas');
  try {
    document.body.appendChild(canvasElement);
    canvasElement.style.position = 'absolute';
    canvasElement.style.top = '50px';
    canvasElement.style.left = '50px';
    canvasElement.style.zIndex = '999999';
    canvasElement.setAttribute('width', '300');
    canvasElement.setAttribute('height', '300');

    paper.setup(canvasElement);

    const paths: paper.PathItem[] = [];
    const nodes = graphComponent.graph.nodes.toArray();
    nodes.forEach((node) => {
      if (node.style instanceof RotatableNodeStyleDecorator) {
        const pathStyle = node.style.wrapped as GeneralPathNodeStyle;
        const item = pathStyle.renderer.getVisualCreator(node, pathStyle);
        const visual = item.createVisual(
          graphComponent.createRenderContext()
        ) as SvgVisual;
        const pathElement = visual.svgElement;
        const pathData = pathElement.getAttribute('d');
        console.debug(pathData, '####');
        const path = new paper.Path(pathData);
        var transformString = pathElement.getAttribute('transform');

        // Example transform string: "translate(100, 50) rotate(45)"
        // Extracting the translation part using a regular expression
        var translateMatch = transformString.match(
          /translate\((-?\d+\.?\d*),?\s*(-?\d+\.?\d*)\)/
        );

        if (translateMatch) {
          var tx = parseFloat(translateMatch[1]);
          var ty = parseFloat(translateMatch[2]);

          // Assuming `paperPath` is your Paper.js path
          path.translate(new paper.Point(tx, ty));
        }
        console.debug(path.pathData);
        const absPathStr = convertPathToAbsolute(path.pathData);
        paths.push(new paper.Path(absPathStr));
      }
    });
    let unitedPath = paths[0];

    for (let i = 1; i < paths.length; i++) {
      unitedPath = unitedPath.unite(paths[i]);
    }
    const unitedPathStr = unitedPath.pathData;
    const unitedPathStrAbs = convertPathToAbsolute(unitedPathStr);
    const bounds = getBounds(graphComponent.canvasContext, nodes);
    const normalizedPath = parsePath(unitedPathStrAbs, {
      normalize: true,
      box: bounds
    });
    // // Unite the two paths
    graphComponent.graph.createNode({
      style: new RotatableNodeStyleDecorator(
        new GeneralPathNodeStyle(normalizedPath)
      ),
      layout: bounds
    });
  } finally {
    canvasElement.remove();
  }
}

export function createCompositeItems(
  context: ICanvasContext,
  nodes: INode[]
): CompositeItem[] {
  const bounds = getBounds(context, nodes);

  return nodes.map((n) => createCompositeItem(n, bounds));
}

export function createShape(graphComponent: GraphComponent): {
  items: CompositeItem[];
  size: Size;
} {
  const nodes = graphComponent.graph.nodes.toArray();
  if (
    !nodes
      .map((d) => (d.style as RotatableNodeStyleDecorator).wrapped)
      .every(
        (d) => d instanceof ShapeNodeStyle || d instanceof GeneralPathNodeStyle
      )
  ) {
    console.error(
      'Cannot create shape, all nodes must have a style of ShapeNodeStyle || GeneralPathNodeStyle'
    );
    return null;
  }
  const context = graphComponent.canvasContext;
  nodes.sort((a, b) => {
    //we reverse the array by multiply this by -1 so that the items are stored correctly and rendered in the correct order
    return graphComponent.graphModelManager.comparer.compare(a, b) * -1;
  });
  const bounds = getBounds(context, nodes);

  const items = createCompositeItems(context, nodes);
  const size = bounds.toSize();

  graphComponent.graph.clear();

  return {
    items,
    size
  };
}

export function splitShape(graphComponent: GraphComponent): void {
  const node = graphComponent.graph.nodes.first();
  if (!node || !(node.style instanceof CompositeNodeStyle)) {
    console.error(
      'Could not split shape, shape on canvas does not have style of type CompositeNodeStyle '
    );
    return;
  }
  const graph = graphComponent.graph;
  const style = node.style;
  const nodeLayout = node.layout;
  for (const item of style.items) {
    const rect = new Rect(
      nodeLayout.x + nodeLayout.width * item.layout.x,
      nodeLayout.y + nodeLayout.height * item.layout.y,
      nodeLayout.width * item.layout.width,
      nodeLayout.height * item.layout.height
    );

    const nodeStyle = createNodeStyle(item);
    graph.createNode({
      layout: rect,
      style: nodeStyle
    });
  }

  graph.remove(node);
}

function getShapeType(node: INode): CompositeItemType {
  const rotatableNodeStyle = node.style as RotatableNodeStyleDecorator;
  if (rotatableNodeStyle.wrapped instanceof ShapeNodeStyle) {
    return CompositeItemType.Shape;
  }
  if (rotatableNodeStyle.wrapped instanceof GeneralPathNodeStyle) {
    return CompositeItemType.Path;
  }
}

function createImageFromCompositeShape(
  items: CompositeItem[],
  layout: Rect
): string {
  const graphComponent = new GraphComponent();
  graphComponent.graph = new DefaultGraph();
  const bounds = new Rect(
    -layout.width / 2,
    -layout.height / 2,
    layout.width,
    layout.height
  );
  addNode(graphComponent, items, bounds.toSize());
  graphComponent.selection.clear();
  const svgExport = new SvgExport(bounds);
  const svg = svgExport.exportSvg(graphComponent);

  return SvgExport.encodeSvgDataUrl(SvgExport.exportSvgString(svg));
}

function createCompositeItem(node: INode, bounds: Rect): CompositeItem {
  const x = (node.layout.x - bounds.x) / bounds.width;
  const y = (node.layout.y - bounds.y) / bounds.height;
  const width = node.layout.width / bounds.width;
  const height = node.layout.height / bounds.height;
  const item: CompositeItem = {
    type: getShapeType(node),
    layout: new Rect(x, y, width, height),
    style: createCompositeItemStyleStyle(node)
  };
  return item;
}

function getBounds(context: ICanvasContext, nodes: INode[]): Rect {
  let bounds = new MutableRectangle();
  for (let index = 0; index < nodes.length; index++) {
    const element = nodes[index];
    bounds.add(
      element.style.renderer
        .getBoundsProvider(element, element.style)
        .getBounds(context)
    );
  }
  return bounds.toRect();
}

export function createNodeStyle(item: CompositeItem): INodeStyle {
  if (item.type == CompositeItemType.Shape) {
    const style = item.style as CompositeShapeItemStyle;
    return new RotatableNodeStyleDecorator(
      new ShapeNodeStyle({
        shape: config.nodeShapes[style.shape],
        fill: style.fill.clone(),
        stroke: style.stroke.clone()
      }),
      item.style.angle
    );
  }

  if (item.type == CompositeItemType.Path) {
    const style = item.style as CompositePathItemStyle;
    return new RotatableNodeStyleDecorator(
      new GeneralPathNodeStyle({
        fill: style.fill.clone(),
        stroke: style.stroke.clone(),
        path: parsePath(style.path)
      }),
      item.style.angle
    );
  }

  throw `Unknown item type ${item.type}`;
}

function createCompositeItemStyleStyle(
  node: INode
): CompositeShapeItemStyle | CompositePathItemStyle {
  const rotatableNodeStyle = node.style as RotatableNodeStyleDecorator;
  const style = rotatableNodeStyle.wrapped;

  if (style instanceof ShapeNodeStyle) {
    const shapeStyle: CompositeShapeItemStyle = {
      shape: config.shapeNodeShapes[style.shape],
      fill: style.fill.clone(),
      stroke: style.stroke.clone(),
      angle: rotatableNodeStyle.angle
    };

    return shapeStyle;
  }

  if (style instanceof GeneralPathNodeStyle) {
    const pathStyle: CompositePathItemStyle = {
      fill: style.fill.clone(),
      stroke: style.stroke.clone(),
      path: style.path.createSvgPathData(),
      angle: rotatableNodeStyle.angle
    };

    return pathStyle;
  }
}

export function addNode(
  graphComponent: GraphComponent,
  items: CompositeItem[],
  size: Size
): void {
  graphComponent.graph.clear();
  const style = new CompositeNodeStyle(items, PresetCompositeShapeType.Custom);

  const node = graphComponent.graph.createNode({
    layout: new Rect(0, 0, size.width, size.height),
    style: style
  });
  graphComponent.graph.setNodeCenter(node, Point.ORIGIN);
  graphComponent.zoomToAnimated(node.layout.center, 2);
  graphComponent.selection.clear();
  graphComponent.selection.setSelected(node, true);
}
