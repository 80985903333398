﻿export const SpinnerKeys = {
  LoadDefinitionDetail: 'load-definition-detail',
  SearchDocuments: 'search-documents',
  LoadPage: 'load-page',
  List: 'list-key',
  CreateNew: 'create-new',
  SaveTheme: 'save-theme',
  SearchImports: 'search-imports',
  QuickPanel: 'quick-panel',
  RibbonContainer: 'ribbon-container',
  StickySidebar: 'sticky-sidebar',
  SidebarSkeleton: 'sidebar-skeleton',
  LoadImport: 'load-import',
  ImportSpreadsheet: 'import-spreadsheet',
  SavedVersionHistory: 'saved-version-history'
};

export default SpinnerKeys;
