import { INode, IRenderContext, Point, Rect, Visual } from 'yfiles';
import JigsawNodeDecorator from './JigsawNodeDecorator';

export default abstract class MovableDecorator extends JigsawNodeDecorator {
  abstract getLayout(node: INode): Rect;
  /**
   *
   * @param node
   * @param location A ratio between 0-1
   */
  abstract setLocation(
    node: INode,
    location: Point,
    snappedLocation?: Point
  ): void;
  abstract getSnapLocations(node: INode): Point[];
  abstract createVisual(context: IRenderContext, node: INode): Visual;
  abstract updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual;
}
