import { DashStyleType, StrokeDto } from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';

export default class PageDividerUtils {
  public static get defaultDividerThickness(): number {
    return ExportConfig.defaultDividerStyle.thickness;
  }

  public static get defaultDividerDashType(): DashStyleType {
    return ExportConfig.defaultDividerStyle.dashStyle.type;
  }

  public static get defaultDividerColor(): string {
    return ExportConfig.defaultDividerStyle.fill.color;
  }

  public static dividerLineStyle(dividerStyle: StrokeDto): string {
    const dashType =
      dividerStyle?.dashStyle?.type ?? PageDividerUtils.defaultDividerDashType;
    switch (dashType) {
      case DashStyleType.Dash:
        return 'dashed';
      case DashStyleType.Dot:
        return 'dotted';
      case DashStyleType.Solid:
        return 'solid';
      default:
        return 'dashed';
    }
  }

  public static getDividerThickness(dividerStyle: StrokeDto): number {
    return dividerStyle?.thickness ?? PageDividerUtils.defaultDividerThickness;
  }

  public static getDividerColor(dividerStyle: StrokeDto): string {
    return dividerStyle?.fill?.color ?? PageDividerUtils.defaultDividerColor;
  }
}
