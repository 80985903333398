import appConfig from '@/core/config/appConfig';
import { isNumber } from 'lodash';

const CHAR_SET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
// do not change this seed. It will invalid all url's
const seed = 9876444513;
export function obfuscateId(num: number): string {
  if (!appConfig.client.enableDocumentIdObfuscation) {
    return num.toString();
  }
  num *= seed;
  if (num === 0) {
    return CHAR_SET[0];
  }
  const base = CHAR_SET.length;
  let encoded = '';
  while (num > 0) {
    encoded = CHAR_SET[num % base] + encoded;
    num = Math.floor(num / base);
  }
  return encoded;
}

export function unobfuscateId(encoded: string): number {
  // we check if this is a number. The value may still be obfuscated, so we can try to unobfuscate if the input is not a number
  if (!appConfig.client.enableDocumentIdObfuscation && isNumber(encoded)) {
    return +encoded;
  }
  const base = CHAR_SET.length;
  let num = 0;
  for (let char of encoded) {
    num = num * base + CHAR_SET.indexOf(char);
  }
  return num / seed;
}
