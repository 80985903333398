import { Rect, GraphEditorInputMode, Size, IInputModeContext } from 'yfiles';
import { LabelStyleDto, TextFit } from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import DiagramUtils from '@/core/utils/DiagramUtils';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import TextBoxNodeStyle from '@/core/styles/TextBoxNodeStyle';
import TextBoxLabelModel from '@/core/services/graph/label-models/TextBoxLabelModel';
import INodeTag from '@/core/common/INodeTag';
import JigsawTextEditorInputMode from './input-modes/text-editor/JigsawTextEditorInputMode';
import i18n from '@/core/plugins/vue-i18n';
import { measureText } from '@/core/utils/html.utils';

export type CreateTextBoxArgs = {
  inputModeContext: IInputModeContext;
  rect: Rect;
  textFit?: TextFit;
  wrapTextInShape?: boolean;
  labelStyle?: LabelStyleDto;
};

export default class TextBoxService {
  private static get defaultTag(): INodeTag {
    const tag = DiagramUtils.createNewNodeTag('TEXTBOX', null);
    tag.isAnnotation = true;
    tag.annotationType = AnnotationType.Text;
    tag.labelIsPlaceholder = true;
    tag.labelData.textFit = TextFit.ResizeShape;

    return tag;
  }

  public static createTextBox(args: CreateTextBoxArgs): void {
    const { inputModeContext } = args;
    const snappedLocation = args.rect.toPoint();

    const placeHolderText = i18n.t('ADD_TEXT_ELLIPSES');
    const labelStyle =
      args.labelStyle || DiagramUtils.getSystemDefaultLabelStyle();
    const tag = TextBoxService.defaultTag;
    tag.labelData.wrapTextInShape = args.wrapTextInShape ?? false;
    tag.labelData.textFit = args.textFit ?? tag.labelData.textFit;

    let nodeSize = args.rect || DiagramUtils.getNodeSize(tag.style);

    if (nodeSize.width < labelStyle.font.fontSize) {
      tag.labelData.wrapTextInShape = false;
    }

    const html = `<div style="font-size:${labelStyle.font.fontSize}pt;font-family:${labelStyle.font.fontFamily}">${placeHolderText}</div>`;
    const textSize = measureText(html);

    nodeSize = new Size(
      Math.max(nodeSize.width, textSize.width),
      Math.max(nodeSize.height, labelStyle.font.fontSize * 1.5)
    );

    const node = inputModeContext.graph.createNode({
      tag,
      layout: new Rect(snappedLocation, nodeSize),
      style: new JigsawNodeStyle(new TextBoxNodeStyle(), [])
    });

    const label = DiagramUtils.setLabelValue(
      inputModeContext.graph,
      node,
      placeHolderText,
      'left',
      labelStyle
    );

    inputModeContext.graph.setLabelLayoutParameter(
      label,
      new TextBoxLabelModel().createDefaultParameter()
    );

    label.tag.style = labelStyle;

    const inputMode = inputModeContext.canvasComponent
      .inputMode as GraphEditorInputMode;

    inputMode.setSelected(node, true);

    setTimeout(() => {
      const textEditorInputMode =
        inputMode.textEditorInputMode as JigsawTextEditorInputMode;
      inputMode.cancel();
      inputMode.editLabel(label);
      setTimeout(() => textEditorInputMode.focusEditor());
    });
  }
}
