import svgData from './SvgElementData';
import { IElementDataProvider } from './IElementDataProvider';
import IDocumentPaletteItem from '../IDocumentPaletteItem';
import ClipArtPaletteBehaviour from '../ClipArtPaletteBehaviour';

export default class SvgElementDataProvider implements IElementDataProvider {
  getElements(): IDocumentPaletteItem[] {
    return svgData.map<IDocumentPaletteItem>((e) => {
      return {
        category: e.Category,
        subcategory: e.Subcategory,
        img: e.ImagePath,
        data: e,
        name: e.Name,
        text: e.Name,
        id: e.Id,
        behaviour: ClipArtPaletteBehaviour.INSTANCE,
        canDrag: true
      };
    });
  }
}
