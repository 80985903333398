import {
  IInputModeContext,
  IMutableRectangle,
  InputModeBase,
  MutableRectangle,
  ICanvasObject,
  CanvasComponent,
  MouseEventArgs,
  Cursor,
  Point,
  MutablePoint,
  HandlePositions,
  ScrollBarVisibility,
  GraphEditorInputMode,
  KeyEventArgs,
  Key,
  Rect,
  IRectangle,
  delegate,
  InputModeEventArgs,
  MoveInputMode,
  GraphComponent,
  IGraph,
  INode,
  ItemEventArgs
} from 'yfiles';
import { PrintLayoutRectangleHandle } from './PrintLayoutRectangleHandle';
import { calculateScale, toWorldRect } from './utils';

export enum State {
  Idle,
  MoveArmed,
  Moving,
  ResizeArmed,
  Resizing
}

export interface ExportRectangleChangedEvent {
  exportRect: IRectangle;
}
export type ExportRectangleChanged = (
  sender: PrintPreviewInputMode,
  args: ExportRectangleChangedEvent
) => void;

export default class PrintPreviewInputMode extends InputModeBase {
  public get frameVisible(): boolean {
    return this._frameVisible;
  }

  private _exportRectangleChangedListener: ExportRectangleChanged | null = null;
  private _resizeInitialLocation: Point = null;
  private _frameVisible: boolean = false;
  private _moveDelta: Point;
  private _currentHandle: PrintLayoutRectangleHandle = null;
  private _handleCanvasObjects: ICanvasObject[];
  private _exportRectCanvasObject: ICanvasObject;
  private _canvasComponentState: {
    verticalScrollBarPolicy: ScrollBarVisibility;
    horizontalScrollBarPolicy: ScrollBarVisibility;
    moveViewPortInputModeEnabled: boolean;
    autoDrag: boolean;
  };

  public get exportRect(): Rect {
    return this._exportRect?.toRect();
  }
  /**
   * The x,y, w, h are all defined in percentage a value between 0 - 1
   * Width & Height are
   */
  private _exportRect: IMutableRectangle;

  public get worldExportRect(): Rect {
    return this._worldExportRect;
  }

  private get _worldExportRect(): Rect {
    return toWorldRect(this._exportRect, this.inputModeContext.canvasComponent);
  }

  private _state: State = State.Idle;
  public get state(): State {
    return this._state;
  }
  private set state(value: State) {
    if (this._state != value) {
      this._state = value;
      this.controller.preferredCursor = this.getCursor(value);
      console.debug(`⭐ ${State[value]}`);
    }
  }

  private mouseDragListener = this.onMouseDrag.bind(this);
  private mouseMoveListener = this.onMouseMove.bind(this);
  private mouseDownListener = this.onMouseDown.bind(this);
  private mouseUpListener = this.onMouseUp.bind(this);
  private keyDownListener = this.onKeyDown.bind(this);
  private contentRectChangedListener = this.onContentRectChanged.bind(this);
  private viewPortChangedListener = this.onViewPortChanged.bind(this);
  private moveInputModeDraggedListener = this.onMoveInputModeDragged.bind(this);
  private nodeCreatedListener = this.onNodeCreated.bind(this);
  private nodeRemovedListener = this.onNodeRemoved.bind(this);
  private edgeCreatedListener = this.onEdgeCreated.bind(this);
  private edgeRemovedListener = this.onEdgeRemoved.bind(this);

  /**
   *
   */
  constructor() {
    super();
    this.priority = 1;
  }
  private getCursor(state: State): Cursor {
    switch (state) {
      case State.MoveArmed:
      case State.Moving:
        return Cursor.MOVE;
      case State.ResizeArmed:
      case State.Resizing:
        return this._currentHandle?.cursor ?? Cursor.E_RESIZE;
    }
    return Cursor.DEFAULT;
  }

  uninstall(context: IInputModeContext): void {
    this.deactivate();
    super.uninstall(context);
    if (this._exportRectCanvasObject) {
      this._exportRectCanvasObject.remove();
      this._exportRectCanvasObject = null;
    }
  }

  private addEventListeners(): void {
    const canvasComponent = this.inputModeContext
      .canvasComponent as GraphComponent;
    const graphEditorInputMode =
      canvasComponent.inputMode as GraphEditorInputMode;

    graphEditorInputMode.moveUnselectedInputMode.addDraggedListener(
      this.moveInputModeDraggedListener
    );

    graphEditorInputMode.moveInputMode.addDraggedListener(
      this.moveInputModeDraggedListener
    );

    canvasComponent.addKeyDownListener(this.keyDownListener);
    canvasComponent.addMouseDragListener(this.mouseDragListener);
    canvasComponent.addMouseMoveListener(this.mouseMoveListener);
    canvasComponent.addMouseDownListener(this.mouseDownListener);
    canvasComponent.addMouseUpListener(this.mouseUpListener);
    canvasComponent.addContentRectChangedListener(
      this.contentRectChangedListener
    );
    canvasComponent.addViewportChangedListener(this.viewPortChangedListener);

    canvasComponent.graph.addNodeCreatedListener(this.nodeCreatedListener);
    canvasComponent.graph.addNodeRemovedListener(this.nodeRemovedListener);
    canvasComponent.graph.addEdgeCreatedListener(this.edgeCreatedListener);
    canvasComponent.graph.addEdgeRemovedListener(this.edgeRemovedListener);
  }

  private removeEventListeners(): void {
    const canvasComponent = this.inputModeContext
      .canvasComponent as GraphComponent;
    const graphEditorInputMode =
      canvasComponent.inputMode as GraphEditorInputMode;
    graphEditorInputMode.moveInputMode.removeDraggedListener(
      this.moveInputModeDraggedListener
    );
    graphEditorInputMode.moveUnselectedInputMode.removeDraggedListener(
      this.moveInputModeDraggedListener
    );
    canvasComponent.removeKeyDownListener(this.keyDownListener);
    canvasComponent.removeMouseDragListener(this.mouseDragListener);
    canvasComponent.removeMouseMoveListener(this.mouseMoveListener);
    canvasComponent.removeMouseDownListener(this.mouseDownListener);
    canvasComponent.removeMouseUpListener(this.mouseUpListener);
    canvasComponent.removeContentRectChangedListener(
      this.contentRectChangedListener
    );
    canvasComponent.removeViewportChangedListener(this.viewPortChangedListener);

    canvasComponent.graph.removeNodeCreatedListener(this.nodeCreatedListener);
    canvasComponent.graph.removeNodeRemovedListener(this.nodeRemovedListener);
    canvasComponent.graph.removeEdgeCreatedListener(this.edgeCreatedListener);
    canvasComponent.graph.removeEdgeRemovedListener(this.edgeRemovedListener);
  }

  private onMouseDrag(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (this.state == State.Moving) {
      this.handleMove(sender, evt);
    } else if (this.state == State.Resizing) {
      this.handleResize(sender, evt);
    }
  }

  private onMouseMove(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (!this._frameVisible) {
      return;
    }
    const viewCoordindates = sender.toViewCoordinates(evt.location);

    const inputModeContext = IInputModeContext.createInputModeContext(
      this,
      this.inputModeContext
    );

    const hit = sender.hitElementsAt(inputModeContext, evt.location);

    if (hit.size == 0 && this._worldExportRect.contains(viewCoordindates)) {
      this.state = State.MoveArmed;
    } else if (
      (this._currentHandle = this.getHitHandle(inputModeContext, evt.location))
    ) {
      this.state = State.ResizeArmed;
      return;
    } else {
      this.state = State.Idle;
      this._resizeInitialLocation = null;
      this.releaseMutex();
    }
  }

  private onMouseDown(sender: CanvasComponent, evt: MouseEventArgs): void {
    const inputModeContext = IInputModeContext.createInputModeContext(
      this,
      this.inputModeContext
    );

    if (this.state == State.MoveArmed) {
      const hit = sender.hitElementsAt(
        inputModeContext,
        evt.location,
        sender.contentGroup
      );
      if (hit.size > 0) {
        return;
      }
      const viewCoordindates = sender.toViewCoordinates(evt.location);
      this.controller.requestMutex();

      this.state = State.Moving;
      this._moveDelta = viewCoordindates.subtract(
        this._worldExportRect.topLeft
      );

      this.fitContentRectToExportRect(inputModeContext);
      return;
    } else if (this.state == State.ResizeArmed) {
      const hit = sender.hitElementsAt(
        inputModeContext,
        evt.location,
        sender.contentGroup
      );
      if (hit.size > 0) {
        return;
      }
      this.requestMutex();
      this._currentHandle.initializeDrag(inputModeContext);
      this._resizeInitialLocation = evt.location;
      this.state = State.Resizing;
      return;
    }
  }

  private onMouseUp(sender: CanvasComponent, evt: MouseEventArgs): void {
    const inputModeContext = IInputModeContext.createInputModeContext(
      this,
      this.inputModeContext
    );
    if (this.state == State.Moving) {
      this.controller.releaseMutex();
      this.state = State.MoveArmed;
    } else if (this.state == State.Resizing) {
      this.endResize(inputModeContext, evt.location);
      return;
    }
  }

  private onKeyDown(sender: CanvasComponent, evt: KeyEventArgs): void {
    if (evt.key == Key.ESCAPE && this.state == State.Resizing) {
      const inputModeContext = IInputModeContext.createInputModeContext(
        this,
        this.inputModeContext
      );
      this.state = State.Idle;
      this.cancelResize(
        inputModeContext,
        inputModeContext.canvasComponent.lastEventLocation
      );
      this.fitContentRectToExportRect(inputModeContext);
      inputModeContext.canvasComponent.invalidate();
      this.controller.releaseMutex();

      return;
    }
  }

  private onContentRectChanged(canvasComponent: CanvasComponent): void {
    let width = canvasComponent.contentRect.width * canvasComponent.zoom;
    let height = canvasComponent.contentRect.height * canvasComponent.zoom;
    const viewPortSize = canvasComponent.viewport
      .toSize()
      .multiply(canvasComponent.zoom);
    if (this._worldExportRect.x + width > viewPortSize.width) {
      width = viewPortSize.width - this._worldExportRect.x;
    }

    if (this._worldExportRect.y + height > viewPortSize.height) {
      height = viewPortSize.height - this._worldExportRect.y;
    }

    this.fitExportRectToContentRect(this.inputModeContext);
  }

  private onNodeCreated(sender: IGraph, evt: ItemEventArgs<INode>): void {
    setTimeout(() => {
      this.fitExportRectToContentRect(this.inputModeContext);
    });
  }

  private onNodeRemoved(sender: IGraph, evt: ItemEventArgs<INode>): void {
    setTimeout(() => {
      this.fitExportRectToContentRect(this.inputModeContext);
    });
  }

  private onEdgeCreated(sender: IGraph, evt: ItemEventArgs<INode>): void {
    setTimeout(() => {
      this.fitExportRectToContentRect(this.inputModeContext);
    });
  }

  private onEdgeRemoved(sender: IGraph, evt: ItemEventArgs<INode>): void {
    setTimeout(() => {
      this.fitExportRectToContentRect(this.inputModeContext);
    });
  }

  private onViewPortChanged(): void {
    this.fitContentRectToExportRect(this.inputModeContext);
  }

  private onMoveInputModeDragged(
    sender: MoveInputMode,
    args: InputModeEventArgs
  ): void {
    this.fitExportRectToContentRect(this.inputModeContext);
  }

  private handleMove(sender: CanvasComponent, evt: MouseEventArgs): void {
    const inputModeContext = IInputModeContext.createInputModeContext(
      this,
      this.inputModeContext
    );
    const viewCoordindates = sender.toViewCoordinates(evt.location);
    const viewPortSize = sender.viewport.toSize().multiply(sender.zoom);

    const newLocation = new MutablePoint(
      viewCoordindates.subtract(this._moveDelta)
    );

    newLocation.x = Math.max(0, newLocation.x) / viewPortSize.width;
    newLocation.y = Math.max(0, newLocation.y) / viewPortSize.height;

    if (newLocation.x + this._exportRect.width > 1) {
      newLocation.x = 1 - this._exportRect.width;
    }

    if (newLocation.y + this._exportRect.height > 1) {
      newLocation.y = 1 - this._exportRect.height;
    }
    this._exportRect.relocate(newLocation);
    this.fitContentRectToExportRect(inputModeContext);
    this.inputModeContext.canvasComponent.invalidate();
  }

  private handleResize(sender: CanvasComponent, evt: MouseEventArgs): void {
    const inputModeContext = IInputModeContext.createInputModeContext(
      this,
      this.inputModeContext
    );
    sender.updateContentRect();
    this._currentHandle.handleMove(
      inputModeContext,
      this._resizeInitialLocation,
      evt.location
    );
    this.fitContentRectToExportRect(inputModeContext);
    inputModeContext.canvasComponent.invalidate();
    this.onExportRectChanged(this._exportRect);
  }

  private endResize(
    inputModeContext: IInputModeContext,
    location: Point
  ): void {
    this._currentHandle.dragFinished(
      inputModeContext,
      this._resizeInitialLocation,
      location
    );

    this.state = State.ResizeArmed;
    this.fitContentRectToExportRect(inputModeContext);
    inputModeContext.canvasComponent.invalidate();
    this.onExportRectChanged(this._exportRect);
  }

  private cancelResize(
    inputModeContext: IInputModeContext,
    location: Point
  ): void {
    this._currentHandle.cancelDrag(inputModeContext, location);
  }

  private getHitHandle(
    context: IInputModeContext,
    location: Point
  ): PrintLayoutRectangleHandle {
    for (const canvasObject of this._handleCanvasObjects) {
      const handle = canvasObject.userObject as PrintLayoutRectangleHandle;
      if (handle.isHit(context, location)) {
        return handle;
      }
    }
    return null;
  }

  private _isUpdatingSize = false;

  private fitExportRectToContentRect(
    inputModeContext: IInputModeContext
  ): void {
    if (this._isUpdatingSize) {
      return;
    }
    this._isUpdatingSize = true;
    try {
      const canvasComponent = inputModeContext.canvasComponent;
      inputModeContext.canvasComponent.updateContentRect();

      const contentRect = canvasComponent.contentRect;
      const viewPortSize = canvasComponent.viewport
        .toSize()
        .multiply(canvasComponent.zoom);
      const contentRectView = canvasComponent.toViewCoordinates(contentRect);
      this._exportRect.x = contentRectView.x / viewPortSize.width;
      this._exportRect.y = contentRectView.y / viewPortSize.height;
      const contentRectViewSize = contentRect
        .toSize()
        .multiply(canvasComponent.zoom);

      this._exportRect.width = contentRectViewSize.width / viewPortSize.width;
      this._exportRect.height =
        contentRectViewSize.height / viewPortSize.height;

      this.onExportRectChanged(this._exportRect);
    } finally {
      this._isUpdatingSize = false;
    }
  }

  private fitContentRectToExportRect(
    inputModeContext: IInputModeContext
  ): void {
    if (this._isUpdatingSize) {
      return;
    }
    this._isUpdatingSize = true;
    const canvasComponent = inputModeContext.canvasComponent;
    canvasComponent.updateContentRect();
    const contentRect = canvasComponent.contentRect;

    const scale = calculateScale(
      contentRect.toSize(),
      this._worldExportRect.toSize()
    );
    canvasComponent.zoom = scale;

    const worldRect = canvasComponent.toWorldCoordinates(
      this._worldExportRect.toPoint()
    );

    const delta = contentRect.topLeft.subtract(worldRect);

    canvasComponent.viewPoint = canvasComponent.viewPoint.add(delta);

    this._isUpdatingSize = false;
  }

  private saveCanvasComponentState(): void {
    const canvasComponent = this.inputModeContext.canvasComponent;
    const geim = canvasComponent.inputMode as GraphEditorInputMode;
    this._canvasComponentState = {
      horizontalScrollBarPolicy: canvasComponent.horizontalScrollBarPolicy,
      verticalScrollBarPolicy: canvasComponent.verticalScrollBarPolicy,
      moveViewPortInputModeEnabled: geim.moveViewportInputMode.enabled,
      autoDrag: canvasComponent.autoDrag
    };
  }

  private restoreCanvasComponentState(): void {
    if (!this._canvasComponentState) {
      return;
    }
    const canvasComponent = this.inputModeContext.canvasComponent;
    const geim = canvasComponent.inputMode as GraphEditorInputMode;
    canvasComponent.horizontalScrollBarPolicy =
      this._canvasComponentState.horizontalScrollBarPolicy;
    canvasComponent.verticalScrollBarPolicy =
      this._canvasComponentState.verticalScrollBarPolicy;
    geim.moveViewportInputMode.enabled =
      this._canvasComponentState.moveViewPortInputModeEnabled;
    canvasComponent.autoDrag = this._canvasComponentState.autoDrag;
    this._canvasComponentState = null;
  }

  public activate(rect?: IRectangle): void {
    //TODO: Uncomment to restore functionality
    // if (this._frameVisible) {
    //   return;
    // }
    const canvasComponent = this.inputModeContext
      .canvasComponent as GraphComponent;
    // if (!rect) {
    //   rect = new Rect(0, 0, 0.5, 0.5);
    //   this._exportRect = new MutableRectangle(rect);
    //   this.fitExportRectToContentRect(this.inputModeContext);
    // } else {
    //   this._exportRect = new MutableRectangle(rect);
    //   this.fitContentRectToExportRect(this.inputModeContext);
    // }
    // this.onExportRectChanged(this._exportRect);
    // this._frameVisible = true;
    const geim = canvasComponent.inputMode as GraphEditorInputMode;
    // /* Configure graph component, saving state first */
    this.saveCanvasComponentState();
    geim.moveViewportInputMode.enabled = false;
    canvasComponent.verticalScrollBarPolicy = ScrollBarVisibility.NEVER;
    canvasComponent.horizontalScrollBarPolicy = ScrollBarVisibility.NEVER;
    canvasComponent.autoDrag = false;
    canvasComponent.selection.clear();
    // canvasComponent.updateContentRect();
    // this._exportRectCanvasObject =
    //   this.inputModeContext.canvasComponent.inputModeGroup.addChild(
    //     new PrintLayoutRectangleVisual(this._exportRect, this)
    //   );
    // this._handleCanvasObjects = [
    //   this.inputModeContext.canvasComponent.inputModeGroup.addChild(
    //     new PrintLayoutRectangleHandle(
    //       this._exportRect,
    //       HandlePositions.NORTH_EAST,
    //       canvasComponent
    //     )
    //   ),
    //   this.inputModeContext.canvasComponent.inputModeGroup.addChild(
    //     new PrintLayoutRectangleHandle(
    //       this._exportRect,
    //       HandlePositions.SOUTH_WEST,
    //       canvasComponent
    //     )
    //   ),
    //   this.inputModeContext.canvasComponent.inputModeGroup.addChild(
    //     new PrintLayoutRectangleHandle(
    //       this._exportRect,
    //       HandlePositions.SOUTH_EAST,
    //       canvasComponent
    //     )
    //   ),
    //   this.inputModeContext.canvasComponent.inputModeGroup.addChild(
    //     new PrintLayoutRectangleHandle(
    //       this._exportRect,
    //       HandlePositions.NORTH_WEST,
    //       canvasComponent
    //     )
    //   ),
    // ];
    // this.addEventListeners();
    // this.inputModeContext.invalidateDisplays();
  }

  public deactivate(): void {
    // if (!this._frameVisible) {
    //   return;
    // }
    // this._frameVisible = false;

    this.restoreCanvasComponentState();
    // this._exportRectCanvasObject.remove();
    // this._exportRectCanvasObject = null;
    // this._handleCanvasObjects.forEach((canvasObject) => {
    //   canvasObject.remove();
    // });
    // this._handleCanvasObjects = [];
    // this.removeEventListeners();
    // if (this.hasMutex()) {
    //   this.state = State.Idle;
    //   this.releaseMutex();
    // }
  }

  public addExportRectangleChangedListener(
    listener: ExportRectangleChanged
  ): void {
    this._exportRectangleChangedListener = delegate.combine(
      this._exportRectangleChangedListener,
      listener
    );
  }

  public removeExportRectangleChangedListener(
    listener: ExportRectangleChanged
  ): void {
    this._exportRectangleChangedListener = delegate.remove(
      this._exportRectangleChangedListener,
      listener
    );
  }

  protected onExportRectChanged(exportRect: IRectangle): void {
    if (this._exportRectangleChangedListener) {
      this._exportRectangleChangedListener(this, { exportRect: exportRect });
    }
  }
}

export function createRect(
  fill: string,
  stroke: string,
  strokeWidth?: number | string,
  dashArray?: string
): SVGElement {
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('fill', fill);
  rect.setAttribute('stroke', stroke);
  if (strokeWidth) {
    rect.setAttribute('stroke-width', strokeWidth.toString());
  }

  if (dashArray) {
    rect.setAttribute('stroke-dasharray', dashArray);
  }
  return rect;
}
