import ISearchParameter from './ISearchParameter';

export default class EntityNameParameter implements ISearchParameter {
  public entityNameType: string;
  public entityName: string;

  constructor(entityNameType: string, entityName: string) {
    this.entityNameType = entityNameType;
    this.entityName = entityName;
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof EntityNameParameter &&
      other.entityName == this.entityName
    );
  }
}
