<template>
  <div
    v-if="$slots.default && !$slots.content"
    class="text-normal cursor-default select-none px-24 py-12 text-base font-normal leading-none text-main-dark-60"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownGroupLabel'
})
export default class AppDropdownGroupLabel extends Vue {}
</script>
