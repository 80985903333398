import axios, {
    AxiosResponse
} from 'axios';
import {
    ZadarResponse,
    WordExportDto
} from './models'
export default class WordExportApiService {
    public static exportDocumentToWord(body: WordExportDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/WordExport/ExportDocumentToWord`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}