<template>
  <div ref="appBreadcrumbWrap" class="w-full">
    <ol ref="appBreadcrumb" class="inline-flex w-full items-center">
      <li v-if="isIncompact" class="flex items-center">
        <AppDropdown placement="bottom-start" :offset="0">
          <template #activator="{ isVisible }">
            <div
              class="h-32 w-32 cursor-pointer rounded-4 p-4 transition hover:bg-main-dark-05"
              :class="{ 'bg-main-dark-05': isVisible }"
            >
              <AppIcon name="more-horisontal" />
            </div>
          </template>

          <AppDropdownItem
            v-for="crumb in dropdownItemsCrumbs"
            :key="crumb.id"
            @click="selected(crumb)"
          >
            {{ crumb.label }}
          </AppDropdownItem>
        </AppDropdown>

        <AppIcon
          name="chevron-right"
          size="16"
          class="pointer-events-none text-main-dark-40"
        />
      </li>

      <li
        v-for="(crumb, ci) in visibleItemsCrumbs"
        :key="crumb.id"
        class="flex items-center whitespace-nowrap"
      >
        <span
          v-if="!isLast(ci)"
          class="cursor-pointer rounded-4 leading-none text-main-dark-40 transition hover:bg-main-dark-05 hover:text-main-dark"
          :class="crumbSizeClass"
          @click="selected(crumb)"
        >
          {{ crumb.label }}
        </span>

        <AppDropdown
          v-else
          placement="bottom-start"
          :offset="0"
          :disabled="!lastItemOptions.length"
        >
          <template #activator="{ isVisible }">
            <span
              class="group inline-flex cursor-pointer rounded-4 leading-none transition hover:bg-main-dark-05"
              :class="[{ 'bg-main-dark-05': isVisible }, crumbSizeClass]"
            >
              <span>{{ crumb.label }}</span>

              <AppIcon
                v-if="isLast(ci) && lastItemOptions.length"
                name="arrow-drop-down"
                size="20"
                class="ml-8 opacity-0 transition group-hover:opacity-100"
                :class="{ 'opacity-100': isVisible }"
              />
            </span>
          </template>

          <template v-for="(option, index) in visibleLastCrumbOptions">
            <AppDropdownItem
              v-if="!option.divider"
              :key="index"
              @click="lastItemCommandHandler(crumb, option.command)"
            >
              {{ option.label }}
            </AppDropdownItem>
            <AppDropdownDivider v-else :key="index" />
          </template>
        </AppDropdown>

        <AppIcon
          v-if="!isLast(ci)"
          name="chevron-right"
          :size="crumbDividerSize"
          class="pointer-events-none ml-2 mr-1 text-main-dark-40"
        />
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { debounce } from '@/core/common/DebounceDecorator';
import { isPrimitive } from '@/core/utils/common.utils';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

export type BreadcrumbItem = {
  id: string | number;
  label: string;
};

export type BreadcrumDropdownOption = {
  label: string;
  command: string;
};

export type CrumbSize = 'default' | 'sm';

export type LastItemOptions = Array<BreadcrumDropdownOption | 'divider'>;

// TODO: add translation
const defaultLastItemOptions: () => LastItemOptions = () => [
  { label: 'Share', command: 'share' },
  { label: 'Copy link', command: 'copy' },
  'divider',
  { label: 'Download files as PDF', command: 'download_pdf' },
  { label: 'Download files as Powerpoint', command: 'download_ppt' },
  'divider',
  { label: 'Rename', command: 'rename' },
  { label: 'Add to favorites', command: 'add_to_favourites' }
];

const crumbSizes = {
  sm: 'text-14 p-5 py-9',
  default: 'text-20 py-10 px-12'
};
const crumbDividerSizes = {
  sm: '12',
  default: '16'
};

@Component({
  name: 'AppBreadcrumb'
})
class AppBreadcrumb extends Vue {
  @Prop({ required: true, type: Array })
  crumbs: BreadcrumbItem[];

  @Prop({ default: defaultLastItemOptions, type: Array })
  lastItemOptions: LastItemOptions;

  @Prop({ default: 'default', type: String })
  size: CrumbSize;

  $refs!: {
    appBreadcrumb: HTMLElement;
    appBreadcrumbWrap: HTMLDivElement;
  };

  visibleCount: number = Infinity;
  resizeObserver = null as ResizeObserver;

  get isIncompact() {
    return this.crumbs.length > this.visibleCount;
  }

  get dropdownItemsCrumbs() {
    if (this.isIncompact) {
      return this.crumbs.slice(0, -this.visibleCount);
    }

    return [];
  }

  get visibleItemsCrumbs() {
    return this.crumbs.slice(-this.visibleCount);
  }

  get visibleLastCrumbOptions() {
    if (this.lastItemOptions.length) {
      return this.lastItemOptions.map((option) => {
        if (isPrimitive(option)) {
          return { divider: true };
        }

        return {
          divider: false,
          ...(option as BreadcrumDropdownOption)
        };
      });
    }

    return [];
  }

  get crumbSizeClass() {
    return crumbSizes[this.size];
  }
  get crumbDividerSize() {
    return crumbDividerSizes[this.size];
  }

  @Watch('crumbs')
  onCrumbsChangeHandler() {
    this.handleCrumbsWidth();
  }

  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.handleCrumbsWidth();
    });

    this.resizeObserver.observe(this.$refs.appBreadcrumbWrap);
  }

  beforeDestroy() {
    this.resizeObserver.disconnect();
  }

  async handleCrumbsWidth() {
    this.visibleCount = Infinity;
    await this.$nextTick();

    const breadcrumbsWidth = this.$refs.appBreadcrumbWrap.clientWidth - 16; // 14 is just margin

    const breadcrumbItemsArray = Array.from(this.$refs.appBreadcrumb.children)
      .slice()
      .reverse();

    let breadcrumbVisibleItemsWidth = 0;
    for (let i = 0; i < breadcrumbItemsArray.length; i++) {
      breadcrumbVisibleItemsWidth += breadcrumbItemsArray[i].scrollWidth;

      if (breadcrumbVisibleItemsWidth > breadcrumbsWidth && i > 0) {
        this.visibleCount = i;
        break;
      }
    }
  }

  isLast(index: number) {
    return index === this.visibleItemsCrumbs.length - 1;
  }

  selected(crumb: BreadcrumbItem) {
    this.$emit('selected', crumb);
  }

  lastItemCommandHandler(crumb: BreadcrumbItem, command: string) {
    this.$emit('custom:action', { item: crumb, command });
  }
}

export default AppBreadcrumb;
</script>
