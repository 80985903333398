import { InsetsDto } from '@/api/models';
import { Insets } from 'yfiles';

/* eslint-disable no-dupe-class-members */
export default class JInsets {
  public left: number;
  public right: number;
  public top: number;
  public bottom: number;

  constructor(inset: number);
  constructor(left: number, top: number, right: number, bottom: number);

  constructor(...args: number[]) {
    if (args.length === 1) {
      this.left = args[0];
      this.top = args[0];
      this.right = args[0];
      this.bottom = args[0];
      return;
    } else {
      this.left = args[0];
      this.top = args[1];
      this.right = args[2];
      this.bottom = args[3];
      return;
    }
  }

  public equals(other: JInsets): boolean {
    return (
      this.left == other.left &&
      this.top == other.top &&
      this.right == other.right &&
      this.bottom == other.bottom
    );
  }

  public getEnlarged(insets: JInsets): JInsets {
    if (!insets) {
      return this.clone();
    }
    const enlarged = this.clone();
    enlarged.left += insets.left;
    enlarged.top += insets.top;
    enlarged.right += insets.right;
    enlarged.bottom += insets.bottom;
    return enlarged;
  }

  public multiply(factor: number): JInsets {
    return new JInsets(
      this.left * factor,
      this.top * factor,
      this.right * factor,
      this.bottom * factor
    );
  }

  public clone(): JInsets {
    return new JInsets(this.left, this.top, this.right, this.bottom);
  }

  public toYFiles(): Insets {
    return new Insets(this.left, this.top, this.right, this.bottom);
  }

  public static fromYFiles(insets: Insets): JInsets {
    return new JInsets(insets.left, insets.top, insets.right, insets.bottom);
  }

  public static fromDto(dto: InsetsDto): JInsets {
    return new JInsets(dto.left, dto.top, dto.right, dto.bottom);
  }

  private static parseFloat(stringVal: string): number {
    let intVal = parseFloat(stringVal);
    if (Number.isNaN(intVal)) {
      return 0;
    }
    return intVal;
  }

  public static fromStringValues(...args: string[]): JInsets {
    if (args.length === 1) {
      return new JInsets(JInsets.parseFloat(args[0]));
    }

    const intArgs: number[] = [];
    for (const strVal in args) {
      intArgs.push(JInsets.parseFloat(strVal));
    }
    return new JInsets(intArgs[0], intArgs[1], intArgs[2], intArgs[3]);
  }

  public static get EMPTY(): JInsets {
    return new JInsets(0, 0, 0, 0);
  }

  toString(): string {
    return `JInsets(left: ${this.left}, top: ${this.top}, right: ${this.right}, bottom: ${this.bottom})`;
  }
}
