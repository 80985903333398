import { IEdge, INode } from 'yfiles';
import DataPropertyParameter from '../parameters/DataPropertyParameter';
import { ITypedMatcher } from './IMatcher';

export default class DataPropertyValueMatcher
  implements ITypedMatcher<INode | IEdge, DataPropertyParameter>
{
  public canMatch(candidate: any, parameter: DataPropertyParameter): boolean {
    return (
      (candidate instanceof INode || candidate instanceof IEdge) &&
      candidate.tag?.dataProperties.some(
        (dp) =>
          dp.dataPropertyDefinitionId == parameter.dataPropertyDefinitionId
      )
    );
  }

  public isMatch(
    candidate: INode | IEdge,
    parameter: DataPropertyParameter
  ): boolean {
    const dataProperties = candidate.tag?.dataProperties;
    if (!dataProperties) {
      return false;
    }

    return dataProperties.some((dp) => {
      return (
        dp.dataPropertyDefinitionId == parameter.dataPropertyDefinitionId &&
        ((Array.isArray(dp.value) &&
          dp.value.some((v) => v == parameter.dataPropertyValue)) ||
          dp.value == parameter.dataPropertyValue)
      );
    });
  }
}
