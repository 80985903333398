import {
  EdgeStyleDecorationInstaller,
  IEdge,
  IEdgeStyle,
  IModelItem,
  Stroke,
  PolylineEdgeStyle,
  GraphComponent,
  ArcEdgeStyle,
  NodeStyleDecorationInstaller,
  ShapeNodeStyle,
  HighlightIndicatorManager
} from 'yfiles';
import HighlightServiceBase from '../graph/HighlightServiceBase';

export default class ExportHighlightService extends HighlightServiceBase {
  public static readonly $class: string = 'HighlightServiceBase';
  public static readonly highlightStrokeThickness = 10;
  public static readonly highlightColor = `rgb(0, 181, 171)`;
  constructor(private graphComponent: GraphComponent) {
    super();
    this.setNodeHighlightStyle();
  }

  public get highlightManager(): HighlightIndicatorManager<IModelItem> {
    return this.graphComponent.highlightIndicatorManager;
  }

  setNodeHighlightStyle(): void {
    const nodeHighlightStyle = new NodeStyleDecorationInstaller({
      nodeStyle: new ShapeNodeStyle({
        shape: 'rectangle',
        stroke: `${ExportHighlightService.highlightStrokeThickness}px ${ExportHighlightService.highlightColor}`,
        fill: 'transparent'
      }),
      // the margin from the actual node to its highlight visualization
      margins: 18
    });

    this.graphComponent.graph.decorator.nodeDecorator.highlightDecorator.setImplementation(
      nodeHighlightStyle
    );
  }

  setEdgeHighlightStyle(edge: IEdge): void {
    let highlightEdgeStyle: IEdgeStyle = edge.style.clone();
    if (highlightEdgeStyle instanceof ArcEdgeStyle) {
      highlightEdgeStyle['stroke'] = new Stroke(
        ExportHighlightService.highlightColor,
        ExportHighlightService.highlightStrokeThickness
      );
    } else {
      highlightEdgeStyle = new PolylineEdgeStyle({
        stroke: new Stroke(
          ExportHighlightService.highlightColor,
          ExportHighlightService.highlightStrokeThickness
        ),
        smoothingLength: highlightEdgeStyle['smoothingLength'],
        sourceArrow: highlightEdgeStyle['sourceArrow'],
        targetArrow: highlightEdgeStyle['targetArrow']
      });
    }

    const edgeSearchHighlightStyle = new EdgeStyleDecorationInstaller({
      edgeStyle: highlightEdgeStyle
    });

    this.graphComponent.graph.decorator.edgeDecorator.highlightDecorator.setImplementation(
      edgeSearchHighlightStyle
    );
  }

  public override highlightElement(item: IModelItem): void {
    if (IEdge.isInstance(item)) {
      this.setEdgeHighlightStyle(item);
    }

    super.highlightElement(item);
  }

  public highlightElements(): void {
    this.graphComponent.graph.nodes.forEach((node) => {
      if (node.tag.highlight) {
        this.highlightElement(node);
      }
    });

    this.graphComponent.graph.edges.forEach((edge) => {
      if (edge.tag.highlight) {
        this.highlightElement(edge);
      }
    });
  }
}
