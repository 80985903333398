import Vue from 'vue';
import Ajv, { ErrorObject } from 'ajv';
import appInfo from '@/app-info.json';
import {
  TutorialStep,
  TutorialCard,
  TutorialData,
  JSONExport,
  OnboardingAdminLSKeys,
  OnboardingAdminError
} from '@/core/services/tutorial/ITutorial';
import { CurrentUserProfileEditDto, TutorialType } from '@/api/models';
import { getDateTimeString } from '@/core/utils/common.utils';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import tutorialStepSchema from '@/core/services/tutorial-data/tutorialStepSchema';
import tutorialCardSchema from '@/core/services/tutorial-data/tutorialCardSchema';
import {
  SET_ERROR_DETAILS,
  TRAINING_NAMESPACE
} from '@/core/services/store/training.module';
import { createDefaultProgressSettingsMap } from '@/core/services/tutorial-data/TutorialDataUtils';

const ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}

export type ValidationResponse = {
  valid: boolean;
  errorDetails?: OnboardingAdminError;
};

export default class TutorialDataService {
  private static stepsValidator = ajv.compile(tutorialStepSchema);
  private static cardsValidator = ajv.compile(tutorialCardSchema);

  private static validateStep(step: Partial<TutorialStep>): ValidationResponse {
    let valid: boolean;
    const errorDetails: OnboardingAdminError = {
      steps: []
    };

    if (!step.stepActions.length) {
      valid = false;
      errorDetails.steps.push({
        stepIndex: parseInt(step.id),
        message: 'Each tutorial step must have at least one action'
      });
    } else {
      valid = TutorialDataService.stepsValidator(step);
      if (TutorialDataService.stepsValidator.errors?.length) {
        TutorialDataService.stepsValidator.errors.forEach(
          (err: ErrorObject) => {
            if (
              err.instancePath.endsWith('title') &&
              err.keyword === 'minLength'
            ) {
              errorDetails.steps.push({
                stepIndex: parseInt(step.id),
                message: 'Step title is required'
              });
            } else if (err.instancePath.includes('stepActions')) {
              const properties = err.instancePath.split('/');
              let message = '';
              properties.forEach((p) => {
                const index = parseInt(p);
                if (!Number.isNaN(index)) {
                  message += `(#${index + 1}) `;
                } else {
                  message += `${p} `;
                }
              });

              errorDetails.steps.push({
                stepIndex: parseInt(step.id),
                message: `${message} - ${err.message}`
              });
            } else {
              const properties = err.instancePath.split('/').join(' ').trim();
              errorDetails.steps.push({
                stepIndex: parseInt(step.id),
                message: `${properties} - ${err.message}`
              });
            }
          }
        );
      }
    }

    return {
      valid,
      errorDetails
    };
  }

  private static validateCard(card: Partial<TutorialCard>): ValidationResponse {
    const valid = TutorialDataService.cardsValidator(card);
    const errorDetails: OnboardingAdminError = { cards: [] };
    const tutorialType = card.type;

    if (!valid && TutorialDataService.cardsValidator.errors?.length) {
      TutorialDataService.cardsValidator.errors.forEach((err: ErrorObject) => {
        const properties = err.instancePath.split('/').join(' ').trim();
        if (properties.includes('completionTime unit')) {
          errorDetails.cards.push({
            tutorialType,
            message:
              'Invalid completion time unit - must be either "minutes" or "hours"'
          });
        } else {
          errorDetails.cards.push({
            tutorialType,
            message: `${properties} - ${err.message}`
          });
        }
      });
    }

    return {
      valid,
      errorDetails
    };
  }

  public static stepValid(step: Partial<TutorialStep>): ValidationResponse {
    const response = TutorialDataService.validateStep(step);

    Vue.$globalStore.dispatch(
      `${TRAINING_NAMESPACE}/${SET_ERROR_DETAILS}`,
      response.errorDetails
    );

    return response;
  }

  public static jsonValid(data: string | JSONExport): ValidationResponse {
    const errorDetails: OnboardingAdminError = {
      message: '',
      cards: [],
      steps: []
    };
    let valid = true;

    try {
      let jsonExport: JSONExport;
      if (typeof data === 'string') {
        jsonExport = TutorialDataService.deserialize(data);
      } else {
        jsonExport = data;
      }

      const map = jsonExport.stepsMap ?? jsonExport.cardsMap;
      if (!map) {
        return {
          valid: true,
          errorDetails: null
        };
      }

      Object.keys(map).forEach((key) => {
        const tutorialType = parseInt(key);

        jsonExport.stepsMap[tutorialType].forEach((step: TutorialStep) => {
          const response = TutorialDataService.validateStep(step);
          if (!response.valid) {
            errorDetails.steps = [
              ...errorDetails.steps,
              ...response.errorDetails.steps
            ];
          }
        });

        const response = TutorialDataService.validateCard(
          jsonExport.cardsMap[tutorialType]
        );
        if (!response.valid) {
          errorDetails.cards = [
            ...errorDetails.cards,
            ...response.errorDetails.cards
          ];
        }
      });

      if (
        errorDetails.message ||
        errorDetails.steps.length ||
        errorDetails.cards.length
      ) {
        valid = false;
      }
    } catch (e) {
      console.debug('Could not parse: ', e);
      valid = false;
      errorDetails.message = 'Could not parse JSON';
    }

    Vue.$globalStore.dispatch(
      `${TRAINING_NAMESPACE}/${SET_ERROR_DETAILS}`,
      errorDetails
    );

    return {
      valid,
      errorDetails
    };
  }

  static serialize(data: TutorialData): string {
    const user: CurrentUserProfileEditDto =
      Vue.$globalStore.getters[`${GET_CURRENT_USER}`] ?? null;

    return JSON.stringify({
      ...data,
      metadata: {
        createdBy: {
          userId: user.userId,
          customerId: user.customerId
        },
        createdAt: getDateTimeString(),
        version: appInfo.version ?? 'dev'
      }
    } as JSONExport);
  }

  static deserialize(json: string): JSONExport {
    try {
      return JSON.parse(json);
    } catch {
      console.debug('Could not load tutorial steps from localStorage');
    }
    return null;
  }

  static toLocalStorage(data: TutorialData | string): void {
    let json: string;
    if (typeof data === 'string') {
      json = data;
    } else {
      json = TutorialDataService.serialize({
        stepsMap: data.stepsMap,
        progressSettingsMap: data.progressSettingsMap,
        cardsMap: data.cardsMap,
        settings: data.settings,
        metadata: data.metadata
      });
    }

    const result = TutorialDataService.jsonValid(json);
    if (!result.valid) {
      return;
    }
    localStorage.setItem(OnboardingAdminLSKeys.OnboardingData, json);
  }

  static fromLocalStorage(): TutorialData | undefined {
    try {
      const json = localStorage.getItem(OnboardingAdminLSKeys.OnboardingData);
      if (json) {
        const savedData = TutorialDataService.deserialize(json);
        if (savedData) {
          if (!savedData.progressSettingsMap) {
            savedData.progressSettingsMap = createDefaultProgressSettingsMap();
          }
          Object.keys(savedData.stepsMap).forEach((tutorialType) => {
            savedData.stepsMap[parseInt(tutorialType) as TutorialType].forEach(
              (step, index) => {
                step.id = `${index}`;
              }
            );
          });

          return savedData;
        }
      }
    } catch {
      console.debug('Could not parse training data from LS');
    }
  }
}
