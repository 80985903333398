import DiagramsApiService from '@/api/DiagramsApiService';
import DocumentPagesApiService from '@/api/DocumentPagesApiService';
import {
  DiagramDto,
  DiagramPosition,
  DocumentDto,
  DocumentPageContentType,
  DocumentSubPageDto,
  DocumentPageDto,
  DocumentPageLayoutType,
  DocumentPageType,
  QuickStartState,
  PageDesignDto,
  PageElementPosition
} from '@/api/models';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import LayoutItemUtils from '@/components/LayoutEditor/Items/LayoutItemUtils';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import { htmlToElement } from '@/core/utils/html.utils';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import isNil from 'lodash/isNil';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import ContentPagination from '../export/ContentPagination';
import Vue from 'vue';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID
} from '../store/datapropertydefinitions.module';
import {
  DOCUMENT_NAMESPACE,
  GET_SELECTED_PAGE,
  GET_SELECTED_SUBPAGE_INDEX
} from '../store/document.module';
import {
  GET_ALL_PAGE_DESIGNS,
  GET_STEPS_FONT_PRESETS,
  PAGE_DESIGN_NAMESPACE
} from '../store/page-design.module';
import { getBodyKey } from './DocumentConsts';
import DocumentCustomPageType from './DocumentCustomPageType';
import DocumentService from './DocumentService';
import NewPageOptions from './NewPageOptions';
import { PagePosition } from './PagePosition';
import PageListItem from '@/view/pages/document/page-list/PageListItem';
import PageLayoutHandler from '../export/PageLayoutHandler';
import { StepsFontPresets } from '@/view/pages/administration/steps-designer/StepsFontPresets';
import { setDocumentPagePrototype } from '@/core/common/DtoExtensions';
import DocumentPageTitleService from './DocumentPageTitleService';

class DocumentEditor {
  private get selectedPage(): DocumentPageDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ];
  }

  private get selectedSubPageIndex(): number {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`
    ];
  }

  private get pageDesigns(): PageDesignDto[] {
    return Vue.$globalStore.getters[
      `${PAGE_DESIGN_NAMESPACE}/${GET_ALL_PAGE_DESIGNS}`
    ];
  }

  private get stepsFontPresets(): StepsFontPresets[] {
    return Vue.$globalStore.getters[
      `${PAGE_DESIGN_NAMESPACE}/${GET_STEPS_FONT_PRESETS}`
    ];
  }

  public async addNewPage(options: NewPageOptions): Promise<DocumentPageDto> {
    const unlock = await DocumentService.saveMutex.lock();
    try {
      let newPage: DocumentPageDto = null;
      if (options.contentType == DocumentPageContentType.Layout) {
        newPage = await this.createLayoutPage(
          options.document,
          options.layoutType,
          options.layoutId,
          options.isPristine,
          options.pageName
        );

        newPage.showHeader = options.document.headerStyle.show;
        newPage.showFooter = options.document.footerStyle.show;
        newPage.showDate = options.showDate;
        newPage.showPageNumber = options.showPageNumber;
        newPage.bodyLayout = options.bodyLayout;

        if (options.layoutType !== DocumentPageLayoutType.ContentTable) {
          LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
            newPage,
            LayoutItemType.PageNumber,
            !newPage.showPageNumber
          );
        } else {
          newPage.headerLayout = LayoutUtils.parseAndReId(
            options.document.headerLayout
          );
          newPage.footerLayout = options.document.footerLayout;
          newPage.backgroundLayout = options.document.backgroundLayout;
          this.handleNewPageWidgets(newPage, options);
        }
      } else {
        let diagram: DiagramDto = null;

        if (options.pageType != DocumentPageType.Content) {
          diagram = {
            nodes: [],
            edges: [],
            templates: [],
            quickStartStateData: {
              state: QuickStartState.Initial
            },
            isTemplate: false,
            id: 0,
            flipbookState: options.document.flipbookState
          };
        }

        newPage = {
          documentId: options.document.id,
          diagramId: diagram?.id,
          order: null,
          pageType: options.pageType,
          showLogo: false,
          showLegend: false,
          contentType: options.contentType,
          layoutType: options.layoutType,
          contentColumns: options.contentColumns,
          content: options.content,
          diagramPosition: options.diagramPosition,
          showHeader: options.document.headerStyle.show,
          showFooter: options.document.footerStyle.show,
          headerLayout: LayoutUtils.parseAndReId(options.document.headerLayout),
          footerLayout: options.document.footerLayout,
          backgroundLayout: options.backgroundLayout,
          stepsFontPresets: DocumentService.getFontPresetsByLayoutType(
            this.stepsFontPresets,
            DocumentPageLayoutType.None
          ),
          isPristine: true,
          diagram: diagram,
          subPageRefs: [],
          showDate: options.showDate,
          showPageNumber: options.showPageNumber,
          showDivider: options.showDivider,
          showTitle: options.showTitle,
          titleHeight: options.titleHeight,
          maxTitleHeight: options.maxTitleHeight,
          titleLayout: LayoutUtils.parseAndReId(options.titleLayout),
          bodyLayout: LayoutUtils.parseAndReId(options.bodyLayout),
          name: options.pageName
        } as DocumentPageDto;
        setDocumentPagePrototype(newPage);

        this.handleNewPageWidgets(newPage, options);
      }
      if (!newPage) {
        return;
      }

      if (options.pageIndex == null || options.pageIndex == undefined) {
        options.pageIndex = await this.getInitialPageIndex(
          options.document,
          newPage,
          options.customPageType
        );
      }

      newPage.order = options.pageIndex;

      const newPageResponse =
        await DocumentPagesApiService.insertNewPage(newPage);
      options.document.lastModificationTime =
        newPageResponse.data.result.lastModificationTime;
      newPage.id = newPageResponse.data.result.pageId;
      if (newPage.diagram && !newPageResponse.data.result.diagramId) {
        throw 'expected diagram id, but none received in response';
      }
      if (newPage.diagram) {
        newPage.diagramId = newPage.diagram.id =
          newPageResponse.data.result.diagramId;
      }

      options.document.pages.push(newPage);
      await this.movePage(
        options.document,
        options.document.pages.indexOf(newPage),
        options.pageIndex
      );

      EventBus.$emit(EventBusActions.DOCUMENT_PAGE_CREATED, newPage);
      return newPage;
    } finally {
      unlock();
    }
  }

  private handleNewPageWidgets(
    newPage: DocumentPageDto,
    options: NewPageOptions
  ): void {
    if (
      newPage.showDate &&
      !LayoutUtils.getContentAreaByItemType(newPage, LayoutItemType.Date)
    ) {
      const headerLayoutItems = LayoutSerializer.deserializeFromJson(
        newPage.headerLayout
      );
      LayoutItemUtils.createDefaultDateItem(
        options.document,
        headerLayoutItems,
        {
          type: LayoutItemType.Date,
          area: DocumentContentArea.Header,
          widgetPosition: PageElementPosition.TopRight,
          containerSize: null
        }
      );
      newPage.headerLayout =
        LayoutSerializer.serializeToJson(headerLayoutItems);
    } else if (
      !newPage.showDate &&
      LayoutUtils.getContentAreaByItemType(newPage, LayoutItemType.Date)
    ) {
      LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
        newPage,
        LayoutItemType.Date,
        true
      );
    }

    if (
      newPage.showPageNumber &&
      !LayoutUtils.getContentAreaByItemType(newPage, LayoutItemType.PageNumber)
    ) {
      const footerLayoutItems = LayoutSerializer.deserializeFromJson(
        newPage.footerLayout
      );
      LayoutItemUtils.createDefaultPageNumberItem(
        options.document,
        footerLayoutItems,
        {
          type: LayoutItemType.PageNumber,
          area: DocumentContentArea.Footer,
          widgetPosition: PageElementPosition.BottomRight,
          widgetPreset: LayoutWidgetUtils.pageNumberPresetsArray[0],
          containerSize: null
        }
      );
      newPage.footerLayout =
        LayoutSerializer.serializeToJson(footerLayoutItems);
    } else if (
      !newPage.showPageNumber &&
      LayoutUtils.getContentAreaByItemType(newPage, LayoutItemType.PageNumber)
    ) {
      LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
        newPage,
        LayoutItemType.PageNumber,
        true
      );
    } else if (
      newPage.showPageNumber &&
      !LayoutUtils.getContentAreaByItemType(
        newPage,
        LayoutItemType.PageNumber,
        true
      )
    ) {
      LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
        newPage,
        LayoutItemType.PageNumber,
        false
      );
    }
  }

  private async getInitialPageIndex(
    document: DocumentDto,
    documentPage: DocumentPageDto,
    customPageType?: DocumentCustomPageType
  ): Promise<number> {
    let pagePosition = PagePosition.First;

    // customPageType is a type of page that we don't need to handle on the backend side as it just replicate existing type of pages
    if (
      customPageType != null &&
      customPageType === DocumentCustomPageType.Disclaimer
    ) {
      pagePosition = this.getCustomPageTypePosition(document, customPageType);
    } else if (
      documentPage.contentType == DocumentPageContentType.MasterLegend
    ) {
      if (document.pages?.[0]?.layoutType == DocumentPageLayoutType.Cover) {
        pagePosition = PagePosition.AfterFirst;
      } else {
        pagePosition = PagePosition.First;
      }
    } else if (documentPage.contentType != DocumentPageContentType.Layout) {
      pagePosition = PagePosition.AfterSelected;
    } else {
      pagePosition = this.getLayoutPagePosition(documentPage.layoutType);
    }

    // Ensure we insert new page after the last continuation sheet for pages with text overflow
    if (pagePosition == PagePosition.AfterSelected) {
      await this.ensureStandaloneLastContinuationPage(
        document,
        this.selectedPage,
        this.selectedSubPageIndex
      );
    }

    const pageIndex = this.convertPagePositionToIndex(document, pagePosition);

    return pageIndex;
  }

  private convertPagePositionToIndex(
    document: DocumentDto,
    pagePosition: PagePosition
  ): number {
    // When we have no pages, it should always be placed at 0
    if (document.pages.length == 0) {
      return 0;
    }
    // When only a single page exists and it doesn't need to be "First"
    // always place it after.
    if (pagePosition != PagePosition.First && document.pages.length == 1) {
      return 1;
    }

    switch (pagePosition) {
      case PagePosition.First:
        return 0;
      case PagePosition.AfterFirst:
        return 1;
      case PagePosition.BeforeLast:
        return document.pages.length - 1;
      case PagePosition.Last:
        return document.pages.length;
      case PagePosition.BeforeSelected:
      case PagePosition.AfterSelected: {
        // if we cannot get currently selected page, fall back to BeforeLast
        if (!this.selectedPage) {
          return this.convertPagePositionToIndex(
            document,
            PagePosition.BeforeLast
          );
        }
        // try get currently selected page index
        const currentlySelectedPageIndex = document.pages.findIndex(
          (x) => x.id == this.selectedPage.id
        );

        // if we cannot get currently selected page index, fall back to BeforeLast
        if (currentlySelectedPageIndex < 0) {
          return this.convertPagePositionToIndex(
            document,
            PagePosition.BeforeLast
          );
        }
        switch (pagePosition) {
          case PagePosition.BeforeSelected:
            return currentlySelectedPageIndex - 1;
          case PagePosition.AfterSelected: {
            const commonDiagramsGroupPages =
              DocumentService.getCommonDiagramsGroupPages(
                document,
                this.selectedPage
              );

            // try to add page after the bucket
            if (commonDiagramsGroupPages?.length) {
              const lastCommonPage =
                commonDiagramsGroupPages[commonDiagramsGroupPages.length - 1];
              const indexOfLastCommonPage = document.pages.findIndex(
                (page) => page.id === lastCommonPage.id
              );
              if (indexOfLastCommonPage >= 0) {
                return indexOfLastCommonPage + 1;
              }
            }

            for (let i = currentlySelectedPageIndex; i >= 0; i--) {
              const page = document.pages[i];
              if (page.layoutType != DocumentPageLayoutType.Closing) {
                return i + 1;
              }
            }

            return currentlySelectedPageIndex + 1;
          }
        }
      }
    }
  }

  private getDefaultPageDesignByLayoutType(
    layoutType: DocumentPageLayoutType
  ): PageDesignDto {
    return this.pageDesigns
      .filter((l) => l.layoutType == layoutType)
      .sort((a, b) => (a.isDefault ? 1 : 0))[0];
  }

  private getPageDesignById(pageDesignId: number): PageDesignDto {
    return this.pageDesigns.find((l) => l.id == pageDesignId);
  }

  private getCustomPageTypePosition(
    document: DocumentDto,
    customPageType: DocumentCustomPageType
  ): PagePosition {
    if (customPageType === DocumentCustomPageType.Disclaimer) {
      const existingCoverPage = document.pages.find(
        (page) => page.layoutType == DocumentPageLayoutType.Cover
      );

      if (existingCoverPage) {
        return PagePosition.AfterFirst;
      }
      return PagePosition.First;
    }
  }

  private getLayoutPagePosition(
    layoutType: DocumentPageLayoutType
  ): PagePosition {
    switch (layoutType) {
      case DocumentPageLayoutType.Cover:
        return PagePosition.First;
      case DocumentPageLayoutType.Filler:
        return PagePosition.AfterSelected;
      case DocumentPageLayoutType.Closing:
        return PagePosition.Last;
      default:
        return PagePosition.AfterSelected;
    }
  }

  public async createLayoutPage(
    document: DocumentDto,
    layoutType: DocumentPageLayoutType,
    layoutId?: number,
    isPristine = true,
    pageName?: string
  ): Promise<DocumentPageDto> {
    const bodyLayout = layoutId
      ? this.getPageDesignById(layoutId)?.bodyLayout
      : this.getDefaultPageDesignByLayoutType(layoutType)?.bodyLayout;
    const newPage = {
      documentId: document.id,
      name: pageName,
      diagram: null,
      order: 0,
      description: 'New page description',
      pageType: DocumentPageType.Content,
      showLogo: false,
      showLegend: false,
      contentType: DocumentPageContentType.Layout,
      layoutType: layoutType,
      content: null,
      contentColumns: 0,
      showHeader: false,
      showFooter: false,
      isPristine: isPristine,
      stepsFontPresets: DocumentService.getFontPresetsByLayoutType(
        this.stepsFontPresets,
        layoutType
      ),
      showDate: false,
      showPageNumber: false,
      showDivider: false,
      showTitle: false,
      titleHeight: 0,
      maxTitleHeight: 0,
      titleLayout: null,
      bodyLayout: bodyLayout
    } as DocumentPageDto;
    setDocumentPagePrototype(newPage);
    return newPage;
  }

  public async duplicatePage(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number = null,
    moveToEnd = false
  ): Promise<DocumentPageDto> {
    const unlock = await DocumentService.saveMutex.lock();
    try {
      const { subPageRefs = [], ...pageToClone } = page; // Do not clone subPageRefs
      pageToClone.pageType = page.getSubPageType(subPageIndex);
      pageToClone.contentColumns = page.getSubPageColumns(subPageIndex);
      if (
        pageToClone.pageType == DocumentPageType.Content &&
        pageToClone.diagram
      ) {
        pageToClone.diagramId = null;
        pageToClone.diagram = null;
      }
      const cloneResponse = await DocumentPagesApiService.clone(pageToClone);
      document.lastModificationTime =
        cloneResponse.data.result.lastModificationTime;

      const getResponse = await DocumentPagesApiService.getDocumentPageForView({
        id: cloneResponse.data.result.pageId
      });
      const newPage = getResponse.data.result.documentPage;
      setDocumentPagePrototype(newPage);
      const subPageCount = ContentPagination.getPageCount(newPage);

      if (subPageIndex != null && subPageCount >= 1) {
        const clonedSubPage = ContentPagination.splitPagedContentIntoPages(
          newPage.content
        )[subPageIndex];

        if (clonedSubPage) {
          newPage.content = clonedSubPage;

          // Make unlinked diagram a primary diagram
          const subPageRef = subPageRefs.find(
            (r) => r.subPageIndex == subPageIndex
          );
          if (subPageRef) {
            if (
              subPageRef.diagram &&
              PageLayoutHandler.isUnlinkedDiagramAvailable
            ) {
              newPage.diagramId = (
                await DiagramsApiService.clone({
                  id: subPageRef.diagramId,
                  diagram: subPageRef.diagram,
                  documentId: document.id
                })
              ).data.result;
            }

            // need to add subPageRef to not change [DocumentPageDto] title data
            newPage.subPageRefs.push({
              pageId: newPage.id,
              subPageIndex: 0,
              titleHeight: subPageRef.titleHeight,
              maxTitleHeight: subPageRef.maxTitleHeight,
              titleLayout: subPageRef.titleLayout,
              showTitle: subPageRef.showTitle
            });
          }
        }
      }

      if (newPage.diagramId) {
        newPage.diagram = (
          await DiagramsApiService.getDiagramForView({
            id: newPage.diagramId
          })
        ).data.result.diagram;
      }
      if (newPage.subPageRefs) {
        for (const ref of newPage.subPageRefs) {
          if (ref.diagramId) {
            ref.diagram = (
              await DiagramsApiService.getDiagramForView({
                id: ref.diagramId
              })
            ).data.result.diagram;
          }
        }
      }

      // put page in right position that UI will show it correctly
      if (!moveToEnd) {
        const originalPageIndex = document.pages.findIndex(
          (p) => p.id == page.id
        );
        document.pages.splice(originalPageIndex + 1, 0, newPage);
      } else {
        document.pages.push(newPage);
      }

      // Move the new page after the original
      await this.ensureStandaloneLastContinuationPage(
        document,
        page,
        subPageIndex
      );

      const newPageIndex = document.pages.findIndex((p) => p.id == newPage.id);

      if (!moveToEnd) {
        newPage.order += 1;
        const originalPageIndex = document.pages.findIndex(
          (p) => p.id == page.id
        );
        if (newPageIndex >= 0 && originalPageIndex >= 0) {
          await this.movePage(document, newPageIndex, originalPageIndex + 1);
        }
      } else {
        newPage.order = document.pages.length - 1;
      }

      await Vue.$globalStore.dispatch(
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID}`,
        document.id
      );

      // on duplicate we need to reassign [maxTitleHeight] value
      if (page.showTitle && page.maxTitleHeight === 0) {
        await DocumentPageTitleService.syncFlipbookGroupMaxTitleHeight({
          pages: [newPage],
          isDelete: false
        });
      }

      if (
        newPage.diagram &&
        newPage.diagram.quickStartStateData?.state !== null
      ) {
        newPage.diagram.quickStartStateData = {
          state: QuickStartState.Complete
        };
      }

      EventBus.$emit(EventBusActions.DOCUMENT_PAGE_CREATED, newPage);
      LayoutUtils.reIdDuplicatedPageLayoutItems(newPage);
      return newPage;
    } finally {
      unlock();
    }
  }

  public async movePage(
    document: DocumentDto,
    sourceIndex: number,
    targetIndex: number,
    sourceSubPageIndex: number = null,
    targetSubPageIndex: number = null
  ): Promise<DocumentPageDto> {
    const isTargetLastPage = targetIndex == document.pages.length;
    let sourcePage = document.pages[sourceIndex];
    let targetPage = isTargetLastPage
      ? document.pages[targetIndex - 1]
      : document.pages[targetIndex];

    if (!sourcePage || !targetPage) {
      return null;
    }

    if (sourceIndex == targetIndex) {
      if (sourceSubPageIndex == targetSubPageIndex) {
        return sourcePage;
      }

      // Move subpage within the same page
      sourcePage.content = ContentPagination.movePage(
        sourcePage.content,
        sourceSubPageIndex,
        targetSubPageIndex
      );
      EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
        key: getBodyKey(sourcePage.id, sourceSubPageIndex),
        content: sourcePage.content
      });

      // Remap unlinked diagrams
      for (const ref of sourcePage.subPageRefs) {
        if (ref.subPageIndex == sourceSubPageIndex) {
          ref.subPageIndex = targetSubPageIndex;
        } else if (
          sourceSubPageIndex > targetSubPageIndex &&
          ref.subPageIndex >= targetSubPageIndex &&
          ref.subPageIndex <= sourceSubPageIndex &&
          PageLayoutHandler.isUnlinkedDiagramAvailable
        ) {
          ref.subPageIndex++;
        } else if (
          sourceSubPageIndex < targetSubPageIndex &&
          ref.subPageIndex >= sourceSubPageIndex &&
          ref.subPageIndex <= targetSubPageIndex &&
          PageLayoutHandler.isUnlinkedDiagramAvailable
        ) {
          ref.subPageIndex--;
        }
      }
    } else {
      if (sourceSubPageIndex != null) {
        sourcePage = await this.ensureStandalonePage(
          document,
          sourcePage,
          sourceSubPageIndex
        );
      }
      if (targetSubPageIndex > 0 && !isTargetLastPage) {
        targetPage = await this.ensureStandalonePage(
          document,
          targetPage,
          targetSubPageIndex
        );
      }

      // Move page/subpage within the document
      sourceIndex = document.pages.indexOf(sourcePage);
      document.pages.splice(sourceIndex, 1);
      if (sourceSubPageIndex > 0 || targetSubPageIndex > 0) {
        if (isTargetLastPage) {
          targetIndex = document.pages.length;
        } else {
          targetIndex = document.pages.indexOf(targetPage);
        }
      }
      document.pages.splice(targetIndex, 0, sourcePage);

      for (let order = 0; order < document.pages.length; order++) {
        document.pages[order].order = order;
      }
    }

    return sourcePage;
  }

  public async mergePages(
    document: DocumentDto,
    sourcePage: DocumentPageDto,
    targetPage: DocumentPageDto
  ): Promise<DocumentPageDto> {
    const sourceSubPageCount = ContentPagination.getPageCount(sourcePage);
    const targetSubPageCount = ContentPagination.getPageCount(targetPage);

    targetPage.content = ContentPagination.mergePages(
      targetPage.content,
      sourcePage.content
    );
    EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
      key: getBodyKey(targetPage.id),
      content: targetPage.content
    });

    if (sourcePage.pageType != DocumentPageType.Content) {
      if (targetPage.pageType == DocumentPageType.Content) {
        targetPage.pageType = sourcePage.pageType;
        targetPage.diagramPosition = sourcePage.diagramPosition;
        targetPage.contentColumns = 0;

        if (!targetPage.diagram) {
          targetPage.diagramId = sourcePage.diagramId;
          targetPage.diagram = sourcePage.diagram;
        }
      }
      if (!targetPage.subPageRefs) {
        targetPage.subPageRefs = [];
      }
      if (!sourcePage.subPageRefs) {
        sourcePage.subPageRefs = [];
      }

      for (
        let subPageIndex = 0;
        subPageIndex < sourceSubPageCount;
        subPageIndex++
      ) {
        let subPageRef = sourcePage.subPageRefs.find(
          (r) => r.subPageIndex == subPageIndex
        );
        if (subPageRef) {
          subPageRef = {
            diagramId: subPageRef.diagram.id,
            diagram: subPageRef.diagram,
            pageId: targetPage.id,
            subPageIndex: subPageIndex + targetSubPageCount,
            titleHeight: subPageRef.titleHeight,
            maxTitleHeight: subPageRef.maxTitleHeight,
            titleLayout: subPageRef.titleLayout,
            showTitle: subPageRef.showTitle
          };
        } else if (targetPage.diagram.id !== sourcePage.diagram.id) {
          subPageRef = {
            diagramId: sourcePage.diagram.id,
            diagram: sourcePage.diagram,
            pageId: targetPage.id,
            subPageIndex: subPageIndex + targetSubPageCount,
            titleHeight: sourcePage.titleHeight,
            maxTitleHeight: sourcePage.maxTitleHeight,
            titleLayout: sourcePage.titleLayout,
            showTitle: sourcePage.showTitle
          };
        }
        if (subPageRef) {
          targetPage.subPageRefs.push(subPageRef);
        }
      }
    }

    await this.deletePage(document, sourcePage);
    return targetPage;
  }

  public async deletePage(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number = null
  ): Promise<DocumentPageDto> {
    const unlock = await DocumentService.saveMutex.lock();
    try {
      const subPageCount = ContentPagination.getPageCount(page);
      if (subPageIndex == null || subPageCount <= 1) {
        await DocumentPagesApiService.delete({ id: page.id });
        const pageIndex = document.pages.findIndex((p) => p.id == page.id);
        if (pageIndex >= 0) {
          document.pages.splice(pageIndex, 1);
        }
      } else {
        if (page.subPageRefs?.length > 0) {
          const subPageRef = page.subPageRefs.find(
            (r) => r.subPageIndex == subPageIndex
          );
          if (subPageRef) {
            page.subPageRefs.splice(page.subPageRefs.indexOf(subPageRef), 1);
          }
          if (PageLayoutHandler.isUnlinkedDiagramAvailable) {
            for (const ref of page.subPageRefs) {
              if (ref.subPageIndex > subPageIndex) {
                // Shift unlinked diagrams up
                ref.subPageIndex--;
              }
            }
          }
        }

        page.content = ContentPagination.removePage(page.content, subPageIndex);
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getBodyKey(page.id, subPageIndex),
          content: page.content
        });
      }

      EventBus.$emit(EventBusActions.DOCUMENT_PAGE_REMOVED, page, subPageIndex);
      return page;
    } finally {
      unlock();
    }
  }

  public async changePageType(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number,
    newType: DocumentPageType
  ): Promise<DocumentPageDto> {
    page = await this.ensureStandalonePage(document, page, subPageIndex);

    if (page.pageType == newType) {
      return page;
    }
    page.pageType = newType;

    switch (newType) {
      case DocumentPageType.Content:
      case DocumentPageType.Split:
        page.contentType = DocumentPageContentType.Html;
        break;
      default:
        page.contentType = DocumentPageContentType.None;
    }

    if (
      !page.diagram &&
      (newType == DocumentPageType.Diagram || newType == DocumentPageType.Split)
    ) {
      page.diagram = {
        id: 0,
        name: page.name,
        description: page.description,
        quickStartStateData: {
          state: QuickStartState.Initial
        },
        isTemplate: false
      };
    }

    EventBus.$emit(EventBusActions.DOCUMENT_PAGE_TYPE_CHANGED);
    return page;
  }

  public async changePageContentColumns(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number,
    columns: number
  ): Promise<DocumentPageDto> {
    page = await this.ensureStandalonePage(document, page, subPageIndex);
    page.contentColumns = columns;
    EventBus.$emit(EventBusActions.DOCUMENT_PAGE_CONTENT_COLUMNS_CHANGED);
    return page;
  }

  public async changePageDiagramPosition(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number,
    position: DiagramPosition
  ): Promise<DocumentPageDto> {
    page = await this.ensureStandalonePage(document, page, subPageIndex);
    page.diagramPosition = position;
    EventBus.$emit(EventBusActions.DOCUMENT_PAGE_DIAGRAM_POSITION_CHANGED);
    return page;
  }

  public async changePageLayout(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number,
    layout: 'text' | 'diagram' | 'text-text' | 'diagram-text' | 'text-diagram'
  ): Promise<void> {
    switch (layout) {
      case 'text':
        page = await this.changePageType(
          document,
          page,
          subPageIndex,
          DocumentPageType.Content
        );
        page = await this.changePageContentColumns(
          document,
          page,
          subPageIndex,
          0
        );
        break;

      case 'diagram':
        page = await this.changePageType(
          document,
          page,
          subPageIndex,
          DocumentPageType.Diagram
        );
        page = await this.changePageDiagramPosition(
          document,
          page,
          subPageIndex,
          DiagramPosition.Left
        );
        page = await this.changePageContentColumns(
          document,
          page,
          subPageIndex,
          0
        );
        break;

      case 'text-text':
        page = await this.changePageType(
          document,
          page,
          subPageIndex,
          DocumentPageType.Content
        );
        page = await this.changePageContentColumns(
          document,
          page,
          subPageIndex,
          2
        );
        break;

      case 'diagram-text':
        page = await this.changePageType(
          document,
          page,
          subPageIndex,
          DocumentPageType.Split
        );
        page = await this.changePageDiagramPosition(
          document,
          page,
          subPageIndex,
          DiagramPosition.Left
        );
        page = await this.changePageContentColumns(
          document,
          page,
          subPageIndex,
          0
        );
        break;

      case 'text-diagram':
        page = await this.changePageType(
          document,
          page,
          subPageIndex,
          DocumentPageType.Split
        );
        page = await this.changePageDiagramPosition(
          document,
          page,
          subPageIndex,
          DiagramPosition.Right
        );
        page = await this.changePageContentColumns(
          document,
          page,
          subPageIndex,
          0
        );
        break;
    }

    EventBus.$emit(EventBusActions.DOCUMENT_SET_PAGE, {
      page: page,
      subPageIndex: 0
    });

    EventBus.$emit(EventBusActions.DOCUMENT_PAGE_LAYOUT_CHANGED);
  }

  /**
   * Ensures that the page is a standalone page and not part of a group of pages (subpage)
   * Splits the page into 2 or 3 parts and extracts the subpage into standalone page if necessary
   * Example with subPageIndex = 2:
   * Split direction before:  0 1|2 3 4   = 2 parts
   * Split direction after:   0 1 2|3 4   = 2 parts
   * Split direction both:    0 1|2|3 4   = 3 parts
   */
  public async ensureStandalonePage(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number,
    splitDirection: 'before' | 'after' | 'both' = 'both'
  ): Promise<DocumentPageDto> {
    const subPageCount = ContentPagination.getPageCount(page);
    if (subPageCount == 1) {
      return page;
    }

    const subPageRefs = page.subPageRefs ?? [];
    const newSubPages: HTMLElement[] = [];
    const mergedSubPageIndexes = [];
    const rootElement = htmlToElement(`<div>${page.content ?? ''}</div>`);
    let currentSubPageIndex = 0;

    // Allocate page content elements to relevant standalone pages
    for (const element of [...rootElement.children]) {
      let newSubPageIndex = 0;
      if (currentSubPageIndex == subPageIndex) {
        if (splitDirection == 'before' || splitDirection == 'both') {
          newSubPageIndex = 1;
        }
      } else if (currentSubPageIndex > subPageIndex) {
        if (splitDirection == 'after') {
          newSubPageIndex = 1;
        } else if (splitDirection == 'both') {
          newSubPageIndex = 2;
        }
      }
      if (
        newSubPageIndex > 0 &&
        subPageIndex == 0 &&
        splitDirection != 'after'
      ) {
        newSubPageIndex--;
      }

      if (!newSubPages[newSubPageIndex]) {
        newSubPages[newSubPageIndex] = window.document.createElement('div');
        mergedSubPageIndexes[newSubPageIndex] = [];
      }
      newSubPages[newSubPageIndex].appendChild(element);
      if (
        !mergedSubPageIndexes[newSubPageIndex].includes(currentSubPageIndex)
      ) {
        mergedSubPageIndexes[newSubPageIndex].push(currentSubPageIndex);
      }

      if (element.matches(ContentPagination.pageElementTag)) {
        currentSubPageIndex++;
      }
    }

    let resultPage = page;
    if (newSubPages.length <= 1) {
      return resultPage;
    }

    // Create multiple standalone pages from the parent page
    for (
      let currentSubPageIndex = 0;
      currentSubPageIndex < newSubPages.length;
      currentSubPageIndex++
    ) {
      const newSubPage = newSubPages[currentSubPageIndex];

      // Remove manual page breaks at the beginning and end of the new subpages (unneccessary)
      if (ContentPagination.isManualPageBreak(newSubPage.firstElementChild)) {
        newSubPage.firstElementChild.remove();
      }
      if (ContentPagination.isManualPageBreak(newSubPage.lastElementChild)) {
        newSubPage.lastElementChild.remove();
      }

      const newSubPageContent = newSubPage.innerHTML;
      const mergedIndexes = mergedSubPageIndexes[currentSubPageIndex];
      let newPage: DocumentPageDto = null;

      if (currentSubPageIndex == 0) {
        newPage = page;
        newPage.content = newSubPageContent;
        newPage.subPageRefs = subPageRefs.filter(
          (r) => r.pageId == page.id && mergedIndexes.includes(r.subPageIndex)
        );
        EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
          key: getBodyKey(newPage.id, currentSubPageIndex),
          content: newSubPageContent
        });
      } else {
        let newDiagram = page.diagram;
        // Find all unlinked diagrams for the current subpage
        const newSubpageRefs = subPageRefs.filter(
          (r) => r.pageId == page.id && mergedIndexes.includes(r.subPageIndex)
        );
        // Set first unlinked diagram as primary diagram if all subpages are unlinked
        if (newSubpageRefs.length == mergedIndexes.length) {
          newDiagram = newSubpageRefs[0].diagram;
          newSubpageRefs.splice(0, 1);
        }
        // Clone diagram and retrieve the dto
        if (newDiagram) {
          const newDiagramId = (
            await DiagramsApiService.clone({
              id: newDiagram.id,
              diagram: newDiagram,
              documentId: document.id
            })
          ).data.result;
          newDiagram = (
            await DiagramsApiService.getDiagramForView({ id: newDiagramId })
          ).data.result.diagram;
        }

        newPage = await this.addNewPage({
          document: document,
          pageType: page.pageType,
          contentType: page.contentType,
          layoutType: page.layoutType,
          contentColumns: page.contentColumns,
          content: newSubPageContent,
          diagramPosition: page.diagramPosition,
          isPristine: true,
          pageIndex: document.pages.indexOf(page) + currentSubPageIndex,
          showDate: page.showDate,
          showPageNumber: page.showPageNumber,
          showDivider: page.showDivider,
          showTitle: page.showTitle,
          titleHeight: page.titleHeight,
          maxTitleHeight: page.maxTitleHeight,
          titleLayout: page.titleLayout,
          bodyLayout: page.bodyLayout
        });
        newPage.diagram = newDiagram;

        // Remap unlinked diagram refs (clone diagrams and recalculate subpage indexes)
        const pageIndexOffset = mergedSubPageIndexes.reduce((a, b, i) => {
          if (i < currentSubPageIndex) {
            return a + b.length;
          } else {
            return a;
          }
        }, 0);
        newPage.subPageRefs = [];
        for (const ref of newSubpageRefs) {
          const refDiagramId = (
            await DiagramsApiService.clone({
              id: ref.diagram.id,
              diagram: ref.diagram,
              documentId: document.id
            })
          ).data.result;
          const refDiagram = (
            await DiagramsApiService.getDiagramForView({ id: refDiagramId })
          ).data.result.diagram;
          const newRef: DocumentSubPageDto = {
            subPageIndex: ref.subPageIndex - pageIndexOffset,
            diagram: refDiagram,
            diagramId: refDiagramId,
            pageId: newPage.id,
            titleHeight: newPage.titleHeight,
            maxTitleHeight: newPage.maxTitleHeight,
            titleLayout: newPage.titleLayout,
            showTitle: newPage.showTitle
          };
          newPage.subPageRefs.push(newRef);
        }
      }

      if (mergedIndexes.includes(subPageIndex)) {
        resultPage = newPage;
      }
    }

    return resultPage;
  }

  /**
   * Ensures that the last page in the list of continuation pages is standalone
   */
  private async ensureStandaloneLastContinuationPage(
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number
  ): Promise<void> {
    if (page?.contentType == DocumentPageContentType.Html) {
      const subPageCount = ContentPagination.getPageCount(page);

      if (subPageCount > 1 && subPageIndex < subPageCount - 1) {
        const continuousPageIndexes =
          ContentPagination.getContinuousPageIndexes(page.content);
        const continuationPages = continuousPageIndexes.find((x) =>
          x.includes(subPageIndex)
        );
        if (continuationPages?.length > 0) {
          await this.ensureStandalonePage(
            document,
            page,
            continuationPages[continuationPages.length - 1],
            'after'
          );
        }
      }
    }
  }

  public getLinkedPageNumbers(
    pageListItem: PageListItem,
    selectedDiagram: DiagramDto
  ): Array<number> {
    if (isNil(pageListItem.page.subPageRefs)) {
      return [pageListItem.pageNumber];
    }
    const linkedSubpageIndexes = [];
    // Selected diagram is main linked diagram
    if (selectedDiagram.id == pageListItem.page.diagram?.id) {
      const subpageIndexes = pageListItem.page.subPageRefs
        .filter((ref) => !!ref.diagramId)
        .map((ref) => ref.subPageIndex);
      for (let i = 0; i < pageListItem.subPageCount; ++i) {
        if (!subpageIndexes.includes(i)) {
          linkedSubpageIndexes.push(i);
        }
      }
    } else {
      // Selected diagram is not main linked diagram but it can be linked with other subpages
      const subpageIndexes = pageListItem.page.subPageRefs
        .filter((ref) => ref.diagram.id === selectedDiagram.id)
        .map((ref) => ref.subPageIndex);
      for (let i = 0; i < pageListItem.subPageCount; ++i) {
        if (subpageIndexes.includes(i)) {
          linkedSubpageIndexes.push(i);
        }
      }
    }

    return linkedSubpageIndexes.map((i) => i + pageListItem.pageNumber);
  }
}

const instance = new DocumentEditor();
export default instance;
