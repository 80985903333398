// Uncomment to use LegacyPdfExportProvider (deprecated)
//const pdfLibImport = () => import('pdf-lib');
const pdfLibImport = () => Promise.resolve(null);

export default class PdfDocumentBuilder {
  public pages: Blob[] = [];
  public coverSheet: Blob;

  public async getDocument(): Promise<Uint8Array> {
    const pdfLib = await pdfLibImport();
    const documentPdf = await pdfLib.PDFDocument.create();
    if (this.coverSheet) {
      const coverSheetBuffer = await this.coverSheet.arrayBuffer();
      const coverSheetPdf = await pdfLib.PDFDocument.load(coverSheetBuffer);
      const coverSheetPage = await documentPdf.copyPages(coverSheetPdf, [0]);
      documentPdf.addPage(coverSheetPage[0]);
    }

    for (let page of this.pages) {
      const pageBuffer = await page.arrayBuffer();
      const pagePdf = await pdfLib.PDFDocument.load(pageBuffer);
      const copiedPages = await documentPdf.copyPages(
        pagePdf,
        pagePdf.getPageIndices()
      );
      for (let copiedPage of copiedPages) {
        documentPdf.addPage(copiedPage);
      }
    }

    return await documentPdf.save();
  }
}
