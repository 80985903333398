import ISearchParameter from './ISearchParameter';

export default class DataPropertyTagParameter implements ISearchParameter {
  public tagName: string;
  public tagColor: string;

  constructor(tagName: string, tagColor: string) {
    this.tagName = tagName;
    this.tagColor = tagColor;
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof DataPropertyTagParameter && other.tagName == this.tagName
    );
  }
}
