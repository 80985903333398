import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  AdjacencyTypes,
  ConcurrencyController,
  GraphComponent,
  IInputModeContext,
  INode,
  Key,
  KeyboardInputMode,
  KeyEventArgs,
  Rect
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import EdgeServiceBase from '../EdgeServiceBase';
import QuickBuildService from '../quick-build.service';
import { EventBus, EventBusActions } from '../../events/eventbus.service';

export default class JigsawKeyboardInputMode extends KeyboardInputMode {
  private readonly moveStep = diagramConfig.grid.size;
  private keys = [
    Key.ARROW_DOWN,
    Key.ARROW_UP,
    Key.ARROW_LEFT,
    Key.ARROW_RIGHT
  ];

  private keyDownListener = this.onKeyDown.bind(this);

  private get edgeService(): EdgeServiceBase {
    return this.graphService.getService<EdgeServiceBase>(
      EdgeServiceBase.$class
    );
  }

  private get quickBuildService(): QuickBuildService {
    return this.graphService.getService<QuickBuildService>(
      QuickBuildService.$class
    );
  }

  constructor(
    private graphComponent: GraphComponent,
    private graphService: IGraphService
  ) {
    super();
  }

  install(context: IInputModeContext, controller: ConcurrencyController): void {
    super.install(context, controller);
    const canvas = context.canvasComponent as GraphComponent;
    if (canvas !== null) {
      canvas.addKeyDownListener(this.keyDownListener);
    }
  }

  uninstall(context: IInputModeContext): void {
    super.uninstall(context);
    const canvas = context.canvasComponent as GraphComponent;
    if (canvas !== null) {
      canvas.removeKeyDownListener(this.keyDownListener);
    }
  }

  private onKeyDown(sender: GraphComponent, evt: KeyEventArgs): void {
    if (!this.keys.includes(evt.key) || !this.enabled) return;
    if (this.quickBuildService.inProgress) {
      EventBus.$emit(EventBusActions.QUICK_BUILD_OPERATION_DENIED);
      return;
    }

    this.moveNodes(evt.key);
  }

  private moveNodes(key: Key): void {
    const selection = this.graphComponent.selection;

    if (
      selection.every((s) => s instanceof INode) &&
      !this.isGraphLabelEditing
    ) {
      selection.forEach((node) => {
        this.moveNode(key, node as INode);
      });
    }
  }

  private moveNode(key: Key, node: INode): void {
    const { x, y, width, height } = node.layout;

    let newX = x;
    let newY = y;

    if (key === Key.ARROW_LEFT) {
      newX -= this.moveStep;
    }
    if (key === Key.ARROW_RIGHT) {
      newX += this.moveStep;
    }
    if (key === Key.ARROW_UP) {
      newY -= this.moveStep;
    }
    if (key === Key.ARROW_DOWN) {
      newY += this.moveStep;
    }

    this.graphComponent.graph.setNodeLayout(
      node,
      new Rect(newX, newY, width, height)
    );

    if (node.ports.size) {
      const adjacentEdges = this.graphComponent.graph.edgesAt(
        node,
        AdjacencyTypes.ALL
      );

      if (adjacentEdges.size) {
        adjacentEdges.forEach((e) => {
          this.edgeService.applyEdgeRouterForEdges([e]);
        });
      }
    }
  }

  private get isGraphLabelEditing(): boolean {
    return this.graphComponent.graph.labels.some((label) =>
      DiagramUtils.isLabelEditing(this.graphComponent, label)
    );
  }
}
