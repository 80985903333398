import ILegendItemDefinition from './ILegendItemDefinition';
import { LegendItemType } from './LegendItemType';
import i18n from '../../core/plugins/vue-i18n';

export default class LegendItem implements ILegendItemDefinition {
  type: LegendItemType;
  show: boolean;
  data: any;
  label: string;
  labelEdit: string;
  name: string;
  symbol: string;
  active: boolean;
  key: string;
  isFiltered: boolean;
  isExternal?: boolean;
  isPristine: boolean;

  constructor(args: {
    type: LegendItemType;
    data?: any;
    show: boolean;
    label: string;
    name: string;
    symbol: string;
    key: string;
    isFiltered: boolean;
    isExternal?: boolean;
    isPristine?: boolean;
  }) {
    this.type = args.type;
    this.data = args.data;
    if (args.label) {
      this.label = args.label;
    } else {
      this.label = i18n.t('UNLABELLED').toString();
    }
    this.name = args.name;
    this.show = args.show;
    this.symbol = args.symbol;
    this.active = false;
    this.key = args.key;
    this.isFiltered = args.isFiltered;
    this.isExternal = args.isExternal;
    this.isPristine = args.isPristine;
  }

  clone(): LegendItem {
    return LegendItem.deserialize(this);
  }

  serialize(): ILegendItemDefinition {
    return {
      type: this.type,
      show: this.show,
      label: this.label,
      name: this.name,
      symbol: this.symbol,
      key: this.key,
      isFiltered: this.isFiltered,
      isExternal: this.isExternal,
      isPristine: this.isPristine
    };
  }

  static deserialize(definition: ILegendItemDefinition): LegendItem {
    return new LegendItem({
      type: definition.type,
      label: definition.label,
      show: definition.show,
      name: definition.name,
      symbol: definition.symbol,
      key: definition.key,
      isFiltered: definition.isFiltered,
      isExternal: definition.isExternal,
      isPristine: definition.isPristine
    });
  }
}
