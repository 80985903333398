<template>
  <transition
    enter-active-class="transition"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="showLoading"
      :class="opacityClass"
      class="absolute inset-0 z-100 flex cursor-wait items-center justify-center bg-white"
    >
      <svg
        v-if="defaultSpinner"
        class="spinner-wrap"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        v-bind="sizeStyle"
      >
        <circle
          class="circle"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          :stroke="color"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>

      <slot v-else>
        <lottie-player
          v-if="isHalloween"
          src="/halloween-media/jigsaw-ghost-loading.json"
          background="transparent"
          speed="1"
          class="h-400 w-250 translate-y-40 transform opacity-20"
          loop
          autoplay
        />

        <lottie-player
          v-else-if="isThanksgiving"
          src="/thanksgiving-media/thanksgiving-basket-loading.json"
          background="transparent"
          speed="1"
          class="h-330 w-330"
          loop
          autoplay
        />

        <lottie-player
          v-else-if="isChristmas"
          src="/christmas-media/loading-snowflake.json"
          background="transparent"
          speed="1"
          class="h-330 w-330"
          loop
          autoplay
        />

        <svg
          v-else
          class="spinner-wrap"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
          v-bind="sizeStyle"
        >
          <circle
            class="circle"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            :stroke="color"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </slot>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  GET_CHRISTMAS_THEME,
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GLOBAL_NAMESPACE
} from '@/core/services/store/global.module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { loadersList } from './index';

@Component({
  name: 'AppLoadingOverlay'
})
export default class AppLoadingOverlay extends Vue {
  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE
  })
  isChristmas: boolean;

  @Prop({ default: false, type: Boolean })
  loading: boolean;

  @Prop({ default: false, type: Boolean })
  defaultSpinner: boolean;

  @Prop({ default: '', type: String })
  name: string;

  @Prop({ default: 64 })
  size: number | string;

  @Prop({ default: '#3433E1' })
  color: string;

  @Prop({ default: 'bg-opacity-70' })
  opacityClass: string;

  loadingList = loadersList;

  get sizeStyle() {
    return {
      width: `${this.size}px`,
      heiht: `${this.size}px`
    };
  }

  get showLoading() {
    if (this.name) {
      return this.loadingList.includes(this.name);
    }

    return this.loading;
  }
}
</script>

<style scoped>
.spinner-wrap {
  animation: rotation 1.35s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.circle {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: turn 1.35s ease-in-out infinite;
}

@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    transform: rotate(450deg);
  }
}
</style>
