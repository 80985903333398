import { DocumentSyncMessageType } from './DocumentSyncMessageType';
import IDocumentSyncMessage from './IDocumentSyncMessage';

export default class DocumentSyncRequest implements IDocumentSyncMessage {
  public type: DocumentSyncMessageType = DocumentSyncMessageType.Request;
  public documentId: number;

  constructor(documentId: number) {
    this.documentId = documentId;
  }
}
