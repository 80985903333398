import {
  ApplicationPermissions,
  IPermission
} from '@/api/ApplicationPermissions';
import { Component, Vue } from 'vue-property-decorator';
import { hasPermission } from '@/core/utils/PermissionUtils';

@Component({})
export default class PermissionMixin extends Vue {
  ApplicationPermissions = ApplicationPermissions;

  hasPermission(permission: IPermission): boolean {
    return hasPermission(permission);
  }

  doesNotHavePermission(permission: IPermission): boolean {
    return !this.hasPermission(permission);
  }
}
