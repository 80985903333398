import IPageSyncChangeData from './IPageSyncChangeData';
import { PageSyncChangeType } from './PageSyncChangeType';

export default class PageSyncChange {
  public type: PageSyncChangeType;
  public data?: IPageSyncChangeData;

  constructor(type: PageSyncChangeType, data?: IPageSyncChangeData) {
    this.type = type;
    this.data = data;
  }

  public equals(other: PageSyncChange): boolean {
    return (
      this.type === other.type &&
      this.data?.compositeStyleIndex === other.data?.compositeStyleIndex
    );
  }
}
