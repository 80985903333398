import axios, {
    AxiosResponse
} from 'axios';
import {
    CurrentAccount,
    ZadarResponse,
    IsTenantAvailableOutput,
    IsTenantAvailableInput,
    ResolveTenantIdInput,
    RegisterOutput,
    RegisterInput,
    SendPasswordResetCodeInput,
    ResetPasswordOutput,
    ResetPasswordInput,
    SendEmailActivationLinkInput,
    ActivateEmailInput,
    ImpersonateOutput,
    ImpersonateInput,
    DelegatedImpersonateInput,
    SwitchToLinkedAccountOutput,
    SwitchToLinkedAccountInput
} from './models'
export default class AccountApiService {
    public static getCurrentAccount(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CurrentAccount >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/GetCurrentAccount`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static isTenantAvailable(body: IsTenantAvailableInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < IsTenantAvailableOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/IsTenantAvailable`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static resolveTenantId(body: ResolveTenantIdInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/ResolveTenantId`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static register(body: RegisterInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < RegisterOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/Register`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static sendPasswordResetCode(body: SendPasswordResetCodeInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Account/SendPasswordResetCode`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static resetPassword(body: ResetPasswordInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ResetPasswordOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/ResetPassword`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static sendEmailActivationLink(body: SendEmailActivationLinkInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Account/SendEmailActivationLink`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static activateEmail(body: ActivateEmailInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Account/ActivateEmail`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static impersonate(body: ImpersonateInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImpersonateOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/Impersonate`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delegatedImpersonate(body: DelegatedImpersonateInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImpersonateOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/DelegatedImpersonate`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static backToImpersonator(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImpersonateOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/BackToImpersonator`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static switchToLinkedAccount(body: SwitchToLinkedAccountInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < SwitchToLinkedAccountOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Account/SwitchToLinkedAccount`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}