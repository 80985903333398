import {
  INode,
  IEdge,
  PortAdjustmentPolicy,
  IGraph,
  EdgeRouterEdgeLayoutDescriptor,
  EdgeRouterScope,
  GridInfo,
  EdgeRouter,
  EdgeRouterEdgeRoutingStyle,
  CurveFittingLayoutStage,
  List,
  Point,
  ILayoutAlgorithm,
  PortCandidate,
  EdgeRouterData
} from 'yfiles';
import groupBy from 'lodash/groupBy';
import diagramConfig from '@/core/config/diagram.definition.config';
import { EdgeVisualType } from '@/api/models';
import EdgeRoutingHelper from '../EdgeRoutingHelper';
import { IEdgeRouting, RoutingResult } from '../IDiagramTypeHelper';
import JigsawStraightLineEdgeRouter from './JigsawStraightLineEdgeRouter';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { hasOwnProperty } from '@/core/utils/common.utils';
import EdgeServiceBase, { READJUSTMENT_TAG } from './EdgeServiceBase';
import HiddenObjectLayoutStage from './HiddenObjectLayoutStage';
import { PreserveFixedInLayoutEdges } from '../graph/PreserveFixedInLayoutEdges';

export default class DefaultEdgeRouting implements IEdgeRouting {
  private adjustmentPhase: boolean = false;
  constructor(private edgeService: EdgeServiceBase) {}
  routeKnownEdges(graph: IGraph, edges: IEdge[]): RoutingResult {
    if (!this.adjustmentPhase) {
      // prevents Stack Overflow, dueto the "TEMP FIX!!" below, this has a potential to get stuck in a loop.
      this.adjustmentPhase = true;

      const adjustmentCandidates = graph.edges
        .filter((e) =>
          hasOwnProperty(e.tag, READJUSTMENT_TAG)
            ? e.tag[READJUSTMENT_TAG]
            : false
        )
        .toArray();

      if (adjustmentCandidates.length > 0) {
        adjustmentCandidates.forEach((e) => {
          DiagramUtils.clearFixedEdgePort(e.tag, true);
          DiagramUtils.clearFixedEdgePort(e.tag, false);
          e.tag[READJUSTMENT_TAG] = false;
          // THIS IS A TEMP FIX!!
          this.edgeService.applyEdgeRouterForEdges([e]);
          this.edgeService.applyEdgeRouterForEdges([e]);
        });
      }
      this.adjustmentPhase = false;
    }

    let grouped = groupBy(edges, (e) => this.getEdgeGroup(e));
    let groupNames = Object.keys(grouped);

    groupNames.forEach((groupName) => {
      const edges = grouped[groupName];
      const edgeRouter = this.getEdgeRouterByGroup(groupName);
      if (!edgeRouter) return;

      const edgeRouterData = new EdgeRouterData();

      edgeRouterData.affectedEdges.delegate = (edge: IEdge): boolean => {
        return (
          edges.indexOf(edge) >= 0 && !DiagramUtils.isAnnotationArrow(edge)
        );
      };

      edgeRouterData.edgeLayoutDescriptors.delegate = (
        edge: IEdge
      ): EdgeRouterEdgeLayoutDescriptor => {
        const descriptor = new EdgeRouterEdgeLayoutDescriptor();
        descriptor.penaltySettings.bendPenalty = 5;
        return descriptor;
      };

      edges.forEach((edge) => {
        const sourcePortParams = EdgeRoutingHelper.getEdgeParams(
          graph,
          edge,
          true,
          this.getIgnoredLocation(edge.sourceNode)
        );
        //if the edge is fixed, then don't filter it's only candidate
        if (!edge.tag.sourcePortFixed) {
          sourcePortParams.candidates = this.filterPortCandidates(
            graph,
            edge.sourceNode,
            edge,
            sourcePortParams.candidates
          );
        }
        const targetPortParams = EdgeRoutingHelper.getEdgeParams(
          graph,
          edge,
          false,
          this.getIgnoredLocation(edge.targetNode)
        );
        //if the edge is fixed, then don't filter it's only candidate
        if (!edge.tag.targetPortFixed) {
          targetPortParams.candidates = this.filterPortCandidates(
            graph,
            edge.targetNode,
            edge,
            targetPortParams.candidates
          );
        }
        if (
          sourcePortParams.candidates &&
          sourcePortParams.candidates.length > 0
        ) {
          edgeRouterData.sourcePortCandidates.mapper.set(
            edge,
            new List(sourcePortParams.candidates)
          );
        }

        if (sourcePortParams.constraint) {
          edgeRouterData.sourcePortConstraints.mapper.set(
            edge,
            sourcePortParams.constraint
          );
        }

        if (
          targetPortParams.candidates &&
          targetPortParams.candidates.length > 0
        ) {
          edgeRouterData.targetPortCandidates.mapper.set(
            edge,
            new List(targetPortParams.candidates)
          );
        }

        if (targetPortParams.constraint) {
          edgeRouterData.targetPortConstraints.mapper.set(
            edge,
            targetPortParams.constraint
          );
        }
      });

      graph.applyLayout({
        layout: new PreserveFixedInLayoutEdges(
          new HiddenObjectLayoutStage(edgeRouter, {
            nodePredicate: (node) =>
              EdgeRoutingHelper.isNodeIgnoredInEdgeRouting(node)
          })
        ),
        layoutData: edgeRouterData,
        automaticEdgeGrouping: true,
        fixPorts: false,
        portAdjustmentPolicy: PortAdjustmentPolicy.NEVER
      });
    });

    return { knownEdges: [], unknownEdges: [] };
  }

  filterPortCandidates(
    graph: IGraph,
    node: INode,
    edge: IEdge,
    candidates: PortCandidate[]
  ): PortCandidate[] {
    for (let index = candidates.length - 1; index >= 0; index--) {
      let candidate = candidates[index];
      const ports = DiagramUtils.findClosestPorts(
        node.ports.toArray(),
        node.layout.center.add(new Point(candidate.xOffset, candidate.yOffset)),
        1
      );

      if (ports && ports.length > 0) {
        let edgesAt: IEdge[] = [];
        let allEdges = ports
          .map((port) => {
            return graph.edgesAt(port, 'all').filter((e) => e != edge);
          })
          .filter((e) => e.size > 0);
        if (allEdges.length > 0) {
          edgesAt = allEdges
            .reduce((m, b) => {
              m.append(...b);
              return m;
            })
            .toArray();
        }

        if (edgesAt.length > 0) {
          candidates.splice(index, 1);
        }
      }
    }
    return candidates;
  }

  getEdgeGroup(edge: IEdge): string {
    switch (edge.tag.name) {
      case 'StraightArrow':
      case 'ArcArrow':
        return edge.tag.name;
    }

    switch (edge.tag.style.visualType) {
      case EdgeVisualType.Curved:
        return 'Curved';
      case EdgeVisualType.Elbow:
        return 'Elbow';
      case EdgeVisualType.Straight:
        return 'Straight';
      case EdgeVisualType.Arc:
        return 'Arc';
    }
  }

  getEdgeRouterByGroup(edgeGroup: string): ILayoutAlgorithm {
    switch (edgeGroup) {
      case 'Straight':
      case 'StraightArrow':
        return this.getStraightEdgeRouter();
      case 'ArcArrow':
      case 'Arc':
        return this.getArcEdgeRouter();
      case 'Elbow':
        return this.getElbowEdgeRouter();
      case 'Curved':
        return this.getCurvedEdgeRouter();
      case 'Bezier':
        return this.getBezierEdgeRouter();
      default:
        return null;
    }
  }

  private getIgnoredLocation(node: INode): Point[] {
    const layout = node.layout;
    return [
      new Point(layout.x, layout.y),
      new Point(layout.x, layout.maxY),
      new Point(layout.maxX, layout.y),
      new Point(layout.maxX, layout.maxY),
      layout.center
    ];
  }

  getBezierEdgeRouter(): EdgeRouter {
    const edgeRouter = new EdgeRouter({
      scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
    });
    edgeRouter.defaultEdgeLayoutDescriptor.routingStyle =
      EdgeRouterEdgeRoutingStyle.CURVED;
    edgeRouter.rerouting = true;
    edgeRouter.maximumDuration = 500;
    edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;
    let curveFittingLayoutStage = new CurveFittingLayoutStage(edgeRouter);
    curveFittingLayoutStage.affectedEdgesDpKey =
      EdgeRouterScope.ROUTE_AFFECTED_EDGES;
    return edgeRouter;
  }

  getCurvedEdgeRouter(): EdgeRouter {
    const edgeRouter = new EdgeRouter({
      scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
    });
    edgeRouter.rerouting = true;
    edgeRouter.maximumDuration = 500;
    edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;
    return edgeRouter;
  }

  getElbowEdgeRouter(): EdgeRouter {
    const edgeRouter = new EdgeRouter({
      scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
    });
    edgeRouter.rerouting = true;
    edgeRouter.maximumDuration = 500;
    edgeRouter.minimumNodeToEdgeDistance = diagramConfig.grid.size * 2;
    return edgeRouter;
  }

  getArcEdgeRouter(): EdgeRouter {
    return new EdgeRouter({
      scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
    });
  }

  getStraightEdgeRouter(): ILayoutAlgorithm {
    return new JigsawStraightLineEdgeRouter(
      new EdgeRouter({
        scope: EdgeRouterScope.ROUTE_AFFECTED_EDGES
      })
    );
  }

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
  }
}
