import { Module } from 'vuex';

import Filter from '../search/filters/Filter';

// namespace
export const SEARCH_NAMESPACE = 'search';

// getters
export const GET_SEARCH_FILTERS = 'getSearchFilters';

// mutations
export const SET_SEARCH_FILTERS = 'setSearchFilters';

// actions ...

interface State {
  searchFilters: Filter[];
}

const searchModule: Module<State, any> = {
  namespaced: true,

  state: {
    searchFilters: []
  },

  getters: {
    [GET_SEARCH_FILTERS](state) {
      return state.searchFilters;
    }
  },

  mutations: {
    [SET_SEARCH_FILTERS](state, payload) {
      state.searchFilters = payload;
    }
  },

  actions: {}
};

export default searchModule;
