import config from '@/core/config/diagram.definition.config';
import { GraphComponent } from 'yfiles';

export default class PaletteItemOffsetHelper {
  private static readonly offsetIncrement = config.grid.size;
  private static readonly offsetStep = config.paletteItemDropOffsetStep;
  private static offsetX = 0;
  private static offsetY = 0;

  public static getOffsetX(): number {
    return this.offsetX * this.offsetIncrement;
  }

  public static getOffsetY(): number {
    return this.offsetY * this.offsetIncrement;
  }

  public static updatePaletteItemInsertOffset(
    graphComponent: GraphComponent,
    additionalOffsetIncrement = 0
  ): void {
    if (this.offsetReachedBounds(graphComponent)) {
      this.offsetX = 0;
      this.offsetY = 0;
    }

    this.offsetY += this.offsetStep + additionalOffsetIncrement;
  }

  private static offsetReachedBounds(graphComponent: GraphComponent): boolean {
    return (
      this.offsetXReachedBounds(graphComponent) ||
      this.offsetYReachedBounds(graphComponent)
    );
  }

  private static offsetYReachedBounds(graphComponent: GraphComponent): boolean {
    return (
      graphComponent.viewport.centerY -
        graphComponent.viewport.height / 4 +
        PaletteItemOffsetHelper.getOffsetY() >=
      graphComponent.viewport.maxY - graphComponent.viewport.height / 4
    );
  }

  private static offsetXReachedBounds(graphComponent: GraphComponent): boolean {
    return (
      PaletteItemOffsetHelper.getOffsetX() +
        graphComponent.viewport.minX +
        graphComponent.viewport.width /
          config.offsetRightFromCanvasLeftBound.large >=
      graphComponent.viewport.centerX - 150
    );
  }
}
