import { Features } from '@/core/common/Features';
import FeaturesService from '@/core/services/FeaturesService';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class FeaturesMixin extends Vue {
  hasFeature(feature: Features): boolean {
    return FeaturesService.hasFeature(feature);
  }

  get featureType(): typeof Features {
    return Features;
  }
}
