import axios, {
    AxiosResponse
} from 'axios';
import {
    GetTranslationsOutput,
    ZadarResponse,
    GetLanguagesOutput,
    GetLanguageForEditOutput,
    CreateOrUpdateLanguageInput,
    SetDefaultLanguageInput,
    PagedResultDtoOfLanguageTextListDto,
    UpdateLanguageTextInput
} from './models'
export default class LanguageApiService {
    public static getPublicTranslations(params ? : {
        targetCulture ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetTranslationsOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Language/GetPublicTranslations`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getTranslations(params ? : {
        targetCulture ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetTranslationsOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Language/GetTranslations`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getLanguages(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetLanguagesOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Language/GetLanguages`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getLanguageForEdit(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetLanguageForEditOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Language/GetLanguageForEdit`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrUpdateLanguage(body: CreateOrUpdateLanguageInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Language/CreateOrUpdateLanguage`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static deleteLanguage(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Language/DeleteLanguage`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static setDefaultLanguage(body: SetDefaultLanguageInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Language/SetDefaultLanguage`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getLanguageTexts(params ? : {
        maxResultCount ? : number,
        skipCount ? : number,
        sorting ? : string,
        sourceName ? : string,
        baseLanguageName ? : string,
        targetLanguageName ? : string,
        targetValueFilter ? : string,
        filterText ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfLanguageTextListDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Language/GetLanguageTexts`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static updateLanguageText(body: UpdateLanguageTextInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Language/UpdateLanguageText`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
}