import { Component, Vue } from 'vue-property-decorator';
import { EventBusActions } from '@/core/services/events/eventbus.service';

@Component({})
export default class QuickPanelMixin extends Vue {
  toggleQuickPanel(id: string): void {
    this.eventBusEmit(
      EventBusActions.TOGGLE_QUICK_PANEL,
      new QuickPanelVisualStateChangeEventArgs(id)
    );
  }

  showQuickPanel(id: string): void {
    this.eventBusEmit(
      EventBusActions.SHOW_QUICK_PANEL,
      new QuickPanelVisualStateChangeEventArgs(id)
    );
  }

  hideQuickPanel(id: string): void {
    this.eventBusEmit(
      EventBusActions.HIDE_QUICK_PANEL,
      new QuickPanelVisualStateChangeEventArgs(id)
    );
  }
}

export class QuickPanelVisualStateChangeEventArgs {
  constructor(
    public id: string,
    public isPanelShown?: boolean
  ) {}
}

export const QuickPanels = {
  DIAGRAM_DATA_PROPERTIES: 'quick_panel_element_data_properties',
  DIAGRAM_SEARCH: 'quick_panel_diagram_search',
  DIAGRAM_PALETTE: 'quick_panel_diagram_palette',
  DIAGRAM_ANNOTATION: 'quick_panel_diagram_annotation',
  DOCUMENT_PAGES: 'quick_panel_document_pages',
  SIDE_PANEL: 'quick_panel_side_panel'
};
