<template>
  <span
    :aria-disabled="disabled"
    :class="wraperClasses"
    class="inline-flex cursor-pointer aria-disabled:cursor-not-allowed"
    @click="$emit('click', $event)"
  >
    <input
      type="checkbox"
      class="app-switch relative h-14 w-24 min-w-24 cursor-[inherit] appearance-none rounded-full border text-24 outline-none transition"
      :class="switchClasses"
      :id="_uid"
      :checked="checked"
      :disabled="disabled"
      @input.prevent="inputHandler"
    />

    <label
      :for="_uid"
      :class="[labelOffsetClass]"
      class="mb-0 cursor-[inherit] text-14"
    >
      <slot />
    </label>
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppSwitch'
})
class AppSwitch extends Vue {
  @Prop({ default: null })
  value: string | number;

  @Model('change', { type: [Boolean, Array] })
  checked!: boolean | Array<string | number>;

  @Prop({ default: false, type: Boolean })
  alwaysColored: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: 'default' })
  variant: TSwitchVariants;

  @Prop({ default: 'center' })
  position: TSwitchPositions;

  @Prop({ default: 'ml-8' })
  labelOffsetClass: string;

  private _uid: string;

  get switchClasses() {
    const checkedIconClass = this.checked ? 'is-checked' : '';
    const alwaysColoredClass = this.alwaysColored ? 'is-colored' : '';
    const variantsClass = this.checked
      ? TSwitchVariantClasses[this.variant]
      : 'border-main-dark-20 bg-main-dark-20';

    let positionClass = '';
    if (this.position !== 'center') {
      positionClass = this.position === 'top' ? 'mt-5' : 'mb-5';
    }

    if (this.disabled) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        positionClass,
        'bg-main-dark-40 bg-opacity-60 border-main-dark-20'
      ];
    }

    if (this.alwaysColored) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        positionClass,
        TSwitchVariantClasses[this.variant]
      ];
    }

    return [checkedIconClass, alwaysColoredClass, positionClass, variantsClass];
  }

  get wraperClasses() {
    return [TSwitchPositionClasses[this.position]];
  }

  inputHandler() {
    if (this.disabled) {
      return;
    }

    this.$emit('change', !this.checked);
  }
}
export default AppSwitch;

export type TSwitchVariants = 'default' | 'purple' | 'cyan';
export type TSwitchPositions = 'center' | 'top' | 'bottom';

const TSwitchVariantClasses = {
  default: 'border-accent-purple bg-accent-purple focus:border-accent-purple',
  white: 'border-main-dark-10 bg-main-dark-10 focus:border-main-dark-10',
  cyan: 'border-accent-cyan bg-accent-cyan focus:border-accent-cyan'
};

const TSwitchPositionClasses = {
  center: 'items-center',
  top: 'items-start',
  bottom: 'items-end'
};
</script>

<style lang="scss" scoped>
.app-switch {
  &::before {
    content: '';
    @apply absolute inset-1 h-10 w-10 transform rounded-full transition;
  }
  &:is(.is-colored)::before {
    @apply bg-white;
  }
  &::before {
    @apply bg-white;
  }
  &.is-checked::before {
    @apply translate-x-10 bg-white;
  }
}
</style>
