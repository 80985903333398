import IDisposable from '@/core/common/IDisposable';
import { LayoutExecutor, Class, OrganicLayout, IGraph } from 'yfiles';
import EdgeServiceBase from './EdgeServiceBase';
import IDiagramTypeHelper from '../IDiagramTypeHelper';
import { BackgroundCorporateDiagramHelper } from './BackgroundCorporateDiagramHelper';
Class.ensure(LayoutExecutor);
Class.ensure(OrganicLayout);

export default class BackgroundEdgeService
  extends EdgeServiceBase
  implements IDisposable
{
  public static readonly $class: string = 'BackgroundEdgeService';
  private _diagramTypeHelper: BackgroundCorporateDiagramHelper = null;
  private _graph: IGraph = null;

  constructor(graph: IGraph) {
    super();
    this._graph = graph;
    this._diagramTypeHelper = new BackgroundCorporateDiagramHelper();
    this._diagramTypeHelper.init(this.graph);
  }

  get graph(): IGraph {
    return this._graph;
  }

  get diagramTypeHelper(): IDiagramTypeHelper {
    return this._diagramTypeHelper;
  }

  public dispose(): void {
    if (this.isDisposed) {
      return;
    }
    this._diagramTypeHelper.dispose();
    this.isDisposed = true;
  }
}
