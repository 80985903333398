import Vue from 'vue';
import { IEdge, ILabelOwner, IModelItem, INode } from 'yfiles';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import appConsts from '@/core/config/appConsts';
import NodeIndicatorItem from '@/core/services/graph/NodeIndicatorItem';
import {
  DataPropertyDefinitionItemDto,
  QuickStartState,
  SidebarCategory
} from '@/api/models';
import QuickBuildButtons from '@/v2/services/graph-service/node-button-providers/QuickBuildButtons';
import { BuildDirection } from '@/core/services/graph/BuildDirection';
import { QuickBuildSettingsChangedEvent } from '@/core/services/store/document.module';
import SystemEntityTypes from '@/core/services/corporate/SystemEntityTypes';
import { AutomationSelectors } from '@/core/services/analytics/AutomationUtils';

const tutorialEventBus = new Vue();

export const TutorialEvents = {
  // move items
  DIAGRAM_NODES_MOVED: 'tour-nodes-moved',
  DIAGRAM_EDGES_MOVED: 'tour-edges-moved',
  DIAGRAM_ITEMS_MOVED: 'tour-items-moved',
  DIAGRAM_TEXT_BOXES_MOVED: 'tour-tb-moved',
  // selection
  DIAGRAM_SELECTION_CHANGED: 'tour-selection-changed',
  DIAGRAM_NODE_SELECTION_CHANGED: 'tour-node-selection-changed',
  DIAGRAM_EDGE_SELECTION_CHANGED: 'tour-edge-selection-changed',
  // create items
  NODE_CREATED: 'tour-node-created',
  NODE_CREATED_CORPORATE: 'tour-node-created-corporate',
  NODE_CREATED_PARTNERSHIP: 'tour-node-created-partnership',
  NODE_CREATED_TRUST: 'tour-node-created-trust',
  EDGE_CREATED: 'tour-edge-created',
  EDGE_CREATED_OWNERSHIP: 'tour-edge-created-ownership',
  EDGE_CREATED_CONTRACT: 'tour-edge-created-contract',
  EDGE_CREATED_CASHFLOW: 'tour-edge-created-cashflow',
  // layout
  AUTO_LAYOUT_POPUP: 'tour-auto-layout-popup',
  // edit label
  START_EDIT_ANY_LABEL: 'tour-start-edit-any-label',
  START_EDIT_EDGE_LABEL: 'tour-start-edit-edge-label',
  START_EDIT_NODE_LABEL: 'tour-start-edit-node-label',
  LABEL_TEXT_INPUT: 'tour-label-text-input',
  NODE_LABEL_TEXT_INPUT: 'tour-node-label-text-input',
  EDGE_LABEL_TEXT_INPUT: 'tour-edge-label-text-input',
  EDIT_ANY_LABEL: 'tour-edit-any-label',
  EDIT_NODE_LABEL: 'tour-edit-node-label',
  EDIT_EDGE_LABEL: 'tour-edit-edge-label',
  // export
  EXPORT_OPEN_MENU: 'export-open-menu',
  EXPORT_DOCUMENT: 'export-document',
  EXPORT_DOCUMENT_PDF: 'export-document-pdf',
  EXPORT_DOCUMENT_PNG: 'export-document-png',
  EXPORT_DOCUMENT_SVG: 'export-document-svg',
  EXPORT_DOCUMENT_PPT: 'export-document-ppt',
  EXPORT_DOCUMENT_VSDX: 'export-document-vsdx',
  // color
  FILL_CHANGED: 'tour-fill-changed',
  STROKE_CHANGED: 'tour-stroke-changed',
  // groups
  NODES_GROUPED: 'tour-nodes-grouped',
  NODES_UNGROUPED: 'tour-nodes-ungrouped',
  NODE_GROUP_FILL_CHANGED: 'tour-node-group-fill-changed',
  NODE_GROUP_OUTLINE_CHANGED: 'tour-node-group-outline-changed',
  NODE_GROUP_MARGIN_CHANGED: 'tour-node-group-margin-changed',
  // node tools
  NODE_TOOLS_LINES_MENU: 'tour-node-tools-lines-menu',
  NODE_TOOLS_SHAPE_CYCLE: 'tour-node-tools-shape-cycle',
  NODE_TOOLS_EDIT_TEXT: 'tour-node-tools-edit-text',
  // node decorator
  NODE_JURISDICTION_CHANGED: 'tour-node-jurisdiction-changed',
  NODE_STATE_CHANGED: 'tour-node-state-changed',
  NODE_SYMBOL_CHANGED: 'tour-node-symbol-changed',
  // quick-(build|start) buttons
  QUICK_BUILD_BUTTON_CLICK_ANY: 'tour-qb-button-click-any',
  QUICK_BUILD_BUTTON_CLICK_TOP: 'tour-qb-button-click-t',
  QUICK_BUILD_BUTTON_CLICK_RIGHT: 'tour-qb-button-click-r',
  QUICK_BUILD_BUTTON_CLICK_BOTTOM: 'tour-qb-button-click-b',
  QUICK_BUILD_BUTTON_CLICK_LEFT: 'tour-qb-button-click-l',
  QUICK_START_BUTTON_CLICK_ANY: 'tour-qs-button-click-any',
  QUICK_START_BUTTON_CLICK_TOP: 'tour-qs-button-click-t',
  QUICK_START_BUTTON_CLICK_RIGHT: 'tour-qs-button-click-r',
  QUICK_START_BUTTON_CLICK_BOTTOM: 'tour-qs-button-click-b',
  QUICK_START_BUTTON_CLICK_LEFT: 'tour-qs-button-click-l',
  // quick-start
  QUICK_START_ACTIVATE: 'tour-qs-activate',
  QUICK_START_DEACTIVATE: 'tour-qs-deactivate',
  QUICK_START_SETTINGS_SHOW_ADVANCED: 'tour-qs-settings-show-advanced',
  QUICK_START_SETTINGS_NODE_DISTANCE: 'tour-qs-settings-node-distance',
  QUICK_START_SETTINGS_VERTICAL_DISTANCE: 'tour-qs-settings-v-distance',
  QUICK_START_SETTINGS_HORIZONTAL_DISTANCE: 'tour-qs-settings-h-distance',
  // sidebar buttons
  SIDEBAR_BUTTON_CLICK_TEXT: 'tour-sidebar-button-text',
  SIDEBAR_BUTTON_CLICK_SHAPES: 'tour-sidebar-button-shapes',
  SIDEBAR_BUTTON_CLICK_LINES: 'tour-sidebar-button-lines',
  SIDEBAR_BUTTON_CLICK_LOGO: 'tour-sidebar-button-logo',
  SIDEBAR_BUTTON_CLICK_LEGEND: 'tour-sidebar-button-legend',
  SIDEBAR_BUTTON_CLICK_THEMES: 'tour-sidebar-button-themes',
  // Other
  SHOW_CONTEXT_MENU: 'tour-show-context-menu',
  CHANGE_EDGE_PORT: 'tour-change-edge-port',
  CLICK_LINES_MENU_ITEM: 'click-lines-menu-item'
};

export default class TutorialActionMediator {
  private static eventsMap = new Map();

  private static buildMap(): void {
    TutorialActionMediator.itemsDragged();
    TutorialActionMediator.selectionChanged();
    TutorialActionMediator.itemCreated();
    TutorialActionMediator.autoLayoutPopup();
    TutorialActionMediator.startLabelEdit();
    TutorialActionMediator.labelTextInput();
    TutorialActionMediator.stopLabelEdit();
    TutorialActionMediator.exportDocument();
    TutorialActionMediator.colorChanged();
    TutorialActionMediator.groupProperties();
    TutorialActionMediator.nodeTools();
    TutorialActionMediator.nodeDecorator();
    TutorialActionMediator.quickBuildActions();
    TutorialActionMediator.quickStart();
    TutorialActionMediator.sidebarButtons();
    TutorialActionMediator.uncategorized();
  }

  public static registerEvents(): void {
    TutorialActionMediator.destroy();

    TutorialActionMediator.buildMap();
    TutorialActionMediator.eventsMap.forEach(
      (listener: (args: any) => void, event: string) => {
        EventBus.$on(event, listener);
      }
    );

    // Actions bound to DOM events
    TutorialActionMediator.handleClickEvent();
  }

  public static $on(event?: string, callback?: (p?: any) => void): void {
    tutorialEventBus.$on(event, callback);
  }

  public static $off(event?: string, callback?: (p?: any) => void): void {
    tutorialEventBus.$off(event, callback);
  }

  public static destroy(): void {
    tutorialEventBus.$off();
    TutorialActionMediator.eventsMap.forEach(
      (listener: (args: any) => void, event: string) => {
        EventBus.$off(event, listener);
      }
    );
    TutorialActionMediator.eventsMap.clear();

    // Actions bound to DOM events
    TutorialActionMediator.handleClickEvent(true);
  }

  private static itemsDragged(): void {
    const listener = (evt: IModelItem[]): void => {
      if (evt.some((x) => INode.isInstance(x))) {
        tutorialEventBus.$emit(TutorialEvents.DIAGRAM_NODES_MOVED);
      }

      if (evt.some((x) => IEdge.isInstance(x))) {
        tutorialEventBus.$emit(TutorialEvents.DIAGRAM_EDGES_MOVED);
      }

      if (evt.some((x) => INode.isInstance(x) && DiagramUtils.isTextBox(x))) {
        tutorialEventBus.$emit(TutorialEvents.DIAGRAM_TEXT_BOXES_MOVED);
      }

      tutorialEventBus.$emit(TutorialEvents.DIAGRAM_ITEMS_MOVED);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_ITEMS_DRAGGED,
      listener
    );
  }

  private static selectionChanged(): void {
    const listener = (evt: { nodes: INode[]; edges: IEdge[] }): void => {
      tutorialEventBus.$emit(
        TutorialEvents.DIAGRAM_NODE_SELECTION_CHANGED,
        evt?.nodes?.length ?? 0
      );
      tutorialEventBus.$emit(
        TutorialEvents.DIAGRAM_EDGE_SELECTION_CHANGED,
        evt?.edges?.length ?? 0
      );
      tutorialEventBus.$emit(
        TutorialEvents.DIAGRAM_SELECTION_CHANGED,
        (evt?.edges?.length ?? 0) + (evt?.nodes?.length ?? 0)
      );
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_SELECTION_CHANGED,
      listener
    );
  }

  private static itemCreated(): void {
    const createNodeListener = (item?: IModelItem): void => {
      switch (item?.tag?.name) {
        case SystemEntityTypes.NODE_CORPORATE:
          tutorialEventBus.$emit(TutorialEvents.NODE_CREATED_CORPORATE);
          break;
        case SystemEntityTypes.NODE_PARTNERSHIP:
          tutorialEventBus.$emit(TutorialEvents.NODE_CREATED_PARTNERSHIP);
          break;
        case SystemEntityTypes.NODE_TRUST:
          tutorialEventBus.$emit(TutorialEvents.NODE_CREATED_TRUST);
          break;
        default:
          tutorialEventBus.$emit(TutorialEvents.NODE_CREATED);
      }
    };
    const createEdgeListener = (item?: IModelItem): void => {
      switch (item?.tag?.name) {
        case SystemEntityTypes.EDGE_OWNERSHIP:
          tutorialEventBus.$emit(TutorialEvents.EDGE_CREATED_OWNERSHIP);
          break;
        case SystemEntityTypes.EDGE_CONTRACT:
          tutorialEventBus.$emit(TutorialEvents.EDGE_CREATED_CONTRACT);
          break;
        case SystemEntityTypes.EDGE_CASHFLOW:
          tutorialEventBus.$emit(TutorialEvents.EDGE_CREATED_CASHFLOW);
          break;
        default:
          tutorialEventBus.$emit(TutorialEvents.EDGE_CREATED);
      }
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODE_CREATED,
      createNodeListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_EDGE_CREATED,
      createEdgeListener
    );
  }

  private static autoLayoutPopup(): void {
    const listener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.AUTO_LAYOUT_POPUP);
    };
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DOCUMENT_DIAGRAM_AUTO_LAYOUT_POPUP,
      listener
    );
  }

  private static startLabelEdit(): void {
    const listener = (data: { owner: ILabelOwner }): void => {
      let event = TutorialEvents.START_EDIT_EDGE_LABEL;
      if (INode.isInstance(data.owner)) {
        event = TutorialEvents.START_EDIT_NODE_LABEL;
      }

      tutorialEventBus.$emit(event);
      tutorialEventBus.$emit(TutorialEvents.START_EDIT_ANY_LABEL);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.LABEL_EDITING_STARTED,
      listener
    );
  }

  private static labelTextInput(): void {
    const listener = (data: { owner: ILabelOwner; text: string }): void => {
      let event = TutorialEvents.EDGE_LABEL_TEXT_INPUT;
      if (INode.isInstance(data.owner)) {
        event = TutorialEvents.NODE_LABEL_TEXT_INPUT;
      }

      tutorialEventBus.$emit(event, data.text?.length ?? 0);
      tutorialEventBus.$emit(
        TutorialEvents.LABEL_TEXT_INPUT,
        data.text?.length ?? 0
      );
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.LABEL_TEXT_INPUT,
      listener
    );
  }

  private static stopLabelEdit(): void {
    const listener = (data: { owner: ILabelOwner; text: string }): void => {
      let event = TutorialEvents.EDIT_EDGE_LABEL;
      if (INode.isInstance(data.owner)) {
        event = TutorialEvents.EDIT_NODE_LABEL;
      }

      tutorialEventBus.$emit(event, data.text?.length ?? 0);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.LABEL_EDITING_STOPPED,
      listener
    );
  }

  private static exportDocument(): void {
    const listener = (data: { format: ExportFormat }): void => {
      let event = '';
      switch (data.format) {
        case ExportFormat.Pdf:
          event = TutorialEvents.EXPORT_DOCUMENT_PDF;
          break;
        case ExportFormat.Png:
          event = TutorialEvents.EXPORT_DOCUMENT_PNG;
          break;
        case ExportFormat.Svg:
          event = TutorialEvents.EXPORT_DOCUMENT_SVG;
          break;
        case ExportFormat.PowerPoint:
          event = TutorialEvents.EXPORT_DOCUMENT_PPT;
          break;
        case ExportFormat.Visio:
          event = TutorialEvents.EXPORT_DOCUMENT_VSDX;
          break;
      }

      if (event) {
        tutorialEventBus.$emit(event, data.format);
      }
      tutorialEventBus.$emit(TutorialEvents.EXPORT_DOCUMENT);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DOCUMENT_EXPORT_CONFIRMED,
      listener
    );
  }

  private static colorChanged(): void {
    const fillChangedListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.FILL_CHANGED);
    };
    const strokeChangedListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.STROKE_CHANGED);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_SELECTION_FILL_CHANGED,
      fillChangedListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_SELECTION_STROKE_CHANGED,
      strokeChangedListener
    );
  }

  private static groupProperties(): void {
    const nodesGroupedListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODES_GROUPED);
    };
    const nodesUngroupedListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODES_UNGROUPED);
    };
    const groupFillListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_GROUP_FILL_CHANGED);
    };
    const strokeFillListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_GROUP_OUTLINE_CHANGED);
    };
    const groupMarginListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_GROUP_MARGIN_CHANGED);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODES_GROUPED,
      nodesGroupedListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODES_UNGROUPED,
      nodesUngroupedListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_GROUP_FILL_CHANGED,
      groupFillListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_GROUP_OUTLINE_CHANGED,
      strokeFillListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_GROUP_MARGIN_CHANGED,
      groupMarginListener
    );
  }

  private static nodeTools(): void {
    const createEdgeListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_TOOLS_LINES_MENU);
    };
    const shapeCycleListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_TOOLS_SHAPE_CYCLE);
    };
    const editTextListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.NODE_TOOLS_EDIT_TEXT);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.TOGGLE_EDGE_CREATION_MENU,
      createEdgeListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.TOGGLE_NODE_TYPE,
      shapeCycleListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.NODE_TOOLS_EDIT_TEXT,
      editTextListener
    );
  }

  private static nodeDecorator(): void {
    const jurisdictionListener = (
      definitionItem: DataPropertyDefinitionItemDto
    ): void => {
      tutorialEventBus.$emit(
        TutorialEvents.NODE_JURISDICTION_CHANGED,
        definitionItem.dataPropertyDefinitionId ===
          appConsts.JURISDICTION_DEFINITION_ID
      );
    };

    const stateListener = (
      definitionItem: DataPropertyDefinitionItemDto
    ): void => {
      tutorialEventBus.$emit(
        TutorialEvents.NODE_STATE_CHANGED,
        definitionItem.dataPropertyDefinitionId ===
          appConsts.STATE_DEFINITION_ID
      );
    };

    const symbolListener = (decorator: NodeIndicatorItem): void => {
      const hasValue =
        decorator.definitionId > appConsts.SQUARE_DEFINITION_ID &&
        decorator.definitionId < appConsts.STATE_DEFINITION_ID;
      tutorialEventBus.$emit(TutorialEvents.NODE_SYMBOL_CHANGED, hasValue);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODE_JURISDICTION_CHANGED,
      jurisdictionListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODE_STATE_CHANGED,
      stateListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_NODE_SYMBOL_CHANGED,
      symbolListener
    );
  }

  private static quickBuildActions(): void {
    const listener = (ev: {
      node: INode;
      locationSide: QuickBuildButtons;
    }): void => {
      let event = '';
      switch (ev.locationSide) {
        case QuickBuildButtons.TOP:
          event = TutorialEvents.QUICK_BUILD_BUTTON_CLICK_TOP;
          break;
        case QuickBuildButtons.RIGHT:
          event = TutorialEvents.QUICK_BUILD_BUTTON_CLICK_RIGHT;
          break;
        case QuickBuildButtons.BOTTOM:
          event = TutorialEvents.QUICK_BUILD_BUTTON_CLICK_BOTTOM;
          break;
        case QuickBuildButtons.LEFT:
          event = TutorialEvents.QUICK_BUILD_BUTTON_CLICK_LEFT;
          break;
      }

      if (event) {
        tutorialEventBus.$emit(event, true);
      }
      tutorialEventBus.$emit(TutorialEvents.QUICK_BUILD_BUTTON_CLICK_ANY, true);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.QUICK_BUILD_NODE_CREATED,
      listener
    );
  }

  private static quickStart(): void {
    // QS Activate / Deactivate
    const toggleQSListener = (state: QuickStartState): void => {
      if (state === QuickStartState.InProgress) {
        tutorialEventBus.$emit(TutorialEvents.QUICK_START_ACTIVATE);
      }

      if (state === QuickStartState.Complete) {
        tutorialEventBus.$emit(TutorialEvents.QUICK_START_DEACTIVATE);
      }
    };

    // QS Buttons
    const clickQSButtonsListener = (ev: {
      node: INode;
      direction: BuildDirection;
    }): void => {
      switch (ev.direction) {
        case 'up':
          tutorialEventBus.$emit(
            TutorialEvents.QUICK_START_BUTTON_CLICK_TOP,
            true
          );
          break;
        case 'right':
          tutorialEventBus.$emit(
            TutorialEvents.QUICK_START_BUTTON_CLICK_RIGHT,
            true
          );
          break;
        case 'down':
          tutorialEventBus.$emit(
            TutorialEvents.QUICK_START_BUTTON_CLICK_BOTTOM,
            true
          );
          break;
        case 'left':
          tutorialEventBus.$emit(
            TutorialEvents.QUICK_START_BUTTON_CLICK_LEFT,
            true
          );
          break;
      }

      tutorialEventBus.$emit(TutorialEvents.QUICK_START_BUTTON_CLICK_ANY, true);
    };

    // QS Settings
    const changeQSSettingsLister = (
      ev: QuickBuildSettingsChangedEvent
    ): void => {
      if (ev.nodeDistanceChanged) {
        tutorialEventBus.$emit(
          TutorialEvents.QUICK_START_SETTINGS_NODE_DISTANCE
        );
      }

      if (ev.verticalDistanceChanged) {
        tutorialEventBus.$emit(
          TutorialEvents.QUICK_START_SETTINGS_VERTICAL_DISTANCE
        );
      }

      if (ev.horizontalDistanceChanged) {
        tutorialEventBus.$emit(
          TutorialEvents.QUICK_START_SETTINGS_HORIZONTAL_DISTANCE
        );
      }

      if (ev.showAdvancedLayoutSettings) {
        tutorialEventBus.$emit(
          TutorialEvents.QUICK_START_SETTINGS_SHOW_ADVANCED
        );
      }
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.QUICK_BUILD_CHANGED,
      toggleQSListener
    );

    TutorialActionMediator.eventsMap.set(
      EventBusActions.QUICK_START_NODE_CREATED,
      clickQSButtonsListener
    );

    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_QUICK_BUILD_SETTINGS_CHANGED,
      changeQSSettingsLister
    );
  }

  private static sidebarButtons(): void {
    const sidebarButtonsListener = (category: SidebarCategory): void => {
      switch (category) {
        case SidebarCategory.Text:
          tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_TEXT);
          break;
        case SidebarCategory.Shapes:
          tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_SHAPES);
          break;
        case SidebarCategory.Lines:
          tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_LINES);
          break;
        case SidebarCategory.Themes:
          tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_THEMES);
          break;
      }
    };

    const logoListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_LOGO);
    };

    const legendListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.SIDEBAR_BUTTON_CLICK_LEGEND);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.TOGGLE_SIDEBAR,
      sidebarButtonsListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.SIDE_BAR_LOGO_CLICKED,
      logoListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DOCUMENT_TOGGLE_LEGEND,
      legendListener
    );
  }

  private static clickListener = (event: Event): void => {
    if (!event.target || !(event.target instanceof Element)) {
      return;
    }

    let target = event.target as HTMLElement;
    const automationIds: string[] = [];

    while (target) {
      if (target.hasAttribute('data-automation-id')) {
        const currentAutomationId = target.getAttribute('data-automation-id');
        if (currentAutomationId) {
          automationIds.push(currentAutomationId);
        }
      }
      target = target.parentElement;
    }

    if (!automationIds.length) {
      return;
    }

    let automationId;
    if (automationIds.includes(AutomationSelectors.RB_MENU_EXPORT_OPTIONS)) {
      tutorialEventBus.$emit(TutorialEvents.EXPORT_OPEN_MENU);
    } else if (
      (automationId = automationIds.find((automationId) =>
        automationId.startsWith(AutomationSelectors.EDGE_MENU)
      ))
    ) {
      tutorialEventBus.$emit(
        TutorialEvents.CLICK_LINES_MENU_ITEM,
        automationId.substring(AutomationSelectors.EDGE_MENU.length + 1) // line name
      );
    }
  };

  private static handleClickEvent(unregister?: boolean): void {
    if (unregister) {
      document.removeEventListener(
        'click',
        TutorialActionMediator.clickListener
      );
    } else {
      document.addEventListener('click', TutorialActionMediator.clickListener);
    }
  }

  private static uncategorized(): void {
    const contextMenuListener = (show: boolean): void => {
      if (show) {
        tutorialEventBus.$emit(TutorialEvents.SHOW_CONTEXT_MENU);
      }
    };
    const edgePortListener = (): void => {
      tutorialEventBus.$emit(TutorialEvents.CHANGE_EDGE_PORT);
    };

    TutorialActionMediator.eventsMap.set(
      EventBusActions.CONTEXT_MENU_TOGGLE,
      contextMenuListener
    );
    TutorialActionMediator.eventsMap.set(
      EventBusActions.DIAGRAM_EDGE_PORT_CHANGED,
      edgePortListener
    );
  }
}
