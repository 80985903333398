import { DiagramDto } from '@/api/models';
import CacheType from '@/core/services/caching/CacheType';
import CachingService from '@/core/services/caching/CachingService';
import ILegendDefinition from './ILegendDefinition';

export default class LegendSerializer {
  public static deserializeFromDiagram(diagram: DiagramDto): ILegendDefinition {
    const cacheKey = CachingService.generateKey(
      CacheType.LegendDefinition,
      diagram.cacheKey
    );

    return CachingService.getOrSet<ILegendDefinition>(cacheKey, () => {
      return { data: this.deserializeDefinition(diagram.legend) };
    });
  }

  public static deserializeDefinition(legend: string): ILegendDefinition {
    if (!legend) {
      return null;
    }
    return <ILegendDefinition>JSON.parse(legend);
  }

  public static serializeDefinition(definition: ILegendDefinition): string {
    return JSON.stringify(definition);
  }
}
