import IDisposable from '@/core/common/IDisposable';
import { CommandHandlerType } from './CommandHandlerType';
import { CommandType } from './CommandType';

export default abstract class CommandHandlerBase implements IDisposable {
  protected children: CommandHandlerBase[] = [];
  protected parent: CommandHandlerBase;
  public abstract readonly type: CommandHandlerType;
  public isDisposed: boolean;

  public dispose(): void {
    if (this.isDisposed) {
      return;
    }

    this.children.forEach((c) => c.dispose());
    this.isDisposed = true;
  }

  public isChildOf(commandHandler: CommandHandlerBase): boolean {
    return this.parent === commandHandler;
  }

  public setParent(commandHandler: CommandHandlerBase): void {
    this.parent = commandHandler;
  }

  public addChild(commandHandler: CommandHandlerBase): void {
    commandHandler.setParent(this);
    this.children.push(commandHandler);
  }

  public getChildren(): CommandHandlerBase[] {
    return [...this.children];
  }

  public abstract executeCommand(commandType: CommandType, value): void;
  public abstract publishAllValues(): void;
  public abstract release(
    nextCommandHandler?: CommandHandlerBase
  ): CommandHandlerBase | null;
}
