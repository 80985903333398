import cloneDeep from 'lodash/cloneDeep';
import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';

export default class JsonExportProvider implements IExportProvider {
  private _fileExtension = 'jgy';
  private _mimeType = 'application/json';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const document = cloneDeep(options.document);
    delete document.id;
    delete document.ownerUserId;
    delete document.creatorUserId;
    delete document.creationTime;
    delete document.lastModifierUserId;
    delete document.lastModificationTime;
    delete document.lockedByUser;
    delete document.lockedCreated;
    delete document.defaultAccessLevel;
    delete document.access;
    delete document.accessRequests;
    delete document.isDeleted;
    delete document.importProcessed;
    delete document.thumbFileAttachmentId;
    const json = JSON.stringify(document, null, 4);

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: new Blob([json], { type: this._mimeType })
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    throw new Error('Not supported');
  }
}
