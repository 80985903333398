export default class LocalStorageService {
  public static hasKey(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  public static removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  public static getItem<T extends {}>(key: string): T | undefined {
    const item: string | null = localStorage.getItem(key);

    try {
      return item !== null ? (JSON.parse(item) as T) : undefined;
    } catch (error) {
      return undefined;
    }
  }

  public static setItem<T extends {}>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public static clear(): void {
    localStorage.clear();
  }
}
