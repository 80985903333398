import ISpecification from './ISpecification';

export default class OrSpecification implements ISpecification {
  private conditions: ISpecification[];

  constructor(conditions: ISpecification[]) {
    this.conditions = conditions;
  }

  public getCandidateScore(candidate: any): number {
    let resultsSum = 0;
    this.conditions.forEach((c) => {
      resultsSum += c.getCandidateScore(candidate);
    });
    return resultsSum;
  }
}
