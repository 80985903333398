const consts = {
  JURISDICTION_DEFINITION_ID: 1,
  SQUARE_DEFINITION_ID: 2,
  TRIANGLE_DEFINITION_ID: 3,
  CIRCLE_DEFINITION_ID: 4,
  CROSS_DEFINITION_ID: 5,
  PLUS_DEFINITION_ID: 6,
  STAR_1_DEFINITION_ID: 7,
  STAR_2_DEFINITION_ID: 8,
  STAR_3_DEFINITION_ID: 9,
  DAGGER_DEFINITION_ID: 10,
  STATE_DEFINITION_ID: 11,
  SYMBOLS_DEFINITION_IDS: []
};
consts.SYMBOLS_DEFINITION_IDS = [
  consts.SQUARE_DEFINITION_ID,
  consts.TRIANGLE_DEFINITION_ID,
  consts.CIRCLE_DEFINITION_ID,
  consts.CROSS_DEFINITION_ID,
  consts.PLUS_DEFINITION_ID,
  consts.STAR_1_DEFINITION_ID,
  consts.STAR_2_DEFINITION_ID,
  consts.STAR_3_DEFINITION_ID,
  consts.DAGGER_DEFINITION_ID
];
export default consts;
