import { waitUntil } from '../utils/common.utils';
import coreStyles from '@/assets/sass/styles.scss';
import pageContentStyles from '@/assets/sass/editor/_page-content.scss';
import BackgroundDomContainerType from './BackgroundDomContainerType';

class BackgroundDomService {
  private readonly isDebug = false;
  private readonly defaultContainerType =
    BackgroundDomContainerType.PageContentStyles;
  private readonly containerSize = '3000px';
  private readonly containers: Record<BackgroundDomContainerType, Node> =
    {} as Record<BackgroundDomContainerType, Node>;
  private backgroundIFrame: HTMLIFrameElement;

  // eslint-disable-next-line no-undef
  private get window(): Window & typeof globalThis {
    return this.backgroundIFrame.contentWindow.window;
  }

  private get document(): Document {
    return this.backgroundIFrame.contentDocument;
  }

  constructor() {
    this.init();
  }

  public async init(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.backgroundIFrame) {
        this.backgroundIFrame.remove();
      }
      this.backgroundIFrame = this.createBackgroundIFrame();
      window.document.body.appendChild(this.backgroundIFrame);
      this.document.open();
      this.document.write(
        '<!DOCTYPE html><html><head></head><body></body></html>'
      );
      this.document.close();

      this.createContainers();
      this.copyStyles();

      let fontsLoaded = false;
      this.document.fonts.ready.then(() => {
        fontsLoaded = true;
      });
      // if for some reason fonts would not be loaded, we make sure that after 2s we resolve the promise.
      waitUntil(() => fontsLoaded, 2000).then((timeout) => {
        if (timeout) {
          console.debug('[BackgroundDomService] Font loading timeout');
        }
        resolve();
      });
    });
  }

  public createElement(tagName: string): HTMLElement {
    return this.document.createElement(tagName);
  }

  public appendElement(
    element: Element,
    containerType: BackgroundDomContainerType = this.defaultContainerType
  ): void {
    this.containers[containerType].appendChild(element);
  }

  public removeElement(element: Element): void {
    element.remove();
  }

  public getElementById(elementId: string): HTMLElement {
    return this.document.getElementById(elementId);
  }

  public getSelection(
    containerType: BackgroundDomContainerType = this.defaultContainerType
  ): Selection {
    if (this.containers[containerType] instanceof ShadowRoot) {
      return (this.containers[containerType] as Document).getSelection();
    }
    return this.window.getSelection();
  }

  public isHtmlElement(obj: any): obj is HTMLElement {
    return (
      obj instanceof window.HTMLElement ||
      obj instanceof this.window.HTMLElement
    );
  }

  public updateParentStyleElements(): void {
    this.cleanupStyleElements();
    this.copyParentStyleElements();
  }

  private copyStyles(): void {
    // Remove all existing styles and links from an iframe
    this.cleanupStyles();

    // Copy parent styles and links to an iframe
    this.copyParentStyleElements();
    this.copyParentStyleLinks();
    this.copyParentFonts();
    this.copyParentBodyStyles();
  }

  private cleanupStyles(): void {
    this.cleanupStyleElements();
    this.cleanupLinkElements();
  }

  private cleanupStyleElements(): void {
    const iframeStyles = this.document.head.getElementsByTagName('style');
    for (const iframeStyle of [...iframeStyles]) {
      iframeStyle.remove();
    }
  }

  private cleanupLinkElements(): void {
    const iframeLinks = this.document.head.querySelectorAll(
      'link[rel="stylesheet"], link[as="font"]'
    );
    for (const iframeLink of [...iframeLinks]) {
      iframeLink.remove();
    }
  }

  private copyParentStyleElements(): void {
    const parentStyles = window.document.head.getElementsByTagName('style');
    for (const parentStyle of parentStyles) {
      const iframeStyle = this.document.createElement('style');
      iframeStyle.innerHTML = parentStyle.innerHTML;
      this.document.head.appendChild(iframeStyle);
    }
  }

  private copyParentStyleLinks(): void {
    const parentLinks = window.document.head.querySelectorAll(
      'link[rel="stylesheet"]'
    );
    for (const parentLink of parentLinks) {
      const iframeLink = this.document.createElement('link');
      iframeLink.as = 'style';
      iframeLink.rel = 'stylesheet';
      iframeLink.crossOrigin = 'anonymous';
      iframeLink.href = (parentLink as HTMLLinkElement).href;
      this.document.head.appendChild(iframeLink);
    }
  }

  private copyParentFonts(): void {
    const existingFonts = [...this.document.fonts];
    const parentFonts = window.document.fonts;
    for (const parentFont of parentFonts) {
      if (
        existingFonts.some(
          (f) =>
            f.family === parentFont.family &&
            f.weight === parentFont.weight &&
            f.style === parentFont.style &&
            f.status === parentFont.status
        )
      ) {
        continue;
      }
      this.document.fonts.add(parentFont);
    }
  }

  private copyParentBodyStyles(): void {
    const bodyStyle = window.document.body.getAttribute('style');
    if (!bodyStyle) {
      return;
    }
    for (const containerName in this.containers) {
      let container = this.containers[containerName];
      // For shadow dom containers
      if (container.host) {
        container = container.host;
      }
      const containerStyle = container.getAttribute('style');
      const newContainerStyle = containerStyle + '; ' + bodyStyle;
      container.setAttribute('style', newContainerStyle);
    }
  }

  private createBackgroundIFrame(): HTMLIFrameElement {
    const iframe = window.document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.margin = '0';
    iframe.style.padding = '0';

    if (!this.isDebug) {
      iframe.style.top = '-30000px';
      iframe.width = '0';
      iframe.height = '0';
    } else {
      iframe.style.top = '1000px';
      iframe.width = '100%';
      iframe.height = '100%';
    }

    return iframe;
  }

  private createContainers(): void {
    this.createAllStylesContainer();
    this.createCoreStylesContainer();
    this.createPageContentStylesContainer();
  }

  private createContainer(): HTMLElement {
    const container = window.document.createElement('div');
    if (!this.isDebug) {
      container.style.overflow = 'hidden';
    }
    container.style.position = 'absolute';
    container.style.width = this.containerSize;
    container.style.height = this.containerSize;
    return container;
  }

  private createAllStylesContainer(): void {
    const container = this.createContainer();
    this.document.body.appendChild(container);
    this.containers[BackgroundDomContainerType.AllStyles] = container;
  }

  private createCoreStylesContainer(): void {
    const container = this.createContainer();
    const shadowDom = container.attachShadow({ mode: 'open' });
    const style = this.document.createElement('style');
    style.innerHTML = coreStyles;
    shadowDom.appendChild(style);
    this.document.body.appendChild(container);
    this.containers[BackgroundDomContainerType.CoreStyles] = shadowDom;
  }

  private createPageContentStylesContainer(): void {
    const container = this.createContainer();
    const shadowDom = container.attachShadow({ mode: 'open' });
    const style = this.document.createElement('style');
    style.innerHTML = pageContentStyles;
    shadowDom.appendChild(style);
    this.document.body.appendChild(container);
    this.containers[BackgroundDomContainerType.PageContentStyles] = shadowDom;
  }
}

const instance = new BackgroundDomService();
export default instance;
