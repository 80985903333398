import PageLayoutHandler from '../export/PageLayoutHandler';
import BackgroundCkEditorService from './BackgroundCkEditorService';
import PageLayoutEditorConfig from '@/core/config/ckEditor/pageLayoutEditorConfig';

export default class PageLayoutBackgroundCkEditorService extends BackgroundCkEditorService {
  constructor() {
    super(PageLayoutEditorConfig.getConfig());
  }

  public get content(): string {
    return PageLayoutHandler.sanitizeEditorOutput(super.content);
  }

  public set content(value: string) {
    super.content = PageLayoutHandler.sanitizeEditorInput(value);
  }

  public get contentWithHighlights(): string {
    return PageLayoutHandler.sanitizeEditorOutput(super.contentWithHighlights);
  }

  public get pageId(): number {
    return this.editor.pageId;
  }

  public set pageId(value: number) {
    this.editor.pageId = value;
  }

  public get columns(): number {
    return this.editor.columns;
  }

  public set columns(value: number) {
    this.editor.columns = value;
  }

  public runPageLayout(): void {
    this.editor.execute('runPageLayout');
  }
}
