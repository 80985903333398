enum PageBackgroundBehavior {
  /**
   * Page background is shared across all page types
   */
  Shared,
  /**
   * Page background is separate for each page type
   */
  Separate
}
export default PageBackgroundBehavior;
