<template>
  <div
    class="cursor-pointer font-normal leading-tight"
    :class="[
      !disabled
        ? active
          ? 'bg-main-dark-10 hover:bg-main-dark-10'
          : 'hover:bg-main-dark-10'
        : 'cursor-not-allowed text-main-dark-20',
      paddingClasses
    ]"
    @click="clickHandler"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownItem'
})
export default class AppDropdownItem extends Vue {
  @Prop({ default: false, type: Boolean })
  disabled: boolean;
  @Prop({ default: false, type: Boolean })
  active: boolean;
  @Prop({ default: 'px-24 py-12', type: String })
  paddingClasses: string;
  @Prop({ default: true, type: Boolean })
  hideDropdownOnClick: boolean;

  @Inject() hideDropdown;

  clickHandler(event: MouseEvent) {
    if (this.disabled) return;

    if (this.hideDropdownOnClick) {
      this.hideDropdown();
    }

    this.$emit('click', event);
  }
}
</script>
