import {
  CurrentUserProfileEditDto,
  DiagramDto,
  DocumentAccessDto,
  DocumentAccessLevel,
  DocumentAccessRequestDto,
  DocumentAttachmentDto,
  AttachmentType,
  DocumentDto,
  DocumentPageDto,
  DocumentPageType,
  DocumentSubPageDto,
  EditDocumentAutoSaveDto,
  ElementType,
  HeaderFooterStyleDto,
  QuickStartState,
  ThemeAttachmentDto,
  ThemeDto,
  SidebarCategory,
  EditDocumentProfileDto,
  ErrorDto,
  FlipbookState,
  PageElementPosition,
  PointDto,
  DiagramViewDto,
  DocumentView,
  ThemeElementDto,
  DocumentPageContentType,
  EditDocumentUsePresetPropertiesDto,
  DocumentVersionDto,
  DocumentActivityBy,
  TutorialDto
} from '@/api/models';
import { RibbonTab } from '@/view/pages/document/ribbon/RibbonTab';
import { ActionContext, Module } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import DocumentsApiService from '@/api/DocumentsApiService';
import { DocumentFocusedSide } from '@/view/pages/document/DocumentFocusedSide';
import DocumentAccessApiService from '@/api/DocumentAccessApiService';
import { GET_CURRENT_USER } from './user.module';
import {
  IPersistedDataPropertyDisplayTypes,
  JurisdictionValueType
} from '@/core/styles/decorators/JurisdictionDecorator';
import { DataPropertyDisplayType } from '@/core/common/DataPropertyDisplayType';
import FileAttachmentsApiService from '@/api/FileAttachmentsApiService';
import renderingConfig from '@/core/config/renderingConfig';
import appConfig from '@/core/config/appConfig';
import diagramConfig from '@/core/config/diagram.definition.config';
import PageSyncContext from '../sync/PageSyncContext';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import DocumentService from '../document/DocumentService';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import Vue from 'vue';

import { IEdge, Point } from 'yfiles';
import PageListItem from '@/view/pages/document/page-list/PageListItem';
import ContentPagination from '../export/ContentPagination';
import DocumentPageTitleService from '../document/DocumentPageTitleService';
import { ContentTableItem } from '@/components/LayoutEditor/Plugins/ContentTable/ContentTableItemType';

// mutation types
export const SET_QUICK_BUILD_SETTINGS = 'setQuickBuildSettings';
export const GET_QUICK_BUILD_SETTINGS = 'getQuickBuildSettings';
export const SET_DOCUMENT = 'setDocument';
export const SET_DOCUMENT_FILTERS = 'setDocumentFilters';
export const SET_DOCUMENT_MODIFICATION_TIME = 'setDocumentModificationTime';
export const SET_SELECTED_PAGE = 'setSelectedPage';
export const SET_SELECTED_PAGE_SHOW_LOGO = 'setSelectedPageShowLogo';
export const SET_SELECTED_SUBPAGE_INDEX = 'setSelectedSubPageIndex';
export const SET_SAVING_STATE = 'setSavingState';
export const SET_SELECTION = 'setSelection';
export const SET_CURRENT_THEME = 'setCurrentTheme';
export const SET_DOCUMENT_HEADER_STYLE = 'setDocumentHeaderStyle';
export const SET_DOCUMENT_FOOTER_STYLE = 'setDocumentFooterStyle';
export const SET_DOCUMENT_FLIPBOOK_STATE = 'setDocumentFlipbookState';
export const GET_DOCUMENT_FLIPBOOK_STATE = 'getDocumentFlipbookState';
export const SET_DIAGRAM_FLIPBOOK_STATE = 'setDiagramFlipbookState';
export const GET_DOCUMENT_CAN_FLIPBOOK = 'getDocumentCanFlipbook';
export const SET_DOCUMENT_CAN_FLIPBOOK = 'setDocumentCanFlipbook';
export const GET_DOCUMENT = 'getDocument';
export const GET_DOCUMENT_FILTERS = 'getDocumentFilters';
export const GET_DOCUMENT_MODIFICATION_TIME = 'getDocumentModificationTime';
export const GET_SELECTED_PAGE = 'getSelectedPage';
export const GET_PAGES = 'getPages';
export const GET_SELECTED_SUBPAGE_INDEX = 'getSelectedSubpageIndex';
export const GET_SELECTED_SUBPAGE_TYPE = 'getSelectedSubpageType';
export const GET_SELECTED_SUBPAGE_COLUMNS = 'getSelectedSubpageColumns';
export const GET_SAVING_STATE = 'getSavingState';
export const GET_THEME_ELEMENT_BY_NAME = 'getThemeElementByName';
export const GET_CURRENT_THEME = 'getCurrentTheme';
export const DOCUMENT_NAMESPACE = 'document';
export const REFRESH_CURRENT_THEME = 'refreshCurrentTheme';
export const GET_SELECTION = 'getSelection';
export const SET_QUICK_START_STATE = 'setQuickBuild';
export const GET_QUICK_START_STATE = 'getQuickStartState';
export const GET_RIBBON_DEFAULT_TAB = 'getRibbonDefaultTab';
export const SET_RIBBON_DEFAULT_TAB = 'setRibbonDefaultTab';
export const SET_DOCUMENT_HEADER_FOOTER_STYLE = 'setDocumentHeaderFooterStyle';
export const SET_DOCUMENT_PROFILE = 'setDocumentProfile';
export const GET_DOCUMENT_PROFILE = 'getDocumentProfile';
export const SET_DOCUMENT_AUTOSAVE = 'setDocumentAutoSave';
export const UNLOAD_DOCUMENT = 'unloadDocument';
export const UPDATE_DOCUMENT_FROM_THEME = 'updateDocumentFromTheme';
export const SET_DOCUMENT_HEADER_FOOTER_VISIBILITY =
  'setDocumentHeaderFooterVisibility';
export const SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY =
  'setDocumentPageHeaderFooterVisibility';
export const ADD_DOCUMENT_ATTACHMENT = 'addDocumentAttachment';
export const SET_ACTIVE_SIDEBAR_BUTTON = 'setActiveSidebarButton';
export const GET_SELECTED_DIAGRAM = 'getSelectedDiagram';
export const UPDATE_SELECTED_DIAGRAM = 'updateSelectedDiagram';
export const GET_SHOW_HEADER = 'getShowHeader';
export const GET_SHOW_FOOTER = 'getShowFooter';
export const GET_IS_SIDEBAR_OPEN = 'getIsSidebarOpen';
export const GET_LAST_SELECTED_SIDEBAR_BUTTON = 'getLastSelectedSidebarButton';
export const SET_LAST_SELECTED_SIDEBAR_BUTTON = 'setLastSelectedSidebarButton';
export const GET_SELECTED_SUBPAGE_REF = 'getSelectedSubpageRef';
export const GET_SELECTED_PAGE_BODY_LAYOUT = 'getSelectedPageBodyLayout';
export const SET_SELECTED_PAGE_BODY_LAYOUT = 'setSelectedPageBodyLayout';
export const GET_SELECTED_PAGE_HEADER = 'getSelectedPageHeader';
export const SET_SELECTED_PAGE_HEADER = 'setSelectedPageHeader';
export const GET_SELECTED_PAGE_FOOTER = 'getSelectedPageFooter';
export const SET_SELECTED_PAGE_FOOTER = 'setSelectedPageFooter';
export const GET_SELECTED_PAGE_TITLE = 'getSelectedPageTitle';
export const SET_SELECTED_PAGE_TITLE = 'setSelectedPageTitle';
export const GET_SELECTED_PAGE_TITLE_HEIGHT = 'getSelectedPageTitleHeight';
export const GET_SELECTED_PAGE_MAX_TITLE_HEIGHT =
  'getSelectedPageMaxTitleHeight';
export const SET_SELECTED_PAGE_TITLE_HEIGHT = 'setSelectedPageTitleHeight';
export const SET_SELECTED_PAGE_SHOW_LEGEND = 'setSelectedPageSowLegend';
export const GET_SELECTED_PAGE_BACKGROUND = 'getSelectedPageBackground';
export const SET_SELECTED_PAGE_BACKGROUND = 'setSelectedPageBackground';
export const SET_SELECTED_PAGE_CONTENT = 'setSelectedPageContent';
export const GET_SELECTED_PAGE_COLLABORATION_DATA =
  'getSelectedPageCollaborationData';
export const SET_SELECTED_PAGE_COLLABORATION_DATA =
  'setSelectedPageCollaborationData';
export const SET_PAGE_HEADER_FROM_THEME = 'setPageHeaderFromTheme';
export const SET_PAGE_FOOTER_FROM_THEME = 'setPageFooterFromTheme';
export const SET_PAGE_BACKGROUND_FROM_THEME = 'setPageBackgroundFromTheme';
export const SET_SUBPAGE_HEADER_FROM_THEME = 'setSubPageHeaderFromTheme';
export const SET_SUBPAGE_FOOTER_FROM_THEME = 'setSubPageFooterFromTheme';
export const SET_SUBPAGE_BACKGROUND_FROM_THEME =
  'setSubPageBackgroundFromTheme';
export const SET_READONLY = 'setReadOnly';
export const GET_READONLY = 'getReadOnly';
//used in steps when header/footer is active and we need to shade the diagram area
export const SET_DIAGRAM_EDITABLE = 'setDiagramEditable';
export const GET_DIAGRAM_EDITABLE = 'getDiagramEditable';
export const GET_FOCUSED_SIDE = 'getFocusedSide';
export const SET_FOCUSED_SIDE = 'setFocusedSide';
export const SET_SAVE_FAILED = 'setSaveFailed';
export const GET_SAVE_FAILED = 'getSaveFailed';
export const SET_SELECTED_PAGE_FILTER_DEFINITION =
  'setSelectedPageFilterDefinition';
export const GET_IS_TRACK_CHANGES_ENABLED = 'getIsTrackChangesEnabled';
export const SET_IS_TRACK_CHANGES_ENABLED = 'setIsTrackChangesEnabled';

export const GET_DOCUMENT_EXTERNAL_FILE_ID = 'getDocumentExternalFileId';
export const SET_DOCUMENT_EXTERNAL_FILE_ID = 'setDocumentExternalFileId';

export const SET_DOCUMENT_ERROR = 'setDocumentError';
export const GET_DOCUMENT_ERROR = 'getDocumentError';

export const GET_DOCUMENT_ACCESS = 'getDocumentAccess';
export const SET_DOCUMENT_ACCESS = 'setDocumentAccess';
export const RELOAD_DOCUMENT_ACCESS = 'reloadDocumentAccess';
export const GET_DOCUMENT_ACCESS_REQUESTS = 'getDocumentAccessRequests';
export const SET_DOCUMENT_ACCESS_REQUESTS = 'setDocumentAccessRequests';
export const RELOAD_DOCUMENT_ACCESS_REQUESTS = 'reloadDocumentAccessRequests';
export const GET_DOCUMENT_ACCESS_LEVEL = 'getDocumentAccessLevel';
export const SET_DOCUMENT_ACCESS_LEVEL = 'setDocumentAccessLevel';
export const GET_DOCUMENT_ACCESS_FORBIDDEN = 'getDocumentAccessForbidden';
export const SET_DOCUMENT_ACCESS_FORBIDDEN = 'setDocumentAccessForbidden';
export const SET_DOCUMENT_OWNER = 'setDocumentOwner';
export const GET_IS_DOCUMENT_OWNER = 'getIsDocumentOwner';
export const GET_DOCUMENT_VIEW = 'getDocumentView';
export const SET_DOCUMENT_VIEW = 'setDocumentView';
export const GET_DOCUMENT_VERSION_TO_RESTORE = 'getDocumentVersionToRestore';
export const SET_DOCUMENT_VERSION_TO_RESTORE = 'setDocumentVersionToRestore';
export const GET_CURRENT_DOCUMENT_VERSION_NUMBER =
  'getCurrentDocumentVersionNumber';
export const SET_CURRENT_DOCUMENT_VERSION_NUMBER =
  'setCurrentDocumentVersionNumber';
export const GET_DOCUMENT_VERSIONS = 'getDocumentVersions';
export const SET_DOCUMENT_VERSIONS = 'setDocumentVersions';

export const GET_CHANGE_NODE_TYPE_IN_PROGRESS = 'getChangeNodeTypeInProgress';
export const SET_CHANGE_NODE_TYPE_IN_PROGRESS = 'setChangeNodeTypeInProgress';

export const SET_DOCUMENT_ACTIVITY_BY = 'setDocumentActivityBy';

export const SET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES =
  'setPersistedDataPropertyDisplayTypes';
export const GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES =
  'getPersistedDataPropertyDisplayTypes';
export const GET_DOCUMENT_AUTOSAVE = 'getDocumentAutoSave';
export const GET_DOCUMENT_USE_PRESET_PROPERTIES =
  'getDocumentUsePresetProperties';
export const SET_DOCUMENT_USE_PRESET_PROPERTIES =
  'setDocumentUsePresetProperties';

export const ADD_THEME_ATTACHMENT = 'addThemeAttachment';
export const DELETE_THEME_ATTACHMENT = 'deleteThemeAttachment';
export const DELETE_DOCUMENT_ATTACHMENT = 'deleteDocumentAttachment';
export const GET_SHOW_LOGO = 'getShowLogo';
export const GET_AUTOZOOM = 'getAutozoom';
/**
 * Do not import this directly. Use ZoomService.setAutoZoom,
 * setAutoZoom controls scrollsbars correctly. Bypassing this can cause unwanted scrollbars
 */
export const SET_AUTOZOOM = 'setAutozoom';
export const GET_SHOW_GRID = 'getShowGrid';
export const SET_SHOW_GRID = 'setShowGrid';
export const GET_PAGE_SYNC_CONTEXT = 'getPageSyncContext';
export const SET_PAGE_SYNC_CONTEXT = 'setPageSyncContext';

export const GET_SHOW_PAGINATION = 'getShowPagination';
export const GET_SHOW_DATE = 'getShowDate';
export const SET_DOCUMENT_SHOW_DATE = 'setDocumentShowDate';
export const SET_DOCUMENT_SHOW_PAGE_NUMBER = 'setDocumentShowPageNumber';
export const SET_DOCUMENT_PAGE_TITLE_VISIBILITY =
  'setDocumentPageTitleVisibility';
export const GET_SHOW_PAGE_TITLE = 'getShowPageTitle';

export const UPDATE_DIAGRAM_VIEW_POINT = 'updateDiagramViewPoint';
export const UPDATE_DIAGRAM_VIEW_POINT_DATA = 'updateDiagramViewPointData';
export const GET_CURRENT_PAGE_LIST_ITEM = 'getCurrentPageListItem';
export const SET_CURRENT_PAGE_LIST_ITEM = 'setCurrentPageListItem';

export const GET_EDGE_WITH_LABEL_PLACEHOLDER = 'getEdgeWithLabelPlaceholder';
export const SET_EDGE_WITH_LABEL_PLACEHOLDER = 'setEdgeWithLabelPlaceholder';
export const GET_EDGE_LABEL_PLACEHOLDER_ENABLED =
  'getEdgeLabelPlaceholderEnabled';
export const SET_EDGE_LABEL_PLACEHOLDER_ENABLED =
  'setEdgeLabelPlaceholderEnabled';

export const GET_GROUPING_MARGIN = 'getGroupingMargin';
export const SET_GROUPING_MARGIN = 'setGroupingMargin';
export const GET_GROUPING_GLUE_EXTENT = 'getGroupingGlueExtent';
export const SET_GROUPING_GLUE_EXTENT = 'setGroupingGlueExtent';
export const GET_GROUPING_MIN_BRIDGE_SIZE = 'getGroupingMinBridgeSize';
export const SET_GROUPING_MIN_BRIDGE_SIZE = 'setGroupingMinBridgeSize';
export const SET_DIAGRAM_GROUPING = 'setDiagramGrouping';
export const GET_TABLE_OF_CONTENT = 'getTableOfContent';
export const SET_TABLE_OF_CONTENT = 'setTableOfContent';

export const GET_HTML_CONTENT_FOCUSED = 'getHtmlContentFocused';
export const SET_HTML_CONTENT_FOCUSED = 'setHtmlContentFocused';

export const SET_DOCUMENT_TUTORIAL = 'setDocumentTutorial';

export enum AutoZoomState {
  /** Autozoom active */
  On,
  /** Autozoom not active */
  Off,
  /** Autozoom cannot be activated */
  Disabled
}
export const GET_SHOW_MASTER_LEGEND_MODAL = 'getShowMasterLegendModal';
export const SET_SHOW_MASTER_LEGEND_MODAL = 'setShowMasterLegendModal';
export const GET_INITIAL_LEGEND_LAYOUT_SET = 'getInitialLegendLayoutSet';
export const SET_INITIAL_LEGEND_LAYOUT_SET = 'setInitialLegendLayoutSet';
export const GET_DOCUMENT_LOADED = 'getDocumentLoaded';
export const SET_DOCUMENT_LOADED = 'setDocumentLoaded';
export const GET_RESTORED_VERSION_NUMBER = 'getRestoredVersionNumber';
export const SET_RESTORED_VERSION_NUMBER = 'setRestoredVersionNumber';
export const GET_SHOW_VERSION_HISTORY_MODAL = 'getShowVersionHistoryModal';
export const SET_SHOW_VERSION_HISTORY_MODAL = 'setShowVersionHistoryModal';

const getInitialState = (): State => {
  return {
    document: null,
    access: [],
    accessRequests: [],
    accessLevel: DocumentAccessLevel.View,
    documentModificationTime: null,
    selectedPage: null,
    selectedSubPageIndex: 0,
    saving: false,
    saveFailed: false,
    selection: null,
    currentTheme: null,
    quickBuildState: QuickStartState.Initial,
    ribbonDefaultTab: RibbonTab.Home,
    selectedSidebarButton: null,
    lastSelectedSidebarButton: null,
    focusedSide: null,
    documentExternalFileId: null,
    isReadOnly: false,
    diagramEditable: true,
    isAccessForbidden: false,
    skipLeaveConfirmation: false,
    persistedDataPropertyDisplayTypes: {},
    autoZoom: AutoZoomState.Off,
    showGrid: false,
    showVersionHistoryModal: false,
    pageSyncContext: null,
    canFlipbook: false,
    documentError: null,
    documentProfile: null,
    documentView: appConfig.diagramConfig.defaultDiagramView,
    documentVersionToRestore: null,
    currentDocumentVersionNumber: null,
    documentVersionNumber: null,
    documentVersions: [],
    restoredVersionNumber: null,
    currentPageListItem: null,
    showMasterLegendModal: false,
    initialLegendLayoutSet: false,
    edgeWithLabelPlaceholder: null,
    tableOfContent: [],
    isTrackChangesEnabled: false,
    htmlCkEditorFocused: false,
    documentLoaded: false,
    documentActivityBy: null,
    edgeLabelPlaceholderEnabled: false,
    changeNodeTypeInProgress: false
  };
};

interface State {
  document: DocumentDto | null;
  access: DocumentAccessDto[];
  accessRequests: DocumentAccessRequestDto[];
  accessLevel: DocumentAccessLevel;
  documentModificationTime: null | string;
  selectedPage: DocumentPageDto | null;
  selectedSubPageIndex: number;
  saving: boolean;
  saveFailed: boolean;
  selection: any;
  currentTheme: ThemeDto | null;
  quickBuildState: QuickStartState;
  ribbonDefaultTab: RibbonTab;
  selectedSidebarButton: SidebarCategory | null;
  lastSelectedSidebarButton: SidebarCategory | null;
  focusedSide: DocumentFocusedSide | null;
  isReadOnly: boolean;
  diagramEditable: boolean;
  isAccessForbidden: boolean;
  skipLeaveConfirmation: boolean;
  persistedDataPropertyDisplayTypes: IPersistedDataPropertyDisplayTypes | any;
  autoZoom: AutoZoomState;
  showGrid: boolean;
  showVersionHistoryModal: boolean;
  pageSyncContext: PageSyncContext;
  canFlipbook: boolean;
  documentExternalFileId: boolean;
  documentError: ErrorDto;
  documentProfile: EditDocumentProfileDto;
  documentView: DocumentView;
  documentVersionToRestore: DocumentVersionDto;
  currentDocumentVersionNumber: number;
  documentVersions: DocumentVersionDto[];
  restoredVersionNumber: string;
  documentVersionNumber: number;
  currentPageListItem: PageListItem;
  showMasterLegendModal: boolean;
  initialLegendLayoutSet: boolean;
  edgeWithLabelPlaceholder: IEdge | null;
  documentActivityBy: DocumentActivityBy;
  tableOfContent: Array<ContentTableItem>;
  isTrackChangesEnabled: boolean;
  htmlCkEditorFocused: boolean;
  documentLoaded: boolean;
  edgeLabelPlaceholderEnabled: boolean;
  changeNodeTypeInProgress: boolean;
}

const documentModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_QUICK_BUILD_SETTINGS](state) {
      return state.document?.quickBuildSettings;
    },
    [GET_SELECTION](state) {
      return state.selection;
    },
    [GET_DOCUMENT](state) {
      return state.document;
    },
    [GET_DOCUMENT_ERROR](state) {
      return state.documentError;
    },
    [GET_DOCUMENT_EXTERNAL_FILE_ID](state) {
      return state.documentExternalFileId;
    },
    [GET_DOCUMENT_ACCESS](state) {
      return state.access;
    },
    [GET_DOCUMENT_ACCESS_REQUESTS](state) {
      return state.accessRequests;
    },
    [GET_DOCUMENT_ACCESS_LEVEL](state) {
      return state.accessLevel;
    },
    [GET_DOCUMENT_VIEW](state) {
      return state.documentView;
    },

    [GET_DOCUMENT_VERSION_TO_RESTORE](state) {
      return state.documentVersionToRestore;
    },
    [GET_CURRENT_DOCUMENT_VERSION_NUMBER](state) {
      return state.currentDocumentVersionNumber;
    },
    [GET_DOCUMENT_VERSIONS](state) {
      return state.documentVersions;
    },
    [GET_RESTORED_VERSION_NUMBER](state) {
      return state.restoredVersionNumber;
    },
    [GET_DOCUMENT_ACCESS_FORBIDDEN](state) {
      return state.isAccessForbidden;
    },
    [GET_IS_DOCUMENT_OWNER](state, getters, rootState, rootGetters) {
      const currentUser = rootGetters[
        GET_CURRENT_USER
      ] as CurrentUserProfileEditDto;
      return currentUser?.userId == state.document?.ownerUserId;
    },
    [GET_DOCUMENT_FILTERS](state) {
      return state.document?.filters;
    },
    [GET_DOCUMENT_MODIFICATION_TIME](state) {
      return state.documentModificationTime;
    },
    [GET_THEME_ELEMENT_BY_NAME](state) {
      return (name: string, elementType?: ElementType): ThemeElementDto => {
        return state.currentTheme?.elements.find(
          (x) =>
            x.name.toLowerCase() == name?.toLowerCase() &&
            (!elementType || x.elementType == elementType)
        );
      };
    },
    [GET_QUICK_START_STATE](state) {
      return (
        state.selectedPage?.diagram?.quickStartStateData?.state ??
        QuickStartState.Initial
      );
    },
    [GET_CURRENT_THEME](state) {
      return state.currentTheme;
    },
    [GET_SELECTED_PAGE](state) {
      return state.selectedPage;
    },
    [GET_PAGES](state) {
      return state.document.pages;
    },
    [GET_SELECTED_SUBPAGE_INDEX](state) {
      return state.selectedSubPageIndex;
    },
    [GET_SELECTED_SUBPAGE_TYPE](state) {
      return state.selectedPage?.getSubPageType(state.selectedSubPageIndex);
    },
    [GET_SELECTED_SUBPAGE_COLUMNS](state) {
      return state.selectedPage?.getSubPageColumns(state.selectedSubPageIndex);
    },
    [GET_SAVING_STATE](state) {
      return state.saving;
    },
    [GET_RIBBON_DEFAULT_TAB](state) {
      return state.ribbonDefaultTab;
    },
    [GET_SELECTED_DIAGRAM](state) {
      const selectedSubPageType = state.selectedPage?.getSubPageType(
        state.selectedSubPageIndex
      );
      if (
        selectedSubPageType === DocumentPageType.Diagram ||
        selectedSubPageType === DocumentPageType.Split
      ) {
        let diagram = state.selectedPage.subPageRefs?.find(
          (r) =>
            r.pageId == state.selectedPage.id &&
            r.subPageIndex == state.selectedSubPageIndex
        )?.diagram;
        if (!diagram) {
          diagram = state.selectedPage.diagram;
        }
        return diagram;
      }
      return null;
    },
    [GET_SHOW_HEADER](state) {
      return state.selectedPage?.showHeader;
    },
    [GET_SHOW_FOOTER](state) {
      return state.selectedPage?.showFooter;
    },
    [GET_DOCUMENT_AUTOSAVE](state) {
      return state.document?.autoSave;
    },
    [GET_DOCUMENT_USE_PRESET_PROPERTIES](state) {
      return state.document?.usePresetProperties;
    },
    [GET_IS_SIDEBAR_OPEN](state) {
      return state.selectedSidebarButton !== null;
    },
    [GET_LAST_SELECTED_SIDEBAR_BUTTON](state) {
      return state.lastSelectedSidebarButton;
    },
    [GET_SELECTED_SUBPAGE_REF](state) {
      return state.selectedPage?.subPageRefs?.find(
        (sp) => sp.subPageIndex === state.selectedSubPageIndex
      );
    },
    [GET_SELECTED_PAGE_BODY_LAYOUT](state, getters) {
      return state.selectedPage?.bodyLayout ?? '';
    },
    [GET_SELECTED_PAGE_HEADER](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        DocumentService.subPageHeaderFooterLayoutAvailable
          ? getters[GET_SELECTED_SUBPAGE_REF]
          : null;
      if (selectedSubpageRef?.headerLayout) {
        return selectedSubpageRef.headerLayout;
      }

      return state.selectedPage.headerLayout ?? '';
    },
    [GET_SELECTED_PAGE_FOOTER](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        DocumentService.subPageHeaderFooterLayoutAvailable
          ? getters[GET_SELECTED_SUBPAGE_REF]
          : null;
      if (selectedSubpageRef?.footerLayout) {
        return selectedSubpageRef.footerLayout;
      }
      return state.selectedPage?.footerLayout ?? '';
    },
    [GET_SELECTED_PAGE_BACKGROUND](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        DocumentService.subPageHeaderFooterLayoutAvailable
          ? getters[GET_SELECTED_SUBPAGE_REF]
          : null;
      if (selectedSubpageRef?.backgroundLayout) {
        return selectedSubpageRef.backgroundLayout;
      }
      return state.selectedPage?.backgroundLayout ?? '';
    },
    [GET_FOCUSED_SIDE](state) {
      return state.focusedSide;
    },
    [GET_READONLY](state) {
      return state.isReadOnly;
    },
    [GET_DIAGRAM_EDITABLE](state) {
      return state.diagramEditable;
    },
    [GET_SAVE_FAILED](state) {
      return state.saveFailed;
    },
    [GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES](state) {
      return state.persistedDataPropertyDisplayTypes;
    },
    [GET_DOCUMENT_FLIPBOOK_STATE](state) {
      return state.document?.flipbookState;
    },
    [GET_DOCUMENT_CAN_FLIPBOOK](state) {
      return state.canFlipbook;
    },
    [GET_SHOW_LOGO](state) {
      return state.selectedPage?.showLogo ?? false;
    },
    [GET_AUTOZOOM](state) {
      return state.autoZoom;
    },
    [GET_SHOW_GRID](state) {
      return state.showGrid;
    },
    [GET_SHOW_VERSION_HISTORY_MODAL](state) {
      return state.showVersionHistoryModal;
    },
    [GET_PAGE_SYNC_CONTEXT](state) {
      return state.pageSyncContext;
    },

    [GET_SHOW_PAGINATION](state) {
      return state.selectedPage?.showPageNumber;
    },
    [GET_SHOW_DATE](state) {
      return state.selectedPage?.showDate;
    },
    [GET_DOCUMENT_PROFILE](state) {
      return state.documentProfile;
    },
    [GET_SHOW_PAGE_TITLE](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.showTitle;
      }
      return state.selectedPage?.showTitle;
    },
    [GET_SELECTED_PAGE_TITLE](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.titleLayout ?? '';
      }
      return state.selectedPage?.titleLayout ?? '';
    },
    [GET_SELECTED_PAGE_TITLE_HEIGHT](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.titleHeight;
      }
      return state.selectedPage?.titleHeight ?? 0;
    },
    [GET_SELECTED_PAGE_MAX_TITLE_HEIGHT](state, getters) {
      const selectedSubpageRef: DocumentSubPageDto =
        getters[GET_SELECTED_SUBPAGE_REF];
      if (selectedSubpageRef) {
        return selectedSubpageRef.maxTitleHeight;
      }
      return state.selectedPage?.maxTitleHeight ?? 0;
    },
    [GET_SELECTED_PAGE_COLLABORATION_DATA](state, getters) {
      return state.selectedPage?.collaborationData ?? '';
    },
    [GET_CURRENT_PAGE_LIST_ITEM](state) {
      return state.currentPageListItem;
    },
    [GET_SHOW_MASTER_LEGEND_MODAL](state) {
      return state.showMasterLegendModal;
    },
    [GET_INITIAL_LEGEND_LAYOUT_SET](state) {
      return state.initialLegendLayoutSet;
    },
    [GET_DOCUMENT_LOADED](state) {
      return state.documentLoaded;
    },
    [GET_GROUPING_MARGIN](state: State): number {
      if (!state.selectedPage?.diagram?.groupSettings) {
        return diagramConfig.groupingDefaults.margin;
      }

      return state.selectedPage.diagram.groupSettings.margin;
    },
    [GET_GROUPING_GLUE_EXTENT](state: State): number {
      if (!state.selectedPage?.diagram?.groupSettings) {
        return diagramConfig.groupingDefaults.glueExtent;
      }

      return state.selectedPage.diagram.groupSettings.glueExtent;
    },
    [GET_GROUPING_MIN_BRIDGE_SIZE](state: State): number {
      if (!state.selectedPage?.diagram?.groupSettings) {
        return diagramConfig.groupingDefaults.minimumBridgeSize;
      }

      return state.selectedPage.diagram.groupSettings.minimumBridgeSize;
    },
    [GET_EDGE_WITH_LABEL_PLACEHOLDER](state: State): IEdge | null {
      return state.edgeWithLabelPlaceholder;
    },
    [GET_TABLE_OF_CONTENT](state: State): Array<ContentTableItem> {
      return state.tableOfContent;
    },
    [GET_IS_TRACK_CHANGES_ENABLED](state: State): boolean {
      return state.isTrackChangesEnabled;
    },
    [GET_HTML_CONTENT_FOCUSED](state: State): boolean {
      return state.htmlCkEditorFocused;
    },
    [GET_EDGE_LABEL_PLACEHOLDER_ENABLED](state: State): boolean {
      return state.edgeLabelPlaceholderEnabled;
    },
    [GET_CHANGE_NODE_TYPE_IN_PROGRESS](state) {
      return state.changeNodeTypeInProgress;
    }
  },
  actions: {
    [UPDATE_DIAGRAM_VIEW_POINT](context, payload: UpdateDiagramViewPoint) {
      context.commit(UPDATE_DIAGRAM_VIEW_POINT, payload);
    },
    [UPDATE_DIAGRAM_VIEW_POINT_DATA](
      context,
      payload: UpdateDiagramViewPointData
    ) {
      context.commit(UPDATE_DIAGRAM_VIEW_POINT_DATA, payload);
    },
    [SET_SELECTED_PAGE_SHOW_LEGEND](context, payload: boolean) {
      context.commit(SET_SELECTED_PAGE_SHOW_LEGEND, payload);
    },
    [SET_READONLY](context, payload) {
      context.commit(SET_READONLY, payload);
    },
    [SET_SAVE_FAILED](context, payload) {
      context.commit(SET_SAVE_FAILED, payload);
    },
    [REFRESH_CURRENT_THEME](context, payload?: ThemeDto) {
      if (payload) {
        context.commit(REFRESH_CURRENT_THEME, payload);
        return;
      }
      if (!context.state.currentTheme) {
        return;
      }
      const theme = context.rootState.themes?.themes?.find(
        (d) => d.id == context.state.currentTheme.id
      );
      if (!theme) {
        return;
      }
      context.commit(REFRESH_CURRENT_THEME, theme);
    },
    async [SET_DOCUMENT](context, payload: DocumentDto) {
      context.commit(SET_DOCUMENT, payload);
      context.commit(SET_DOCUMENT_PROFILE, {
        name: payload.name,
        client: payload.client,
        matter: payload.matter
      });
      context.commit(SET_DOCUMENT_EXTERNAL_FILE_ID, payload.externalFileId);
      context.commit(
        SET_DOCUMENT_MODIFICATION_TIME,
        payload.lastModificationTime
      );
      await context.dispatch(RELOAD_DOCUMENT_ACCESS, {
        documentId: payload.id
      });
      await context.dispatch(RELOAD_DOCUMENT_ACCESS_REQUESTS, {
        documentId: payload.id
      });

      const renderPerformanceSettings = payload.hasSteps
        ? diagramConfig.renderingSteps
        : diagramConfig.renderingDiagram[
            appConfig.diagramConfig.performanceIndex
          ];
      Object.assign(renderingConfig, renderPerformanceSettings);
    },
    async [RELOAD_DOCUMENT_ACCESS](context, payload) {
      const documentAccess = (
        await DocumentAccessApiService.getDocumentAccess({
          documentId: payload.documentId
        })
      ).data.result;
      context.commit(SET_DOCUMENT_ACCESS, documentAccess);
    },
    async [RELOAD_DOCUMENT_ACCESS_REQUESTS](context, payload) {
      const documentAccessRequests = (
        await DocumentAccessApiService.getDocumentAccessRequests({
          documentId: payload.documentId
        })
      ).data.result;
      context.commit(SET_DOCUMENT_ACCESS_REQUESTS, documentAccessRequests);
    },
    [SET_DOCUMENT_VIEW](context, payload: DocumentView) {
      context.commit(SET_DOCUMENT_VIEW, payload);
    },
    [SET_DOCUMENT_VERSION_TO_RESTORE](context, payload: DocumentVersionDto) {
      context.commit(SET_DOCUMENT_VERSION_TO_RESTORE, payload);
    },
    [SET_CURRENT_DOCUMENT_VERSION_NUMBER](context, payload: number) {
      context.commit(SET_CURRENT_DOCUMENT_VERSION_NUMBER, payload);
    },
    [SET_DOCUMENT_VERSIONS](context, payload: DocumentVersionDto[]) {
      context.commit(SET_DOCUMENT_VERSIONS, payload);
    },
    [SET_RESTORED_VERSION_NUMBER](context, payload: string) {
      context.commit(SET_RESTORED_VERSION_NUMBER, payload);
    },
    [SET_DOCUMENT_ERROR](context, payload) {
      context.commit(SET_DOCUMENT_ERROR, payload);
    },
    [SET_DOCUMENT_EXTERNAL_FILE_ID](context, payload) {
      context.commit(SET_DOCUMENT_EXTERNAL_FILE_ID, payload);
    },
    [SET_DOCUMENT_ACCESS](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS, payload);
    },
    [SET_DOCUMENT_ACCESS_REQUESTS](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_REQUESTS, payload);
    },
    [SET_DOCUMENT_ACCESS_LEVEL](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_LEVEL, payload);
    },
    [SET_DOCUMENT_ACCESS_FORBIDDEN](context, payload) {
      context.commit(SET_DOCUMENT_ACCESS_FORBIDDEN, payload);
    },
    [SET_DOCUMENT_ACTIVITY_BY](context, payload) {
      context.commit(SET_DOCUMENT_ACTIVITY_BY, payload);
    },
    [SET_DOCUMENT_OWNER](context, payload) {
      context.commit(SET_DOCUMENT_OWNER, payload);
    },
    [SET_DOCUMENT_FILTERS](context, payload) {
      context.commit(SET_DOCUMENT_FILTERS, payload);
    },
    [SET_SELECTED_PAGE](context, payload) {
      context.commit(SET_SELECTED_PAGE, payload);
    },
    [SET_SELECTED_SUBPAGE_INDEX](context, payload) {
      context.commit(SET_SELECTED_SUBPAGE_INDEX, payload);
    },
    [SET_SAVING_STATE](context, payload) {
      context.commit(SET_SAVING_STATE, payload);
    },
    [SET_SELECTION](context, payload) {
      context.commit(SET_SELECTION, payload);
    },
    [SET_CURRENT_THEME](context, payload) {
      context.commit(SET_CURRENT_THEME, payload);
    },
    [SET_QUICK_START_STATE](context, payload) {
      context.commit(SET_QUICK_START_STATE, payload);
    },
    [SET_RIBBON_DEFAULT_TAB](state, payload) {
      state.commit(SET_RIBBON_DEFAULT_TAB, payload);
    },
    [SET_DOCUMENT_HEADER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_HEADER_STYLE, payload);
    },
    [SET_DOCUMENT_FOOTER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_FOOTER_STYLE, payload);
    },
    [UNLOAD_DOCUMENT](state) {
      state.commit(UNLOAD_DOCUMENT);
    },
    [SET_DOCUMENT_HEADER_FOOTER_STYLE](state, payload) {
      state.commit(SET_DOCUMENT_HEADER_FOOTER_STYLE, payload);
    },
    [SET_DOCUMENT_PROFILE](state, payload) {
      state.commit(SET_DOCUMENT_PROFILE, payload);
    },

    async [SET_DOCUMENT_AUTOSAVE](state, payload: EditDocumentAutoSaveDto) {
      await DocumentsApiService.updateDocumentAutoSave({
        documentId: payload.documentId,
        autoSave: payload.autoSave
      });
      state.commit(SET_DOCUMENT_AUTOSAVE, payload.autoSave);
    },

    async [SET_DOCUMENT_USE_PRESET_PROPERTIES](
      state,
      payload: EditDocumentUsePresetPropertiesDto
    ) {
      await DocumentsApiService.updateDocumentUsePresetProperties({
        documentId: payload.documentId,
        usePresetProperties: payload.usePresetProperties
      });
      state.commit(
        SET_DOCUMENT_USE_PRESET_PROPERTIES,
        payload.usePresetProperties
      );
    },
    [SET_SELECTED_PAGE_HEADER](context, payload) {
      context.commit(SET_SELECTED_PAGE_HEADER, payload);
    },
    [SET_SELECTED_PAGE_FOOTER](context, payload) {
      context.commit(SET_SELECTED_PAGE_FOOTER, payload);
    },
    [SET_SELECTED_PAGE_BACKGROUND](context, payload) {
      context.commit(SET_SELECTED_PAGE_BACKGROUND, payload);
    },
    [SET_SELECTED_PAGE_TITLE_HEIGHT](context, payload: number) {
      context.commit(SET_SELECTED_PAGE_TITLE_HEIGHT, payload);
    },
    [SET_SELECTED_PAGE_CONTENT](context, payload) {
      context.commit(SET_SELECTED_PAGE_CONTENT, payload);
    },
    [SET_SELECTED_PAGE_COLLABORATION_DATA](context, payload) {
      context.commit(SET_SELECTED_PAGE_COLLABORATION_DATA, payload);
    },
    [UPDATE_SELECTED_DIAGRAM](state, payload) {
      state.commit(UPDATE_SELECTED_DIAGRAM, payload);
    },
    [UPDATE_DOCUMENT_FROM_THEME](ctx, payload: { theme: ThemeDto }) {
      const theme = payload.theme;
      const document = ctx.state.document;

      // LOGO
      replaceDocumentAttachmentsFromTheme(
        document,
        theme,
        AttachmentType.Logo,
        AttachmentType.Logo
      );

      document.logoPosition = theme.logoPosition;
      document.legendPosition = theme.legendPosition;
      document.lastSelectedThemeId = theme.id;
      document.tableSwatch = theme.tableSwatch
        ? cloneDeep(theme.tableSwatch)
        : {};
      document.fontStyles = cloneDeep(theme.fontStyles);
      ctx.commit(UPDATE_DOCUMENT_FROM_THEME, document);
    },
    [SET_DOCUMENT_HEADER_FOOTER_VISIBILITY](ctx, payload) {
      ctx.commit(SET_DOCUMENT_HEADER_FOOTER_VISIBILITY, payload);
    },

    [SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY](ctx, payload) {
      ctx.commit(SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY, payload);
    },
    [ADD_DOCUMENT_ATTACHMENT](ctx, payload: DocumentAttachmentDto) {
      const attachment = payload;
      DocumentsApiService.addDocumentAttachment(payload).then((response) => {
        if (response.data.result && response.data.result > 0) {
          attachment.id = response.data.result;
          const attachmentExists = ctx.state.document.attachments.some(
            (a) =>
              a.documentId === payload.documentId &&
              a.fileAttachmentId === payload.fileAttachmentId
          );
          if (attachmentExists) return;
          ctx.commit(ADD_DOCUMENT_ATTACHMENT, attachment);
        }
      });
    },
    [SET_FOCUSED_SIDE](ctx, payload) {
      ctx.commit(SET_FOCUSED_SIDE, payload);
    },
    [SET_PAGE_HEADER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_PAGE_HEADER_FROM_THEME, payload);
    },
    [SET_PAGE_FOOTER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_PAGE_FOOTER_FROM_THEME, payload);
    },
    [SET_PAGE_BACKGROUND_FROM_THEME](ctx, payload) {
      ctx.commit(SET_PAGE_BACKGROUND_FROM_THEME, payload);
    },
    [SET_SUBPAGE_HEADER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_SUBPAGE_HEADER_FROM_THEME, payload);
    },
    [SET_SUBPAGE_FOOTER_FROM_THEME](ctx, payload) {
      ctx.commit(SET_SUBPAGE_FOOTER_FROM_THEME, payload);
    },
    [SET_SUBPAGE_BACKGROUND_FROM_THEME](ctx, payload) {
      ctx.commit(SET_SUBPAGE_BACKGROUND_FROM_THEME, payload);
    },
    [SET_SELECTED_PAGE_FILTER_DEFINITION](ctx, payload) {
      ctx.commit(SET_SELECTED_PAGE_FILTER_DEFINITION, payload);
    },
    async [DELETE_THEME_ATTACHMENT](
      ctx,
      payload: { themeId: number; fileId: string }
    ) {
      try {
        await FileAttachmentsApiService.deleteFileByFileId({
          fileId: payload.fileId,
          themeId: payload.themeId
        });
        ctx.commit(DELETE_THEME_ATTACHMENT, payload);
      } catch (err) {
        console.error(err);
      }
    },
    async [DELETE_DOCUMENT_ATTACHMENT](
      ctx,
      payload: { documentId: number; fileId: string }
    ) {
      try {
        await FileAttachmentsApiService.deleteFileByFileId({
          fileId: payload.fileId,
          documentId: payload.documentId
        });
        ctx.commit(DELETE_DOCUMENT_ATTACHMENT, payload);
      } catch (err) {
        console.error(err);
      }
    },
    [SET_AUTOZOOM](ctx, payload) {
      ctx.commit(SET_AUTOZOOM, payload);
    },
    [SET_SHOW_GRID](ctx, payload) {
      ctx.commit(SET_SHOW_GRID, payload);
    },
    [SET_SHOW_VERSION_HISTORY_MODAL](ctx, payload) {
      ctx.commit(SET_SHOW_VERSION_HISTORY_MODAL, payload);
    },
    [SET_PAGE_SYNC_CONTEXT](ctx, payload) {
      ctx.commit(SET_PAGE_SYNC_CONTEXT, payload);
    },
    [SET_GROUPING_MARGIN](context, payload: number) {
      context.commit(SET_DIAGRAM_GROUPING, { margin: payload });
    },
    [SET_GROUPING_GLUE_EXTENT](context, payload: number) {
      context.commit(SET_DIAGRAM_GROUPING, { glueExtent: payload });
    },
    [SET_GROUPING_MIN_BRIDGE_SIZE](context, payload: number) {
      context.commit(SET_DIAGRAM_GROUPING, { minimumBridgeSize: payload });
    },
    [SET_EDGE_WITH_LABEL_PLACEHOLDER](context, payload: IEdge | null): void {
      context.commit(SET_EDGE_WITH_LABEL_PLACEHOLDER, payload);
    },
    [SET_DOCUMENT_TUTORIAL](
      ctx: ActionContext<State, any>,
      payload: Partial<TutorialDto>
    ): void {
      ctx.commit(SET_DOCUMENT_TUTORIAL, payload);
    },
    [SET_SELECTED_PAGE_SHOW_LOGO](
      ctx: ActionContext<State, any>,
      payload: boolean
    ): void {
      ctx.commit(SET_SELECTED_PAGE_SHOW_LOGO, payload);
    },
    [SET_SELECTED_PAGE_SHOW_LEGEND](
      ctx: ActionContext<State, any>,
      payload: boolean
    ): void {
      ctx.commit(SET_SELECTED_PAGE_SHOW_LEGEND, payload);
    },
    [SET_EDGE_LABEL_PLACEHOLDER_ENABLED](
      ctx: ActionContext<State, any>,
      payload: boolean
    ): void {
      ctx.commit(SET_EDGE_LABEL_PLACEHOLDER_ENABLED, payload);
    },
    [SET_CHANGE_NODE_TYPE_IN_PROGRESS](context, payload: boolean) {
      context.commit(SET_CHANGE_NODE_TYPE_IN_PROGRESS, payload);
    }
  },
  mutations: {
    [SET_QUICK_BUILD_SETTINGS](state, payload: QuickBuildSettings) {
      state.document.quickBuildSettings = payload;
    },
    [SET_SELECTED_PAGE_SHOW_LEGEND](state: State, payload: boolean) {
      state.selectedPage.showLegend = payload;
    },
    [SET_READONLY](state, payload) {
      state.isReadOnly = payload;
    },
    [SET_DIAGRAM_EDITABLE](state, payload) {
      state.diagramEditable = payload;
    },
    [SET_SAVE_FAILED](state, payload) {
      state.saveFailed = payload;
    },
    [SET_DOCUMENT](state, payload) {
      if (!payload.headerStyle) {
        payload.headerStyle = {} as HeaderFooterStyleDto;
      }
      if (!payload.footerStyle) {
        payload.footerStyle = {} as HeaderFooterStyleDto;
      }
      if (!payload.quickBuildSettings) {
        payload.quickBuildSettings = null;
      }
      state.document = payload;
    },
    [SET_DOCUMENT_ACCESS](state, payload) {
      state.access = payload;
    },
    [SET_DOCUMENT_ACCESS_REQUESTS](state, payload) {
      state.accessRequests = payload;
    },
    [SET_DOCUMENT_ACCESS_LEVEL](state, payload) {
      state.accessLevel = payload;
    },
    [SET_DOCUMENT_ACCESS_FORBIDDEN](state, payload) {
      state.isAccessForbidden = payload;
    },
    [SET_DOCUMENT_ACTIVITY_BY](state, payload) {
      state.documentActivityBy = payload;
    },
    [SET_DOCUMENT_OWNER](state, payload) {
      state.document.ownerUserId = payload;
    },
    [SET_DOCUMENT_FILTERS](state, payload) {
      state.document.filters = payload;
    },
    [SET_DOCUMENT_MODIFICATION_TIME](state, payload) {
      state.documentModificationTime = payload;
    },
    [SET_SELECTED_PAGE](state, payload) {
      state.selectedPage = payload.page;
      state.selectedSubPageIndex = payload.subPageIndex ?? 0;
      const documentView =
        (payload.page?.getSubPageType(payload.subPageIndex) ===
        DocumentPageType.Content
          ? DocumentView.Print
          : payload.page?.diagram?.diagramViews?.selectedView) ??
        DocumentView.Print;

      state.documentView = documentView;
    },

    [SET_DOCUMENT_VIEW](state, payload: DocumentView) {
      if (!state.selectedPage?.diagram) {
        return;
      }
      if (!state.selectedPage.diagram.diagramViews) {
        state.selectedPage.diagram.diagramViews = {
          selectedView: payload
        };
      } else {
        state.selectedPage.diagram.diagramViews.selectedView = payload;
      }
      state.documentView = payload;
    },

    [SET_DOCUMENT_VERSION_TO_RESTORE](state, payload) {
      state.documentVersionToRestore = payload;
    },
    [SET_CURRENT_DOCUMENT_VERSION_NUMBER](state, payload) {
      state.currentDocumentVersionNumber = payload;
    },
    [SET_DOCUMENT_VERSIONS](state, payload) {
      state.documentVersions = payload;
    },
    [SET_RESTORED_VERSION_NUMBER](state, payload) {
      state.restoredVersionNumber = payload;
    },

    [SET_SELECTED_PAGE_SHOW_LOGO](state, payload: boolean) {
      if (!state.selectedPage) return;
      state.selectedPage.showLogo = payload;
    },
    [SET_SELECTED_SUBPAGE_INDEX](state, payload) {
      state.selectedSubPageIndex = payload;
    },
    [SET_SAVING_STATE](state, payload) {
      state.saving = payload;
    },
    [SET_SELECTION](state, payload) {
      state.selection = payload;
    },
    [SET_CURRENT_THEME](state, payload) {
      state.currentTheme = payload;
    },
    [REFRESH_CURRENT_THEME](state, payload) {
      state.currentTheme = null;
      state.currentTheme = payload;
    },
    [SET_QUICK_START_STATE](state, payload) {
      if (!state.selectedPage?.diagram) return;

      /* ensure only 1 qs active at a time */
      if (state.document.hasSteps && payload === QuickStartState.InProgress) {
        for (const page of state.document.pages) {
          if (
            page != state.selectedPage &&
            page.diagram?.quickStartStateData?.state ===
              QuickStartState.InProgress
          ) {
            const allowQuickstart = page.diagram.nodes.length < 2;
            page.diagram.quickStartStateData.state = allowQuickstart
              ? QuickStartState.Initial
              : QuickStartState.Complete;
          }
        }
      }

      state.selectedPage.diagram.quickStartStateData.state = payload;
    },

    [SET_RIBBON_DEFAULT_TAB](state, payload) {
      state.ribbonDefaultTab = payload;
    },
    [SET_DOCUMENT_HEADER_STYLE](state, payload) {
      state.document.headerStyle = payload;
    },
    [SET_DOCUMENT_FOOTER_STYLE](state, payload) {
      state.document.footerStyle = payload;
    },
    [UNLOAD_DOCUMENT](state) {
      Object.assign(state, getInitialState());
    },
    [SET_DOCUMENT_HEADER_FOOTER_STYLE](
      state,
      payload: {
        headerStyle: HeaderFooterStyleDto;
        footerStyle: HeaderFooterStyleDto;
      }
    ) {
      state.document.headerStyle = payload.headerStyle;
      state.document.footerStyle = payload.footerStyle;
    },

    [SET_DOCUMENT_PROFILE](state, payload: EditDocumentProfileDto) {
      state.documentProfile = payload;
      state.document.name = payload.name;
      state.document.client = payload.client;
      state.document.matter = payload.matter;
    },
    [SET_DOCUMENT_AUTOSAVE](state, payload: boolean) {
      state.document.autoSave = payload;
    },
    [SET_DOCUMENT_USE_PRESET_PROPERTIES](state, payload: boolean) {
      state.document.usePresetProperties = payload;
    },
    [UPDATE_DOCUMENT_FROM_THEME](state, payload: DocumentDto) {
      state.document = payload;
    },
    [SET_DOCUMENT_HEADER_FOOTER_VISIBILITY](state, payload) {
      if (!isNil(payload.showHeader)) {
        state.document.headerStyle.show = payload.showHeader;
        state.document.pages.forEach((page) => {
          page.showHeader = payload.showHeader;
        });
        DocumentService.syncHeaderFooterLayoutItemsVisibility(
          state.document,
          DocumentContentArea.Header
        );
      }
      if (!isNil(payload.showFooter)) {
        state.document.footerStyle.show = payload.showFooter;
        state.document.pages.forEach((page) => {
          page.showFooter = payload.showFooter;
        });
        DocumentService.syncHeaderFooterLayoutItemsVisibility(
          state.document,
          DocumentContentArea.Footer
        );
      }
    },
    [SET_DOCUMENT_PAGE_HEADER_FOOTER_VISIBILITY](state, payload) {
      state.selectedPage.showHeader =
        payload?.showHeader ?? state.selectedPage.showHeader;
      state.selectedPage.showFooter =
        payload?.showFooter ?? state.selectedPage.showFooter;
    },
    [SET_DOCUMENT_PAGE_TITLE_VISIBILITY](state, payload: boolean) {
      const selectedSubPageRef = getSelectedSubpageFromState(state);
      if (selectedSubPageRef) {
        // if title state is the same as in source page we remove subPageRef
        if (
          payload === state.selectedPage.showTitle &&
          selectedSubPageRef.titleLayout === state.selectedPage.titleLayout
        ) {
          const subPageRefIndex = state.selectedPage.subPageRefs.findIndex(
            (sp) => sp.subPageIndex === selectedSubPageRef.subPageIndex
          );
          state.selectedPage.subPageRefs.splice(subPageRefIndex, 1);
          DocumentPageTitleService.updateContinuationPagesMaxTitleHeight(
            state.selectedPage
          );
          return;
        }

        selectedSubPageRef.showTitle = payload;
      } else if (
        !selectedSubPageRef &&
        state.selectedPage.contentType === DocumentPageContentType.Html &&
        (state.selectedSubPageIndex > 0 ||
          ContentPagination.getPageCount(state.selectedPage) > 1)
      ) {
        state.selectedPage.subPageRefs.push({
          pageId: state.selectedPage.id,
          subPageIndex: state.selectedSubPageIndex,
          titleHeight: state.selectedPage.titleHeight,
          maxTitleHeight: state.selectedPage.maxTitleHeight,
          titleLayout: state.selectedPage.titleLayout,
          showTitle: payload
        });
        DocumentPageTitleService.updateContinuationPagesMaxTitleHeight(
          state.selectedPage
        );
      } else {
        state.selectedPage.showTitle = payload;
      }
    },
    [ADD_DOCUMENT_ATTACHMENT](state, payload: DocumentAttachmentDto) {
      state.document.attachments.push(payload);
    },
    [SET_ACTIVE_SIDEBAR_BUTTON](state, payload: SidebarCategory) {
      state.selectedSidebarButton = payload;
    },
    [SET_LAST_SELECTED_SIDEBAR_BUTTON](state, payload: SidebarCategory) {
      state.lastSelectedSidebarButton = payload;
    },
    [UPDATE_SELECTED_DIAGRAM](state, payload: DiagramDto) {
      if (isNil(state.selectedPage?.subPageRefs)) {
        state.selectedPage.diagram = payload;
        return;
      }
      const ref = state.selectedPage.subPageRefs.find(
        (r) => r.diagramId == payload.id
      );
      if (ref) {
        ref.diagram = payload;
      } else {
        state.selectedPage.diagram = payload;
      }
    },
    [SET_SELECTED_PAGE_BODY_LAYOUT](state, payload) {
      state.selectedPage.bodyLayout = payload;
    },
    [SET_SELECTED_PAGE_HEADER](state, payload) {
      const selectedSubPageRef =
        DocumentService.subPageHeaderFooterLayoutAvailable
          ? getSelectedSubpageFromState(state)
          : null;
      if (selectedSubPageRef) {
        state.selectedPage.subPageRefs.forEach((r) => {
          if (r.diagramId === selectedSubPageRef.diagramId) {
            r.headerLayout = payload;
          }
        });
      } else {
        Vue.set(state.selectedPage, 'headerLayout', payload);
      }
    },
    [SET_SELECTED_PAGE_FOOTER](state, payload) {
      const selectedSubPageRef =
        DocumentService.subPageHeaderFooterLayoutAvailable
          ? getSelectedSubpageFromState(state)
          : null;
      if (selectedSubPageRef) {
        state.selectedPage.subPageRefs.forEach((r) => {
          if (r.diagramId === selectedSubPageRef.diagramId) {
            r.footerLayout = payload;
          }
        });
      } else {
        Vue.set(state.selectedPage, 'footerLayout', payload);
      }
    },
    [SET_SELECTED_PAGE_TITLE](state, payload) {
      const selectedSubPageRef = getSelectedSubpageFromState(state);
      if (selectedSubPageRef) {
        selectedSubPageRef.titleLayout = payload;
      } else {
        Vue.set(state.selectedPage, 'titleLayout', payload);
      }
    },
    [SET_SELECTED_PAGE_BACKGROUND](state, payload) {
      const selectedSubPageRef = getSelectedSubpageFromState(state);
      if (selectedSubPageRef) {
        state.selectedPage.subPageRefs.forEach((r) => {
          if (r.diagramId === selectedSubPageRef.diagramId) {
            r.backgroundLayout = payload;
          }
        });
      } else {
        state.selectedPage.backgroundLayout = payload;
      }
    },
    [SET_SELECTED_PAGE_TITLE_HEIGHT](state, payload: number) {
      const selectedSubPageRef = getSelectedSubpageFromState(state);

      if (selectedSubPageRef) {
        selectedSubPageRef.titleHeight = payload;
      } else {
        state.selectedPage.titleHeight = payload;
      }
    },
    [SET_SELECTED_PAGE_CONTENT](state, payload) {
      state.selectedPage.content = payload;
    },
    [SET_SELECTED_PAGE_COLLABORATION_DATA](state, payload) {
      Vue.set(state.selectedPage, 'collaborationData', payload);
    },
    [SET_PAGE_HEADER_FROM_THEME](state, payload) {
      const page = state.document.pages.find(
        (page) => page.id == payload.page.id
      );
      if (page) {
        page.headerLayout = payload.headerLayout;
      }
    },
    [SET_PAGE_FOOTER_FROM_THEME](state, payload) {
      const page = state.document.pages.find(
        (page) => page.id == payload.page.id
      );
      if (page) {
        page.footerLayout = payload.footerLayout;
      }
    },
    [SET_PAGE_BACKGROUND_FROM_THEME](state, payload) {
      const page = state.document.pages.find(
        (page) => page.id == payload.page.id
      );
      if (page) {
        page.backgroundLayout = payload.backgroundLayout;
      }
    },
    [SET_SUBPAGE_HEADER_FROM_THEME](
      state,
      payload: { subPage: DocumentSubPageDto; headerLayout: string }
    ) {
      const subPage = state.document.pages
        .find((page) => page.id === payload.subPage.pageId)
        .subPageRefs.find(
          (subPage) => subPage.subPageIndex === payload.subPage.subPageIndex
        );
      if (subPage) {
        subPage.headerLayout = payload.headerLayout;
      }
    },
    [SET_SUBPAGE_FOOTER_FROM_THEME](
      state,
      payload: { subPage: DocumentSubPageDto; footerLayout: string }
    ) {
      const subPage = state.document.pages
        .find((page) => page.id === payload.subPage.pageId)
        .subPageRefs.find(
          (subPage) => subPage.subPageIndex === payload.subPage.subPageIndex
        );
      if (subPage) {
        subPage.footerLayout = payload.footerLayout;
      }
    },
    [SET_SUBPAGE_BACKGROUND_FROM_THEME](
      state,
      payload: { subPage: DocumentSubPageDto; backgroundLayout: string }
    ) {
      const subPage = state.document.pages
        .find((page) => page.id === payload.subPage.pageId)
        .subPageRefs.find(
          (subPage) => subPage.subPageIndex === payload.subPage.subPageIndex
        );
      if (subPage) {
        subPage.backgroundLayout = payload.backgroundLayout;
      }
    },
    [SET_FOCUSED_SIDE](state, payload: DocumentFocusedSide) {
      state.focusedSide = payload;
    },
    [SET_DOCUMENT_ERROR](state, payload) {
      state.documentError = payload;
    },
    [SET_DOCUMENT_EXTERNAL_FILE_ID](state, payload) {
      state.documentExternalFileId = payload;
    },
    [SET_SELECTED_PAGE_FILTER_DEFINITION](state, payload) {
      state.selectedPage.filterDefinition = payload;
    },
    [SET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES](
      state,
      data: {
        type: JurisdictionValueType;
        dataPropertyDisplayType: DataPropertyDisplayType;
      }
    ) {
      const persistedTypes = state.persistedDataPropertyDisplayTypes;
      const persistedType = state.persistedDataPropertyDisplayTypes[data.type];

      if (
        persistedType &&
        persistedType.includes(data.dataPropertyDisplayType)
      ) {
        const existingIndex = persistedType.findIndex(
          (dp) => dp === data.dataPropertyDisplayType
        );
        existingIndex >= 0 &&
          persistedTypes[data.type].splice(existingIndex, 1);
      } else {
        if (isArray(persistedType)) {
          persistedTypes[data.type].push(data.dataPropertyDisplayType);
        } else {
          persistedTypes[data.type] = [data.dataPropertyDisplayType];
        }
      }
    },
    [DELETE_THEME_ATTACHMENT](
      state,
      payload: { themeId: number; fileId: string }
    ) {
      const index = state.currentTheme.attachments.findIndex(
        (x) => x.fileAttachment.fileId === payload.fileId
      );
      state.currentTheme.attachments.splice(index, 1);
    },
    [DELETE_DOCUMENT_ATTACHMENT](
      state,
      payload: { documentId: number; fileId: string }
    ) {
      const index = state.document.attachments.findIndex(
        (x) => x.fileAttachment.fileId === payload.fileId
      );
      state.document.attachments.splice(index, 1);
    },
    [ADD_THEME_ATTACHMENT](state, payload: ThemeAttachmentDto) {
      state.currentTheme.attachments.push(payload);
    },
    [SET_DOCUMENT_FLIPBOOK_STATE](state, payload: FlipbookState) {
      state.document.flipbookState = payload;
      state.document.pages.forEach((p) => {
        if (p?.diagram) {
          p.diagram.flipbookState = payload;
        }
      });
    },
    [SET_DIAGRAM_FLIPBOOK_STATE](
      state,
      payload: { value: FlipbookState; diagramId: number }
    ) {
      const page = state.document.pages.find(
        (p) => p.diagram?.id === payload.diagramId
      );
      if (page) {
        page.diagram.flipbookState = payload.value;
      }
    },
    [SET_DOCUMENT_CAN_FLIPBOOK](state, payload) {
      state.canFlipbook = payload;
    },
    [SET_AUTOZOOM](state, payload) {
      state.autoZoom = payload;
    },
    [SET_SHOW_GRID](state, payload) {
      state.showGrid = payload;
    },
    [SET_SHOW_VERSION_HISTORY_MODAL](state, payload) {
      state.showVersionHistoryModal = payload;
    },
    [SET_PAGE_SYNC_CONTEXT](state, payload) {
      state.pageSyncContext = payload;
    },

    [SET_DOCUMENT_SHOW_DATE](state, payload: boolean) {
      if (!isNil(payload)) {
        if (payload) {
          DocumentService.syncHeaderFooterWidgetItems(state.document, {
            type: LayoutItemType.Date,
            widgetPosition: PageElementPosition.TopRight,
            containerSize: null
          });
        }

        state.document.pages.forEach((page) => {
          if (page == state.selectedPage) {
            state.selectedPage.showDate = payload;
          } else {
            page.showDate = payload;
          }

          LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
            page,
            LayoutItemType.Date,
            !payload
          );
        });
      }
    },
    [SET_DOCUMENT_SHOW_PAGE_NUMBER](state, payload: boolean) {
      if (!isNil(payload)) {
        if (payload) {
          DocumentService.syncHeaderFooterWidgetItems(state.document, {
            type: LayoutItemType.PageNumber,
            widgetPosition: PageElementPosition.BottomRight,
            widgetPreset: LayoutWidgetUtils.pageNumberPresetsArray[0],
            containerSize: null
          });
        }

        state.document.pages.forEach((page) => {
          if (page == state.selectedPage) {
            state.selectedPage.showPageNumber = payload;
          } else {
            page.showPageNumber = payload;
          }

          LayoutWidgetUtils.updateItemVisibilityFirstOrDefault(
            page,
            LayoutItemType.PageNumber,
            !payload
          );
        });
      }
    },
    [UPDATE_DIAGRAM_VIEW_POINT](state, payload: UpdateDiagramViewPoint) {
      if (state.selectedPage.diagram.diagramViews == null) {
        state.selectedPage.diagram.diagramViews = {
          web: null,
          print: null,
          printPreview: null
        };
      }
      const viewPoint: DiagramViewDto = {
        zoom: payload.zoom,
        location: new PointDto(payload.location.x, payload.location.y),
        data: payload.data
      };

      //state.selectedPage.diagram.diagramViews.selectedView = payload.activeView;
      switch (payload.activeView) {
        case DocumentView.Web:
          state.selectedPage.diagram.diagramViews.web = viewPoint;
          break;
        case DocumentView.Print:
          state.selectedPage.diagram.diagramViews.print = viewPoint;
          break;
        case DocumentView.PrintPreview:
          state.selectedPage.diagram.diagramViews.printPreview = viewPoint;
          state.selectedPage.diagram.diagramViews.print = viewPoint;
          break;
      }
    },

    [UPDATE_DIAGRAM_VIEW_POINT_DATA](
      state,
      payload: UpdateDiagramViewPointData
    ) {
      const diagramViews = state.selectedPage.diagram.diagramViews;
      const data = payload.data;
      const initIfNull = (dv: DiagramViewDto, data: any): DiagramViewDto => {
        if (!dv) {
          dv = { zoom: 0, data: data, location: null };
        } else {
          dv.data = data;
        }
        return dv;
      };
      switch (payload.documentView) {
        case DocumentView.Web:
          diagramViews.web = initIfNull(diagramViews.web, data);
          break;
        case DocumentView.Print:
          diagramViews.print = initIfNull(diagramViews.print, data);
          break;
        case DocumentView.PrintPreview:
          diagramViews.printPreview = initIfNull(
            diagramViews.printPreview,
            data
          );
          break;
      }
    },
    [SET_CURRENT_PAGE_LIST_ITEM](state, payload: PageListItem) {
      state.currentPageListItem = payload;
    },
    [SET_SHOW_MASTER_LEGEND_MODAL](state, payload: boolean) {
      state.showMasterLegendModal = payload;
    },
    [SET_INITIAL_LEGEND_LAYOUT_SET](state, payload: boolean) {
      state.initialLegendLayoutSet = payload;
    },
    [SET_DOCUMENT_LOADED](state, payload: boolean) {
      state.documentLoaded = payload;
    },

    [SET_DIAGRAM_GROUPING](
      state: State,
      payload: {
        margin: number;
        glueExtent: number;
        minimumBridgeSize: number;
      }
    ) {
      if (!state.selectedPage?.diagram) {
        console.debug(
          `Cannot set diagram grouping without diagram page selected`
        );
        return;
      }
      if (!state.selectedPage.diagram.groupSettings) {
        Vue.set(state.selectedPage.diagram, 'groupSettings', {
          margin: diagramConfig.groupingDefaults.glueExtent,
          glueExtent: diagramConfig.groupingDefaults.margin,
          minimumBridgeSize: diagramConfig.groupingDefaults.minimumBridgeSize
        });
      }
      state.selectedPage.diagram.groupSettings = Object.assign(
        state.selectedPage.diagram.groupSettings,
        payload
      );
    },
    [SET_EDGE_WITH_LABEL_PLACEHOLDER](state, payload: IEdge | null): void {
      state.edgeWithLabelPlaceholder = payload;
    },
    [SET_TABLE_OF_CONTENT](
      state: State,
      payload: Array<ContentTableItem>
    ): void {
      state.tableOfContent = payload;
    },
    [SET_IS_TRACK_CHANGES_ENABLED](state: State, payload: boolean): void {
      state.isTrackChangesEnabled = payload;
    },
    [SET_HTML_CONTENT_FOCUSED](state, payload: boolean): void {
      state.htmlCkEditorFocused = payload;
    },
    [SET_DOCUMENT_TUTORIAL](state: State, payload: Partial<TutorialDto>): void {
      state.document.tutorial = { ...state.document.tutorial, ...payload };
    },
    [SET_EDGE_LABEL_PLACEHOLDER_ENABLED](state: State, payload: boolean): void {
      state.edgeLabelPlaceholderEnabled = payload;
    },
    [SET_CHANGE_NODE_TYPE_IN_PROGRESS](state, payload: boolean) {
      state.changeNodeTypeInProgress = payload;
    }
  }
};

function getSelectedSubpageFromState(state: State): DocumentSubPageDto {
  return state.selectedPage?.subPageRefs?.find(
    (sp) => sp.subPageIndex === state.selectedSubPageIndex
  );
}

/**
 * Takes a document and updates all images from the supplied theme
 * @param document the document to update
 * @param theme the theme to pull from
 * @param documentAttachmentType the document attachment type
 * @param AttachmentType the theme attachment type that matches document attachment type
 */
function replaceDocumentAttachmentsFromTheme(
  document: DocumentDto,
  theme: ThemeDto,
  documentAttachmentType: AttachmentType,
  attachmentType: AttachmentType
): void {
  // find any existing attachments to remove
  const documentAttachments = document.attachments.filter(
    (x) => x.attachmentType == documentAttachmentType
  );

  // remove any attachments in revsere
  for (let index = documentAttachments.length - 1; index >= 0; index--) {
    document.attachments.splice(
      document.attachments.indexOf(documentAttachments[index]),
      1
    );
  }

  // find any theme attachments to use if any
  if (theme.attachments) {
    const themeImages = theme.attachments.filter(
      (x) => x.attachmentType == attachmentType
    );

    // add theme attachments to document
    if (themeImages && themeImages.length > 0) {
      for (const themeImage of themeImages) {
        document.attachments.push(
          new DocumentAttachmentDto(
            document.id,
            themeImage.fileAttachmentId,
            null,
            themeImage.fileAttachment,
            documentAttachmentType
          )
        );
      }
    }
  }
}

export type UpdateDiagramViewPoint = {
  activeView: DocumentView;
  location: Point;
  zoom: number;
  data?: any;
};

export type UpdateDiagramViewPointData = {
  documentView: DocumentView;
  data: any;
};

export type QuickBuildSettings = {
  minimumNodeDistance: number;
  minimumLayerDistance: number;
  showAdvancedLayoutSettings: boolean;
};

export type QuickBuildSettingsChangedEvent = QuickBuildSettings & {
  showAdvancedChanged?: boolean;
  nodeDistanceChanged?: boolean;
  verticalDistanceChanged?: boolean;
  horizontalDistanceChanged?: boolean;
};

export default documentModule;
