import JSize from '../common/JSize';
import BackgroundDomService from '../services/BackgroundDomService';
import { ZERO_WIDTH_SPACE } from './CKEditorUtils';
import { wait } from './common.utils';

export function addClass(e, className) {
  const classes = e.getAttribute('class');
  if (classes === null || classes === '') {
    e.setAttribute('class', className);
  } else if (!hasClass(e, className)) {
    e.setAttribute('class', `${classes} ${className}`);
  }
  return e;
}

export function removeClass(e, className) {
  const classes = e.getAttribute('class');
  if (classes !== null && classes !== '') {
    if (classes === className) {
      e.setAttribute('class', '');
    } else {
      const result = classes
        .split(' ')
        .filter((s) => s !== className)
        .join(' ');
      e.setAttribute('class', result);
    }
  }
  return e;
}

export function hasClass(e, className) {
  const classes = e.getAttribute('class');
  const r = new RegExp(`\\b${className}\\b`, '');
  return r.test(classes);
}

/**
 *
 * @param {Element} e
 * @param {string} className
 * @return {Element}
 */
export function toggleClass(e, className) {
  if (hasClass(e, className)) {
    removeClass(e, className);
  } else {
    addClass(e, className);
  }
  return e;
}

/**
 * Binds the given command to the input element specified by the given selector.
 *
 * @param {string} selector
 * @param {ICommand} command
 * @param {CanvasComponent|GraphComponent} target
 * @param {Object?} parameter
 */
export function bindCommand(selector, command, target, parameter) {
  const element = document.querySelector(selector);
  if (arguments.length < 4) {
    parameter = null;
    if (arguments.length < 3) {
      target = null;
    }
  }
  if (!element) {
    return;
  }
  command.addCanExecuteChangedListener((sender, e) => {
    if (command.canExecute(parameter, target)) {
      element.removeAttribute('disabled');
    } else {
      element.setAttribute('disabled', 'disabled');
    }
  });
  element.addEventListener('click', (e) => {
    if (command.canExecute(parameter, target)) {
      command.execute(parameter, target);
    }
  });
}

/**
 * @param {string} selector
 * @param {function(Event)} action
 */
export function bindAction(selector, action) {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }
  element.addEventListener('click', (e) => {
    action(e);
  });
}

/**
 * @param {string} selectors
 * @param {function(Event)} action
 */
export function bindActions(selectors, action) {
  const elements = document.querySelectorAll(selectors);
  if (!elements) {
    return;
  }
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.addEventListener('click', (e) => {
      action(e);
    });
  }
}

/**
 * @param {string} selector
 * @param {function(string|boolean)} action
 */
export function bindChangeListener(selector, action) {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }
  element.addEventListener('change', (e) => {
    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      action(e.target.checked);
    } else {
      action(e.target.value);
    }
  });
}

export function htmlToElement(html: string): HTMLElement {
  const template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild as HTMLElement;
}

export function encodeHtml(html: string): string {
  return html
    .replaceAll(/&/g, '&amp;')
    .replaceAll(/</g, '&lt;')
    .replaceAll(/>/g, '&gt;')
    .replaceAll(/"/g, '&quot;')
    .replaceAll(/'/g, '&apos;');
}

export function decodeHtml(html: string): string {
  return html
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&quot;', `"`)
    .replaceAll('&apos;', "'");
}

export function measureElement(element: HTMLElement): JSize {
  BackgroundDomService.appendElement(element);
  const size = JSize.fromHtmlElement(element);
  BackgroundDomService.removeElement(element);
  return size;
}

function createMeasureContainer(): HTMLElement {
  const el = BackgroundDomService.createElement('div');
  el.style.position = 'absolute';
  el.style.border = '1px solid black';
  el.style.width = 'fit-content';
  return el;
}

export function measureText(html: string): JSize {
  const container = createMeasureContainer();
  container.innerHTML = html;
  return measureElement(container);
}

/**
 *
 * @param container Parent element relative to which the child will be calculated
 * @param el Children element within the parent one
 * @param absolute Determine whether the element is absolutely invisible (e.g. if 1px of the children element still visible - function will return true)
 * @returns true/false
 */

export const isElementInView = function (
  container: HTMLElement,
  el: HTMLElement,
  absolute = false
): boolean {
  if (!(container instanceof HTMLElement)) {
    throw new Error('Container is not valid HTML element');
  }

  if (!(el instanceof HTMLElement)) {
    throw new Error('Element is not valid HTML element');
  }

  const { top: cTop, bottom: cBottom } = container.getBoundingClientRect();
  const { top: elTop, bottom: elBottom, height } = el.getBoundingClientRect();

  if (
    elTop >= cTop - (absolute ? height : 0) &&
    elBottom <= cBottom + (absolute ? height : 0)
  ) {
    return true;
  }

  return false;
};

export const elementVerticallyAligned = (
  el1: HTMLElement,
  el2: HTMLElement
): boolean => {
  if (!(el1 instanceof HTMLElement)) {
    throw new Error('Element is not valid HTML element');
  }

  if (!(el2 instanceof HTMLElement)) {
    throw new Error('Element is not valid HTML element');
  }

  const { top: el1Top } = el1.getBoundingClientRect();
  const { top: el2Top } = el2.getBoundingClientRect();

  return Math.floor(el2Top) === Math.floor(el1Top);
};

export function copyElementAttributes(
  sourceElement: Element,
  targetElement: Element
): void {
  for (const attr of sourceElement.attributes) {
    targetElement.setAttribute(attr.name, attr.value);
  }
}

export function stripHtml(htmlString: string, withSpace = false): string {
  if (!htmlString) {
    return htmlString;
  }

  const string = htmlString
    .replaceAll(ZERO_WIDTH_SPACE, '')
    .replace(/<[^>]+>/g, withSpace ? ' ' : '')
    .replaceAll('&nbsp;', '');

  if (withSpace) {
    return string.trim().split(' ').filter(Boolean).join(' ');
  }

  return string;
}

export function extractTextWithLinebreaks(htmlString: string): string {
  if (htmlString == null) {
    return null;
  }

  const tmpElement = document.createElement('div');
  tmpElement.innerHTML = htmlString;

  let result = '';
  function traverseNodes(node: HTMLElement): void {
    if (node.nodeType === Node.TEXT_NODE) {
      result += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName === 'BR') {
        result += '\n\n';
      }
      for (var i = 0; i < node.childNodes.length; i++) {
        traverseNodes(node.childNodes[i] as HTMLElement);
      }
    }
  }

  traverseNodes(tmpElement);

  return result;
}

export async function waitDocumentHasFocus(
  timeoutMs: number
): Promise<boolean> {
  if (document.hasFocus()) {
    return true;
  }
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < timeoutMs) {
    if (document.hasFocus()) {
      return true;
    }
    await wait(100);
  }
  return false;
}

export function findElementParents(el: HTMLOrSVGElement): HTMLOrSVGElement[] {
  if (!el) {
    return [];
  }
  const parents = [el];
  const parentElement = (el as SVGElement | HTMLElement).parentElement;
  const outerParents = findElementParents(parentElement);
  parents.push(...outerParents);
  return parents;
}

/*
Takes a font size string and ensure you get the value as a number back in points
 */
export function ensureFontSizeUnit(sizeString: string): number {
  let fontSizePts = 0;
  if (sizeString.indexOf('px') >= 0) {
    let result = convertPixelsToPoints(Number(sizeString.replace('px', '')));
    fontSizePts = Math.floor(result) + closestValue(result % 1, [0, 0.5, 1]);
  } else if (sizeString.indexOf('pt') >= 0) {
    fontSizePts = Number.parseFloat(sizeString.replace('pt', ''));
  } else {
    fontSizePts = Number.parseFloat(sizeString);
  }
  return fontSizePts;
}

export function closestValue(value: number, values: number[]): number {
  return values.reduce((prev, curr): number => {
    return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
  });
}

export function convertPixelsToPoints(pixels): number {
  return pixels * (72 / 96);
}

export function convertPointsToPixels(points): number {
  return points * (96 / 72);
}

export function setElementHtmlByQuerySelector(
  html: string,
  querySelector: string,
  value: string
): string {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  const element = wrapper.querySelector(querySelector);
  if (element) {
    element.innerHTML = value;
    html = wrapper.innerHTML;
  }
  wrapper.remove();

  return html;
}

export function replaceHtmlElements(
  html: string,
  originalElementName: string,
  newElementName: string
): string {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  const elements = wrapper.getElementsByTagName(originalElementName);
  while (elements.length > 0) {
    const newElement = document.createElement(newElementName);
    const originalElement = elements[0];
    copyElementAttributes(originalElement, newElement);
    newElement.innerHTML = originalElement.innerHTML;
    while (originalElement.firstChild) {
      newElement.appendChild(originalElement.firstChild);
    }
    originalElement.replaceWith(newElement);
  }

  html = wrapper.innerHTML;
  wrapper.remove();
  return html;
}

function detectPointerEventsSupported(): boolean {
  const testDiv = document.createElement('div');
  testDiv.style.cssText = 'pointer-events:auto';
  return testDiv.style.pointerEvents === 'auto';
}

export function wrapInTag(element: string, tagName: string): string {
  return `<${tagName}>${element}</${tagName}>`;
}
export function wrapNode(node: Node, tagName: string): HTMLElement {
  const wrapper = document.createElement(tagName);
  node.parentNode.insertBefore(wrapper, node);
  wrapper.appendChild(node);
  return wrapper;
}

export function setSelectionAtLocation(x: number, y: number): void {
  let range: Range = null;
  const w = window as any;
  if (w.document.caretRangeFromPoint) {
    // WebKit
    range = w.document.caretRangeFromPoint(x, y);
  } else if (w.document.caretPositionFromPoint) {
    const caretPosition = w.document.caretPositionFromPoint(x, y);
    // Mozilla
    range = document.createRange();
    range.setStart(caretPosition.offsetNode, caretPosition.offset);
  }

  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
}

export function findStackingContextParent(el: HTMLElement): HTMLElement {
  let parent = el.parentElement;
  while (
    parent &&
    getComputedStyle(parent).zIndex === 'auto' &&
    parent !== document.body
  ) {
    parent = parent.parentElement;
  }
  return parent;
}

export function getRelativeParentRect(el: HTMLElement): DOMRect {
  let parent = el.parentElement;
  if (!parent) return;
  while (
    parent &&
    getComputedStyle(parent).position === 'static' &&
    parent !== document.body
  ) {
    parent = parent.parentElement;
  }
  return parent.getBoundingClientRect();
}

/** States whether the browser supports the pointer-events CSS property */
export const pointerEventsSupported = detectPointerEventsSupported();

export function mergeTextIntoParent(html): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const firstTextElement = Array.from(doc.body.getElementsByTagName('*')).find(
    (element) =>
      Array.from(element.childNodes).some(
        (node) =>
          node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== ''
      )
  );

  if (!firstTextElement) {
    return html;
  }

  const parentElement =
    (firstTextElement.parentNode as HTMLElement) ||
    (firstTextElement as HTMLElement);
  firstTextElement.textContent = stripHtml(parentElement.innerHTML);

  parentElement.innerHTML = '';
  parentElement.appendChild(firstTextElement);
  return doc.body.innerHTML;
}
