import appConfig from '@/core/config/appConfig';
import SignalRHelper from './SignalRHelper';

type InvokeMethods = 'RegisterDocument' | 'DeregisterDocument';

class RealTimeDocumentService {
  public signalRHelper: SignalRHelper<InvokeMethods, string>;

  public start(): void {
    this.signalRHelper = new SignalRHelper<InvokeMethods, string>(
      appConfig.realTime.document.endpoint
    );
    this.signalRHelper.start();
  }

  public async registerDocument(documentId: number): Promise<void> {
    if (this.signalRHelper.isConnected) {
      await this.signalRHelper.invoke('RegisterDocument', documentId);
    }
  }

  public async deregisterDocument(documentId: number): Promise<void> {
    if (this.signalRHelper.isConnected) {
      await this.signalRHelper.invoke('DeregisterDocument', documentId);
    }
  }
}
const instance = new RealTimeDocumentService();
export default instance;
