import axios, {
    AxiosResponse
} from 'axios';
import {
    TutorialDto,
    ZadarResponse
} from './models'
export default class TutorialsApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < TutorialDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/Tutorials/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
}