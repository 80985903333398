import {
  Fill,
  GeneralPath,
  IInputModeContext,
  INode,
  IRenderContext,
  NodeStyleBase,
  Point,
  Stroke,
  SvgVisual,
  Visual
} from 'yfiles';

export type TextBoxNodeStyleOptions = {
  fill?: Fill;
  stroke?: Stroke;
};
const hitBoxRadiusModifier = 15;
export default class TextBoxNodeStyle extends NodeStyleBase {
  public static defaultOptions: TextBoxNodeStyleOptions = {
    fill: Fill.TRANSPARENT,
    stroke: Stroke.TRANSPARENT
  };

  public static INSTANCE = new TextBoxNodeStyle();
  public get fill(): Fill {
    return this.options.fill;
  }

  public get stroke(): Stroke {
    return this.options.stroke;
  }

  constructor(private options?: TextBoxNodeStyleOptions | null) {
    super();
    this.options = Object.assign({}, TextBoxNodeStyle.defaultOptions, options);
  }

  createVisual(context: IRenderContext, node: INode): Visual | null {
    return new SvgVisual(this.renderVisual(context, null, node));
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode
  ): Visual {
    this.renderVisual(context, oldVisual.svgElement, node);
    return oldVisual;
  }

  private renderVisual(
    context: IRenderContext,
    oldVisualElement: SVGElement,
    node: INode
  ): SVGElement {
    if (!oldVisualElement) {
      oldVisualElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      );
      this.options.stroke.applyTo(oldVisualElement, context);
      this.options.fill.applyTo(oldVisualElement, context);
    }
    const layout = node.layout;
    oldVisualElement.setAttribute('width', layout.width.toString());
    oldVisualElement.setAttribute('height', layout.height.toString());
    SvgVisual.setTranslate(oldVisualElement, layout.x, layout.y);
    return oldVisualElement;
  }

  protected getOutline(node: INode): GeneralPath {
    var path = new GeneralPath();
    path.appendRectangle(node.layout, false);

    return path;
  }

  public isHit(
    context: IInputModeContext,
    location: Point,
    node: INode
  ): boolean {
    return node.layout
      .toRect()
      .getEnlarged(hitBoxRadiusModifier)
      .contains(location);
  }
}
