import Vue from 'vue';
import {
  ADD_ANNOTATION,
  COLLABORATION_NAMESPACE,
  REMOVE_ANNOTATION,
  RESET_ANNOTATIONS,
  SET_ACTIVE_ANNOTATIONS,
  SET_ANNOTATIONS,
} from '../../store/collaboration.module';
import AnnotationData from './AnnotationData';

export default class AnnotationsUIHandler {
  public static handleActivated(annotations: AnnotationData[]): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${SET_ANNOTATIONS}`,
      annotations.map((a) => new AnnotationData(a.annotation, a.data)),
    );
  }

  public static handleDeactivated(): void {
    Vue.$globalStore.commit(`${COLLABORATION_NAMESPACE}/${RESET_ANNOTATIONS}`);
  }

  public static handleAnnotationAdded(annotation: AnnotationData): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${ADD_ANNOTATION}`,
      new AnnotationData(annotation.annotation, annotation.data),
    );
  }

  public static handleAnnotationRemoved(annotation: AnnotationData): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${REMOVE_ANNOTATION}`,
      new AnnotationData(annotation.annotation, annotation.data),
    );
  }

  public static handleActiveAnnotationChanged(
    annotation: AnnotationData,
  ): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${SET_ACTIVE_ANNOTATIONS}`,
      [new AnnotationData(annotation.annotation, annotation.data)],
    );
  }
}
