import IImageLayoutItemOptions from './IImageLayoutItemOptions';
import LayoutItem from './LayoutItem';
import JRect from '@/core/common/JRect';
import { LayoutItemType } from './LayoutItemType';
import JInsets from '@/core/common/JInsets';

export default class ImageLayoutItem extends LayoutItem {
  public imageSrc: string;
  public cropping: boolean = false;
  public originalImageLayout: JRect = null;
  public originalImageSrc: string;
  public cropArea: JInsets = null;

  constructor(options?: IImageLayoutItemOptions) {
    super(LayoutItemType.Image);
    this.imageSrc = options?.imageSrc ?? options?.originalImageSrc;
    if (
      options?.originalImageLayout &&
      !(options.originalImageLayout instanceof JRect)
    ) {
      this.originalImageLayout = new JRect(
        (options.originalImageLayout as any).x,
        (options.originalImageLayout as any).y,
        (options.originalImageLayout as any).width,
        (options.originalImageLayout as any).height
      );
    } else {
      this.originalImageLayout = options?.originalImageLayout as JRect;
    }
    this.originalImageSrc = options?.originalImageSrc;

    if (options?.cropArea) {
      this.cropArea = new JInsets(
        options.cropArea.left,
        options.cropArea.top,
        options.cropArea.right,
        options.cropArea.bottom
      );
    }
  }

  public serialize(): Object {
    const obj = super.serialize();
    if (this.cropArea) {
      obj['originalImageLayout'] = this.originalImageLayout;
      obj['originalImageSrc'] = this.originalImageSrc;
      obj['cropArea'] = this.cropArea;
    } else {
      obj['imageSrc'] = this.imageSrc;
    }
    return obj;
  }
}
