import axios, {
    AxiosResponse
} from 'axios';
import {
    PageDesignSetDto,
    ZadarResponse,
    PagedResultDtoOfPageDesignSetDto,
    CreatePageDesignSetResponseDto,
    FileDto
} from './models'
export default class PageDesignApiService {
    public static getDefaultSet(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetDefaultSet`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getSetById(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetSetById`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getSetByUuid(params ? : {
        uuid ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetSetByUuid`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getAllSets(params ? : {
        filter ? : string,
        defaultEntityRequired ? : boolean,
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfPageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetAllSets`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreatePageDesignSetResponseDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/CreateSet`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/UpdateSet`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static deleteSet(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/DeleteSet`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static setDefaultPageDesignSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/SetDefaultPageDesignSet`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static
    export (params ? : {
        uuid ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/Export`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static uploadFile(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/UploadFile`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static import(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/Import`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}