import { CompositeItemType, NodeShape } from '@/api/models';
import { Fill, GeneralPath, PathType, Point, Rect, Size, Stroke } from 'yfiles';
import { normalize } from '@/core/utils/math.utils';
import Raphael from 'raphael';
import CompositeItem from './CompositeItem';

import CompositePathItemStyle from './CompositePathItemStyle';
import CompositeShapeItemStyle from './CompositeShapeItemStyle';
const svgPathRegEx =
  /([ml](\s?-?((\d+(\.\d+)?)|(\.\d+)))[,\s]?(-?((\d+(\.\d+)?)|(\.\d+))))|([hv](\s?-?((\d+(\.\d+)?)|(\.\d+))))|(c(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s]?(-?((\d+(\.\d+)?)|(\.\d+)))){5})|(q(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s]?(-?((\d+(\.\d+)?)|(\.\d+)))){3}(\s?t?(\s?-?((\d+(\.\d+)?)|(\.\d+)))[,\s]?(-?((\d+(\.\d+)?)|(\.\d+))))*)|(a(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s]?(-?((\d+(\.\d+)?)|(\.\d+)))){2}[,\s]?[01][,\s]+[01][,\s]+([,\s]?(-?((\d+(\.\d+)?)|(\.\d+)))){2})|(s(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s]?(-?((\d+(\.\d+)?)|(\.\d+)))){3})|z/gi;
const svgPathCoordRegEx = /(-?\d+(\.\d+)?)/g;

export function convertPathToAbsolute(pathString: string): string {
  const convertToString = function (arr: []): string {
    let str = '';
    for (let i = 0; i < arr.length; i++) {
      const command = arr[i] as [];
      for (let j = 0; j < command.length; j++) {
        const element = command[j];
        if (j > 1) {
          str += ',';
        }
        // ignore any high precision values
        str += Math.abs(element) < 1e-10 ? 0 : element;
      }
      str += ' ';
    }
    return str;
  };
  const el = document.createElement('canvas');

  document.body.append(el);

  const pathStringRel = Raphael.pathToRelative(pathString);
  const pathStringAbs = Raphael._pathToAbsolute(pathStringRel);
  const absolutePath = convertToString(pathStringAbs);

  el.remove();
  return absolutePath;
}
export function parsePath(
  pathString: string,
  options: { normalize: boolean; box: Rect } = null
): GeneralPath {
  options = options || { box: null, normalize: false };

  if (options.normalize && options.box == null) {
    throw 'Must supply `box` when `normalize` is true';
  }

  if (options.normalize) {
    pathString = convertPathToAbsolute(pathString);
  }

  const path = new GeneralPath();
  const matches = [...pathString.matchAll(svgPathRegEx)].map((d) => d[0]);
  let isClosed = false;

  const tryNormalize = (
    actual: number,
    lowerBound: number,
    upperBound: number
  ): number => {
    if (!options.normalize) {
      return actual;
    }
    return normalize(actual, lowerBound, upperBound);
  };
  const tryNormalizePoint = (x: number, y: number): Point => {
    const xMin = options.normalize ? options.box.x : 0;
    const yMin = options.normalize ? options.box.y : 0;
    const xMax = options.normalize ? options.box.maxX : 0;
    const yMax = options.normalize ? options.box.maxY : 0;
    return new Point(tryNormalize(x, xMin, xMax), tryNormalize(y, yMin, yMax));
  };

  for (let i = 0; i < matches.length; i++) {
    const command = matches[i];
    const char = command[0].toUpperCase();
    if (!isValidCommand(char)) {
      throw `Unknown command ${char}`;
    }

    if (char == 'Z') {
      path.close();
      isClosed = true;
      continue;
    }
    isClosed = false;
    const coords = [...command.substring(1).matchAll(svgPathCoordRegEx)].map(
      (d) => parseFloat(d[0])
    );

    const expectedCoordCount = getExpectedCoordCount(char);
    if (coords.length != expectedCoordCount) {
      throw `Unexpected number of coords ${coords.length}, expected ${expectedCoordCount}`;
    }

    switch (char) {
      case 'M':
        path.moveTo(tryNormalizePoint(coords[0], coords[1]));
        break;
      case 'L':
        path.lineTo(tryNormalizePoint(coords[0], coords[1]));
        break;
      case 'H':
        path.lineTo(
          tryNormalize(coords[0], options.box?.x ?? 0, options.box?.maxX ?? 0),
          path.lastY
        );
        break;
      case 'V':
        path.lineTo(
          path.lastX,
          tryNormalize(coords[0], options.box?.y ?? 0, options.box?.maxY ?? 0)
        );
        break;
      case 'C':
        path.cubicTo(
          tryNormalizePoint(coords[0], coords[1]),
          tryNormalizePoint(coords[2], coords[3]),
          tryNormalizePoint(coords[4], coords[5])
        );
        break;
      case 'Q':
        path.quadTo(
          tryNormalizePoint(coords[0], coords[1]),
          tryNormalizePoint(coords[2], coords[3])
        );
        break;
    }
  }

  if (!isClosed) {
    path.close();
  }

  return path;
}

function isValidCommand(character: string): boolean {
  return ['M', 'L', 'C', 'Q', 'H', 'V', 'Z'].indexOf(character) >= 0;
}

function getExpectedCoordCount(character: string): number {
  character = character.toUpperCase();
  switch (character) {
    case 'M':
      return 2;
    case 'L':
      return 2;
    case 'H':
      return 1;
    case 'V':
      return 1;
    case 'C':
      return 6;
    case 'Q':
      return 4;
  }
  return 0;
}

export function calculateSvgViewBox(svgElement: SVGElement): Rect {
  // Create an empty DOMRect object that will be used to calculate the overall bounding box
  let combinedBBox: DOMRect = new DOMRect();

  const children = svgElement.querySelectorAll('*');
  children.forEach((child) => {
    if (child instanceof SVGGraphicsElement) {
      const bbox = child.getBBox();
      // Update the combined bounding box
      combinedBBox.x = Math.min(combinedBBox.x, bbox.x);
      combinedBBox.y = Math.min(combinedBBox.y, bbox.y);
      combinedBBox.width = Math.max(combinedBBox.width, bbox.x + bbox.width);
      combinedBBox.height = Math.max(combinedBBox.height, bbox.y + bbox.height);
    }
  });

  // Adjust width and height relative to the origin
  combinedBBox.width -= combinedBBox.x;
  combinedBBox.height -= combinedBBox.y;

  return new Rect(
    combinedBBox.x,
    combinedBBox.y,
    combinedBBox.width,
    combinedBBox.height
  );
}

export function convertSvg(svgElement: SVGElement): {
  items: CompositeItem[];
  size: Size;
} {
  const items: CompositeItem[] = [];
  const children = svgElement.children;

  const box = calculateSvgViewBox(svgElement);

  for (let i = 0; i < children.length; i++) {
    const child = children[i] as SVGElement & SVGGeometryElement;

    // Determine the type of the SVG element and apply scaling
    switch (child.tagName.toLowerCase()) {
      case 'path':
        {
          const path = parsePath(child.getAttribute('d'), {
            box: box,
            normalize: true
          });

          const fillAttr = child.getAttribute('fill');
          const strokeAttr = child.getAttribute('stroke');
          const strokeWidthAttr = child.getAttribute('stroke-width');
          const style: CompositePathItemStyle = {
            fill: fillAttr ? Fill.from(fillAttr) : Fill.WHITE,
            stroke: new Stroke({
              fill: strokeAttr ? Fill.from(strokeAttr) : Fill.BLACK,
              thickness: strokeWidthAttr ? +strokeWidthAttr : 1
            }),
            path: path.createSvgPathData()
          };
          items.push({
            style: style,
            type: CompositeItemType.Path,
            layout: new Rect(0, 0, 1, 1)
          });
        }
        break;
      case 'rect':
        {
          const style: CompositeShapeItemStyle = {
            fill: Fill.RED,
            stroke: Stroke.GREEN,
            shape: NodeShape.Rectangle
          };
          const x = parseFloat(child.getAttribute('x')) / box.width;
          const y = parseFloat(child.getAttribute('y')) / box.height;
          items.push({
            style: style,
            type: CompositeItemType.Shape,
            layout: new Rect(
              x,
              y,
              parseFloat(child.getAttribute('width')) / box.width,
              parseFloat(child.getAttribute('height')) / box.height
            )
          });
        }
        break;
      case 'circle':
      case 'ellipse':
        {
          const style: CompositeShapeItemStyle = {
            fill: Fill.RED,
            stroke: Stroke.GREEN,
            shape: NodeShape.Oval
          };
          const width = parseFloat(child.getAttribute('rx')) * 2;
          const height = parseFloat(child.getAttribute('ry')) * 2;
          const x = parseFloat(child.getAttribute('cx')) - width / 2;
          const y = parseFloat(child.getAttribute('cy')) - height / 2;
          items.push({
            style: style,
            type: CompositeItemType.Shape,
            layout: new Rect(
              x / box.width,
              y / box.height,
              width / box.width,
              height / box.height
            )
          });
        }

        break;
      case 'line':
        {
          const x1 = child.getAttribute('x1');
          const y1 = child.getAttribute('y1');
          const x2 = child.getAttribute('x2');
          const y2 = child.getAttribute('y2');
          const path = `M${x1},${y1} L${x2},${y2} Z`;
          const style: CompositePathItemStyle = {
            fill: Fill.RED,
            stroke: Stroke.GREEN,
            path: path
          };
          items.push({
            style: style,
            type: CompositeItemType.Path,
            layout: new Rect(0, 0, 1, 1)
          });
        }

        break;
      default:
        console.warn(
          'Unsupported SVG element type for scaling:',
          child.tagName
        );
        break;
    }
  }
  return {
    items: items,
    size: box.toSize()
  };
}

export function createNormalizedPath(path: GeneralPath): GeneralPath {
  const pathCursor = path.createCursor();

  const bounds = path.getBounds();

  pathCursor.reset();
  const normalizedPath = new GeneralPath(path.size);
  while (pathCursor.moveNext()) {
    const normalizedPoint = normalizePathPoint(
      pathCursor.currentEndPoint,
      bounds.topLeft,
      bounds.bottomRight
    );
    if (pathCursor.pathType == PathType.MOVE_TO) {
      normalizedPath.moveTo(normalizedPoint);
    } else if (pathCursor.pathType == PathType.LINE_TO) {
      normalizedPath.lineTo(normalizedPoint);
    } else if (pathCursor.pathType == PathType.CLOSE) {
      normalizedPath.close();
    }
  }

  return normalizedPath;
}

export function normalizePathPoint(
  point: Point,
  min: Point,
  max: Point
): Point {
  const x =
    Math.round((normalize(point.x, min.x, max.x) + Number.EPSILON) * 10) / 10;
  const y =
    Math.round((normalize(point.y, min.y, max.y) + Number.EPSILON) * 10) / 10;
  return new Point(x, y);
}
