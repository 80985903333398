import ExportOptions from '../ExportOptions';
import ExportPage from '../ExportPage';
import ExportPageElement from '../ExportPageElement';

export default abstract class AdditionalElementProvider {
  protected readonly options: ExportOptions;
  protected readonly exportPage: ExportPage;

  constructor(options: ExportOptions, exportPage: ExportPage) {
    this.options = options;
    this.exportPage = exportPage;
  }

  public abstract get(): Promise<ExportPageElement[]>;
}
