import { ContentTableItem } from './ContentTableItemType';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import {
  DOCUMENT_NAMESPACE,
  GET_TABLE_OF_CONTENT,
  SET_TABLE_OF_CONTENT
} from '@/core/services/store/document.module';
import Vue from 'vue';
import { DocumentPageDto, DocumentPageLayoutType, FontDto } from '@/api/models';
import { HexToRgbString, generateUuid } from '@/core/utils/common.utils';
import { measureText, stripHtml } from '@/core/utils/html.utils';
import { TPreset } from '../../TextElementPresets';
import ContentTableLayoutItem from './ContentTableLayoutItem';
import TextBoxLayoutItem from '../../Items/TextBoxLayoutItem';
import i18n from '@/core/plugins/vue-i18n';
import { LayoutItemType } from '../../Items/LayoutItemType';
import ExportConfig from '@/core/config/ExportConfig';
import JSize from '@/core/common/JSize';
import stepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import ContentPagination from '@/core/services/export/ContentPagination';
import DocumentService from '@/core/services/document/DocumentService';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import DeloittePlugin from '@/plugins/Deloitte/DeloittePlugin';

export default class ContentTableUtils {
  public static allowedContentAreas = [
    DocumentContentArea.Header,
    DocumentContentArea.Title,
    DocumentContentArea.BodyLayout
  ];
  public static readonly prefixTypeMap = {
    disc: '\u25CF',
    circle: '\u25CB',
    square: '\u25A0'
  };

  public static get allowedLayoutTypes(): Array<DocumentPageLayoutType> {
    const toReturn = [DocumentPageLayoutType.Filler];
    if (DeloittePlugin.isEnabled) {
      toReturn.push(DocumentPageLayoutType.ContentTable);
    }
    return toReturn;
  }
  public static parseLayoutForContentTable(
    layout: string,
    pageNumber: number
  ): {
    layout: string;
    items: Array<ContentTableItem>;
  } {
    let idAdded = false;
    const items = [];
    if (!layout) {
      return { layout, items };
    }
    const parsedLayout = JSON.parse(layout);
    for (const item of parsedLayout) {
      if (!item.id) {
        item.id = generateUuid();
        idAdded = true;
      }
      if ((item?.preset as TPreset) === 'heading') {
        const title = stripHtml(item.html, true);
        if (!title || title === i18n.t(item.defaultText)) {
          continue;
        }
        items.push({
          id: item.id,
          title: title,
          pageNumber: pageNumber,
          edited: false,
          originalTitle: title,
          hidden: false,
          deleted: false,
          fontStyle: this.defaultFont(),
          indentLevel: 0,
          showPrefix: true,
          showPageNumbers: true,
          prefix: '',
          type: 'heading'
        });
      }
    }
    if (idAdded) {
      layout = JSON.stringify(parsedLayout);
    }
    return { layout, items };
  }

  public static updateRow(
    textElement: TextBoxLayoutItem,
    pageNumber: number,
    deleteAction: boolean = false,
    contentArea: DocumentContentArea,
    documentPage: DocumentPageDto
  ): void {
    if ((textElement?.preset as TPreset) !== 'heading') {
      return;
    }
    if (!this.allowedContentAreas.includes(contentArea)) {
      return;
    }

    if (!this.allowedLayoutTypes.includes(documentPage.layoutType)) {
      return;
    }

    const contentTableItems = this.getContentTableItems();
    let rowIndex = contentTableItems.findIndex(
      (row) => textElement.id === row.id
    );
    let contentTableItem: ContentTableItem = contentTableItems[rowIndex]
      ? structuredClone(contentTableItems[rowIndex])
      : null;

    if (deleteAction) {
      contentTableItem.deleted = true;
    } else {
      const title = stripHtml(textElement.html, true);
      if (!title || title === i18n.t(textElement.defaultText)) {
        return;
      }

      if (rowIndex >= 0 && !contentTableItem.edited) {
        contentTableItem.title = title;
        contentTableItem.originalTitle = title;
      } else if (rowIndex >= 0) {
        contentTableItem.originalTitle = title;
      } else if (rowIndex < 0) {
        rowIndex = 0;
        for (const item of contentTableItems) {
          if (item.pageNumber <= pageNumber) {
            rowIndex++;
          } else {
            break;
          }
        }
        contentTableItem = {
          id: textElement.id,
          title: title,
          pageNumber: pageNumber,
          edited: false,
          originalTitle: title,
          hidden: false,
          deleted: false,
          fontStyle: this.defaultFont(),
          indentLevel: 0,
          showPrefix: true,
          showPageNumbers: true,
          prefix: '',
          type: 'heading'
        };
      }
    }
    contentTableItems.splice(rowIndex, 0, contentTableItem);

    this.presistContentTableItems(contentTableItems);
    this.updateContentTable(contentTableItems);
  }
  public static updateStepsTableOfContentsItems(): void {
    const allPages = DocumentService.currentDocument.pages;
    let items = [];
    let pageCount = 1;
    for (const page of allPages) {
      const subPageCount = ContentPagination.getPageCount(page);
      if (
        page.layoutType === DocumentPageLayoutType.ContentTable &&
        !this.allowedLayoutTypes.includes(page.layoutType)
      ) {
        pageCount = pageCount + subPageCount;
        continue;
      }
      if (page.headerLayout && page.showHeader) {
        const result = this.parseLayoutForContentTable(
          page.headerLayout,
          pageCount
        );
        page.headerLayout = result.layout;
        items = items.concat(result.items);
      }
      if (subPageCount > 1 || page.subPageRefs?.length) {
        for (let index = 0; index < subPageCount; index++) {
          const subPageRefIndexBySubPageIndex = page.subPageRefs.findIndex(
            (item) => item.subPageIndex === index
          );
          if (subPageRefIndexBySubPageIndex >= 0) {
            const subPageRef = page.subPageRefs[subPageRefIndexBySubPageIndex];
            if (subPageRef.showTitle) {
              const result = this.parseLayoutForContentTable(
                subPageRef.titleLayout,
                pageCount + subPageRef.subPageIndex
              );
              subPageRef.titleLayout = result.layout;
              items = items.concat(result.items);
            }
          } else {
            const result = this.parseLayoutForContentTable(
              page.titleLayout,
              pageCount + index
            );
            page.titleLayout = result.layout;
            items = items.concat(result.items);
          }
        }
      } else if (page.titleLayout && page.showTitle) {
        const result = this.parseLayoutForContentTable(
          page.titleLayout,
          pageCount
        );
        page.titleLayout = result.layout;
        items = items.concat(result.items);
      }
      if (this.allowedLayoutTypes.includes(page.layoutType)) {
        const result = this.parseLayoutForContentTable(
          page.bodyLayout,
          pageCount
        );
        page.bodyLayout = result.layout;
        items = items.concat(result.items);
      }

      if (
        page.layoutType === DocumentPageLayoutType.ContentTable &&
        page.bodyLayout
      ) {
        const parsedLayout = JSON.parse(page.bodyLayout);
        const contentTable = parsedLayout?.find(
          (item) => item.type === LayoutItemType.ContentTable
        );
        if (contentTable?.chapterTitle) {
          const contentTableChapterItem: ContentTableItem = {
            id: 'chapter',
            title: stripHtml(contentTable?.chapterTitle),
            pageNumber: pageCount + 1,
            edited: false,
            originalTitle: stripHtml(contentTable?.chapterTitle),
            hidden: false,
            deleted: false,
            fontStyle: structuredClone(ExportConfig.defaultContentFontStyle),
            indentLevel: 0,
            showPrefix: true,
            showPageNumbers: true,
            prefix: '',
            type: 'chapter',
            column: 1
          };
          items = items.concat(contentTableChapterItem);
        }
      }

      pageCount = pageCount + subPageCount;
    }
    this.updateContentTable(items);
  }

  public static updateContentTable(items: Array<ContentTableItem>): void {
    const allPages = DocumentService.currentDocument.pages;
    const updatedPages: DocumentPageDto[] = [];
    for (const page of allPages) {
      if (page.layoutType !== DocumentPageLayoutType.ContentTable) {
        continue;
      }

      if (!page.bodyLayout) {
        continue;
      }

      const parsedLayout = JSON.parse(page.bodyLayout);
      const contentTableItemIndex = parsedLayout.findIndex(
        (item) => item.type === LayoutItemType.ContentTable
      );
      if (!parsedLayout[contentTableItemIndex].autoUpdate) {
        continue;
      }

      if (parsedLayout[contentTableItemIndex]?.itemTypeFilter) {
        parsedLayout[contentTableItemIndex].items = items.filter(
          (item) =>
            item.type == parsedLayout[contentTableItemIndex].itemTypeFilter
        );
      }

      this.updateContentTableInLayout(
        parsedLayout[contentTableItemIndex],
        items
      );

      const newBodyLayout = JSON.stringify(parsedLayout);
      if (newBodyLayout !== page.bodyLayout) {
        page.bodyLayout = newBodyLayout;
        updatedPages.push(page);
      }
    }
    if (updatedPages.length > 0) {
      EventBus.$emit(EventBusActions.PAGES_CONTENT_CHANGED, updatedPages);
    }

    this.presistContentTableItems(items);
  }

  public static getContentTableItems(): Array<ContentTableItem> {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_TABLE_OF_CONTENT}`
    ];
  }

  public static presistContentTableItems(items: Array<ContentTableItem>): void {
    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${SET_TABLE_OF_CONTENT}`,
      items
    );
  }

  public static updateTable(contentTableItem: ContentTableLayoutItem): void {
    this.updateStepsTableOfContentsItems();
    this.updateContentTableInLayout(contentTableItem);
  }

  public static resetTable(contentTableItem: ContentTableLayoutItem): void {
    this.updateStepsTableOfContentsItems();
    contentTableItem.reset();
    contentTableItem.items = this.getContentTableItems();
    if (contentTableItem?.itemTypeFilter) {
      contentTableItem.items = contentTableItem.items.filter(
        (item) => item.type == contentTableItem.itemTypeFilter
      );
    }
    this.updatePrefix(contentTableItem);
    EventBus.$emit(EventBusActions.CONTENT_TABLE_SPLIT);
  }

  public static updateContentTableInLayout(
    contentTableItem: ContentTableLayoutItem,
    contentTableRows: Array<ContentTableItem> = null
  ): void {
    let items = contentTableRows
      ? contentTableRows
      : this.getContentTableItems();

    if (contentTableItem?.itemTypeFilter) {
      items = items.filter(
        (item) => item.type == contentTableItem.itemTypeFilter
      );
    }

    const newItemsList = [];
    const oldItems = contentTableItem.items;
    for (const item of items) {
      const oldItem = oldItems.find(
        (i) => i.id === item.id && i.pageNumber === item.pageNumber
      );
      if (!oldItem) {
        item.fontStyle = contentTableItem.fontSet;
        newItemsList.push(item);
        continue;
      }
      oldItem.pageNumber = item.pageNumber;
      if (!oldItem.edited) {
        oldItem.title = item.title;
        oldItem.originalTitle = item.originalTitle;
      }
      newItemsList.push(oldItem);
    }
    contentTableItem.items = newItemsList;
    this.updatePrefix(contentTableItem);
    contentTableItem.html = this.generateContentTableHtml(contentTableItem);
    EventBus.$emit(EventBusActions.CONTENT_TABLE_SPLIT);
  }

  private static getItemSize(
    item: ContentTableLayoutItem,
    row: ContentTableItem,
    type: 'prefix' | 'page-number',
    isTwoTables = false,
    secondColumn = false
  ): JSize {
    const style = this.getRowStyle(row, item);
    let element = '';
    const isIndentationApplied = row.indentLevel > 0;

    if (type === 'prefix') {
      element = `<p style="${style}; ${
        isIndentationApplied ? '' : 'padding-left: 10px;'
      }">${row.prefix?.trim()}</p>`;
    } else if (type === 'page-number') {
      const padding =
        (isTwoTables && secondColumn) || !isTwoTables ? 'padding: 0 10px;' : '';
      element = `<p style="${style}; ${padding}">${row.pageNumber}</p>`;
    }

    return measureText(element);
  }

  public static updatePrefix(item: ContentTableLayoutItem): void {
    if (!item.showPrefix) {
      return;
    }
    if (item.prefixType === 'number') {
      return this.updateNumberedPrefix(item);
    }

    const prefix = this.prefixTypeMap[item.prefixType];

    for (const tableItem of item.items) {
      tableItem.prefix = prefix;
    }
  }
  public static updateNumberedPrefix(item: ContentTableLayoutItem): void {
    const count = [0];
    const indentLevelMultiplier = ContentTableLayoutItem.indentLevelMultiplier;
    for (const tableItem of item.items) {
      if (!tableItem.showPrefix || tableItem.deleted) {
        continue;
      }
      const indent = tableItem.indentLevel / indentLevelMultiplier;
      if (count.length <= indent) {
        while (count.length < indent + 1) {
          count.push(0);
        }
      } else {
        for (let level = indent + 1; level < count.length; level++) {
          count[level] = 0;
        }
      }

      count[indent] = count[indent] + 1;
      tableItem.prefix = count.slice(0, indent + 1).join('.');
    }
  }

  public static generateContentTableHtml(item: ContentTableLayoutItem): string {
    if (!item.items?.length) {
      return '';
    }

    const twoTableSpacing = 8;
    const rowItems = item.items?.filter((i) => !i.deleted) ?? [];
    const firstTableRows = rowItems.filter((i) => !i.column || i.column === 1);
    const secondTableRows = rowItems.filter((i) => i.column === 2);

    const isTwoTables = !!firstTableRows.length && !!secondTableRows.length;

    let tableWidth = item.layout.width - item.padding * 2;
    if (isTwoTables) {
      tableWidth /= 2;
      tableWidth = tableWidth - twoTableSpacing / 2;
    }

    let rowPrefixSize = new JSize(0, 0);
    let rowPageNumberSize = new JSize(0, 0);

    for (let i = rowItems.length - 1; i >= 0; i--) {
      const rowItem = rowItems[i];

      if (rowItem.type === 'chapter') {
        if (rowItem.originalTitle === item.chapterTitle) {
          rowItem.fontStyle = {
            ...rowItem.fontStyle,
            color: stepsDesignerConfig.get.contentTableChapterTitleColor
          };
        } else if (
          rowItem.fontStyle?.color ===
          stepsDesignerConfig.get.contentTableChapterTitleColor
        ) {
          rowItem.fontStyle = {
            ...rowItem.fontStyle,
            color: ExportConfig.defaultContentFontStyle.color
          };
        }
      }

      let currentRowPrefixSize = JSize.EMPTY;

      if (rowItem.showPrefix && item.showPrefix && !rowItem.indentLevel) {
        currentRowPrefixSize = this.getItemSize(item, rowItem, 'prefix');
      }

      if (currentRowPrefixSize.width > rowPrefixSize.width) {
        rowPrefixSize = currentRowPrefixSize;
      }

      if (
        rowItem.showPageNumbers &&
        item.showPageNumbers &&
        rowPageNumberSize.width === 0 &&
        (!rowItem.column || rowItem.column === 1)
      ) {
        rowPageNumberSize = this.getItemSize(
          item,
          rowItem,
          'page-number',
          isTwoTables,
          rowItem.column && rowItem.column === 2
        );
      }
    }

    let html = '';

    if (isTwoTables) {
      html += '<div class="flex items-start">';
    }

    html += this.getTableHtml(
      firstTableRows,
      item,
      tableWidth,
      item.layout.height - item.padding * 2,
      0,
      0,
      rowPrefixSize,
      rowPageNumberSize,
      isTwoTables
    );

    if (isTwoTables) {
      const positionLeft = tableWidth + twoTableSpacing;
      const lastItem = rowItems[rowItems.length - 1];
      const rowPageNumberSize = this.getItemSize(
        item,
        lastItem,
        'page-number',
        isTwoTables,
        lastItem.column && lastItem.column === 2
      );
      html += this.getTableHtml(
        secondTableRows,
        item,
        tableWidth,
        item.layout.height - item.padding * 2,
        0,
        positionLeft,
        rowPrefixSize,
        rowPageNumberSize,
        isTwoTables
      );
    }

    if (isTwoTables) {
      html += '</div>';
    }

    return html;
  }

  // get ContentTable item html for thumbnails|PDf export
  public static generateContentTableHtmlThumbnails(
    item: ContentTableLayoutItem
  ): string {
    if (!item.items?.length) {
      return '';
    }

    const rowItems = item.items?.filter((i) => !i.deleted) ?? [];
    const firstTableRows = rowItems.filter((i) => !i.column || i.column === 1);
    const secondTableRows = rowItems.filter((i) => i.column === 2);
    const isTwoTables = !!firstTableRows.length && !!secondTableRows.length;

    let html = '<div style="display: flex; column-gap: 8px;">';

    html += '<div style="display: flex; flex-direction: column; width: 100%;">';
    for (const row of firstTableRows) {
      html += this.getTableThumbnailHtml(item, row);
    }
    html += '</div>';

    if (isTwoTables) {
      html +=
        '<div style="display: flex; flex-direction: column; width: 100%;">';
      for (const row of secondTableRows) {
        html += this.getTableThumbnailHtml(item, row);
      }
      html += '</div>';
    }

    html += '</div>';
    return html;
  }

  private static getTableHtml(
    rows: Array<ContentTableItem>,
    item: ContentTableLayoutItem,
    tableWidth: number,
    tableHeight: number,
    positionTop: number,
    positionLeft: number,
    rowPrefixSize: JSize,
    rowPageNumberSize: JSize,
    isTwoTables = false
  ): string {
    const defaultIndentation = 20;

    const table = document.createElement('table');
    const tbody = document.createElement('tbody');

    table.style.tableLayout = 'auto';
    table.setAttribute('width', `${tableWidth}pt`);
    table.setAttribute('data-width', `${tableWidth}`);
    table.setAttribute('data-height', `${tableHeight}`);
    table.setAttribute('data-top', `${positionTop}`);
    table.setAttribute('data-left', `${positionLeft}`);

    const rowsWithIndentation = rows.filter((rowItem) => rowItem.indentLevel);

    // as in PPT the smallest column width will be taken
    // we need to repeat it here for right calculation of the column size with colspan applied
    const columnWidthArray = [];
    rows.forEach((row) => {
      if (row.deleted) {
        return;
      }

      const isAnyPrefixShown = rows.some((rowItem) => {
        return item.showPrefix && rowItem.id !== row.id && rowItem.showPrefix;
      });

      const isAnyPageNumbersShown = rows.some((rowItem) => {
        return (
          item.showPageNumbers &&
          rowItem.id !== row.id &&
          rowItem.showPageNumbers
        );
      });

      const rowEl = document.createElement('tr');
      rowEl.style.lineHeight = '0';

      const currentRowPrefixSize = this.getItemSize(item, row, 'prefix');
      const columnHeight = row.fontStyle.fontSize * item.spacing;
      const isPrefixShown = item.showPrefix && row.showPrefix;
      const isPageNumbersShown = item.showPageNumbers && row.showPageNumbers;
      const style = this.getRowStyle(row, item, false, true);
      const indentationStyle = this.getRowStyle(row, item, true, true);

      let indentWidth = 0;

      if (rowPrefixSize.width > currentRowPrefixSize.width) {
        currentRowPrefixSize.width = rowPrefixSize.width;
      }

      // adding columns for indentation
      const indentCount = row.indentLevel / defaultIndentation;
      if (indentCount > 0) {
        for (let i = 0; i < indentCount; i++) {
          const existingColumnWidth = columnWidthArray.at(i);
          if (
            (existingColumnWidth &&
              existingColumnWidth > currentRowPrefixSize.width) ||
            !existingColumnWidth
          ) {
            columnWidthArray[i] = currentRowPrefixSize.width;
          }
          indentWidth += columnWidthArray[i]
            ? columnWidthArray[i]
            : currentRowPrefixSize.width;

          const indentCell = document.createElement('td');
          indentCell.noWrap = true;
          indentCell.width = `${currentRowPrefixSize.width}`;
          indentCell.dataset.width = `${currentRowPrefixSize.width}`;
          indentCell.dataset.height = `${columnHeight}`;
          indentCell.innerHTML = `<p style="${indentationStyle}">&nbsp;</p>`;
          indentCell.style.padding = '0 !important';
          this.hanldeCellBackgroundColor(indentCell, row.fontStyle);
          rowEl.appendChild(indentCell);
        }
      }

      if (isPrefixShown) {
        if (
          (columnWidthArray[indentCount] &&
            columnWidthArray[indentCount] > currentRowPrefixSize.width) ||
          !columnWidthArray[indentCount]
        ) {
          columnWidthArray[indentCount] = currentRowPrefixSize.width;
        }
        const prefixCell = document.createElement('td');
        prefixCell.noWrap = true;
        prefixCell.width = `${currentRowPrefixSize.width}`;
        prefixCell.dataset.width = `${currentRowPrefixSize.width}`;
        prefixCell.dataset.height = `${currentRowPrefixSize.height}`;
        prefixCell.style.padding = '0 !important';
        prefixCell.innerHTML = `<p style="${style}; ${
          !row.indentLevel ? 'padding-left: 10px;' : ''
        }">${row.prefix}</p>`;
        this.hanldeCellBackgroundColor(prefixCell, row.fontStyle);

        rowEl.appendChild(prefixCell);
      }

      const existingPrefixColumnWidth = columnWidthArray[indentCount];

      const titleCellWidth =
        tableWidth -
        (isPrefixShown ? existingPrefixColumnWidth : 0) -
        (isPageNumbersShown ? rowPageNumberSize.width : 0) -
        indentWidth;

      const existingColumnWidth =
        columnWidthArray[isPrefixShown ? indentCount + 1 : indentCount];
      if (
        (isPrefixShown &&
          existingColumnWidth &&
          existingColumnWidth > titleCellWidth) ||
        !existingColumnWidth
      ) {
        columnWidthArray[indentCount + 1] = titleCellWidth;
      } else if (existingColumnWidth && existingColumnWidth > titleCellWidth) {
        columnWidthArray[indentCount] = titleCellWidth;
      }

      // title column
      const titleCell = document.createElement('td');
      titleCell.noWrap = true;
      titleCell.width = `${titleCellWidth}`;
      titleCell.dataset.width = `${titleCellWidth}`;
      titleCell.dataset.height = `${columnHeight}`;
      titleCell.style.padding = '0 !important';

      this.handleColspan(
        row,
        rows,
        item,
        titleCell,
        isPrefixShown,
        isPageNumbersShown,
        isAnyPrefixShown,
        isAnyPageNumbersShown,
        indentCount,
        rowsWithIndentation
      );
      this.hanldeCellBackgroundColor(titleCell, row.fontStyle);

      const link = `<a href="${
        row.pageNumber
      }" isinternal="true" rel="noopener noreferrer" ${'ignore-underline'} target="_self" class="hover:bg-accent-purple-20" style="${style};  ${
        row.indentLevel ? 'padding-left: 8px;' : ''
      }">${row.title}</link>`;
      const titleCellContent = `<p style="${style}; ${
        !row.indentLevel ? 'padding-left: 8px;' : ''
      }">${link}</p>`;
      titleCell.innerHTML = titleCellContent;

      titleCell.style.padding = '0';
      rowEl.appendChild(titleCell);

      // page number column
      if (isPageNumbersShown) {
        const pageNumberCell = document.createElement('td');
        pageNumberCell.noWrap = true;
        pageNumberCell.width = `${rowPageNumberSize.width}`;
        pageNumberCell.dataset.width = `${rowPageNumberSize.width}`;
        pageNumberCell.dataset.height = `${columnHeight}`;

        pageNumberCell.innerHTML = `<p style="${style}; text-align: right; ${
          (row.column && row.column === 2) || !isTwoTables
            ? 'padding-right: 10px;'
            : ''
        }">${row.pageNumber}</p>`;
        pageNumberCell.style.padding = '0';
        this.hanldeCellBackgroundColor(pageNumberCell, row.fontStyle);

        rowEl.appendChild(pageNumberCell);
      }

      tbody.appendChild(rowEl);
    });

    table.appendChild(tbody);
    const result = table.outerHTML;
    table.remove();
    return result;
  }

  private static getBiggestColSpan(
    rows: Array<ContentTableItem>,
    item: ContentTableLayoutItem
  ): number {
    let colSpan = 0;

    rows.forEach((rowItem) => {
      let localColSpan = 0;
      const indentCount = rowItem.indentLevel / 20;
      localColSpan += indentCount + 1;
      if (rowItem.showPrefix && item.showPrefix) {
        localColSpan += 1;
      }

      if (colSpan < localColSpan) {
        colSpan = localColSpan;
      }
    });

    return colSpan;
  }

  private static handleColspan(
    row: ContentTableItem,
    rows: Array<ContentTableItem>,
    item: ContentTableLayoutItem,
    cellElement: HTMLTableCellElement,
    isPrefixShown: boolean,
    isPageNumbersShown: boolean,
    isAnyPrefixShown: boolean,
    isAnyPageNumbersShown: boolean,
    indentCount: number,
    rowsWithIndentation: Array<ContentTableItem>
  ): void {
    const otherRows = rowsWithIndentation.filter(
      (rowItem) => rowItem.id !== row.id
    );

    if (isPrefixShown) {
      // [-1] as prefix is shown
      let colSpan = this.getBiggestColSpan(otherRows, item) - 1 - indentCount;

      if (!isPageNumbersShown && isAnyPageNumbersShown) {
        if (colSpan <= 0) {
          colSpan = 2;
        } else {
          colSpan += 1;
        }
      }

      if (colSpan > 1) {
        cellElement.colSpan = colSpan;
      }
    } else if (!isPrefixShown) {
      let colSpan = this.getBiggestColSpan(otherRows, item) - indentCount;

      if (!isPageNumbersShown && isAnyPageNumbersShown) {
        if (colSpan <= 0) {
          colSpan = 2;

          if (!isPrefixShown && !indentCount) {
            colSpan += 1;
          }
        } else {
          colSpan += 1;
        }
      } else if (
        colSpan === 0 &&
        !isPrefixShown &&
        !indentCount &&
        isAnyPrefixShown
      ) {
        colSpan = 2;
      }

      if (colSpan > 1) {
        cellElement.colSpan = colSpan;
      }
    }
  }

  private static getRowStyle(
    row: ContentTableItem,
    item: ContentTableLayoutItem,
    isIndentation = false,
    isTable = false
  ): string {
    let style = '';

    if (row.fontStyle?.fontSize) {
      style += `font-size: ${row.fontStyle.fontSize}pt;`;
    }
    if (row.fontStyle?.fontFamily) {
      style += `font-family: ${row.fontStyle.fontFamily};`;
    }
    if (row.fontStyle?.fontStyle) {
      style += `font-style: ${row.fontStyle.fontStyle};`;
    }
    if (row.fontStyle?.fontWeight) {
      style += `font-weight: ${row.fontStyle.fontWeight};`;
    }
    if (row.fontStyle?.textDecoration && !isIndentation) {
      style += `text-decoration: ${row.fontStyle.textDecoration.toLowerCase()};`;
    }
    if (row.fontStyle?.color) {
      style += `color: ${row.fontStyle.color};`;
    }
    style += `line-height: ${item.spacing}`;

    return style;
  }

  private static getTableThumbnailHtml(
    item: ContentTableLayoutItem,
    row: ContentTableItem
  ): string {
    let html = '';
    const style = this.getRowStyle(row, item);
    const link = `<a href="${row.pageNumber}" isinternal="true" rel="noopener noreferrer" ${'ignore-underline'} target="_self" style="${style};">`;

    html += `<div style="display: flex; padding-left: ${
      row.indentLevel
    }pt;${this.cellBackgroundColor(row.fontStyle)}">`;

    if (row.showPrefix && item.showPrefix) {
      const padding = 10;
      html += `<div style="${style}; padding-left: ${padding}px; min-width: 4ch;">${link}${row.prefix}</a></div>`;
    }

    html += `<div style="${style}; flex-grow: 1; padding-left: 8px; word-break: keep-all;">`;
    html += link;
    html += row.title;
    html += '</a>';
    html += '</div>';

    if (row.showPageNumbers && item.showPageNumbers) {
      html += `<div style=" ${style}; padding: 0 10px;">${link}${row.pageNumber}</a></div>`;
    }

    html += '</div>';

    return html;
  }

  private static hanldeCellBackgroundColor(
    cellElement: HTMLTableCellElement,
    fontStyle: FontDto
  ): void {
    if (fontStyle.backgroundColor) {
      cellElement.style.backgroundColor = HexToRgbString(
        fontStyle.backgroundColor
      );
    }
  }

  public static cellBackgroundColor(fontStyle: FontDto): string {
    return fontStyle.backgroundColor
      ? 'background-color:' + HexToRgbString(fontStyle.backgroundColor)
      : '';
  }

  public static defaultFont(): FontDto {
    const font = structuredClone(ExportConfig.defaultContentFontStyle);
    font.fontSize = 16;
    return font;
  }
}
