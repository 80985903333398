export enum PageDesignGroup {
  DIAGRAM_PAGE = 'DIAGRAM_PAGE',
  DIAGRAM_TEXT = 'DIAGRAM_TEXT',
  TEXT = 'TEXT',
  TEXT_TEXT = 'TEXT_TEXT',
  COVER_PAGE = 'COVER_PAGE',
  FILLER_PAGE = 'FILLER_PAGE',
  CLOSING_PAGE = 'CLOSING_PAGE',
  MASTER_LEGEND_PAGE = 'MASTER_LEGEND_PAGE'
}

export enum PageType {
  Diagram = 0,
  DiagramText = 1,
  Text = 2,
  TextText = 3,
  Cover = 4,
  Filler = 5,
  Closing = 6,
  MasterLegend = 7
}
