import EntityTypeMatcher from '../matchers/EntityTypeMatcher';
import EntityTypeParameter from '../parameters/EntityTypeParameter';
import ISearchParameter from '../parameters/ISearchParameter';
import ISpecification from '../specifications/ISpecification';
import MatchSpecification from '../specifications/MatchSpecification';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import { FilterType } from './FilterType';

export default class EntityTypeFilter extends Filter {
  static matchers = [new EntityTypeMatcher()];
  public type: FilterType;
  public scope = FilterScope.EntityType;
  public parameter: EntityTypeParameter;
  protected _specification: ISpecification;

  constructor(
    parameter: ISearchParameter,
    type: FilterType = FilterType.Include
  ) {
    super();
    this.type = type;
    this.parameter = parameter as EntityTypeParameter;
    this._specification = new MatchSpecification<EntityTypeParameter>(
      EntityTypeFilter.matchers,
      this.parameter
    );
  }
}
