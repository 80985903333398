import { locationOrigin } from '@/core/utils/common.utils';

const origin = locationOrigin();
export type FontDefinition = {
  regular: string;
  bold?: string;
  italic?: string;
  bolditalic?: string;
  // (Legacy PDF export) Values needed to calculate the correct line height
  // Use https://opentype.js.org/font-inspector.html
  ascender?: number; // Horizontal Header - ascender
  descender?: number; // Horizontal Header - descender
  unitsPerEm?: number; // Font Header - unitsPerEm
};

export type FontType = 'regular' | 'bold' | 'italic' | 'bolditalic';

export const fontTypes: FontType[] = [
  'regular',
  'bold',
  'italic',
  'bolditalic'
];

export const customFonts: Record<string, FontDefinition> = {
  // Can't use WOFF for Arial as it is missing unicode character glyphs
  'Arial': {
    regular: origin + '/media/fonts/Arial.ttf',
    bold: origin + '/media/fonts/ArialBold.ttf',
    italic: origin + '/media/fonts/ArialItalic.ttf',
    bolditalic: origin + '/media/fonts/ArialBoldItalic.ttf'
  },
  'Arial Narrow': {
    regular: origin + '/media/fonts/ArialNarrow.ttf',
    bold: origin + '/media/fonts/ArialNarrowBold.ttf',
    italic: origin + '/media/fonts/ArialNarrowItalic.ttf',
    bolditalic: origin + '/media/fonts/ArialNarrowBoldItalic.ttf'
  },
  'Calibri': {
    regular: origin + '/media/fonts/Calibri.woff',
    bold: origin + '/media/fonts/CalibriBold.woff',
    italic: origin + '/media/fonts/CalibriItalic.woff',
    bolditalic: origin + '/media/fonts/CalibriBoldItalic.woff'
  },
  'Calibri Light': {
    regular: origin + '/media/fonts/CalibriLight.woff',
    bold: origin + '/media/fonts/CalibriBold.woff', // Calibri Light does not have bold variant
    italic: origin + '/media/fonts/CalibriLightItalic.woff',
    bolditalic: origin + '/media/fonts/CalibriBoldItalic.woff', // Calibri Light does not have bold variant
  },
  'Courier New': {
    regular: origin + '/media/fonts/Courier.woff',
    bold: origin + '/media/fonts/CourierBold.woff',
    italic: origin + '/media/fonts/CourierItalic.woff',
    bolditalic: origin + '/media/fonts/CourierBoldItalic.woff'
  },
  'Georgia': {
    regular: origin + '/media/fonts/Georgia.woff',
    bold: origin + '/media/fonts/GeorgiaBold.woff',
    italic: origin + '/media/fonts/GeorgiaItalic.woff',
    bolditalic: origin + '/media/fonts/GeorgiaBoldItalic.woff'
  },
  'Times New Roman': {
    regular: origin + '/media/fonts/Times.woff',
    bold: origin + '/media/fonts/TimesBold.woff',
    italic: origin + '/media/fonts/TimesItalic.woff',
    bolditalic: origin + '/media/fonts/TimesBoldItalic.woff'
  },
  'Trebuchet MS': {
    regular: origin + '/media/fonts/TrebuchetMS.woff',
    bold: origin + '/media/fonts/TrebuchetMSBold.woff',
    italic: origin + '/media/fonts/TrebuchetMSItalic.woff',
    bolditalic: origin + '/media/fonts/TrebuchetMSBoldItalic.woff'
  },
  'Verdana': {
    regular: origin + '/media/fonts/Verdana.woff',
    bold: origin + '/media/fonts/VerdanaBold.woff',
    italic: origin + '/media/fonts/VerdanaItalic.woff',
    bolditalic: origin + '/media/fonts/VerdanaBoldItalic.woff'
  },
  'Ebrima': {
    regular: origin + '/media/fonts/Ebrima.ttf',
    bold: origin + '/media/fonts/EbrimaBold.ttf'
  },
  'Minion Pro': {
    regular: origin + '/media/fonts/MinionPro.otf',
    bold: origin + '/media/fonts/MinionProBold.otf',
    italic: origin + '/media/fonts/MinionProItalic.otf',
    bolditalic: origin + '/media/fonts/MinionProBoldItalic.otf'
  },
  'Symbola': {
    regular: origin + '/media/fonts/Symbola.ttf'
  },
  // Japanese font
  'Meiryo': {
    regular: origin + '/media/fonts/Meiryo.woff',
    bold: origin + '/media/fonts/MeiryoBold.woff',
    italic: origin + '/media/fonts/MeiryoItalic.woff',
    bolditalic: origin + '/media/fonts/MeiryoBoldItalic.woff'
  }
};
