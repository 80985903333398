import {
  BaseClass,
  GeneralPathNodeStyle,
  INode,
  INodeStyle,
  INodeStyleRenderer,
  Rect,
  ShapeNodeStyle
} from 'yfiles';
import { parsePath } from './ShapeBuilderUtils';
import CompositePathItemStyle from './CompositePathItemStyle';
import CompositeShapeItemStyle from './CompositeShapeItemStyle';
import { CompositeItemType, PresetCompositeShapeType } from '@/api/models';
import diagramConfig from '@/core/config/diagram.definition.config';
import CompositeItem from './CompositeItem';
import CompositeNodeStyleRenderer from './CompositeNodeStyleRenderer';

export default class CompositeNodeStyle extends BaseClass<INodeStyle>(
  INodeStyle
) {
  get renderer(): INodeStyleRenderer {
    return CompositeNodeStyleRenderer.INSTANCE;
  }
  clone(): this {
    const clonedStyle = new CompositeNodeStyle(
      this.items.map((item) => {
        let style: CompositeShapeItemStyle | CompositePathItemStyle = null;
        if (item.type == CompositeItemType.Shape) {
          style = {
            fill: item.style.fill.clone(),
            stroke: item.style.stroke.clone(),
            angle: item.style.angle,
            shape: (item.style as CompositeShapeItemStyle).shape
          };
        } else {
          style = {
            fill: item.style.fill.clone(),
            stroke: item.style.stroke.clone(),
            angle: item.style.angle,
            path: (item.style as CompositePathItemStyle).path
          };
        }

        return {
          layout: new Rect(
            item.layout.x,
            item.layout.y,
            item.layout.width,
            item.layout.height
          ),
          style: style,
          type: item.type
        };
      }),
      this.shape
    );
    return clonedStyle as this;
  }

  private _shape: PresetCompositeShapeType;
  private _items: CompositeItem[];
  private itemStyles: INodeStyle[];

  public get items(): CompositeItem[] {
    return this._items;
  }

  public get shape(): PresetCompositeShapeType {
    return this._shape;
  }

  constructor(items: CompositeItem[], shape: PresetCompositeShapeType) {
    super();

    this._items = items;
    this._shape = shape;
    if (shape === undefined) {
      debugger;
    }
  }

  public getItemLayout(node: INode, item: CompositeItem): Rect {
    const xOffset = node.layout.width * item.layout.x;
    const yOffset = node.layout.height * item.layout.y;
    const x = node.layout.x + xOffset;
    const y = node.layout.y + yOffset;
    const width = node.layout.width * item.layout.width;
    const height = node.layout.height * item.layout.height;

    return new Rect(x, y, width, height);
  }

  public getItemStyles(): INodeStyle[] {
    if (this.itemStyles) {
      return this.itemStyles;
    }
    this.itemStyles = [];

    for (let i = 0; i < this._items.length; i++) {
      const item = this._items[i];
      const style = this.createStyle(item);
      this.itemStyles.push(style);
    }

    return this.itemStyles;
  }
  public createStyle(item: CompositeItem): INodeStyle {
    if (item.type == CompositeItemType.Path) {
      return this.createPathNodeStyle(item.style as CompositePathItemStyle);
    }

    return this.createShapeNodeStyle(item.style as CompositeShapeItemStyle);
  }

  private createPathNodeStyle(
    style: CompositePathItemStyle
  ): GeneralPathNodeStyle {
    const path = parsePath(style.path);

    return new GeneralPathNodeStyle({
      path: path,
      fill: style.fill,
      stroke: style.stroke
    });
  }

  private createShapeNodeStyle(style: CompositeShapeItemStyle): ShapeNodeStyle {
    return new ShapeNodeStyle({
      shape: diagramConfig.nodeShapes[style.shape],
      fill: style.fill,
      stroke: style.stroke
    });
  }
}
