﻿import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  GraphEditorInputMode,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  Point,
  Rect,
  Size
} from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import StyleCreator from '@/core/utils/StyleCreator';

import config from '@/core/config/diagram.definition.config';
import { QuickStartState, ThemeElementDto } from '@/api/models';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import CommandManager from '@/core/services/CommandManager/CommandManager';
import {
  createDragPreview,
  queryContinueDragHandler
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import QuickBuildService from '@/core/services/graph/quick-build.service';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import DiagramCommandHandler from '@/view/pages/document/DiagramCommandHandler';
import { PaletteDropInputModeBase } from '@/components/DiagramPalette/PaletteDropInputModeBase';

export default class ThemeElementNodeBehaviour extends PaletteItemBehaviourBase {
  private static _instance: ThemeElementNodeBehaviour = null;

  public static get INSTANCE(): ThemeElementNodeBehaviour {
    return (
      ThemeElementNodeBehaviour._instance ??
      (ThemeElementNodeBehaviour._instance = new ThemeElementNodeBehaviour())
    );
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      new Point(itemPositionX, itemPositionY),
      graphService
    );

    PaletteItemOffsetHelper.updatePaletteItemInsertOffset(
      graphService.graphComponent
    );
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const itemCreator = (
      context,
      graph,
      draggedItem,
      dropTarget,
      dropLocation
    ): IDocumentPaletteItem =>
      this.itemCreator(
        context,
        graph,
        draggedItem,
        dropTarget,
        dropLocation,
        graphService
      );

    this.addDropInputMode(
      graphService.graphComponent.inputMode as GraphEditorInputMode,
      new ThemeElementDropInputMode(),
      itemCreator
    );

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = createDragPreview(event);
    const dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(INode.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);
    });
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: IDocumentPaletteItem,
    dropTarget: IModelItem | null,
    dropLocation: Point,
    graphService: IGraphService
  ): IDocumentPaletteItem | null {
    if (!draggedItem.data?.element) {
      return null;
    }

    const size = DiagramUtils.getNodeSize(draggedItem.data.element.style);

    let style = StyleCreator.createNodeStyle(draggedItem.data.element.style);
    const simpleNode = DiagramUtils.createSimpleNode(
      draggedItem.name,
      style,
      size
    );
    simpleNode.tag.placeholderText = (
      draggedItem.data.element as ThemeElementDto
    )?.displayName;

    let layout = new Rect(
      dropLocation?.x ?? 0,
      dropLocation?.y ?? 0,
      size.width,
      size.height
    );

    let node = graph.createNodeAt(
      layout,
      simpleNode.style.clone(),
      simpleNode.tag
    );
    DiagramUtils.focusGraphComponent(context.canvasComponent);
    CommandManager.INSTANCE.setCommandHandler(
      graphService.getService<DiagramCommandHandler>(
        DiagramCommandHandler.$class
      )
    );

    //override the tags style and indicators position with the theme element
    node.tag.style = draggedItem.data.element.style;
    node.tag.indicatorsPosition = draggedItem.data.element.indicatorsPosition;
    node.tag.entityTypeId = draggedItem.data.element?.defaultEntityTypeId;

    node.labels.forEach((x) => graph.remove(x));
    const labelText = DiagramUtils.getPlaceholderLabelText(node);

    const position = new Point(
      layout.x - layout.width / 2,
      layout.y - layout.height / 2
    );

    const updatedLayout = new Rect(
      position,
      new Size(layout.width, layout.height)
    );

    graph.setNodeLayout(node, updatedLayout);

    // set the node label after we've updated the nodes layout. This allows the labels size to be calculated correctly.
    DiagramUtils.setLabel(graph, node, labelText);

    if (!node.tag.isAnnotation) {
      graphService
        .getService<QuickBuildService>(QuickBuildService.$class)
        .setQuickStartState(QuickStartState.Complete);
    }

    this.removeDropInputMode(
      context.canvasComponent.inputMode as GraphEditorInputMode
    );

    return draggedItem;
  }
}

class ThemeElementDropInputMode extends PaletteDropInputModeBase {
  populatePreviewGraph(previewGraph: IGraph): void {
    const graph = previewGraph;
    const item = this.dropData;
    if (!item.data?.element) {
      return;
    }

    let style = StyleCreator.createNodeStyle(item.data.element.style);
    const size = DiagramUtils.getNodeSize(item.data.element.style);
    const simpleNode = DiagramUtils.createSimpleNode(item.text, style, size);

    const node = graph.createNode(
      simpleNode.layout.toRect(),
      simpleNode.style,
      simpleNode.tag
    );
    //override the tags style with the theme elements style
    node.tag.style = item.data.element.style;

    const labelText = DiagramUtils.getPlaceholderLabelText(node);
    DiagramUtils.setLabel(graph, node, labelText);
  }
}
