/* eslint-disable no-undef */
import {
  FillType,
  FontDto,
  FontStyleDto,
  SolidColorFillDto
} from '@/api/models';
import cloneDeep from 'lodash/cloneDeep';
import CommandManager from '../services/CommandManager/CommandManager';
import CommandHandlerBase from '../services/CommandManager/CommandHandlerBase';
import DiagramUtils from './DiagramUtils';
import i18n from '@/core/plugins/vue-i18n';
import CommonEditorConfig from '@/core/config/ckEditor/commonEditorConfig';
import i18nService from '../services/i18n.service';
import { EventBus, EventBusActions } from '../services/events/eventbus.service';
import { Size } from 'yfiles/typings/yfiles-api-npm';
import JigsawRichTextLabelStyle from '../styles/JigsawRichTextLabelStyle';
import BackgroundDomService from '../services/BackgroundDomService';
import { ensureFontSizeUnit } from './html.utils';
import ExportConfig from '../config/ExportConfig';
import { FontColorPickerType } from '@/components/ColorPicker/ColorPickerUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import { TextElementTemplate } from '@/components/LayoutEditor/TextElementPresets';
import { Editor } from '@ckeditor/ckeditor5-core';
import { debounce } from '../common/DebounceDecorator';
import fastdom from 'fastdom';

export const ZERO_WIDTH_SPACE = '\u200B';
export const WORD_JOINER = '\u2060';
export let lockToolbarSync = false;
export default class CKEditorUtils {
  private static storedSelectionRange: any;
  private static storedSelectionAttributes: any;
  private static ckEditorInstance: Editor;

  private static onKeyDown(e, data): void {
    data.domEvent.preventDefault();
    e.stop();
  }

  public static invokeColorPicker(
    options: any,
    callback: Function,
    fontColorPickerType: FontColorPickerType
  ): void {
    let handler = null;
    handler = (): void => {
      EventBus.$off(EventBusActions.CKEDITOR_COLOR_PICKER_CHANGED, callback);
      EventBus.$off(EventBusActions.CKEDITOR_COLOR_PICKER_HIDE, handler);
    };

    // click-outside runs "open" first then "close"
    // run this in next event loop, to let Color Picker "close" and then be "open" again
    setTimeout(() => {
      if (options.isOpen) {
        EventBus.$on(EventBusActions.CKEDITOR_COLOR_PICKER_CHANGED, callback);
        EventBus.$on(EventBusActions.CKEDITOR_COLOR_PICKER_HIDE, handler);
        EventBus.$emit(
          EventBusActions.CKEDITOR_COLOR_PICKER_SHOW,
          options,
          fontColorPickerType
        );
      }
    });
  }

  public static handleEditorKeyDown(isOpen: boolean, editor: Editor): void {
    if (editor) {
      if (isOpen) {
        editor.editing.view.document.on('keydown', CKEditorUtils.onKeyDown);
      } else {
        editor.editing.view.document.off('keydown', CKEditorUtils.onKeyDown);
      }
    }
  }

  // handler for Toolbar dropdown open/close events
  public static onDropdownOpen(
    commandName: string,
    options: Record<string, any>,
    callback: Function,
    editor: Editor
  ): void {
    CKEditorUtils.handleEditorKeyDown(options.isOpen, editor);

    switch (commandName) {
      case 'fontColor':
        CKEditorUtils.handleFontColor(options, callback);
        break;
      case 'fontBackgroundColor':
        CKEditorUtils.handleFontBackgroundColor(options, callback);
        break;
      case 'listMarkerColor':
        CKEditorUtils.handleListMarkerColor(options, callback);
        break;
      case 'tableColor':
        CKEditorUtils.handleTableColor(options, callback);
        break;
      case 'fontFamily':
      case 'lineHeight':
      case 'alignment':
      case 'listStyle':
      case 'fontSize':
        break;
      default:
        console.warn(
          '[onDropdownOpen] error: not supported [commandName]: ',
          commandName
        );
    }
  }

  public static onCustomSpacingOpen(options: Record<string, any>): void {
    EventBus.$emit(EventBusActions.DOCUMENT_CUSTOM_SPACING_CLICKED, options);
  }

  public static onInternalLinkClicked(options: Record<string, any>): void {
    EventBus.$emit(
      EventBusActions.DOCUMENT_STEPS_PAGE_NUMBER_NAVIGATION,
      options
    );
  }

  public static onEditLinkClicked(options: Record<string, any>): void {
    EventBus.$emit(EventBusActions.DOCUMENT_EDIT_LINK_CLICKED, options);
  }

  public static handleFontBackgroundColor(
    options: any,
    callback: Function
  ): void {
    CKEditorUtils.invokeColorPicker(
      options,
      callback,
      FontColorPickerType.FontBackgroundColor
    );
  }

  public static handleFontColor(options: any, callback: Function): void {
    CKEditorUtils.invokeColorPicker(
      options,
      callback,
      FontColorPickerType.FontColor
    );
  }

  public static handleListMarkerColor(options: any, callback: Function): void {
    CKEditorUtils.invokeColorPicker(
      options,
      callback,
      FontColorPickerType.ListMarkerColor
    );
  }

  public static handleTableColor(options: any, callback: Function): void {
    CKEditorUtils.invokeColorPicker(
      options,
      callback,
      FontColorPickerType.TableColor
    );
  }

  public static closeColorPicker(): void {
    setTimeout(() => {
      EventBus.$emit(EventBusActions.CKEDITOR_COLOR_PICKER_HIDE);
    });
  }

  private static getFontStyleFromModel(
    model: String,
    fontStyles: FontStyleDto[]
  ): FontStyleDto {
    if (!fontStyles) return null;
    return fontStyles.find((fs) => fs.model == model);
  }

  public static applyFontStyleToConfiguration(
    configToUpdate: any,
    fontStyles: FontStyleDto[]
  ): any {
    if (!fontStyles || fontStyles.length === 0) {
      return configToUpdate;
    }

    const config = cloneDeep(configToUpdate);

    for (let i = 0; i < config.heading.options.length; i++) {
      const fs = this.getFontStyleFromModel(
        config.heading.options[i].model,
        fontStyles
      );
      if (!fs) continue;
      config.heading.options[i].title = fs.title;
    }
    return config;
  }

  public static generatePtSetting(sizes: number | number[]) {
    if (!Array.isArray(sizes) && !isNaN(sizes)) {
      sizes = [sizes];
    }
    return (sizes as number[]).map((size) => {
      return {
        model: `${size}pt`,
        title: size.toString(),
        view: {
          name: 'span',
          styles: {
            'font-size': `${size}pt`
          }
        }
      };
    });
  }

  public static setCkEditorFontStyles(fontStyles: FontStyleDto[]): void {
    if (fontStyles) {
      fontStyles.forEach((fs) => {
        CKEditorUtils.removeFontStyleSheet(fs);
      });
      CKEditorUtils.addStyleSheet(fontStyles);
    }
  }

  public static removeFontStyleSheet(fontStyle: FontStyleDto): void {
    const styleSheetId = fontStyle.model + fontStyle.cssElement;
    var sheet = document.getElementById(styleSheetId);
    if (sheet) {
      sheet.parentNode.removeChild(sheet);
    }
  }

  public static addStyleSheet(fontStyles: FontStyleDto[]): void {
    fontStyles.forEach((fs) => {
      var style = document.createElement('style');
      style.id = fs.model + fs.cssElement;
      style.type = 'text/css';

      style.innerHTML = `${fs.cssElement}, ${fs.cssElement} span
       { font-family: ${fs.style.fontFamily}, sans-serif !important;
         font-size: ${fs.style.fontSize}pt !important;
         font-weight: ${fs.style.fontWeight} !important;
         text-decoration: ${fs.style.textDecoration} !important;
         font-style: ${fs.style.fontStyle} !important; }`;
      document.getElementsByTagName('head')[0].appendChild(style);
    });
  }

  public static createHtmlStringFromStyle(
    color?: SolidColorFillDto,
    backgroundColor?: SolidColorFillDto,
    font?: FontDto,
    text?: string,
    textAlign?: string,
    lineHeight?: string,
    hasStrikethrough?: boolean,
    hasSubscript?: boolean,
    hasSuperscript?: boolean,
    hasList?: boolean,
    isNodeLabel?: boolean
  ): string {
    let htmlElement;
    if (hasList) {
      htmlElement = document.createElement('li');
      htmlElement.style.fontFamily = font.fontFamily;
      htmlElement.style.textAlign = textAlign;
      htmlElement.style.margin = '0';
      htmlElement.style.fontWeight = font.fontWeight;
    } else {
      htmlElement = document.createElement('p');
      htmlElement.style.textAlign = textAlign ?? 'center';
    }
    if (isNodeLabel) {
      htmlElement.style.lineHeight = diagramConfig.defaultNodeLabelLineHeight;
    }

    let spanElement = CKEditorUtils.appendStyles(
      color,
      backgroundColor,
      font,
      lineHeight,
      hasStrikethrough,
      hasSubscript,
      hasSuperscript,
      text
    );
    htmlElement.appendChild(spanElement);
    let html = htmlElement.outerHTML.replaceAll('&quot;', "'");

    // Trim all whitespace in the attribute values to match CKEditor output e.g.
    // <p style="text-align: center;"><span style="color: rgb(0, 0, 0); font-family: 'Courier New'; font-size: 10pt;">[Partnership]</span></p>
    // <p style="text-align:center;"><span style="color:rgb(0,0,0);font-family:'Courier New';font-size:10pt;">[Partnership]</span></p>
    html = html.replaceAll(/=".+?"/g, (attr) => {
      return attr.replaceAll(/([:;,])\s/g, '$1');
    });
    return html;
  }

  private static appendStyles(
    color?: SolidColorFillDto,
    backgroundColor?: SolidColorFillDto,
    font?: FontDto,
    lineHeight?: string,
    hasStrikethrough?: boolean,
    hasSubscript?: boolean,
    hasSuperscript?: boolean,
    text?: string
  ): HTMLSpanElement {
    // font color,family & size must be attached to this element
    const spanElement = document.createElement('span');
    let innerElement: HTMLElement = spanElement;

    if (color) {
      spanElement.style.color = `${color.color}`;
    }
    if (backgroundColor) {
      spanElement.style.backgroundColor = `${backgroundColor.color}`;
    }
    if (lineHeight) {
      spanElement.style.lineHeight = `${lineHeight}`;
    }

    if (font) {
      if (font.fontFamily) {
        spanElement.style.fontFamily = font.fontFamily;
      }

      if (font.fontSize) {
        spanElement.style.fontSize = `${font.fontSize}pt`;
      }

      if (font.fontStyle?.toLocaleLowerCase() == 'italic') {
        const childElement = document.createElement('i');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }

      if (
        font.fontWeight?.toLocaleLowerCase() == 'bold' ||
        +font.fontWeight > 400
      ) {
        const childElement = document.createElement('b');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }

      if (
        font.textDecoration?.toLocaleLowerCase() == 'underline' ||
        font.textDecoration?.includes('underline')
      ) {
        const childElement = document.createElement('u');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }

      if (hasStrikethrough) {
        const childElement = document.createElement('s');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }
      if (hasSubscript) {
        const childElement = document.createElement('sub');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }
      if (hasSuperscript) {
        const childElement = document.createElement('sup');
        innerElement.appendChild(childElement);
        innerElement = childElement;
      }
    }
    // Once all elements have been placed, insert the final inner span.
    innerElement.innerText = text ?? ZERO_WIDTH_SPACE;

    return spanElement;
  }

  /**
   * Syncronizes the state of the toolbar with the ribbon
   * @param editor The CKEditor Instance
   * @param ribbonToolbarSelector The toolbar inside
   * @param toolbarToolsSelector The Ribbon tool selctor
   * @param isFocused is ckEditor focused?
   * @param commandHandler the command manager to send the state through
   */
  public static toolbarStateSync(
    editor,
    ribbonToolbarSelector: string,
    toolbarToolsSelector: string,
    isFocused: boolean,
    commandHandler: CommandHandlerBase
  ): void {
    if (lockToolbarSync) {
      return;
    }
    lockToolbarSync = true;
    CKEditorUtils.ckEditorInstance = editor;
    // locate the toolbar element
    const toolbarElement = editor.ui.view.toolbar.element;

    // Set focused editor toolbar

    CKEditorUtils.ensureToolbar(
      ribbonToolbarSelector,
      toolbarToolsSelector,
      toolbarElement
    );

    if (isFocused) {
      CommandManager.INSTANCE.setCommandHandler(commandHandler);
    } else {
      CommandManager.INSTANCE.unsetCommandHandler(commandHandler);
    }
    /**
     * in case of diagram text pages, two sync commands fire almost at the same time, so we process the first (the target of the click) and lock the syncing so the second
     * does not proceed
     */
    setTimeout(() => {
      lockToolbarSync = false;
    });
  }
  /**
   * Inserts the toolbarElement into the correct container
   * @param ribbonToolbarSelector
   * @param toolbarToolsSelector
   * @param toolbarElement
   * @returns
   */
  // Debounce is used to prevent the removal/insertion of toolbar elements multiple times, which can cause flickering
  @debounce(100)
  public static ensureToolbar(
    ribbonToolbarSelector: string,
    toolbarToolsSelector: string,
    toolbarElement: HTMLElement
  ): void {
    const ribbonToolbarElement = document.querySelector(ribbonToolbarSelector);
    let container: HTMLElement = ribbonToolbarElement as HTMLElement;
    if (!ribbonToolbarElement) {
      console.debug(
        'Unable to locate the top level container for the toolbar, does it exist?'
      );
      return;
    }
    fastdom.mutate(() => {
      const toolsElement =
        ribbonToolbarElement.querySelector(toolbarToolsSelector);
      if (toolsElement) {
        container = toolsElement as HTMLElement;
      }
      if (container.querySelectorAll('.ck.ck-toolbar').length != 0) {
        (container as any).replaceChildren(...[]);
      }
      container.appendChild(toolbarElement);
      // Add automation id to the toolbar
      ribbonToolbarElement
        .querySelector('.ck-toolbar__items')
        ?.setAttribute('data-automation-id', 'rb-ckeditor-toolbar');

      EventBus.$emit(EventBusActions.CKEDITOR_TOOLBAR_INSERTED);
    });
  }

  /**
   * Adds predefined elements to the ckEditor focusTracker elements list
   * @param editor The CKEditor Instance
   */
  public static addElementsToFocusTracker(editor): void {
    const clipboardEl = document.querySelector('[rb-name="clipboard"]');
    if (clipboardEl) {
      editor.ui.focusTracker.add(clipboardEl);
    }
    const undoEl = document.querySelector('[rb-name="undo"]');
    if (undoEl) {
      undoEl.setAttribute('tab-index', '-1');
      editor.ui.focusTracker.add(undoEl);
    }
    const redoEl = document.querySelector('[rb-name="redo"]');
    if (redoEl) {
      redoEl.setAttribute('tab-index', '-1');
      editor.ui.focusTracker.add(redoEl);
    }
  }

  private static translationsLoaded = false;
  public static ensureTranslationsLoaded(): void {
    if (this.translationsLoaded) {
      return;
    }
    this.translationsLoaded = true;
    const activeLanguage = i18nService.getActiveLanguage();
    const allMessages: { [key: string]: string } = i18n.messages[
      activeLanguage
    ] as any;
    const editorMessages = {};
    Object.keys(allMessages)
      .filter((key) => key.startsWith('EDITOR_'))
      .forEach(
        (key) =>
          (editorMessages[key.substr('EDITOR_'.length)] = allMessages[key])
      );
    // Make sure that the global object is defined. If not, define it.
    CKEDITOR_TRANSLATIONS = CKEDITOR_TRANSLATIONS || {};
    // Make sure that the dictionary for translations exists
    const lang = CommonEditorConfig.getConfig().language;
    CKEDITOR_TRANSLATIONS[lang] = CKEDITOR_TRANSLATIONS[lang] || {
      dictionary: {}
    };
    CKEDITOR_TRANSLATIONS[lang].dictionary =
      CKEDITOR_TRANSLATIONS[lang].dictionary || {};
    // Extend the CKEditor dictionary with our translations
    Object.assign(CKEDITOR_TRANSLATIONS[lang].dictionary, editorMessages);
  }

  public static modelItemToTextTemplate(
    modelItem: any,
    defaultText: string
  ): TextElementTemplate {
    const template: TextElementTemplate = { tagName: '' };
    switch (modelItem.name) {
      case 'heading1': {
        template.tagName = 'h1';
        template.classes = 'steps-heading-font';
        break;
      }
      case 'heading4': {
        template.tagName = 'h4';
        template.classes = 'steps-heading-font';
        break;
      }
      default: {
        template.tagName = 'p';
        template.classes = 'steps-normal-font';
        break;
      }
    }
    let fontStyles = {
      'background-color': '',
      'color': '',
      'font-family': '',
      'font-size': ''
    };
    let parentStyles = '';
    // if the item is empty, do not presist the alignment
    if (
      modelItem.hasAttribute('alignment') &&
      modelItem.getChild(0).data !== ZERO_WIDTH_SPACE
    ) {
      parentStyles += `text-align:${modelItem.getAttribute('alignment')};`;
    }

    if (modelItem.hasAttribute('lineHeight')) {
      parentStyles += `line-height:${modelItem.getAttribute('lineHeight')};`;
    }

    template.styles = parentStyles;

    const textNode = modelItem.getChild(0);

    textNode.hasAttribute('fontBackgroundColor')
      ? (fontStyles['background-color'] = textNode.getAttribute(
          'fontBackgroundColor'
        ))
      : delete fontStyles['background-color'];

    textNode.hasAttribute('fontColor')
      ? (fontStyles['color'] = textNode.getAttribute('fontColor'))
      : delete fontStyles['color'];

    textNode.hasAttribute('fontFamily')
      ? (fontStyles['font-family'] = textNode.getAttribute('fontFamily'))
      : delete fontStyles['font-family'];

    textNode.hasAttribute('fontSize')
      ? (fontStyles['font-size'] = textNode.getAttribute('fontSize'))
      : delete fontStyles['font-size'];

    const fontData = {
      text: i18n.t(defaultText),
      isBold: !!textNode.getAttribute('bold'),
      isItalic: !!textNode.getAttribute('italic'),
      isUnderline: !!textNode.getAttribute('underline'),
      isStrikeThrough: !!textNode.getAttribute('strikethrough'),
      styles: fontStyles
    };
    template.data = fontData;
    return template;
  }

  public static placeCursorAtEnd(ckEditor: Editor): void {
    ckEditor.model.change((writer) => {
      try {
        writer.setSelection(
          writer.createPositionAt(ckEditor.model.document.getRoot(), 'end')
        );
      } catch (ex) {
        console.error(ex);
      }
    });
  }

  public static placeCursorAtPath(ckEditor: Editor, path: Array<number>): void {
    ckEditor.model.change((writer) => {
      try {
        writer.setSelection(
          writer.createPositionFromPath(ckEditor.model.document.getRoot(), path)
        );
      } catch (ex) {
        console.error(ex);
      }
    });
  }

  public static selectAllText(ckEditor: Editor): void {
    ckEditor.model.change((writer) => {
      try {
        const range = writer.createRangeIn(ckEditor.model.document.getRoot());
        writer.setSelection(range);
      } catch (ex) {
        console.error(ex);
      }
    });
  }

  /**
   * Measures the minimum width of a single character
   * @param maxWidth the maximum width
   * @param font the font to use when measuring.
   * @returns
   */
  public static getMinimumCharacterWidth(
    maxWidth: number,
    font?: FontDto
  ): Size {
    return JigsawRichTextLabelStyle.measureTextRaw(
      CKEditorUtils.createHtmlStringFromStyle(null, null, font, '_'),
      maxWidth
    );
  }

  public static getProminentStyles(
    inputHtml: string,
    whitelistStyleNames: string[]
  ): KeyValuePairStyle[] {
    const child = BackgroundDomService.createElement('div');
    child.classList.add(
      ExportConfig.pageContentClass,
      ExportConfig.diagramContentClass
    );
    child.innerHTML = inputHtml;
    BackgroundDomService.appendElement(child);

    const styleDictionary = CKEditorUtils.createProminentStyleDictionary(
      child,
      whitelistStyleNames
    );

    const kvp = [];
    for (const key in styleDictionary) {
      // sort by weight
      styleDictionary[key].sort((a, b) =>
        a.count > b.count ? -1 : a.count < b.count ? 1 : 0
      );
      kvp.push({
        key: key,
        value: styleDictionary[key][0].value
      });
    }
    BackgroundDomService.removeElement(child);
    return kvp;
  }

  /**
   *
   * @param element Creates a dictionary of all styles on this element and it's children.
   * Each style can have multiple values and each of those values have a count, that count is the number of characters that the given style is applied to.
   * The more characters the more prominent.
   * <p>
   * <span style="color:green; font-size: 10pt">Hello</span>
   * <span style="color:yellow; font-size: 10pt">World</span>
   * <span style="font-size:; font-size: 6pt">Jigsaw Rocks</span>
   * </p>
   * Give the above example this would be the result
   * {
   *    "color": [{value: 'green', count: 6}, {value: 'yellow', count: 5}]
   *    "font-size": [{value: '10pt', count: 11}, {value: '6pt', count: 12}]
   * }
   * @param stylePropertyWhitelist
   * @param styleDictionary
   * @returns
   */
  private static createProminentStyleDictionary(
    element: Element,
    stylePropertyWhitelist: string[],
    styleDictionary?: { [name: string]: { value: string; count: number }[] }
  ): any {
    if (!styleDictionary) {
      styleDictionary = {};
    }

    const computed = window.getComputedStyle(element);
    // will count he text length for TEXT_NODES only, ignoring child values
    const getElementTextLength = (e): number => {
      let textLength = 0;
      e.childNodes.forEach((childNode) => {
        if (childNode.nodeType == Node.TEXT_NODE) {
          textLength += childNode.textContent.length;
        }
      });
      return textLength;
    };

    for (let i = 0; i < computed.length; i++) {
      const styleProperty = computed[i];
      // ignore properties that are not in the whitelist
      if (stylePropertyWhitelist.indexOf(styleProperty) < 0) {
        continue;
      }
      // get the value from style
      const value = computed[styleProperty] as string;

      if (CKEditorUtils.isDefaultStyleValue(styleProperty, value)) {
        continue;
      }

      // add non-existent value to dictionary with zero count
      if (!styleDictionary[styleProperty]) {
        styleDictionary[styleProperty] = [{ value: value, count: 0 }];
      }

      const existingStyleProp = styleDictionary[styleProperty];

      const existingValue = existingStyleProp.find((d) => d.value == value);

      if (existingValue) {
        existingValue.count += getElementTextLength(element);
      } else {
        styleDictionary[styleProperty].push({
          value: value,
          count: getElementTextLength(element)
        });
      }
    }

    for (let i = 0; i < element.children.length; i++) {
      const childElement = element.children[i];
      CKEditorUtils.createProminentStyleDictionary(
        childElement,
        stylePropertyWhitelist,
        styleDictionary
      );
    }
    return styleDictionary;
  }

  private static isDefaultStyleValue(
    styleProperty: string,
    value: string
  ): boolean {
    switch (styleProperty) {
      case 'padding-left':
      case 'padding-top':
      case 'padding-bottom':
      case 'padding-right':
      case 'margin-left':
      case 'margin-top':
      case 'margin-right':
      case 'margin-bottom':
        return value == '0px';
    }
  }

  // example: const str = '<p style="line-height:2;text-align:center;"><span style="color:rgb(0,0,0);font-family:'Arial Narrow';font-size:20pt;">[</span><span style="background-color:#ff980c;color:#ff0309;font-family:'Arial Narrow';font-size:20pt;"><i><strong><u>Corporate</u></strong></i></span><span style="color:rgb(0,0,0);font-family:'Arial Narrow';font-size:20pt;">]</span></p>'
  public static getFont(keyValuePairs: KeyValuePairStyle[]): FontDto {
    const font = DiagramUtils.getSystemDefaultLabelStyle().font;

    const getValue = (key: string): KeyValuePairStyle =>
      keyValuePairs.find((f) => f.key == key);
    const family = getValue('font-family');
    if (family) {
      font.fontFamily = family.value;
    }
    const size = getValue('font-size');
    if (size) {
      font.fontSize = ensureFontSizeUnit(size.value);
    }
    const color = getValue('color');
    if (color) {
      font.color = color.value;
    }
    const backgroundColor = getValue('background-color');
    if (backgroundColor) {
      font.backgroundColor = backgroundColor.value;
    }

    const fontStyle = getValue('font-style');
    if (fontStyle) {
      font.fontStyle = fontStyle.value;
    }

    const fontWeight = getValue('font-weight');
    if (fontWeight) {
      font.fontWeight = fontWeight.value;
    }

    const textDecoration = getValue('text-decoration');
    if (textDecoration) {
      font.textDecoration = textDecoration.value;
    }

    return font;
  }

  public static getFillFromName(
    keyValuePairs: KeyValuePairStyle[],
    name: string
  ): SolidColorFillDto {
    const fill: SolidColorFillDto = new SolidColorFillDto(FillType.SolidColor);
    let kv = keyValuePairs.find((f) => f.key.trim() == name);
    if (kv) fill.color = kv.value;
    return fill;
  }

  public static getColor(styles: KeyValuePairStyle[]): SolidColorFillDto {
    return this.getFillFromName(styles, 'color');
  }

  public static getBackgroundColor(
    styles: KeyValuePairStyle[]
  ): SolidColorFillDto {
    return this.getFillFromName(styles, 'background-color');
  }

  public static getTextAlignment(styles: KeyValuePairStyle[]): string {
    let kv = styles.find((f) => f.key == 'text-align');
    return kv?.value;
  }

  public static getLineHeight(styles: KeyValuePairStyle[]): string {
    let kv = styles.find((f) => f.key == 'line-height');
    return kv?.value;
  }

  public static getPaddingLeft(styles: KeyValuePairStyle[]): string {
    let kv = styles.find((f) => f.key == 'padding-left');
    return kv?.value;
  }
  public static getDisplay(styles: KeyValuePairStyle[]): string {
    let kv = styles.find((f) => f.key == 'display');
    return kv?.value;
  }

  public static getListStyleType(styles: KeyValuePairStyle[]): string {
    let kv = styles.find((f) => f.key == 'list-style-type');
    return kv?.value;
  }

  public static getHtmlStyle(stringHtml: string, style: string): string {
    return stringHtml.split(style)[1]?.split(';')[0];
  }

  public static sanitizeHtml(html: string): string {
    return html.replaceAll(WORD_JOINER, '');
  }

  public static saveCaretPosition(ckeditor: Editor): void {
    CKEditorUtils.ckEditorInstance = ckeditor;

    CKEditorUtils.storedSelectionRange =
      CKEditorUtils.ckEditorInstance.model.document.selection.getLastRange();
    CKEditorUtils.storedSelectionAttributes =
      CKEditorUtils.ckEditorInstance.model.document.selection.getAttributes();
  }

  public static restoreCaretPosition(): void {
    if (!CKEditorUtils.storedSelectionRange) return;

    CKEditorUtils.ckEditorInstance.editing.view.focus();

    CKEditorUtils.ckEditorInstance.model.change((writer) => {
      try {
        writer.setSelection(CKEditorUtils.storedSelectionRange);
        writer.setSelectionAttribute(CKEditorUtils.storedSelectionAttributes);
      } catch (e) {
        console.warn(e);
      }
    });
  }

  public static splitHtmlIntoParagraphs(html: string): string[] {
    return html.match(/<p[^>]*>.*?<\/p>/g);
  }

  public static resetEditor(editor: Editor): void {
    // CKEditor doesn't unbind internal event listeners for comment threads when removing them, causing memory leak
    const commentsRepository = editor.plugins.has('CommentsRepository')
      ? editor.plugins.get('CommentsRepository')
      : null;
    if (commentsRepository) {
      for (const commentThread of commentsRepository.getCommentThreads()) {
        commentThread.unbind();
      }
    }
    // History does not automatically reset when using setData, need to do this manually
    editor.model.document.history.reset();
  }
}
export interface KeyValuePairStyle {
  key: string;
  value: string;
}

const ribbonToolsSelector: string = '.vc-ribbon-ribbon-tools';
const toolbarSelector: string = '[rb-name="ckeditor-toolbar"]';

export { toolbarSelector, ribbonToolsSelector };
