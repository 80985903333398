import CollaborationApiService from '@/api/CollaborationApiService';
import { UserDto } from '@/api/models';
import { Module } from 'vuex';
import AnnotationData from '../editor/collaboration/AnnotationData';
import MarkerData from '../editor/collaboration/MarkerData';
import AnnotationDisplayMode from '../editor/collaboration/AnnotationDisplayMode';
import { Editor } from '@ckeditor/ckeditor5-core';

export const COLLABORATION_NAMESPACE = 'collaboration';

// action types
export const LOAD_USERS = 'loadUsers';
export const GET_ACTIVE_ANNOTATIONS = 'getActiveAnnotations';
export const GET_ACTIVE_MARKERS = 'getActiveMarkers';
// mutation types
export const SET_USERS = 'setUsers';
export const SET_ANNOTATIONS = 'setAnnotations';
export const SET_ACTIVE_ANNOTATIONS = 'setActiveAnnotations';
export const RESET_ACTIVE_ANNOTATIONS = 'resetActiveAnnotations';
export const SET_ACTIVE_MARKERS = 'setActiveMarkers';
export const SET_DISPLAY_MODE = 'setDisplayMode';
export const ADD_ANNOTATION = 'addAnnotation';
export const REMOVE_ANNOTATION = 'removeAnnotation';
export const RESET_ANNOTATIONS = 'resetAnnotation';
export const SET_IS_ACCEPT_ALL_SUGGESTIONS_ENABLED =
  'setIsAcceptAllSuggestionsEnabled';
export const SET_IS_ACCEPT_SELECTED_SUGGESTIONS_ENABLED =
  'setIsAcceptSelectedSuggestionsEnabled';
export const SET_IS_DISCARD_ALL_SUGGESTIONS_ENABLED =
  'setIsDiscardAllSuggestionsEnabled';
export const SET_IS_DISCARD_SELECTED_SUGGESTIONS_ENABLED =
  'setIsDiscardSelectedSuggestionsEnabled';
// getters
export const GET_USERS = 'getUsers';
export const GET_ANNOTATIONS = 'getAnnotations';
export const GET_DISPLAY_MODE = 'getDisplayMode';
export const GET_IS_ACCEPT_ALL_SUGGESTIONS_ENABLED =
  'getIsAcceptAllSuggestionsEnabled';
export const GET_IS_ACCEPT_SELECTED_SUGGESTIONS_ENABLED =
  'getIsAcceptSelectedSuggestionsEnabled';
export const GET_IS_DISCARD_ALL_SUGGESTIONS_ENABLED =
  'getIsDiscardAllSuggestionsEnabled';
export const GET_IS_DISCARD_SELECTED_SUGGESTIONS_ENABLED =
  'getIsDiscardSelectedSuggestionsEnabled';

interface State {
  users: UserDto[];
  annotations: AnnotationData[];
  activeAnnotations: AnnotationData[];
  activeMarkers: MarkerData[];
  displayMode: AnnotationDisplayMode;
  isAcceptAllSuggestionsEnabled: boolean;
  isAcceptSelectedSuggestionsEnabled: boolean;
  isDiscardAllSuggestionsEnabled: boolean;
  isDiscardSelectedSuggestionsEnabled: boolean;
}

const getInitialState = (): State => {
  return {
    users: null,
    annotations: [],
    activeAnnotations: [],
    activeMarkers: [],
    displayMode: AnnotationDisplayMode.CustomInline,
    isAcceptAllSuggestionsEnabled: false,
    isAcceptSelectedSuggestionsEnabled: false,
    isDiscardAllSuggestionsEnabled: false,
    isDiscardSelectedSuggestionsEnabled: false
  };
};

const collaborationModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_USERS](state): UserDto[] {
      return state.users;
    },
    [GET_ANNOTATIONS](state): AnnotationData[] {
      return state.annotations;
    },
    [GET_ACTIVE_ANNOTATIONS](state): AnnotationData[] {
      return state.activeAnnotations;
    },
    [GET_ACTIVE_MARKERS](state): MarkerData[] {
      return state.activeMarkers;
    },
    [GET_DISPLAY_MODE](state): AnnotationDisplayMode {
      return state.displayMode;
    },
    [GET_IS_ACCEPT_ALL_SUGGESTIONS_ENABLED](state): boolean {
      return state.isAcceptAllSuggestionsEnabled;
    },
    [GET_IS_ACCEPT_SELECTED_SUGGESTIONS_ENABLED](state): boolean {
      return state.isAcceptSelectedSuggestionsEnabled;
    },
    [GET_IS_DISCARD_ALL_SUGGESTIONS_ENABLED](state): boolean {
      return state.isDiscardAllSuggestionsEnabled;
    },
    [GET_IS_DISCARD_SELECTED_SUGGESTIONS_ENABLED](state): boolean {
      return state.isDiscardSelectedSuggestionsEnabled;
    }
  },
  actions: {
    async [LOAD_USERS]({ state, commit }) {
      if (!state.users) {
        const result = await CollaborationApiService.getUsers();
        commit(SET_USERS, result.data.result);
      }
      return state.users;
    }
  },
  mutations: {
    [SET_USERS](state, users: UserDto[]) {
      state.users = users;
    },
    [SET_ANNOTATIONS](state, annotations: AnnotationData[]) {
      state.annotations = annotations;
    },
    [SET_ACTIVE_ANNOTATIONS](state, annotations: AnnotationData[]) {
      state.activeAnnotations = annotations;
    },
    [RESET_ACTIVE_ANNOTATIONS](state) {
      if (state.activeAnnotations?.length > 0) {
        state.activeAnnotations = [];
      }
    },
    [SET_ACTIVE_MARKERS](state, markers: MarkerData[]) {
      state.activeMarkers = markers;
    },
    [SET_DISPLAY_MODE](state, mode: AnnotationDisplayMode) {
      state.displayMode = mode;
    },
    [ADD_ANNOTATION](state, annotation: AnnotationData) {
      state.annotations.push(annotation);
    },
    [REMOVE_ANNOTATION](state, annotation: AnnotationData) {
      const idx = state.annotations.findIndex(
        (a) => a.annotation === annotation.annotation
      );
      if (idx >= 0) {
        state.annotations.splice(idx, 1);
      }
    },
    [SET_IS_ACCEPT_ALL_SUGGESTIONS_ENABLED](state, payload: boolean) {
      state.isAcceptAllSuggestionsEnabled = payload;
    },
    [SET_IS_ACCEPT_SELECTED_SUGGESTIONS_ENABLED](state, payload: boolean) {
      state.isAcceptSelectedSuggestionsEnabled = payload;
    },
    [SET_IS_DISCARD_ALL_SUGGESTIONS_ENABLED](state, payload: boolean) {
      state.isDiscardAllSuggestionsEnabled = payload;
    },
    [SET_IS_DISCARD_SELECTED_SUGGESTIONS_ENABLED](state, payload: boolean) {
      state.isDiscardSelectedSuggestionsEnabled = payload;
    },
    [RESET_ANNOTATIONS](state) {
      state.annotations = [];
    }
  }
};

export default collaborationModule;
