import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import ThumbnailService from '../thumb/ThumbnailService';
import { ExportCachePolicy } from '../ExportCachePolicy';
import { ExportFormat } from '../ExportFormat';
import { FontType, customFonts } from '../CustomFonts';
import ExportConfig from '@/core/config/ExportConfig';
import CachingService from '../../caching/CachingService';
import {
  convertUrlToDataUrl,
  ScaleImageResult
} from '@/core/utils/common.utils';
import ContentPagination from '../ContentPagination';

const pdfKitImport = () => import('@jigsaw/pdfkit-latest');
const svgToPdfKitImport = () => import('@jigsaw/svg-to-pdfkit');
const blobStreamImport = () => import('blob-stream');

type FontData = {
  regular: ArrayBuffer;
  bold: ArrayBuffer;
  italic: ArrayBuffer;
  bolditalic: ArrayBuffer;
};
export default class PdfExportProvider implements IExportProvider {
  private static fontData: Record<string, FontData> = {};

  private _fileExtension = 'pdf';
  private _mimeType = 'application/pdf';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const pdfKit = (await pdfKitImport()).default;
    const svgToPdfKit = (await svgToPdfKitImport()).default;
    const blobStream = (await blobStreamImport()).default;

    const pdfDoc = new pdfKit({
      font: null,
      compress: true,
      autoFirstPage: false,
      displayTitle: true,
      size: [
        options.document.pageStyle.width,
        options.document.pageStyle.height
      ],
      info: {
        Title: options.document.name,
        Producer: 'Jigsaw',
        Creator: 'Jigsaw'
      },
      version: '1.7'
    });
    const svgToPdfKitOptions = {
      useCSS: false,
      assumePt: false,
      fontCallback: this.fontCallback,
      warningCallback: this.warningCallback
    };

    for (const exportPage of options.pages) {
      const subPageCount = ContentPagination.getPageCount(exportPage.page);
      for (let subPageIndex = 0; subPageIndex < subPageCount; subPageIndex++) {
        exportPage.subPageIndex = subPageIndex;
        options.metadata.currentPage = exportPage;
        const thumbElement = await this.getPageThumbnail(options);
        const loadFallbackFont = thumbElement.innerHTML.includes(
          ExportConfig.fallbackFontName
        );
        await this.ensureFontsLoaded(loadFallbackFont);
        pdfDoc.addPage();
        svgToPdfKit(pdfDoc, thumbElement, 0, 0, svgToPdfKitOptions);
      }
    }

    const blob = await new Promise((resolve: (blob: Blob) => void) => {
      const stream = pdfDoc.pipe(blobStream());
      stream.on('finish', () => {
        const blob = stream.toBlob(this._mimeType);
        resolve(blob);
      });
      pdfDoc.end();
    });

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: blob
    };
  }

  /**
   * Try to get and reuse cached thumbnail first, regenerate if it's low detail
   */
  private async getPageThumbnail(options: ExportOptions): Promise<SVGElement> {
    const generateThumbnailOptions = {
      ...options,
      cachePolicy: options.document.hasSteps
        ? ExportCachePolicy.Reuse
        : ExportCachePolicy.Ignore,
      format: ExportFormat.SvgElement
    };
    let thumbElement = (await ThumbnailService.generateThumbnail(
      generateThumbnailOptions
    )) as SVGElement;
    if (thumbElement.hasAttribute(ExportConfig.lowDetailDiagramAttribute)) {
      generateThumbnailOptions.cachePolicy = ExportCachePolicy.Ignore;
      thumbElement = (await ThumbnailService.generateThumbnail(
        generateThumbnailOptions
      )) as SVGElement;
    }
    if (thumbElement.hasAttribute(ExportConfig.lowDetailBackgroundAttribute)) {
      await this.replaceScaledImagesWithOriginal(thumbElement);
    }
    return thumbElement;
  }

  private fontCallback(
    family: string,
    bold: boolean,
    italic: boolean,
    element: any
  ): { fontBinary: ArrayBuffer; isSubstitute: boolean } {
    // True when font family or style has been substituted
    let isSubstitute = false;
    // Extract the font name (e.g. 'Arial, sans-serif' => 'Arial')
    let fontName = family.replace(/"|'/g, '').split(',')[0];
    // Substitute unicode unordered list symbols for custom fonts with Arial
    // Most custom fonts are missing the necessary unicode character glyphs
    if (element && element.getChildren) {
      const children = element.getChildren();
      if (
        children.length === 1 &&
        children[0].name === '#text' &&
        (children[0].textContent == '●' ||
          children[0].textContent == '○' ||
          children[0].textContent == '■' ||
          children[0].textContent == '– ')
      ) {
        fontName = 'Arial';
        isSubstitute = true;
      }
    }

    let fontType: FontType | '' = '';
    if (!bold && !italic) {
      fontType = 'regular';
    } else {
      if (bold) {
        fontType = 'bold';
      }
      if (italic) {
        fontType += 'italic';
      }
    }

    let fontBinary: ArrayBuffer;
    const fontData = PdfExportProvider.fontData[fontName.toLowerCase()];
    if (fontData) {
      fontBinary = fontData[fontType];
      if (!fontBinary) {
        if (bold && italic) {
          // For bold italic, try to fall back to bold first, then regular
          fontBinary = fontData.bold ?? fontData.regular;
        } else {
          // Otherwise always fall back to regular
          fontBinary = fontData.regular;
        }
        isSubstitute = true;
      }
    }

    // If no font was found, fall back to default
    if (!fontBinary) {
      fontName = ExportConfig.defaultContentFontStyle.fontFamily;
      fontBinary = PdfExportProvider.fontData[fontName.toLowerCase()][fontType];
      isSubstitute = true;
    }

    return { fontBinary, isSubstitute };
  }

  private warningCallback(message: string): void {
    console.warn(`Pdf export warning: ${message}`);
  }

  private async ensureFontsLoaded(loadFallbackFont = false): Promise<void> {
    for (const fontName in customFonts) {
      const fontNameLowercase = fontName.toLowerCase();
      if (
        PdfExportProvider.fontData[fontNameLowercase] ||
        (!loadFallbackFont &&
          fontNameLowercase == ExportConfig.fallbackFontName.toLowerCase())
      ) {
        continue;
      }
      const result = await Promise.all([
        this.fetchFont(fontName, 'regular'),
        this.fetchFont(fontName, 'bold'),
        this.fetchFont(fontName, 'italic'),
        this.fetchFont(fontName, 'bolditalic')
      ]);
      const [regular, bold, italic, bolditalic] = result;
      const fontBinaryData: FontData = { regular, bold, italic, bolditalic };
      PdfExportProvider.fontData[fontNameLowercase] = fontBinaryData;
    }
  }

  private async fetchFont(
    fontName: string,
    fontType: FontType
  ): Promise<ArrayBuffer> {
    if (!customFonts[fontName][fontType]) return null;
    const fontDefinition = customFonts[fontName];
    const response = await fetch(fontDefinition[fontType]);
    return await response.arrayBuffer();
  }

  private async replaceScaledImagesWithOriginal(
    thumbElement: SVGElement
  ): Promise<void> {
    const images = thumbElement.querySelectorAll(
      `image[${ExportConfig.thumbOriginalImageKeySvgAttribute}]`
    );
    for (const image of images) {
      const cacheKey = image.getAttribute(
        ExportConfig.thumbOriginalImageKeySvgAttribute
      );
      const res = CachingService.get<ScaleImageResult>(cacheKey);
      if (res) {
        image.setAttribute('href', await convertUrlToDataUrl(res.originalUrl));
      }
      image.removeAttribute(ExportConfig.thumbOriginalImageKeySvgAttribute);
    }
  }
}
