import CacheType from './CacheType';
import ICacheData from './ICacheData';
import Mutex from '@/core/common/Mutex';
import imurmurhash from 'imurmurhash';
import { getObjectSize } from '@/core/utils/common.utils';

class CachingService {
  private readonly purgeExpiredEntriesInterval = 60000; // 1 min
  private readonly defaultMaxAge = 60000 * 5; // 5 mins
  private readonly cacheTypeSeparator = '|';
  private readonly cacheKeyParamsSeparator = '-';

  private cache: Record<string, ICacheData<any>> = {};
  private mutexes: Record<CacheType, Mutex> = {} as Record<CacheType, Mutex>;

  public get keys(): string[] {
    return Object.keys(this.cache);
  }

  public get size(): number {
    return getObjectSize(this.cache);
  }

  constructor() {
    setInterval(
      () => this.purgeExpiredEntries(),
      this.purgeExpiredEntriesInterval
    );
    (window as any).cache = this;
  }

  public get<T>(key: string): T {
    const data: ICacheData<T> = this.cache[key];
    if (!data || this.isExpired(key)) {
      return null;
    }
    if (data.slidingExpiration) {
      this.updateExpiry(data);
    }
    return data.data as T;
  }

  public set<T>(key: string, data: ICacheData<T>): void {
    if (!data.maxAge) {
      data.maxAge = this.defaultMaxAge;
      data.slidingExpiration = true;
    }
    if (!data.expires) {
      this.updateExpiry(data);
    }
    this.cache[key] = data;
  }

  public getOrSet<T>(key: string, setDataFunc: () => ICacheData<T>): T {
    if (!this.contains(key) || this.isExpired(key)) {
      const data: ICacheData<T> = setDataFunc();
      this.set(key, data);
    }
    return this.get<T>(key);
  }

  public async getOrSetAsync<T>(
    key: string,
    setDataFunc: () => Promise<ICacheData<T>>
  ): Promise<T> {
    if (!this.contains(key) || this.isExpired(key)) {
      const data: ICacheData<T> = await setDataFunc();
      this.set(key, data);
    }
    return this.get<T>(key);
  }

  public async getOrSetMutexAsync<T>(
    key: string,
    setDataFunc: () => Promise<ICacheData<T>>
  ): Promise<T> {
    const type = this.getCacheTypeFromKey(key);
    if (type == CacheType.Unset) {
      return await this.getOrSetAsync(key, setDataFunc);
    }
    if (!(type in this.mutexes)) {
      this.mutexes[type] = new Mutex();
    }
    const unlock = await this.mutexes[type].lock();
    try {
      return await this.getOrSetAsync(key, setDataFunc);
    } finally {
      unlock();
    }
  }

  public contains(key: string): boolean {
    return key in this.cache;
  }

  public generateKey(type: CacheType, ...params: any[]): string {
    const hashedParams = params.flat().map(this.calculateHash);
    return `${type}${this.cacheTypeSeparator}${hashedParams.join(
      this.cacheKeyParamsSeparator
    )}`;
  }

  public clear(): void {
    this.cache = {};
    this.mutexes = {} as Record<CacheType, Mutex>;
  }

  public removeByKey(key: string): void {
    delete this.cache[key];
  }

  public removeByCacheType(type: CacheType): void {
    for (const key in this.cache) {
      if (type == this.getCacheTypeFromKey(key)) {
        this.removeByKey(key);
      }
    }
  }

  private purgeExpiredEntries(): void {
    for (const key in this.cache) {
      if (this.isExpired(key)) {
        this.removeByKey(key);
      }
    }
  }

  private isExpired(key: string): boolean {
    const data = this.cache[key];
    return data?.expires < new Date();
  }

  private updateExpiry(data: ICacheData<any>): void {
    if (!data.maxAge) {
      return;
    }
    data.expires = new Date(Date.now() + data.maxAge);
  }

  private getCacheTypeFromKey(key: string): CacheType {
    const idx = key.indexOf(this.cacheTypeSeparator);
    if (idx > 0) {
      return CacheType[key.substring(0, idx)];
    }
    return CacheType.Unset;
  }

  private calculateHash(value: any): string {
    if (value === null || value === undefined) {
      return 'empty';
    }
    if (typeof value === 'object') {
      const data = JSON.stringify(value);
      return imurmurhash(data).result().toString();
    } else if (typeof value === 'string') {
      return imurmurhash(value).result().toString();
    }
    return value.toString();
  }
}

const instance = new CachingService();
export default instance;
