import isNil from 'lodash/isNil';
import { TPreset } from '../TextElementPresets';
import HtmlLayoutItem from './HtmlLayoutItem';
import ITextBoxLayoutItemOptions from './ITextBoxLayoutItemOptions';
import { LayoutItemType } from './LayoutItemType';

export default class TextBoxLayoutItem extends HtmlLayoutItem {
  public preset: TPreset;
  // This property is used to mimic Powerpoint behavior (keep size until editing started), when create textBox by CreationBox
  public keepInitialSize: boolean = false;
  public defaultText = 'ADD_TEXT_ELLIPSES';
  public defaultStyles: string = null;

  constructor(options?: ITextBoxLayoutItemOptions) {
    super(LayoutItemType.Text);
    this.html = options?.html;
    this.preset = options?.preset;
    this.isTextEditable = true;
    this.isTextStyleEditable = true;
    if (!isNil(options?.defaultText)) {
      this.defaultText = options.defaultText;
    }
    this.defaultStyles = options?.defaultStyles;
  }

  public serialize(): Object {
    const obj = super.serialize();
    obj['preset'] = this.preset;
    obj['defaultText'] = this.defaultText;
    obj['defaultStyles'] = this.defaultStyles;
    return obj;
  }
}
