import AuthService from '@/core/services/auth/AuthService';
import appConfig from '@/core/config/appConfig';
import { ThemeDto, TimelineDto } from '@/api/models';
import i18n from '@/core/plugins/vue-i18n';
import IManageAuthService from '@/core/services/auth/IManageAuthService';

const DEFAULT_EVENT_MARGIN = 74;
const DEFAULT_TIME_AXIS_MODE = 'Date';
const DEFAULT_TIME_AXIS_SETTINGS = {
  isSolidBlock: false,
  topRowHeight: 3,
  bottomRowHeight: 3,
  lineThickness: 24,
  topRowTransparency: 100,
  bottomRowTransparency: 100,
  topRowTextTransparency: 100,
  bottomRowTextTransparency: 100,
  topRowFillColor: '#FFFFFF',
  bottomRowFillColor: '#ECEDEF',
  solidFillColor: '#ECEDEF',
  outlineColor: '#464E5F',
  outlineStyle: 'solid',
  outlineWidth: 1,
  weekendColor: '',
  topRowTextBold: false,
  bottomRowTextBold: false,
  topRowTextItalic: false,
  bottomRowTextItalic: false,
  topRowTextUnderline: false,
  bottomRowTextUnderline: false,
  topRowTextColor: '#667280',
  bottomRowTextColor: '#667280',
  topRowFontFamily: 'Arial',
  bottomRowFontFamily: 'Arial',
  topRowFontSize: 14,
  bottomRowFontSize: 14
};

export default class TimelineUtils {
  public static async redirectToTimeline(id?: number): Promise<void> {
    const accessToken = await AuthService.getAccessToken();

    // Store access token in cookie
    // Short lived - cookie expires in 1 minute
    document.cookie = `timelineSession=${accessToken};secure;max-age=60`;

    // Check iManage token
    if (appConfig.iManage.isEnabled) {
      await IManageAuthService.checkToken();
    }

    // Redirect to Timelines App
    window.location.href = this.getTimelineUrl(id);
  }

  public static getTimelineUrl(id: number): string {
    const isLocal = window.location.hostname === 'localhost';
    var link =
      (isLocal ? 'https://localhost:8085' : '/timelines') +
      `${appConfig.timeline.endpoint}`;
    if (id !== undefined) {
      link += `?id=${id}`;
    }
    return link;
  }

  public static generateLink(documentId: number | string): string {
    const isLocal = window.location.hostname === 'localhost';
    if (isLocal) {
      return `https://localhost:8085/#/timeline?id=${documentId}`;
    }
    return `${window.location.origin}/timelines/#/timeline?id=${documentId}`;
  }

  public static getInitialTimeline(theme: ThemeDto): TimelineDto {
    const timelineContent = new TimelineDto(
      false,
      i18n.t('NEW_TIMELINE'),
      'Timeline document',
      TimelineUtils.getInitialTimelineJSON(theme)
    );
    return timelineContent;
  }

  public static getTimelineTheme(theme: ThemeDto): object {
    return JSON.parse(
      theme.timelineTheme ?? this.getDefaultTimelineThemeJson()
    );
  }

  public static getDefaultSwimlaneHtml(name: string): string {
    return `<p style="font-size:10pt;"><span style="font-family:Arial;font-size:10pt;">${i18n.t(
      name
    )}</span></p>`;
  }

  public static getInitialTimelineJSON(theme: ThemeDto): string {
    const timelineInitDisplayOptions = {
      isNonWorkingDays: appConfig.timelineSettings.isNonWorkingDays,
      displayLogo: appConfig.timelineSettings.displayLogo,
      displayAttachments: appConfig.timelineSettings.displayAttachments,
      displayDependency: appConfig.timelineSettings.dependencyDisplay,
      connectorLabelPosition: appConfig.timelineSettings.connectorLabelPosition,
      swimlaneWidth: appConfig.timelineSettings?.swimlaneWidth,
      eventDateSettings: {
        Date: appConfig.timelineSettings.singleDateDatePosition,
        Range: appConfig.timelineSettings.rangeDatePosition,
        Phase: appConfig.timelineSettings.phaseDatePosition
      }
    };

    const topSwimlane = appConfig.timelineSettings?.topSwimlaneName;

    const bottomSwimlane = appConfig.timelineSettings?.bottomSwimlaneName;

    const timelineJSON = {
      success: true,
      project: {
        calendar: 'project',
        displayOptions: timelineInitDisplayOptions,
        logo: {
          logoPosition: theme.logoPosition,
          logoX: null,
          logoY: null
        },
        viewPortState: {
          eventMinHeight: DEFAULT_EVENT_MARGIN,
          zoomLevel: appConfig.timelineSettings?.zoomLevel,
          centerDate: new Date()
        },
        dateLocale: null,
        dateLocaleFormatOptions: {
          dateDigitsFormat: 'Long',
          monthDigitsFormat: 'Letters',
          yearDigitsFormat: 'Long',
          leadWithYear: false,
          separator: ' ',
          showDate: true,
          showMonth: true,
          showYear: true
        },
        dependencyLabelSettings: {
          dependencyDisplay: appConfig.timelineSettings.dependencyDisplay,
          dependencyLineThickness:
            appConfig.timelineSettings.dependencyLineThickness,
          dependencyLabelFontFamily:
            appConfig.timelineSettings.dependencyLabelFontFamily,
          dependencyLabelFontSize:
            appConfig.timelineSettings.dependencyLabelFontSize,
          dependencyLabelBold: appConfig.timelineSettings?.dependencyLabelBold,
          dependencyLabelItalic:
            appConfig.timelineSettings.dependencyLabelItalic,
          dependencyLabelUnderline:
            appConfig.timelineSettings.dependencyLabelUnderline
        },
        timeAxisStyle: {
          isSolidBlock: appConfig.timelineSettings.isTimeAxisSolidBlock,
          topRowHeight: DEFAULT_TIME_AXIS_SETTINGS.topRowHeight,
          bottomRowHeight: DEFAULT_TIME_AXIS_SETTINGS.bottomRowHeight,
          lineThickness: appConfig.timelineSettings.timeAxisSolidHeight,
          topRowTransparency: DEFAULT_TIME_AXIS_SETTINGS.topRowTransparency,
          bottomRowTransparency:
            DEFAULT_TIME_AXIS_SETTINGS.bottomRowTransparency,
          topRowTextTransparency:
            DEFAULT_TIME_AXIS_SETTINGS.topRowTextTransparency,
          bottomRowTextTransparency:
            DEFAULT_TIME_AXIS_SETTINGS.bottomRowTextTransparency,
          topRowFillColor: DEFAULT_TIME_AXIS_SETTINGS.topRowFillColor,
          bottomRowFillColor: DEFAULT_TIME_AXIS_SETTINGS.bottomRowFillColor,
          solidFillColor: appConfig.timelineSettings.timeAxisSolidColor,
          outlineColor: DEFAULT_TIME_AXIS_SETTINGS.outlineColor,
          outlineStyle: DEFAULT_TIME_AXIS_SETTINGS.outlineStyle,
          outlineWidth: DEFAULT_TIME_AXIS_SETTINGS.outlineWidth,
          weekendColor: DEFAULT_TIME_AXIS_SETTINGS.weekendColor,
          topRowTextBold: DEFAULT_TIME_AXIS_SETTINGS.topRowTextBold,
          bottomRowTextBold: DEFAULT_TIME_AXIS_SETTINGS.bottomRowTextBold,
          topRowTextItalic: DEFAULT_TIME_AXIS_SETTINGS.topRowTextItalic,
          bottomRowTextItalic: DEFAULT_TIME_AXIS_SETTINGS.bottomRowTextItalic,
          topRowTextUnderline: DEFAULT_TIME_AXIS_SETTINGS.topRowTextUnderline,
          bottomRowTextUnderline:
            DEFAULT_TIME_AXIS_SETTINGS.bottomRowTextUnderline,
          topRowTextColor: DEFAULT_TIME_AXIS_SETTINGS.topRowTextColor,
          bottomRowTextColor: DEFAULT_TIME_AXIS_SETTINGS.bottomRowTextColor,
          topRowFontFamily: DEFAULT_TIME_AXIS_SETTINGS.topRowFontFamily,
          bottomRowFontFamily: DEFAULT_TIME_AXIS_SETTINGS.bottomRowFontFamily,
          topRowFontSize: DEFAULT_TIME_AXIS_SETTINGS.topRowFontSize,
          bottomRowFontSize: DEFAULT_TIME_AXIS_SETTINGS.bottomRowFontSize
        },
        timeAxisMode: DEFAULT_TIME_AXIS_MODE
      },
      calendars: {
        rows: [
          {
            id: 'project',
            name: 'Project Calendar',
            unspecifiedTimeIsWorking: true,
            intervals: []
          },
          {
            id: 'workinghours',
            name: 'Working Hours',
            unspecifiedTimeIsWorking: true,
            intervals: []
          }
        ]
      },
      resources: {
        rows: [
          {
            id: 1,
            name: this.getDefaultSwimlaneHtml(topSwimlane),
            displayName: topSwimlane,
            calendar: 'workinghours',
            isTimeAxis: false,
            themeId: theme.id
          },
          {
            id: 2,
            name: '',
            displayName: '',
            calendar: 'project',
            isTimeAxis: true,
            themeId: theme.id
          },
          {
            id: 3,
            name: this.getDefaultSwimlaneHtml(bottomSwimlane),
            displayName: bottomSwimlane,
            calendar: 'workinghours',
            isTimeAxis: false,
            themeId: theme.id
          }
        ]
      }
    };
    return JSON.stringify(timelineJSON);
  }

  public static getDefaultTimelineThemeJson(): string {
    const defaultTimelineThemeJson = {
      timelineThemeEventShapes: [
        {
          id: 1,
          name: 'Rounded Rectangle (Single Date)',
          eventType: 'Date',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 2,
          name: 'Rectangle (Single Date)',
          eventType: 'Date',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 3,
          name: 'Diamond (Single Date)',
          eventType: 'Date',
          shape: 'Diamond',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 4,
          name: 'Rounded Rectangle (Range)',
          eventType: 'Range',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: true,
          connector: {
            type: 'rangeDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 5,
          name: 'Rectangle (Range)',
          eventType: 'Range',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: true,
          connector: {
            type: 'rangeDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 6,
          name: 'Rounded Rectangle (Phase)',
          eventType: 'Phase',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        },
        {
          id: 7,
          name: 'Rectangle (Phase)',
          eventType: 'Phase',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        },
        {
          id: 8,
          name: 'Arrow Left (Phase)',
          eventType: 'Phase',
          shape: 'ArrowLeft',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        },
        {
          id: 9,
          name: 'Arrow Right (Phase)',
          eventType: 'Phase',
          shape: 'ArrowRight',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        },
        {
          id: 10,
          name: 'Double Arrow (Phase)',
          eventType: 'Phase',
          shape: 'DoubleArrow',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        },
        {
          id: 11,
          name: 'Rounded Rectangle (TextBox)',
          eventType: 'TextBox',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          titleFontBackgroundColor: 'transparent',
          showConnector: false
        }
      ]
    };
    return JSON.stringify(defaultTimelineThemeJson);
  }
}
