import {
  BaseClass,
  Class,
  Color,
  Fill,
  FillConvertible,
  GeneralPath,
  IBoundsProvider,
  ICanvasContext,
  IHitTestable,
  IInputModeContext,
  ILookup,
  IMarqueeTestable,
  INode,
  INodeStyle,
  INodeStyleRenderer,
  IRenderContext,
  IShapeGeometry,
  IVisibilityTestable,
  IVisualCreator,
  Point,
  Rect,
  SolidColorFill,
  Stroke,
  SvgVisual,
  Visual
} from 'yfiles';

import JigsawPathShapeNodeStyle from './JigsawPathShapeNodeStyle';
import { JigsawPathShape } from '@/api/models';
import GraphElementsComparer from '../utils/GraphElementsComparer';

const RenderCacheKey = 'JigsawPathNodeStyleRenderCache';
class JigsawPathNodeStyleRendererImpl extends BaseClass<
  INodeStyleRenderer,
  IVisualCreator,
  IBoundsProvider,
  IHitTestable,
  ILookup,
  IMarqueeTestable,
  IVisibilityTestable,
  IShapeGeometry
>(
  INodeStyleRenderer,
  IVisualCreator,
  IBoundsProvider,
  IHitTestable,
  ILookup,
  IMarqueeTestable,
  IVisibilityTestable,
  IShapeGeometry
) {
  public static readonly INSTANCE: JigsawPathNodeStyleRendererImpl =
    new JigsawPathNodeStyleRendererImpl();
  private _node: INode;
  private _nodeStyle: JigsawPathShapeNodeStyle;
  private _path: GeneralPath;
  constructor() {
    super();
  }

  private configure(node: INode, style: INodeStyle): void {
    this._node = node;
    this._nodeStyle = style as JigsawPathShapeNodeStyle;
    this._path = null;
  }

  get shape(): JigsawPathShape {
    return +this._nodeStyle.shape;
  }

  createPath(): GeneralPath | null {
    if (this._path) {
      return this._path;
    }
    switch (this.shape) {
      case JigsawPathShape.Pentagon:
        this._path = this.createPentagonPath(this._node.layout);
        break;
      default:
        throw 'Unknown JigsawPathShape';
    }

    return this._path;
  }

  createPentagonPath(layout): GeneralPath {
    const path = new GeneralPath();
    path.moveTo(layout.x + layout.width / 2, layout.y);
    path.lineTo(layout.x + layout.width, layout.y + layout.height / 3);
    path.lineTo(layout.x + layout.width * 0.8, layout.y + layout.height);
    path.lineTo(layout.x + layout.width * 0.2, layout.y + layout.height);
    path.lineTo(layout.x, layout.y + layout.height / 3);
    path.close();
    return path;
  }

  createVisual(renderContext: IRenderContext): Visual {
    const svgPathElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    this.applyPath(svgPathElement, renderContext);
    this.applyFill(svgPathElement, renderContext);
    this.applyStroke(svgPathElement, renderContext);

    svgPathElement[RenderCacheKey] = this.createCache();
    return new SvgVisual(svgPathElement);
  }

  updateVisual(renderContext: IRenderContext, oldVisual: SvgVisual): Visual {
    if (!(oldVisual instanceof SvgVisual) || !oldVisual[RenderCacheKey]) {
      return this.createVisual(renderContext);
    }
    const cache = this.getCurrentCache(oldVisual);
    const svgElement = oldVisual.svgElement;

    if (
      this.hasShapeChanged(cache, oldVisual) ||
      this.hasLayoutChanged(cache, oldVisual)
    ) {
      // update svgElement
      this.applyPath(svgElement, renderContext);
      // update cache
      cache.shape = this._nodeStyle.shape;
      cache.x = this._node.layout.x;
      cache.y = this._node.layout.y;
      cache.width = this._node.layout.width;
      cache.height = this._node.layout.height;
    }

    if (this.hasFillChanged(cache, oldVisual)) {
      // update svgElement
      this.applyFill(svgElement, renderContext);
      // update cache
      cache.fill = this._nodeStyle.fill ? this._nodeStyle.fill.clone() : null;
    }
    if (this.hasStrokeChanged(cache, oldVisual)) {
      // update svgElement
      this.applyStroke(svgElement, renderContext);
      // update cache
      cache.stroke = this._nodeStyle.stroke
        ? this._nodeStyle.stroke.clone()
        : null;
    }

    return oldVisual;
  }

  getBoundsProvider(node: INode, style: INodeStyle): IBoundsProvider {
    this.configure(node, style);
    return this;
  }

  getContext(node: INode, style: INodeStyle): ILookup {
    this.configure(node, style);
    return this;
  }

  getHitTestable(node: INode, style: INodeStyle): IHitTestable {
    this.configure(node, style);
    return this;
  }

  getMarqueeTestable(node: INode, style: INodeStyle): IMarqueeTestable {
    this.configure(node, style);
    return this;
  }

  getShapeGeometry(node: INode, style: INodeStyle): IShapeGeometry {
    this.configure(node, style);
    return this;
  }

  getVisibilityTestable(node: INode, style: INodeStyle): IVisibilityTestable {
    this.configure(node, style);
    return this;
  }

  getVisualCreator(node: INode, style: INodeStyle): IVisualCreator {
    this.configure(node, style);
    return this;
  }

  getBounds(context: ICanvasContext): Rect {
    return this._node.layout.toRect();
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    return this.getOutline().areaContains(location);
  }

  lookup<T extends any>(type: Class<T>): T | null {
    if (type.name == IBoundsProvider.$class.name) {
      return this as unknown as T;
    }
    console.debug(type.name, type);
    return null;
  }

  isInBox(context: IInputModeContext, rectangle: Rect): boolean {
    return rectangle.contains(this._node.layout);
  }

  isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    return rectangle.intersects(this._node.layout.toRect());
  }

  getIntersection(inner: Point, outer: Point): Point {
    // Number of line segments to divide the line into
    const outline = this.getOutline();
    const lineSegment = new GeneralPath();

    const numSegments = 100;
    const dx = (outer.x - inner.x) / numSegments;
    const dy = (outer.y - inner.y) / numSegments;
    for (let i = 0; i < numSegments; i++) {
      const startPoint = new Point(inner.x + i * dx, inner.y + i * dy);
      const endPoint = new Point(
        inner.x + (i + 1) * dx,
        inner.y + (i + 1) * dy
      );

      lineSegment.moveTo(startPoint);
      lineSegment.lineTo(endPoint);

      if (outline.intersects(lineSegment)) {
        const intersectionPoint = new Point(
          (startPoint.x + endPoint.x) / 2,
          (startPoint.y + endPoint.y) / 2
        );
        return intersectionPoint;
      }
      lineSegment.clear();
    }
    return null;
  }

  getOutline(): GeneralPath {
    return this.createPath();
  }

  isInside(location: Point): boolean {
    return this.getOutline().areaContains(location);
  }

  private applyPath(svgElement: SVGElement, context: IRenderContext): void {
    const svgPathData = this.createPath().createSvgPathData();
    svgElement.setAttribute('d', svgPathData);
  }

  private applyFill(svgElement: SVGElement, context: IRenderContext): void {
    if (!this._nodeStyle.fill) {
      svgElement.setAttribute('fill', 'none');
    } else {
      this._nodeStyle.fill.applyTo(svgElement, context);
    }
  }

  private applyStroke(svgElement: SVGElement, context: IRenderContext): void {
    if (!this._nodeStyle.stroke) {
      svgElement.setAttribute('stroke', 'none');
      svgElement.setAttribute('stroke-width', '0');
    } else {
      this._nodeStyle.stroke.applyTo(svgElement, context);
    }
  }

  private hasLayoutChanged(cache: IRenderCache, visual: Visual): boolean {
    return (
      cache.x != this._node.layout.x ||
      cache.y != this._node.layout.y ||
      cache.width != this._node.layout.width ||
      cache.height != this._node.layout.height
    );
  }

  private hasShapeChanged(cache: IRenderCache, visual: Visual): boolean {
    return cache.shape != this._nodeStyle.shape;
  }

  private hasFillChanged(cache: IRenderCache, visual: Visual): boolean {
    return GraphElementsComparer.fillsEqual(cache.fill, this._nodeStyle.fill);
  }
  private hasStrokeChanged(cache: IRenderCache, visual: Visual): boolean {
    return GraphElementsComparer.strokesEqual(
      cache.stroke,
      this._nodeStyle.stroke
    );
  }

  private getCurrentCache(visual: Visual): IRenderCache {
    return visual[RenderCacheKey];
  }

  private createCache(): IRenderCache {
    return {
      x: this._node.layout.x,
      y: this._node.layout.y,
      width: this._node.layout.width,
      height: this._node.layout.height,
      fill: this._nodeStyle.fill.clone(),
      stroke: this._nodeStyle.stroke.clone(),
      shape: this._nodeStyle.shape
    };
  }
}

interface IRenderCache {
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: Stroke;
  fill: Fill;
  shape: JigsawPathShape;
}

export default JigsawPathNodeStyleRendererImpl;
