import DataPropertyValueMatcher from '../matchers/DataPropertyValueMatcher';
import DataPropertyParameter from '../parameters/DataPropertyParameter';
import ISearchParameter from '../parameters/ISearchParameter';
import ISpecification from '../specifications/ISpecification';
import MatchSpecification from '../specifications/MatchSpecification';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import { FilterType } from './FilterType';

export default class DataPropertyFilter extends Filter {
  static matchers = [new DataPropertyValueMatcher()];
  public type: FilterType;
  public scope = FilterScope.DataProperty;
  public parameter: DataPropertyParameter;
  protected _specification: ISpecification;

  constructor(
    parameter: ISearchParameter,
    type: FilterType = FilterType.Include
  ) {
    super();
    this.type = type;
    this.parameter = parameter as DataPropertyParameter;
    this._specification = new MatchSpecification<DataPropertyParameter>(
      DataPropertyFilter.matchers,
      this.parameter
    );
  }
}
