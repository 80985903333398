﻿import DiagramUtils from '@/core/utils/DiagramUtils';
import { IEdge } from 'yfiles';
import i18n from '@/core/plugins/vue-i18n';
import orderBy from 'lodash/orderBy';
import IGraphService from '@/v2/services/interfaces/IGraphService';

export class ContractRelationshipDisplay {
  constructor(private graphService: IGraphService) {}

  public getDisplayString(edge: IEdge) {
    let edges = [];

    this.graphService.graphComponent.graph.edges
      .filter((x) => x.tag.busid === edge.tag.busid)
      .forEach((x) => {
        edges.push(x);
      });

    if (edges.length === 0) return '';

    edges = orderBy(edges, ['labels.size'], ['desc']);

    const primaryEdge = edges[0];
    const lastItem =
      DiagramUtils.getLabelValue(primaryEdge.targetNode) ?? i18n.t('SOURCE');
    const firstItems = edges
      .map((x) => DiagramUtils.getLabelValue(x.sourceNode) ?? 'Target')
      .join(',');
    edge.tag.name ? edge.tag.name : 'Unnamed';

    const edgeLabel =
      DiagramUtils.getLabelValue(primaryEdge) ?? i18n.t('NO_LABEL');
    const stringFormat = i18n
      .t('BOLD_BETWEEN_X_AND_X', [firstItems, lastItem])
      .toString();
    return stringFormat;
  }
}
