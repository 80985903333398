import { ApplicationPermissions } from './api/ApplicationPermissions';
import { Features } from './core/common/Features';
import { ApplicationRoles, GrantType } from './core/common/ApplicationRoles';
import { RouterParams } from './core/config/routerParams';
import AccessCondition from './AccessCondition';
import { unobfuscateId } from './core/utils/document-obfuscation';

const Home = () => import('@/view/pages/hub/home/Home.vue');
const AllDocumentsPage = () =>
  import('@/view/pages/hub/all-documents/AllDocuments.vue');
const DocumentsAccessRequests = () =>
  import(
    '@/view/pages/hub/documents-access-requests/DocumentsAccessRequests.vue'
  );
const DocumentPreview = () =>
  import('@/view/pages/hub/document-preview/DocumentPreview.vue');
const DocumentManager = () =>
  import('@/view/pages/hub/document-manager/DocumentManager.vue');
const Favourites = () => import('@/view/pages/hub/favourites/Favourites.vue');
const Templates = () => import('@/view/pages/hub/templates/Templates.vue');
const DeletedFiles = () =>
  import('@/view/pages/hub/deleted-files/DeletedFiles.vue');
const IManage = () => import('@/view/pages/imanage/IManage.vue');
const DocumentProfiling = () =>
  import('@/view/pages/documentprofiling/DocumentProfiling.vue');
const DataProperties = () =>
  import('@/view/pages/hub/data-properties/DataProperties.vue');

const RefreshTokenPage = () => import('@/view/pages/RefreshTokenPage.vue');
const ExternalAccessPage = () => import('@/view/pages/ExternalAccessPage.vue');
export const RouteNames = {
  home: 'landing',
  welcome: 'welcome',
  welcomeExpectation: 'welcome-expectation',
  training: 'training',
  trainingCongratulations: 'training-congratulations',
  allDocuments: 'all-documents',
  favourites: 'favourites',
  shared: 'shared',
  templates: 'templates',
  deletedFiles: 'deleted',
  accessRequests: 'access-requests',
  preview: 'preview',
  documentManager: 'document-manager',
  themes: 'hub-themes',
  documentDetails: 'document-details',
  stepsDesign: 'steps-design',
  stepsDesignEditor: 'steps-design-editor',
  hubThemeDetails: 'hub-theme-details',
  documentThemeDetails: 'document-theme-details',
  resetPassword: 'reset-password',
  shouldChangePassword: 'should-change-password',
  recoverPassword: 'recover-password',
  createExternalAccount: 'create-external-account',
  iManageCallback: 'imanage-callback',
  imanage: 'imanage',
  documentProfiling: 'document-profiling',
  diagram: 'diagram',
  documentThemes: 'document-themes',
  dataProperties: 'data-properties',
  dataPropertiesDetails: 'data-properties-details',
  importDefinition: 'import-definition',
  processLoading: 'process-loading',
  externalAccess: 'external-access',
  deloitteReport: 'deloitte-report'
};

/**
 * Route Access
 * There are three access control types you can put on a route, Feature, Role or Permission.
 * 
 * To add access control to a route you can set the 'meta' property on a route to one of the following:
 * Feature
 *  meta: {
          features: {
            value: [Features.Import, Features.ImportExcel],
            condition: AccessCondition.Or
          },
        }

 * Role
 *  meta: {
          features: {
            value: [ApplicationRoles.User, ApplicationRoles.SuperUser],
            condition: AccessCondition.Or
          },
        }

 * Permission
 *  meta: {
          permissions: {
            value: [ApplicationPermissions.ImportDefinitionsRead, ApplicationPermissions.ImportDefinitionsEdit],
            condition: AccessCondition.Or
          },
        }

The 'condition' defines if 1 or all of the values must exist before granting access
The 'value' can be an array of strings or objects like this
  {
    name: ApplicationRoles.External,
    grantType: GrantType.Deny,
    redirect: 'external-access'
  }

  This says, if a user, with the role 'External' tries to access this route, then they should be denied access and redirected to 'external-access'.
  Switching to 'GrantType.Allow' would do the opposite, by default, the GrantType is always allow and is set for 'string' values.
  'redirect' is an optional route name that can be specified to send the user to a specific page if they fail the check


  If a route has Feautre, Role and Permission checks define, the user must pass all 3 to be granted access.

  Order or precedence
  1. Feature
  2. Roles
  3. Permissions

  This means, if you have a feature and a role check with a 'redirect' defined, the features redirect would be used.

 */

const routes = [
  {
    path: '/loading',
    name: RouteNames.processLoading,
    component: () => import('@/view/pages/process-loading/ProcessLoading.vue')
  },
  {
    path: '/sidebyside',
    component: () => import('@/v2/components/DebugSideBySide.vue')
  },
  {
    path: '/training-congratulations',
    component: () =>
      import(
        '@/view/pages/training/tutorial-congratulations-page/TutorialCongratulationsPage.vue'
      ),
    name: RouteNames.trainingCongratulations,
    meta: {
      features: {
        value: [Features.Onboarding],
        allRequired: true
      }
    }
  },
  {
    path: '/welcome',
    component: () => import('@/view/pages/training/WelcomePage.vue'),
    name: RouteNames.welcome,
    meta: {
      features: {
        value: [Features.Onboarding],
        allRequired: true
      }
    }
  },
  {
    path: '/get-started',
    component: () =>
      import('@/view/pages/training/TutorialExpectationPage.vue'),
    name: RouteNames.welcomeExpectation,
    meta: {
      features: {
        value: [Features.Onboarding],
        allRequired: true
      }
    }
  },
  {
    path: '/documents',
    component: () => import('@/view/pages/document/DocumentLayout.vue'),
    redirect: RouteNames.home,
    children: [
      {
        path: ':' + RouterParams.documentId + `/:${RouterParams.pageNumber}?`,
        components: {
          default: () => import('@/view/pages/document/DocumentDetails.vue'),
          side: () =>
            import('@/view/pages/document/sidebar/DocumentSideBar.vue')
        },
        props: {
          default: (route) => {
            let documentId = route.params[RouterParams.documentId];
            if (isNaN(documentId)) {
              documentId = unobfuscateId(documentId);
            }
            return {
              documentId: Number(documentId),
              pageNumber: Number(route.params[RouterParams.pageNumber])
            };
          }
        },
        children: [
          {
            path: '',
            name: RouteNames.documentDetails
          },
          {
            name: RouteNames.documentThemes,
            path: 'themes',
            props: {
              query: {
                page: '1'
              }
            },
            components: {
              sidepanel: () =>
                import('@/view/pages/administration/ThemesList.vue')
            },
            meta: {
              openSidePanel: true,
              commonName: 'themes',
              roles: {
                value: [
                  {
                    name: 'External',
                    grantType: GrantType.Deny,
                    redirect: RouteNames.externalAccess
                  }
                ]
              }
            }
          },
          {
            name: RouteNames.documentThemeDetails,
            path: 'themes/:' + RouterParams.themeId,
            components: {
              sidepanel: () =>
                import('@/view/pages/administration/ThemeDetails.vue')
            },
            props: {
              sidepanel: (route) => {
                return {
                  id: Number(route.params[RouterParams.themeId])
                };
              }
            },
            meta: {
              openSidePanel: true,
              commonName: 'theme-details'
            }
          },
          {
            path: 'data-properties',
            name: RouteNames.dataPropertiesDetails,
            components: {
              sidepanel: DataProperties
            },
            meta: {
              commonName: 'data-properties',
              permissions: {
                value: [
                  ApplicationPermissions.PresetProperties,
                  ApplicationPermissions.People
                ],
                condition: AccessCondition.Or
              },
              features: {
                value: [Features.DataFull],
                condition: AccessCondition.And
              },
              openSidePanel: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/hub',
    component: () => import('@/view/layout/AppLayout.vue'),
    meta: {},
    children: [
      // New Pages
      {
        path: '/',
        name: RouteNames.home,
        component: Home,
        meta: {
          skipPadding: true
        }
      },
      {
        path: '/training',
        component: () =>
          import(
            '@/view/pages/training/select-tutorial-page/SelectTutorialPage.vue'
          ),
        name: RouteNames.training,
        props: {
          isHubTab: true
        },
        meta: {
          features: {
            value: [Features.Onboarding],
            allRequired: true
          }
        }
      },
      {
        path: `/all-documents/:${RouterParams.folderId}?`,
        name: RouteNames.allDocuments,
        component: AllDocumentsPage
      },
      {
        path: `/favourites/:${RouterParams.folderId}?`,
        name: RouteNames.favourites,
        component: Favourites
      },
      // Temporary disabled
      // {
      //   path: '/templates',
      //   name: RouteNames.templates,
      //   component: Templates,
      // },
      {
        path: '/deleted',
        name: RouteNames.deletedFiles,
        component: DeletedFiles
      },
      {
        path: '/imanage',
        name: RouteNames.imanage,
        component: IManage
      },
      {
        path: '/document-profiling',
        name: RouteNames.documentProfiling,
        component: DocumentProfiling
      },
      {
        path: '/access-requests',
        name: RouteNames.accessRequests,
        component: DocumentsAccessRequests
      },
      {
        path: '/preview/:' + RouterParams.documentId,
        name: RouteNames.preview,
        component: DocumentPreview
      },
      {
        path: '/document-manager',
        name: RouteNames.documentManager,
        component: DocumentManager
      },
      // New Pages End
      {
        path: 'themes',
        name: RouteNames.themes,
        props: {
          query: {
            page: '1'
          }
        },
        meta: {
          commonName: 'themes'
        },
        component: () => import('@/view/pages/administration/ThemesList.vue')
      },
      {
        path: 'import',
        name: 'hub-import',
        props: {
          query: {
            page: '1'
          }
        },
        meta: {
          commonName: 'import',
          features: {
            value: [Features.Import, Features.ImportExcel],
            condition: AccessCondition.Or
          },
          permissions: {
            value: [
              ApplicationPermissions.ImportDefinitionsRead,
              ApplicationPermissions.ImportDefinitionsEdit
            ],
            condition: AccessCondition.Or
          }
        },
        component: () => import('@/view/pages/hub/import/ImportsPreview.vue')
      },
      {
        path: 'import-definition/:' + RouterParams.importDefinitionId,
        name: RouteNames.importDefinition,
        meta: {
          features: {
            value: [Features.ImportExcel, Features.ImportExcelBuilder],
            condition: AccessCondition.And
          },
          permissions: {
            value: [
              ApplicationPermissions.ImportDefinitionsRead,
              ApplicationPermissions.ImportDefinitionsEdit
            ],
            condition: AccessCondition.Or
          }
        },
        props: (route) => ({
          id: Number(route.params[RouterParams.importDefinitionId])
        }),
        component: () =>
          import('@/view/pages/hub/import/ImportDefinitionBuilder.vue')
      },
      {
        name: RouteNames.hubThemeDetails,
        path: 'themes/:' + RouterParams.themeId,
        meta: {
          commonName: 'theme-details'
        },
        component: () => import('@/view/pages/administration/ThemeDetails.vue'),
        props: (route) => ({
          id: Number(route.params[RouterParams.themeId])
        })
      },

      // Steps design
      {
        path: '/steps-design',
        name: RouteNames.stepsDesign,
        meta: {
          features: {
            value: [Features.Steps, Features.StepsDesign],
            condition: AccessCondition.And
          },
          permissions: {
            value: [ApplicationPermissions.AdministrationPageDesign],
            condition: AccessCondition.Or
          }
        },
        components: {
          default: () =>
            import(
              '@/view/pages/administration/steps-designer/StepsDesigner.vue'
            )
        }
      },
      // Steps design editor
      {
        path: `/steps-design-editor/:${RouterParams.stepsDesignSetUuid}`,
        name: RouteNames.stepsDesignEditor,
        components: {
          default: () =>
            import(
              '@/view/pages/administration/steps-designer/StepsDesignerEditor.vue'
            )
        },
        props: (route) => ({
          id: Number(route.params[RouterParams.stepsDesignSetUuid])
        })
      },
      // Data Properties
      {
        path: 'data-properties',
        name: RouteNames.dataProperties,
        meta: {
          commonName: 'data-properties',
          permissions: {
            value: [
              ApplicationPermissions.PresetProperties,
              ApplicationPermissions.People
            ],
            condition: AccessCondition.Or
          },
          features: {
            value: [Features.DataFull],
            condition: AccessCondition.And
          }
        },
        component: DataProperties
      }
    ]
  },
  {
    name: RouteNames.createExternalAccount,
    path: '/create-external-account',
    component: () => import('@/view/pages/auth/CreateExternalAccount.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/login',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('@/view/pages/auth/Login.vue'),
        meta: {
          anonymous: true
        }
      }
    ]
  },
  {
    path: '/logout',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: 'logout',
        path: '/logout',
        component: () => import('@/view/pages/auth/Logout.vue'),
        meta: {
          commonName: 'logout',
          anonymous: false
        }
      }
    ]
  },
  {
    path: '/should-change-password',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: RouteNames.shouldChangePassword,
        path: '/should-change-password',
        component: () => import('@/view/pages/auth/ShouldChangePassword.vue'),
        meta: {
          anonymous: true
        }
      }
    ]
  },
  {
    path: '/reset-password',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: RouteNames.resetPassword,
        path: '/reset-password',
        component: () => import('@/view/pages/auth/ResetPassword.vue'),
        meta: {
          anonymous: true
        }
      }
    ]
  },
  {
    path: '/recover-password',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: RouteNames.recoverPassword,
        path: '/recover-password',
        component: () => import('@/view/pages/auth/RecoverPassword.vue'),
        meta: {
          anonymous: true
        }
      }
    ]
  },
  {
    path: '/token-refresh',
    name: 'token-refresh',
    component: RefreshTokenPage,
    meta: {
      anonymous: true
    }
  },
  {
    path: '/external-access',
    name: RouteNames.externalAccess,
    component: ExternalAccessPage,
    meta: {
      roles: {
        value: [ApplicationRoles.External]
      }
    }
  },
  {
    name: RouteNames.diagram,
    path: '/diagram/:documentId',
    component: () => import('@/view/pages/Diagram.vue'),
    props: (route) => {
      return { documentId: Number(route.params.documentId) };
    }
  },
  {
    name: RouteNames.deloitteReport,
    path: '/deloitte-report/:id',
    component: () => import('@/view/pages/deloitte/DeloitteReport.vue'),
    props: (route) => {
      return { id: Number(route.params.id) };
    }
  },
  {
    path: '*',
    redirect: '/hub',
    meta: {
      anonymous: true
    }
  }
];

export default routes;
