import {
  INode,
  IEdge,
  LayoutExecutor,
  Class,
  OrganicLayout,
  AdjacencyTypes,
  IGraph
} from 'yfiles';

import IDisposable from '@/core/common/IDisposable';
import DefaultEdgeRouting from './DefaultEdgeRouting';
import IDiagramTypeHelper from '../IDiagramTypeHelper';
import DiagramUtils from '@/core/utils/DiagramUtils';
Class.ensure(LayoutExecutor);
Class.ensure(OrganicLayout);

export const READJUSTMENT_TAG: string = 'readjust';
export default abstract class EdgeServiceBase implements IDisposable {
  public static readonly $class: string = 'EdgeServiceBase';

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
  }

  abstract get graph(): IGraph;
  abstract get diagramTypeHelper(): IDiagramTypeHelper;

  applyEdgeRouterForNodes(nodes: INode[]): void {
    try {
      // collect up all edges
      let affectedEdges: IEdge[] = [];
      nodes.forEach((node) => {
        affectedEdges = this.graph
          .edgesAt(node, AdjacencyTypes.ALL)
          .concat(affectedEdges)
          .toArray();
      });

      if (affectedEdges.length > 0) {
        this.applyEdgeRouterForEdges(affectedEdges);
      }
    } catch (error) {
      console.debug('applyEdgeRouterAffectedNodes:' + error);
    }
  }

  applyEdgeRouterForEdges(edges: IEdge[]): void {
    if (!edges || edges.length <= 0) return;

    edges = edges.filter(this.shouldRouteEdge.bind(this));
    if (edges.length <= 0) {
      return;
    }

    // first we ask the diagram type helper to route known edges
    let routingResult = this.diagramTypeHelper.routing.routeKnownEdges(
      this.graph,
      edges
    );

    // off load all unknown edges to the DefaultEdgeRouting
    // DefaultEdgeRouting should route all edges it's given
    const defaultEdgeRouting = new DefaultEdgeRouting(this);
    defaultEdgeRouting.routeKnownEdges(this.graph, routingResult.unknownEdges);
  }

  public resetEdgeRoute(edges: IEdge[]): void {
    edges.forEach((e) => {
      // When refreshing routing, ensure to reset all flags which "fix" edges in place
      e.tag.isFixedInLayout = false;
      DiagramUtils.clearFixedEdgePort(e.tag, true);
      DiagramUtils.clearFixedEdgePort(e.tag, false);
      this.applyEdgeRouterForEdges([e]);
    });
  }

  private portsShareSameLocation(edge: IEdge): boolean {
    return edge.sourcePort.location.equals(edge.targetPort.location);
  }

  private shouldRouteEdge(edge: IEdge): boolean {
    /**
     * Rules
     * #1 Don't route orphan edges
     * #2 Don't route edges with the same source and target port locations
     */
    return !edge.tag.isOrphan && !this.portsShareSameLocation(edge);
  }
}
