import CommandHandlerBase from './CommandHandlerBase';
import { Mapper } from 'yfiles';
import CommandManager from '../CommandManager/CommandManager';
import { CommandType } from '../CommandManager/CommandType';
import { CommandHandlerSubtype } from '../CommandManager/CommandHandlerSubtype';
import ExportConfig from '@/core/config/ExportConfig';
import { Editor } from '@ckeditor/ckeditor5-core';

export default abstract class CKEditorCommandHandlerBase extends CommandHandlerBase {
  protected commands: Mapper<CommandType, any>;
  protected editor: Editor;
  public suppressEvent: boolean = false;
  public abstract readonly subtype: CommandHandlerSubtype;

  public override dispose(): void {
    if (this.isDisposed) {
      return;
    }

    if (CommandManager.INSTANCE.isCommandHandlerActive(this)) {
      CommandManager.INSTANCE.unsetCommandHandler();
    }
    super.dispose();
  }

  public init(editor: Editor, parent?: CommandHandlerBase): void {
    this.editor = editor;
    if (parent) {
      this.parent = parent;
    }
    this.setupCommands();
    this.publishAllValues();
  }

  abstract setupCommands(): void;

  public release(): CommandHandlerBase {
    if (this.isDisposed) {
      return null;
    }

    return this.parent;
  }

  public isChildOf(parent: CommandHandlerBase): boolean {
    return this.parent && this.parent === parent;
  }

  public setParent(parent: CommandHandlerBase): void {
    this.parent = parent;
  }

  /**
   * Takes each command type, gets the correct value, the publishes this out for any listeners
   */
  public publishAllValues() {
    this.commands.entries.forEach((c) => {
      if (c.value) {
        let value = c.value.value;
        if (value === undefined || value === null) {
          value = this.getDefaultValue(c.value.attributeKey);
        }
        if (value !== undefined && value !== null) {
          this.publishValue(c.key, this.formatManagerValue(c.key, value));
        }
      }
    });
  }

  public abstract publishValue(commandType: CommandType, value: any): void;

  /**
   * If we can't get a value from CKEditor (usually when the content is empty), reset it to default
   */
  public getDefaultValue(key: string): any {
    switch (key) {
      case 'fontSize':
        return ExportConfig.defaultContentFontStyle.fontSize + 'pt';
      case 'fontFamily':
        return ExportConfig.defaultContentFontStyle.fontFamily;
      default:
        return null;
    }
  }

  protected formatManagerValue(command: CommandType, value: any) {
    if (!value) {
      return value;
    }
    if (command == CommandType.fontSize) {
      return Number.parseInt(value.toString().replace('pt', ''));
    }
    if (command == CommandType.fontFamily) {
      return value.replaceAll("'", '').replaceAll('"', '');
    }
    return value;
  }
}
