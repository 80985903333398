import CustomFontsApiService from '@/api/CustomFontsApiService';
import ExportConfig from '../config/ExportConfig';
import appConfig from '../config/appConfig';
import supportedFontFamilies from '../config/supportedFontFamilies';
import { customFonts, fontTypes } from './export/CustomFonts';

export default class CustomFontsService {
  private static fontCssDefinitions: string;
  private static hasLoadedCustomFonts = false;

  public static init(): void {
    if (!appConfig.customFonts.japaneseFontsEnabled) {
      delete customFonts[ExportConfig.japaneseFallbackFontName];
      return;
    }
    supportedFontFamilies.push(ExportConfig.japaneseFallbackFontName);
  }

  public static async addCustomDocumentFonts(): Promise<void> {
    if (CustomFontsService.hasLoadedCustomFonts) return;
    const fontFaces: FontFace[] = [];
    const fontsWithStyles = (await CustomFontsApiService.getAllWithStyles())
      .data.result;

    // Add customer uploaded fonts to supported custom fonts
    for (const fontFamily of fontsWithStyles) {
      if (customFonts[fontFamily.name]) {
        continue;
      }
      customFonts[fontFamily.name] = {
        regular: fontFamily.regular
          ? `${appConfig.apiBaseUrl}${fontFamily.regular.fileAttachment.path}`
          : null,
        bold: fontFamily.bold
          ? `${appConfig.apiBaseUrl}${fontFamily.bold.fileAttachment.path}`
          : null,
        italic: fontFamily.italic
          ? `${appConfig.apiBaseUrl}${fontFamily.italic.fileAttachment.path}`
          : null,
        bolditalic: fontFamily.boldItalic
          ? `${appConfig.apiBaseUrl}${fontFamily.boldItalic.fileAttachment.path}`
          : null
      };
      supportedFontFamilies.push(fontFamily.name);
    }

    // Load all custom fonts
    for (const fontFamily in customFonts) {
      const customFont = customFonts[fontFamily];
      if (
        // Font is already in document: ignore
        Array.from(document.fonts.values()).some((f) => f.family === fontFamily)
      ) {
        continue;
      }

      for (const type of fontTypes) {
        if (!customFont[type]) {
          continue;
        }

        const fontFace = new FontFace(fontFamily, `url(${customFont[type]})`);
        if (type === 'bold' || type === 'bolditalic') {
          fontFace.weight = 'bold';
        }
        if (type === 'italic' || type === 'bolditalic') {
          fontFace.style = 'italic';
        }
        fontFaces.push(fontFace);
        document.fonts.add(fontFace);
      }
    }

    await Promise.allSettled(
      fontFaces.map((fontFace) => {
        return fontFace.load();
      })
    );

    fontFaces
      .filter((fontFace) => fontFace.status !== 'loaded')
      .forEach((fontFace) => {
        console.error('Failed to load font', fontFace);
      });

    CustomFontsService.hasLoadedCustomFonts = true;
  }

  public static constructFontCssDefinitions(): string {
    if (CustomFontsService.fontCssDefinitions) {
      return CustomFontsService.fontCssDefinitions;
    }

    let cssString = '';
    for (const fontFamily in customFonts) {
      const customFont = customFonts[fontFamily];
      for (const type of fontTypes) {
        if (!customFont[type]) {
          continue;
        }

        cssString += `@font-face {
              font-family: '${fontFamily}';
              src: url('${customFont[type]}');
              ${
                type === 'bold' || type === 'bolditalic'
                  ? 'font-weight: bold;'
                  : ''
              }
              ${
                type === 'italic' || type === 'bolditalic'
                  ? 'font-style: italic;'
                  : ''
              }
            }\n`;
      }
    }

    CustomFontsService.fontCssDefinitions = cssString;
    return cssString;
  }

  public static getAllUsedFontFamilies(html: string): string[] {
    const usedFontFamilies = new Set<string>();
    const fontFamilyRegex =
      /font-family:\s*(&quot;|'|")?(?<font>[\w ]+)(&quot;|'|")?\s*;/g;
    for (const match of html.matchAll(fontFamilyRegex)) {
      const fontFamily = match.groups.font.trim();
      usedFontFamilies.add(fontFamily);
    }
    return [...usedFontFamilies];
  }
}
