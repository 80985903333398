import { Module } from 'vuex';

import PeopleApiService from '@/api/PeopleApiService';

import { CreateOrEditPersonDto, PersonDto } from '@/api/models';

export const PEOPLE_NAMESPACE = 'peopleNamespace';
export const GET_PEOPLE_LOADING = 'getPeopleLoading';
export const GET_PEOPLE = 'getPeople';
export const SET_PEOPLE_LOADING = 'setPeopleLoading';
export const SET_PEOPLE = 'setPeople';
export const LOAD_PEOPLE = 'loadPeople';
export const CREATE_OR_EDIT_PEOPLE = 'createOrEditPeople';
export const DELETE_PERSON = 'deletePerson';

interface State {
  peopleLoading: boolean;
  people: PersonDto[];
}

const PeopleModule: Module<State, any> = {
  namespaced: true,

  state: {
    peopleLoading: false,
    people: []
  },

  getters: {
    [GET_PEOPLE_LOADING](state) {
      return state.peopleLoading;
    },

    [GET_PEOPLE](state) {
      return state.people.map((person) => {
        const { firstName, lastName } = person;
        const profileInitials = `${firstName?.charAt(0) || ''}${
          lastName?.charAt(0) || ''
        }`;

        const label =
          firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '';

        return {
          ...person,
          label,
          profilePictureInitials: profileInitials
        };
      });
    }
  },

  mutations: {
    [SET_PEOPLE_LOADING](state, payload) {
      state.peopleLoading = payload;
    },

    [SET_PEOPLE](state, payload: PersonDto[]) {
      state.people = payload;
    }
  },

  actions: {
    async [LOAD_PEOPLE](context) {
      const response = await PeopleApiService.getAll();
      const people = response.data.result;
      context.commit(SET_PEOPLE, people);
      return people;
    },

    async [CREATE_OR_EDIT_PEOPLE](_, payload: CreateOrEditPersonDto) {
      const response = await PeopleApiService.createOrEdit(payload);
      return response.data.result;
    },

    async [DELETE_PERSON](_, id: number) {
      await PeopleApiService.delete({ id });
      return id;
    }
  }
};
export default PeopleModule;
