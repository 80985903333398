import {
  AttachmentType,
  DocumentPaletteCategory,
  ElementType,
  ThemeAttachmentDto,
  ThemeDto,
  ThemeElementDto,
  ThemeTextBoxDto
} from '@/api/models';

import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  REFRESH_CURRENT_THEME
} from '@/core/services/store/document.module';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import ClipArtPaletteBehaviour from '../ClipArtPaletteBehaviour';
import appConfig from '@/core/config/appConfig';
import { IElementDataProvider } from './IElementDataProvider';
import { convertUrlToDataUrl, getImageSize } from '@/core/utils/common.utils';
import PaletteSubCategory from '../PaletteSubCategory';
import ThemeElementEdgeBehaviour from '../ThemeElementEdgeBehaviour';
import ThemeElementNodeBehaviour from '../ThemeElementNodeBehaviour';
import DiagramUtils from '@/core/utils/DiagramUtils';
import TextBoxPaletteBehaviour from '@/components/DiagramPalette/TextBoxPaletteBehaviour';

/**
 * Defines a list of element types that can be rendered into SVG's.
 */
const renderableElementTypes: ElementType[] = [
  ElementType.Node,
  ElementType.Edge
];
export default class ThemedElementsDataProvider
  implements IElementDataProvider
{
  private items: IDocumentPaletteItem[] = null;

  constructor() {
    //subscribe to any changes of the current theme
  }
  async getElements(): Promise<IDocumentPaletteItem[]> {
    // before we return elements, must ensure the document.currentTheme has the correct elements attached.
    await Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${REFRESH_CURRENT_THEME}`
    );
    await this.setItems(Vue.$globalStore.state.document.currentTheme);
    await this.createRenders();

    return this.items;
  }

  /**
   * Sets the local @property items property based of the current @param theme
   * @param theme theme to generate palette items from
   * @returns
   */
  private async setItems(theme: ThemeDto): Promise<void> {
    if (!theme?.elements) {
      return;
    }

    // convert all theme elements to PaletteItems
    const elements = theme.elements.map<IDocumentPaletteItem>(
      this.createPaletteItemFromThemeElement
    );

    // convert all theme logos to PaletteItems
    let attachments: IDocumentPaletteItem[] = [];
    const themeLogos = theme.attachments?.filter(
      (a) => a.attachmentType == AttachmentType.Logo
    );
    if (themeLogos) {
      attachments = themeLogos.map<IDocumentPaletteItem>(
        ThemedElementsDataProvider.createPaletteItemFromAttachment
      );
      for (let att of attachments) {
        if (!att.img.startsWith('data:')) {
          att.img = await convertUrlToDataUrl(att.img);
        }
        const dim = await getImageSize(att.img);
        att.data.Width = dim.width;
        att.data.Height = dim.height;
      }
    }

    const textBoxes = theme.textBoxes?.map<IDocumentPaletteItem>(
      ThemedElementsDataProvider.createPaletteItemFromTextBox
    );

    // concat theme elements and attachements and store a copy for rendering.
    this.items = [...elements, ...attachments, ...textBoxes];
  }

  /**
   * Takes a @param themeElement and converts it into a @type PaletteItem
   * @param themeElement The theme element to convert
   * @returns @type IDocumentPaletteItem
   */
  private createPaletteItemFromThemeElement(
    themeElement: ThemeElementDto
  ): IDocumentPaletteItem {
    if (themeElement.elementType == ElementType.Node) {
      return {
        category: DocumentPaletteCategory.Elements,
        img: null,
        data: {
          element: themeElement
        },
        name: themeElement.name,
        text: DiagramUtils.getThemeElementDisplayName(themeElement),
        id: themeElement.id,
        behaviour: ThemeElementNodeBehaviour.INSTANCE,
        canDrag: true,
        subcategory: PaletteSubCategory.ThemedElements
      };
    } else if (themeElement.elementType == ElementType.Edge) {
      return {
        category: DocumentPaletteCategory.Lines,
        img: null,
        data: {
          element: themeElement
        },
        name: themeElement.name,
        text: DiagramUtils.getThemeElementDisplayName(themeElement),
        id: themeElement.id,
        behaviour: ThemeElementEdgeBehaviour.INSTANCE,
        canDrag: true,
        subcategory: PaletteSubCategory.ThemedElements
      };
    }
  }
  /**
   * Takes a @param attachment and converts it into a @type PaletteItem
   * @param attachment The attachment to convert
   * @returns @type IDocumentPaletteItem
   */
  private static createPaletteItemFromAttachment(
    attachment: ThemeAttachmentDto
  ): IDocumentPaletteItem {
    return {
      category: DocumentPaletteCategory.Logos,
      img: `${appConfig.apiBaseUrl + attachment.fileAttachment.path}`,
      data: attachment,
      text: attachment.fileAttachment.filename,
      id: attachment.id,
      behaviour: ClipArtPaletteBehaviour.INSTANCE,
      canDrag: true
    };
  }
  /**
   * Takes a @param textBox and converts it into a @type PaletteItem
   * @param textBox The textBox to convert
   * @returns @type IDocumentPaletteItem
   */
  private static createPaletteItemFromTextBox(
    textBox: ThemeTextBoxDto
  ): IDocumentPaletteItem {
    return {
      category: DocumentPaletteCategory.Text,
      subcategory: 'Text',
      img: null,
      data: textBox,
      text: textBox.name,
      name: textBox.name,
      id: textBox.id,
      behaviour: TextBoxPaletteBehaviour.INSTANCE,
      canDrag: true,
      previewFontSize:
        ThemedElementsDataProvider.getTextBoxPreviewFontSizeByName(
          textBox.name
        ),
      style: textBox.fontStyle
    };
  }

  private static getTextBoxPreviewFontSizeByName(name: string): string {
    if (name === 'HEADING') {
      return '20px';
    }
    if (name === 'SUBHEADING') {
      return '16px';
    }
    if (name === 'NORMAL') {
      return '14px';
    }
  }

  /**
   * Creates render of all @property items
   * @returns
   */
  private async createRenders() {
    if (!this.items || this.items.length <= 0) {
      return;
    }
    // filter items and create their render
    for (const item of this.items.filter(this.shouldCreateRender)) {
      await this.createRender(item);
    }
  }

  private async createRender(paletteItem: IDocumentPaletteItem) {
    const themeElement: ThemeElementDto = paletteItem.data.element; // default theme element

    // invoke render creation
    let src =
      await ElementSvgRenderUtils.createSvgFromThemeElement(themeElement);
    paletteItem.img = src;
  }

  /**
   * Determines whether a render should be generated for the given palette item
   * @param paletteItem
   * @returns true if create render
   */
  private shouldCreateRender(paletteItem: IDocumentPaletteItem): boolean {
    return (
      paletteItem.category == DocumentPaletteCategory.Elements ||
      (paletteItem.category == DocumentPaletteCategory.Lines &&
        renderableElementTypes.indexOf(paletteItem.data.element.elementType) >=
          0)
    );
  }
}
