import { GraphComponent, GraphMLSupport } from 'yfiles';
import { PowerPointExportDto } from '@/api/models';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import IExportProvider from './IExportProvider';
import ExportOptions from '@/core/services/export/ExportOptions';
import b64toBlob from '@/core/services/graph/b64ToBlob';
import IExportResult from './IExportResult';
import { OpenXmlExportRequestBuilder } from './open-xml-export/OpenXmlExportRequestBuilder';
import { OpenXmlExportType } from './open-xml-export/OpenXmlExportType';

export default class PowerPointExportProvider implements IExportProvider {
  private _fileExtension = 'pptx';
  private _mimeType = 'application/vnd.ms-powerpoint';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const request = await this.getExportRequest(options);
    const result = await this.getExport(request);

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: b64toBlob(result, this._mimeType)
    };
  }

  private async getExportRequest(
    options: ExportOptions
  ): Promise<PowerPointExportDto> {
    const builder = new OpenXmlExportRequestBuilder(
      OpenXmlExportType.PowerPoint,
      options
    );
    return await builder.getExportRequest<PowerPointExportDto>();
  }

  private async getExport(request: PowerPointExportDto): Promise<string> {
    let exportString: string = null;
    this._fileExtension = 'pptx';

    try {
      const response =
        await DiagramExportApiService.postPowerPointExport(request);
      if (response.data.result.base64String.length > 0) {
        exportString = response.data.result.base64String;
      }
    } catch (e) {
      console.error(
        e,
        'Error calling DiagramExportApiService.postPowerPointExport'
      );
    }
    return exportString;
  }
}
