import { Editor } from '@ckeditor/ckeditor5-core';
import Vue from 'vue';
import { GET_CURRENT_USER } from '../../store/user.module';
import {
  COLLABORATION_NAMESPACE,
  LOAD_USERS
} from '../../store/collaboration.module';
import { CurrentUserProfileEditDto, UserDto } from '@/api/models';
import type CommentsRepository from '@ckeditor/ckeditor5-comments/src/comments/commentsrepository';
import type {
  CommentData,
  CommentThreadData,
  BaseComment,
  BaseCommentData,
  BaseCommentThread,
  AddCommentThreadEventData,
  UpdateCommentEventData,
  UpdateCommentThreadEventData
} from '@ckeditor/ckeditor5-comments/src/comments/commentsrepository';
import type TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges';
import type {
  SuggestionData,
  AddSuggestionInput,
  UpdateSuggestionInput
} from '@ckeditor/ckeditor5-track-changes/src/trackchanges';

export default class Integration {
  public static get pluginName(): string {
    return 'CollaborationIntegration';
  }

  private get currentUser(): CurrentUserProfileEditDto {
    return Vue.$globalStore.getters[GET_CURRENT_USER];
  }

  private editor: Editor;
  private commentsRepository: CommentsRepository;
  private trackChanges: TrackChanges;

  private suggestions: Record<string, SuggestionData> = {};
  private comments: Record<string, CommentData> = {};
  private commentThreads: Record<string, CommentThreadData> = {};

  constructor(editor: Editor) {
    this.editor = editor;
  }

  public async init(): Promise<void> {
    this.commentsRepository = this.editor.plugins.get('CommentsRepository');
    this.trackChanges = this.editor.plugins.get(
      'TrackChanges'
    ) as unknown as TrackChanges;

    const usersPlugin = this.editor.plugins.get('Users') as any;
    const users: UserDto[] = await Vue.$globalStore.dispatch(
      `${COLLABORATION_NAMESPACE}/${LOAD_USERS}`
    );

    for (const user of users) {
      usersPlugin.addUser({
        id: user.id.toString(),
        name: `${user.name} ${user.surname ?? ''}`
      });
    }

    const currentUserId = this.currentUser.userId;
    usersPlugin.defineMe(currentUserId.toString());

    // Uncomment to use custom adapters
    // this.trackChanges.adapter = {
    //   addSuggestion: this.addSuggestion.bind(this),
    //   updateSuggestion: this.updateSuggestion.bind(this),
    //   getSuggestion: this.getSuggestion.bind(this),
    // };
    // this.commentsRepository.adapter = {
    //   addComment: this.addComment.bind(this),
    //   updateComment: this.updateComment.bind(this),
    //   removeComment: this.removeComment.bind(this),
    //   addCommentThread: this.addCommentThread.bind(this),
    //   getCommentThread: this.getCommentThread.bind(this),
    //   updateCommentThread: this.updateCommentThread.bind(this),
    //   removeCommentThread: this.removeCommentThread.bind(this),
    //   resolveCommentThread: this.resolveCommentThread.bind(this),
    //   reopenCommentThread: this.reopenCommentThread.bind(this),
    // };
  }

  public async addSuggestion(
    input: AddSuggestionInput
  ): Promise<SuggestionData> {
    const id = input.id;
    const suggestionData: SuggestionData = {
      ...input,
      authorId: input['authorId'] ?? this.currentUser.userId.toString(),
      createdAt: new Date(input['createdAt'] ?? new Date())
    };
    this.suggestions[id] = suggestionData;
    return suggestionData;
  }

  public async updateSuggestion(
    id: string,
    input: UpdateSuggestionInput
  ): Promise<void> {
    const suggestionData = await this.getSuggestion(id);
    if (!suggestionData) {
      return;
    }
    this.suggestions[id] = { ...suggestionData, ...input };
  }

  public async getSuggestion(id: string): Promise<SuggestionData> {
    const suggestionData = this.suggestions[id];
    return suggestionData;
  }

  public async addComment(
    input: Omit<BaseComment, 'isFromAdapter'> & BaseCommentData
  ): Promise<{
    commentId: string;
    createdAt: Date;
  }> {
    const id = input.commentId;
    const commentData: CommentData = {
      ...input,
      authorId: input['authorId'] ?? this.currentUser.userId.toString(),
      createdAt: new Date(input['createdAt'] ?? new Date())
    };
    this.comments[id] = commentData;
    return {
      commentId: id,
      createdAt: commentData.createdAt
    };
  }

  public async getComment(id: string): Promise<CommentData> {
    const commentData = this.comments[id];
    return commentData;
  }

  public async updateComment(input: UpdateCommentEventData): Promise<void> {
    const id = input.commentId;
    const commentData = await this.getComment(id);
    if (!commentData) {
      return;
    }
    this.comments[id] = { ...commentData, ...input };
  }

  public async removeComment(input: BaseComment): Promise<void> {
    const id = input.commentId;
    delete this.comments[id];
  }

  public async addCommentThread(input: AddCommentThreadEventData): Promise<{
    threadId: string;
    comments: CommentData[];
  }> {
    const id = input.threadId;
    const commentThreadData: CommentThreadData = {
      ...input
    };
    this.commentThreads[id] = commentThreadData;
    return {
      threadId: commentThreadData.threadId,
      comments: input.comments
    };
  }

  public async getCommentThread(input: {
    threadId: string;
  }): Promise<CommentThreadData> {
    const id = input.threadId;
    const commentThreadData = this.commentThreads[id];
    return commentThreadData;
  }

  public async updateCommentThread(
    input: UpdateCommentThreadEventData
  ): Promise<void> {
    const id = input.threadId;
    const commentThreadData = await this.getCommentThread({ threadId: id });
    if (!commentThreadData) {
      return;
    }
    this.commentThreads[id] = { ...commentThreadData, ...input };
  }

  public async resolveCommentThread(input: BaseCommentThread): Promise<{
    threadId: string;
    resolvedAt: Date;
    resolvedBy: string;
  }> {
    const id = input.threadId;
    const commentThreadData = await this.getCommentThread({ threadId: id });
    if (!commentThreadData) {
      return;
    }
    const updatedCommentThreadData = {
      ...commentThreadData,
      resolvedAt: new Date(),
      resolvedBy: this.currentUser.userId.toString()
    };
    this.commentThreads[id] = updatedCommentThreadData;
    return {
      threadId: updatedCommentThreadData.threadId,
      resolvedAt: updatedCommentThreadData.resolvedAt,
      resolvedBy: updatedCommentThreadData.resolvedBy
    };
  }

  public async reopenCommentThread(input: BaseCommentThread): Promise<void> {
    const id = input.threadId;
    const commentThreadData = await this.getCommentThread({ threadId: id });
    if (!commentThreadData) {
      return;
    }
    const updatedCommentThreadData = {
      ...commentThreadData,
      resolvedAt: null,
      resolvedBy: null
    };
    this.commentThreads[id] = updatedCommentThreadData;
  }

  public async removeCommentThread(input: BaseCommentThread): Promise<void> {
    const id = input.threadId;
    delete this.commentThreads[id];
  }

  public setSuggestionData(data: string): void {
    // Remove all existing suggestions
    try {
      this.editor.execute('discardAllSuggestions');
    } catch (e) {
      console.debug('Error removing existing suggestions', e);
    }
    if (!data) {
      return;
    }
    const suggestionDataList = JSON.parse(data);
    for (const item of suggestionDataList) {
      // May be needed when using a custom adapter
      // this.addSuggestion(item);
      // Suggestions without 'createdAt' will crash the CKEditor, ignore those
      if (!item.createdAt) {
        continue;
      }
      this.trackChanges.addSuggestion(item);
    }
  }

  public setCommentData(data: string): void {
    // Remove all existing comments
    for (const thread of [...this.commentsRepository.getCommentThreads()]) {
      try {
        thread.remove();
      } catch (e) {
        console.debug('Error removing comment thread', e);
      }
    }

    if (!data) {
      return;
    }
    const commentDataList = JSON.parse(data);
    for (const item of commentDataList) {
      // May be needed when using a custom adapter
      // this.addCommentThread(item);
      this.commentsRepository.addCommentThread(item);
    }
  }
}
