// Note: this file is only used by the LegacyPdfExportProvider (deprecated)
// This needs to be kept in sync with styles for the document page content (see _page-content.scss) and thumbnail generation (ThumbnailStyles.ts)
// IMPORTANT: All sizes are in points (pt), 1px = 0.75pt

import ExportConfig from '@/core/config/ExportConfig';

export const defaultPageStyle = {
  b: { bold: true },
  strong: { bold: true },
  u: { decoration: 'underline' },
  s: { decoration: 'lineThrough' },
  em: { italics: true },
  i: { italics: true },
  h1: { fontSize: 60, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h2: { fontSize: 36, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h3: { fontSize: 26, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h4: { fontSize: 24, bold: false, marginBottom: 5, lineHeight: 1.04 },
  h5: { fontSize: 16, bold: false, marginBottom: 5 },
  h6: { fontSize: 14, bold: false, marginBottom: 5 },
  a: { color: 'blue', decoration: 'underline' },
  strike: { decoration: 'lineThrough' },
  p: { margin: '', marginTop: 0, marginBottom: 14 },
  ul: { marginBottom: 5 },
  ol: { marginBottom: 5 },
  li: { marginLeft: 5 },
  table: { marginBottom: 5 },
  th: { bold: true, fillColor: '#EEEEEE' }
};

export const defaultFontStyle = {
  font: ExportConfig.defaultContentFontStyle.fontFamily,
  fontSize: ExportConfig.defaultContentFontStyle.fontSize,
  lineHeight: 1.005
};

export const headerFooterStyle = Object.assign(
  Object.assign({}, defaultPageStyle),
  {
    p: { margin: '', marginTop: 0, marginBottom: 0 },
    table: { marginBottom: 0 }
  }
);
export const textElementStyle = Object.assign(
  Object.assign({}, defaultPageStyle),
  {
    p: {
      margin: '',
      marginTop: 0,
      marginBottom: 0,
      preserveLeadingSpaces: true,
      // it does a trick, when it set to true it changes logic of pdfmake, and as a result we have right break lines.
      leadingCut: true
    },
    table: { marginBottom: 0 }
  }
);
