import { ExportFormat } from '../ExportFormat';

export default class CachedThumbnail {
  public get pageHash(): string {
    return this._pageHash;
  }

  private _pageHash: string;
  private _element: SVGElement;
  private _thumbnails = {} as Record<ExportFormat, string>;

  public set(
    format: ExportFormat,
    element: SVGElement,
    thumbnail: string | SVGElement,
    pageHash: string
  ): void {
    if (format !== ExportFormat.SvgElement) {
      this._thumbnails[format] = thumbnail as string;
    }
    this._element = element;
    this._pageHash = pageHash;
  }

  public get(format: ExportFormat): string | SVGElement {
    if (format === ExportFormat.SvgElement) {
      return this._element;
    }
    return this._thumbnails[format];
  }

  public contains(format: ExportFormat): boolean {
    if (format === ExportFormat.SvgElement) {
      return !!this._element;
    }
    return format in this._thumbnails;
  }
}
