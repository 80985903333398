import axios, {
    AxiosResponse
} from 'axios';
import {
    PowerPointFileDto,
    ZadarResponse,
    PowerPointExportDto,
    DataExportDocumentDto
} from './models'
export default class DiagramExportApiService {
    public static postPowerPointExport(body: PowerPointExportDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PowerPointFileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DiagramExport/PostPowerPointExport`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static documentDataExport(body: DataExportDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/DiagramExport/DocumentDataExport`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}