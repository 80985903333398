import { PluginObject } from 'vue';

export const loadersList = [];
export class Loading {
  private endTimers: { [loader: string]: ReturnType<typeof setTimeout> } = {};

  /**
   * Shows the loading overlay for the specified key
   * @param loader key
   * @param timeToRender (optional) Additional time to let AppLoadingOverlay render itself
   * @returns Promise which can be awaited to ensure that the AppLoadingOverlay is rendered
   */
  public start = (loader: string, timeToRender = 30): Promise<void> => {
    clearTimeout(this.endTimers[loader]);

    if (!loadersList.includes(loader)) {
      loadersList.push(loader);
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeToRender);
    });
  };

  /**
   * Hides the loading overlay for the specified key
   * @param loader key
   * @param delay (optional) Additional delay before hiding the loader
   */
  public end = (loader: string, delay: number = 0) => {
    const removeLoader = () => {
      const existingIndex = loadersList.findIndex((i) => i === loader);
      if (existingIndex >= 0) loadersList.splice(existingIndex, 1);
    };
    if (delay > 0) {
      clearTimeout(this.endTimers[loader]);
      this.endTimers[loader] = setTimeout(removeLoader, delay);
    } else {
      removeLoader();
    }
  };
}

export const LoadingPlugin: PluginObject<any> = {
  /**
   *
   * @param Vue
   */
  install(Vue) {
    console.debug('Installing loading...');
    Vue.prototype.$loading = new Loading();
  }
};
