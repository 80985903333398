import canvasComponentStyles from '@/assets/sass/yfiles/canvas-component-wc.scss';
const template = document.createElement('template');
template.innerHTML = `
  <style>
    .graphComponentContainer {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  ${canvasComponentStyles}
  </style>
  <div class="graphComponentContainer">
    <slot></slot>
  </div>
`;

export default class CanvasComponentWc extends HTMLElement {
  private shadow: ShadowRoot = null;
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.shadow.appendChild(template.content.cloneNode(true));
  }

  public getContainer(): HTMLDivElement {
    return this.shadow.querySelector(
      '.graphComponentContainer'
    ) as HTMLDivElement;
  }
}
