export enum ApplicationRoles {
  Admin = 'Admin',
  SuperUser = 'SuperUser',
  User = 'User',
  Reader = 'Reader',
  External = 'External'
}
export enum GrantType {
  Allow = 'Allow',
  Deny = 'Deny'
}
