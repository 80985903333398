import {
  BusRouter,
  BusRouterBusDescriptor,
  BusRouterData,
  EdgeRouterBusDescriptor,
  EdgeRouterData,
  EdgeRouterEdgeLayoutDescriptor,
  GridInfo,
  IEdge,
  IGraph,
  INode,
  LayoutData,
  LayoutStageBase,
  List,
  Mapper,
  Point,
  PortAdjustmentPolicy,
  PortCandidate,
  PortConstraint,
  PortDirections,
  PortSide,
  Reachability
} from 'yfiles';
import { IEdgeRouting, RoutingResult } from '../IDiagramTypeHelper';
import toArray from 'lodash/toArray';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import diagramConfig from '@/core/config/diagram.definition.config';
import {
  getOwnershipEdgeDescriptor,
  getCashflowEdgeDescriptor
} from './CorporateEdgeDescriptors';

import {
  getCashflowEdgeRouter,
  getContractEdgeRouter,
  getOwnershipEdgeRouter
} from './CorporateEdgeRouters';
import IDisposable from '@/core/common/IDisposable';
import DiagramUtils from '@/core/utils/DiagramUtils';
import EdgeRoutingHelper from '../EdgeRoutingHelper';
import { READJUSTMENT_TAG } from '../graph/EdgeServiceBase';
import CorporateDiagramHelperBase from './CorporateDiagramHelperBase';
import HiddenObjectLayoutStage from '../graph/HiddenObjectLayoutStage';
import { EdgeVisualType, RelationshipType } from '@/api/models';
import { PreserveFixedInLayoutEdges } from '../graph/PreserveFixedInLayoutEdges';
import { calculateHash } from '@/core/utils/common.utils';

// Mappings should use  the edge visual type & the relationship type; these can be configured independently
export type RelationshipVisualType<RelationshipType, EdgeVisualType> = {
  relationshipType: RelationshipType;
  visualType: EdgeVisualType;
};

// Default relationship types and their respective visual types. These edges have special routers
export const DefaultRelationshipVisualTypes: RelationshipVisualType<
  RelationshipType,
  EdgeVisualType
>[] = [
  {
    relationshipType: RelationshipType.Cashflow,
    visualType: EdgeVisualType.Curved
  },
  {
    relationshipType: RelationshipType.Contract,
    visualType: EdgeVisualType.Elbow
  },
  {
    relationshipType: RelationshipType.Ownership,
    visualType: EdgeVisualType.Elbow
  }
];

export default class CorporateDiagramEdgeRouting
  implements IEdgeRouting, IDisposable
{
  // The key is the hashed value of an object, since objects will be compared by their reference in a hashmap
  private edgeRouterMappings: Mapper<String, LayoutStageBase> = null;
  private edgeLayoutDescriptorMappings: Mapper<
    String,
    EdgeRouterEdgeLayoutDescriptor
  > = null;

  constructor(private corporateDiagramHelper: CorporateDiagramHelperBase) {
    const gridInfo = new GridInfo(diagramConfig.grid.size);

    //initialize mappers
    this.edgeRouterMappings = new Mapper<String, LayoutStageBase>();
    this.edgeLayoutDescriptorMappings = new Mapper<
      String,
      EdgeRouterEdgeLayoutDescriptor
    >();
    // setup all edge router mappings between edge types & their routers
    this.edgeRouterMappings.set(
      calculateHash({
        relationshipType: RelationshipType.Ownership,
        visualType: EdgeVisualType.Elbow
      }),
      getOwnershipEdgeRouter(gridInfo)
    );
    this.edgeRouterMappings.set(
      calculateHash({
        relationshipType: RelationshipType.Cashflow,
        visualType: EdgeVisualType.Curved
      }),
      getCashflowEdgeRouter(gridInfo)
    );
    this.edgeRouterMappings.set(
      calculateHash({
        relationshipType: RelationshipType.Contract,
        visualType: EdgeVisualType.Elbow
      }),
      getContractEdgeRouter(gridInfo)
    );

    // setup all edge descriptor mappings between edge types & their descriptor
    this.edgeLayoutDescriptorMappings.set(
      calculateHash({
        relationshipType: RelationshipType.Ownership,
        visualType: EdgeVisualType.Elbow
      }),
      getOwnershipEdgeDescriptor()
    );
    this.edgeLayoutDescriptorMappings.set(
      calculateHash({
        relationshipType: RelationshipType.Cashflow,
        visualType: EdgeVisualType.Curved
      }),
      getCashflowEdgeDescriptor()
    );

    // Contract Edges need a bus id ...
    // self.edgeLayoutDescriptorMappings.set(
    //   RelationshipTypes.Contract,
    //   getContractEdgeDescriptor()
    // );
  }
  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
  }

  getMappedEdgeRouter(
    type: RelationshipVisualType<RelationshipType, EdgeVisualType>
  ): LayoutStageBase {
    const relationshipVisualTypeHash = calculateHash(type);
    return this.edgeRouterMappings.get(relationshipVisualTypeHash);
  }

  getMappedLayoutDescriptor(
    type: RelationshipVisualType<RelationshipType, EdgeVisualType>
  ): EdgeRouterEdgeLayoutDescriptor {
    const relationshipVisualTypeHash = calculateHash(type);
    return this.edgeLayoutDescriptorMappings.get(relationshipVisualTypeHash);
  }

  getLayoutSettings(
    edgeType: RelationshipVisualType<RelationshipType, EdgeVisualType>
  ): any {
    // The port adjustment policy should always be set to NEVER.
    // Pulling an edge to the nodes geometry is handled within the EdgeRoutingHelper
    const portAdjustmentPolicy = PortAdjustmentPolicy.NEVER;
    const fixPorts = false;
    const automaticEdgeGrouping =
      edgeType.relationshipType !== RelationshipType.Cashflow;

    return {
      automaticEdgeGrouping,
      fixPorts,
      portAdjustmentPolicy
    };
  }

  getEdgeSortOrder(name: RelationshipType): number {
    switch (name) {
      case RelationshipType.Ownership:
        return 0;
      case RelationshipType.Contract:
        return 1;
      case RelationshipType.Cashflow:
        return 3;
    }
    return 4;
  }

  markForAdjustment(
    graph: IGraph,
    affectedEdge: IEdge,
    sourceEnd: boolean
  ): void {
    if (affectedEdge.tag.relationshipType == RelationshipType.Ownership) {
      const edgePort = sourceEnd
        ? affectedEdge.sourcePort
        : affectedEdge.targetPort;
      const edgeNode = edgePort.owner;

      const adjustmentCandidatesPredicate = (edge: IEdge) => {
        if (edge.tag.relationshipType === RelationshipType.Ownership) {
          return false;
        }
        if (!edge.targetPort.location.equals(edgePort.location)) {
          return false;
        }
        return true;
      };

      const adjustmentCandidates = graph
        .edgesAt(edgeNode, 'all')
        .filter(adjustmentCandidatesPredicate)
        .toArray();

      adjustmentCandidates.forEach((e) => {
        if (e != affectedEdge) {
          e.tag[READJUSTMENT_TAG] = true;
        }
      });
    }
  }

  routeKnownEdges(graph: IGraph, affectedEdges: IEdge[]): RoutingResult {
    affectedEdges.forEach((affectedEdge) => {
      this.markForAdjustment(graph, affectedEdge, true);
      // This will be reworked to readjust other edges takes same port "Ownership" take
      // this.markForAdjustment(graph, affectedEdge, false);
    });

    let self = this;
    const grouped = groupBy(affectedEdges, (e) => e.tag.relationshipType);
    let groupRelationshipType = Object.keys(grouped).sort(
      (a, b) =>
        self.getEdgeSortOrder(parseInt(a)) - self.getEdgeSortOrder(parseInt(b))
    );
    let edgesToRoute = [...affectedEdges];
    const unknownEdges = [];
    groupRelationshipType.forEach((relationshipTypeGroup) => {
      const edges = grouped[relationshipTypeGroup];
      // remove the current groups edges from the filter so they are considered by the layout algo
      edgesToRoute = edgesToRoute.filter((edge) => !edges.includes(edge));
      const relationshipVisualType = {
        relationshipType: edges[0].tag.relationshipType,
        visualType: edges[0].tag.style.visualType
      };
      const routerExistsForRelationshipType =
        DefaultRelationshipVisualTypes.some(
          (relationshipVisual) =>
            relationshipVisual.relationshipType ==
              relationshipVisualType.relationshipType &&
            relationshipVisual.visualType == relationshipVisualType.visualType
        );
      if (routerExistsForRelationshipType) {
        const edgeRouter = this.getMappedEdgeRouter(relationshipVisualType);
        const layoutSettings = this.getLayoutSettings(relationshipVisualType);

        const layoutData = this.getLayoutData(graph, edges);
        const unreachableNodes = this.getUnreachableNodes(graph, edges);
        graph.applyLayout({
          layout: new PreserveFixedInLayoutEdges(
            new HiddenObjectLayoutStage(edgeRouter, {
              nodePredicate: (node) =>
                EdgeRoutingHelper.isNodeIgnoredInEdgeRouting(node) ||
                unreachableNodes.indexOf(node) >= 0,
              edgePredicate: (edge) => edgesToRoute.includes(edge)
            })
          ),
          layoutData: layoutData,
          automaticEdgeGrouping: layoutSettings.automaticEdgeGrouping,
          fixPorts: layoutSettings.fixPorts,
          portAdjustmentPolicy: layoutSettings.portAdjustmentPolicy
        });
      } else {
        unknownEdges.push(...edges);
      }
    });

    // 2nd pass

    return {
      knownEdges: [],
      unknownEdges: unknownEdges
    };
  }

  /**
   * Given a list of edges, this will return a list of nodes that are disconnected from these edges
   * @param graph
   * @param affectedEdges
   * @returns
   */
  private getUnreachableNodes(graph: IGraph, affectedEdges: IEdge[]): INode[] {
    const reachability = new Reachability({
      directed: false,
      startNodes: affectedEdges.flatMap((edge) => [
        edge.sourceNode,
        edge.targetNode
      ])
    }).run(graph);

    return graph.nodes
      .filter((d) => !reachability.reachableNodes.contains(d))
      .toArray();
  }

  getLayoutDataContract(graph: IGraph, affectedEdges: IEdge[]): LayoutData {
    const em = graph.mapperRegistry.createMapper(
      BusRouter.EDGE_DESCRIPTOR_DP_KEY
    );

    const busids = toArray(
      mapValues(
        keyBy(affectedEdges, (c) => c.tag.busid),
        (l) => l.tag.busid
      )
    );

    const busEdges: IEdge[] = [];
    busids.forEach((busid) => {
      if (busid != null) {
        graph.edges
          .filter((e) => e.tag.busid == busid)
          .forEach((b) => {
            busEdges.push(b);
          });
      }
    });

    if (busEdges) {
      busEdges.forEach((e) => {
        const descriptor = new BusRouterBusDescriptor(e.tag.busid);
        descriptor.fixed = e.tag.isFixedInLayout;
        em.set(e, descriptor);
      });
    }

    graph.edges.forEach((e) => {
      if (busEdges.indexOf(e) < 0) {
        const descriptor = new BusRouterBusDescriptor(null);
        descriptor.fixed = true; // fix all edges we don't want moved
        em.set(e, descriptor);
      }
    });

    //https://docs.yworks.com/yfileshtml/#/api/BusRouterBusDescriptor#BusDescriptor-property-fixed

    let busRouterData = new BusRouterData({
      edgeDescriptors: em,
      affectedEdges: (edge) => busEdges.indexOf(edge) >= 0
    });

    busEdges.forEach((e) => {
      const sourcePortParams = this.getSourcePortParams(graph, e);
      const targetPortParams = this.getTargetPortParams(graph, e);

      // source
      if (
        sourcePortParams.candidates &&
        sourcePortParams.candidates.length > 0
      ) {
        busRouterData.sourcePortCandidates.mapper.set(
          e,
          new List(sourcePortParams.candidates)
        );
      }

      if (sourcePortParams.constraint) {
        busRouterData.sourcePortConstraints.mapper.set(
          e,
          sourcePortParams.constraint
        );
      }

      // target
      if (
        targetPortParams.candidates &&
        targetPortParams.candidates.length > 0
      ) {
        busRouterData.targetPortCandidates.mapper.set(
          e,
          new List(targetPortParams.candidates)
        );
      }

      if (targetPortParams.constraint) {
        busRouterData.targetPortConstraints.mapper.set(
          e,
          targetPortParams.constraint
        );
      }
    });

    return busRouterData;
    //
  }

  getSourcePortParams(
    graph: IGraph,
    edge: IEdge
  ): {
    candidates: PortCandidate[];
    constraint: PortConstraint;
  } {
    return this.getEdgeParams(graph, edge, true);
  }

  getTargetPortParams(
    graph: IGraph,
    edge: IEdge
  ): {
    candidates: PortCandidate[];
    constraint: PortConstraint;
  } {
    return this.getEdgeParams(graph, edge, false);
  }

  getOwnershipParams(sourceEnd: boolean, node: INode) {
    let candidate = null;
    if (sourceEnd) {
      candidate = EdgeRoutingHelper.pullPortCandidateToGeometry(
        node,
        DiagramUtils.createPortCandidate(
          node,
          0,
          node.layout.maxY - node.layout.center.y,
          PortDirections.SOUTH,
          0
        )
      );
    } else {
      candidate = EdgeRoutingHelper.pullPortCandidateToGeometry(
        node,
        DiagramUtils.createPortCandidate(
          node,
          0,
          node.layout.y - node.layout.center.y,
          PortDirections.NORTH,
          0
        )
      );
    }
    return { candidates: [candidate], constraint: null };
  }

  getEdgeParams(
    graph: IGraph,
    edge: IEdge,
    sourceEnd: boolean
  ): {
    candidates: PortCandidate[];
    constraint: PortConstraint;
  } {
    const node = sourceEnd ? edge.sourceNode : edge.targetNode;
    const edgeType = edge.tag.relationshipType;
    //this logic still stands
    if (sourceEnd) {
      if (edge.tag.sourcePortFixed) {
        return EdgeRoutingHelper.getFixedEdgeParams(edge, sourceEnd);
      }
    } else {
      if (edge.tag.targetPortFixed) {
        return EdgeRoutingHelper.getFixedEdgeParams(edge, sourceEnd);
      }
    }
    if (edgeType == RelationshipType.Ownership) {
      return this.getOwnershipParams(sourceEnd, node);
    }

    const layout = node.layout;
    const ignoreLocations = [
      new Point(layout.x, layout.y),
      new Point(layout.x, layout.maxY),
      new Point(layout.maxX, layout.y),
      new Point(layout.maxX, layout.maxY),
      layout.center
    ];

    const params = EdgeRoutingHelper.getEdgeParams(
      graph,
      edge,
      sourceEnd,
      ignoreLocations
    );

    params.candidates = this.filterPortCandidates(
      graph,
      node,
      edge,
      edgeType,
      params.candidates
    );
    return params;
  }

  filterPortCandidates(
    graph: IGraph,
    node: INode,
    edge: IEdge,
    edgeType: RelationshipType,
    candidates: PortCandidate[]
  ): PortCandidate[] {
    for (let index = candidates.length - 1; index >= 0; index--) {
      let candidate = candidates[index];
      const ports = DiagramUtils.findClosestPorts(
        node.ports.toArray(),
        node.layout.center.add(new Point(candidate.xOffset, candidate.yOffset)),
        1
      );

      if (ports && ports.length > 0) {
        let edgesAt: IEdge[] = [];
        let allEdges = ports
          .map((port) => {
            return graph.edgesAt(port, 'all').filter((e) => e != edge);
          })
          .filter((e) => e.size > 0);
        if (allEdges.length > 0) {
          edgesAt = allEdges
            .reduce((m, b) => {
              m.append(...b);
              return m;
            })
            .toArray();
        }
        if (edgeType == RelationshipType.Contract) {
          // Same BusID can share same port
          edgesAt = edgesAt.filter((e) => e.tag.busid != edge.tag.busid);
        }
        if (edgesAt.length > 0) {
          candidates.splice(index, 1);
        }
      }
    }
    return candidates;
  }
  convertPortSideToDirection(portSide: PortSide) {
    if (portSide === PortSide.NORTH) {
      console.debug('North');

      return PortDirections.NORTH;
    }
    if (portSide === PortSide.EAST) {
      console.debug('East');

      return PortDirections.EAST;
    }
    if (portSide === PortSide.SOUTH) {
      console.debug('South');

      return PortDirections.SOUTH;
    }
    if (portSide === PortSide.WEST) {
      console.debug('West');

      return PortDirections.WEST;
    }
    if (portSide === PortSide.ANY) return PortDirections.ANY;

    throw 'Unknown portside';
  }

  private getLayoutData(graph: IGraph, affectedEdges: IEdge[]): LayoutData {
    let self = this;
    let layoutData: LayoutData = null;
    if (
      affectedEdges.every(
        (s) => s.tag.relationshipType == RelationshipType.Contract
      )
    ) {
      layoutData = this.getLayoutDataContract(graph, affectedEdges);
    }
    //
    else {
      const edgeRouterData = new EdgeRouterData();

      affectedEdges.forEach((edge) => {
        const sourcePortParams = this.getSourcePortParams(graph, edge);
        const targetPortParams = this.getTargetPortParams(graph, edge);
        if (
          sourcePortParams.candidates &&
          sourcePortParams.candidates.length > 0
        ) {
          edgeRouterData.sourcePortCandidates.mapper.set(
            edge,
            new List(sourcePortParams.candidates)
          );
        }

        if (sourcePortParams.constraint) {
          edgeRouterData.sourcePortConstraints.mapper.set(
            edge,
            sourcePortParams.constraint
          );
        }

        if (
          targetPortParams.candidates &&
          targetPortParams.candidates.length > 0
        ) {
          edgeRouterData.targetPortCandidates.mapper.set(
            edge,
            new List(targetPortParams.candidates)
          );
        }

        if (targetPortParams.constraint) {
          edgeRouterData.targetPortConstraints.mapper.set(
            edge,
            targetPortParams.constraint
          );
        }
      });

      edgeRouterData.affectedEdges.delegate = (edge) => {
        return (
          affectedEdges.indexOf(edge) >= 0 &&
          !DiagramUtils.isAnnotationArrow(edge)
        );
      };
      edgeRouterData.edgeLayoutDescriptors.delegate = (edge) => {
        return this.getMappedLayoutDescriptor({
          relationshipType: edge.tag.relationshipType,
          visualType: edge.tag.style.visualType
        });
      };

      layoutData = edgeRouterData;
    }

    return layoutData;
  }
}
