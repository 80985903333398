import Vue from 'vue';
import {
  DeloitteSection,
  DocumentDto,
  DocumentPageDto,
  PageDesignDto
} from '@/api/models';
import PluginsService from '@/core/services/PluginsService';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import DeloittePageService from './DeloittePageService';
import { DeloitteMergeTagsRegex } from './DeloitteMergeTagsRegex';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT
} from '@/core/services/store/document.module';
import DocumentService from '@/core/services/document/DocumentService';

export default class DeloittePlugin {
  public static shouldRunPagination: boolean = false;

  public static get isEnabled(): boolean {
    return PluginsService.hasPlugin('Deloitte');
  }

  public static get isDeloitteIFrame(): boolean {
    return this.isEnabled && window.parent !== window;
  }

  private static get document(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  public static customizeIFrameRibbon(): void {
    if (!this.isDeloitteIFrame) {
      return;
    }

    const ribbon = document.querySelector('.vc-ribbon');
    if (!ribbon) {
      return;
    }

    const quickLaunchToolbar = ribbon.querySelector(
      '.vc-ribbon-flex'
    ) as HTMLElement;
    if (quickLaunchToolbar) {
      quickLaunchToolbar.style.display = 'none';
    }

    const saveButton = ribbon.querySelector(
      'div[rb-name="file-save-export-functions"] div[rb-name="save"].vc-ribbon-button-large'
    );
    if (saveButton) {
      saveButton.classList.remove('vc-ribbon-button-large');
    }

    const exportButton = ribbon.querySelector(
      'div[rb-tool="menu:file-save-export-functions-export"] .vc-ribbon-menu-header-label'
    ) as HTMLElement;
    if (exportButton) {
      exportButton.style.fontSize = '11px';
    }

    const pinContainer = ribbon.querySelector('.pin-container') as HTMLElement;
    if (pinContainer) {
      pinContainer.style.display = 'none';
    }
  }

  public static init(): void {
    if (!this.isEnabled) {
      return;
    }

    EventBus.$on(
      EventBusActions.DOCUMENT_PAGE_ADDED,
      this.onDocumentPageAdded.bind(this)
    );

    if (this.isDeloitteIFrame) {
      window.addEventListener('message', (event) => {
        if (event.data === 'saveDocument') {
          EventBus.$emit(EventBusActions.DOCUMENT_SAVE, { force: true });
        }
      });

      EventBus.$on(EventBusActions.DOCUMENT_SAVED, () => {
        window.parent.postMessage('saveComplete', '*');
      });
    }
  }

  public static async mergeDocument(
    reportSections: DeloitteSection[],
    document: DocumentDto,
    layoutPageDesigns: PageDesignDto[],
    externalReportName: string
  ): Promise<void> {
    const existingSections = reportSections.filter((section) =>
      document.pages.some((page) => page.name === section.name)
    );
    await DeloittePageService.mergePages(
      existingSections,
      document,
      layoutPageDesigns,
      externalReportName
    );

    const newSections = reportSections.filter(
      (section) => !existingSections.some((page) => page.name === section.name)
    );
    await DeloittePageService.createPages(
      newSections,
      document,
      layoutPageDesigns,
      externalReportName
    );

    //delete the content table which is not in the chaptersSections
    await DeloittePageService.deleteContentPages(reportSections, document);

    DeloittePageService.reorderPages(reportSections, document);

    DeloittePageService.updateContentPages(
      reportSections,
      document,
      layoutPageDesigns
    );

    await DocumentService.saveDocument(document, true);
  }

  private static async onDocumentPageAdded(
    page: DocumentPageDto
  ): Promise<void> {
    if (!DeloitteMergeTagsRegex.PROJECT_TITLE.test(page.footerLayout)) {
      return;
    }

    page.footerLayout = page.footerLayout.replace(
      DeloitteMergeTagsRegex.PROJECT_TITLE,
      this.document.name
    );
    await DocumentService.saveDocument(this.document, true);
  }
}
