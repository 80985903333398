﻿export enum ExportCachePolicy {
  /**
   * Use Default behaviour from the ExportProvider
   */
  Default,
  /**
   * Ignore cached exports even when available
   */
  Ignore,
  /**
   * Reuse cached exports when available
   */
  Reuse
}
