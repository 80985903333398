import { CompositionType } from '../filters/CompositeFilter';
import Filter from '../filters/Filter';
import ISearchParameter from './ISearchParameter';

export default class CompositeParameter implements ISearchParameter {
  public filters: Filter[];
  public compositionType: CompositionType;

  constructor(filters: Filter[], compositionType: CompositionType) {
    this.filters = filters;
    this.compositionType = compositionType;
  }

  public equals(other: ISearchParameter): boolean {
    return false;
  }
}
