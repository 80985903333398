import { IEdge, INode } from 'yfiles';

import DiagramUtils from '@/core/utils/DiagramUtils';
import i18n from '@/core/plugins/vue-i18n';

import EntityNameParameter from '../parameters/EntityNameParameter';

import { ITypedMatcher } from './IMatcher';

export default class EntityNameMatcher
  implements ITypedMatcher<INode | IEdge, EntityNameParameter>
{
  public canMatch(candidate: any, parameter: EntityNameParameter): boolean {
    return candidate instanceof INode;
  }

  public isMatch(
    candidate: INode | IEdge,
    parameter: EntityNameParameter
  ): boolean {
    const refText = candidate.labels.firstOrDefault()?.text;

    return candidate instanceof INode && refText
      ? DiagramUtils.getExactLabel(refText) === parameter.entityName
      : i18n.t(candidate.tag?.name) === parameter.entityName;
  }
}
