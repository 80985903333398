import {
  WordExportDto,
  PowerPointExportDto,
  AttachmentType,
  WordExportPageDto,
  DocumentPageContentType,
  DocumentPageType,
  ImageType,
  PowerPointExportSlideDto,
  InsetsDto,
  DocumentPageLayoutType
} from '@/api/models';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import ExportConfig from '@/core/config/ExportConfig';
import DocumentService from '@/core/services/document/DocumentService';
import BackgroundGraphService from '@/core/services/graph/BackgroundGraphService';
import { convertImageSrcToPng } from '@/core/utils/common.utils';
import ExportOptions from '../../ExportOptions';
import ExportUtils from '../../ExportUtils';
import { OpenXmlExportContentAsImage } from './OpenXmlExportContentAsImage';
import { OpenXmlExportLegend } from './OpenXmlExportLegend';
import { OpenXmlExportType } from './OpenXmlExportType';
import { OpenXmlExportUtils } from './OpenXmlExportUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import ExportPage from '../../ExportPage';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { IGraph } from 'yfiles';
import { OpenXmlExportDiagram } from './OpenXmlExportDiagram';
import DeloittePlugin from '@/plugins/Deloitte/DeloittePlugin';
import FeaturesService from '../../../FeaturesService';
import { Features } from '@/core/common/Features';

export class OpenXmlExportRequestBuilder {
  private readonly exportType: OpenXmlExportType;
  private readonly exportOptions: ExportOptions;
  private readonly legendExport: OpenXmlExportLegend;
  private readonly diagramExport: OpenXmlExportDiagram;
  private readonly contentAsImageExport: OpenXmlExportContentAsImage;
  private currentPageNumber: 0;
  private totalPages = 0;

  constructor(exportType: OpenXmlExportType, exportOptions: ExportOptions) {
    this.exportType = exportType;
    this.exportOptions = exportOptions;
    this.legendExport = new OpenXmlExportLegend(exportOptions);
    this.diagramExport = new OpenXmlExportDiagram(exportOptions);
    this.contentAsImageExport = new OpenXmlExportContentAsImage(exportOptions);
  }

  public async getExportRequest<
    T extends WordExportDto | PowerPointExportDto
  >(): Promise<T> {
    const logo = this.exportOptions.document?.attachments?.find(
      (a) => a.attachmentType == AttachmentType.Logo
    );
    this.totalPages = DocumentService.getTotalPagesCount();

    const request = {
      pages: [],
      headerStyle: this.exportOptions.document.hasSteps
        ? this.exportOptions.document.headerStyle
        : null,
      footerStyle: this.exportOptions.document.hasSteps
        ? this.exportOptions.document.footerStyle
        : null,
      logoFileAttachmentId: logo?.fileAttachment?.fileId,
      logoPosition: this.exportOptions.document.logoPosition,
      pageStyle: this.exportOptions.document.pageStyle,
      defaultDiagramFont: {
        fontSize: diagramConfig.defaultFontSize,
        fontFamily: diagramConfig.defaultFontFamily,
        fontWeight: diagramConfig.defaultFontWeight,
        fontStyle: diagramConfig.defaultFontStyle,
        textDecoration: diagramConfig.defaultTextDecoration
      },
      defaultContentFont: ExportConfig.defaultContentFontStyle,
      subPageFlowBehavior: DocumentService.subPageFlowBehavior
    } as T;

    this.currentPageNumber = 0;
    for (const exportPage of this.exportOptions.pages) {
      this.currentPageNumber++;
      this.exportOptions.metadata.currentPage = exportPage;

      if (exportPage.page.contentType === DocumentPageContentType.Layout) {
        const pageRequest = await this.getLayoutPage(exportPage);
        request.pages.push(pageRequest as any);
      } else if (exportPage.page.contentType == DocumentPageContentType.Html) {
        const subPagesContent = await OpenXmlExportUtils.getHtmlPageContent(
          this.exportOptions.document,
          exportPage.page
        );

        for (let index = 0; index < subPagesContent.length; index++) {
          if (index > 0) {
            this.currentPageNumber++;
          }
          const pageRequest = await this.getHtmlPage(
            exportPage,
            index,
            subPagesContent[index]
          );
          request.pages.push(pageRequest as any);
        }
      } else if (
        exportPage.page.contentType == DocumentPageContentType.MasterLegend
      ) {
        const pageRequest = await this.getMasterLegendPage(exportPage);

        request.pages.push(pageRequest as any);
      } else {
        const pageRequest = await this.getDiagramPage(exportPage);
        request.pages.push(pageRequest as any);
      }
    }
    return request;
  }

  private async getLayoutPage<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(exportPage: ExportPage): Promise<T> {
    const pageRequest = await this.getExportPageDto<T>();
    const layoutDefinitions = await OpenXmlExportUtils.getLayouts(
      this.exportOptions.document,
      exportPage
    );
    pageRequest.bodyLayout = layoutDefinitions.bodyLayout;
    pageRequest.documentPageType = DocumentPageType.Content;
    pageRequest.documentPageContentType = DocumentPageContentType.Layout;
    pageRequest.diagramPosition = null;

    if (exportPage.page.layoutType === DocumentPageLayoutType.ContentTable) {
      pageRequest.headerLayout = layoutDefinitions.headerLayout;
      pageRequest.footerLayout = layoutDefinitions.footerLayout;
      pageRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
      pageRequest.bodyLayout = layoutDefinitions.bodyLayout;
      pageRequest.documentPageLayoutType = DocumentPageLayoutType.ContentTable;

      const layoutItems = [
        ...pageRequest.headerLayout.items,
        ...pageRequest.footerLayout.items
      ].map((i) => OpenXmlExportUtils.layoutItemDtoAsItem(i));
      LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
        layoutItems,
        this.currentPageNumber,
        this.totalPages,
        exportPage.page.layoutType,
        false
      );
    }

    if (this.exportType === OpenXmlExportType.Word) {
      (pageRequest as WordExportPageDto).contentAsImage =
        await this.contentAsImageExport.getContentAsImage(
          ImageType.Png,
          exportPage,
          pageRequest as WordExportPageDto,
          0,
          null
        );
    }

    return pageRequest;
  }

  private async getHtmlPage<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(
    exportPage: ExportPage,
    subPageIndex: number,
    htmlContent: string
  ): Promise<T> {
    const diagram =
      exportPage.page.subPageRefs?.find((x) => x.subPageIndex == subPageIndex)
        ?.diagram ?? exportPage.page.diagram;

    const sourceGraph = diagram
      ? BackgroundGraphService.createGraph(diagram)
      : null;

    const subPageRequest = await this.getExportPageDto<T>(
      sourceGraph,
      subPageIndex
    );

    const includeLegend = ExportUtils.shouldIncludeLegend(
      this.exportOptions.document,
      exportPage.page,
      subPageIndex
    );

    if (includeLegend) {
      subPageRequest.tables = [
        await this.legendExport.legendToTable(
          JSON.parse(diagram.legend),
          subPageRequest.pageMargins
        )
      ];
    }

    const layoutDefinitions = await OpenXmlExportUtils.getLayouts(
      this.exportOptions.document,
      exportPage,
      subPageIndex
    );
    subPageRequest.headerLayout = layoutDefinitions.headerLayout;
    subPageRequest.footerLayout = layoutDefinitions.footerLayout;
    subPageRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
    subPageRequest.titleLayout = layoutDefinitions.titleLayout;
    subPageRequest.forceReservePageTitleSpace =
      OpenXmlExportUtils.forceReservePageTitleSpace(
        this.exportOptions.document,
        exportPage,
        subPageIndex
      );

    const layoutItems = [
      ...subPageRequest.headerLayout.items,
      ...subPageRequest.footerLayout.items
    ].map((i) => OpenXmlExportUtils.layoutItemDtoAsItem(i));

    LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
      layoutItems,
      this.currentPageNumber,
      this.totalPages,
      exportPage.page.layoutType,
      false
    );

    const result = {
      ...subPageRequest,
      htmlContent: htmlContent,
      slideNumber: this.currentPageNumber
    } as T;

    if (this.exportType === OpenXmlExportType.Word) {
      if (sourceGraph) {
        (result as WordExportPageDto).contentAsImage =
          await this.contentAsImageExport.getContentAsImage(
            ImageType.Png,
            exportPage,
            subPageRequest as WordExportPageDto,
            subPageIndex,
            sourceGraph
          );
      }

      (result as WordExportPageDto).isSubPage = true;
      (result as WordExportPageDto).subPageIndex = subPageIndex;
    }

    return result;
  }

  private async getMasterLegendPage<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(exportPage: ExportPage): Promise<T> {
    const pageRequest = await this.getExportPageDto<T>();

    const layoutDefinitions = await OpenXmlExportUtils.getLayouts(
      this.exportOptions.document,
      exportPage
    );
    pageRequest.headerLayout = layoutDefinitions.headerLayout;
    pageRequest.footerLayout = layoutDefinitions.footerLayout;
    pageRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
    pageRequest.titleLayout = layoutDefinitions.titleLayout;
    pageRequest.bodyLayout = layoutDefinitions.bodyLayout;

    const layoutItems = [
      ...pageRequest.headerLayout.items,
      ...pageRequest.footerLayout.items
    ].map((i) => OpenXmlExportUtils.layoutItemDtoAsItem(i));
    LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
      layoutItems,
      this.currentPageNumber,
      this.totalPages,
      exportPage.page.layoutType,
      false
    );

    pageRequest.tables = [
      await this.legendExport.legendToTable(
        JSON.parse(exportPage.page.content),
        pageRequest.pageMargins
      )
    ];

    if (this.exportType === OpenXmlExportType.Word) {
      (pageRequest as WordExportPageDto).contentAsImage =
        await this.contentAsImageExport.getContentAsImage(
          ImageType.Png,
          exportPage,
          pageRequest as WordExportPageDto,
          0,
          null
        );
    }

    return pageRequest;
  }

  private async getDiagramPage<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(exportPage: ExportPage): Promise<T> {
    const sourceGraph = exportPage.page.diagram
      ? BackgroundGraphService.createGraph(exportPage.page.diagram)
      : null;

    const pageRequest = await this.getExportPageDto<T>(sourceGraph);

    const includeLegend = ExportUtils.shouldIncludeLegend(
      this.exportOptions.document,
      exportPage.page
    );

    if (includeLegend) {
      const legendDefinition = JSON.parse(
        exportPage.page.diagram.legend
      ) as ILegendDefinition;
      /* Now we need to inline all the images*/
      for (const item of legendDefinition.items) {
        item.symbol = (await convertImageSrcToPng(item.symbol, false)).src;
      }
      pageRequest.showLegend = includeLegend;
      pageRequest.tables = [
        await this.legendExport.legendToTable(
          JSON.parse(exportPage.page.diagram.legend),
          pageRequest.pageMargins
        )
      ];
    }

    const layoutDefinitions = await OpenXmlExportUtils.getLayouts(
      this.exportOptions.document,
      exportPage
    );
    pageRequest.headerLayout = layoutDefinitions.headerLayout;
    pageRequest.footerLayout = layoutDefinitions.footerLayout;
    pageRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
    pageRequest.titleLayout = layoutDefinitions.titleLayout;

    if (this.exportOptions.document.hasSteps) {
      pageRequest.pageNumber = this.currentPageNumber;
      const layoutItems = [
        ...pageRequest.headerLayout.items,
        ...pageRequest.footerLayout.items
      ].map((i) => OpenXmlExportUtils.layoutItemDtoAsItem(i));
      LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
        layoutItems,
        this.currentPageNumber,
        this.totalPages,
        exportPage.page.layoutType,
        false
      );

      if (this.exportType === OpenXmlExportType.Word) {
        (pageRequest as WordExportPageDto).contentAsImage =
          await this.contentAsImageExport.getContentAsImage(
            ImageType.Png,
            exportPage,
            pageRequest as WordExportPageDto,
            0,
            sourceGraph
          );
      }
    }

    return pageRequest;
  }

  private async getExportPageDto<
    T extends WordExportPageDto | PowerPointExportSlideDto
  >(graph: IGraph = null, subPageIndex: number = null): Promise<T> {
    const document = this.exportOptions.document;
    const page = this.exportOptions.metadata.currentPage.page;
    const margins = ExportUtils.calculatePageMargins(document, page);
    const pageMargins = new InsetsDto(
      Math.round(margins.top),
      Math.round(margins.left),
      Math.round(margins.bottom),
      Math.round(margins.right)
    );

    const showHeader =
      page.showHeader ||
      (LayoutWidgetUtils.contentAreaContainsWidgets(
        page,
        DocumentContentArea.Header,
        true
      ) &&
        (page.contentType != DocumentPageContentType.Layout ||
          (page.contentType === DocumentPageContentType.Layout &&
            page.layoutType === DocumentPageLayoutType.ContentTable)));
    const showFooter =
      page.showFooter ||
      (LayoutWidgetUtils.contentAreaContainsWidgets(
        page,
        DocumentContentArea.Footer,
        true
      ) &&
        (page.contentType != DocumentPageContentType.Layout ||
          (page.contentType === DocumentPageContentType.Layout &&
            page.layoutType === DocumentPageLayoutType.ContentTable)));

    const subPageRef = page.subPageRefs?.find(
      (sp) => sp.subPageIndex === subPageIndex
    );
    const showTitle =
      (subPageRef?.showTitle ?? page.showTitle) &&
      page.contentType != DocumentPageContentType.Layout;

    const forceReservePageTitleSpace =
      page.contentType != DocumentPageContentType.Layout &&
      OpenXmlExportUtils.forceReservePageTitleSpace(
        document,
        this.exportOptions.metadata.currentPage,
        subPageIndex
      );

    const includeLegend = ExportUtils.shouldIncludeLegend(
      this.exportOptions.document,
      page
    );

    const exportSlide = {
      pageMargins: pageMargins,
      documentPageType: page.getSubPageType(subPageIndex),
      documentPageContentType: page.contentType,
      diagramPosition: page.diagramPosition,
      htmlContentColumns: page.getSubPageColumns(subPageIndex),
      htmlContentColumnGap: ExportUtils.calculateHtmlContentGap(document),
      pageWidth: Math.round(
        document.pageStyle.width * ExportConfig.pointToPixelFactor
      ),
      pageHeight: Math.round(
        document.pageStyle.height * ExportConfig.pointToPixelFactor
      ),
      pageNumber: 0,
      showHeader: showHeader,
      showFooter: showFooter,
      showTitle: showTitle,
      showLegend: includeLegend,
      showLogo: ExportUtils.shouldIncludeLogo(document, page),
      showDivider: ExportUtils.shouldShowDivider(page, subPageIndex),
      nodes: [],
      edges: [],
      forceReservePageTitleSpace: forceReservePageTitleSpace,
      removeLinkUnderline:
        DeloittePlugin.isEnabled ||
        FeaturesService.hasFeature(Features.RemoveLinkUnderline),
      isSubPage: false
    } as T;

    if (page.contentType == DocumentPageContentType.Html) {
      const padding = ExportUtils.calculatePadding(
        document,
        page,
        subPageIndex,
        'htmlContent'
      );
      if (this.exportType === OpenXmlExportType.PowerPoint) {
        exportSlide.htmlContentPadding = padding.getEnlarged(
          ExportConfig.pptContentPaddingAdjustment
        );
      } else {
        exportSlide.htmlContentPadding = padding;
      }
    }

    if (graph) {
      await this.diagramExport.addDiagramDataToPage(
        exportSlide,
        graph,
        subPageIndex
      );
    }

    return exportSlide;
  }
}
