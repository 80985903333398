export default class SystemEntityTypes {
  public static readonly EDGE_OWNERSHIP: string = 'OWNERSHIP';
  public static readonly EDGE_CONTRACT: string = 'CONTRACT';
  public static readonly EDGE_CASHFLOW: string = 'CASHFLOW';
  public static readonly EDGE_PLACEHOLDER: string = 'PLACEHOLDER';
  public static readonly NODE_CORPORATE: string = 'CORPORATE';
  public static readonly NODE_PARTNERSHIP: string = 'PARTNERSHIP';
  public static readonly NODE_TRUST: string = 'TRUST';
  public static readonly EDGE_TO_NOWHERE_NODE: string = 'EdgeToNowhereNode';
  public static readonly ARROW_NODE: string = 'ArrowNode';
}
