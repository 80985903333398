import IDisposable from '@/core/common/IDisposable';
import IGraphService from '@/v2/services/interfaces/IGraphService';

import { HighlightIndicatorManager, IModelItem } from 'yfiles';

export default abstract class HighlightServiceBase implements IDisposable {
  public static readonly $class: string = 'HighlightServiceBase';
  constructor() {}

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
  }

  public abstract get highlightManager(): HighlightIndicatorManager<IModelItem>;

  public highlightElement(item: IModelItem): void {
    this.highlightManager.addHighlight(item);
  }

  clearHighlights(): void {
    this.highlightManager.clearHighlights();
  }

  removeHighlight(item: IModelItem): void {
    this.highlightManager.removeHighlight(item);
  }
}
