import {
  WordExportPageDto,
  DocumentPageType,
  ImageType,
  DocumentPageContentType,
  ImageData
} from '@/api/models';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import JSize from '@/core/common/JSize';
import { convertHtmlElementToSvgElement } from '@/core/utils/common.utils';
import { htmlToElement } from '@/core/utils/html.utils';
import { toPng } from 'html-to-image';
import { IGraph } from 'yfiles';
import { ExportFormat } from '../../ExportFormat';
import ExportOptions from '../../ExportOptions';
import ExportPage from '../../ExportPage';
import { OpenXmlExportUtils } from './OpenXmlExportUtils';
import { ExportType } from '../../ExportType';
import ExportService from '../../ExportService';
import { ExportCachePolicy } from '../../ExportCachePolicy';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import { ExportPageElementType } from '../../ExportPageElementType';

export class OpenXmlExportContentAsImage {
  private readonly exportOptions: ExportOptions;

  constructor(exportOptions: ExportOptions) {
    this.exportOptions = exportOptions;
  }

  public async getContentAsImage(
    imageType: ImageType,
    exportPage: ExportPage,
    pageRequest: WordExportPageDto,
    subPageIndex: number,
    sourceGraph: IGraph,
    sizeMultiplier: number = 2
  ): Promise<ImageData> {
    let contentAsImage: ImageData;
    if (
      exportPage.page.contentType === DocumentPageContentType.None ||
      (exportPage.page.contentType == DocumentPageContentType.Html &&
        exportPage.page.pageType === DocumentPageType.Split)
    ) {
      const diagramImage = await this.getDiagramAsImage(
        sourceGraph,
        exportPage,
        pageRequest,
        subPageIndex,
        imageType,
        sizeMultiplier
      );

      contentAsImage = {
        image: diagramImage,
        type: imageType
      };
    } else if (
      exportPage.page.contentType === DocumentPageContentType.MasterLegend
    ) {
      let imageStr = await this.createMasterLegendPageSvg();
      if (imageType === ImageType.Svg) {
        contentAsImage = {
          image: imageStr,
          type: ImageType.Svg
        };
      } else {
        const htmlPage = htmlToElement(imageStr);
        const width = Math.round(Number(htmlPage.getAttribute('width')));
        const height = Math.round(Number(htmlPage.getAttribute('height')));
        imageStr = await toPng(htmlPage, {
          backgroundColor: 'white',
          width: width,
          height: height
        });
        contentAsImage = {
          image: imageStr,
          type: ImageType.Png
        };
      }
    } else if (exportPage.page.contentType === DocumentPageContentType.Layout) {
      const svgEl = (await ExportService.export({
        ...this.exportOptions,
        format: ExportFormat.SvgElement,
        type: ExportType.PageThumbnail,
        document: this.exportOptions.document,
        pages: [exportPage],
        withData: false,
        withAttachments: false,
        withFilters: false,
        download: false,
        clipboard: false,
        print: false,
        lowDetailDiagram: false,
        lowDetailBackground: false,
        cachePolicy: ExportCachePolicy.Ignore
      })) as unknown as HTMLElement;

      if (imageType === ImageType.Svg) {
        return {
          image: svgEl.outerHTML,
          type: ImageType.Svg
        };
      } else {
        const pngString = await toPng(svgEl, {
          backgroundColor: 'white',
          width: this.exportOptions.document.pageStyle.width * sizeMultiplier,
          height: this.exportOptions.document.pageStyle.height * sizeMultiplier
        });

        return {
          image: pngString,
          type: ImageType.Png
        };
      }
    }

    return contentAsImage;
  }

  private async createMasterLegendPageSvg(): Promise<string> {
    const page = this.exportOptions.metadata.currentPage.page;
    const contentSize = OpenXmlExportUtils.getContentSize(this.exportOptions);
    const bodyItems = LayoutSerializer.deserializeFromJson(page.bodyLayout);
    const bodyLayoutHtml = await LayoutSerializer.serializeToHtml(bodyItems);

    const svgElement = await LegendUtils.generateLegendImageSVG(
      this.exportOptions.document,
      page
    );
    const legendHtml = svgElement.outerHTML;

    const pageHtml = `<div style="position:absolute; height: ${contentSize.height}pt; width: ${contentSize.width}pt">${bodyLayoutHtml}${legendHtml}</div>`;
    const htmlElement = htmlToElement(pageHtml);
    const svgPage = convertHtmlElementToSvgElement(htmlElement);
    return new XMLSerializer().serializeToString(svgPage);
  }

  private async getDiagramAsImage(
    sourceGraph: IGraph,
    exportPage: ExportPage,
    slideRequest: WordExportPageDto,
    subPageIndex: number,
    imageType: ImageType,
    sizeMultiplier: number
  ): Promise<string> {
    const diagramSvg = (
      await ExportService.exportGraphAsSvg(
        this.exportOptions,
        sourceGraph,
        this.exportOptions.withFilters,
        false,
        [ExportPageElementType.Legend]
      )
    ).result as string;

    if (imageType === ImageType.Svg) {
      return diagramSvg;
    }

    const svgElement = htmlToElement(diagramSvg);
    const imageSize = this.getDiagramImageSize(
      exportPage,
      slideRequest,
      subPageIndex,
      sizeMultiplier
    );
    return await toPng(svgElement as unknown as HTMLElement, {
      backgroundColor: 'white',
      width: imageSize.width,
      height: imageSize.height
    });
  }

  private getDiagramImageSize(
    exportPage: ExportPage,
    slideRequest: WordExportPageDto,
    subPageIndex: number,
    sizeMultiplier: number
  ): JSize {
    const titleHeight = slideRequest.showTitle
      ? OpenXmlExportUtils.getPageTitleHeight(
          this.exportOptions.document,
          exportPage,
          0
        )
      : subPageIndex;

    const splitRatio =
      exportPage.page.pageType == DocumentPageType.Split
        ? this.exportOptions.document.pageStyle.splitRatio
        : 1;
    const width =
      (this.exportOptions.document.pageStyle.width -
        slideRequest.pageMargins.left -
        slideRequest.pageMargins.right) *
      sizeMultiplier *
      splitRatio;
    const height =
      (this.exportOptions.document.pageStyle.height -
        slideRequest.pageMargins.top -
        slideRequest.pageMargins.bottom -
        titleHeight) *
      sizeMultiplier;

    return new JSize(width, height);
  }
}
