import humanizeString from 'humanize-string';
import { Color, IGraph, IRectangle, Point, Rect } from 'yfiles';
import { Base64Utils } from '@/core/utils/Base64Utils';
import appConfig from '../config/appConfig';
import i18n from '../plugins/vue-i18n';
import BackgroundDomService from '../services/BackgroundDomService';
import { elementToSVG } from '@jigsaw/dom-to-svg';
import CachingService from '../services/caching/CachingService';
import CacheType from '../services/caching/CacheType';
import ICacheData from '../services/caching/ICacheData';
import { FileDto } from '@/api/models';
import JSize from '@/core/common/JSize';
import JPoint from '@/core/common/JPoint';
import moment from 'moment';
import ExportConfig from '@/core/config/ExportConfig';
import i18nService from '@/core/services/i18n.service';
import { findElementParents } from './html.utils';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import RectLike from '../common/RectLike';
import imurmurhash from 'imurmurhash';
import SparkMD5 from 'spark-md5';

export type ImageResult = {
  src: string;
  height: number;
  width: number;
};

export type ScaleImageResult = ImageResult & {
  cacheKey: string;
  originalUrl: string;
};

export function generateUuid(): string {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function commonDateFormat(date: string): string {
  return moment(date).format('L HH:mm');
}

export function alternativeDateFormat(date: string): string {
  return moment(date).format('L - HH:mm');
}

export function getDateTimeString(date?: string): string {
  return moment(date).format('DD-MM-YYYY-HH-MM');
}

export function downloadTempFile(fileDto: FileDto): void {
  const fileName = fileDto.fileName;
  const fileType = fileDto.fileType;
  const fileToken = fileDto.fileToken;
  const url = `${appConfig.apiBaseUrl}/admin/File/DownloadTempFile?fileType=${fileType}&fileToken=${fileToken}&fileName=${fileName}`;
  window.location.href = url;
}

export function validateUuid(uuid: string): boolean {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid);
}

export function RgbaStringToHex(rgbaString: string): string {
  const rgbaValues = rgbaString.match(/(\d+(\.\d+)?)/g);
  if (!rgbaValues || rgbaValues.length < 3) {
    return null;
  }
  const [r, g, b, a = 1] = rgbaValues.map(parseFloat);
  return RgbaToHex(r, g, b, Math.round(a * 255));
}

export function RgbaToHex(r, g, b, a): string {
  //YFiles uses AARRGGBB
  let hex =
    (a | (1 << 8)).toString(16).slice(1) +
    (r | (1 << 8)).toString(16).slice(1) +
    (g | (1 << 8)).toString(16).slice(1) +
    (b | (1 << 8)).toString(16).slice(1);

  return `#${hex}`;
}

export function HexToRgb(hex: string): Color {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? new Color(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      )
    : null;
}

export function HexToRgbString(hex: string): string {
  const color = HexToRgb(hex);
  if (!color) {
    return null;
  }
  return ColorToRgbString(color);
}

export function RemoveAlphaFromColor(hex: string): string {
  if (hex === null) {
    return '#000000';
  }
  return hex.length > 7 ? '#' + hex.substring(3) : hex;
}

export function GetOpacityFromColor(hex: string): number {
  if (hex === null) {
    return 0;
  }

  if (hex.startsWith('rgb')) {
    const rgbaValues = hex.match(/(\d+(\.\d+)?)/g);
    if (rgbaValues.length === 4) {
      return parseFloat(rgbaValues[3]);
    }
    return 0;
  }

  return hex.length > 7 ? (HexToRgba(hex).a / 255) * 100 : 100;
}

export function HexToRgba(hex: string): Color {
  if (hex?.length < 8) {
    return HexToRgb(hex);
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex
  );
  return result
    ? new Color(
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        parseInt(result[4], 16),
        parseInt(result[1], 16)
      )
    : null;
}

export function ColorToRgbString(color: Color): string {
  if (!color) {
    throw 'Argument cannot be null';
  }
  return `rgb(${color.r},${color.g},${color.b})`;
}

export function RgbToHex(r, g, b): string {
  const hex =
    (r | (1 << 8)).toString(16).slice(1) +
    (g | (1 << 8)).toString(16).slice(1) +
    (b | (1 << 8)).toString(16).slice(1);

  return `#${hex}`;
}

/**
 * Converts enum to an array
 * @param enumType : enum to convert
 * @param translateText: when true, values are translated as translatedText propterty. Otherwise it equals null
 * @returns array of enum
 */

export function getEnumAsArray(
  enumType,
  translateText?: boolean
): Array<{ value: string; text: string; translatedText: string }> {
  return Object.getOwnPropertyNames(enumType)
    .filter((d) => {
      return !isNaN(parseInt(d));
    })
    .map((d) => {
      return {
        value: d,
        text: humanizeString(enumType[d]),
        translatedText: translateText
          ? i18n.t(formatStringForTranslate(enumType[d])).toString()
          : null
      };
    });
}

/**
 * Converts camel case, kebab case, pascal case to the translation key format
 * @param s : String to be formatted
 * @returns string: ready for translation: FORMATTED_STRING
 */
export function formatStringForTranslate(s: string): string {
  let x = humanizeString(s).toUpperCase().replaceAll(' ', '_');
  return x;
}
export function formatString(s: string, ...values: any[]): string {
  for (var arg in values) {
    s = s.replace('{' + arg + '}', values[arg]);
  }
  return s;
}

export function roundToClosest(input: number, nearest: number): number {
  return Math.round(input / nearest) * nearest;
}

export function firstLetterToLowerCase(str: string): string {
  return str[0].toLowerCase() + str.substring(1);
}

export function toTitleCase(text: string): string {
  const conjunctions = ['and', 'or', 'for', 'nor', 'but', 'yet', 'so'];

  if (text) {
    const wordsArray = [];
    const words = text.split(' ');

    words.forEach((word) => {
      if (conjunctions.includes(word)) {
        wordsArray.push(word);
      } else {
        wordsArray.push(word[0].toUpperCase() + word.substr(1));
      }
    });

    return wordsArray.join(' ');
  } else {
    return null;
  }
}

export async function convertSvgToImage(
  src: string,
  outputFormat: string,
  scale = 1
): Promise<ImageResult> {
  const cacheKey = CachingService.generateKey(
    CacheType.ConvertedSvgToImage,
    src,
    outputFormat,
    scale
  );
  return CachingService.getOrSetMutexAsync(cacheKey, () => {
    return new Promise<ICacheData<ImageResult>>((resolve, reject) => {
      const img = new Image();
      if (appConfig.debugMode) {
        img.crossOrigin = 'Anonymous';
      }
      const imageLoaded = () => {
        let dataUrl = src;
        if (
          !src.startsWith('data:image') ||
          src.startsWith('data:image/svg+xml')
        ) {
          const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
          const ctx = canvas.getContext('2d');
          canvas.height = img.height * scale;
          canvas.width = img.width * scale;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          dataUrl = canvas.toDataURL(outputFormat, 1);
        }
        const result: ImageResult = {
          src: dataUrl,
          height: img.height * scale,
          width: img.width * scale
        };
        resolve({ data: result });
      };

      img.src = src;
      if (img.complete) {
        imageLoaded();
      } else {
        img.onload = imageLoaded;
        img.onerror = () => reject();
      }
    });
  });
}

export async function convertSvgElementToImage(
  svgElement: SVGElement,
  outputFormat: string
): Promise<ImageResult> {
  return await convertSvgToImage(
    'data:image/svg+xml;base64,' + Base64Utils.encode(svgElement.outerHTML),
    outputFormat
  );
}

export function convertSvgElementToDataUrl(svgElement: SVGElement): string {
  const svgString = new XMLSerializer().serializeToString(svgElement);
  return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
}

export function convertHtmlElementToSvgElement(
  htmlElement: HTMLElement
): SVGElement {
  BackgroundDomService.appendElement(htmlElement);
  const svgDocument = elementToSVG(htmlElement, {
    defaultFontSize: ExportConfig.defaultContentFontStyle.fontSize + 'pt',
    defaultFontFamily: ExportConfig.defaultContentFontStyle.fontFamily,
    defaultLineHeight: ExportConfig.defaultLineHeight.toString()
  });
  const svgElement = svgDocument.documentElement as unknown as SVGElement;
  fixSvgElementStyles(svgElement);
  BackgroundDomService.removeElement(htmlElement);
  return svgElement;
}

export async function convertUrlToDataUrl(url: string): Promise<string> {
  if (url.startsWith('data:')) {
    return url;
  }
  const cacheKey = CachingService.generateKey(
    CacheType.ConvertedUrlToDataUrl,
    url
  );
  return CachingService.getOrSetMutexAsync(cacheKey, () => {
    return new Promise<ICacheData<string>>((resolve, reject) => {
      fetch(url, { credentials: 'include' })
        .then((response) => {
          const headers = Object.fromEntries(response.headers.entries()) as any;

          response.blob().then((blob) => {
            const reader = new FileReader();
            reader.onload = (): void => {
              // We cannot always assume the response will be a blob, it could be an SVG disguised as a blob
              // we check the headers for the correct content-type
              let dataUrl = reader.result.toString();
              if (
                headers['content-type'] == 'image/svg+xml' &&
                !dataUrl.startsWith('data:image/svg+xml')
              ) {
                dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(
                  dataUrl
                )}`;
              }
              resolve({ data: dataUrl });
            };
            reader.readAsDataURL(blob);
          });
        })
        .catch(() => reject());
    });
  });
}

/**
 *
 * @param imgSrc
 * @param requiresSize When some blobs are downloaded the size is unknown, use this flag to request calculations of the size.
 * If this param is false, the width/height return values may be empty;
 * @returns
 */
export async function convertImageSrcToPng(
  imgSrc: string,
  requiresSize: boolean
): Promise<ImageResult> {
  let width: number = null;
  let height: number = null;

  if (imgSrc.indexOf(appConfig.endpoints.fileDownload) >= 0) {
    imgSrc = await convertUrlToDataUrl(imgSrc);
  }

  // get size of image if PNG or convert SVG to PNG
  if (requiresSize && imgSrc.startsWith('data:image/png')) {
    const imageSize = await getImageSize(imgSrc);
    width = imageSize.width;
    height = imageSize.height;
  } else if (
    imgSrc.startsWith('data:image/svg+xml') ||
    imgSrc.endsWith('.svg')
  ) {
    const result = await convertSvgToImage(imgSrc, 'png');
    imgSrc = result.src;
    width = result.width;
    height = result.height;
  }

  return {
    src: imgSrc,
    width,
    height
  };
}

export async function scaleImage(
  src: string,
  scale: number | ((el: HTMLImageElement) => number)
): Promise<ScaleImageResult> {
  if (appConfig.debugMode) {
    // This is needed in dev env, otherwise 'tainted canvas' error is thrown for cross-origin requests
    src = await convertUrlToDataUrl(src);
  }

  return new Promise<ScaleImageResult>((resolve, reject) => {
    const img = new Image();
    const imageLoaded = async (): Promise<void> => {
      if (typeof scale === 'function') {
        scale = scale(img);
      }
      const cacheKey = CachingService.generateKey(
        CacheType.ScaledImage,
        src,
        scale
      );
      const scaledImage = await CachingService.getOrSetMutexAsync(
        cacheKey,
        async () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width * <number>scale;
          canvas.height = img.height * <number>scale;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const dataUrl = canvas.toDataURL();
          const result: ScaleImageResult = {
            src: dataUrl,
            width: canvas.width,
            height: canvas.height,
            cacheKey: cacheKey,
            originalUrl: src
          };
          return { data: result };
        }
      );
      resolve(scaledImage);
    };

    img.src = src;
    if (img.complete) {
      imageLoaded();
    } else {
      img.onload = imageLoaded;
      img.onerror = (): void => reject();
    }
  });
}

/**
 * Reduces the base64 encoded image string to new with and height.
 * Does not scale up when image is already smaller then width/height
 * @param base64Image
 * @param newWidth
 * @param newHeight
 * @returns newHeight
 */
export async function reduceBase64Image(
  base64Image: string,
  newWidth: number,
  newHeight: number
): Promise<string> {
  const canvas = document.createElement('canvas');
  const img = new Image();
  img.src = base64Image;

  return await new Promise<string>((resolve, reject) => {
    try {
      const imageLoaded = (): void => {
        const imgContainer = BackgroundDomService.createElement('div');

        imgContainer.append(img);
        BackgroundDomService.appendElement(imgContainer);
        const { clientWidth, clientHeight } = imgContainer.querySelector('img');
        BackgroundDomService.removeElement(imgContainer);

        //don't upscale when image is smaller
        if (clientWidth <= newWidth && clientHeight <= newHeight) {
          resolve(base64Image);
        }

        const aspectRatio = clientWidth / clientHeight;
        if (newWidth / newHeight > aspectRatio) {
          canvas.width = newHeight * aspectRatio;
          canvas.height = newHeight;
        } else {
          canvas.width = newWidth;
          canvas.height = Math.round(newWidth / aspectRatio);
        }

        canvas
          .getContext('2d')!
          .drawImage(img, 0, 0, canvas.width, canvas.height);

        resolve(canvas.toDataURL());
      };

      // Wait until logo image is loaded before proceeding
      if (img.complete) {
        imageLoaded();
      } else {
        img.onload = imageLoaded;
        img.onerror = (): void => reject();
      }
    } catch (e) {
      reject(e);
    }
  });
}

export async function getImageSize(src: string): Promise<ImageResult> {
  const cacheKey = CachingService.generateKey(CacheType.ImageSize, src);
  return CachingService.getOrSetMutexAsync(cacheKey, () => {
    return new Promise<ICacheData<ImageResult>>((resolve, reject) => {
      const img = new Image();
      const imageLoaded = () => {
        const result: ImageResult = {
          src: src,
          width: img.width,
          height: img.height
        };
        resolve({ data: result });
      };

      img.src = src;
      if (img.complete) {
        imageLoaded();
      } else {
        img.onload = imageLoaded;
        img.onerror = () => reject();
      }
    });
  });
}

export function splitArrayIntoChunks<T>(
  array: T[],
  chunkSize: number,
  rowCount?: number
): T[][] {
  if (chunkSize <= 0) {
    return [array];
  }
  const output = new Array<T[]>();
  let arrayIndex = 0;
  while (arrayIndex < array.length) {
    output.push(array.slice(arrayIndex, (arrayIndex += chunkSize)));
  }

  if (rowCount && rowCount > 0) {
    return output.slice(0, rowCount);
  }
  return output;
}

export function selectText(containerId) {
  if ((document as any).selection) {
    // IE
    const range = (document.body as any).createTextRange();
    range.moveToElementText(document.getElementById(containerId));
    range.select();
  } else if (window.getSelection) {
    const range = document.createRange() as any;
    range.selectNode(document.getElementById(containerId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

export function stringsEqualCI(a: string, b: string): boolean {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
    : a === b;
}

export function stringContainsCI(str: string, match: string): boolean {
  return typeof str === 'string' && typeof match === 'string'
    ? str.toLowerCase().includes(match.toLowerCase())
    : false;
}

export function randomNumber(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function randomRgb(includeAlpha?: boolean): Color {
  const r: number = randomNumber(255);
  const g: number = randomNumber(255);
  const b: number = randomNumber(255);
  const a: number = includeAlpha ? Math.random() : 1;

  return new Color(r, g, b, a);
}

export function randomRgbString(): string {
  const rgb = randomRgb();
  return ColorToRgbString(rgb);
}

export function getTextElementWidthPx(el: HTMLElement, text: string): number {
  if (!el) return 0;
  let fontSizeStr = window
    .getComputedStyle(el, null)
    .getPropertyValue('font-size');
  let fontSize = parseFloat(fontSizeStr);
  let fontFamily = window
    .getComputedStyle(el, null)
    .getPropertyValue('font-family');
  let font = fontSize + 'px ' + fontFamily;
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  context.font = font;
  let width = context.measureText(text).width;
  let formattedWidth = Math.ceil(width);
  return formattedWidth;
}

/**
 * Fits the rectangle into specified boundaries both vertically & horizontally
 */
export function fitRectIntoBounds(rect: JSize, bounds: JSize): JSize {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const newDimensions = JSize.EMPTY;

  // Rect is more landscape than bounds - fit to width
  if (rectRatio > boundsRatio) {
    newDimensions.width = bounds.width;
    newDimensions.height = rect.height * (bounds.width / rect.width);
  }
  // Rect is more portrait than bounds - fit to height
  else {
    newDimensions.width = rect.width * (bounds.height / rect.height);
    newDimensions.height = bounds.height;
  }
  return newDimensions;
}

/**
 * Scales the rectangle to match the aspect ratio and minimum size of the specified boundaries
 */
export function scaleRectIntoBounds(rect: JSize, bounds: JSize): JSize {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const newDimensions = JSize.EMPTY;

  // Rect is more landscape than bounds - scale to width
  if (rectRatio > boundsRatio) {
    newDimensions.width = Math.max(bounds.width, rect.width);
    newDimensions.height = newDimensions.width * (bounds.height / bounds.width);
  }
  // Rect is more portrait than bounds - scale to height
  else {
    newDimensions.height = Math.max(bounds.height, rect.height);
    newDimensions.width = newDimensions.height * (bounds.width / bounds.height);
  }
  return newDimensions;
}

export function scaleAndCropToBounds(
  size: JSize | { width: number; height: number },
  bounds: JSize
): JSize {
  let newWidth = 0;
  let newHeight = 0;
  if (size.width > size.height) {
    let scale = bounds.height / size.height;
    newWidth = size.width * scale;
    newHeight = bounds.height;
  } else {
    let scale = bounds.width / size.width;
    newHeight = size.height * scale;
    newWidth = bounds.width;
  }
  return new JSize(newWidth, newHeight);
}

export function mergeRects(rects: RectLike[]): Rect {
  if (!rects?.length) {
    return Rect.EMPTY;
  }
  let minX: number = Number.POSITIVE_INFINITY;
  let minY: number = Number.POSITIVE_INFINITY;
  let maxX: number = Number.NEGATIVE_INFINITY;
  let maxY: number = Number.NEGATIVE_INFINITY;

  for (const rect of rects) {
    if (rect.x < minX) {
      minX = rect.x;
    }
    if (rect.y < minY) {
      minY = rect.y;
    }
    if (rect.x + rect.width > maxX) {
      maxX = rect.x + rect.width;
    }
    if (rect.y + rect.height > maxY) {
      maxY = rect.y + rect.height;
    }
  }
  return new Rect(new Point(minX, minY), new Point(maxX, maxY));
}

export function ensureFullUri(segment: string): string {
  if (!segment || segment.startsWith('http') || segment.startsWith('data:')) {
    return segment;
  }

  return buildAdminUrl(segment);
}

export function buildAdminUrl(
  segment: string = '',
  query: string = ''
): string {
  let root = appConfig.apiBaseUrl as string;
  if (!root.endsWith('/')) {
    root = `${root}/`;
  }

  if (segment && segment.startsWith('/')) {
    segment = segment.substring(1);
  }
  if (query && !query.startsWith('?')) {
    query = `?${query}`;
  }

  return `${root}${segment}${query}`;
}

export async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(',')[1];
      resolve(base64);
    };
    reader.onerror = () => {
      reject();
    };
  });
}

export function plainTextToHtml(text: string): string {
  text = text
    // Encode <>.
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    // Creates a paragraph for each double line break.
    .replace(/\r?\n\r?\n/g, '</p><p>')
    // Creates a line break for each single line break.
    .replace(/\r?\n/g, '<br>')
    // Preserve trailing spaces (only the first and last one – the rest is handled below).
    .replace(/^\s/, '&nbsp;')
    .replace(/\s$/, '&nbsp;')
    // Preserve other subsequent spaces now.
    .replace(/\s\s/g, ' &nbsp;');

  if (text.includes('</p><p>') || text.includes('<br>')) {
    // If we created paragraphs above, add the trailing ones.
    text = `<p>${text}</p>`;
  }

  return text;
}

export function fixSvgElementStyles(svgElement: SVGElement): void {
  const propertyMap = [
    {
      name: 'textDecorationLine',
      attributeName: 'text-decoration'
    }
  ];
  const textElements = svgElement.querySelectorAll('text');

  for (const textElement of textElements) {
    const style = textElement.style;

    for (const prop of propertyMap) {
      const value = style[prop.name];
      if (value == '') {
        continue;
      }
      textElement.setAttribute(prop.attributeName, value);
    }

    // Apply text decoration attribute based on parent elements
    const textDecorations = [];
    const parents = findElementParents(textElement);
    const hasUnderline = parents.some((p) => p.dataset.tag == 'u');
    const hasStrikethrough = parents.some((p) => p.dataset.tag == 's');
    if (hasUnderline) {
      textDecorations.push('underline');
    }
    if (hasStrikethrough) {
      textDecorations.push('line-through');
    }

    if (textDecorations.length > 0) {
      style.textDecorationLine = textDecorations.join(' ');
      textElement.setAttribute('text-decoration', textDecorations.join(' '));
    }

    textElement.setAttribute('text-rendering', 'optimizeSpeed');

    textElement.innerHTML = textElement.innerHTML
      .replaceAll(ZERO_WIDTH_SPACE, '')
      // Replace non-breaking hyphens with regular hyphens (PDF can't render the former)
      .replaceAll('\u2011', '-');
  }

  // needed for visio export
  // seems like during the export vsdx if see "stroke" attribute always render it (even if stroke-width is 0)
  const rootBackgroundAndBordersElements = svgElement.querySelectorAll(
    '[data-stacking-layer="rootBackgroundAndBorders"]'
  );
  for (const el of rootBackgroundAndBordersElements) {
    const rect = el.firstElementChild;

    if (rect) {
      const strokeWidth = rect.getAttribute('stroke-width');

      if (strokeWidth === '0px' || strokeWidth === '0') {
        rect.removeAttribute('stroke');
        rect.removeAttribute('stroke-width');
      }
    }
  }

  // Remove unnecessary clip-paths (they break PDF, PNG and other exports)
  svgElement.querySelectorAll('clipPath').forEach((x) => {
    if (x.parentElement && x.parentElement.id === 'header') {
      return;
    }
    x.remove();
  });
  svgElement.querySelectorAll('[clip-path]').forEach((x) => {
    if (x.id === 'header') {
      return;
    }
    x.removeAttribute('clip-path');
  });
}

export function postForm(url: string, params: any): void {
  const formElement = document.createElement('form');
  formElement.classList.add('hidden');
  formElement.setAttribute('method', 'post');
  formElement.setAttribute('action', url);

  for (let param in params) {
    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', param);
    hiddenField.setAttribute('value', params[param]);
    formElement.appendChild(hiddenField);
  }

  document.body.appendChild(formElement);
  formElement.submit();
}

export function unixEpoch(): number {
  return ~~(+new Date() / 1000);
}

export function appendUrl(baseUrl: string, path: string): string {
  if (!baseUrl.endsWith('/') && !path.startsWith('/')) {
    return `${baseUrl}/${path}`;
  }
  return `${baseUrl}${path}`;
}

export function calculateHash(value: any): string {
  if (!value) {
    return null;
  }
  const data =
    typeof value == 'object' ? JSON.stringify(value) : value.toString();
  return imurmurhash(data).result().toString();
}

/**
 * Used for saving & loading legacy LegendDefinition, do not change/remove
 */
export function calculateHashOld(value: any): string {
  if (!value) {
    return null;
  }
  const data =
    typeof value == 'object' ? JSON.stringify(value) : value.toString();
  return SparkMD5.hash(data);
}

export function wait(timeMs: number = 0): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMs);
  });
}

export async function waitUntil(
  condition: () => boolean,
  timeoutMs: number,
  checkIntervalMs: number = 50
): Promise<boolean> {
  if (condition()) {
    return true;
  }
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < timeoutMs) {
    if (condition()) {
      return true;
    }
    await wait(checkIntervalMs);
  }
  return false;
}

export function elementsCollide(el1: HTMLElement, el2: HTMLElement) {
  if (!el1 || !el2) return false;

  const rect1 = el1.getBoundingClientRect();
  const rect2 = el2.getBoundingClientRect();

  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  );
}

export function hasOwnProperty(obj: object, key: string) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export function hasOwnProperties(obj: object, keys: string[]) {
  return keys.every((key) => Object.prototype.hasOwnProperty.call(obj, key));
}

export function isVNode(node): boolean {
  return (
    node !== null &&
    typeof node === 'object' &&
    hasOwnProperty(node, 'componentOptions')
  );
}

export function nonReactive(value: any): any {
  // Set dummy observer on value
  value.__v_skip = true;
  return value;
}

export function isPrimitive(arg): boolean {
  const valueType = typeof arg;
  return arg === null || (valueType !== 'object' && valueType !== 'function');
}

export const seedRandom = (seed: number): number => {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

export function copyToClipboard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value);
}

/**
 *
 * @param {object} obj An object to lookup
 * @param path A dot notation path (e.g: "obj.a.b")
 * @returns The value that was found in specified path
 */
export const getNestedProp = (obj: object, path: string) => {
  if (!isObject(obj)) {
    return undefined;
  }

  const arr: Array<string> = path.split('.');

  while (arr.length && obj) {
    const shift = arr.shift();
    if (shift) obj = obj?.[shift];
  }

  return obj as any;
};

export const isObject = (obj: object) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const formatCsvString = (value: string) => {
  if (!value) {
    return '';
  }

  return value
    .split(',')
    .filter((x) => x.trim())
    .join(', ');
};

/**
 *
 * @param obj The object to flatten
 * @param next A function to select the next object
 * @param selector A function to create the item to be added to the array
 * @param acc The accumulator, should be null on first call.
 * @returns
 */
export function flattenObject(
  obj: any,
  next: (a) => any,
  selector: (a) => any,
  acc: any[] = null
): any[] {
  if (!acc) {
    acc = [selector(obj)];
  }
  var nested = next(obj);
  if (nested) {
    acc.push(selector(nested));
    flattenObject(nested, next, selector, acc);
  }
  return acc;
}

/**
 * Split Camel or Kebab case to a string with space between words
 * @param input The camel or kebab case string that will be split

 * @returns String with a space between words
 */
export function camelToHuman(input) {
  return input.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getImageType(data: string) {
  if (!data) return '';
  return data.split(';')[0].split(':')[1];
}

export function extractFileIdFromImageSrc(imageSrc: string): string {
  const regex = /\?id=([\w\d-]+)/;
  const result = regex.exec(imageSrc);
  if (result?.length > 1) {
    return result[1];
  }
  return null;
}

/**
 * Find the 3rd apex of isosceles triangle by given height and two other apexs
 * https://lucidar.me/en/mathematics/how-to-calculate-the-intersection-points-of-two-circles/
 */
export function getApexOfIsoscelesTriangle(
  apex1: { x: number; y: number },
  apex2: { x: number; y: number },
  height: number
) {
  // Lenght of base side
  const baseLineLength = Math.sqrt(
    Math.pow(apex1.x - apex2.x, 2) + Math.pow(apex1.y - apex2.y, 2)
  );

  // Center point of base side line
  const baseLineCenterX =
    apex2.x + (baseLineLength / 2 / baseLineLength) * (apex1.x - apex2.x);
  const baseLineCenterY =
    apex2.y + (baseLineLength / 2 / baseLineLength) * (apex1.y - apex2.y);

  // 3rd apex coordinate
  const apex3x =
    baseLineCenterX - (height * (apex1.y - apex2.y)) / baseLineLength;
  const apex3y =
    baseLineCenterY + (height * (apex1.x - apex2.x)) / baseLineLength;

  return new JPoint(apex3x, apex3y);
}

export function intersects(a: RectLike, b: RectLike): boolean {
  if (a.x >= b.x + b.width || b.x >= a.x + a.width) {
    return false;
  }

  if (a.y >= b.y + b.height || b.y >= a.y + a.height) {
    return false;
  }

  return true;
}

export function intersectsMultiple(a: RectLike, bArray: RectLike[]): boolean {
  return bArray.some((b) => intersects(a, b));
}
/**
 * Get rough size of an object in bytes
 */
export function getObjectSize(object: Object): number {
  const objectList = new Set<Object>();
  const recurse = (value: any): number => {
    let bytes = 0;
    if (typeof value === 'boolean') bytes = 4;
    else if (typeof value === 'string') bytes = value.length * 2;
    else if (typeof value === 'number') bytes = 8;
    else if (typeof value === 'object' && !objectList.has(value)) {
      objectList.add(value);
      for (let key in value) {
        bytes += 8;
        bytes += recurse(value[key]);
      }
    }
    return bytes;
  };
  return recurse(object);
}

export function convertPointsToInches(input: number): number {
  return input / ExportConfig.inchToPointFactor;
}

export function convertPointsToCm(input: number): number {
  return input / ExportConfig.centimeterToPointFactor;
}

export function convertCmToPoints(input: number): number {
  return input * ExportConfig.centimeterToPointFactor;
}

export function convertInchesToPoints(input: number): number {
  return input * ExportConfig.inchToPointFactor;
}

export function convertPointsToCurrentMeasurement(value: number): number {
  switch (i18nService.getActiveLanguage()) {
    case appConfig.supportedLocales.enUS:
      return Number(convertPointsToInches(value).toFixed(1));
    default:
      return Number(convertPointsToCm(value).toFixed(1));
  }
}

export function convertCurrentMeasurementToPoints(value: number): number {
  switch (i18nService.getActiveLanguage()) {
    case appConfig.supportedLocales.enUS:
      return Number(convertInchesToPoints(value).toFixed(1));
    default:
      return Number(convertCmToPoints(value).toFixed(1));
  }
}

// get measurement depending on the selected language
export function getCurrentMeasurementUnit(): string {
  switch (i18nService.getActiveLanguage()) {
    case appConfig.supportedLocales.enUS:
      return '″';
    default:
      return 'cm';
  }
}

export function shallowCompare(obj1: Object, obj2: Object): boolean {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(
      (key) => hasOwnProperty(obj2, key) && obj1[key] === obj2[key]
    )
  );
}

export function isMacOS(): boolean {
  return navigator.userAgent.indexOf('Mac') !== -1;
}

export function tEnvironment(key: string): string {
  return isMacOS() ? i18n.t(`${key}_CMD`) : i18n.t(`${key}_CTRL`);
}

export function roundDown(num: number, decimalPlaces: number): number {
  const pow = Math.pow(10, decimalPlaces);
  return +((num * pow) / pow).toFixed(decimalPlaces);
}

export function checkElementTag(
  element: HTMLElement,
  tagName: string
): boolean {
  return element.tagName.toLowerCase() == tagName;
}

export function destroyObject(obj: Object): void {
  if (obj) {
    Object.keys(obj).forEach((key) => delete obj[key]);
  }
}

// Encoding UTF8 ⇢ base64

export function b64EncodeUnicode(str): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}

// Decoding base64 ⇢ UTF8

export function b64DecodeUnicode(str): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

// Delay cb execution to make sure we do not block any visual updates
export function doubleRaf<T = void>(cb: () => T): void {
  if (!cb || typeof cb !== 'function') {
    return;
  }

  requestAnimationFrame(() => {
    requestAnimationFrame(cb);
  });
}

export function oncePageVisible(cb: () => void): void {
  if (document.visibilityState === 'visible') {
    cb();
    return;
  }

  const handler = () => {
    if (document.visibilityState === 'visible') {
      cb();
      document.removeEventListener('visibilitychange', handler);
    }
  };

  document.addEventListener('visibilitychange', handler);
}

export function ensureValidUrl(url: string): string {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  } else {
    return `https://${url}`;
  }
}

export function firstUndefined<T extends any>(...values: T[]): T | undefined {
  return values[values.findIndex((x: T): boolean => x !== undefined)];
}

export function sortArrayBy(
  array: Array<any>,
  key: string,
  direction: 'ascending' | 'descending' = 'ascending'
): Array<any> {
  return array.sort((a, b) => {
    const itemA = a[key] ?? '';
    const itemB = b[key] ?? '';
    if (direction === 'ascending') {
      return itemA.localeCompare(itemB);
    } else {
      return itemB.localeCompare(itemA);
    }
  });
}

export function getScreenResolution(): JSize {
  const width = window.screen.width * window.devicePixelRatio;
  const height = window.screen.height * window.devicePixelRatio;
  return new JSize(width, height);
}

export function executeAfterTicks(func: Function, ticks: number) {
  if (ticks <= 0) {
    func();
  } else {
    setTimeout(() => executeAfterTicks(func, ticks - 1), 0);
  }
}

export async function performNonUndoableOperation(
  operation: () => void,
  graph: IGraph
) {
  const compoundEdit = graph.undoEngine.beginCompoundEdit('', '');

  await operation();
  compoundEdit.cancel();
}

export function locationHost(): string {
  return window.location.host;
}

export function locationOrigin(): string {
  return window.location.origin;
}

export function arraysEqual(a: any[], b: any[]): boolean {
  return a && b && a.length === b.length && a.every((el, ix) => el === b[ix]);
}

export function findClosestIndexByFontSize(
  number: number,
  availableFontSizes: number[]
): number {
  const index = availableFontSizes.indexOf(number);

  if (index >= 0) {
    return index;
  }

  const closest = availableFontSizes.reduce((prev, curr) =>
    Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev
  );

  return availableFontSizes.indexOf(closest);
}

export function convertNumberToString(
  value: number,
  decimalPlaces: number
): string {
  if (Number.isInteger(value)) {
    return value.toString();
  } else {
    return value.toFixed(decimalPlaces);
  }
}
