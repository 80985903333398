import axios, {
    AxiosResponse
} from 'axios';
import {
    CurrentUserProfileEditDto,
    ZadarResponse,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    VerifySmsCodeInputDto,
    ChangePasswordInput,
    UpdateProfilePictureInput,
    GetPasswordComplexitySettingOutput,
    GetProfilePictureOutput,
    ChangeUserLanguageDto
} from './models'
export default class ProfileApiService {
    public static getCurrentUserProfileForEdit(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CurrentUserProfileEditDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/GetCurrentUserProfileForEdit`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static disableGoogleAuthenticator(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/DisableGoogleAuthenticator`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static updateGoogleAuthenticatorKey(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UpdateGoogleAuthenticatorKeyOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/UpdateGoogleAuthenticatorKey`,
            method: 'put',
            clientConfig: clientConfig
        })
    }
    public static sendVerificationSms(body: SendVerificationSmsInputDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/SendVerificationSms`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static verifySmsCode(body: VerifySmsCodeInputDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/VerifySmsCode`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static prepareCollectedData(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/PrepareCollectedData`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static updateCurrentUserProfile(body: CurrentUserProfileEditDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/UpdateCurrentUserProfile`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static changePassword(body: ChangePasswordInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/ChangePassword`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateProfilePicture(body: UpdateProfilePictureInput, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/UpdateProfilePicture`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getPasswordComplexitySetting(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetPasswordComplexitySettingOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/GetPasswordComplexitySetting`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getProfilePicture(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetProfilePictureOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/GetProfilePicture`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getFriendProfilePictureById(params ? : {
        profilePictureId ? : string,
        userId ? : number,
        tenantId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetProfilePictureOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/GetFriendProfilePictureById`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getProfilePictureById(params ? : {
        profilePictureId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetProfilePictureOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/GetProfilePictureById`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static changeLanguage(body: ChangeUserLanguageDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Profile/ChangeLanguage`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}