import get from 'lodash/get';
import {
  EventCondition,
  StepAction,
  TutorialStep
} from '@/core/services/tutorial/ITutorial';
import isNil from 'lodash/isNil';
import TutorialActionMediator from '@/core/services/tutorial/TutorialActionMediator';

type ActionEventListener = (evt: any) => void;

export default class TutorialStepActionHandler {
  private static readonly keySeparator = '---';
  private static events = new Map();

  // To allow registering the same event for different actions
  private static buildKey(action: StepAction, index: number): string {
    return `${action.event.name}${TutorialStepActionHandler.keySeparator}${index}`;
  }

  private static hasEvent(action: StepAction, index: number): boolean {
    return TutorialStepActionHandler.events.has(
      TutorialStepActionHandler.buildKey(action, index)
    );
  }

  private static register(
    action: StepAction,
    index: number,
    listener: ActionEventListener
  ): void {
    if (!TutorialStepActionHandler.hasEvent(action, index)) {
      TutorialStepActionHandler.events.set(
        TutorialStepActionHandler.buildKey(action, index),
        listener
      );
    }
  }

  private static unregister(action: StepAction, index: number): void {
    const key = TutorialStepActionHandler.buildKey(action, index);
    if (TutorialStepActionHandler.events.has(key)) {
      TutorialStepActionHandler.events.delete(key);
    }
  }

  private static isNilOrEmpty(value: any): boolean {
    return isNil(value) || value === '';
  }

  private static compare(action: StepAction, value: unknown): boolean {
    // Any value would be ok
    if (TutorialStepActionHandler.isNilOrEmpty(action.event.value)) {
      return true;
    }

    switch (action.event.condition?.value) {
      case EventCondition.NotEqual:
        return value !== action.event.value;
      case EventCondition.LessThan:
        return value < action.event.value;
      case EventCondition.LessThanOrEqual:
        return value <= action.event.value;
      case EventCondition.GreaterThan:
        return value > action.event.value;
      case EventCondition.GreaterThanOrEqual:
        return value >= action.event.value;
      case EventCondition.Between:
        if (Array.isArray(action.event.value)) {
          return (
            value >= action.event.value[0] && value <= action.event.value[1]
          );
        }
        throw new TypeError('Value must be an array');
      default:
        return action.event.value === value;
    }
  }

  public static processAction(
    step: TutorialStep,
    actionIndex: number,
    doneCallback: (actionIndex: number) => void
  ): void {
    const action = step.stepActions[actionIndex];

    if (TutorialStepActionHandler.hasEvent(action, actionIndex)) {
      return;
    }

    const listener: ActionEventListener = (evt: any): void => {
      if (TutorialStepActionHandler.compare(action, evt)) {
        action.progress += 1;
      }

      if (action.progress >= action.repeatCount) {
        TutorialActionMediator.$off(action.event.name, listener);
        const nextAction = step.stepActions[actionIndex + 1];
        if (nextAction) {
          TutorialStepActionHandler.unregister(action, actionIndex);
          TutorialStepActionHandler.processAction(
            step,
            actionIndex + 1,
            doneCallback
          );
        } else {
          doneCallback(actionIndex);
        }
      }
    };

    TutorialActionMediator.$on(action.event.name, listener);
    TutorialStepActionHandler.register(action, actionIndex, listener);
  }

  public static unregisterAllEvents(): void {
    TutorialStepActionHandler.events.forEach((_, key: string) => {
      const [eventName] = key.split(TutorialStepActionHandler.keySeparator);
      TutorialActionMediator.$off(
        eventName,
        TutorialStepActionHandler.events.get(key)
      );
    });
    TutorialStepActionHandler.events.clear();
  }
}
