<template>
  <div class="w-full">
    <AppDropdown placement="top-start" activator-class="w-full" min-width="240">
      <SidebarProfile
        class="flex h-60 w-full cursor-pointer items-center px-20 transition duration-200 hover:bg-accent-purple"
        data-automation-id="sidebar-open-profile"
        slot="activator"
      />

      <!-- Language dropdown -->
      <AppDropdown placement="right-end" :offset="2" activator-class="w-full">
        <template #activator="{ isVisible }">
          <AppDropdownItem
            class="flex items-center"
            :class="{
              'bg-main-dark-10': isVisible
            }"
          >
            <AppIcon name="language" class="mr-16 text-main-dark" />
            <div class="flex w-full items-center justify-between">
              {{ activeLanguageDisplayName }}

              <div class="flex -rotate-90 transform">
                <AppIcon
                  name="arrow-drop-down"
                  size="20"
                  class="text-main-dark"
                />
              </div>
            </div>
          </AppDropdownItem>
        </template>

        <AppDropdownItem
          v-for="language in languages"
          :key="language.name"
          :class="{
            'bg-main-dark-10': isSelectedLanguage(language)
          }"
          @click="setLanguage(language)"
        >
          {{ language.displayName }}
        </AppDropdownItem>
      </AppDropdown>
      <!-- Language dropdown -->

      <AppDropdownItem
        class="flex items-center"
        @click="changePassword"
        v-if="!currentUser.authenticationSource"
      >
        <AppIcon name="key" class="mr-16 text-main-dark" />
        {{ $t('CHANGE_PASSWORD') }}
      </AppDropdownItem>

      <AppDropdownItem class="flex items-center" @click="onLogoutHandler">
        <AppIcon name="logout" class="mr-16 text-main-dark" />
        {{ $t('LOGOUT') }}
      </AppDropdownItem>
    </AppDropdown>

    <AppVersion class="mt-10 px-28" />

    <AppUpdatePasswordModal v-model="isChangePasswordModalVisible" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { LogoutConfirmationConfig } from '@/core/config/LogoutConfirmationConfig';
import DocumentService from '@/core/services/document/DocumentService';

import UserLanguageMixin from '@/mixins/UserLanguage.mixin';

import AppVersion from '@/view/layout/AppVersion.vue';
import AppUpdatePasswordModal from '@/view/modals/AppUpdatePasswordModal.vue';
import SidebarProfile from './SidebarProfile.vue';

import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import { CurrentUserProfileEditDto } from '@/api/models';
import { Getter } from 'vuex-class';

@Component({
  name: 'SidebarProfileDropdown',
  components: {
    AppVersion,
    SidebarProfile,
    AppUpdatePasswordModal
  }
})
export default class AppSidebar extends mixins(UserLanguageMixin) {
  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  isChangePasswordModalVisible: boolean = false;

  changePassword() {
    this.isChangePasswordModalVisible = true;
  }

  onLogoutHandler() {
    this.$confirm({
      title: this.$t(LogoutConfirmationConfig.config.title).toString(),
      text: this.$t(LogoutConfirmationConfig.content).toString(),
      okText: this.$t(LogoutConfirmationConfig.config.okTitle),
      denyText: this.$t(LogoutConfirmationConfig.config.cancelTitle)
    }).then((result) => {
      if (result) {
        DocumentService.closeDocument().finally(() => {
          this.$router.push({
            name: 'logout'
          });
        });
      }
    });
  }
}
</script>
