import ExportConfig from '@/core/config/ExportConfig';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';

export enum RibbonElements {
  Ownership = '[rb-name="ownership"]',
  MenuFileSaveExportFunctionsExport = '[rb-name="menu:file-save-export-functions-export"]',
  MenuFileSaveExportFunctionsSave = '[rb-name="menu:file-save-export-functions-save"]',
  FormatPainter = '[rb-name="format-painter"]',
  Copy = '[rb-name="copy"]',
  Cut = '[rb-name="cut"]',
  Paste = '[rb-name="paste"]',
  StepFunctions = '[rb-name="step-functions"]',
  ExtractSelection = '[rb-name="extract-selection"]',
  DocumentView = "[rb-name='document-view']",
  CKEditorToolbar = "div[rb-name='ckeditor-toolbar']",
  StepsPageFunctions = "[rb-name='steps-page-functions']",
  TrackChanges = "[rb-name='track-changes']",
  StepsFlipBook = "[rb-name='steps-flipbook']",
  CKEditorLink = "[rb-name='ckeditor-link']",
  Print = "[rb-name='print']",
  Undo = "[rb-name='undo']",
  Redo = "[rb-name='redo']",
  DataPanel = "[rb-name='data-panel']",
  PinContainer = '.pin-container'
}

export enum StickySidebarButtons {
  Text = '[data-automation-id="palette-open-text"]',
  Shapes = '[data-automation-id="palette-open-shapes"]',
  Lines = '[data-automation-id="palette-open-lines"]',
  Logo = '[data-automation-id="palette-toggle-logo"]',
  Legend = '[data-automation-id="palette-toggle-legend"]',
  Themes = '[data-automation-id="palette-open-themes"]'
}

export default class UIService {
  private static cache: Map<string, Element> = new Map();

  private static get disabledClass(): string[] {
    return ExportConfig.disabledElementClass.split(' ');
  }

  private static getElement(
    parent: Element,
    selector: string,
    disabled: boolean,
    skipElements: string[]
  ): Element | null {
    let element: Element;
    if (UIService.cache.has(selector)) {
      element = UIService.cache.get(selector);
      if (
        disabled &&
        element.classList.contains(UIService.disabledClass[0]) &&
        element.classList.contains(UIService.disabledClass[1]) &&
        !skipElements.includes(selector)
      ) {
        // The element already has disabled state
        return null;
      }
    } else {
      element = (parent ?? document).querySelector(selector);
      UIService.cache.set(selector, element);
    }

    return element;
  }

  private static processElements(
    parent: Element,
    collection: Record<string | number, string>,
    disabled: boolean,
    skipElements: string[] = []
  ): void {
    Object.keys(collection).forEach((key) => {
      if (Number.isInteger(parseInt(key, 10))) {
        return;
      }

      const element = UIService.getElement(
        parent,
        collection[key],
        disabled,
        skipElements
      );

      if (!element) return;

      UIService.setState(
        element,
        disabled && !skipElements.includes(collection[key])
      );
    });
  }

  public static setState(element: Element, disabled: boolean): void {
    element.classList.remove(...UIService.disabledClass);
    if (disabled) {
      element.classList.add(...UIService.disabledClass);
    }
  }

  public static destroy(): void {
    UIService.toggleDisabledStateForTrackedElements(false);
    UIService.cache.clear();
  }

  public static toggleDisabledStateForTrackedElements(disabled: boolean): void {
    UIService.toggleRibbonItems(disabled);
    UIService.toggleStickySidebarItems(disabled);
    UIService.toggleSidebar(disabled);
    UIService.toggleDocumentControls(disabled);
  }

  public static toggleRibbonItems(
    disabled: boolean,
    skipElements?: RibbonElements[]
  ): void {
    const ribbon = document.querySelector(`.${ExportConfig.ribbonClass}`);
    UIService.processElements(ribbon, RibbonElements, disabled, skipElements);
  }

  public static toggleStickySidebarItems(
    disabled: boolean,
    skipElements?: StickySidebarButtons[]
  ): void {
    const stickySidebar = document.getElementById(ExportConfig.stickySidebarId);
    UIService.processElements(
      stickySidebar,
      StickySidebarButtons,
      disabled,
      skipElements
    );
  }

  public static toggleSidebar(disabled: boolean): void {
    EventBus.$emit(EventBusActions.DISABLE_DIAGRAM_PALETTE, disabled);
    EventBus.$emit(EventBusActions.DISABLE_DOCUMENT_SIDEBAR, disabled);
  }

  public static toggleDocumentControls(disabled: boolean): void {
    EventBus.$emit(EventBusActions.DISABLE_DOCUMENT_TOOLBAR, disabled);
  }

  public static toggleContextMenuItemsDisabledState(
    skipElements?: string[]
  ): void {
    const menu = document.querySelector('.contextmenu');
    if (menu) {
      Array.from(menu.children).forEach((child) => {
        if (skipElements.includes(child.getAttribute('data-automation-id'))) {
          return;
        }
        child.classList.add(...UIService.disabledClass);
      });
    }
  }
}
