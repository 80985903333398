import { DashStyleType, EdgeVisualType, FillType } from '@/api/models';
import { ArrowElementType } from '@/core/common/ArrowElementType';

export default [
  {
    category: 'arrows',
    imagePath: '/media/svg/arrows/arrow-1.svg',
    id: 190001,
    name: ArrowElementType.StraightArrow,
    subcategory: 'Arrows',
    style: {
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid
        },
        fill: {
          color: '#000000',
          type: FillType.SolidColor
        },
        thickness: 1
      },
      sourceArrow: {
        scale: 2,
        type: 'none'
      },
      targetArrow: {
        scale: 2,
        type: 'triangle'
      }
    }
  },
  {
    category: 'arrows',
    imagePath: '/media/svg/arrows/arrow-3.svg',
    id: 190003,
    name: ArrowElementType.ArcArrow,
    subcategory: 'Arrows',
    style: {
      visualType: EdgeVisualType.Arc,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid
        },
        fill: {
          color: '#000000',
          type: FillType.SolidColor
        },
        thickness: 1
      },
      sourceArrow: {
        scale: 2,
        type: 'none'
      },
      targetArrow: {
        scale: 2,
        type: 'triangle'
      }
    }
  }
];
