import {
  IInputModeContext,
  Insets,
  Point,
  ShapeNodeShape,
  ShapeNodeStyleRenderer
} from 'yfiles';
import { NodeShape } from '@/api/models';
import config from '@/core/config/diagram.definition.config';
import SystemEntityTypes from '../services/corporate/SystemEntityTypes';

export class JigsawShapeNodeStyleRenderer extends ShapeNodeStyleRenderer {
  private readonly epsilon = 1e-8;
  constructor(private nodeShape: NodeShape) {
    super();
  }

  protected configure(): void {
    this.setRadius();
    super.configure();
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    if (
      this.node.tag.name !== SystemEntityTypes.EDGE_TO_NOWHERE_NODE &&
      this.node.tag.name !== SystemEntityTypes.ARROW_NODE
    ) {
      return super.isHit(context, location);
    }

    const largerLayout = this.node.layout
      .toRect()
      .getEnlarged(new Insets(25, 25, 25, 25));

    return largerLayout.contains(location);
  }

  setRadius() {
    if (config.nodeRadiusFlexRatios[this.shape]) {
      this.roundRectArcRadius = Math.min(
        this.layout.width * config.nodeRadiusFlexRatios[this.shape],
        config.maxNodeEdgeRadius
      );
    }
  }

  isInside(location: Point): boolean {
    const shape = this.getShape();
    if (shape == ShapeNodeShape.TRIANGLE) {
      return this.isInsideTriangle(location);
    } else if (shape == ShapeNodeShape.TRIANGLE2) {
      return this.isInsideTriangle2(location);
    }

    return super.isInside(location);
  }

  private isInsideTriangle(location: Point): boolean {
    const nodeLayout = this.layout;
    if (
      this.isWithinEpsilon(
        location,
        new Point(nodeLayout.center.x, nodeLayout.y)
      )
    ) {
      return true;
    }
    if (
      this.isWithinEpsilon(location, new Point(nodeLayout.x, nodeLayout.maxY))
    ) {
      return true;
    }

    if (
      this.isWithinEpsilon(
        location,
        new Point(nodeLayout.maxX, nodeLayout.maxY)
      )
    ) {
      return true;
    }

    return super.isInside(location);
  }

  private isInsideTriangle2(location: Point): boolean {
    const nodeLayout = this.layout;
    if (
      this.isWithinEpsilon(
        location,
        new Point(nodeLayout.center.x, nodeLayout.maxY)
      )
    ) {
      return true;
    }
    if (this.isWithinEpsilon(location, new Point(nodeLayout.x, nodeLayout.y))) {
      return true;
    }

    if (
      this.isWithinEpsilon(location, new Point(nodeLayout.maxX, nodeLayout.y))
    ) {
      return true;
    }

    return super.isInside(location);
  }

  private isWithinEpsilon(p1: Point, p2: Point): boolean {
    return (
      Math.abs(p1.x - p2.x) < this.epsilon &&
      Math.abs(p1.y - p2.y) < this.epsilon
    );
  }

  get shape(): NodeShape {
    return this.nodeShape;
  }
}
