﻿import { Vue } from 'vue-property-decorator';
import { IEdge, INode } from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { OwnershipRelationshipDisplay } from '@/core/services/corporate/OwnershipRelationshipDisplay';
import { ContractRelationshipDisplay } from '@/core/services/corporate/ContractRelationshipDisplay';
import isNil from 'lodash/isNil';
import i18n from '@/core/plugins/vue-i18n';
import SystemEntityTypes from './SystemEntityTypes';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { RelationshipType } from '@/api/models';
export class CorporateEdgeRelationshipProvider extends Vue {
  private ownershipRelationshipDisplay: OwnershipRelationshipDisplay;
  private contractRelationshipDisplay: ContractRelationshipDisplay;

  constructor(private graphService: IGraphService) {
    super();
    this.ownershipRelationshipDisplay = new OwnershipRelationshipDisplay();
    this.contractRelationshipDisplay = new ContractRelationshipDisplay(
      graphService
    );
  }

  getMultipartiteRelationshipDisplay(edge: IEdge, node?: INode): string {
    if (isNil(edge?.tag?.name)) return '';

    const relationshipType = edge.tag.relationshipType;

    if (
      CorporateEdgeRelationshipProvider.isSingleRelationship(
        relationshipType,
        edge
      )
    ) {
      return CorporateEdgeRelationshipProvider.getRelationshipDisplayString(
        edge
      );
    }

    if (relationshipType === RelationshipType.Ownership) {
      return this.ownershipRelationshipDisplay.getDisplayString(edge, node);
    }

    if (relationshipType === RelationshipType.Contract) {
      return this.contractRelationshipDisplay.getDisplayString(edge);
    }

    return '';
  }

  static getRelationshipDisplayString(edge: IEdge): string {
    // this can throw and error if the edge is actually being deleted.
    if (!IEdge.isInstance(edge)) {
      return;
    }
    try {
      if (edge == null || edge.sourcePort == null || edge.targetPort == null) {
        console.warn(
          'Could not retrieve relationships due to null Edge or Edge Ports'
        );
        return '';
      }

      const sourceLabel =
        DiagramUtils.getLabelValue(edge.sourceNode) ?? 'Source';
      const targetLabel =
        DiagramUtils.getLabelValue(edge.targetNode) ?? 'Target';
      const edgeName = edge.tag.name ? edge.tag.name : 'Unnamed';
      let edgeLabel = DiagramUtils.getLabelValue(edge) ?? '';

      if (edge.tag.relationshipType === RelationshipType.Ownership) {
        edgeLabel =
          OwnershipRelationshipDisplay.formatUnknownPercentage(edgeLabel);
      }

      const stringFormat = CorporateEdgeRelationshipProvider.displayFormat(
        edge,
        i18n.t(edgeName),
        sourceLabel,
        targetLabel,
        edgeLabel
      );

      return stringFormat;
    } catch (e) {
      console.error(e);
    }

    return '';
  }

  private static displayFormat(
    edge,
    edgeName,
    sourceLabel,
    targetLabel,
    edgeLabel
  ): string {
    switch (edge.tag.relationshipType) {
      case RelationshipType.Cashflow:
        return i18n
          .t('BOLD_FLOWING_FROM_X_TO_X', [sourceLabel, targetLabel])
          .toString();

      case RelationshipType.Contract:
        return i18n
          .t('BOLD_BETWEEN_X_AND_X', [sourceLabel, targetLabel])
          .toString();

      case RelationshipType.Ownership:
        return i18n
          .t('BOLD_OWNS_X_OF_X', [sourceLabel, targetLabel])
          .toString();
    }
    return i18n
      .t('BOLD_BETWEEN_X_AND_X', [sourceLabel, targetLabel])
      .toString();
  }

  private static isSingleRelationship(
    relationshipType: RelationshipType,
    edge: IEdge
  ): boolean {
    return (
      relationshipType !== RelationshipType.Ownership &&
      (edge.tag.busid === null || edge.tag.busid.length === 0)
    );
  }
}
