import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';

export default class GraphMLExportProvider implements IExportProvider {
  private _fileExtension = 'graphml';
  private _mimeType = 'application/xml';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const graphMLString = await options.graphMLSupport.saveFile(
      graphComponent.graph
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: new Blob([graphMLString], { type: this._mimeType })
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    throw new Error('Not supported');
  }
}
