export default class LegendHtmlUtils {
  public static readonly diagramLegendClass = 'diagram-legend';
  public static readonly hiddenLegendItemsClass = 'hidden-legend-items';
  public static readonly legendHeaderClass = 'legend-header';
  public static readonly legendItemsContainerClass = 'legend-items-container';
  public static readonly diagramLegendSeparatorClass =
    'diagram-legend-separator';

  private static querySelector(
    cssClass: string,
    parent?: Element
  ): HTMLElement | undefined {
    if (parent) {
      return parent.querySelector(`.${cssClass}`);
    }

    return document.querySelector(`.${cssClass}`);
  }

  public static getDiagramLegend(parent?: Element): HTMLElement | undefined {
    return LegendHtmlUtils.querySelector(
      LegendHtmlUtils.diagramLegendClass,
      parent
    );
  }

  public static getHiddenLegendItems(
    parent?: Element
  ): HTMLElement | undefined {
    return LegendHtmlUtils.querySelector(
      LegendHtmlUtils.hiddenLegendItemsClass,
      parent
    );
  }

  public static getLegendHeader(parent?: Element): HTMLElement | undefined {
    return LegendHtmlUtils.querySelector(
      LegendHtmlUtils.legendHeaderClass,
      parent
    );
  }

  public static getLegendItemsContainer(
    parent?: Element
  ): HTMLElement | undefined {
    return LegendHtmlUtils.querySelector(
      LegendHtmlUtils.legendItemsContainerClass,
      parent
    );
  }

  public static getDiagramLegendSeparator(
    parent?: Element
  ): HTMLElement | undefined {
    return LegendHtmlUtils.querySelector(
      LegendHtmlUtils.diagramLegendSeparatorClass,
      parent
    );
  }
}
