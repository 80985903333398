import { DocumentSyncMessageType } from './DocumentSyncMessageType';
import IDocumentSyncMessage from './IDocumentSyncMessage';

export default class DocumentSyncResponse implements IDocumentSyncMessage {
  public type: DocumentSyncMessageType = DocumentSyncMessageType.Response;
  public documentId: number;
  public isEditing: boolean;

  constructor(documentId: number, isEditing: boolean) {
    this.documentId = documentId;
    this.isEditing = isEditing;
  }
}
