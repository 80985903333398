import {
  CreateOrEditDocumentPageDto,
  DocumentPageDto,
  DocumentPageType,
  SubPageFlowBehavior
} from '@/api/models';
import DocumentService from '../services/document/DocumentService';

function getSubPageType(subPageIndex: number): DocumentPageType {
  const page = this as DocumentPageDto;
  const behavior = DocumentService.subPageFlowBehavior;
  if (
    (behavior === SubPageFlowBehavior.SplitToText ||
      behavior === SubPageFlowBehavior.SplitToTextText) &&
    page.pageType === DocumentPageType.Split &&
    subPageIndex >= 1
  ) {
    return DocumentPageType.Content;
  }
  return page.pageType;
}
DocumentPageDto.prototype.getSubPageType = getSubPageType;
CreateOrEditDocumentPageDto.prototype.getSubPageType = getSubPageType;

function getSubPageColumns(subPageIndex: number): number {
  const page = this as DocumentPageDto;
  const behavior = DocumentService.subPageFlowBehavior;
  if (
    behavior === SubPageFlowBehavior.SplitToTextText &&
    page.pageType === DocumentPageType.Split &&
    subPageIndex >= 1
  ) {
    return 2;
  }
  return page.contentColumns;
}
DocumentPageDto.prototype.getSubPageColumns = getSubPageColumns;
CreateOrEditDocumentPageDto.prototype.getSubPageColumns = getSubPageColumns;

export function setDocumentPagePrototype(...pages: DocumentPageDto[]): void {
  //@ts-expect-error
  const proto = new DocumentPageDto();
  for (const page of pages) {
    Object.setPrototypeOf(page, proto);
  }
}
