import appConfig from '@/core/config/appConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AnalyticsEvent } from './AnalyticsEvent';
import Vue from 'vue';
import AuthService from '../auth/AuthService';
import { getScreenResolution } from '@/core/utils/common.utils';
import { CurrentUserProfileEditDto } from '@/api/models';

export default class AnalyticsService {
  private static _appInsights: ApplicationInsights;
  private static heartbeatHandle: number = null;
  private static readonly heartbeatInterval = 30000;
  private static instanceId = 'unknown';

  public static setAuthenticatedUser(user: CurrentUserProfileEditDto): void {
    try {
      if (appConfig.appInsights.enabled) {
        const additionalDetail = appConfig.pendo?.additionalDetail ?? false;
        const userId =
          additionalDetail && user?.emailAddress
            ? user.emailAddress
            : window.location.hostname + ':' + user.userId;

        this.getAppInsights().setAuthenticatedUserContext(userId);
        console.debug(
          `[Application Insights] Authenticated user set to '${userId}'`
        );
      }
    } catch (e) {
      console.debug(
        '[Application Insights] Failed to set authenticated user',
        e
      );
    }
  }

  public static setInstanceId(instanceId: string): void {
    if (appConfig.appInsights.enabled) {
      this.instanceId = instanceId;
      console.debug(`[Application Insights] Instance id set to ${instanceId}`);
    }
  }

  public static trackPageView(pageName: string): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackPageView({ name: pageName });
        console.debug('[Application Insights] Page view tracked');
      }
    } catch (e) {
      console.debug('[Application Insights] Failed to track page view', e);
    }
  }

  public static trackEvent(
    event: AnalyticsEvent,
    properties: Object = null
  ): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackEvent({
          name: event,
          properties: properties
        });
        console.debug(`[Application Insights] Event tracked: '${event}'`);
      }
    } catch (e) {
      console.debug(
        `[Application Insights] Failed to track event: '${event}'`,
        e
      );
    }
  }

  public static trackError(error: Error): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackException({ exception: error });
        console.debug(`[Application Insights] Error tracked`);
      }
    } catch (e) {
      console.debug(`[Application Insights] Failed to track error`, e);
    }
  }

  public static trackUnhandledErrors(): void {
    if (appConfig.appInsights.enabled) {
      Vue.config.errorHandler = (error, vm, info): void => {
        this.trackError(error);
      };
      window.onerror = (msg, url, lineNo, columnNo, error): void => {
        this.trackError(error);
      };
    }
  }

  public static enableHeartbeat(): void {
    if (appConfig.appInsights.enabled) {
      clearInterval(this.heartbeatHandle);
      this.heartbeatHandle = setInterval(() => {
        if (AuthService.isLoggedIn) {
          let token = AuthService.getCachedAccessToken();
          if (token) {
            fetch(`${appConfig.apiBaseUrl}/heartbeat`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then(() => {
              console.debug(`[Application Insights] Heartbeat`);
            });
          }
        }
      }, this.heartbeatInterval) as any;
      console.info(`[Application Insights] Heartbeat enabled`);
    }
  }

  public static disableHeartbeat(): void {
    clearInterval(this.heartbeatHandle);
    console.info(`[Application Insights] Heartbeat disabled`);
  }

  private static getAppInsights(): ApplicationInsights {
    if (!this._appInsights && appConfig.appInsights.enabled) {
      this._appInsights = new ApplicationInsights({
        config: appConfig.appInsights
      });
      this._appInsights.loadAppInsights();

      const screenRes = getScreenResolution();
      const screenResTag = `${screenRes.width} x ${screenRes.height}`;
      this._appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.tags['ai.cloud.role'] = this.instanceId;
        telemetryItem.tags['ai.device.screenResolution'] = screenResTag;
      });
      console.info('[Application Insights] Initialized');
    }
    return this._appInsights;
  }
}
