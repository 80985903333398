import CompositeParameter from '../parameters/CompositeParameter';
import DataPropertyParameter from '../parameters/DataPropertyParameter';
import DataPropertyTagParameter from '../parameters/DataPropertyTagParameter';
import EntityTypeParameter from '../parameters/EntityTypeParameter';
import EntityNameParameter from '../parameters/EntityNameParameter';
import OwnershipParameter from '../parameters/OwnershipParameter';
import TextParameter from '../parameters/TextParameter';
import CompositeFilter from './CompositeFilter';
import DataPropertyFilter from './DataPropertyFilter';
import DataPropertyTagFilter from './DataPropertyTagFilter';
import EntityTypeFilter from './EntityTypeFilter';
import EntityNameFilter from './EntityNameFilter';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import FilterSerializer from './FilterSerializer';
import { FilterType } from './FilterType';
import OwnershipFilter from './OwnershipFilter';
import TextFilter from './TextFilter';

export default class FilterFactory {
  public static getFilter(
    scope: FilterScope,
    parameterDef: any,
    type: FilterType
  ): Filter {
    switch (scope) {
      case FilterScope.Composite:
        return new CompositeFilter(
          new CompositeParameter(
            FilterSerializer.deserialize(parameterDef.filters),
            parameterDef.compositionType
          ),
          type
        );
      case FilterScope.DataProperty:
        return new DataPropertyFilter(
          new DataPropertyParameter(
            parameterDef.dataPropertyDefinitionId,
            parameterDef.dataPropertyValue
          ),
          type
        );
      case FilterScope.DataPropertyTag:
        return new DataPropertyTagFilter(
          new DataPropertyTagParameter(
            parameterDef.tagName,
            parameterDef.tagColor
          ),
          type
        );
      case FilterScope.EntityType:
        return new EntityTypeFilter(
          new EntityTypeParameter(
            parameterDef.baseType,
            parameterDef.entityType
          ),
          type
        );
      case FilterScope.EntityName:
        return new EntityNameFilter(
          new EntityNameParameter('entityNameType', parameterDef.entityName),
          type
        );
      case FilterScope.Ownership:
        return new OwnershipFilter(
          new OwnershipParameter(
            parameterDef.nodeUuid,
            parameterDef?.percentage,
            parameterDef?.condition
          ),
          type
        );
      case FilterScope.Text:
        return new TextFilter(new TextParameter(parameterDef.text), type);
      default:
        throw `Unknown filter type: ${scope}`;
    }
  }
}
