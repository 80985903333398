<template>
  <div
    class="relative"
    v-click-outside="clickOutsideConfig"
    :class="{ 'w-full': fullWidth }"
  >
    <label v-if="label" :for="fieldId" class="block">
      {{ label }}
    </label>
    <AppFlyout
      ref="flyoutRef"
      offset="0"
      same-or-wider
      block-content
      :activator-class="fullWidth ? 'w-full' : ''"
      propagate
      :placement="placement"
      :max-width="maxWidth"
      :append-to-body="appendToBody"
      :disabled="disabled"
      :popper-class="popperContextClass"
      :fallback-placements="fallbackPlacements"
    >
      <template #activator="{ isVisible }">
        <div
          ref="selectMainRef"
          class="select__main"
          :data-automation-id="activatorDataAutomationId"
          :class="[
            activatorCustomClasses,
            activatorMargin,
            sizeClasses,
            borderlessClass,
            {
              'is-disabled': disabled,
              'is-open': isVisible,
              'max-h-40': fixedHeight,
              'w-full': fullWidth,
              'custom-size': customSize,
              'dense': dense,
              'rounded-4': rounded
            }
          ]"
          :style="{
            minWidth: minimumWidth
          }"
          tabindex="0"
        >
          <slot
            name="selection"
            :value="rawValue"
            :hasInitialValue="hasInitialValue"
            :set-selection="setSelection"
            :is-visible="isVisible"
          >
            <span>{{ selectedDisplayValue || placeholder }}</span>
          </slot>
          <AppIcon
            name="arrow-drop-down"
            size="20"
            class="icon-arrow-drop-down absolute right-8 transform transition duration-300"
            :class="[isVisible ? 'rotate-180' : 'rotate-0', iconClass]"
          />
        </div>
      </template>

      <div slot="popper" class="select__list" ref="optionsList">
        <slot v-if="isPrependToListSlot" name="prepend-to-list"></slot>

        <ul
          class="flex w-full min-w-full flex-grow flex-col overflow-y-auto overflow-x-hidden"
        >
          <template v-for="(item, index) in options">
            <Compute
              :key="index"
              :isSelected="isSelected(item)"
              :isDisabled="isDisabled(item)"
              #default="{ isSelected, isDisabled }"
            >
              <li
                :key="index"
                class="select__item"
                :class="[
                  optionsPaddingClass,
                  {
                    'is-selected': isSelected,
                    'is-disabled': isDisabled
                  }
                ]"
                :tabindex="isDisabled ? -1 : 0"
                @click="
                  isDisabled ? $event.stopPropagation() : setSelection(item)
                "
              >
                <slot
                  name="options"
                  :option="item"
                  :is-selected="isSelected"
                  :is-disabled="isDisabled"
                >
                  {{ getOptionLabel(item) }}
                </slot>
              </li>
            </Compute>
          </template>

          <p
            v-if="optionsPlaceholder && !options.length"
            class="w-full p-10 text-center text-base leading-tight text-main-dark"
          >
            {{ optionsPlaceholder }}
          </p>
        </ul>

        <slot v-if="isAppendToListSlot" name="append-to-list"></slot>
      </div>
    </AppFlyout>
  </div>
</template>

<script lang="ts">
import { generateUuid, isPrimitive } from '@/core/utils/common.utils';
import isNil from 'lodash/isNil';
import { Vue, Component, Prop } from 'vue-property-decorator';
import AppFlyout, { Placement } from './AppFlyout.vue';

export type TSelectRounded = 'top' | 'bottom' | 'left' | 'right';

type selectSizes = 'xs' | 'sm' | 'md' | 'lg';

const sizeClasses = {
  xs: 'pl-8 pr-12 pb-2 pt-4 text-base',
  sm: 'pl-8 pr-12 pt-6 pb-5 text-14',
  md: 'pl-16 pr-32 py-12 text-14'
};

@Component({
  name: 'AppSelect',
  components: {
    AppFlyout
  }
})
class AppSelect extends Vue {
  @Prop({ required: true })
  value: Array<string | number> | string | number;

  @Prop({ type: String, required: false })
  label: string;

  @Prop({ type: String, required: false })
  id: string;

  @Prop({ default: 'Select options' })
  placeholder: string;

  @Prop({ required: true })
  options: any[];

  @Prop({ default: 'value' })
  valueKey: string;

  @Prop({ default: 'label' })
  labelKey: string;

  @Prop({ default: null })
  disabledOptions: string[];

  @Prop({ default: false, type: Boolean })
  multiple: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  returnSingleValue: boolean;

  @Prop({ default: true, type: Boolean })
  fixedHeight: boolean;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: 180, type: Number })
  minWidth: number;

  @Prop({ default: false, type: Boolean })
  widthFitContent: boolean;

  @Prop({ default: 'bottom-start' })
  placement: Placement;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: true, type: Boolean })
  rounded: boolean;

  @Prop({ default: false, type: Boolean })
  dense: boolean;

  @Prop({ default: 'md', type: String })
  size: selectSizes;

  @Prop({ default: null, type: String })
  optionsPlaceholder: string;

  @Prop({ default: null, type: String })
  popperContextClass: string;

  @Prop({ default: null, type: String })
  iconClass: string;

  @Prop({ default: 'px-16 py-8', type: String })
  optionsPaddingClass: string;

  @Prop({ default: 'm-2', type: String })
  activatorMargin: string;

  @Prop({ default: null, type: String })
  activatorDataAutomationId: string | null;

  @Prop({ default: null, type: String })
  freeSizeClasses: string;

  @Prop({ default: '', type: String })
  activatorCustomClasses: string;

  @Prop({ default: false, type: Boolean })
  borderless: boolean;

  @Prop({ default: () => [], type: Array })
  fallbackPlacements: Array<Placement>;

  $refs: {
    flyoutRef: AppFlyout;
    optionsList: HTMLElement | null;
    selectMainRef: HTMLElement;
  };

  get fieldId(): string {
    return this.id ?? generateUuid();
  }

  get rawValue(): any {
    if (this.multiple) {
      return this.options.filter((i) =>
        (this.value as Array<string | number>).includes(i[this.valueKey])
      );
    }

    if (this.returnSingleValue) {
      return this.options.find((i) => i[this.valueKey] == this.value);
    }

    return this.value;
  }

  get hasInitialValue(): boolean {
    if (isNil(this.value)) {
      return false;
    }

    if (isPrimitive(this.value)) {
      return !!(this.value + '');
    }

    if (Array.isArray(this.value)) {
      return this.value.length > 0;
    } else {
      return Object.entries(this.value).length > 0;
    }
  }

  get selectedDisplayValue(): string {
    if (!this.hasInitialValue) {
      return '';
    }

    if (this.multiple) {
      return this.options
        .filter((i) =>
          (this.value as Array<string | number>).includes(
            this.getOptionValue(i)
          )
        )
        .map((i) => this.getOptionLabel(i))
        .join(', ');
    } else if (this.returnSingleValue) {
      const selectedOption = this.options.find(
        (i) => this.getOptionValue(i) == this.value
      );
      return this.getOptionLabel(selectedOption);
    } else {
      return String(this.getOptionLabel(this.value));
    }
  }

  get borderlessClass(): string {
    return this.borderless ? 'ring-transparent' : '';
  }

  get minimumWidth(): string {
    if (this.widthFitContent) {
      return 'fit-content';
    } else {
      return `${this.minWidth}px`;
    }
  }

  get sizeClasses(): string {
    if (this.freeSizeClasses) {
      return this.freeSizeClasses;
    }

    return sizeClasses[this.size];
  }

  get isPrependToListSlot(): boolean {
    return !!this.$slots['prepend-to-list'];
  }

  get isAppendToListSlot(): boolean {
    return !!this.$slots['append-to-list'];
  }

  get clickOutsideConfig(): Record<string, any> {
    return {
      events: ['click', 'dbclick'],
      middleware: this.middleware,
      handler: this.close
    };
  }

  get customSize(): boolean {
    return this.size !== 'md' || !!this.freeSizeClasses;
  }

  middleware(event: Event & { path: Element[] }): boolean {
    const path = event?.path || event?.composedPath();
    return !path.includes(this.$refs.optionsList);
  }

  getOptionValue(option: any) {
    return isPrimitive(option) ? option : option[this.valueKey];
  }

  getOptionLabel(option: any): string {
    return isPrimitive(option) ? option : option[this.labelKey];
  }

  getValueToEmit(option: any): any {
    if (this.multiple) {
      return this.handleMultipleSelection(option);
    }

    if (this.returnSingleValue) {
      return this.getOptionValue(option);
    }

    return option;
  }

  handleMultipleSelection(option: any): (string | number)[] {
    const value = this.getOptionValue(option);
    const set = new Set(this.value as Array<string | number>);

    set.has(value) ? set.delete(value) : set.add(value);

    return Array.from(set);
  }

  setSelection(option): void {
    const toEmit = this.getValueToEmit(option);

    this.$emit('input', toEmit);
    this.$emit('change', toEmit);

    if (!this.multiple) {
      this.close();
    }
  }

  isSelected(option: any): boolean {
    if (!this.hasInitialValue) {
      return false;
    }

    if (this.multiple) {
      return (this.value as Array<number | string>).some(
        (i) => this.getOptionValue(i) == this.getOptionValue(option)
      );
    } else {
      return this.getOptionValue(this.value) == this.getOptionValue(option);
    }
  }

  isDisabled(option: any): boolean {
    if (option.disabled) return true;

    if (!this.disabledOptions?.length) return false;

    return this.disabledOptions.includes(this.getOptionValue(option));
  }

  // use for programmatically open via ref from parent (e.g. this.$refs.select.open())
  open(): void {
    if (this.disabled) return;

    this.$refs.flyoutRef.show();
    this.$refs.selectMainRef.focus();
  }

  close(): void {
    if (this.disabled) return;

    this.$refs.flyoutRef.hide();
  }
}
export default AppSelect;
</script>

<style lang="scss" scoped>
.select {
  &__main {
    &:not(.custom-size) {
      padding: 12px 32px 12px 16px;

      &.dense {
        padding: 6px 32px 6px 16px;
      }
    }

    @apply relative inline-flex cursor-pointer items-center
    truncate
    bg-white
    outline-none
    
    transition
    duration-200
    focus:ring-2
    focus:ring-accent-purple;

    &:not(.is-disabled) {
      @apply text-main-dark
      ring-main-dark-20
        focus:z-1
      focus:ring-2
        focus:ring-accent-purple;

      &.is-open {
        @apply z-1 ring-2 ring-accent-purple;
      }
    }

    &:not(.ring-transparent) {
      @apply ring-1;
    }

    &.is-disabled {
      @apply cursor-not-allowed
      select-none
        text-main-dark-20
        ring-main-dark-10;
    }
  }

  &__list {
    @apply z-10
      flex
      min-w-full
    flex-col
      overflow-y-auto
      overflow-x-hidden
      rounded-4
      bg-white py-8 shadow-sm;

    max-height: 300px;
  }

  &__item {
    @apply cursor-pointer whitespace-nowrap
    text-left
      text-14
      text-main-dark
      outline-none
      transition;

    &:not(.is-disabled) {
      @apply hover:bg-main-dark-10;
    }

    &.is-selected {
      @apply bg-main-dark-10;
    }

    &.is-disabled {
      @apply cursor-not-allowed
        select-none
        text-main-dark-20;
    }
  }
}
</style>
