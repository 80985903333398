import { DiagramDto, DiagramEdgeDto, DiagramNodeDto } from '@/api/models';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames
} from '@/core/common/DataPropertyDisplayType';
import JPoint from '@/core/common/JPoint';
import appConsts from '@/core/config/appConsts';
import { generateUuid } from '@/core/utils/common.utils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { cloneDeep } from 'lodash';
import IEdgePageSyncCommand from './IEdgePageSyncCommand';
import INodePageSyncCommand from './INodePageSyncCommand';
import ISyncEdgeResult from './ISyncEdgeResult';
import ISyncNodeResult from './ISyncNodeResult';
import { PageSyncChangeType } from './PageSyncChangeType';
import { SerializedDiagramEntityComparer as Comparer } from './SerializedDiagramEntityComparer';
import SerializedDiagramUtils from './SerializedDiagramUtils';

export default class PageSyncCommandExecutor {
  public static executeNodeCommand(
    command: INodePageSyncCommand,
    sourceDiagram: DiagramDto,
    targetNode: DiagramNodeDto,
    targetDiagram: DiagramDto
  ): ISyncNodeResult {
    let isModified = false;
    let needRerouting = false;

    for (const change of command.changes) {
      let innerIsModified = false;
      switch (change.type) {
        case PageSyncChangeType.ChangeNodeType:
          innerIsModified = this.executeCopyNodeType(
            command.updatedItem,
            targetNode
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeNodeFill:
          innerIsModified = this.executeCopyNodeFill(
            command.updatedItem,
            targetNode,
            change?.data?.compositeStyleIndex
          );
          break;
        case PageSyncChangeType.ChangeNodeAngle:
          innerIsModified = this.executeCopyNodeAngle(
            command.updatedItem,
            targetNode,
            change?.data?.compositeStyleIndex
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeNodeStrokeFill:
          innerIsModified = this.executeCopyNodeStrokeFill(
            command.updatedItem,
            targetNode,
            change?.data?.compositeStyleIndex
          );
          break;
        case PageSyncChangeType.ChangeNodeStrokeThickness:
          innerIsModified = this.executeCopyNodeStrokeThickness(
            command.updatedItem,
            targetNode,
            change?.data?.compositeStyleIndex
          );
          break;
        case PageSyncChangeType.ChangeNodeStrokeDashStyle:
          innerIsModified = this.executeCopyNodeStrokeDashStyle(
            command.updatedItem,
            targetNode,
            change?.data?.compositeStyleIndex
          );
          break;
        case PageSyncChangeType.EditNodeLabel:
          innerIsModified = this.executeCopyLabel(
            command.updatedItem,
            targetNode
          );
          break;
        case PageSyncChangeType.DeleteNode:
          innerIsModified = this.executeDeleteNode(targetNode, targetDiagram);
          break;
        case PageSyncChangeType.AddNode:
          targetNode = this.executeAddNode(command.updatedItem, targetDiagram);
          innerIsModified = !!targetNode;
          break;
        case PageSyncChangeType.ChangeNodeLabelData:
          innerIsModified = this.executeCopyLabelData(
            command.updatedItem,
            targetNode
          );
          break;
        case PageSyncChangeType.ChangeJurisdiction:
          innerIsModified = this.executeCopyNodeJurisdictionOrState(
            command.updatedItem,
            targetNode,
            appConsts.JURISDICTION_DEFINITION_ID
          );
          break;
        case PageSyncChangeType.ChangeCountryState:
          innerIsModified = this.executeCopyNodeJurisdictionOrState(
            command.updatedItem,
            targetNode,
            appConsts.STATE_DEFINITION_ID
          );
          break;
        case PageSyncChangeType.ChangeNodeDisplayOrder:
          innerIsModified = this.executeCopyDisplayOrder(
            command.updatedItem,
            targetNode
          );
          break;
        case PageSyncChangeType.ChangeNodeDataPropertyStyleState:
          innerIsModified = this.executeCopyNodeDataPropertyStyleState(
            command.updatedItem,
            targetNode
          );
          break;
        case PageSyncChangeType.ChangeJurisdictionDisplayTypeNodeLabel:
          innerIsModified = this.executeCopyNodeJurisdictionDisplayTypeState(
            command.updatedItem,
            targetNode,
            DataPropertyDisplayTypeNames.Jurisdiction,
            DataPropertyDisplayType.NodeLabel
          );
          break;
        case PageSyncChangeType.ChangeJurisdictionDisplayTypeDecorator:
          innerIsModified = this.executeCopyNodeJurisdictionDisplayTypeState(
            command.updatedItem,
            targetNode,
            DataPropertyDisplayTypeNames.Jurisdiction,
            DataPropertyDisplayType.Decorator
          );
          break;
        case PageSyncChangeType.ChangeCountryStateDisplayTypeNodeLabel:
          innerIsModified = this.executeCopyNodeJurisdictionDisplayTypeState(
            command.updatedItem,
            targetNode,
            DataPropertyDisplayTypeNames.State,
            DataPropertyDisplayType.NodeLabel
          );
          break;
        case PageSyncChangeType.ChangeCountryStateDisplayTypeDecorator:
          innerIsModified = this.executeCopyNodeJurisdictionDisplayTypeState(
            command.updatedItem,
            targetNode,
            DataPropertyDisplayTypeNames.State,
            DataPropertyDisplayType.Decorator
          );
          break;
        case PageSyncChangeType.ChangeJurisdictionLocation:
          innerIsModified = this.executeCopyJurisdictionLocation(
            command.updatedItem,
            targetNode
          );
          break;
        case PageSyncChangeType.ChangeSymbol:
          innerIsModified = this.executeCopySymbol(
            command.originalItem,
            command.updatedItem,
            targetNode
          );
          break;
      }

      if (innerIsModified) {
        isModified = innerIsModified;
      }
    }

    // For correct mirroring node layout changes need to follow next rules:
    // If changes contain both change node size and change node location - need to use executeCopyNodeLayout method
    // Otherwise these changes must be processed separately
    const nodeLayoutChanges = command.changes.filter(
      (c) =>
        c.type == PageSyncChangeType.ChangeNodeSize ||
        c.type == PageSyncChangeType.ChangeNodeLocation
    );

    if (nodeLayoutChanges.length > 0) {
      let nodeLayoutModified = false;
      if (nodeLayoutChanges.length === 1) {
        const change = nodeLayoutChanges[0];
        switch (change.type) {
          case PageSyncChangeType.ChangeNodeSize:
            nodeLayoutModified = this.executeCopyNodeSize(
              command.updatedItem,
              targetNode,
              change.data.positionDifference
            );
            break;
          case PageSyncChangeType.ChangeNodeLocation:
            nodeLayoutModified = this.executeCopyNodeLocation(
              command.updatedItem,
              targetNode
            );
            break;
        }
      } else {
        nodeLayoutModified = this.executeCopyNodeLayout(
          command.updatedItem,
          targetNode
        );
      }

      if (nodeLayoutModified) {
        needRerouting = true;
        isModified = true;
      }
    }

    return {
      isModified: isModified,
      needRerouting: isModified ? needRerouting : false,
      item: targetNode
    };
  }

  public static executeEdgeCommand(
    command: IEdgePageSyncCommand,
    sourceDiagram: DiagramDto,
    targetEdge: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): ISyncEdgeResult {
    let isModified = false;
    let needRerouting = false;

    for (const change of command.changes) {
      let innerIsModified = false;
      switch (change.type) {
        case PageSyncChangeType.ChangeEdgeType:
          innerIsModified = this.executeCopyEdgeType(
            command.updatedItem,
            targetEdge
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeEdgeTargetPort:
          innerIsModified = this.executeChangeEdgePort(
            command.updatedItem,
            targetEdge,
            sourceDiagram,
            targetDiagram,
            'target'
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeEdgeSourcePort:
          innerIsModified = this.executeChangeEdgePort(
            command.updatedItem,
            targetEdge,
            sourceDiagram,
            targetDiagram,
            'source'
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeEdgeBend:
          innerIsModified = this.executeChangeEdgeBends(
            command.updatedItem,
            targetEdge
          );
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.ChangeEdgeHeight:
          innerIsModified = this.executeChangeEdgeHeight(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.EditEdgeLabel:
          innerIsModified = this.executeCopyLabel(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgeLabelData:
          innerIsModified = this.executeCopyLabelData(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.AddEdge:
          targetEdge = this.executeAddEdge(
            command.updatedItem,
            sourceDiagram,
            targetDiagram
          );
          innerIsModified = !!targetEdge;
          if (innerIsModified) {
            needRerouting = true;
          }
          break;
        case PageSyncChangeType.DeleteEdge:
          innerIsModified = this.executeDeleteEdge(targetEdge, targetDiagram);
          break;
        case PageSyncChangeType.ChangeEdgeStrokeFill:
          innerIsModified = this.executeCopyEdgeStrokeFill(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgeStrokeThickness:
          innerIsModified = this.executeCopyEdgeStrokeThickness(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgeStrokeDashStyle:
          innerIsModified = this.executeCopyEdgeStrokeDashStyle(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgeSourceArrowType:
          innerIsModified = this.executeCopyEdgeArrowType(
            command.updatedItem,
            targetEdge,
            'source'
          );
          break;
        case PageSyncChangeType.ChangeEdgeTargetArrowType:
          innerIsModified = this.executeCopyEdgeArrowType(
            command.updatedItem,
            targetEdge,
            'target'
          );
          break;
        case PageSyncChangeType.ChangeEdgeBridgeState:
          innerIsModified = this.executeCopyEdgeBridgeState(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgePortDirection:
          innerIsModified = this.executeCopyEdgePortDirections(
            command.updatedItem,
            targetEdge
          );
          break;
        case PageSyncChangeType.ChangeEdgeDisplayOrder:
          innerIsModified = this.executeCopyDisplayOrder(
            command.updatedItem,
            targetEdge
          );
          break;
      }
      if (innerIsModified) {
        isModified = innerIsModified;
      }
    }
    return {
      isModified: isModified,
      needRerouting: isModified ? needRerouting : false,
      item: targetEdge
    };
  }

  public static executeUndoModifyNode(
    targetNode: DiagramNodeDto,
    targetNodeSnapshot: DiagramNodeDto
  ): boolean {
    targetNode.style = cloneDeep(targetNodeSnapshot.style);
    targetNode.layout = { ...targetNodeSnapshot.layout };
    targetNode.label = targetNodeSnapshot.label;
    targetNode.data = { ...targetNodeSnapshot.data };
    targetNode.dataProperties = targetNodeSnapshot.dataProperties
      ? [...targetNodeSnapshot.dataProperties]
      : [];

    return true;
  }

  public static executeUndoAddNode(
    addedTargetDiagramNode: DiagramNodeDto,
    targetDiagram: DiagramDto
  ): boolean {
    const nodeIndex = targetDiagram.nodes.findIndex(
      (n) => n.uuid === addedTargetDiagramNode.uuid
    );
    if (nodeIndex === -1) {
      return false;
    }
    targetDiagram.nodes.splice(nodeIndex, 1);
    return true;
  }

  public static executeUndoDeleteNode(
    targetNodeSnapshot: DiagramNodeDto,
    targetDiagram: DiagramDto
  ): boolean {
    const clonedNode = cloneDeep(targetNodeSnapshot);
    clonedNode.id = null;
    targetDiagram.nodes.push(clonedNode);
    return true;
  }

  public static executeUndoAddEdge(
    addedTargetDiagramEdge: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): boolean {
    const edgeIndex = targetDiagram.edges.findIndex(
      (n) => n.uuid === addedTargetDiagramEdge.uuid
    );
    if (edgeIndex === -1) {
      return false;
    }
    targetDiagram.edges.splice(edgeIndex, 1);
    return true;
  }

  public static executeUndoDeleteEdge(
    targetEdgeSnapshot: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): boolean {
    const clonedEdge = cloneDeep(targetEdgeSnapshot);
    clonedEdge.id = null;
    targetDiagram.edges.push(clonedEdge);
    return true;
  }

  public static executeUndoModifyEdge(
    targetEdge: DiagramEdgeDto,
    targetEdgeSnapshot: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): boolean {
    targetEdge.label = targetEdgeSnapshot.label;
    targetEdge.style = cloneDeep(targetEdgeSnapshot.style);
    targetEdge.data = cloneDeep(targetEdgeSnapshot.data);
    SerializedDiagramUtils.copyEdgeRouting(
      targetEdgeSnapshot,
      targetEdge,
      targetDiagram
    );

    return true;
  }

  private static executeCopyNodeType(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.copyNodeType(sourceNode, targetNode);
    return true;
  }

  private static executeCopyNodeFill(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    compositeStyleIndex?: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.applyNodeStyle(
      sourceNode,
      targetNode,
      SerializedDiagramUtils.copyNodeFill,
      compositeStyleIndex
    );
    return true;
  }

  private static executeCopyNodeAngle(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    compositeStyleIndex?: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.applyNodeStyle(
      sourceNode,
      targetNode,
      SerializedDiagramUtils.copyNodeAngle,
      compositeStyleIndex
    );
    return true;
  }

  private static executeCopyNodeStrokeFill(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    compositeStyleIndex?: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.applyNodeStyle(
      sourceNode,
      targetNode,
      SerializedDiagramUtils.copyNodeStrokeFill,
      compositeStyleIndex
    );
    return true;
  }

  private static executeCopyNodeStrokeThickness(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    compositeStyleIndex?: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.applyNodeStyle(
      sourceNode,
      targetNode,
      SerializedDiagramUtils.copyNodeStrokeThickness,
      compositeStyleIndex
    );
    return true;
  }

  private static executeCopyNodeStrokeDashStyle(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    compositeStyleIndex?: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.applyNodeStyle(
      sourceNode,
      targetNode,
      SerializedDiagramUtils.copyNodeStrokeDashStyle,
      compositeStyleIndex
    );
    return true;
  }

  private static executeCopyNodeLayout(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }
    SerializedDiagramUtils.copyNodeLayout(sourceNode, targetNode);

    return true;
  }

  private static executeCopyNodeSize(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    positionDifference: JPoint
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.copyNodeSize(sourceNode, targetNode);
    SerializedDiagramUtils.applyPositionDifference(
      targetNode,
      positionDifference
    );

    return true;
  }

  private static executeCopyNodeLocation(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }
    SerializedDiagramUtils.copyNodeLocation(sourceNode, targetNode);

    return true;
  }

  private static executeCopyNodeDataPropertyStyleState(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }
    SerializedDiagramUtils.copyNodeDataPropertyStyleState(
      sourceNode,
      targetNode
    );

    return true;
  }

  private static executeCopyNodeJurisdictionDisplayTypeState(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    dataPropertyDisplayTypeName: DataPropertyDisplayTypeNames,
    dataPropertyDisplayType: DataPropertyDisplayType
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }
    SerializedDiagramUtils.copyNodeJurisdictionDisplayTypeState(
      sourceNode,
      targetNode,
      dataPropertyDisplayTypeName,
      dataPropertyDisplayType
    );

    return true;
  }

  private static executeCopyJurisdictionLocation(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.copyJurisdictionLocation(sourceNode, targetNode);
    return true;
  }

  private static executeCopyLabel<T extends DiagramNodeDto | DiagramEdgeDto>(
    sourceItem: T,
    targetItem: T
  ): boolean {
    if (!sourceItem || !targetItem) {
      return false;
    }

    SerializedDiagramUtils.copyLabel(sourceItem, targetItem);
    return true;
  }

  private static executeChangeEdgePort(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    sourceDiagram: DiagramDto,
    targetDiagram: DiagramDto,
    portType: 'source' | 'target'
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    let node: DiagramNodeDto = null;
    switch (portType) {
      case 'source': {
        const sourceDiagramSourceNode = sourceDiagram.nodes.find(
          (node) => node.uuid === sourceEdge.sourceNodeUuid
        );
        node = DiagramUtils.findRelatedItems(
          targetDiagram.nodes,
          sourceDiagramSourceNode
        )[0];
        if (node) {
          targetEdge.sourceNodeUuid = node.uuid;
        }
        break;
      }
      case 'target': {
        const sourceDiagramTargetNode = sourceDiagram.nodes.find(
          (node) => node.uuid === sourceEdge.targetNodeUuid
        );
        node = DiagramUtils.findRelatedItems(
          targetDiagram.nodes,
          sourceDiagramTargetNode
        )[0];
        if (node) {
          targetEdge.targetNodeUuid = node.uuid;
        }
        break;
      }
    }

    if (!node) {
      SerializedDiagramUtils.deleteEdge(targetEdge, targetDiagram);
    } else {
      SerializedDiagramUtils.copyEdgePorts(sourceEdge, targetEdge, portType);
    }

    return true;
  }

  private static executeChangeEdgeBends(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeBends(sourceEdge, targetEdge);
    return true;
  }

  private static executeChangeEdgeHeight(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeHeight(sourceEdge, targetEdge);
    return true;
  }

  private static executeDeleteNode(
    targetNode: DiagramNodeDto,
    targetDiagram: DiagramDto
  ): boolean {
    if (!targetNode) {
      return false;
    }

    const nodeIndex = targetDiagram.nodes.findIndex(
      (n) => n.uuid === targetNode.uuid
    );

    if (nodeIndex >= 0) {
      // Find and delete not common edges, common edges will be deleted separately
      const edgesToDelete = SerializedDiagramUtils.findEdgesByNode(
        targetDiagram.edges,
        targetNode
      ).filter((edge) => !edge.originalUuid);

      edgesToDelete.forEach((e) => {
        const edgeIndex = targetDiagram.edges.indexOf(e);
        if (edgeIndex >= 0) {
          targetDiagram.edges.splice(edgeIndex, 1);
        }
      });

      targetDiagram.nodes.splice(nodeIndex, 1);
      return true;
    }

    return false;
  }

  private static executeAddNode(
    sourceNode: DiagramNodeDto,
    targetDiagram: DiagramDto
  ): DiagramNodeDto | null {
    if (!sourceNode) {
      return null;
    }
    const node = cloneDeep(sourceNode);
    node.originalUuid = sourceNode.uuid;
    node.uuid = generateUuid();
    node.groupUuid = null;
    node.dataProperties = [];
    node.dataPropertyTags = [];
    node.id = null;
    node.attachments = [];
    node.isGroupNode = false;
    targetDiagram.nodes.push(node);

    return node;
  }

  private static executeAddEdge(
    sourceEdge: DiagramEdgeDto,
    sourceDiagram: DiagramDto,
    targetDiagram: DiagramDto
  ): DiagramEdgeDto | null {
    if (!sourceEdge) {
      return null;
    }
    const sourceDiagramEdge = sourceDiagram.edges.find(
      (edge) => edge.uuid == sourceEdge.uuid
    );
    if (!sourceDiagramEdge) {
      return null;
    }

    const sourceDiagramTargetNode = sourceDiagram.nodes.find(
      (node) => node.uuid === sourceDiagramEdge.targetNodeUuid
    );
    const sourceDiagramSourceNode = sourceDiagram.nodes.find(
      (node) => node.uuid === sourceDiagramEdge.sourceNodeUuid
    );
    if (!sourceDiagramTargetNode || !sourceDiagramSourceNode) {
      return null;
    }

    const targetNode = DiagramUtils.findRelatedItems(
      targetDiagram.nodes,
      sourceDiagramTargetNode
    )[0];
    const sourceNode = DiagramUtils.findRelatedItems(
      targetDiagram.nodes,
      sourceDiagramSourceNode
    )[0];

    if (targetNode && sourceNode) {
      const edge = cloneDeep(sourceEdge);
      edge.originalUuid = sourceEdge.uuid;
      edge.uuid = generateUuid();
      edge.targetNodeUuid = targetNode.uuid;
      edge.sourceNodeUuid = sourceNode.uuid;
      edge.attachments = [];
      edge.dataProperties = [];
      edge.dataPropertyTags = [];
      edge.id = null;
      if (edge.data['labelIsPlaceholder']) {
        edge.data['labelIsPlaceholder'] = false;
        edge.label = null;
      }
      targetDiagram.edges.push(edge);
      return edge;
    }

    return null;
  }

  private static executeDeleteEdge(
    targetEdge: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): boolean {
    return SerializedDiagramUtils.deleteEdge(targetEdge, targetDiagram);
  }

  private static executeCopyLabelData<
    T extends DiagramNodeDto | DiagramEdgeDto
  >(sourceItem: T, targetItem: T): boolean {
    if (
      sourceItem &&
      targetItem &&
      !Comparer.labelDataEquals(
        sourceItem.data?.labelData,
        targetItem.data?.labelData
      )
    ) {
      SerializedDiagramUtils.copyLabelData(sourceItem, targetItem);
      return true;
    }

    return false;
  }

  private static executeCopyNodeJurisdictionOrState(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    definitionId: number
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.copyNodeJurisdictionOrState(
      sourceNode,
      targetNode,
      definitionId
    );
    return true;
  }

  private static executeCopyEdgeType(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeType(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyEdgeStrokeFill(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeStrokeFill(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyEdgeStrokeThickness(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeStrokeThickness(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyEdgeStrokeDashStyle(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeStrokeDashStyle(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyEdgeArrowType(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    destination: 'source' | 'target'
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeArrowType(
      sourceEdge,
      targetEdge,
      destination
    );
    return true;
  }

  private static executeCopyEdgeBridgeState(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgeBridgeState(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyEdgePortDirections(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): boolean {
    if (!sourceEdge || !targetEdge) {
      return false;
    }

    SerializedDiagramUtils.copyEdgePortDirections(sourceEdge, targetEdge);
    return true;
  }

  private static executeCopyDisplayOrder<
    T extends DiagramNodeDto | DiagramEdgeDto
  >(sourceItem: T, targetItem: T): boolean {
    if (!sourceItem || !targetItem) {
      return false;
    }

    SerializedDiagramUtils.copyDisplayOrder(sourceItem, targetItem);
    return true;
  }

  private static executeCopySymbol(
    originalNode: DiagramNodeDto,
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean {
    if (!sourceNode || !targetNode) {
      return false;
    }

    SerializedDiagramUtils.copySymbols(originalNode, sourceNode, targetNode);
    return true;
  }
}
