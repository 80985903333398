import { DiagramDto } from '@/api/models';
import DefaultDiContainer from '@/v2/configuration/dependency-injection/DefaultDiContainer';
import IContainer from '@/v2/configuration/dependency-injection/IContainer';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { DefaultGraph, IGraph } from 'yfiles';
import BackgroundEdgeService from './BackgroundEdgeService';
import EdgeServiceBase from './EdgeServiceBase';
import DiagramReader from './serialization/diagram-reader.service';

/**
 * This will allow you to load a diagram into an IGraph which can be manipulated without a graph component
 */
export default class BackgroundGraphService implements IGraphService {
  private _graph: IGraph;
  private _diContainer: IContainer;
  private _edgeService: BackgroundEdgeService;

  constructor(private diagramDto: DiagramDto) {
    this._diContainer = new DefaultDiContainer();
    this._graph = BackgroundGraphService.createGraph(this.diagramDto);
    this.configure();
  }

  public get graph(): IGraph {
    return this._graph;
  }

  public static createGraph(diagramDto: DiagramDto): DefaultGraph {
    const graph = new DefaultGraph();
    DiagramReader.toGraphFromDiagram(graph, diagramDto);
    return graph;
  }

  public get edgeService(): BackgroundEdgeService {
    return this._edgeService;
  }

  public isDisposed: boolean;
  public dispose(): void {
    if (this.isDisposed) {
      return;
    }

    this._diContainer.dispose();
    this.isDisposed = true;
  }

  public configure(): void {
    this.registerServices();
  }

  public getService<T>(key: string): T {
    return this._diContainer.resolve<T>(key);
  }

  private registerServices(): void {
    this._edgeService = this.registerService(
      EdgeServiceBase.$class,
      new BackgroundEdgeService(this.graph)
    );
  }

  private registerService(key: string, instance: any): any {
    this._diContainer.register(key, instance);
    return instance;
  }
}
