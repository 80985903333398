import { EntityTypeParameterBaseType } from './EntityTypeParameterBaseType';
import ISearchParameter from './ISearchParameter';

export default class EntityTypeParameter implements ISearchParameter {
  public baseType: EntityTypeParameterBaseType;
  public entityType: number | string;

  constructor(
    baseType: EntityTypeParameterBaseType,
    entityType: number | string
  ) {
    this.baseType = baseType;
    this.entityType = entityType;
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof EntityTypeParameter &&
      other.baseType === this.baseType &&
      other.entityType === this.entityType
    );
  }
}
