import Vue from 'vue';
import {
  COLLABORATION_NAMESPACE,
  SET_ACTIVE_ANNOTATIONS,
  SET_ACTIVE_MARKERS
} from '../../store/collaboration.module';
import MarkerData from './MarkerData';
import AnnotationData from './AnnotationData';
import CollaborationService from './CollaborationService';

export default class CollaborationEventsHandler {
  public static handleActiveMarkersChanged(markers: MarkerData[]): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${SET_ACTIVE_MARKERS}`,
      markers ? markers.map((m) => new MarkerData(m.marker, m.data)) : []
    );
  }

  public static handleActiveAnnotationsChanged(
    annotations: AnnotationData[]
  ): void {
    Vue.$globalStore.commit(
      `${COLLABORATION_NAMESPACE}/${SET_ACTIVE_ANNOTATIONS}`,
      annotations
        ? annotations
            .sort((a, b) => (a.data?.id ?? '').localeCompare(b.data?.id ?? ''))
            .map((a) => new AnnotationData(a.annotation, a.data))
        : []
    );
  }
}
