export function calculateAngle(
  anchor: { x: number; y: number },
  location: { x: number; y: number }
): number {
  return (
    ((-(
      Math.atan2(anchor.x - location.x, anchor.y - location.y) *
      (180 / Math.PI)
    ) %
      360) +
      360) %
    360
  );
}

export function calculateMode(arr: number[]): number {
  const mode = {};
  let max = 0,
    count = 0;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (mode[item]) {
      mode[item]++;
    } else {
      mode[item] = 1;
    }

    if (count < mode[item]) {
      max = item;
      count = mode[item];
    }
  }
  return max;
}

export function round(num: number, places: number = 2): number {
  const factor = Math.pow(10, places);
  return Math.round(num * factor) / factor;
}

export function normalize(
  actual: number,
  lowerBound: number,
  upperBound: number
): number {
  // Ensure the bounds are valid
  if (lowerBound >= upperBound) {
    throw new Error('lowerBound must be less than upperBound');
  }

  if (lowerBound >= upperBound) {
    throw new Error('lowerBound must be less than upperBound');
  }

  // Normalize x to a value between 0 and 1
  return (actual - lowerBound) / (upperBound - lowerBound);
}

/**
 * Returns the given angle in degrees.
 */
export function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}

/**
 * Returns the given angle in radians.
 */
export function toRadians(degrees: number): number {
  return (degrees / 180) * Math.PI;
}
