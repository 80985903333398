import {
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  ImageNodeStyleDto,
  JigsawPathShapeNodeStyleDto,
  NodeVisualType,
  ShapeNodeStyleDto
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames
} from '@/core/common/DataPropertyDisplayType';
import JPoint from '@/core/common/JPoint';
import appConsts from '@/core/config/appConsts';
import PageSyncChange from './PageSyncChange';
import { PageSyncChangeType } from './PageSyncChangeType';
import { SerializedDiagramEntityComparer as Comparer } from './SerializedDiagramEntityComparer';
import SerializedDiagramUtils from './SerializedDiagramUtils';

export class PageSyncChangeFinder {
  public static canSyncNode(node: DiagramNodeDto): boolean {
    return !node.isGroupNode;
  }

  public static canSyncEdge(edge: DiagramEdgeDto): boolean {
    return true;
  }

  public static findNodeChanges(
    oldNode: DiagramNodeDto,
    newNode: DiagramNodeDto
  ): PageSyncChange[] {
    const changes: PageSyncChange[] = [];
    if (!this.canSyncNode(oldNode)) {
      return changes;
    }
    if (!newNode) {
      changes.push(new PageSyncChange(PageSyncChangeType.DeleteNode));
      return changes;
    }

    // ChangeNodeType is special case and should be handled separately
    // and it's supposed that changes which are in the 'else' case
    // are part of ChangeNodeType command by default, so no need to
    // check/include them into the changes list
    if (!Comparer.nodeTypesEqual(oldNode, newNode)) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeNodeType));
    } else {
      if (!Comparer.nodeSizesEqual(oldNode.layout, newNode.layout)) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeSize, {
            positionDifference: new JPoint(
              oldNode.layout.x - newNode.layout.x,
              oldNode.layout.y - newNode.layout.y
            )
          })
        );
      }

      if (
        !Comparer.nodeLocationsEqual(oldNode.layout, newNode.layout) &&
        Comparer.nodeSizesEqual(oldNode.layout, newNode.layout)
      ) {
        changes.push(new PageSyncChange(PageSyncChangeType.ChangeNodeLocation));
      }

      if (!Comparer.labelsEqual(oldNode.label, newNode.label)) {
        changes.push(new PageSyncChange(PageSyncChangeType.EditNodeLabel));
      }

      if (
        !Comparer.labelDataEquals(
          oldNode.data.labelData,
          newNode.data.labelData
        )
      ) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeLabelData)
        );
      }

      if (
        !Comparer.displayOrdersEqual(
          oldNode.data.displayOrder,
          newNode.data.displayOrder
        )
      ) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeDisplayOrder)
        );
      }

      this.findNodeJurisdictionChanges(oldNode, newNode, changes);

      this.findNodeSymbolChanges(oldNode, newNode, changes);
    }

    this.findNodeStyleChanges(oldNode, newNode, changes);

    return changes;
  }

  public static findEdgeChanges(
    oldEdge: DiagramEdgeDto,
    newEdge: DiagramEdgeDto
  ): PageSyncChange[] {
    const changes: PageSyncChange[] = [];
    if (!this.canSyncEdge(oldEdge)) {
      return changes;
    }
    if (!newEdge) {
      changes.push(new PageSyncChange(PageSyncChangeType.DeleteEdge));
      return changes;
    }

    if (!oldEdge) {
      return changes;
    }
    if (!Comparer.edgeTypesEqual(oldEdge, newEdge)) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeType));
    } else if (
      !Comparer.edgePortsEqual(
        { port: oldEdge.sourcePort, nodeUuid: oldEdge.sourceNodeUuid },
        { port: newEdge.sourcePort, nodeUuid: newEdge.sourceNodeUuid }
      )
    ) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeSourcePort));
    }

    if (
      !Comparer.edgePortsEqual(
        { port: oldEdge.targetPort, nodeUuid: oldEdge.targetNodeUuid },
        { port: newEdge.targetPort, nodeUuid: newEdge.targetNodeUuid }
      )
    ) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeTargetPort));
    }

    if (!Comparer.edgeBendsEqual(newEdge, oldEdge)) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeBend));
    }

    if (!Comparer.edgeHeightsEqual(newEdge, oldEdge)) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeHeight));
    }

    if (!Comparer.labelsEqual(oldEdge.label, newEdge.label)) {
      changes.push(new PageSyncChange(PageSyncChangeType.EditEdgeLabel));
    }

    if (
      !Comparer.labelDataEquals(oldEdge.data.labelData, newEdge.data.labelData)
    ) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeLabelData));
    }

    if (
      !Comparer.fillsEqual(oldEdge.style.stroke.fill, newEdge.style.stroke.fill)
    ) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeEdgeStrokeFill));
    }

    if (
      !Comparer.thicknessEqual(
        oldEdge.style.stroke.thickness,
        newEdge.style.stroke.thickness
      )
    ) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgeStrokeThickness)
      );
    }

    if (
      !Comparer.dashesEqual(
        oldEdge.style.stroke.dashStyle,
        newEdge.style.stroke.dashStyle
      )
    ) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgeStrokeDashStyle)
      );
    }

    if (
      !Comparer.arrowTypesEqual(
        oldEdge.style.sourceArrow,
        newEdge.style.sourceArrow
      )
    ) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgeSourceArrowType)
      );
    }

    if (
      !Comparer.arrowTypesEqual(
        oldEdge.style.targetArrow,
        newEdge.style.targetArrow
      )
    ) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgeTargetArrowType)
      );
    }

    if (!Comparer.edgeBridgeStatesEqual(oldEdge.style, newEdge.style)) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgeBridgeState)
      );
    }

    if (
      !Comparer.edgePortDirectionsEqual(
        oldEdge.data.portDirection,
        newEdge.data.portDirection
      )
    ) {
      changes.push(
        new PageSyncChange(PageSyncChangeType.ChangeEdgePortDirection)
      );
    }

    return changes;
  }

  private static findNodeStyleChanges(
    oldNode: DiagramNodeDto,
    newNode: DiagramNodeDto,
    changes: PageSyncChange[]
  ): PageSyncChange[] {
    const oldStyles = SerializedDiagramUtils.getNodeStyles(oldNode);
    const newStyles = SerializedDiagramUtils.getNodeStyles(newNode);

    for (let i = 0; i < oldStyles.length && i < newStyles.length; ++i) {
      if (
        oldStyles[i].visualType === NodeVisualType.Image ||
        newStyles[i].visualType === NodeVisualType.Image
      ) {
        if (
          oldStyles[i].visualType === NodeVisualType.Image &&
          newStyles[i].visualType === NodeVisualType.Image &&
          !Comparer.anglesEqual(
            (oldStyles[i] as ImageNodeStyleDto).rotation,
            (newStyles[i] as ImageNodeStyleDto).rotation
          )
        ) {
          changes.push(
            new PageSyncChange(PageSyncChangeType.ChangeNodeAngle, {
              compositeStyleIndex: i
            })
          );
        }
        continue;
      }
      const oldStyle = oldStyles[i] as
        | ShapeNodeStyleDto
        | JigsawPathShapeNodeStyleDto;
      const newStyle = newStyles[i] as
        | ShapeNodeStyleDto
        | JigsawPathShapeNodeStyleDto;
      if (
        oldStyles[i].visualType !== NodeVisualType.DividingLine &&
        newStyles[i].visualType !== NodeVisualType.DividingLine
      ) {
        if (!Comparer.fillsEqual(oldStyle.fill, newStyle.fill)) {
          changes.push(
            new PageSyncChange(PageSyncChangeType.ChangeNodeFill, {
              compositeStyleIndex: i
            })
          );
        }
      }
      if (!Comparer.fillsEqual(oldStyle.stroke.fill, newStyle.stroke.fill)) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeStrokeFill, {
            compositeStyleIndex: i
          })
        );
      }

      if (
        !Comparer.thicknessEqual(
          oldStyle.stroke.thickness,
          newStyle.stroke.thickness
        )
      ) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeStrokeThickness, {
            compositeStyleIndex: i
          })
        );
      }

      if (
        !Comparer.dashesEqual(
          oldStyle.stroke.dashStyle,
          newStyle.stroke.dashStyle
        )
      ) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeNodeStrokeDashStyle, {
            compositeStyleIndex: i
          })
        );
      }
    }

    return changes;
  }

  private static findNodeJurisdictionChanges(
    oldNode: DiagramNodeDto,
    newNode: DiagramNodeDto,
    changes: PageSyncChange[]
  ): PageSyncChange[] {
    /* JURISDICTION CHANGES */
    const oldNodeJurisdiction = oldNode.dataProperties.find(
      (dp) =>
        dp.dataPropertyDefinitionId === appConsts.JURISDICTION_DEFINITION_ID
    );
    const newNodeJurisdiction = newNode.dataProperties.find(
      (dp) =>
        dp.dataPropertyDefinitionId === appConsts.JURISDICTION_DEFINITION_ID
    );

    if (
      !Comparer.nodeJurisdictionOrStateDataEquals(
        oldNodeJurisdiction,
        newNodeJurisdiction
      )
    ) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeJurisdiction));
    }

    if (
      !Comparer.nodeDataPropertyDisplayTypesEqual(
        oldNode.data.dataPropertyDisplayTypes,
        newNode.data.dataPropertyDisplayTypes,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.NodeLabel
      )
    ) {
      changes.push(
        new PageSyncChange(
          PageSyncChangeType.ChangeJurisdictionDisplayTypeNodeLabel
        )
      );
    }

    if (
      !Comparer.nodeDataPropertyDisplayTypesEqual(
        oldNode.data.dataPropertyDisplayTypes,
        newNode.data.dataPropertyDisplayTypes,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.Decorator
      )
    ) {
      changes.push(
        new PageSyncChange(
          PageSyncChangeType.ChangeJurisdictionDisplayTypeDecorator
        )
      );
    }

    // Check other jurisdiction changes only if jurisdiction exists
    if (oldNodeJurisdiction && newNodeJurisdiction) {
      if (
        !Comparer.nodeDataPropertyStyleStateEquals(
          oldNode.data.dataPropertyStyle,
          newNode.data.dataPropertyStyle
        )
      ) {
        changes.push(
          new PageSyncChange(
            PageSyncChangeType.ChangeNodeDataPropertyStyleState
          )
        );
      }

      if (
        !Comparer.jurisdictionLocationsEqual(
          oldNode.data.decorationStates,
          newNode.data.decorationStates
        )
      ) {
        changes.push(
          new PageSyncChange(PageSyncChangeType.ChangeJurisdictionLocation)
        );
      }

      /* STATE CHANGES */
      const oldNodeCountryState = oldNode.dataProperties.find(
        (dp) => dp.dataPropertyDefinitionId === appConsts.STATE_DEFINITION_ID
      );
      const newNodeCountryState = newNode.dataProperties.find(
        (dp) => dp.dataPropertyDefinitionId === appConsts.STATE_DEFINITION_ID
      );
      if (
        !Comparer.nodeJurisdictionOrStateDataEquals(
          oldNodeCountryState,
          newNodeCountryState
        )
      ) {
        changes.push(new PageSyncChange(PageSyncChangeType.ChangeCountryState));
      }

      if (oldNodeCountryState && newNodeCountryState) {
        if (
          !Comparer.nodeDataPropertyDisplayTypesEqual(
            oldNode.data.dataPropertyDisplayTypes,
            newNode.data.dataPropertyDisplayTypes,
            DataPropertyDisplayTypeNames.State,
            DataPropertyDisplayType.NodeLabel
          )
        ) {
          changes.push(
            new PageSyncChange(
              PageSyncChangeType.ChangeCountryStateDisplayTypeNodeLabel
            )
          );
        }

        if (
          !Comparer.nodeDataPropertyDisplayTypesEqual(
            oldNode.data.dataPropertyDisplayTypes,
            newNode.data.dataPropertyDisplayTypes,
            DataPropertyDisplayTypeNames.State,
            DataPropertyDisplayType.Decorator
          )
        ) {
          changes.push(
            new PageSyncChange(
              PageSyncChangeType.ChangeCountryStateDisplayTypeDecorator
            )
          );
        }
      }
    }

    return changes;
  }

  public static findAddedNodes(
    oldDiagram: DiagramDto,
    newDiagram: DiagramDto
  ): DiagramNodeDto[] {
    return newDiagram.nodes.filter(
      (newNode) =>
        this.canSyncNode(newNode) &&
        !oldDiagram.nodes.some((oldNode) => oldNode.uuid === newNode.uuid)
    );
  }

  public static findAddedEdges(
    oldDiagram: DiagramDto,
    newDiagram: DiagramDto
  ): DiagramEdgeDto[] {
    return newDiagram.edges.filter(
      (newEdge) =>
        this.canSyncEdge(newEdge) &&
        !oldDiagram.edges.some((oldEdge) => oldEdge.uuid === newEdge.uuid)
    );
  }

  private static findNodeSymbolChanges(
    oldNode: DiagramNodeDto,
    newNode: DiagramNodeDto,
    changes: PageSyncChange[]
  ): PageSyncChange[] {
    const oldSymbols = oldNode.dataProperties.filter((dp) =>
      appConsts.SYMBOLS_DEFINITION_IDS.includes(dp.dataPropertyDefinitionId)
    );
    const newSymbols = newNode.dataProperties.filter((dp) =>
      appConsts.SYMBOLS_DEFINITION_IDS.includes(dp.dataPropertyDefinitionId)
    );

    if (!Comparer.dataPropertyArraysEqual(oldSymbols, newSymbols)) {
      changes.push(new PageSyncChange(PageSyncChangeType.ChangeSymbol));
    }

    return changes;
  }
}
