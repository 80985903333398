import UserLoginApiService from '@/api/UserLoginApiService';
import Mutex from '@/core/common/Mutex';
import appConfig from '@/core/config/appConfig';
import { appendUrl } from '@/core/utils/common.utils';
import {
  Log,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore
} from 'oidc-client';
import { ErrorCodes } from './Errors/ErrorCodes';
import { ErrorHandler } from './Errors/ErrorHandler';
import { AuthStorageHelper } from './Utils/AuthStorageHelper';
import UrlParamsHelper from './Utils/UrlParamsHelper';

export const UtmSourceKey = 'utm_source';

class AuthService {
  private userManager: UserManager;

  private get oidcConfig(): UserManagerSettings {
    const webBaseUrl = window.location.origin;
    const oidcConfig = {
      // URL to the authentication server (including realm)
      authority: appConfig.authentication.authority,
      // Name of the client as defined on authentication server
      client_id: appConfig.authentication.clientId,
      // Where to redirect the user to after successful authentication
      redirect_uri: appendUrl(webBaseUrl, appConfig.oidc.redirectUri),
      silent_redirect_uri: appendUrl(webBaseUrl, appConfig.oidc.redirectUri),
      // Where to redirect the user to after logging the user out
      post_logout_redirect_uri: appendUrl(
        webBaseUrl,
        appConfig.oidc.logoutRedirectUri
      ),
      // Which flow to use for the authentication
      response_type: appConfig.oidc.responseType,
      // Which scopes to use for the authentication
      scope: appConfig.oidc.scope,
      // Where to get the response values
      response_mode: appConfig.oidc.responseMode,
      // Tokens are stored only in session storage
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      // userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
      // Store redirect state such as PKCE verifiers in session storage, for more reliable cleanup
      stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
      // Renew on the app's main URL and do so explicitly rather than via a background timer
      automaticSilentRenew: false,
      // We get user info separately from API, so that it is not limited to only OAuth user info
      loadUserInfo: false,
      monitorSession: !appConfig.debugMode
    };
    return oidcConfig;
  }

  private getAccessTokenMutex = new Mutex();
  private refreshAccessTokenMutex = new Mutex();
  private cachedAccessToken: string = null;

  public async init(onSignedOut: () => void = null): Promise<void> {
    Log.logger = console;
    Log.level = Log.WARN;
    // Log.level = Log.DEBUG;

    // Create the user manager
    this.userManager = new UserManager(this.oidcConfig);

    // When the user signs out from another browser tab, also remove tokens from this browser tab
    // This will only work if the Authorization Server has a check_session_iframe endpoint
    this.userManager.events.addUserSignedOut(() => {
      console.debug('[AUTH_SERVICE] userSignedOut');
      this.userManager.removeUser();
      AuthStorageHelper.isLoggedIn = false;
      if (onSignedOut) onSignedOut();
    });

    // Check if page invocation is an OAuth login response
    await this.handleLoginResponse();

    // Try to refresh access token if current url is not an OAuth callback
    if (!AuthStorageHelper.isLoggedIn && !UrlParamsHelper.getReturnUrl()) {
      await this.refreshAccessToken();
    }
  }

  /**
   * Returns true if current user is authenticated
   */
  public get isLoggedIn() {
    return AuthStorageHelper.isLoggedIn;
  }

  /**
   * Get an access token and login if required
   */
  public async getAccessToken(): Promise<string> {
    console.debug('[AUTH_SERVICE] getAccessToken');
    const unlock = await this.getAccessTokenMutex.lock();
    try {
      // If not logged in on any browser tab, do not return a token
      // This ensures that Tab B does not continue working after a logout on Tab A
      if (AuthStorageHelper.isLoggedIn) {
        // On most calls we just return the existing token from memory
        const user = await this.userManager.getUser();
        if (user && user.access_token && !user.expired) {
          this.cachedAccessToken = user.access_token;
          return this.cachedAccessToken;
        }
      }

      // If a new token is needed or the page is refreshed, try to refresh the access token
      this.cachedAccessToken = await this.refreshAccessToken();
      return this.cachedAccessToken;
    } finally {
      unlock();
    }
  }

  /**
   * Get current user id
   */
  public async getUserId(): Promise<number> {
    console.debug('[AUTH_SERVICE] getUserId');
    if (AuthStorageHelper.isLoggedIn) {
      const user = await this.userManager.getUser();
      const userId = user?.profile?.sub;
      if (userId) {
        return Number(userId);
      }
    }
    return null;
  }

  /**
   * Get cached access token (not guaranteed to be valid!)
   * Only use where async method (getAccessToken) is not an option
   */
  public getCachedAccessToken(): string {
    return this.cachedAccessToken;
  }

  /**
   * Try to refresh an access token
   */
  public async refreshAccessToken(): Promise<string> {
    console.debug('[AUTH_SERVICE] refreshAccessToken');
    const unlock = await this.refreshAccessTokenMutex.lock();
    try {
      // If not logged in on any browser tab, do not try an iframe redirect, to avoid slowness
      if (AuthStorageHelper.isLoggedIn) {
        // Try to refresh the access token via an iframe redirect
        // The UI has no way of determining if there is a valid Authorization Server session cookie
        await this.performTokenRefresh();

        // Return the renewed access token if found
        const user = await this.userManager.getUser();
        if (user && user.access_token) {
          return user.access_token;
        }
      }

      // Otherwise trigger a login redirect
      await this.startLogin();

      // End the API request which brought us here with an error code that can be ignored
      throw ErrorHandler.getFromLoginRequired();
    } finally {
      unlock();
    }
  }

  /**
   * Redirect in order to log out at the authorization server and remove the session cookie
   */
  public async startLogout(): Promise<void> {
    console.debug('[AUTH_SERVICE] startLogout');
    try {
      try {
        if (AuthStorageHelper.isLoggedIn) {
          // Can't use api proxy / axios here as it doesn't support credentials: 'omit' option
          // This option is required for the backend endpoint to extract user claims from the bearer token rather than cookies
          await fetch(
            appConfig.apiBaseUrl + appConfig.oidc.revokeAccessTokenUrl,
            {
              method: 'post',
              credentials: 'omit',
              headers: {
                Authorization: `Bearer ${this.getCachedAccessToken()}`
              }
            }
          );
        }
      } finally {
        // Remove the logged in flag, and other browser tabs will then act logged out
        AuthStorageHelper.isLoggedIn = false;
        // Do the redirect
        await this.userManager.signoutRedirect();
      }
    } catch (e) {
      // Handle failures
      throw ErrorHandler.getFromLogoutOperation(
        e,
        ErrorCodes.logoutRequestFailed
      );
    }
  }

  public async getStateDataValue(
    key: string,
    stateUrl: string = null
  ): Promise<string> {
    if (!stateUrl) {
      const returnUrl = UrlParamsHelper.getReturnUrl();
      if (!returnUrl) {
        return null;
      }
      stateUrl = decodeURIComponent(returnUrl);
    }

    const stateIdentifier = UrlParamsHelper.getState(stateUrl); // this will be xxx segment of 'oidc.xxx
    if (!stateIdentifier) {
      return null;
    }
    // grab the internal state from the store base don the current id
    const storedState =
      await this.userManager.settings.stateStore?.get(stateIdentifier);
    if (!storedState) {
      return null;
    }
    try {
      // the stored state is a json string
      const state = JSON.parse(storedState);
      if (!state.data) {
        return null;
      }
      return state.data[key] ?? null;
    } catch {
      console.error('Unable to parse stored state to object');
    }
    return null;
  }

  /**
   * Handle the response from the authorization server
   */
  public async handleLoginResponse(): Promise<void> {
    console.debug('[AUTH_SERVICE] handleLoginResponse');
    // If the page loads with a state query parameter we classify it as an OAuth response
    const state = UrlParamsHelper.getState();
    if (state) {
      // Only try to process a login response if the state exists
      const storedState =
        await this.userManager.settings.stateStore?.get(state);
      if (storedState) {
        let redirectLocation = '#';
        try {
          // Handle the login response
          const user = await this.userManager.signinRedirectCallback();

          // We will return to the app location before the login redirect
          redirectLocation = user.state?.hash;
          if (
            !redirectLocation ||
            redirectLocation.includes(appConfig.oidc.loginPageHash)
          ) {
            redirectLocation = appConfig.oidc.landingPageHash;
          }

          // Set the logged in flag, which prevents unnecessary iframe redirects
          AuthStorageHelper.isLoggedIn = true;
          // Re-enable autologin on successful login
          AuthStorageHelper.disableAutoLogin = false;
        } catch (e) {
          // Handle and rethrow OAuth response errors
          throw ErrorHandler.getFromLoginOperation(
            e,
            ErrorCodes.loginResponseFailed
          );
        } finally {
          if (redirectLocation) {
            // Always replace the browser location, to remove OAuth details from back navigation
            history.replaceState({}, document.title, redirectLocation);
          }
        }
      }
    }
  }

  /**
   * This method is for testing only, to make the access token in storage act like it has expired
   */
  public async expireAccessToken(): Promise<void> {
    console.debug('[AUTH_SERVICE] expireAccessToken');
    const user = await this.userManager.getUser();
    if (user) {
      user.access_token = 'x' + user.access_token + 'x';
      this.userManager.storeUser(user);
    }
  }

  /**
   * Do the interactive login redirect on the main window
   */
  private async startLogin(): Promise<void> {
    console.debug('[AUTH_SERVICE] startLogin');
    // Otherwise start a login redirect, by first storing the SPA's client side location
    // Some apps might also want to store form fields being edited in the state parameter
    const data = {
      hash: location.hash.length > 0 ? location.hash : '#',
      [UtmSourceKey]: new URLSearchParams(location.search).get(UtmSourceKey)
    };

    try {
      // Remove logged in flag from local storage
      AuthStorageHelper.isLoggedIn = false;

      // Start a login redirect
      await this.userManager.signinRedirect({
        state: data
      });
    } catch (e) {
      // Handle OAuth specific errors, such as those calling the metadata endpoint
      throw ErrorHandler.getFromLoginOperation(
        e,
        ErrorCodes.loginRequestFailed
      );
    }
  }

  /**
   * Try to refresh the access token by manually triggering a silent token renewal on an iframe
   * This will fail if there is no authorization server session cookie yet
   * It will also fail in Safari if there is a session cookie but it is not persistent
   * It may also fail if there has been no top level redirect yet for the current browser session
   */
  private async performTokenRefresh(): Promise<void> {
    console.debug('[AUTH_SERVICE] performTokenRefresh');
    try {
      // Redirect on an iframe using the Authorization Server session cookie and prompt=none
      // This instructs the Authorization Server to not render the login page on the iframe
      // If the request fails there should be a login_required error returned from the Authorization Server
      await this.userManager.signinSilent();
    } catch (e) {
      if (e.error === ErrorCodes.loginRequired) {
        // Clear token data and our code will then trigger a new login redirect
        await this.userManager.removeUser();
      } else {
        // Rethrow any technical errors
        throw ErrorHandler.getFromTokenError(e, ErrorCodes.tokenRenewalError);
      }
    }
  }
}

const instance = new AuthService();
export default instance;
