import JSize from '@/core/common/JSize';
import ExportOptions from '../../ExportOptions';
import ExportUtils from '../../ExportUtils';
import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageLayoutType,
  DocumentPageType,
  InsetsDto,
  LayoutDefinitionDto,
  LayoutItemDto
} from '@/api/models';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import ExportPage from '../../ExportPage';
import TextBoxLayoutItem from '@/components/LayoutEditor/Items/TextBoxLayoutItem';
import ExportConfig from '@/core/config/ExportConfig';
import { HtmlStylesToInlineOptions } from '../../HtmlStylesToInlineOptions';
import DocumentService from '../../../document/DocumentService';
import ContentPagination from '../../ContentPagination';
import stepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import PageBackgroundBehavior from '@/core/common/PageBackgroundBehavior';

type IPageLayoutDefinitions = {
  headerLayout?: LayoutDefinitionDto;
  footerLayout?: LayoutDefinitionDto;
  backgroundLayout?: LayoutDefinitionDto;
  titleLayout?: LayoutDefinitionDto;
  bodyLayout?: LayoutDefinitionDto;
};

export class OpenXmlExportUtils {
  public static readonly inlineProperties = [
    'font-size',
    'font-family',
    'font-style',
    'font-weight',
    'text-decoration-line',
    'margin-top',
    'margin-bottom'
  ];

  public static getContentSize(options: ExportOptions): JSize {
    return ExportUtils.calculateBodyPartSize(
      options.document,
      options.metadata.currentPage.page,
      options.metadata.currentPage.subPageIndex,
      'content',
      false
    );
  }

  public static async getHtmlPageContent(
    document: DocumentDto,
    exportPage: DocumentPageDto
  ): Promise<string[]> {
    if (!exportPage.content) return [];

    const inlineOptions = new HtmlStylesToInlineOptions();
    inlineOptions.containerClassList = [ExportConfig.pageContentClass];
    inlineOptions.setDimensionData = true;
    inlineOptions.updateLineHeights = 'compute';
    inlineOptions.includeMarkers = true;
    inlineOptions.properties = this.inlineProperties;

    const contentList: string[] = ContentPagination.splitPagedContentIntoPages(
      exportPage.content
    );
    for (let index = 0; index < contentList.length; index++) {
      inlineOptions.containerColumns = exportPage.getSubPageColumns(index);
      if (inlineOptions.containerColumns > 0) {
        inlineOptions.containerColumnGap =
          ExportUtils.calculateHtmlContentGap(document);
      }
      inlineOptions.containerSize = ExportUtils.calculateBodyPartSize(
        document,
        exportPage,
        index,
        'content',
        false
      );

      let content = contentList[index];
      content = await DocumentService.getContentWithHighlights(
        content,
        exportPage.collaborationData
      );
      content = await ExportUtils.htmlStylesToInline(content, inlineOptions);
      contentList[index] = content;
    }
    return contentList;
  }

  public static async getLayouts(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): Promise<IPageLayoutDefinitions> {
    const pageWidth = document.pageStyle.width;

    if (exportPage.page.contentType == DocumentPageContentType.Layout) {
      const bodyLayout = await LayoutUtils.cropLayoutItems(
        exportPage.page.bodyLayout,
        new JSize(pageWidth, document.pageStyle.height)
      );

      this.parseLayoutItemsStyles(bodyLayout);

      if (exportPage.page.layoutType === DocumentPageLayoutType.ContentTable) {
        const headerLayoutItems = await LayoutUtils.cropLayoutItems(
          this.getPageHeaderLayout(document, exportPage, index),
          new JSize(pageWidth, document.headerStyle.height)
        );
        const footerLayoutItems = await LayoutUtils.cropLayoutItems(
          this.getPageFooterLayout(document, exportPage, index),
          new JSize(pageWidth, document.footerStyle.height)
        );
        const backgroundLayoutItems = await LayoutUtils.cropLayoutItems(
          this.getPageBackgroundLayout(document, exportPage, index),
          new JSize(pageWidth, document.pageStyle.height)
        );

        this.parseLayoutItemsStyles(headerLayoutItems);
        this.parseLayoutItemsStyles(footerLayoutItems);
        this.parseLayoutItemsStyles(backgroundLayoutItems);

        return {
          headerLayout: {
            items: headerLayoutItems?.map((i) => this.layoutItemAsDto(i)),
            width: pageWidth,
            height: document.headerStyle.height ?? 0
          },
          footerLayout: {
            items: footerLayoutItems?.map((i) => this.layoutItemAsDto(i)),
            width: pageWidth,
            height: document.footerStyle.height ?? 0
          },
          backgroundLayout: {
            items: backgroundLayoutItems?.map((i) => this.layoutItemAsDto(i)),
            width: pageWidth,
            height: document.pageStyle.height
          },
          bodyLayout: {
            items: bodyLayout?.map((i) => this.layoutItemAsDto(i)),
            width: pageWidth,
            height: document.pageStyle.height
          }
        };
      }

      return {
        bodyLayout: {
          items: bodyLayout?.map((i) => this.layoutItemAsDto(i)),
          width: pageWidth,
          height: document.pageStyle.height
        }
      };
    }

    const titleHeight = this.getPageTitleHeight(document, exportPage, index);

    const headerLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageHeaderLayout(document, exportPage, index),
      new JSize(pageWidth, document.headerStyle.height)
    );
    const titleLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageTitleLayout(document, exportPage, index),
      new JSize(pageWidth, titleHeight)
    );
    const footerLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageFooterLayout(document, exportPage, index),
      new JSize(pageWidth, document.footerStyle.height)
    );
    const backgroundLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageBackgroundLayout(document, exportPage, index),
      new JSize(pageWidth, document.pageStyle.height)
    );

    this.parseLayoutItemsStyles(headerLayoutItems);
    this.parseLayoutItemsStyles(titleLayoutItems);
    this.parseLayoutItemsStyles(footerLayoutItems);
    this.parseLayoutItemsStyles(backgroundLayoutItems);

    let bodyLayoutItems: LayoutItem[];
    if (exportPage.page.contentType == DocumentPageContentType.MasterLegend) {
      bodyLayoutItems = await LayoutUtils.cropLayoutItems(
        this.getPageBodyLayout(document, exportPage),
        new JSize(pageWidth, document.pageStyle.height)
      );
      this.parseLayoutItemsStyles(bodyLayoutItems);
    }

    return {
      headerLayout: {
        items: headerLayoutItems?.map((i) => this.layoutItemAsDto(i)),
        width: pageWidth,
        height: document.headerStyle.height ?? 0
      },
      footerLayout: {
        items: footerLayoutItems?.map((i) => this.layoutItemAsDto(i)),
        width: pageWidth,
        height: document.footerStyle.height ?? 0
      },
      backgroundLayout: {
        items: backgroundLayoutItems?.map((i) => this.layoutItemAsDto(i)),
        width: pageWidth,
        height: document.pageStyle.height
      },
      titleLayout: {
        items: titleLayoutItems?.map((i) => this.layoutItemAsDto(i)),
        width: pageWidth,
        height: titleHeight
      },
      bodyLayout: {
        items: bodyLayoutItems?.map((i) => this.layoutItemAsDto(i)),
        width: pageWidth,
        height: document.pageStyle.height
      }
    };
  }

  // Powerpoint Export does not currently support full table layout in headers and footers so we need
  // to convert to something it will handle. Extract <td> data to <p>
  public static getPageHeaderLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? exportPage.page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.headerLayout
        ? subPageRef.headerLayout
        : exportPage.page.headerLayout;
    }
  }

  public static getPageFooterLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? exportPage.page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.footerLayout
        ? subPageRef.footerLayout
        : exportPage.page.footerLayout;
    }
  }

  public static getPageTitleLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef ? subPageRef.titleLayout : exportPage.page.titleLayout;
    }
  }

  public static getPageBackgroundLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const page = exportPage.page;
      // Temp until we make content table pages configurable from Steps Designer
      if (page.layoutType === DocumentPageLayoutType.ContentTable) {
        return null;
      }
      if (
        stepsDesignerConfig.get.pageBackgroundBehavior ==
        PageBackgroundBehavior.Separate
      ) {
        const pageDesign = ExportUtils.getPageDesign(page, index);
        if (pageDesign?.backgroundLayout) {
          return pageDesign?.backgroundLayout;
        }
      }
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.backgroundLayout
        ? subPageRef.backgroundLayout
        : page.backgroundLayout;
    }
  }

  public static getPageBodyLayout(
    document: DocumentDto,
    exportPage: ExportPage
  ): string {
    if (document.hasSteps) {
      return exportPage.page.bodyLayout;
    }
  }

  public static getPageTitleHeight(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): number {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef
        ? ExportUtils.calculatePageTitleHeight(subPageRef, subPageRef.showTitle)
        : ExportUtils.calculatePageTitleHeight(
            exportPage.page,
            exportPage.page.showTitle
          );
    }
    return 0;
  }

  public static forceReservePageTitleSpace(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): boolean {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef
        ? subPageRef.maxTitleHeight > 0
        : exportPage.page.maxTitleHeight > 0;
    }
    return false;
  }

  public static parseLayoutItemsStyles(layoutItems: LayoutItem[]): void {
    layoutItems.forEach((layoutItem) => {
      if (!(layoutItem instanceof TextBoxLayoutItem)) {
        return;
      }
      // styles with [!important] will be broken on PPT
      layoutItem.html = layoutItem.html.replaceAll('!important', '');
    });
  }

  public static calculateAvailablePageSize(
    options: ExportOptions,
    pageMargins: InsetsDto
  ): JSize {
    const page = options.metadata.currentPage.page;
    const subPageIndex = options.metadata.currentPage.subPageIndex;
    const document = options.document;
    const isHalfScreen =
      page.getSubPageType(subPageIndex) == DocumentPageType.Split &&
      page.contentType != DocumentPageContentType.MasterLegend;

    const padding = ExportUtils.calculatePadding(
      document,
      page,
      subPageIndex,
      'diagram'
    ).multiply(ExportConfig.pointToPixelFactor);

    let availableWidth =
      document.pageStyle.width -
      pageMargins.left -
      pageMargins.right -
      padding.left -
      padding.right;

    if (isHalfScreen) {
      availableWidth = availableWidth * document.pageStyle.splitRatio;
    }

    const titleHeight = this.getPageTitleHeight(
      options.document,
      options.metadata.currentPage,
      options.metadata.currentPage.subPageIndex
    );

    const availableHeight =
      document.pageStyle.height -
      pageMargins.bottom -
      pageMargins.top -
      padding.top -
      padding.bottom -
      titleHeight;

    return new JSize(availableWidth, availableHeight).multiply(
      ExportConfig.pointToPixelFactor
    );
  }

  public static layoutItemAsDto(item: LayoutItem): LayoutItemDto {
    return item as unknown as LayoutItemDto;
  }

  public static layoutItemDtoAsItem(item: LayoutItemDto): LayoutItem {
    return item as unknown as LayoutItem;
  }
}
