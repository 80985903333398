// Body key should not use subPageIndex as it breaks content pagination
export const getBodyKey = (id: number, subPageIndex?: number) => `${id}-body`;
export const getHeaderKey = (id: number, subPageIndex?: number) =>
  `${id}-${subPageIndex}-header`;
export const getFooterKey = (id: number, subPageIndex?: number) =>
  `${id}-${subPageIndex}-footer`;
export const getBackgroundKey = (id: number, subPageIndex?: number) =>
  `${id}-${subPageIndex}-background`;
export const getTitleKey = (id: number, subPageIndex?: number) =>
  `${id}-${subPageIndex}-title`;
export const leftHandSidebarBorderSize = 3;
