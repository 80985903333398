import config from '@/core/config/diagram.definition.config';
import { Module } from 'vuex';

export const GLOBAL_NAMESPACE = 'global';
export const GET_GLOBAL_LOADING = 'getGlobalLoading';
export const GET_APP_SIDEBAR_WIDTH = 'getAppSideBarWidth';
export const GET_HALLOWEEN_THEME = 'getHalloweenTheme';
export const GET_THANKSGIVING_THEME = 'getThanksgivingTheme';
export const GET_CHRISTMAS_THEME = 'getChristmasTheme';
export const SET_SEASON_THEME = 'setSeasonTheme';
export const SET_GLOBAL_LOADING = 'setGlobalLoading';
export const SET_APP_SIDEBAR_WIDTH = 'setAppSideBarWidth';

export const SET_IS_COMPACT_MENU = 'setIsCompactMenu';
export const GET_IS_COMPACT_MENU = 'getIsCompactMenu';

export const SET_IS_MENU_EXTENDED = 'setIsMenuExtended';
export const GET_IS_MENU_EXTENDED = 'getIsMenuExtended';

const getInitialState = () => {
  return {
    loading: false,
    appSideBarWidth: config.sideBarWidth,
    seasonalTheme: null,
    isCompactMenu: false,
    isMenuExtended: false
  };
};

interface State {
  loading: boolean;
  appSideBarWidth: number;
  seasonalTheme: string | null;
  isCompactMenu: boolean;
  isMenuExtended: boolean;
}

const globalModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_GLOBAL_LOADING](state) {
      return state.loading;
    },
    [GET_APP_SIDEBAR_WIDTH](state) {
      return state.appSideBarWidth;
    },
    [GET_HALLOWEEN_THEME](state) {
      return state.seasonalTheme === 'Halloween';
    },
    [GET_THANKSGIVING_THEME](state) {
      return state.seasonalTheme === 'Thanksgiving';
    },
    [GET_CHRISTMAS_THEME](state) {
      return state.seasonalTheme === 'Christmas';
    },
    [GET_IS_COMPACT_MENU](state) {
      return state.isCompactMenu;
    },
    [GET_IS_MENU_EXTENDED](state) {
      return state.isMenuExtended;
    }
  },
  mutations: {
    [SET_GLOBAL_LOADING](state, payload: boolean) {
      state.loading = payload;
    },
    [SET_APP_SIDEBAR_WIDTH](state, payload: number) {
      state.appSideBarWidth = payload;
    },
    [SET_SEASON_THEME](state, payload: string) {
      state.seasonalTheme = payload;
    },
    [SET_IS_COMPACT_MENU](state, payload: boolean) {
      state.isCompactMenu = payload;
    },
    [SET_IS_MENU_EXTENDED](state, payload: boolean) {
      state.isMenuExtended = payload;
    }
  }
};

export default globalModule;
