import { Module } from 'vuex';

// namespace
export const DATA_PANEL_NAMESPACE = 'data-panel';

// getters
export const GET_DATA_PANEL_OPEN = 'getDataPanelOpen';

// mutations
export const SET_DATA_PANEL_OPEN = 'setDataPanelOpen';

// actions ...

interface State {
  isDataPanelOpen: boolean;
}

const dataPanelModule: Module<State, any> = {
  namespaced: true,

  state: {
    isDataPanelOpen: false
  },

  getters: {
    [GET_DATA_PANEL_OPEN](state) {
      return state.isDataPanelOpen;
    }
  },

  mutations: {
    [SET_DATA_PANEL_OPEN](state, payload) {
      state.isDataPanelOpen = payload;
    }
  },

  actions: {}
};

export default dataPanelModule;
