import { CanvasComponent, IRectangle, Rect, Size } from 'yfiles';

/**
 * This method will convert the given exportRect to world coordinates. The export rect, x, y, w, h is expected to be in percentages of values between 0-1
 * @param exportRect T
 * @param canvasComponent
 * @returns
 */
export function toWorldRect(
  exportRect: IRectangle,
  canvasComponent: CanvasComponent
): Rect {
  const viewPortSize = canvasComponent.viewport
    .toSize()
    .multiply(canvasComponent.zoom);
  const x = viewPortSize.width * exportRect.x;
  const y = viewPortSize.height * exportRect.y;
  return new Rect(
    x,
    y,
    viewPortSize.width * exportRect.width,
    viewPortSize.height * exportRect.height
  );
}

export function calculateScale(contentSize: Size, containerSize: Size): number {
  const innerWidth: number = contentSize.width;
  const innerHeight: number = contentSize.height;

  const outerWidth: number = containerSize.width;
  const outerHeight: number = containerSize.height;
  const innerAspectRatio = innerWidth / innerHeight;
  const outerAspectRatio = outerWidth / outerHeight;

  if (innerAspectRatio > outerAspectRatio) {
    // inner rectangle is wider than outer rectangle, so scale down by outerWidth / innerWidth
    return outerWidth / innerWidth;
  } else {
    // inner rectangle is taller than outer rectangle, so scale down by outerHeight / innerHeight
    return outerHeight / innerHeight;
  }
}
