import Vue from 'vue';
import Shepherd from '@jigsaw/shepherd.js';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import {
  ICanvasObjectDescriptor,
  ICanvasObjectGroup,
  IEdge,
  IEdgeStyle,
  IGraph,
  IHandle,
  IHandleProvider,
  IModelItem,
  INode,
  INodeStyle,
  SvgVisual
} from 'yfiles';
import SpotlightVisual from '@/v2/yfiles/background-visuals/SpotlightVisual';
import {
  EdgeSection,
  TutorialStep,
  TutorialStepsMap
} from '@/core/services/tutorial/ITutorial';
import TutorialStepActionHandler from '@/core/services/tutorial/TutorialStepActionHandler';
import {
  EventBus,
  EventBusActions
} from '@/core/services/events/eventbus.service';
import { offset } from '@floating-ui/dom';
import {
  DocumentDto,
  EditDocumentAutoSaveDto,
  QuickStartState,
  TutorialDto,
  TutorialType
} from '@/api/models';
import {
  AutoZoomState,
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  GET_READONLY,
  SET_DOCUMENT_AUTOSAVE,
  SET_DOCUMENT_TUTORIAL,
  SET_EDGE_LABEL_PLACEHOLDER_ENABLED,
  SET_SELECTED_PAGE_SHOW_LEGEND,
  SET_SELECTED_PAGE_SHOW_LOGO
} from '@/core/services/store/document.module';
import {
  GET_DISPLAY_LANGUAGE,
  GET_TUTORIALS_STEPS_MAP,
  LOAD_DATA,
  RESET_ADMIN_UI_STATE,
  SET_TOUR_STEP,
  SET_TOUR_TYPE,
  TRAINING_NAMESPACE
} from '@/core/services/store/training.module';
import router from '@/JigsawVueRouter';
import { RouteNames } from '@/routes';
import { RouterParams } from '@/core/config/routerParams';
import { notify } from '@/components/shared/AppNotification';
import DiagramUtils from '@/core/utils/DiagramUtils';
import QuickBuildService from '@/core/services/graph/quick-build.service';
import JigsawButtonInputMode from '@/core/services/graph/input-modes/button/JigsawButtonInputMode';
import i18n from '@/core/plugins/vue-i18n';
import {
  findStackingContextParent,
  getRelativeParentRect
} from '@/core/utils/html.utils';
import diagramConfig from '@/core/config/diagram.definition.config';
import { JigsawPortRelocationHandle } from '../graph/JigsawPortRelocationHandle';
import isNil from 'lodash/isNil';
import TutorialActionMediator from '@/core/services/tutorial/TutorialActionMediator';
import ZoomService from '@/core/services/graph/ZoomService';
import TutorialLayoutService from '@/core/services/tutorial/TutorialLayoutService';
import { DocumentSaveEventArgs } from '@/view/pages/document/DocumentSaveEventArgs';
import DocumentService from '@/core/services/document/DocumentService';
import TutorialUserInputController from '@/core/services/tutorial/TutorialUserInputController';
import debounce from 'lodash/debounce';
import ExportConfig from '@/core/config/ExportConfig';
import PendoService, {
  PendoEventName
} from '@/core/services/analytics/PendoService';
import { AnalyticsEvent } from '@/core/services/analytics/AnalyticsEvent';
import AnalyticsService from '@/core/services/analytics/AnalyticsService';

export type TutorialSettings = {
  steps: TutorialStep[];
  /**
   * A function called when the tutorial is completed
   */
  document?: DocumentDto;
  onCompleted?: () => void;
  processActions?: boolean;
  restrictActions?: boolean;
  demoPreview?: boolean;
};

export default class TutorialService {
  public static graphService: IGraphService;
  private static tour: Shepherd.Tour;
  private static currentStepIndex: number = 0;
  private static settings: TutorialSettings;
  private static spotlightVisual: SpotlightVisual;
  private static spotlightGroup: ICanvasObjectGroup;
  private static highlightDiv: HTMLDivElement;
  private static allowedElements: HTMLElement[];
  public static inProgress: boolean;
  public static readonly tourDelay = 500;
  private static stepTargetMutationObserver: MutationObserver;
  private static stepResizeObserver: ResizeObserver;
  private static targetItem: IModelItem | string;
  private static targetElement: Element;
  private static targetHandle: IHandle | false;
  private static nextStepTimeout: ReturnType<typeof setTimeout>;

  private static get zoomService(): ZoomService {
    return TutorialService.graphService.getService<ZoomService>(
      ZoomService.$class
    );
  }

  private static fitDiagramDebounced = debounce(
    (): void => {
      TutorialService.zoomService.fitCurrentDiagram({ force: true });
    },
    100,
    { trailing: true }
  );

  public static toggleElements(step?: TutorialStep): void {
    if (TutorialService.highlightDiv) {
      TutorialService.highlightDiv.style.removeProperty('display');
    }

    TutorialLayoutService.toggleLayoutItems(step);

    if (step?.hideHighlight && TutorialService.highlightDiv) {
      TutorialService.highlightDiv.style.display = 'none';
    }
  }

  private static setSettings(settings: TutorialSettings): void {
    TutorialService.settings = settings;

    TutorialService.settings.processActions =
      TutorialService.settings.processActions ?? true;
  }

  public static destroy(): void {
    window.removeEventListener('resize', TutorialService.fitDiagramDebounced);

    document
      .querySelector(`.${ExportConfig.outerPageContainerClass}`)
      ?.classList.remove(diagramConfig.contextMenu.disallowCloseOnClickClass);

    TutorialLayoutService.toggleActionToolbarButtons(true);
    TutorialUserInputController.reset();

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_LABEL_PLACEHOLDER_ENABLED}`,
      true
    );

    Vue.$globalStore.dispatch(`${TRAINING_NAMESPACE}/${SET_TOUR_STEP}`, null);

    if (TutorialService.graphService) {
      if (DocumentService.selectedPage) {
        TutorialService.zoomService.setAutoZoom(AutoZoomState.On);
      }
      TutorialService.graphService = null;
    }

    if (TutorialService.nextStepTimeout) {
      clearTimeout(TutorialService.nextStepTimeout);
    }
    TutorialService.settings = null;
    TutorialService.allowedElements = [];
    TutorialService.targetItem = null;
    TutorialService.targetElement = null;
    TutorialService.targetHandle = null;

    TutorialService.inProgress = false;
    TutorialActionMediator.destroy();
    TutorialService.scrollListener.removeListener();
    TutorialService.removeSpotlight();
    TutorialService.highlightDiv = null;
    TutorialService.stepResizeObserver?.disconnect();
    TutorialService.stepTargetMutationObserver?.disconnect();
    TutorialService.stepTargetMutationObserver = null;
    TutorialStepActionHandler.unregisterAllEvents();

    if (TutorialService.tour) {
      TutorialService.tour.steps.forEach((step) => {
        step.destroy();
      });
      TutorialService.tour.hide();
      TutorialService.tour = null;
    }
  }

  public static removeSpotlight(): void {
    TutorialService.highlightDiv?.remove();
    TutorialService.spotlightGroup?.remove();
    TutorialService.spotlightVisual = null;
    TutorialService.spotlightGroup = null;
  }

  private static setAllowedElements(): void {
    TutorialService.allowedElements = [
      window.document.getElementById('onboardingAdminUI'),
      window.document.getElementById(
        'vc-ribbon-quick-launch-toolbar-container'
      ),
      window.document.querySelector('.onboarding-admin-ui-button'),
      window.document.getElementById('tutorial-progress')
    ];
  }

  public static async tryStartUserTutorial(
    graphService: IGraphService,
    document: DocumentDto
  ): Promise<void> {
    TutorialService.destroy();
    if (
      !document.tutorial ||
      document.tutorial.complete ||
      TutorialService.inProgress
    ) {
      return;
    }
    TutorialService.inProgress = true;

    const isReadonly: boolean =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_READONLY}`];

    await Vue.$globalStore.dispatch(
      `${TRAINING_NAMESPACE}/${RESET_ADMIN_UI_STATE}`
    );
    await Vue.$globalStore.dispatch(`${TRAINING_NAMESPACE}/${LOAD_DATA}`);

    if (!isReadonly && document.tutorial && !document.tutorial.complete) {
      const stepsMap: TutorialStepsMap =
        Vue.$globalStore.getters[
          `${TRAINING_NAMESPACE}/${GET_TUTORIALS_STEPS_MAP}`
        ];
      TutorialService.toggleElements(stepsMap[document.tutorial.type][0]);
      TutorialService.beforeStartTutorial(graphService, document);
      TutorialService.nextStepTimeout = setTimeout(async () => {
        if (!stepsMap) return;

        PendoService.triggerPendoEvent(PendoEventName.TUTORIAL_STARTED);
        AnalyticsService.trackEvent(AnalyticsEvent.TutorialStarted);

        TutorialService.setAllowedElements();
        await TutorialService.startTutorial(graphService, {
          document,
          steps: stepsMap[document.tutorial.type].slice(document.tutorial.step)
        });
      }, TutorialService.tourDelay);
    }
  }

  public static tryStartDemoTutorial(
    graphService: IGraphService,
    settings: TutorialSettings,
    tutorialType?: TutorialType
  ): void {
    TutorialService.destroy();
    if (settings.processActions) {
      TutorialService.beforeStartTutorial(
        graphService,
        null,
        true,
        tutorialType
      );
    }
    TutorialService.inProgress = true;
    TutorialService.toggleElements(settings.steps[0]);
    TutorialService.nextStepTimeout = setTimeout(() => {
      TutorialService.setAllowedElements();
      TutorialService.startTutorial(graphService, {
        ...settings,
        demoPreview: true
      });
    }, TutorialService.tourDelay);
  }

  public static async resetTutorialProgress(
    graph: IGraph,
    document: DocumentDto
  ): Promise<void> {
    try {
      graph.clear();

      await Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_TUTORIAL}`,
        {
          step: 0,
          complete: false
        }
      );

      await TutorialService.saveDocument();

      await router.replace({
        name: RouteNames.documentDetails,
        params: {
          [RouterParams.documentId]: document.id.toString()
        }
      });
    } catch (e) {
      console.debug('Could not reset tutorial progress: ', e);
    }
  }

  private static animate(spotlightTarget: HTMLElement): void {
    if (!TutorialService.highlightDiv?.parentElement) return;
    const gap = diagramConfig.tutorial.spotlight.padding;
    const rect = spotlightTarget.getBoundingClientRect();

    const relativeRect = getRelativeParentRect(TutorialService.highlightDiv);
    TutorialService.highlightDiv.style.width = `${rect.width + 2 * gap}px`;
    TutorialService.highlightDiv.style.height = `${rect.height + 2 * gap}px`;
    TutorialService.highlightDiv.style.top = `${
      rect.top - gap - relativeRect.top
    }px`;
    TutorialService.highlightDiv.style.left = `${
      rect.left - gap - relativeRect.left
    }px`;

    if (TutorialService.highlightDiv.parentElement) {
      requestAnimationFrame(() => TutorialService.animate(spotlightTarget));
    }
  }

  public static beforeStartTutorial(
    graphService: IGraphService,
    document?: DocumentDto,
    testMode?: boolean,
    tutorialType?: TutorialType
  ): void {
    TutorialLayoutService.toggleActionToolbarButtons(false);

    let type = tutorialType;
    if (document) {
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_AUTOSAVE}`,
        {
          documentId: document.id,
          autoSave: false
        } as EditDocumentAutoSaveDto
      );

      type = tutorialType ?? document.tutorial?.type;
    }

    if (!isNil(type)) {
      Vue.$globalStore.dispatch(`${TRAINING_NAMESPACE}/${SET_TOUR_TYPE}`, type);
    }

    const resetDocument = document?.tutorial?.step === 0 || testMode;
    if (resetDocument) {
      const qbService = graphService.getService<QuickBuildService>(
        QuickBuildService.$class
      );
      if (qbService) {
        qbService.setQuickStartState(QuickStartState.Initial, true);
        setTimeout(() => {
          TutorialLayoutService.toggleQSButton(false);
        });
      }

      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_SHOW_LOGO}`
      );
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_SHOW_LEGEND}`
      );

      if (
        graphService.graph.nodes.size !== 1 ||
        graphService.graph.edges.size
      ) {
        graphService.graph.clear();
        DiagramUtils.createInitialEntity(false);
      }
    }

    const jigsawButtonInputMode =
      graphService.getService<JigsawButtonInputMode>(
        JigsawButtonInputMode.$class.name
      );
    if (jigsawButtonInputMode) {
      jigsawButtonInputMode.queryAllButtons();
    }
  }

  public static async startTutorial(
    graphService: IGraphService,
    settings: TutorialSettings
  ): Promise<void> {
    window.addEventListener('resize', TutorialService.fitDiagramDebounced);

    TutorialService.currentStepIndex = 0;
    AnalyticsService.trackPageView('Onboarding-Step-1');
    TutorialActionMediator.registerEvents();

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_LABEL_PLACEHOLDER_ENABLED}`,
      false
    );

    const locale =
      Vue.$globalStore.getters[
        `${TRAINING_NAMESPACE}/${GET_DISPLAY_LANGUAGE}`
      ] ?? i18n.locale;

    TutorialService.setSettings(settings);

    TutorialService.graphService = graphService;
    TutorialService.highlightDiv = document.createElement('div');
    TutorialService.highlightDiv.classList.add('highlight-effect');
    TutorialService.highlightDiv.style.borderColor =
      diagramConfig.tutorial.spotlight.color;
    TutorialService.highlightDiv.style.borderWidth = `${diagramConfig.tutorial.spotlight.thickness}px`;

    TutorialService.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: false
        },
        classes: `tourguide-dialog ${diagramConfig.contextMenu.disallowCloseOnClickClass}`
      },
      keyboardNavigation: false,
      exitOnEsc: false,
      useModalOverlay: false,
      modalContainer: document.body
    });

    if (TutorialService.settings.processActions) {
      TutorialUserInputController.init(graphService);
      await TutorialUserInputController.handleStep(
        TutorialService.tour,
        TutorialService.settings.steps[0]
      );
    }

    TutorialService.settings.steps.forEach((step) => {
      let element: HTMLElement | (() => HTMLElement) = null;
      if (step.attachTo) {
        element = (): undefined | HTMLElement => {
          const targetItem: IModelItem | string =
            !step.attachTo.element.startsWith('EDGE::') ||
            step.attachTo.edgeSection === EdgeSection.EntireEdge
              ? TutorialService.tryGetModelItemFromSelector(
                  step.attachTo.element
                )
              : step.attachTo.element;

          const targetHandle =
            step.attachTo.element.startsWith('EDGE::') &&
            step.attachTo.edgeSection !== EdgeSection.EntireEdge
              ? TutorialService.tryGetEdgeHandle(
                  step.attachTo.element,
                  step.attachTo.edgeSection
                )
              : false;

          const targetElement = TutorialService.getTargetFromSelector(
            step.attachTo.element,
            step.attachTo.isWithinCanvas,
            step.attachTo.edgeSection
          );
          TutorialService.targetItem = targetItem;
          TutorialService.targetElement = targetElement;

          TutorialService.createStepTargetMutationObserver(
            targetElement,
            step.attachTo.isWithinCanvas,
            step.hideHighlight,
            step.attachTo.element
          );
          if (!targetElement || targetHandle === null) {
            return;
          } else {
            TutorialService.targetHandle = targetHandle;
          }
          const spotlightTarget = targetHandle
            ? targetHandle
            : targetItem instanceof IModelItem
              ? targetItem
              : targetElement;

          if (!step.hideHighlight) {
            TutorialService.attachSpotlightToTarget(
              spotlightTarget,
              step.attachTo.isWithinCanvas
            );
          } else {
            TutorialService.removeSpotlight();
          }

          if (
            spotlightTarget &&
            !step.attachTo.isWithinCanvas &&
            !step.hideHighlight
          ) {
            let parent = findStackingContextParent(
              spotlightTarget as HTMLElement
            );
            let zIndex = getComputedStyle(
              spotlightTarget as HTMLElement
            ).zIndex;
            if (parent.classList.contains('contextmenu')) {
              zIndex = getComputedStyle(parent).zIndex; // The context menu zIndex
              parent = document.body;
            }
            TutorialService.highlightDiv.style.zIndex = zIndex;
            parent.appendChild(TutorialService.highlightDiv);
          }

          if (!step.attachTo.isWithinCanvas) {
            TutorialService.scrollListener.addListener();
          }

          return targetElement as HTMLElement;
        };
      }

      TutorialService.tour.addStep({
        id: step.id,
        title:
          (step.translations && step.translations[locale]?.title) ?? step.title,
        text:
          (step.translations && step.translations[locale]?.text) ?? step.text,
        classes: step.arrow ? 'with-arrow' : '',
        attachTo: element
          ? {
              element: element,
              on: step.attachTo.side
            }
          : {},
        floatingUIOptions: step.promptOffset
          ? {
              noStepFocus: true,
              middleware: [
                offset((options) => {
                  const { placement, rects } = options;
                  const xAxisOffset = Math.round(
                    window.innerWidth * (step.promptOffset.xAxisOffset / 1000)
                  );
                  const yAxisOffset = Math.round(
                    window.innerHeight * (step.promptOffset.yAxisOffset / 1000)
                  );

                  switch (placement) {
                    case 'top':
                    case 'top-start':
                    case 'top-end':
                    case 'bottom':
                    case 'bottom-start':
                    case 'bottom-end':
                      return {
                        mainAxis: yAxisOffset,
                        crossAxis: xAxisOffset
                      };
                    case 'left':
                    case 'left-start':
                    case 'left-end':
                    case 'right':
                    case 'right-start':
                    case 'right-end':
                      return {
                        mainAxis: xAxisOffset,
                        crossAxis: yAxisOffset
                      };
                  }
                })
              ]
            }
          : undefined,
        modalTarget:
          step.restrictTo === 'canvas'
            ? TutorialService.graphService.graphComponent.div
            : null,
        modalAllowedElements: TutorialService.allowedElements,
        beforeShowPromise: (): Promise<void> => {
          return new Promise((resolve) => {
            if (
              TutorialService.currentStepIndex === 0 &&
              graphService.graph.nodes.at(0)
            ) {
              graphService.graphComponent.selection.setSelected(
                graphService.graph.nodes.at(0),
                true
              );
            }

            TutorialService.scrollListener.removeListener();

            Vue.$globalStore.dispatch(
              `${TRAINING_NAMESPACE}/${SET_TOUR_STEP}`,
              step
            );

            if (TutorialService.settings.processActions) {
              TutorialStepActionHandler.processAction(step, 0, () => {
                const stepIndex = TutorialService.settings.steps.indexOf(step);

                TutorialService.currentStepIndex = stepIndex + 1;

                if (!TutorialService.settings?.demoPreview) {
                  Vue.$globalStore
                    .dispatch(
                      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_TUTORIAL}`,
                      {
                        step: stepIndex + 1
                      } as Partial<TutorialDto>
                    )
                    .then(() => {
                      EventBus.$emit(EventBusActions.DOCUMENT_SAVE, {
                        force: true,
                        spinner: false
                      } as DocumentSaveEventArgs);
                    });

                  PendoService.triggerPendoEvent(
                    PendoEventName.TUTORIAL_STEP_COMPLETED,
                    {
                      step: stepIndex + 1
                    }
                  );
                  AnalyticsService.trackEvent(
                    AnalyticsEvent.TutorialStepCompleted,
                    {
                      step: stepIndex + 1
                    }
                  );
                }

                // Toggle the layout elements before showing the next step
                if (stepIndex < TutorialService.settings.steps.length - 1) {
                  TutorialService.toggleElements(
                    TutorialService.settings.steps[stepIndex + 1]
                  );
                }

                TutorialStepActionHandler.unregisterAllEvents();

                TutorialUserInputController.disableAll();

                TutorialService.nextStepTimeout = setTimeout(async () => {
                  if (TutorialService.settings.processActions) {
                    await TutorialUserInputController.handleStep(
                      TutorialService.tour,
                      TutorialService.settings.steps[stepIndex + 1]
                    );
                  }
                  TutorialService.stepTargetMutationObserver?.disconnect();
                  TutorialService.stepTargetMutationObserver = null;
                  TutorialService.tour.next();

                  if (
                    stepIndex + 1 <=
                    TutorialService.settings.steps.length - 1
                  ) {
                    AnalyticsService.trackPageView(
                      `Onboarding-Step-${TutorialService.currentStepIndex + 1}`
                    );
                  }
                }, TutorialService.tourDelay);
              });
            }

            if (!TutorialService.currentStepHasTarget()) {
              setTimeout(() => {
                TutorialService.tour?.getCurrentStep().hide(true);
              });
            }
            resolve();
          });
        },
        when: {
          show: () => {
            const dialogs = document.querySelectorAll('.tourguide-dialog');
            const dialog = dialogs[dialogs.length - 1] as HTMLElement;
            if (dialog) {
              if (step.dialogWidth != null) {
                dialog.style.width = `${step.dialogWidth}px`;
                dialog.removeAttribute('tabindex');
              }

              const dialogTarget =
                document.body.querySelector('.shepherd-target');
              // Move prompt box in front of context menu. If the modal is open the context menu will close anyways.
              if (dialogTarget && dialogTarget.closest('.contextmenu')) {
                dialog.style.zIndex = '3000';
              }
            }
          },
          hide: () => {
            TutorialService.removeSpotlight();
          }
        }
      });
    });

    TutorialService.tour.once('complete', async () => {
      if (!TutorialService.graphService?.graphComponent?.inputMode) {
        return;
      }

      TutorialService.inProgress = false;
      if (TutorialService.settings.onCompleted)
        TutorialService.settings.onCompleted();
      TutorialStepActionHandler.unregisterAllEvents();

      // Only for testing. For user tutorial, we use redirect, and we don't
      // want to toggle elements before redirect to avoid flickering
      if (this.settings.demoPreview) {
        TutorialService.toggleElements();
      }

      TutorialService.removeSpotlight();
      TutorialService.stepTargetMutationObserver?.disconnect();

      const currentStep = TutorialService.tour?.getCurrentStep();
      if (currentStep) {
        currentStep.hide();
      }

      EventBus.$emit(EventBusActions.TUTORIAL_COMPLETED);

      PendoService.triggerPendoEvent(PendoEventName.TUTORIAL_COMPLETED);
      AnalyticsService.trackEvent(AnalyticsEvent.TutorialCompleted);

      if (!TutorialService.settings?.demoPreview) {
        await Vue.$globalStore.dispatch(
          `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_TUTORIAL}`,
          { complete: true } as Partial<TutorialDto>
        );

        setTimeout(async () => {
          // Fade-out overlay
          const fadeOverlay = document.createElement('div');
          fadeOverlay.style.position = 'fixed';
          fadeOverlay.style.top = '0';
          fadeOverlay.style.left = '0';
          fadeOverlay.style.width = '100%';
          fadeOverlay.style.height = '100%';
          fadeOverlay.style.backgroundColor = 'white';
          fadeOverlay.style.zIndex = '1000';
          fadeOverlay.style.opacity = '0';
          fadeOverlay.style.transition = 'opacity 0.3s';
          document.body.appendChild(fadeOverlay);

          await TutorialService.saveDocument();
          fadeOverlay.style.opacity = '1';

          setTimeout(() => {
            router.replace({
              name: RouteNames.trainingCongratulations
            });
            fadeOverlay.remove();

            TutorialService.destroy();
          }, TutorialService.tourDelay * 2);
        }, TutorialService.tourDelay);
      } else {
        setTimeout(async () => {
          await notify({
            title:
              'Tutorial complete; The user will be redirected to congrats page.',
            type: 'success'
          });
          TutorialService.destroy();
        }, TutorialService.tourDelay);
      }
    });

    Vue.$globalStore.dispatch(
      `${TRAINING_NAMESPACE}/${SET_TOUR_STEP}`,
      TutorialService.settings.steps[0]
    );

    setTimeout(() => {
      TutorialService.tour.start();

      TutorialService.createStepResizeObserver();

      const overlayContainer = document.querySelector(
        '.shepherd-modal-overlay-container'
      );
      if (overlayContainer) {
        document
          .querySelector(`.${ExportConfig.outerPageContainerClass}`)
          ?.classList.add(diagramConfig.contextMenu.disallowCloseOnClickClass);
        overlayContainer.classList.add(
          diagramConfig.contextMenu.disallowCloseOnClickClass
        );
        overlayContainer.addEventListener('click', (evt) => {
          evt.stopImmediatePropagation();
        });
      }

      TutorialService.afterStartTutorial();
    }, diagramConfig.defaultTransitionDuration);
  }

  private static afterStartTutorial(): void {
    TutorialService.fitDiagramDebounced();
    TutorialService.zoomService.setAutoZoom(AutoZoomState.Off);
  }

  private static async saveDocument(): Promise<void> {
    const document =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
    await DocumentService.saveDocument(document, true);
    DocumentService.updateDocumentModificationTime();
  }

  private static attachSpotlightToTarget(
    target: IModelItem | Element | IHandle,
    isWithinCanvas: boolean
  ): void {
    if (isWithinCanvas) {
      TutorialService.removeSpotlight();
      TutorialService.spotlightVisual = new SpotlightVisual(
        target as INode | IEdge | SVGElement | IHandle
      );
      if (!TutorialService.spotlightGroup) {
        let parentGroup = 'inputModeGroup';
        if (
          this.targetElement
            ?.getAttribute('data-automation-id')
            ?.startsWith('node-quickbuild-decorator') ||
          target instanceof IEdge
        ) {
          parentGroup = 'highlightGroup';
        }

        TutorialService.spotlightGroup =
          TutorialService.graphService.graphComponent[parentGroup].addGroup();
      }
      TutorialService.spotlightGroup.addChild(
        TutorialService.spotlightVisual,
        ICanvasObjectDescriptor.ALWAYS_DIRTY_INSTANCE
      );
    } else {
      requestAnimationFrame(() =>
        TutorialService.animate(target as HTMLElement)
      );
    }
  }

  private static scrollListener = {
    currentStep: null as Shepherd.Step | null,

    outsideParentBounds(element: Element): boolean {
      if (!element) return false;

      if (!element.parentElement) return false;

      const elRect = element.getBoundingClientRect();
      const parentRect = element.parentElement.getBoundingClientRect();

      // Check if element is within the parent bounds
      return (
        elRect.top < parentRect.top ||
        elRect.bottom > parentRect.bottom ||
        elRect.left < parentRect.left ||
        elRect.right > parentRect.right
      );
    },
    listener(): void {
      if (
        TutorialService.scrollListener.outsideParentBounds(
          TutorialService.targetElement
        )
      ) {
        if (
          TutorialService.targetElement &&
          TutorialService.scrollListener.currentStep.isOpen()
        ) {
          TutorialService.stepTargetMutationObserver.disconnect();
          TutorialService.removeSpotlight();
          TutorialService.scrollListener.currentStep.hide(true);
        }
      } else {
        const tutorialStep = TutorialService.getTutorialStep();

        TutorialService.createStepTargetMutationObserver(
          TutorialService.targetElement as any,
          tutorialStep.attachTo.isWithinCanvas,
          tutorialStep.arrow,
          tutorialStep.attachTo.element
        );
      }
    },
    addListener(): void {
      const step = TutorialService.tour?.getCurrentStep();
      if (step) {
        TutorialService.scrollListener.currentStep = step;
        TutorialService.targetElement?.parentElement?.addEventListener(
          'scroll',
          TutorialService.scrollListener.listener
        );
      }
    },
    removeListener(): void {
      if (!TutorialService.targetElement) return;
      TutorialService.targetElement.parentElement?.removeEventListener(
        'scroll',
        TutorialService.scrollListener.listener
      );

      TutorialService.scrollListener.currentStep = null;
    }
  };

  private static reAttachDebounced = debounce(
    (): void => {
      const currentStep = TutorialService.tour.getCurrentStep();
      if (!currentStep || !TutorialService.currentStepHasTarget()) {
        return;
      }
      currentStep.updateStepOptions({
        attachTo: {
          element: TutorialService.targetElement as unknown as HTMLElement,
          on: currentStep.options.attachTo.on
        }
      });
      currentStep.show(true);
    },
    TutorialService.tourDelay,
    { trailing: true }
  );

  private static createStepResizeObserver(): void {
    TutorialService.stepResizeObserver?.disconnect();

    const resizeObserverTarget = document.querySelector(
      `.${ExportConfig.pageContainerClass}`
    );
    let lastBlockSize = 0;
    if (resizeObserverTarget) {
      TutorialService.stepResizeObserver = new ResizeObserver(
        (entries, observer) => {
          if (!lastBlockSize) {
            lastBlockSize = entries[0].contentBoxSize[0].blockSize;
            return;
          }

          if (entries[0].contentBoxSize[0].blockSize !== lastBlockSize) {
            TutorialService.reAttachDebounced();
          }
        }
      );
      TutorialService.stepResizeObserver.observe(resizeObserverTarget);
    }
  }

  private static createStepTargetMutationObserver(
    target: HTMLElement | SVGElement,
    isWithinCanvas: boolean,
    hideHighlight: boolean,
    targetSelector: string
  ): void {
    TutorialService.stepTargetMutationObserver?.disconnect();
    const containingDocument = isWithinCanvas
      ? document.getElementById('graphComponentContainer').shadowRoot
      : document.body;
    TutorialService.stepTargetMutationObserver = new MutationObserver(
      (mutations) => {
        const currentStep = TutorialService.tour.getCurrentStep();
        if (!currentStep) {
          return;
        }
        const itemInView =
          (TutorialService.targetItem instanceof INode ||
            TutorialService.targetItem instanceof IEdge) &&
          this.graphService.graph.contains(TutorialService.targetItem) &&
          TutorialService.targetItem.style.renderer
            ?.getBoundsProvider(
              TutorialService.targetItem as INode & IEdge,
              TutorialService.targetItem.style as INodeStyle & IEdgeStyle
            )
            .getBounds(this.graphService.graphComponent.canvasContext)
            .intersects(this.graphService.graphComponent.viewport);

        const handleHidden =
          TutorialService.targetHandle &&
          !this.tryGetEdgeHandleElement(
            TutorialService.targetItem as string,
            TutorialService.settings.steps[Number(currentStep.id)].attachTo
              .edgeSection
          );
        const handleVisible = TutorialService.targetHandle && !handleHidden;

        let newTarget = null;

        let currentStepVisible = currentStep?.isOpen();
        mutations.forEach((mutation) => {
          if (
            (mutation.removedNodes[0]?.contains(target as HTMLElement) ||
              (mutation.type === 'attributes' &&
                mutation.target.contains(target) &&
                (target instanceof HTMLElement
                  ? target.offsetParent === null
                  : !target.parentNode)) ||
              handleHidden) &&
            currentStepVisible &&
            !itemInView
            // Target is hidden
          ) {
            if (!(TutorialService.targetItem instanceof IModelItem)) {
              TutorialService.removeSpotlight();
            }
            currentStep.hide(true);
            currentStepVisible = false;
          } else if (
            (mutation.addedNodes[0]?.contains(target as HTMLElement) ||
              itemInView ||
              handleVisible ||
              (mutation.type === 'attributes' &&
                mutation.target.contains(target) &&
                (target instanceof HTMLElement
                  ? target.offsetParent !== null
                  : target.parentNode)) ||
              (!targetSelector.includes('::') &&
                (newTarget =
                  containingDocument.querySelector(targetSelector)))) &&
            !currentStepVisible &&
            !handleHidden
            // Target is visible
          ) {
            if (newTarget) {
              // Target exists in the dom, but it's hidden
              if (newTarget.offsetParent === null) {
                return;
              }

              // There is a new target element
              target = newTarget;
              TutorialService.targetElement = newTarget;
            }

            if (
              !hideHighlight &&
              !(TutorialService.targetItem instanceof IModelItem)
            ) {
              TutorialService.attachSpotlightToTarget(
                target as HTMLElement,
                isWithinCanvas
              );
            }

            // Reattach shepherd to yFiles model item SVG
            if (TutorialService.targetItem instanceof IModelItem) {
              const targetElement = TutorialService.getSvgElementForItem(
                TutorialService.targetItem
              );
              TutorialService.attachSpotlightToTarget(
                TutorialService.targetItem,
                isWithinCanvas
              );
              target = targetElement;
              TutorialService.targetElement = targetElement;
              currentStep.updateStepOptions({
                attachTo: {
                  element: target as unknown as HTMLElement,
                  on: currentStep.options.attachTo.on
                }
              });
            } else if (TutorialService.targetHandle) {
              // Reattach shepherd to edge handle
              const targetElement = TutorialService.tryGetEdgeHandleElement(
                TutorialService.targetItem as string,
                TutorialService.settings.steps[Number(currentStep.id)].attachTo
                  .edgeSection
              );
              TutorialService.attachSpotlightToTarget(
                TutorialService.targetHandle,
                isWithinCanvas
              );
              target = targetElement;
              TutorialService.targetElement = targetElement;
              currentStep.updateStepOptions({
                attachTo: {
                  element: target as unknown as HTMLElement,
                  on: currentStep.options.attachTo.on
                }
              });
            }
            currentStepVisible = true;
            currentStep.show();
          } else if (
            itemInView &&
            currentStepVisible &&
            !TutorialService.targetElement?.parentElement
          ) {
            // Current step is visible and item is in view, but model item was removed from canvas (eg. edges removed on zoom)
            // So get the SVG element for this item and see if it's in the canvas yet
            const targetElement = TutorialService.getSvgElementForItem(
              TutorialService.targetItem as IModelItem
            );
            if (!targetElement || !targetElement.parentElement) {
              (
                document.querySelector('.shepherd-enabled') as HTMLElement
              ).style.display = 'none';
              return; // Item is still not available
            }
            (
              document.querySelector('.shepherd-enabled') as HTMLElement
            ).style.display = '';

            target = targetElement;
            TutorialService.targetElement = targetElement;
            currentStep.updateStepOptions({
              attachTo: {
                element: target as unknown as HTMLElement,
                on: currentStep.options.attachTo.on
              }
            });
            currentStep.show();
          }
        });
      }
    );
    TutorialService.stepTargetMutationObserver.observe(containingDocument, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['style', 'class']
    });
  }

  private static tryGetModelItemFromSelector(
    selector: string
  ): IModelItem | string {
    if (selector.startsWith('NODE::')) {
      return TutorialService.getNodeFromSelector(selector);
    } else if (selector.startsWith('EDGE::')) {
      return TutorialService.getEdgeFromSelector(selector);
    }
    return selector;
  }

  private static tryGetEdgeHandle(
    selector: string,
    edgeSection?: EdgeSection
  ): IHandle | null {
    const edge = TutorialService.getEdgeFromSelector(selector);
    if (edgeSection !== EdgeSection.EntireEdge) {
      const sourceEnd = edgeSection === EdgeSection.SourceHandle;
      return edge
        .lookup(IHandleProvider.$class)
        .getHandles(
          TutorialService.graphService.graphComponent.inputMode.inputModeContext
        )
        .find(
          (h: JigsawPortRelocationHandle) => h.getIsSourceEnd() === sourceEnd
        );
    }
    return null;
  }

  private static tryGetEdgeHandleElement(
    selector: string,
    edgeSection?: EdgeSection
  ): SVGElement {
    const edge = TutorialService.getEdgeFromSelector(selector);
    if (edgeSection !== EdgeSection.EntireEdge) {
      const sourceEnd = edgeSection === EdgeSection.SourceHandle;
      return TutorialService.graphService.graphComponent.div.querySelector(
        `[data-edge-id="${edge.tag.uuid}"][data-source-end="${sourceEnd}"][data-automation-id="edge-anchor-point"`
      ) as SVGElement;
    }
  }

  // Selector: TYPE::INFO
  // Node Selector INFO: NAME:SEQUENCE_NUMBER
  // Edge Selector INFO: SOURCE_NAME:SOURCE_SEQUENCE_NUMBER→TARGET_NAME:TARGET_SEQUENCE_NUMBER

  private static nodeMatchesNodeSelector(
    node: INode,
    nodeSelector: string
  ): boolean {
    const nodeName = nodeSelector.split(':')[0];
    const sequenceNumber = Number(nodeSelector.split(':')[1]);
    return (
      node.tag.name === nodeName && node.tag.sequenceNumber === sequenceNumber
    );
  }

  private static getNodeFromSelector(selector: string): INode {
    const nodeSelector = selector.split('::')[1];
    return TutorialService.graphService.graphComponent.graph.nodes.find((n) =>
      TutorialService.nodeMatchesNodeSelector(n, nodeSelector)
    );
  }

  private static getEdgeFromSelector(selector: string): IEdge {
    const sourceNodeSelector = selector.split('::')[1].split('→')[0];
    const targetNodeSelector = selector.split('→')[1];
    return TutorialService.graphService.graphComponent.graph.edges.find(
      (e) =>
        TutorialService.nodeMatchesNodeSelector(
          e.sourceNode,
          sourceNodeSelector
        ) &&
        TutorialService.nodeMatchesNodeSelector(
          e.targetNode,
          targetNodeSelector
        )
    );
  }

  private static getTargetFromSelector(
    selector: string,
    isWithinCanvas: boolean,
    edgeSection: EdgeSection
  ): HTMLElement {
    if (selector.startsWith('NODE::')) {
      return TutorialService.getSvgElementForItem(
        TutorialService.getNodeFromSelector(selector)
      ) as unknown as HTMLElement;
    } else if (selector.startsWith('EDGE::')) {
      return edgeSection === EdgeSection.EntireEdge
        ? (TutorialService.getSvgElementForItem(
            TutorialService.getEdgeFromSelector(selector)
          ) as unknown as HTMLElement)
        : (TutorialService.tryGetEdgeHandleElement(
            selector,
            edgeSection
          ) as unknown as HTMLElement);
    } else {
      let parentElement = isWithinCanvas
        ? document.getElementById('graphComponentContainer').shadowRoot
        : document.body;

      return (parentElement as HTMLElement)?.querySelector(selector as string);
    }
  }

  private static getTutorialStep(): TutorialStep | null {
    const step = TutorialService.tour?.getCurrentStep();
    if (!step) {
      return null;
    }
    return TutorialService.settings.steps.find((s) => s.id === step?.id);
  }

  private static currentStepHasTarget(): boolean {
    const tutorialStep = TutorialService.getTutorialStep();
    if (!tutorialStep) {
      return false;
    }

    if (!tutorialStep.attachTo.element) {
      return null;
    }

    return !!TutorialService.getTargetFromSelector(
      tutorialStep.attachTo.element,
      tutorialStep.attachTo.isWithinCanvas,
      tutorialStep.attachTo.edgeSection
    );
  }

  private static getSvgElementForItem(item: IModelItem): SVGElement | null {
    if (!item) return null;
    const canvasObject =
      TutorialService.graphService.graphComponent.graphModelManager.getCanvasObject(
        item
      );
    if (canvasObject) {
      const visual =
        TutorialService.graphService.graphComponent.getVisual(canvasObject);
      if (visual instanceof SvgVisual) {
        return visual.svgElement;
      }
    }
    return null;
  }
}
