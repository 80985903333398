import {
  DiagramPosition,
  DocumentPageContentType,
  DocumentPageType,
  PageElementPosition
} from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import PdfMakeUtils from '@/core/services/export/pdf/PdfMakeUtils';
import {
  convertUrlToDataUrl,
  ensureFullUri,
  fitRectIntoBounds
} from '@/core/utils/common.utils';
import {
  defaultFontStyle,
  defaultPageStyle,
  headerFooterStyle,
  textElementStyle
} from './PdfStyles';
import ContentPagination from '../ContentPagination';
import ExportUtils from '../ExportUtils';
import PdfExportSubPage from './PdfExportSubPage';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import ImageLayoutItem from '@/components/LayoutEditor/Items/ImageLayoutItem';
import IExportResult from '../providers/IExportResult';
import BackgroundGraphService from '../../graph/BackgroundGraphService';
import ExportService from '../ExportService';
import ExportPageBuilder from '../ExportPageBuilder';
import LogoAsImageProvider from '../additional-element-providers/LogoAsImageProvider';
import LegendAsImageProvider from '../additional-element-providers/LegendAsImageProvider';
import { ExportPageElementType } from '../ExportPageElementType';
import IPdfExportElement from './IPdfExportElement';
import JSize from '@/core/common/JSize';
import JRect from '@/core/common/JRect';
import HtmlLayoutItem from '@/components/LayoutEditor/Items/HtmlLayoutItem';
import DocumentService from '../../document/DocumentService';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import PageLayoutHandler from '../PageLayoutHandler';
import { HtmlStylesToInlineOptions } from '../HtmlStylesToInlineOptions';

// Uncomment to use LegacyPdfExportProvider (deprecated)
// import * as htmlToPdfmake from '@jigsaw/html-to-pdfmake/index';
declare var htmlToPdfmake: any;

export default class PdfPageBuilder extends ExportPageBuilder {
  private content: string;
  private subPagesData: PdfExportSubPage[] = [];

  private get includePageNumber(): boolean {
    return this.page.showPageNumber;
  }

  private get startingPageNumber(): number {
    return this.options.metadata.currentPageNumber;
  }

  public async getPageDefinition(): Promise<object> {
    await this.initContent();
    await this.assignDefinitions();

    const bodyDefinition = await this.getBodyDefinition();
    const backgroundDefinition = await this.getBackgroundDefinition();
    const pageMargins = ExportUtils.calculatePageMargins(
      this.document,
      this.page
    );

    return {
      content: bodyDefinition,
      header: this.includeHeader ? this.buildHeader : null,
      footer: this.includeFooter ? this.buildFooter : null,
      pageSize: {
        width: this.pageStyle.width,
        height: this.pageStyle.height
      },
      pageMargins: [
        pageMargins.left,
        pageMargins.top,
        pageMargins.right,
        pageMargins.bottom
      ],
      defaultStyle: defaultFontStyle,
      pageBreakBefore(currentNode): any {
        return currentNode.pageBreak;
      },
      background: backgroundDefinition,
      maxPagesNumber: PageLayoutHandler.paginationEnabled ? null : 1
    };
  }

  private async initContent(): Promise<void> {
    if (this.contentType == DocumentPageContentType.Html) {
      const inlineOptions = new HtmlStylesToInlineOptions();
      inlineOptions.containerClassList = [ExportConfig.pageContentClass];
      inlineOptions.containerSize = this.contentSize;
      inlineOptions.updateLineHeights = 'convert';
      this.content = await ExportUtils.htmlStylesToInline(
        this.page.content ?? '',
        inlineOptions
      );
    }

    const subPageCount = ContentPagination.getPageCount(this.page);
    let primaryGraphSvg = null;

    for (let i = 0; i < subPageCount; i++) {
      const subPageRef = this.page.subPageRefs?.find(
        (r) => r.subPageIndex === i
      );
      const subpage = new PdfExportSubPage();

      if (this.includeHeader) {
        subpage.headerLayout =
          subPageRef && DocumentService.subPageHeaderFooterLayoutAvailable
            ? subPageRef.headerLayout
            : this.page.headerLayout;
      }
      if (this.includeFooter) {
        subpage.footerLayout =
          subPageRef && DocumentService.subPageHeaderFooterLayoutAvailable
            ? subPageRef.footerLayout
            : this.page.footerLayout;
      }

      if (this.includeBackground) {
        subpage.backgroundLayout =
          subPageRef && DocumentService.subPageHeaderFooterLayoutAvailable
            ? subPageRef.backgroundLayout
            : this.page.backgroundLayout;
      }

      subpage.subPageIndex = i;

      const diagram = subPageRef?.diagram ?? this.page.diagram;
      const isPrimaryDiagram = diagram == this.page.diagram;

      if (diagram) {
        let graphSvgExportResult: IExportResult = isPrimaryDiagram
          ? primaryGraphSvg
          : null;
        if (!graphSvgExportResult) {
          const sourceGraph = BackgroundGraphService.createGraph(diagram);
          graphSvgExportResult = await ExportService.exportGraphAsSvg(
            this.options,
            sourceGraph,
            this.options.withFilters,
            this.options.lowDetailDiagram,
            []
          );
          if (isPrimaryDiagram) {
            primaryGraphSvg = graphSvgExportResult;
          }
        }
        subpage.diagramId = diagram.id;
        subpage.graphSvg = graphSvgExportResult.result as string;
      }
      this.subPagesData.push(subpage);
    }
  }

  private async getBodyDefinition(): Promise<object> {
    switch (this.contentType) {
      case DocumentPageContentType.None:
      case DocumentPageContentType.Html: {
        let pageHtml = await this.buildPageHtml();
        pageHtml = PdfMakeUtils.ensureValidHtml(pageHtml);
        return htmlToPdfmake(pageHtml, {
          tableAutoSize: true,
          defaultStyles: defaultPageStyle
        });
      }
      case DocumentPageContentType.Layout: {
        return await this.getDefinitionFromLayout(this.content);
      }
      case DocumentPageContentType.MasterLegend: {
        const legend = await this.buildLegend(this.page.id, this.contentSize);
        return htmlToPdfmake(legend.html, {
          tableAutoSize: true,
          defaultStyles: defaultPageStyle
        });
      }
    }
  }

  private async getBackgroundDefinition(): Promise<object> {
    switch (this.contentType) {
      case DocumentPageContentType.None:
      case DocumentPageContentType.Html:
      case DocumentPageContentType.MasterLegend:
        return await this.getRegularPageBackgroundDefinition();
      default:
        return null;
    }
  }

  private async getRegularPageBackgroundDefinition(): Promise<object> {
    const items = [];

    // Take background definition from the first subpage
    // Individual subpage backgrounds are not currently supported
    const backgroundDefinition = this.subPagesData[0]?.backgroundDefinition;
    if (backgroundDefinition) {
      items.push(backgroundDefinition);
    }

    return items;
  }

  private async getDefinitionFromLayout(
    layoutContent: string,
    area: DocumentContentArea = DocumentContentArea.BodyLayout
  ): Promise<object> {
    const itemDefinitions = [];
    let maxHeight = 0;
    switch (area) {
      case DocumentContentArea.Header:
        maxHeight = this.headerStyle.height;
        break;
      case DocumentContentArea.Footer:
        maxHeight = this.footerStyle.height;
        break;
      default:
        maxHeight = this.pageStyle.height;
    }
    const areaSize = new JSize(this.pageStyle.width, maxHeight);
    const layoutItems = (await LayoutUtils.cropLayoutItems(
      layoutContent,
      areaSize
    )) as LayoutItem[];

    for (let item of layoutItems) {
      if (item.hidden) {
        continue;
      }
      const itemBounds = new JRect(
        item.layout.x + item.padding,
        item.layout.y + item.padding,
        item.layout.width - item.padding * 2,
        item.layout.height - item.padding * 2
      );
      let itemDefinition: any = {};

      if (item instanceof HtmlLayoutItem) {
        let html = this.prepareHtml(item.html);
        html = PdfMakeUtils.ensureValidHtml(html);
        const tableAttr = PdfMakeUtils.createDataAttribute({
          layout: 'textElement'
        });
        const defaultStyles = {
          ...textElementStyle
        };

        // if content contain spaces
        if (html.search(/\w&nbsp;<\//g) >= 0) {
          defaultStyles.p['preserveTrailingSpaces'] = true;
          defaultStyles.p.leadingCut = false;
        }

        itemDefinition = htmlToPdfmake(
          `
          <table ${tableAttr} style="max-height: ${item.layout.height}">
            <tr>
              <td>
              ${html}
            </td>
            </tr>
          </table>`,
          {
            tableAutoSize: true,
            defaultStyles: defaultStyles,
            dontBreakRows: true,
            unbreakable: true,
            customTag: this.processCustomTag
          }
        )[0];

        const table = itemDefinition.table;
        table.widths = [itemBounds.width];
        table.heights = [itemBounds.height];
      } else if (item.type == LayoutItemType.Image) {
        itemDefinition = {
          nodeName: 'DIV',
          image: await this.getImageElementSrc(item as ImageLayoutItem)
        };
      }
      itemDefinition.width = itemBounds.width;
      itemDefinition.height = itemBounds.height;
      itemDefinition.absolutePosition = {
        x: itemBounds.x,
        y: itemBounds.y
      };
      itemDefinitions.push(itemDefinition);
    }

    return {
      nodeName: 'DIV',
      stack: itemDefinitions
    };
  }

  private processCustomTag(params: any): any {
    const ret = params.ret;
    const element = params.element;
    switch (ret.nodeName) {
      case ExportConfig.placeholderTagName: {
        if (!ret['attributes']) {
          ret['attributes'] = {};
        }
        ret['attributes'][ExportConfig.placeholderNameAttributeKey] =
          element.getAttribute(ExportConfig.placeholderNameAttributeKey);
        if (!ret.style || !Array.isArray(ret.style)) {
          ret.style = [];
        }
        break;
      }
    }
    return ret;
  }

  private prepareHtml(html: string): string {
    let container = document.createElement('div');
    let element: HTMLElement;
    const div = document.createElement('div');
    div.innerHTML = html;
    element = div;
    container.appendChild(element);
    return container.outerHTML;
  }

  private async getImageElementSrc(item: ImageLayoutItem): Promise<string> {
    let imageSrc = ensureFullUri(item.imageSrc);
    return await convertUrlToDataUrl(imageSrc);
  }

  private buildPageHtml = async (): Promise<string> => {
    switch (this.subPageType) {
      case DocumentPageType.Split:
      case DocumentPageType.Content: {
        const contentSubPages = [];
        const chunks = ContentPagination.splitPagedContentIntoPages(
          this.content
        );

        for (let i = 0; i < chunks.length; i++) {
          const chunkHtml = chunks[i];
          const pageBreakAttr =
            i == 0 ? '' : PdfMakeUtils.createDataAttribute({ pageBreak: true });
          const contentTableAttr = PdfMakeUtils.createDataAttribute({
            layout: 'htmlContent'
          });

          if (this.subPageType == DocumentPageType.Split) {
            const splitRatio = this.document.pageStyle.splitRatio;
            const diagramHtml = `
              <td style="border: none; width: ${100 * splitRatio}%">
                <div>
                  ${await this.buildGraphContent(i)}
                </div>
              </td>`;
            const innerContentHtml = ExportUtils.applyLineHeights(chunkHtml);
            const contentHtml = `
              <td style="border: none; width: ${100 * (1 - splitRatio)}%">
                <div>
                  <table ${contentTableAttr}>
                    <tr>
                      <td style="border: none; width: 100%">${innerContentHtml}</td>
                    </tr>
                  </table>
                </div>
              </td>`;
            const containerTableAttr = PdfMakeUtils.createDataAttribute({
              layout: 'noPadding'
            });

            contentSubPages.push(`
                <div ${pageBreakAttr}>
                  <table ${containerTableAttr}>
                    <tr>
                      ${
                        this.diagramPosition == DiagramPosition.Left
                          ? diagramHtml
                          : contentHtml
                      }
                      ${
                        this.diagramPosition == DiagramPosition.Left
                          ? contentHtml
                          : diagramHtml
                      }
                    </tr>
                  </table>
                </div>`);
          } else {
            if (this.contentColumns > 0) {
              const columnsHtml = ContentPagination.splitPageIntoColumns(
                chunkHtml,
                this.document,
                this.page,
                this.subPageIndex
              );
              const leftColumnHtml = ExportUtils.applyLineHeights(
                columnsHtml[0] ?? ''
              );
              const rightColumnHtml = ExportUtils.applyLineHeights(
                columnsHtml[1] ?? ''
              );
              const columnGap =
                ExportUtils.calculateHtmlContentGap(this.document) / 2;

              contentSubPages.push(`
                  <div ${pageBreakAttr}>
                    <table ${contentTableAttr}>
                      <tr>
                        <td style="border: none; width: 50%; margin-right: ${columnGap}pt;">
                          <div>
                            ${leftColumnHtml}
                          </div>
                        </td>
                        <td style="border: none; width: 50%; margin-left: ${columnGap}pt;">
                          <div>
                            ${rightColumnHtml}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>`);
            } else {
              const innerContentHtml = ExportUtils.applyLineHeights(chunkHtml);
              contentSubPages.push(`
                  <div ${pageBreakAttr}>
                    <table ${contentTableAttr}>
                      <tr>
                        <td style="border: none; width: 100%">${innerContentHtml}</td>
                      </tr>
                    </table>
                  </div>`);
            }
          }
        }
        return contentSubPages.join('\n');
      }
      case DocumentPageType.Diagram:
        return `
          <div>
            ${await this.buildGraphContent(0)}
          </div>`;
    }
  };

  private buildGraphContent = async (subPageIndex: number): Promise<string> => {
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );

    let graphSvg = subpage?.graphSvg;
    if (!graphSvg) {
      return '<svg></svg>';
    }

    graphSvg = graphSvg
      .replaceAll(ZERO_WIDTH_SPACE, '')
      // This fixes issue with parts of bold text sliding up
      .replaceAll(
        'dominant-baseline="text-after-edge"',
        'dominant-baseline="auto"'
      )
      // Set different baseline-shift to type label
      .replaceAll('data-label-baseline-shift', 'baseline-shift');

    const diagramSize = this.diagramSize;
    const logo = await this.buildLogo();
    const legend = await this.buildLegend(subpage.diagramId, diagramSize, logo);

    // Take away 1pt to avoid PDFMake creating additional page in some cases
    diagramSize.height -= 1;

    if (legend && !legend.options.inline) {
      if (
        legend.options.position == PageElementPosition.Left ||
        legend.options.position == PageElementPosition.Right
      ) {
        diagramSize.width = diagramSize.width - legend.size.width;
      } else {
        diagramSize.height = diagramSize.height - legend.size.height;
      }
    }

    let graphContent = graphSvg;
    let fittedSvgSize = diagramSize;

    // Centre diagram on the page and pad if necessary
    const match = graphSvg.match(/width="([\d.]+)"\s+height="([\d.]+)"/);
    if (match && match.length == 3) {
      const svgSize = new JSize(parseInt(match[1]), parseInt(match[2]));
      fittedSvgSize = fitRectIntoBounds(svgSize, fittedSvgSize);

      let diagramHorizontalOffset =
        (diagramSize.width - fittedSvgSize.width) / 2;
      if (diagramHorizontalOffset < 0) diagramHorizontalOffset = 0;
      let diagramVerticalOffset =
        (diagramSize.height - fittedSvgSize.height) / 2;
      if (diagramVerticalOffset < 0) diagramVerticalOffset = 0;

      const diagramPadding = ExportUtils.calculatePadding(
        this.document,
        this.page,
        this.subPageIndex,
        'diagram'
      );
      diagramHorizontalOffset += diagramPadding.left;
      diagramVerticalOffset += diagramPadding.top;

      if (legend && !legend.options.inline) {
        if (
          legend.options.position == PageElementPosition.Top ||
          legend.options.position == PageElementPosition.TopLeft ||
          legend.options.position == PageElementPosition.TopRight
        ) {
          diagramVerticalOffset += legend.size.height;
        } else if (legend.options.position == PageElementPosition.Left) {
          diagramHorizontalOffset += legend.size.width;
        }
      }
      const relAttr = PdfMakeUtils.createDataAttribute({
        relativePosition: {
          x: diagramHorizontalOffset,
          y: diagramVerticalOffset
        }
      });

      graphContent = [
        graphSvg.slice(0, 4),
        ` width="${fittedSvgSize.width}" height="${fittedSvgSize.height}" `,
        graphSvg.slice(4)
      ].join('');

      graphContent = `
            <div ${relAttr}>
              ${graphContent}
            </div>`;
    }

    if (legend) {
      graphContent = `
          <div>
            ${graphContent}
          </div>
          ${legend.html}
        `;
    }
    if (logo) {
      graphContent = `
          <div>
            ${graphContent}
          </div>
          ${logo.html}
        `;
    }
    return graphContent;
  };

  private buildHeader = (currentPage, pageCount, pageSize): Object => {
    const headerBorderWidth = this.headerStyle.borderWidth ?? 0;
    const headerBackground = this.headerStyle.backgroundColor
      ? `background-color: ${this.headerStyle.backgroundColor};`
      : '';
    const headerDataAttr = PdfMakeUtils.createDataAttribute({
      layout: 'headerFooter',
      borderColor: this.headerStyle.borderColor ?? 'white',
      borderWidth: headerBorderWidth
    });

    let html = `
        <div>
          <table ${headerDataAttr}>
            <tr style="width: 100%; height: ${this.headerStyle.height ?? 0}pt">
              <td style="${headerBackground}"></td>
            </tr>
          </table>
        </div>
    `;

    html = PdfMakeUtils.ensureValidHtml(html);

    const definition: Array<any> = htmlToPdfmake(html, {
      defaultStyles: headerFooterStyle,
      tableAutoSize: true
    });

    const subPageIndex = currentPage - 1;
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );
    if (subpage?.headerDefinition) {
      if (this.includePageNumber) {
        this.setPageNumber(subpage.headerDefinition, subPageIndex);
      }
      definition.push(subpage.headerDefinition);
    }

    return definition;
  };

  private buildFooter = (currentPage, pageCount, pageSize): Object => {
    const pageMargins = ExportUtils.calculatePageMargins(
      this.document,
      this.page
    );
    const footerBorderWidth = this.footerStyle.borderWidth ?? 0;
    const footerBackground = this.footerStyle.backgroundColor
      ? `background-color: ${this.footerStyle.backgroundColor};`
      : '';
    const footerDataAttr = PdfMakeUtils.createDataAttribute({
      layout: 'headerFooter',
      borderColor: this.footerStyle.borderColor ?? 'white',
      borderWidth: footerBorderWidth
    });

    let html = `
        <div>
          <table ${footerDataAttr}>
            <tr style="width: 100%; height: ${this.footerStyle.height ?? 0}pt">
              <td style="${footerBackground}"></td>
            </tr>
          </table>
        </div>
    `;

    const footerContainerDataAttr = PdfMakeUtils.createDataAttribute({
      marginTop: pageMargins.bottom - this.footerStyle.height ?? 0
    });
    html = `
        <div ${footerContainerDataAttr}>
          ${html}
        </div>
    `;

    html = PdfMakeUtils.ensureValidHtml(html);

    const definition: Array<any> = htmlToPdfmake(html, {
      defaultStyles: headerFooterStyle,
      tableAutoSize: true
    });

    const subPageIndex = currentPage - 1;
    const subpage = this.subPagesData.find(
      (d) => d.subPageIndex === subPageIndex
    );
    if (subpage?.footerDefinition) {
      if (this.includePageNumber) {
        this.setPageNumber(subpage.footerDefinition, subPageIndex);
      }
      definition.push(subpage.footerDefinition);
    }

    return definition;
  };

  private buildLogo = async (): Promise<IPdfExportElement> => {
    if (!this.includeLogo) {
      return null;
    }
    const logoProvider = new LogoAsImageProvider(this.options, this.exportPage);
    const logoExport = (await logoProvider.get())[0];
    if (!logoExport) {
      return null;
    }
    const logoElement = await logoExport.toSvgAsync();
    logoExport.destroy();

    const logoSize = new JSize(
      parseFloat(logoElement.getAttribute('width')) /
        ExportConfig.pointToPixelFactor,
      parseFloat(logoElement.getAttribute('height')) /
        ExportConfig.pointToPixelFactor
    );
    logoElement.setAttribute('width', logoSize.width.toString());
    logoElement.setAttribute('height', logoSize.height.toString());

    const logoPosition = logoExport.options.position;
    let horizontalOffset = 0;
    let verticalOffset = 0;
    if (
      logoPosition == PageElementPosition.Right ||
      logoPosition == PageElementPosition.TopRight ||
      logoPosition == PageElementPosition.BottomRight
    ) {
      horizontalOffset = this.pageStyle.width - logoSize.width;
    }
    if (
      logoPosition == PageElementPosition.Bottom ||
      logoPosition == PageElementPosition.BottomLeft ||
      logoPosition == PageElementPosition.BottomRight
    ) {
      verticalOffset = this.pageStyle.height - logoSize.height;
    }

    const pageMargins = ExportUtils.calculatePageMargins(
      this.document,
      this.page
    );
    const relAttr = PdfMakeUtils.createDataAttribute({
      relativePosition: {
        x: horizontalOffset - pageMargins.left,
        y: verticalOffset - pageMargins.top
      }
    });

    return {
      html: `
        <div ${relAttr}>
          ${logoElement.outerHTML}
        </div>`,
      size: logoSize,
      options: logoExport.options
    };
  };

  private buildLegend = async (
    legendKey: number,
    diagramSize: JSize,
    logo: IPdfExportElement | null = null
  ): Promise<IPdfExportElement> => {
    if (!this.includeLegend) {
      return null;
    }
    const legendProvider = new LegendAsImageProvider(
      this.options,
      this.exportPage
    );
    const legendExports = await legendProvider.get();
    const legendExport = legendExports.find(
      (e) => e.key == legendKey && e.type == ExportPageElementType.Legend
    );
    if (!legendExport) {
      return null;
    }
    const legendElement = await legendExport.toSvgAsync();
    legendExport.destroy();

    let legendSize = new JSize(
      parseFloat(legendElement.getAttribute('width')),
      parseFloat(legendElement.getAttribute('height'))
    );
    if (
      this.options.document.hasSteps &&
      this.subPageType == DocumentPageType.Split
    ) {
      legendSize = legendSize.multiply(ExportConfig.diagramLegendScale.small);
    } else {
      legendSize = legendSize.multiply(ExportConfig.diagramLegendScale.medium);
    }

    const legendPosition = legendExport.options.position;
    let horizontalOffset = 0;
    let verticalOffset = 0;

    const fittedLegendSize = fitRectIntoBounds(legendSize, diagramSize);
    let legendScale = fittedLegendSize.width / legendSize.width;
    if (legendScale > 1) {
      legendScale = 1;
    }
    const scaledSize = legendSize.multiply(legendScale);

    legendSize = new JSize(
      Math.floor(scaledSize.width),
      Math.floor(scaledSize.height)
    );

    let svgSize = legendSize.clone();
    if (
      !legendExport.options.inline &&
      this.includeLogo &&
      logo &&
      logo.options.position
    ) {
      if (logo.options.position === PageElementPosition.TopLeft) {
        if (legendPosition === PageElementPosition.Top) {
          svgSize.width -= logo.size.width;
          horizontalOffset += logo.size.width;
        } else if (legendPosition === PageElementPosition.Left) {
          svgSize.height -= logo.size.height;
          verticalOffset += logo.size.height;
        }
      }

      if (logo.options.position === PageElementPosition.TopRight) {
        if (legendPosition === PageElementPosition.Top) {
          svgSize.width -= logo.size.width;
        } else if (legendPosition === PageElementPosition.Right) {
          svgSize.height -= logo.size.height;
          verticalOffset += logo.size.height;
        }
      }

      if (logo.options.position === PageElementPosition.BottomLeft) {
        if (legendPosition === PageElementPosition.Bottom) {
          svgSize.width -= logo.size.width;
          horizontalOffset += logo.size.width;
        } else if (legendPosition === PageElementPosition.Left) {
          svgSize.height -= logo.size.height;
        }
      }

      if (logo.options.position === PageElementPosition.BottomRight) {
        if (legendPosition === PageElementPosition.Bottom) {
          svgSize.width -= logo.size.width;
        } else if (legendPosition === PageElementPosition.Right) {
          svgSize.height -= logo.size.height;
        }
      }
    }

    legendElement.setAttribute('width', `${svgSize.width}`);
    legendElement.setAttribute('height', `${svgSize.height}`);

    if (
      legendPosition == PageElementPosition.Right ||
      legendPosition == PageElementPosition.TopRight ||
      legendPosition == PageElementPosition.BottomRight
    ) {
      horizontalOffset += diagramSize.width - legendSize.width;
    }
    if (
      legendPosition == PageElementPosition.Bottom ||
      legendPosition == PageElementPosition.BottomLeft ||
      legendPosition == PageElementPosition.BottomRight
    ) {
      verticalOffset += diagramSize.height - legendSize.height;
    }
    const diagramPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      this.subPageIndex,
      'diagram'
    );
    horizontalOffset += diagramPadding.left;
    verticalOffset += diagramPadding.top;

    const relAttr = PdfMakeUtils.createDataAttribute({
      relativePosition: {
        x: horizontalOffset,
        y: verticalOffset
      }
    });

    return {
      html: `
        <div ${relAttr}>
          ${legendElement.outerHTML}
        </div>`,
      size: legendSize,
      options: legendExport.options
    };
  };

  private async assignDefinitions(): Promise<void> {
    for (const subpage of this.subPagesData) {
      if (subpage.headerLayout) {
        subpage.headerDefinition = await this.getDefinitionFromLayout(
          subpage.headerLayout,
          DocumentContentArea.Header
        );
      }
      if (subpage.footerLayout) {
        subpage.footerDefinition = await this.getDefinitionFromLayout(
          subpage.footerLayout,
          DocumentContentArea.Footer
        );
      }
      if (subpage.backgroundLayout) {
        subpage.backgroundDefinition = await this.getDefinitionFromLayout(
          subpage.backgroundLayout
        );
      }
    }
  }

  private setPageNumber(definition: any, subpageIndex: number): void {
    const pageNumberElement = PdfMakeUtils.findElementByAttribute(
      definition,
      ExportConfig.placeholderNameAttributeKey,
      ExportConfig.placeholderNameAttributeValues.pageNumber
    );
    if (pageNumberElement) {
      pageNumberElement.text = this.startingPageNumber + subpageIndex;
    }
    const totalPagesElement = PdfMakeUtils.findElementByAttribute(
      definition,
      ExportConfig.placeholderNameAttributeKey,
      ExportConfig.placeholderNameAttributeValues.totalPages
    );
    if (totalPagesElement) {
      totalPagesElement.text = DocumentService.getTotalPagesCount();
    }
  }
}
