import { PluginObject } from 'vue';
import { TButtonVariants } from '@/components/shared/AppButton/types';

export interface ActionToasterProps {
  text: string | object | Function; // VNode
  okText?: string;
  minWidth?: number;
  maxWidth?: number;
  isConfirm?: boolean;
  okVariant?: string;
  customButtons?: ICustomButtons[];
  denyText?: string;
}

export interface ICustomButtons {
  text: string;
  action?: string;
  variant?: TButtonVariants;
  fullWidth?: boolean;
}

export const list = {
  list: []
};

const removeById = (id: string | number): void => {
  const idx = list.list.findIndex((x) => x?.id === id);
  list.list.splice(idx, 1);
};

export const removeAllToasters = (): void => {
  list.list = [];
};

const createNewPopUp = async (
  confirmOptions: ActionToasterProps,
  isConfirm: boolean
): Promise<boolean | string> => {
  const id = Date.now();

  try {
    const sum = await new Promise<boolean | string>((resolve) => {
      const newPopup = {
        ...(!isConfirm && { okText: 'Ok' }),
        ...confirmOptions,
        promise: {
          resolve
        },
        id,
        isConfirm
      };

      list.list.push(newPopup);

      console.debug('ActionToasterPlugin list: ', list);
    });
    console.debug('ActionToasterPlugin: resolve', id);
    removeById(id);
    return sum;
  } catch (err) {
    console.debug('ActionToasterPlugin: reject', id);
    removeById(id);
    throw err;
  }
};

export const actionToaster = (
  confirmOptions: ActionToasterProps
): Promise<boolean | string> => createNewPopUp(confirmOptions, true);

export const ActionToasterPlugin: PluginObject<any> = {
  install(Vue) {
    console.debug('Installing popups [$actionToaster]...');
    Vue.prototype.$actionToaster = actionToaster;
  }
};
