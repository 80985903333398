import {
  InsetsDto,
  TDataDto,
  TColumnOptionsDto,
  TCellOptionsDto,
  PageElementPosition,
  DocumentView,
  TColumnDto,
  TRowDto,
  TRowOptionsDto,
  TCellContentType,
  TImageCellOptionsDto,
  CellAlignment,
  TCellDto
} from '@/api/models';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import LegendHtmlUtils from '@/components/DiagramLegend/LegendHtmlUtils';
import ExportConfig from '@/core/config/ExportConfig';
import {
  splitArrayIntoChunks,
  convertImageSrcToPng,
  fitRectIntoBounds
} from '@/core/utils/common.utils';
import { encodeHtml } from '@/core/utils/html.utils';
import ExportOptions from '../../ExportOptions';
import { OpenXmlExportUtils } from './OpenXmlExportUtils';
import diagramConfig from '@/core/config/diagram.definition.config';
import { LayoutForView } from '@/components/DiagramLegend/LayoutForViewOptions';
import JPoint from '@/core/common/JPoint';
import JSize from '@/core/common/JSize';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT_VIEW
} from '@/core/services/store/document.module';
import { DiagramSize } from '@/view/pages/document/DiagramSize';
import Vue from 'vue';
import LegendAsImageProvider from '../../additional-element-providers/LegendAsImageProvider';

export class OpenXmlExportLegend {
  private readonly exportOptions: ExportOptions;
  // In pixels
  private readonly _defaultLegendTableOptions = {
    imageColumnWidth: 30,
    imageColumnImageWidth: 25,
    labelColumnWidth: 140,
    headerRowHeight: 30,
    headerFontSize: 0,
    rowHeight: 30,
    minImageColumnWidth: 8,
    minLabelColumnWidth: 16,
    minRowHeight: 8,
    imageHeight: 30
  };

  private _legendTableOptions = { ...this._defaultLegendTableOptions };

  constructor(exportOptions: ExportOptions) {
    this.exportOptions = exportOptions;
  }

  public async legendToTable(
    legend: ILegendDefinition,
    pageMargins: InsetsDto
  ): Promise<TDataDto> {
    if (!legend) {
      return null;
    }

    const availablePageSize = OpenXmlExportUtils.calculateAvailablePageSize(
      this.exportOptions,
      pageMargins
    );

    // Not scaled sizes
    let { rowHeightData, columnWidthData, fontSize, layout, isPrintView } =
      await this.getSizeData(availablePageSize);

    const headerFontSize = this._legendTableOptions.headerFontSize;

    const padding =
      (this._legendTableOptions.imageColumnWidth -
        this._legendTableOptions.imageColumnImageWidth) /
      2;

    const imageColumnOptions: TColumnOptionsDto = {
      width: this._legendTableOptions.imageColumnWidth,
      insets: new InsetsDto(0, padding, 0, padding)
    };

    const legendTableWidth =
      columnWidthData.reduce((total, currentValue) => total + currentValue) +
      imageColumnOptions.width * columnWidthData.length; // also add image columns width;
    let legendTableHeight =
      rowHeightData.reduce((total, currentValue) => total + currentValue) +
      rowHeightData[0]; // + 1 row as table header;

    // Remove table rows that do not fit within the available page size
    if (legendTableHeight > availablePageSize.height) {
      let index = 0;
      let currentHeight = rowHeightData[0]; // + 1 row as table header;
      for (let i = 0; i < rowHeightData.length; ++i) {
        if (currentHeight >= availablePageSize.height) {
          index = i;
          break;
        }
        currentHeight += rowHeightData[i];
      }
      rowHeightData.splice(index);
      legendTableHeight = currentHeight;
    }

    const scaleMultiplier = this.calculateLegendTableScaleMultiplier(
      availablePageSize,
      legendTableWidth,
      legendTableHeight
    );

    rowHeightData = rowHeightData.map((x) => Math.floor(x * scaleMultiplier));
    columnWidthData = columnWidthData.map((x) =>
      Math.floor(x * scaleMultiplier)
    );

    const tableColumns = columnWidthData.length;
    const tableRows = rowHeightData.length;

    // Must be integer otherwise ppt export will be broken
    fontSize = Math.round(
      (fontSize ?? diagramConfig.defaultFontSize) * scaleMultiplier
    );

    const defaultLabelCellOptions: TCellOptionsDto = {
      fontSize
    };

    // Must be integer otherwise ppt export will be broken
    imageColumnOptions.width = Math.floor(
      imageColumnOptions.width * scaleMultiplier
    );
    imageColumnOptions.insets = new InsetsDto(
      0,
      imageColumnOptions.insets.left * scaleMultiplier,
      0,
      imageColumnOptions.insets.right * scaleMultiplier
    );
    const data: TDataDto = {
      isLegend: true,
      rows: [],
      columns: [],
      position: isPrintView
        ? layout?.staticPosition || PageElementPosition.TopLeft
        : legend.options.layout[DocumentView.Web].staticPosition
    };

    for (let i = 0; i < tableColumns; i++) {
      data.columns.push(
        new TColumnDto(imageColumnOptions),
        new TColumnDto({
          width: Math.floor(
            columnWidthData[i] * scaleMultiplier ||
              this._legendTableOptions.labelColumnWidth * scaleMultiplier
          )
        })
      );
    }

    if (headerFontSize) {
      const headerRow: TRowDto = {
        options: {
          height: Math.round(
            this._legendTableOptions.headerRowHeight * scaleMultiplier
          ),
          isHeaderRow: true
        } as TRowOptionsDto,
        cells: [
          {
            content: {
              options: {
                fontSize: headerFontSize,
                fontFamily: defaultLabelCellOptions.fontFamily,
                backgroundColour: defaultLabelCellOptions.backgroundColour,
                colour: defaultLabelCellOptions.colour
              },
              contentType: TCellContentType.String,
              data: encodeHtml(legend.options.header)
            },
            horizontalMerge: false,
            gridSpan: tableColumns * 2
          }
        ]
      };

      for (let i = 0; i < tableColumns * 2 - 1; i++) {
        headerRow.cells.push({
          content: {
            options: defaultLabelCellOptions,
            contentType: TCellContentType.String,
            data: ''
          },
          horizontalMerge: false,
          gridSpan: 0
        });
      }

      data.rows.push(headerRow);
    }

    data.location = layout.position;

    let legendItems = legend.items.filter((x) => x.show);
    if (this.exportOptions.withFilters) {
      legendItems = legend.items.filter((x) => !x.isFiltered);
    }

    const legendItemsTable = splitArrayIntoChunks(
      legendItems,
      tableColumns,
      tableRows
    );

    for (const [itemRowIndex, itemRow] of legendItemsTable.entries()) {
      const row: TRowDto = {
        options: {
          height: Math.floor(
            rowHeightData[itemRowIndex] ||
              this._legendTableOptions.rowHeight * scaleMultiplier
          )
        } as TRowOptionsDto,
        cells: [] as any[]
      };

      for (const item of itemRow) {
        const { src, width, height } = await convertImageSrcToPng(
          item.symbol,
          true
        );

        const imageCellOptions = new TImageCellOptionsDto(
          width * scaleMultiplier,
          height * scaleMultiplier,
          fontSize,
          undefined,
          undefined,
          undefined,
          CellAlignment.Center
        );
        row.cells.push(
          {
            content: {
              contentType: TCellContentType.PngImage,
              data: src,
              options: imageCellOptions
            }
          },
          {
            content: {
              options: {
                ...defaultLabelCellOptions,
                alignment: CellAlignment.Center
              },
              contentType: TCellContentType.String,
              data: encodeHtml(item.label)
            }
          }
        );
      }
      data.rows.push(row);
    }
    const lastRowCellCount = data.rows[data.rows.length - 1].cells.length;
    if (lastRowCellCount < tableColumns * 2) {
      data.rows[data.rows.length - 1].cells[lastRowCellCount - 1].gridSpan =
        tableColumns * 2 - lastRowCellCount + 1;
      for (let i = 0; i < tableColumns * 2 - lastRowCellCount; i++) {
        data.rows[data.rows.length - 1].cells.push(
          new TCellDto({
            options: defaultLabelCellOptions,
            contentType: TCellContentType.String,
            data: ''
          })
        );
      }
    }

    return data;
  }

  private setLegendTableOptions(legendElement: HTMLElement): void {
    const legendItem = legendElement.querySelector('.legend-item');
    const imageColumnWidth =
      (legendItem.querySelector('.item-symbol')?.clientWidth ??
        this._defaultLegendTableOptions.imageColumnWidth) *
      ExportConfig.pointToPixelFactor;
    const imageColumnImageWidth =
      (legendItem.querySelector('img')?.clientWidth ??
        this._defaultLegendTableOptions.imageColumnImageWidth) *
      ExportConfig.pointToPixelFactor;
    const rowHeight =
      legendItem.clientHeight ??
      this._defaultLegendTableOptions.rowHeight *
        ExportConfig.pointToPixelFactor;
    const imageHeight =
      (legendItem.querySelector('.item-symbol')?.clientHeight ??
        this._defaultLegendTableOptions.imageHeight) *
      ExportConfig.pointToPixelFactor;

    const header = LegendHtmlUtils.getLegendHeader(legendElement);
    let headerRowHeight = header?.clientHeight ?? 0;
    let headerFontSize = this._defaultLegendTableOptions.headerFontSize;

    if (header) {
      const style = getComputedStyle(header);
      headerFontSize = Math.ceil(
        parseFloat(style.getPropertyValue('font-size')) /
          ExportConfig.pointToPixelFactor
      );

      const separator =
        LegendHtmlUtils.getDiagramLegendSeparator(legendElement);
      if (separator) {
        const style = getComputedStyle(separator);
        const margin = Math.ceil(
          parseFloat(style.marginTop) + parseFloat(style.marginBottom)
        );

        if (Number.isInteger(margin)) {
          headerRowHeight += margin;
        }
        headerRowHeight += separator.clientHeight;
      }
    }

    this._legendTableOptions = {
      ...this._defaultLegendTableOptions,
      imageColumnImageWidth,
      imageColumnWidth,
      headerRowHeight,
      headerFontSize,
      rowHeight,
      imageHeight
    };
  }

  private async getSizeData(pageSize: JSize): Promise<{
    rowHeightData: number[];
    columnWidthData: number[];
    fontSize: number;
    layout: LayoutForView;
    isPrintView: boolean;
  }> {
    const rowHeightData: number[] = [];
    const columnWidthData: number[] = [];

    const provider = new LegendAsImageProvider(
      this.exportOptions,
      this.exportOptions.metadata.currentPage
    );
    const isWebView =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`] ===
      DocumentView.Web;
    const { legendContainer, legendInstance } = await provider.renderLegend(
      this.exportOptions.metadata.currentPage.page,
      this.exportOptions.metadata.currentPage.page.diagram,
      DiagramSize.Large,
      isWebView ? fitRectIntoBounds(provider.getParentSize(), pageSize) : null
    );

    const parentSize = isWebView ? legendInstance.parentSize : pageSize;
    const borderSize = ExportConfig.pointToPixelFactor;
    const layout: LayoutForView = {
      ...legendInstance.layout,
      position: new JPoint(
        legendInstance.layout.position.x * parentSize.width + borderSize,
        legendInstance.layout.position.y * parentSize.height + borderSize
      ),
      size: new JSize(
        legendInstance.layout.size.width * parentSize.width + borderSize * 2,
        legendInstance.layout.size.height * parentSize.height + borderSize * 2
      )
    };

    let row = 0;
    let col = 0;

    this.setLegendTableOptions(legendContainer as HTMLElement);

    // Compute the width of each row and each column based on every table cell;
    for (const legendItem of legendContainer.querySelectorAll('.legend-item')) {
      if (col === legendInstance.columnCount) {
        col = 0;
        row += 1;
      }

      columnWidthData[col] = Math.max(
        columnWidthData[col] || 0,
        legendItem.clientWidth - this._legendTableOptions.imageColumnWidth
      );

      rowHeightData[row] = Math.max(
        rowHeightData[row] || 0,
        legendItem.clientHeight -
          (legendItem.clientHeight -
            this._defaultLegendTableOptions.imageHeight) /
            2
      );

      col += 1;
    }

    const legendItemLabel = legendContainer.querySelector('.legend-item-label');
    const fontSize = Math.ceil(
      parseFloat(
        getComputedStyle(legendItemLabel).getPropertyValue('font-size')
      ) / ExportConfig.pointToPixelFactor
    );

    legendContainer.remove();
    legendInstance.$destroy();

    return {
      rowHeightData,
      columnWidthData,
      fontSize,
      layout,
      isPrintView: legendInstance.isPrintView
    };
  }

  private calculateLegendTableScaleMultiplier(
    availablePageSize: JSize,
    width: number,
    height: number
  ): number {
    let scaleMultiplier = 1;

    if (availablePageSize.width < width) {
      scaleMultiplier = availablePageSize.width / width;
    } else if (availablePageSize.height < height) {
      scaleMultiplier = availablePageSize.height / height;
    }

    return scaleMultiplier;
  }
}
