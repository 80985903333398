import type Annotation from '@ckeditor/ckeditor5-comments/src/annotations/annotation';
import type Suggestion from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import type { SuggestionType } from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import type { CommentThread } from '@ckeditor/ckeditor5-comments';
import CollaborationDataType from './CollaborationDataType';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';

export default class AnnotationData {
  public readonly annotation?: Annotation;
  public readonly data?: Suggestion | CommentThread;

  public get description(): string {
    return (this.annotation?.view?.mainView as any)?.description ?? 'EMPTY';
  }

  public get author(): string {
    return (this.data as any)?.author?.name ?? 'UNKNOWN';
  }

  public get dataType(): CollaborationDataType {
    if ('type' in this.data) {
      return CollaborationDataType.Suggestion;
    }
    return CollaborationDataType.CommentThread;
  }

  public get suggestionType(): SuggestionType {
    if (this.dataType === CollaborationDataType.Suggestion) {
      return (this.data as Suggestion).type;
    }

    return null;
  }

  public get annotationType(): string {
    if (this.dataType === CollaborationDataType.Suggestion) {
      return this.annotation.type;
    }

    return null;
  }

  // wrap the content in <span> element
  public get parsedDescription(): string {
    const tempWrapper = document.createElement('div');
    tempWrapper.innerHTML = this.handleAnnotationDescriptionTranslation(
      this.description
    );

    tempWrapper.childNodes.forEach((element, index) => {
      this.customizeAnnotationDescriptionElements(
        element as HTMLElement,
        index,
        tempWrapper
      );
    });

    const result = tempWrapper.outerHTML;
    tempWrapper.remove();

    return result;
  }

  constructor(annotation?: Annotation, data?: Suggestion | CommentThread) {
    this.annotation = annotation;
    this.data = data;
  }

  private handleAnnotationDescriptionTranslation(html: string): string {
    if (!this.annotation?.view?.t) {
      return html;
    }
    return html.replace(
      'ELEMENT_BULLETED_LIST_"– "',
      this.annotation.view.t('ELEMENT_BULLETED_LIST_DASH')
    );
  }

  private customizeAnnotationDescriptionElements(
    element: HTMLElement,
    index: number,
    container: HTMLElement
  ): void {
    let shouldReplace = true;
    let pElement = element;
    let firstElement = pElement.childNodes[1] as HTMLElement;
    if (!firstElement) {
      return;
    }

    const newSpan = document.createElement('span');

    const isColorChange =
      firstElement.nodeType === Node.ELEMENT_NODE &&
      firstElement.classList.contains('ck-suggestion-color') &&
      this.annotationType === 'suggestion-format';

    // when we insert table it shows that we inserted table with several empty spaces
    // so we are getting rid of it
    if (
      this.annotationType === 'suggestion-insertion' &&
      this.suggestionType === 'insertion' &&
      pElement.childNodes.length > 3
    ) {
      // the node that shows we added empty spaces
      const textNodeAddedWith = pElement.childNodes[3];
      const textContent = textNodeAddedWith.textContent
        .replaceAll(/\s/g, '') // Remove all white spaces
        .replaceAll(ZERO_WIDTH_SPACE, '') // Remove zero width spaces
        .replaceAll('""', ''); // Remove double quotation marks

      if (textContent.length === 0) {
        const formattedPElement = document.createElement('p');
        formattedPElement.appendChild(pElement.childNodes[0].cloneNode(true));
        formattedPElement.appendChild(firstElement.cloneNode(true));
        pElement.replaceWith(formattedPElement);
        // since element replaced we need to get it again
        pElement = container.childNodes[index] as HTMLElement;
        firstElement = pElement.childNodes[1] as HTMLElement;
      } else {
        // remove extra spaces
        const parsedTextContent = textNodeAddedWith.textContent
          .replaceAll(ZERO_WIDTH_SPACE, ' ')
          .split(/\s+/)
          .join(' ');

        // add an appropriate highlight class to the text that shows the element we operate with
        textNodeAddedWith.textContent = parsedTextContent;
        newSpan.classList.add('ck-suggestion-marker-insertion');
        newSpan.textContent = firstElement.textContent;
        firstElement.replaceWith(newSpan.cloneNode(true));
        // as we formatted data already
        shouldReplace = false;
      }
    }

    // to correctly display the replacement of inserted text
    // we need to parse it specifically to add appropriate styles
    if (this.annotationType === 'suggestion-replace') {
      // in this case pElement.childNodes[1] is the text node we replace
      newSpan.classList.add('ck-suggestion-marker-deletion');
      newSpan.textContent = ` ${firstElement.textContent.trim()}`;
      firstElement.replaceWith(newSpan);

      const labelSpan = pElement.childNodes[2];
      if (labelSpan && labelSpan.nodeType === Node.ELEMENT_NODE) {
        (labelSpan as HTMLElement).innerHTML =
          ' ' + labelSpan.textContent + ' ';
      }

      // in this case pElement.childNodes[3] is the text node we added
      const textNodeAdded = pElement.childNodes[3];
      if (textNodeAdded) {
        const newSpan = document.createElement('span');
        newSpan.classList.add('ck-suggestion-marker-insertion');
        newSpan.textContent = textNodeAdded.textContent.trim();
        textNodeAdded.replaceWith(newSpan);
      }
    } else if (isColorChange) {
      // color formatting operation
      // the case when it is a color change operation
      firstElement.innerHTML = ' ' + firstElement.style.backgroundColor;
      firstElement.style.color = firstElement.style.backgroundColor;
      // reset default attributes
      firstElement.style.backgroundColor = null;
      firstElement.title = '';
    } else if (shouldReplace) {
      switch (this.suggestionType) {
        case 'insertion':
          newSpan.classList.add('ck-suggestion-marker-insertion');
          break;
        case 'deletion':
          newSpan.classList.add('ck-suggestion-marker-deletion');
          break;
        case 'formatInline':
          newSpan.classList.add('ck-suggestion-marker-formatInline');
          break;
      }

      newSpan.textContent = firstElement.textContent;
      firstElement.replaceWith(newSpan);
      pElement.appendChild(newSpan);
    }
  }
}
