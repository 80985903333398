import { TutorialType } from '@/api/models';

const cardListItemSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      title: { type: 'string' },
      text: { type: 'string' },
      highlighted: { type: 'boolean' }
    },
    required: ['title']
  }
};

const cardTranslationSchema = {
  type: 'object',
  nullable: true,
  properties: {
    name: { type: 'string' },
    title: { type: 'string' },
    caption: { type: 'string' },
    greetingTitle: { type: 'string' },
    greetingText: { type: 'string' },
    footerText: { type: 'string' },
    listItems: cardListItemSchema
  }
};

export default Object.freeze({
  type: 'object',
  properties: {
    icon: { type: 'string' },
    type: {
      type: 'integer',
      minimum: TutorialType.Beginner,
      maximum: TutorialType.Advanced
    },
    title: { type: 'string' },
    caption: { type: 'string' },
    completionTime: {
      type: 'object',
      properties: {
        value: { type: 'number' },
        unit: { type: 'string', pattern: '^(minutes|hours)$' }
      }
    },
    listItems: cardListItemSchema,
    merchIncluded: { type: 'boolean' },
    footerText: { type: 'string' },
    highlighted: { type: 'boolean' },
    greetingTitle: { type: 'string' },
    greetingText: { type: 'string' },
    translations: cardTranslationSchema
  },
  required: ['type', 'title', 'completionTime', 'listItems']
});
