import HostSettingsApiService from '@/api/HostSettingsApiService';
import HostSettingsServiceApiService from '@/api/HostSettingsServiceApiService';
import { ClientHostSettingsDto } from '@/api/models';
import { Module } from 'vuex';

// action types
export const GET_SETTINGS = 'getSettings';
// mutation types
export const SET_SETTINGS = 'setSettings';
// getters
export const GET_CURRENT_SETTINGS = 'currentSettings';
export const GET_AUTOSAVE_ENABLED = 'autoSaveEnabled';

interface State {
  settings: ClientHostSettingsDto;
}

const getInitialState = (): State => {
  return {
    settings: new ClientHostSettingsDto()
  };
};

const settingsModule: Module<State, any> = {
  namespaced: false,
  state: getInitialState(),
  getters: {
    currentSettings(state) {
      return state.settings;
    },
    [GET_AUTOSAVE_ENABLED](state) {
      return state.settings.autoSaveSettings.isEnabled;
    }
  },
  actions: {
    [GET_SETTINGS]({ commit, getters, dispatch }) {
      return HostSettingsServiceApiService.getClientHostSettings()
        .then(({ data }) => {
          commit(SET_SETTINGS, data?.result);
          return data?.result;
        })
        .catch(() => {
          commit(SET_SETTINGS, null);
        });
    },
    [SET_SETTINGS](context, profile) {
      context.commit(SET_SETTINGS, profile);
    }
  },
  mutations: {
    [SET_SETTINGS](state, settings) {
      state.settings = settings;
    }
  }
};

export default settingsModule;
