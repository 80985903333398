import { Module } from 'vuex';

import { CompositionBlockType } from '../search/filters/CompositeFilter';

import { compositionBlockTypes } from '@/components/Filter/Filter.config';

import FilterGroup from '@/components/Filter/FilterGroup';

// namespace
export const FILTERS_NAMESPACE = 'filters';

// getters
export const GET_FILTERS_BAR_OPEN = 'getFiltersBarOpen';
export const GET_FILTERS_APPLIED = 'getFiltersApplied';
export const GET_SELECTED_FILTERS = 'getSelectedFilters';
export const GET_SELECTED_COMPOSITION_BLOCK_TYPE =
  'getSelectedCompositionBlockType';
export const GET_DATA_TO_REFRESH = 'getDataToRefresh';

// mutations
export const SET_FILTERS_BAR_OPEN = 'setFiltersBarOpen';
export const SET_FILTERS_APPLIED = 'setFiltersApplied';
export const SET_SELECTED_FILTERS = 'setSelectedFilters';
export const SET_SELECTED_COMPOSITION_BLOCK_TYPE =
  'setSelectedCompositionBlockType';
export const SET_DATA_TO_REFRESH = 'setDataToRefresh';

// actions ...

interface State {
  isFiltersBarOpen: boolean;
  isFiltersApplied: boolean;
  selectedFilters: FilterGroup[];
  selectedCompositionBlockType: CompositionBlockType;
  isDataToRefresh: boolean;
}

const FiltersModule: Module<State, any> = {
  namespaced: true,

  state: {
    isFiltersBarOpen: false,
    isFiltersApplied: false,
    selectedFilters: [],
    selectedCompositionBlockType: CompositionBlockType.Or,
    isDataToRefresh: false
  },

  getters: {
    [GET_FILTERS_BAR_OPEN](state) {
      return state.isFiltersBarOpen;
    },

    [GET_FILTERS_APPLIED](state) {
      return state.isFiltersApplied;
    },

    [GET_SELECTED_FILTERS](state) {
      return state.selectedFilters;
    },

    [GET_SELECTED_COMPOSITION_BLOCK_TYPE](state) {
      return (
        compositionBlockTypes.find(
          (type) => type.value === state.selectedCompositionBlockType
        ) || null
      );
    },

    [GET_DATA_TO_REFRESH](state) {
      return state.isDataToRefresh;
    }
  },

  mutations: {
    [SET_FILTERS_BAR_OPEN](state, payload) {
      state.isFiltersBarOpen = payload;
    },

    [SET_FILTERS_APPLIED](state, payload) {
      state.isFiltersApplied = payload;
    },

    [SET_SELECTED_FILTERS](state, payload) {
      state.selectedFilters = payload;
    },

    [SET_SELECTED_COMPOSITION_BLOCK_TYPE](state, payload) {
      state.selectedCompositionBlockType = payload;
    },

    [SET_DATA_TO_REFRESH](state, payload) {
      state.isDataToRefresh = payload;
    }
  },

  actions: {}
};
export default FiltersModule;
