import Button from './Button';
import Collection from '@/core/common/Collection';

export default class ButtonCollection extends Collection<Button> {
  constructor(private readonly buttons: Button[]) {
    super(buttons);
  }

  public findById(id: string): Button {
    return this.find((x) => x.tag.id == id);
  }
}
