import {
  DeloittePageType,
  DiagramPosition,
  DocumentPageContentType,
  DocumentPageLayoutType,
  DocumentPageType,
  PageDesignDto
} from '@/api/models';
import { DeloittePageConfiguration } from './DeloittePageConfiguration';

export default class DeloittePageConfigurator {
  private layoutPageDesigns: PageDesignDto[];
  private pageConfigMap: Record<
    DeloittePageType,
    () => DeloittePageConfiguration
  >;

  constructor(layoutPageDesigns: PageDesignDto[]) {
    this.layoutPageDesigns = layoutPageDesigns;
    this.pageConfigMap = {
      [DeloittePageType.Undefined]: (): DeloittePageConfiguration => null,
      [DeloittePageType.COVER]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Layout,
        layoutType: DocumentPageLayoutType.Cover,
        pageDesign: this.getPageDesign(
          DocumentPageType.Content,
          DocumentPageContentType.Layout,
          DocumentPageLayoutType.Cover
        )
      }),
      [DeloittePageType.FILLER]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Layout,
        layoutType: DocumentPageLayoutType.Filler,
        pageDesign: this.getPageDesign(
          DocumentPageType.Content,
          DocumentPageContentType.Layout,
          DocumentPageLayoutType.Filler
        )
      }),
      [DeloittePageType.CLOSING]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Layout,
        layoutType: DocumentPageLayoutType.Closing,
        pageDesign: this.getPageDesign(
          DocumentPageType.Content,
          DocumentPageContentType.Layout,
          DocumentPageLayoutType.Closing
        )
      }),
      [DeloittePageType.DIAGRAM]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Diagram,
        contentType: DocumentPageContentType.None,
        pageDesign: this.getPageDesign(
          DocumentPageType.Diagram,
          DocumentPageContentType.None
        )
      }),
      [DeloittePageType.DIAGRAM_TEXT]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Split,
        contentType: DocumentPageContentType.Html,
        contentPageLayoutType: DocumentPageLayoutType.None,
        contentColumns: 0,
        diagramPosition: DiagramPosition.Left,
        pageDesign: this.getPageDesign(
          DocumentPageType.Split,
          DocumentPageContentType.Html,
          DocumentPageLayoutType.None,
          0
        )
      }),
      [DeloittePageType.TEXT_DIAGRAM]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Split,
        contentType: DocumentPageContentType.Html,
        contentPageLayoutType: DocumentPageLayoutType.None,
        contentColumns: 0,
        diagramPosition: DiagramPosition.Right,
        pageDesign: this.getPageDesign(
          DocumentPageType.Split,
          DocumentPageContentType.Html,
          DocumentPageLayoutType.None,
          0
        )
      }),
      [DeloittePageType.TEXT]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Html,
        pageDesign: this.getPageDesign(
          DocumentPageType.Content,
          DocumentPageContentType.Html
        )
      }),
      [DeloittePageType.TEXT_TEXT]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Html,
        contentPageLayoutType: DocumentPageLayoutType.None,
        contentColumns: 2,
        pageDesign: this.getPageDesign(
          DocumentPageType.Content,
          DocumentPageContentType.Html,
          DocumentPageLayoutType.None,
          2
        )
      }),
      [DeloittePageType.CONTENTS]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Layout,
        contentPageLayoutType: DocumentPageLayoutType.ContentTable,
        pageDesign: this.getPageDesign(
          DocumentPageType.Diagram,
          DocumentPageContentType.None,
          DocumentPageLayoutType.None
        )
      }),
      [DeloittePageType.CHAPTER]: (): DeloittePageConfiguration => ({
        pageType: DocumentPageType.Content,
        contentType: DocumentPageContentType.Layout,
        contentPageLayoutType: DocumentPageLayoutType.ContentTable,
        pageDesign: this.getPageDesign(
          DocumentPageType.Diagram,
          DocumentPageContentType.None,
          DocumentPageLayoutType.None
        )
      })
    };
  }

  public getPageConfiguration(
    pageType: DeloittePageType
  ): DeloittePageConfiguration {
    const configFunction = this.pageConfigMap[pageType];
    if (configFunction) {
      return configFunction();
    }
    return null;
  }

  private getPageDesign(
    pageType: DocumentPageType,
    contentType: DocumentPageContentType,
    contentPageLayoutType?: DocumentPageLayoutType,
    contentColumns?: number
  ): PageDesignDto {
    return this.layoutPageDesigns?.find(
      (pageDesign) =>
        pageDesign.pageType === pageType &&
        pageDesign.contentType === contentType &&
        (!contentPageLayoutType ||
          pageDesign.layoutType === contentPageLayoutType) &&
        (!contentColumns || pageDesign.contentColumns === contentColumns)
    );
  }
}
