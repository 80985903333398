<template>
  <div
    class="debug rounded-8 border border-main-dark-40 bg-main-dark-10"
    :class="{ hide: hidden, dragging: isDragging }"
    ref="draggable"
  >
    <div
      @mousedown="beginDrag"
      class="flex cursor-pointer flex-row items-center justify-center p-8 text-center"
    >
      <h4 class="title m-0">Debug</h4>
      <span
        @click.stop.prevent="setHiddenState(true)"
        class="hide-button inline-flex items-center justify-center"
      >
        <i class="las la-compress cursor-pointer align-middle" />
      </span>
    </div>
    <div class="debug-body p-8">
      <AppButton @click="refreshData">Refresh Data</AppButton>
      <AppButton @click="resetData">Reset Data</AppButton>

      Auto Refresh
      <AppCheckbox @change="toggleAutoUpdate" v-model="autoUpdateEnabled" />

      <div class="overscroll-y-auto">
        <slot />

        <PortalTarget multiple :name="$portalNames.Debug" ref="portal" />
      </div>
    </div>
    <div
      v-if="hidden"
      @click="setHiddenState(false)"
      class="restore-button rounded-12 border border-main-dark-01 bg-danger-red"
    ></div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'debug',
  data: () => {
    return {
      currentPosition: null,
      hidden: false,
      isDragging: true,
      JSON: JSON,
      autoUpdateTimer: null,
      autoUpdateEnabled: false
    };
  },
  methods: {
    beginDrag(e) {
      if (this.hidden) {
        return;
      }
      let elRect = this.getElement().getBoundingClientRect();
      this.offset = { x: elRect.left - e.pageX, y: elRect.top - e.pageY };
      this.isDragging = true;
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.endDrag);
    },
    endDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.endDrag);
    },
    handleMouseMove(e) {
      let pos = {
        x: e.pageX + this.offset.x,
        y: e.pageY + this.offset.y
      };
      this.setPos(pos);
    },

    setPos(pos) {
      this.currentPosition = pos;
      let el = this.getElement();
      if (pos.x < 0) {
        pos.x = 0;
      }
      if (pos.x + el.clientWidth > window.innerWidth) {
        pos.x = window.innerWidth - el.clientWidth;
      }

      if (pos.y < 0) {
        pos.y = 0;
      }
      if (pos.y + el.clientHeight > window.innerHeight) {
        pos.y = window.innerHeight - el.clientHeight;
      }
      el.style.left = `${pos.x}px`;
      el.style.top = `${pos.y}px`;
      localStorage.setItem('debug-pos', JSON.stringify(pos));
    },
    setHiddenState(hidden: boolean) {
      this.hidden = hidden;
      localStorage.setItem('debug-hidden', JSON.stringify(hidden));
    },
    getElement() {
      return this._el ?? (this._el = this.$refs['draggable']);
    },
    handleWindowResize(e) {
      this.setPos(this.currentPosition);
    },
    toggleAutoUpdate() {
      if (this.autoUpdateTimer) {
        clearInterval(this.autoUpdateTimer);
      } else {
        this.autoUpdateTimer = setInterval(this.refreshData, 1500);
      }
    },
    refreshData() {
      const portal = this.$refs.portal;
      var children = portal.children();
      for (let index = 0; index < children.length; index++) {
        const element = children[index].componentInstance;
        if (element.refreshData) {
          element.refreshData();
        }
      }
    },
    resetData() {
      const portal = this.$refs.portal;
      var children = portal.children();
      for (let index = 0; index < children.length; index++) {
        const element = children[index].componentInstance;
        if (element.resetData) {
          element.resetData();
        }
      }
    }
  },
  mounted() {
    if (localStorage.getItem('debug-pos')) {
      this.currentPosition = JSON.parse(localStorage.getItem('debug-pos'));

      this.setPos(this.currentPosition);
    }
    if (localStorage.getItem('debug-hidden')) {
      this.hidden = JSON.parse(localStorage.getItem('debug-hidden'));
    }
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    this.endDrag(); // cancel out any existing drag
  }
};
</script>

<style lang="scss">
.debug {
  min-width: 300px;
  min-height: 170px;
  position: fixed;
  display: inline-block;
  z-index: 9999999;
  max-width: 800px;
  max-height: 750px;
  overflow-y: auto;
  &:not(.dragging) {
    transition:
      top 0.3s ease 0.3s,
      left 0.3s ease;
  }
  &.hide {
    left: 0px !important;
    top: -500px !important;
  }
}

.hide-button {
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 15px;
  width: 24px;
  height: 24px;
  &:hover {
    border-color: lightskyblue;
  }
}
.restore-button {
  position: fixed;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  cursor: pointer;
  box-shadow: 0 0 3px 3px #888888;
}
</style>
