<template>
  <div class="my-4 h-1 w-full bg-main-dark-20" />
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownDivider'
})
export default class AppDropdownDivider extends Vue {}
</script>
