//@ts-nocheck
import GraphElementsComparer from '@/core/utils/GraphElementsComparer';
import clamp from 'lodash/clamp';
import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  Point,
  OrientedRectangle,
  ILookup,
  IEnumerable,
  List,
  Size,
  BaseClass,
  IEdge,
  IPoint,
  PathType,
  ArcEdgeStyle,
  ILabelSnapContextHelper,
  LabelSnapContextHelper,
  Matrix,
  INode
} from 'yfiles';
import JigsawEdgeLabelModelParameter from './JigsawEdgeLabelModelParameter';
import { toDegrees } from '@/core/utils/math.utils';
import DiagramUtils from '@/core/utils/DiagramUtils';
export default class JigsawEdgeLabelModel extends BaseClass<
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
>(ILabelModel, ILabelModelParameterProvider, ILabelModelParameterFinder) {
  /**
   * Defines at what distance from the edge the label should snap to @property snapDistance
   */
  private snapThreshold = 15;

  /**
   * Enables snapping along three 'tracks', disable this to allow free placement within @property maxDistance
   */
  private enableSnapping = true;

  /**
   * Label boundaries that we should take into account when calculating label distance
   */
  private edgeLabelOffset = 2;

  /**
   * Flag to determine if the current edge is an arc, so that it can be handled slightly differently.
   */
  private isEdgeArc = false;

  /**
   * Padding applied to sides of a label when being displayed.
   */
  private padding = 10;

  /**
   * Returns instances of the support interfaces (which are actually the model instance itself)
   */

  lookup<T>(type: Class<T>): T {
    if (type === ILabelModelParameterProvider.$class) {
      // If we request a ILabelModelParameterProvider AND we use discrete label candidates, we return the label model
      // itself, otherwise, null is returned, which means that continuous label positions are supported.
      return this;
    } else if (type === ILabelModelParameterFinder.$class) {
      // If we request a ILabelModelParameterProvider, we return the label model itself, so we can always retrieve a
      // matching parameter for a given actual position.
      return this;
    }
    if (type == ILabelSnapContextHelper.$class) {
      return LabelSnapContextHelper.INSTANCE;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const labelSize = label.preferredSize;
    if (!(layoutParameter instanceof JigsawEdgeLabelModelParameter)) {
      throw 'layoutParameter must be of type JigsawExteriorNodeLabelModelParameter';
    }
    if (!label.owner) {
      return IOrientedRectangle.EMPTY;
    }
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    this.isEdgeArc = label.owner.style instanceof ArcEdgeStyle;
    const points = JigsawEdgeLabelModel.getEdgePoints(label.owner);
    const normalizedSegmentIndex = JigsawEdgeLabelModel.normalizeSegmentIndex(
      layoutParameter.segmentIndex,
      points
    );
    const angle = label.layoutParameter.angle ?? 0;
    const isSegmentVertical = JigsawEdgeLabelModel.isSegmentVertical(
      points,
      normalizedSegmentIndex
    );
    const labelPosition = this.getLabelPosition(
      label,
      points,
      layoutParameter.ratio,
      normalizedSegmentIndex,
      layoutParameter.left,
      this.getSnapDistance(
        label,
        angle,
        layoutParameter.distance,
        isSegmentVertical,
        this.isEdgeArc,
        normalizedSegmentIndex
      ),
      angle
    );

    const labelRect = this.createLabelOrientedRectangle(
      labelPosition,
      labelSize,
      angle,
      isSegmentVertical
    );
    return this.applyPaddingAtBoundary(
      label,
      labelSize,
      layoutParameter,
      labelRect,
      points,
      normalizedSegmentIndex
    );
  }

  private applyPaddingAtBoundary(
    label: ILabel,
    labelSize: Size,
    layoutParameter: JigsawEdgeLabelModelParameter,
    labelRect: IOrientedRectangle,
    points: Point[],
    segmentIndex: number
  ): IOrientedRectangle {
    if ((label.owner as IEdge).style instanceof ArcEdgeStyle) {
      const arcSegmentInfo = this.getArcLabelSegmentAndRatio(
        points,
        layoutParameter.ratio
      );
      segmentIndex = arcSegmentInfo.segmentIndex;
    }
    const isSegmentVertical = JigsawEdgeLabelModel.isSegmentVertical(
      points,
      segmentIndex
    );
    const isTextVertical =
      Math.abs(labelRect.toOrientedRectangle().angle) === Math.abs(Math.PI / 2);
    const dimension =
      (isTextVertical
        ? isSegmentVertical
          ? labelSize.width
          : labelSize.height
        : isSegmentVertical
          ? labelSize.height
          : labelSize.width) / 2;

    const orientedRect = new OrientedRectangle(labelRect);

    const sourceLayout = new OrientedRectangle(
      (label.owner.sourceNode as INode).layout.toRect()
    );
    sourceLayout.angle = 0;
    const targetLayout = new OrientedRectangle(
      (label.owner.targetNode as INode).layout.toRect()
    );
    targetLayout.angle = 0;
    const intersectsSource = sourceLayout.bounds.intersects(orientedRect);
    const intersectsTarget = targetLayout.bounds.intersects(orientedRect);

    if (intersectsSource || intersectsTarget) {
      const sourcePoint = label.owner.sourcePort.location.toPoint();
      const targetPoint = label.owner.targetPort.location.toPoint();
      let newCentreX = orientedRect.bounds.centerX;
      let newCentreY = orientedRect.bounds.centerY;

      if (isSegmentVertical) {
        const lower = Math.min(sourcePoint.y, targetPoint.y);
        const upper = Math.max(sourcePoint.y, targetPoint.y);
        const lowerHit = lower >= orientedRect.bounds.minY;

        newCentreY = lowerHit
          ? lower + dimension + this.padding
          : upper - dimension - this.padding;
      } else {
        const lower = Math.min(sourcePoint.x, targetPoint.x);
        const upper = Math.max(sourcePoint.x, targetPoint.x);
        const lowerHit = lower >= orientedRect.bounds.minX;

        newCentreX = lowerHit
          ? lower + dimension + this.padding
          : upper - dimension - this.padding;
      }
      orientedRect.setCenter(new Point(newCentreX, newCentreY));
    }
    return orientedRect;
  }

  private createLabelOrientedRectangle(
    position: Point,
    size: Size,
    angle: number
  ): IOrientedRectangle {
    const center = new Point(
      position.x + size.width / 2,
      position.y - size.height / 2
    );
    const transformPoint = this.getTransformedPoint(position, center, angle);
    const rect = new OrientedRectangle(
      transformPoint.toMutablePoint(),
      size.toMutableSize()
    );
    rect.angle = angle;
    return rect;
  }

  private getTransformedPoint(
    location: Point,
    center: Point,
    angle: number
  ): Point {
    const matrix = new Matrix();
    matrix.rotate(angle * -1, center);
    return matrix.transform(location);
  }

  private getArcLabelSegmentAndRatio(
    points: IPoint[],
    ratio: number
  ): {
    segmentIndex: number;
    ratio: number;
  } {
    const totalSegments = points.length - 1;

    const segmentIndex = totalSegments * ratio;

    const segmentRatio = segmentIndex % 1;

    return {
      ratio: segmentRatio,
      segmentIndex: Math.floor(segmentIndex)
    };
  }

  /**
   *
   * @param label The label for which we are calculating the position
   * @param points The points along the label's edge, ports, bends etc, this is used to break up the edge into segments
   * @param ratio The ratio along the segmentIndex where the label should be placed
   * @param normalizedSegmentIndex The segment index
   * @param left Which side of the edge the label is placed
   * @param distance The distance from the edge at which the label should be placed
   * @returns
   */

  private getLabelPosition(
    label: ILabel,
    points: IPoint[],
    ratio: number,
    normalizedSegmentIndex: number,
    left: boolean,
    distance: number,
    angle: number
  ): Point {
    const labelSize = label.preferredSize;
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    if (label.owner.style instanceof ArcEdgeStyle) {
      // arcs are treated as a single segment
      // we ignore the segmentIndex passed in and calculcate it
      // the incoming ratio is actually a ratio along the whole line
      // we convert the incoming ratio into a segment and ratio relative to that segment
      const arcSegmentInfo = this.getArcLabelSegmentAndRatio(points, ratio);
      normalizedSegmentIndex = arcSegmentInfo.segmentIndex;
      ratio = arcSegmentInfo.ratio;
      this.isEdgeArc = true;
    }

    // The start of the segment
    const start = points[normalizedSegmentIndex];
    // The end of the segment
    const end = points[normalizedSegmentIndex + 1];
    // difference between x/y coordinates for stand and end
    const xDiff = end.x - start.x;
    const yDiff = end.y - start.y;

    // if the label is on the "left" of the line, then is distance should be flipped to a negative value
    distance = left ? distance * -1 : distance;
    const isSegmentVertical = JigsawEdgeLabelModel.isSegmentVertical(
      points,
      normalizedSegmentIndex
    );
    const maxDistance = this.getDistance(
      label,
      angle,
      isSegmentVertical,
      this.isEdgeArc,
      normalizedSegmentIndex
    );

    //clamp the distance  between the negative and positive maxDistance
    distance = clamp(distance, maxDistance * -1, maxDistance);

    // calculate the angle of the line in degrees 0-360
    let edgeAngle =
      ((((-(Math.atan2(start.x - end.x, start.y - end.y) * (180 / Math.PI)) %
        360) +
        360) %
        360) *
        Math.PI) /
      180;

    // calculate a position along the edge using ratio
    // TODO: this is moving as the size increases, need to have it fixed
    const vectorX = start.x + xDiff * ratio - labelSize.width / 2;
    const vectorY = start.y + yDiff * ratio + labelSize.height / 2;

    // apply  a distance from the line at the correct angle to generate a point
    const midPointX = vectorX + distance * Math.cos(edgeAngle);
    const midPointY = vectorY + distance * Math.sin(edgeAngle);

    return new Point(midPointX, midPointY);
  }

  /**
   * Get the maximum distance a label can be away from the edge.
   * Currently width or height of the label depending on it's orientation / 2
   * plus some padding (edgeLabelOffset)
   * @param label
   * @param angle
   * @returns
   */
  getMaxDistance(
    label: ILabel,
    angle: number,
    isSegmentVertical: boolean
  ): number {
    const degree = toDegrees(angle);
    const height = label.preferredSize.height + this.edgeLabelOffset;
    const width = label.preferredSize.width + this.edgeLabelOffset;
    if ([0, 180].includes(Math.abs(degree))) {
      return (isSegmentVertical ? width : height) / 2;
    }
    if ([90, 270].includes(Math.abs(degree))) {
      return (isSegmentVertical ? height : width) / 2;
    }
    const sinAngle = Math.sin(angle);
    const cosAngle = Math.cos(angle);
    return (Math.abs(width * cosAngle) + Math.abs(height * sinAngle)) / 2;
  }
  getMaxDistanceArc(label: ILabel, initialSegmentIndex: number): number {
    const { isHorizontal } = DiagramUtils.getEdgeLabelSegmentInfo(
      label,
      initialSegmentIndex
    );
    return (
      (isHorizontal ? label.preferredSize.height : label.preferredSize.width) /
        2 +
      this.edgeLabelOffset
    );
  }
  getDistance(
    label: ILabel,
    angle: number,
    isSegmentVertical: boolean,
    isEdgeArc: boolean,
    initialSegmentIndex: number
  ): number {
    return isEdgeArc
      ? this.getMaxDistanceArc(label, initialSegmentIndex)
      : this.getMaxDistance(label, angle, isSegmentVertical);
  }
  createDefaultParameter(): ILabelModelParameter {
    return this.createParameterForSegment(0, 0.5, 0, false, 0);
  }

  createParameterForSegment(
    segmentIndex: number,
    ratio: number,
    distance: number,
    left: boolean,
    angle: number
  ): JigsawEdgeLabelModelParameter {
    return new JigsawEdgeLabelModelParameter(this, {
      ratio: ratio,
      segmentIndex: segmentIndex,
      distance: distance,
      left: left,
      angle: angle
    });
  }

  createMidpointParameter(
    edge: IEdge,
    distance = 0,
    left = false,
    angle = 0
  ): JigsawEdgeLabelModelParameter {
    const points = JigsawEdgeLabelModel.getEdgePoints(edge);

    if (edge.style instanceof ArcEdgeStyle) {
      return this.createParameterForSegment(0, 0.5, distance, left, angle);
    }
    // Straight line
    if (points.length == 2) {
      return this.createParameterForSegment(0, 0.5, distance, left, angle);
    }

    if (points.length > 3) {
      let midPoint = points.length / 2;
      let ratio = midPoint % 1;

      // adjust for equal number of points on both side, we place the label slightly before the center
      // then offset it half way along that segment.
      if (ratio == 0) {
        ratio = 0.5;
      }
      const segmentIndex = Math.floor(midPoint - 1);
      return new JigsawEdgeLabelModel().createParameterForSegment(
        segmentIndex,
        ratio,
        distance,
        left,
        angle
      );
    }

    // When there is only two segments (3 points), choose the longest segment
    if (points.length == 3) {
      // get the longest segment index
      let longestSegmentIndex = this.getLongestSegmentIndex(points);
      return this.createParameterForSegment(
        longestSegmentIndex,
        0.5,
        distance,
        left,
        angle
      );
    }

    return this.createDefaultParameter() as unknown as JigsawEdgeLabelModelParameter;
  }

  private getLongestSegmentIndex(points: IPoint[]): number {
    let longestSegmentIndex = 0;
    let longestSegmentLength = 0;

    for (let index = 0; index < points.length - 1; index++) {
      const p1 = points[index];
      const p2 = points[index + 1];
      const distance = p1.distanceTo(p2);
      if (distance > longestSegmentLength) {
        longestSegmentIndex = index;
        longestSegmentLength = distance;
      }
    }
    return longestSegmentIndex;
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }

  public static normalizeSegmentIndex(
    initialSegmentIndex: number,
    points: IPoint[]
  ): number {
    let segmentIndex = initialSegmentIndex;
    // the segment index can be less than 1, this indicates a percentage a long the whole path, the true segment index is dynamically chosen
    if (!Number.isInteger(segmentIndex)) {
      segmentIndex = Math.ceil((points.length - 1) * segmentIndex) - 1;
    }
    // the segment no longer exists, fallback to 0
    if (segmentIndex >= points.length - 1) {
      segmentIndex = 0;
    }
    return segmentIndex;
  }

  public static getEdgePoints(edge: IEdge): IPoint[] {
    const points = [];
    const path = edge.style.renderer
      .getPathGeometry(edge, edge.style)
      .getPath();
    const cursor = path.createCursor();
    while (cursor.moveNext()) {
      switch (cursor.pathType) {
        case PathType.MOVE_TO:
        case PathType.LINE_TO:
          points.push(cursor.currentEndPoint);
      }
    }

    if (points.length == 0) {
      // When no points are available from the SVG path, could be caused by nothing to draw
      // fall back to ports
      points.push(edge.sourcePort.location, edge.targetPort.location);
    }
    return points;
  }

  public static isSegmentVertical(points: IPoint[], index: number): boolean {
    const start = points[index];
    const end = points[index + 1];
    return start && end ? Math.abs(end.x) == Math.abs(start.x) : false;
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    const points = JigsawEdgeLabelModel.getEdgePoints(label.owner);
    const totalSegments = points.length - 1;

    let closestSegmentIndex = 0;
    let distance = Number.MAX_SAFE_INTEGER;
    let closestSegmentStart = null;
    let closestSegmentEnd = null;

    const currentLayout = label.layout.toOrientedRectangle();
    const point = layout.toOrientedRectangle().center;

    const angle = currentLayout.angle;

    for (let index = 0; index < totalSegments; index++) {
      const segmentStart = points[index];
      const segmentEnd = points[index + 1];
      const distanceToSegment = this.getDistanceToSegment(
        point.x,
        point.y,
        segmentStart.x,
        segmentStart.y,
        segmentEnd.x,
        segmentEnd.y
      );

      if (distanceToSegment < distance) {
        distance = distanceToSegment;
        closestSegmentIndex = index;
        closestSegmentStart = segmentStart;
        closestSegmentEnd = segmentEnd;
      }
    }
    const ratioInfo = this.getSegmentRatioInfo(
      point,
      closestSegmentStart,
      closestSegmentEnd
    );
    const { min, max } = this.minMaxRatio();
    let ratio = clamp(ratioInfo.ratio, min, max);
    if (label.owner.style instanceof ArcEdgeStyle) {
      // Arc Edges have all segments combined into a single segment and the ratio is along the whole line
      ratio = (closestSegmentIndex + ratio) / totalSegments;
      this.isEdgeArc = true;
    }
    const isSegmentVertical = JigsawEdgeLabelModel.isSegmentVertical(
      points,
      closestSegmentIndex
    );
    return new JigsawEdgeLabelModelParameter(this, {
      segmentIndex: closestSegmentIndex,
      ratio: ratio,
      distance: this.getSnapDistance(
        label,
        angle,
        distance,
        isSegmentVertical,
        this.isEdgeArc,
        closestSegmentIndex
      ),
      left: ratioInfo.left,
      angle: angle
    });
  }

  private getSnapDistance(
    label: ILabel,
    angle: number,
    distance: number,
    isSegmentVertical: boolean,
    isEdgeArc: boolean,
    segmentIndex: number
  ): number {
    // If they user hasn't dragged the label beyond the snap threshhold, we do nothing.
    if (distance < this.snapThreshold / 2) {
      return 0;
    }
    const maxDistance = this.getDistance(
      label,
      angle,
      isSegmentVertical,
      isEdgeArc,
      segmentIndex
    );

    if (!this.enableSnapping) {
      //clamp the distance between the negative and positive maxDistance
      return clamp(distance, maxDistance * -1, maxDistance);
    }

    // if the max distance is less than the threshold, we use the snapThreshold instead
    return maxDistance < this.snapThreshold ? this.snapThreshold : maxDistance;
  }

  private getSegmentRatioInfo(
    point: IPoint,
    segmentA: IPoint,
    segmentB: IPoint
  ): IRatioInfo {
    const atob = { x: segmentB.x - segmentA.x, y: segmentB.y - segmentA.y };
    const atop = { x: point.x - segmentA.x, y: point.y - segmentA.y };
    const len = atob.x * atob.x + atob.y * atob.y;
    let dot = atop.x * atob.x + atop.y * atob.y;
    const t = GraphElementsComparer.pointsEqual(segmentA, segmentB)
      ? 0
      : Math.min(1, Math.max(0, dot / len));
    dot =
      (segmentB.x - segmentA.x) * (point.y - segmentA.y) -
      (segmentB.y - segmentA.y) * (point.x - segmentA.x);

    return {
      point: {
        x: segmentA.x + atob.x * t,
        y: segmentA.y + atob.y * t
      },
      left: dot < 1,
      dot: dot,
      ratio: t
    };
  }

  private getDistanceToSegment(
    x: number,
    y: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): number {
    const xDiff = x - x1;
    const yDiff = y - y1;
    const segmentXDiff = x2 - x1;
    const segmentYDiff = y2 - y1;

    const dot = xDiff * segmentXDiff + yDiff * segmentYDiff;
    const lengthSquared =
      segmentXDiff * segmentXDiff + segmentYDiff * segmentYDiff;
    let p = -1;
    if (lengthSquared != 0) {
      p = dot / lengthSquared;
    }

    let xx: number = 0;
    let yy: number = 0;

    if (p < 0) {
      xx = x1;
      yy = y1;
    } else if (p > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + p * segmentXDiff;
      yy = y1 + p * segmentYDiff;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

  private minMaxRatio(): { min: number; max: number } {
    let min = 0;
    let max = 1;
    return { min, max };
  }
}
interface IRatioInfo {
  point: {
    x: number;
    y: number;
  };
  left: boolean;
  dot: number;
  ratio: number;
}
