import Vue from 'vue';

const elementData: {
  element: HTMLElement;
  observer: ResizeObserver;
  scale: number;
  enabled: boolean;
}[] = [];

const performScaleFit = (el: HTMLElement) => {
  const item = elementData.find((o) => o.element == el);
  if (!el.parentElement || !item || !item.scale) {
    return;
  }
  if (!item.enabled) {
    el.style.setProperty('width', '');
    el.style.setProperty('height', '');
    el.style.setProperty('position', '');
    el.style.setProperty('transform', '');
    return;
  }
  const parentSize = el.parentElement.getBoundingClientRect();
  el.style.setProperty(
    'width',
    (parentSize.width / item.scale).toFixed(2) + 'px',
    'important'
  );
  el.style.setProperty(
    'height',
    (parentSize.height / item.scale).toFixed(2) + 'px',
    'important'
  );
  el.style.setProperty('position', 'absolute', 'important');
  el.style.setProperty('transform', `scale(${item.scale})`, 'important');
  el.style.setProperty('transform-origin', 'top left', 'important');
};

Vue.directive('scalefit', {
  inserted: (el, binding, vnode) => {
    const item = elementData.find((o) => o.element == el);
    if (item) {
      if (binding.arg == 'enabled') {
        item.enabled = binding.value;
      }
    } else {
      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        performScaleFit(el);
      });
      observer.observe(el.parentElement);

      elementData.push({
        element: el,
        observer: observer,
        scale: binding.value,
        enabled: true
      });
    }

    performScaleFit(el);
  },
  update: (el, binding, vnode) => {
    const item = elementData.find((o) => o.element == el);
    if (!item) {
      return;
    }

    let updated = false;
    if (binding.arg == 'enabled') {
      if (item.enabled !== binding.value) {
        item.enabled = binding.value;
        updated = true;
      }
    } else {
      if (item.scale !== binding.value) {
        item.scale = binding.value;
        updated = true;
      }
    }

    if (updated) {
      performScaleFit(el);
    }
  },
  unbind: (el, binding, vnode) => {
    const item = elementData.find((o) => o.element == el);
    if (item) {
      item.observer.disconnect();
      const itemIdx = elementData.indexOf(item);
      elementData.splice(itemIdx, 1);
    }
  }
});
