import AutomationUtils from '@/core/services/analytics/AutomationUtils';
import {
  BaseClass,
  DefaultLabelStyle,
  FreeLabelModel,
  IBoundsProvider,
  ICanvasContext,
  ICanvasObject,
  ICanvasObjectDescriptor,
  IHitTestable,
  IInputModeContext,
  Insets,
  IRenderContext,
  IVisibilityTestable,
  IVisualCreator,
  Matrix,
  Point,
  Rect,
  SimpleLabel,
  Size,
  SvgVisual,
  SvgVisualGroup,
  Visual
} from 'yfiles';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import diagramConfig from '@/core/config/diagram.definition.config';
const RenderCacheKey = '_renderCache';

export default class ButtonDescriptor extends BaseClass<
  ICanvasObjectDescriptor,
  IVisualCreator,
  IBoundsProvider,
  IVisibilityTestable,
  IHitTestable
>(
  ICanvasObjectDescriptor,
  IVisualCreator,
  IBoundsProvider,
  IVisibilityTestable,
  IHitTestable
) {
  /**
   * The anchor point of the button, this is considered to be the center of there the button should be positioned
   */
  private anchor: Point = null;
  /**
   * Dummy label for rendering any text
   */
  private readonly dummyLabel: SimpleLabel;
  /**
   * The button we're rendering
   */
  private button: Button = null;
  /**
   * The button group we're rendering
   */
  private buttonGroup: ButtonGroup = null;
  /**
   * When rendering a ButtonGroup, this is the standard button Size that should be used
   */
  private buttonGroupButtonSize: Size = null;

  /**
   * The padding between an icon and text
   */
  private iconAndTextSpace: number = 4;

  /**
   * True when rendering a ButtonGroup
   */
  private get isButtonGroup(): boolean {
    return !!this.buttonGroup;
  }

  constructor() {
    super();
    this.dummyLabel = new SimpleLabel();
  }

  public buttonSeparatorColor = '#e0dee0';
  public buttonSeparatorWidth = 1;

  //#region interface implementations
  public getBoundsProvider(forUserObject: any): IBoundsProvider {
    this.initialize(forUserObject);
    return this;
  }

  public getHitTestable(forUserObject: any): IHitTestable {
    this.initialize(forUserObject);
    return this;
  }

  public getVisibilityTestable(forUserObject: any): IVisibilityTestable {
    this.initialize(forUserObject);
    return this;
  }

  public getVisualCreator(forUserObject: any): IVisualCreator {
    this.initialize(forUserObject);
    return this;
  }

  public isDirty(
    context: ICanvasContext,
    canvasObject: ICanvasObject
  ): boolean {
    return true;
  }

  public createVisual(context: IRenderContext): SvgVisual | null {
    if (this.isButtonGroup) {
      return this.createButtonGroupVisual(context, this.buttonGroup);
    }
    return this.createButtonVisual(context, this.button, null);
  }

  public updateVisual(
    context: IRenderContext,
    oldVisual: Visual
  ): Visual | null {
    if (this.isButtonGroup) {
      return this.updateButtonGroupVisual(context, oldVisual, this.buttonGroup);
    }
    return this.updateButtonVisual(context, oldVisual, this.button, null);
  }

  /**
   * Returns a Rect.EMPTY instance. Buttons should not affect the graphs content rect
   * @param context
   * @returns
   */
  public getBounds(context: ICanvasContext): Rect {
    return Rect.EMPTY;
  }

  public isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    this.updateDummyLabel(this.button);
    this.updateButtonGroupButtonSize(this.buttonGroup);
    let bounds: Rect = null;
    if (this.isButtonGroup) {
      bounds = this.getButtonGroupBounds(this.buttonGroup);
    } else {
      bounds = this.getButtonLayout(this.button, null);
    }
    return bounds.intersects(rectangle);
  }

  public isHit(context: IInputModeContext, location: Point): boolean {
    this.updateDummyLabel(this.button);
    this.updateButtonGroupButtonSize(this.buttonGroup);
    let bounds: Rect = null;
    if (this.isButtonGroup) {
      bounds = this.getButtonGroupBounds(this.buttonGroup);
    } else {
      bounds = this.getButtonLayout(this.button, null);
    }
    return bounds.containsWithEps(location, 0.001);
  }
  //#endregion

  private createButtonVisual(
    context: IRenderContext,
    button: Button,
    parent?: ButtonGroup,
    buttonLayout?: Rect
  ): SvgVisualGroup | null {
    const container = new SvgVisualGroup();

    if (button.tag?.automationElementType) {
      AutomationUtils.attachAutomationIdToSvg(
        container.svgElement,
        button.tag.automationElementType,
        button.tag.automationVariant
      );
    }
    container.svgElement.classList.add('bim-button');
    if (button.disabled) {
      container.svgElement.classList.add('bim-disabled');
    }

    const layout = buttonLayout ?? this.getButtonLayout(button, parent);

    container.transform = this.createMatrix(layout, button.angle);

    container.add(
      createRectVisual(
        layout,
        button.fill ?? parent?.fill ?? 'transparent',
        button.stroke,
        button.strokeWidth,
        button.cornerRadius,
        ['background']
      )
    );

    // create button icon
    let iconLocation: Point = this.getIconLocation(button);
    if (button.icon) {
      const iconElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image'
      );
      iconElement.setAttribute('href', button.icon);
      iconElement.setAttribute('preserveaspectratio', 'none');
      iconElement.setAttribute('width', button.iconSize.width.toString());
      iconElement.setAttribute('height', button.iconSize.height.toString());
      const iconVisual = new SvgVisual(iconElement);

      const defsElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'defs'
      );
      const filterElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'filter'
      );
      filterElement.setAttribute('id', 'colorOverride');

      const colorMatrixElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'feColorMatrix'
      );
      colorMatrixElement.setAttribute('in', 'SourceGraphic');
      colorMatrixElement.setAttribute('type', 'matrix');
      colorMatrixElement.setAttribute(
        'values',
        `1 0 0 0 ${diagramConfig.button.disabledButtonLightenAmount}
         0 1 0 0 ${diagramConfig.button.disabledButtonLightenAmount}
         0 0 1 0 ${diagramConfig.button.disabledButtonLightenAmount}
         0 0 0 1 0`
      );

      filterElement.appendChild(colorMatrixElement);
      defsElement.appendChild(filterElement);
      iconVisual.svgElement.insertBefore(
        defsElement,
        iconVisual.svgElement.firstChild
      );

      if (button.disabled) {
        iconElement.setAttribute('filter', 'url(#colorOverride)');
      }

      SvgVisual.setTranslate(
        iconVisual.svgElement,
        iconLocation.x,
        iconLocation.y
      );
      container.children.add(iconVisual);

      if (button.animationName) {
        iconVisual.svgElement.classList.add('animated', button.animationName);
      }
    }

    // create button text
    if (button.text) {
      const textLocation = this.getTextLocation(button, iconLocation);
      const labelVisual = this.dummyLabel.style.renderer
        .getVisualCreator(this.dummyLabel, this.dummyLabel.style)
        .createVisual(context) as SvgVisual;
      SvgVisual.setTranslate(
        labelVisual.svgElement,
        textLocation.x,
        textLocation.y
      );
      container.children.add(labelVisual);
    }

    return container;
  }

  private updateButtonVisual(
    context: IRenderContext,
    oldVisual: Visual,
    button: Button,
    parent?: ButtonGroup,
    buttonLayout?: Rect
  ): SvgVisual | null {
    let visualIndex = 0;
    const oldVisualGroup = oldVisual as SvgVisualGroup;

    const layout = buttonLayout ?? this.getButtonLayout(button, parent);

    updateRectVisual(
      oldVisualGroup.children.at(visualIndex++),
      layout,
      button.fill ?? parent?.fill ?? 'transparent',
      button.stroke,
      button.strokeWidth,
      button.cornerRadius
    );

    // create button icon
    let iconLocation: Point = this.getIconLocation(button);
    if (button.icon) {
      const iconElement = oldVisualGroup.children.at(visualIndex++).svgElement;
      SvgVisual.setTranslate(iconElement, iconLocation.x, iconLocation.y);
      if (iconElement.getAttribute('href') != button.icon) {
        iconElement.setAttribute('href', button.icon);
      }
      iconElement.setAttribute('width', button.iconSize.width.toString());
      iconElement.setAttribute('height', button.iconSize.height.toString());
      const iconVisual = new SvgVisual(iconElement);
      if (!button.animationName) {
        iconVisual.svgElement?.classList?.remove('animated');
      }

      if (button.disabled) {
        iconElement.setAttribute('filter', 'url(#colorOverride)');
      } else {
        iconElement.removeAttribute('filter');
      }
    }

    if (button.disabled) {
      oldVisualGroup.svgElement.classList.add('bim-disabled');
    } else {
      oldVisualGroup.svgElement.classList.remove('bim-disabled');
    }

    // create button text
    if (button.text) {
      const textLocation = this.getTextLocation(button, iconLocation);
      const labelVisual = this.dummyLabel.style.renderer
        .getVisualCreator(this.dummyLabel, this.dummyLabel.style)
        .updateVisual(
          context,
          oldVisualGroup.children.at(visualIndex)
        ) as SvgVisual;
      SvgVisual.setTranslate(
        labelVisual.svgElement,
        textLocation.x,
        textLocation.y
      );
      oldVisualGroup.children.set(visualIndex++, labelVisual);
    }
    oldVisualGroup.transform = this.createMatrix(layout, button.angle);
    return oldVisualGroup;
  }

  private createButtonGroupVisual(
    context: IRenderContext,
    buttonGroup: ButtonGroup
  ): SvgVisual | null {
    const buttonGroupLayout = this.getButtonGroupBounds(buttonGroup);
    var container = new SvgVisualGroup();
    container.svgElement.classList.add('bim-group');
    if (buttonGroup.fill || buttonGroup.stroke) {
      container.add(
        this.createButtonGroupBackgroundVisual(
          buttonGroupLayout,
          buttonGroup.fill,
          buttonGroup.stroke,
          buttonGroup.strokeWidth,
          buttonGroup.cornerRadius
        )
      );
    }
    let nextButtonX = null;

    buttonGroup.buttons.forEach((button, index) => {
      this.updateDummyLabel(button);
      const buttonLayout = this.getButtonLayout(
        button,
        buttonGroup,
        nextButtonX
      );
      nextButtonX = buttonLayout.maxX;

      const buttonVisual = this.createButtonVisual(
        context,
        button,
        buttonGroup,
        buttonLayout
      );

      container.add(buttonVisual);

      if (this.isButtonSeparatorRequired(index, buttonGroup)) {
        container.add(
          this.renderButtonSeparator(
            new Point(buttonLayout.maxX, buttonLayout.topRight.y),
            new Point(buttonLayout.maxX, buttonLayout.bottomRight.y)
          )
        );
      }
    });
    container[RenderCacheKey] = { buttonCount: buttonGroup.buttons.length };
    return container;
  }

  private updateButtonGroupVisual(
    context: IRenderContext,
    oldVisual: Visual,
    buttonGroup: ButtonGroup
  ): Visual | null {
    // any change to the number of buttons then lets recreate the whole visual
    if (
      !oldVisual[RenderCacheKey] ||
      oldVisual[RenderCacheKey].buttonCount != buttonGroup.buttons.length
    ) {
      return this.createButtonGroupVisual(context, buttonGroup);
    }

    const oldVisualGroup = oldVisual as SvgVisualGroup;

    const buttonGroupLayout = this.getButtonGroupBounds(buttonGroup);
    let visualIndex = 0;
    if (buttonGroup.fill || buttonGroup.stroke) {
      this.updateButtonGroupBackgroundVisual(
        oldVisualGroup.children.at(visualIndex++),
        buttonGroupLayout
      );
    }
    let nextButtonX = null;
    buttonGroup.buttons.forEach((button, index) => {
      this.updateDummyLabel(button);
      const buttonLayout = this.getButtonLayout(
        button,
        buttonGroup,
        nextButtonX
      );
      nextButtonX = buttonLayout.maxX;
      this.updateButtonVisual(
        context,
        oldVisualGroup.children.at(visualIndex++),
        button,
        buttonGroup,
        buttonLayout
      );

      if (this.isButtonSeparatorRequired(index, buttonGroup)) {
        this.renderButtonSeparator(
          new Point(buttonLayout.maxX, buttonLayout.topRight.y),
          new Point(buttonLayout.maxX, buttonLayout.bottomRight.y),
          oldVisualGroup.children.at(visualIndex++)
        );
      }
    });
    return oldVisualGroup;
  }

  private isButtonSeparatorRequired(
    buttonIndex: number,
    buttonGroup: ButtonGroup
  ): boolean {
    return buttonIndex >= 0 && buttonIndex < buttonGroup.buttons.length - 1;
  }

  private renderButtonSeparator(
    a: Point,
    b: Point,
    oldVisual?: SvgVisual
  ): SvgVisual {
    let pathElement: SVGPathElement;
    if (!oldVisual) {
      pathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      oldVisual = new SvgVisual(pathElement);

      pathElement.setAttribute('stroke', this.buttonSeparatorColor);

      pathElement.setAttribute(
        'stroke-width',
        this.buttonSeparatorWidth.toString()
      );
    } else {
      pathElement = oldVisual.svgElement as SVGPathElement;
    }
    const pathData = `M ${a.x} ${a.y} L ${b.x} ${b.y} Z`;
    pathElement.setAttribute('d', pathData);
    return oldVisual;
  }

  private createButtonGroupBackgroundVisual(
    layout: Rect,
    fill: string,
    stroke: string,
    strokeWidth: number,
    cornerRadius: number
  ): SvgVisual {
    return this.updateButtonGroupBackgroundVisual(
      createRectVisual(layout, fill, stroke, strokeWidth, cornerRadius, [
        'background'
      ]),
      layout
    );
  }

  private updateButtonGroupBackgroundVisual(
    visual: SvgVisual,
    layout: Rect
  ): SvgVisual {
    const element = visual.svgElement;
    SvgVisual.setTranslate(element, layout.x, layout.y);
    return visual;
  }

  public initialize(forUserObject: any): void {
    this.button = forUserObject;
    this.buttonGroup =
      forUserObject instanceof ButtonGroup ? forUserObject : null;
    if (!this.button.anchor) {
      throw 'anchor must be supplied';
    }
    this.anchor =
      this.button.anchor instanceof Point
        ? this.button.anchor
        : this.button.anchor(this.button);
    this.updateButtonGroupButtonSize(this.buttonGroup);
  }

  private updateButtonGroupButtonSize(buttonGroup: ButtonGroup): void {
    if (!buttonGroup) {
      this.buttonGroupButtonSize = null;
      return;
    }
    let width = 0;
    let height = 0;
    buttonGroup.buttons.forEach((btn, index) => {
      this.updateDummyLabel(btn);
      const bounds = this.getButtonLayout(btn, null);

      width = Math.max(width, bounds.width);
      height = Math.max(height, bounds.height);
    });
    this.buttonGroupButtonSize = new Size(
      width + buttonGroup.padding.horizontalInsets,
      height
    );
  }

  private getIconLocation(button: Button): Point {
    if (!button.icon) {
      return Point.ORIGIN;
    }
    const padding = button.padding;
    let yAdjust = 0;
    if (button.iconSize.height < this.dummyLabel.layout.height) {
      yAdjust = (this.dummyLabel.layout.height - button.iconSize.height) / 2;
    }

    return Point.ORIGIN.add(new Point(padding.left, padding.top + yAdjust));
  }

  private getTextLocation(button: Button, iconLocation: Point): Point {
    if (!iconLocation) {
      return Point.ORIGIN;
    }
    let yAdjust = 0;
    if (button.iconSize.height > this.dummyLabel.layout.height) {
      yAdjust = (button.iconSize.height - this.dummyLabel.layout.height) / 2;
    }

    return new Point(
      iconLocation.x + button.iconSize.width + this.iconAndTextSpace,
      button.padding.top + yAdjust
    );
  }

  private getButtonGroupWidth(buttonGroup: ButtonGroup): number {
    return (
      buttonGroup.buttons.reduce(
        (prev, curr) => prev + this.getButtonLayout(curr, null).width,
        0
      ) + buttonGroup.padding.horizontalInsets
    );
  }

  public getButtonGroupBounds(buttonGroup: ButtonGroup): Rect {
    const buttonGroupWidth = this.getButtonGroupWidth(buttonGroup);
    const buttonGroupSize = new Size(
      buttonGroupWidth,
      this.buttonGroupButtonSize.height
    );
    const buttonGroupLocation = this.getButtonLocation(buttonGroupSize);
    return new Rect(buttonGroupLocation, buttonGroupSize);
  }

  public getButtonLayout(
    button: Button,
    parent: ButtonGroup,
    buttonX?: number
  ): Rect {
    if (button instanceof ButtonGroup) {
      throw 'button must be of type Button';
    }

    const getButtonSize = (): Size => {
      this.updateDummyLabel(button);
      const padding = button.padding;
      const contentSize = this.getButtonContentSize(button);
      return new Size(
        contentSize.width + padding.horizontalInsets,
        contentSize.height + padding.verticalInsets
      );
    };

    if (parent) {
      const buttonGroupLayout = this.getButtonGroupBounds(parent);

      const buttonSize = getButtonSize();
      return new Rect(
        new Point(buttonX ?? buttonGroupLayout.x, buttonGroupLayout.y),
        buttonSize
      );
    }

    const buttonSize = getButtonSize();

    return new Rect(this.getButtonLocation(buttonSize), buttonSize);
  }

  private getIconSize(button: Button): Size {
    if (button.icon && button.iconSize) {
      return button.iconSize;
    }
    return Size.ZERO;
  }

  private getTextSize(button: Button): Size {
    if (button.text) {
      return this.dummyLabel.layout.toSize();
    }
    return Size.ZERO;
  }

  /**
   * Gets the content size of icon + text, no padding
   * @param button
   */
  private getButtonContentSize(button: Button): Size {
    const iconSize = this.getIconSize(button);
    const textSize = this.getTextSize(button);
    let spacing = 0;
    if (!iconSize.equals(Size.ZERO) && !textSize.equals(Size.ZERO)) {
      spacing = this.iconAndTextSpace;
    }
    return new Size(
      iconSize.width + textSize.width + spacing,
      Math.max(iconSize.height, textSize.height)
    );
  }

  private getButtonLocation(size: Size): Point {
    return this.anchor.subtract(new Point(size.width / 2, 0));
  }

  private updateDummyLabel(button: Button): void {
    this.dummyLabel.text = button.text ?? '';
    this.dummyLabel.layoutParameter = FreeLabelModel.INSTANCE.createAbsolute([
      0, 0
    ]);
    this.dummyLabel.style = new DefaultLabelStyle({
      font: button.font,
      textFill: button.disabled
        ? diagramConfig.button.disabledButtonLabelColor
        : '#000000'
    });
    this.dummyLabel.adoptPreferredSizeFromStyle();
  }

  private createMatrix(bounds: Rect, angle: number): Matrix {
    if (!angle) {
      angle = 0;
    }
    const matrix = new Matrix();
    matrix.translate(bounds.toPoint());
    const rotationCenter = bounds.size.multiply(0.5);
    matrix.rotate(
      angle,
      new Point(rotationCenter.width, rotationCenter.height)
    );
    return matrix;
  }
}

function createRectVisual(
  layout: Rect,
  fill: string,
  stroke: string,
  strokeWidth: number,
  cornerRadius?: number,
  classList?: string[]
): SvgVisual {
  const element = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'rect'
  );
  if (classList && classList.length) {
    element.classList.add(...classList);
  }
  return updateRectVisual(
    new SvgVisual(element),
    layout,
    fill,
    stroke,
    strokeWidth,
    cornerRadius
  );
}

function updateRectVisual(
  visual: SvgVisual,
  layout: Rect,
  fill: string,
  stroke: string,
  strokeWidth: number,
  cornerRadius?: number
): SvgVisual {
  visual.svgElement.setAttribute('width', `${layout.width}`);
  visual.svgElement.setAttribute('height', `${layout.height}`);
  visual.svgElement.setAttribute('fill', fill ?? '');
  visual.svgElement.setAttribute('stroke', stroke ?? '');
  visual.svgElement.setAttribute('stroke-width', (strokeWidth ?? 0).toString());
  visual.svgElement.setAttribute('rx', `${cornerRadius ?? '0'}`);

  return visual;
}
