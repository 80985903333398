import {
  CanvasComponent,
  GraphComponent,
  GraphModelManager,
  HierarchicNestingPolicy,
  ICanvasObjectGroup,
  IEdge,
  IEnumerable,
  IModelItem,
  INode,
  LabelLayerPolicy
} from 'yfiles';
import NodeLabelDescriptor from './descriptors/NodeLabelDescriptor';

export default class JigsawGraphModelManager extends GraphModelManager {
  constructor(
    private graphComponent: GraphComponent,
    contentGroup: ICanvasObjectGroup = graphComponent.contentGroup,
    labelLayerPolicy: LabelLayerPolicy = LabelLayerPolicy.AT_OWNER
  ) {
    super(graphComponent, contentGroup);

    //https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#tab_effect-LabelLayerPolicy
    this.labelLayerPolicy = labelLayerPolicy;

    // https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#customizing_view-z_order_canvasobject
    this.hierarchicNestingPolicy = HierarchicNestingPolicy.NONE;
    this.edgeLabelGroup.below(this.nodeGroup);
    this.nodeLabelGroup.toFront();
    this.nodeLabelDescriptor = new NodeLabelDescriptor();
  }
  protected createEdgeGroup(): ICanvasObjectGroup {
    return this.nodeGroup;
  }

  public override toFront(args: IModelItem): void;
  public override toFront(args: IEnumerable<IModelItem>): void;
  public override toFront(args: IEnumerable<IModelItem> | IModelItem): void {
    if (args instanceof IModelItem) {
      const item = args;
      super.toFront(args);
      if (INode.isInstance(item) || IEdge.isInstance(item)) {
        this.setDisplayOrder(item, this.getMaxDisplayOrder() + 1);
      }
    } else {
      const items = args as IEnumerable<IModelItem>;
      super.toFront(items);

      let maxDisplayOrder = this.getMaxDisplayOrder();
      items
        .filter((i) => INode.isInstance(i) || IEdge.isInstance(i))
        .orderBy((i: INode | IEdge) => i.tag.displayOrder)
        .forEach((i: INode | IEdge) =>
          this.setDisplayOrder(i, ++maxDisplayOrder)
        );
    }
  }

  public override toBack(args: IModelItem): void;
  public override toBack(args: IEnumerable<IModelItem>): void;
  public override toBack(args: IEnumerable<IModelItem> | IModelItem): void {
    if (args instanceof IModelItem) {
      const item = args;
      super.toFront(args);
      if (INode.isInstance(item) || IEdge.isInstance(item)) {
        this.setDisplayOrder(item, this.getMinDisplayOrder() + 1);
      }
    } else {
      const items = args as IEnumerable<IModelItem>;
      super.toBack(items);

      let minDisplayOrder = this.getMinDisplayOrder();
      items
        .filter((i) => INode.isInstance(i) || IEdge.isInstance(i))
        .orderBy((i: INode | IEdge) => i.tag.displayOrder)
        .forEach((i: INode | IEdge) =>
          this.setDisplayOrder(i, --minDisplayOrder)
        );
    }
  }

  // Implement if we need it
  // Should be implemented in same way as toFront/toBack to set displayOrder value
  public override raise(args: IModelItem): void;
  public override raise(args: IEnumerable<IModelItem>): void;
  public override raise(args: IEnumerable<IModelItem> | IModelItem): void {
    throw 'Method is not implemented';
  }

  // Implement if we need it
  // Should be implemented in same way as toFront/toBack to set displayOrder value
  public override lower(args: IModelItem): void;
  public override lower(args: IEnumerable<IModelItem>): void;
  public override lower(args: IEnumerable<IModelItem> | IModelItem): void {
    throw 'Method is not implemented';
  }

  public setNewItemDisplayOrder(item: INode | IEdge): void {
    const displayOrder = this.getMaxDisplayOrder() + 1;
    this.setDisplayOrder(item, displayOrder);
  }

  public forceDisplayOrderEvaluation(): void {
    [...this.graphComponent.graph.nodes, ...this.graphComponent.graph.edges]
      .sort((a, b) => {
        if (a.tag.displayOrder < b.tag.displayOrder) {
          return -1;
        }

        if (a.tag.displayOrder > b.tag.displayOrder) {
          return 1;
        }
        return 0;
      })
      .forEach((modelItem) => {
        this.graphComponent.graphModelManager.toBack(modelItem);
      });
  }

  private getMaxDisplayOrder(): number {
    const nodeOrders = this.graph.nodes.map((n) => n.tag.displayOrder);
    const edgeOrders = this.graph.edges.map((n) => n.tag.displayOrder);
    return Math.max(...nodeOrders, ...edgeOrders) ?? 0;
  }

  private getMinDisplayOrder(): number {
    const nodeOrders = this.graph.nodes.map((n) => n.tag.displayOrder);
    const edgeOrders = this.graph.edges.map((n) => n.tag.displayOrder);
    return Math.min(...nodeOrders, ...edgeOrders) ?? 0;
  }

  private setDisplayOrder(item: INode | IEdge, value: number): void {
    item.tag.displayOrder = value;
  }

  uninstall(canvas: CanvasComponent): void {
    const edgeGroupKey = '$f2';
    this[edgeGroupKey] = canvas.rootGroup.addGroup();
    super.uninstall(canvas);
  }
}
