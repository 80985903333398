import IDisposable from '@/core/common/IDisposable';
import Vue from 'vue';
import InputModeContext from '../InputModes/InputModeContext';
import IItemChangedEventArgs from './IItemChangedEventArgs';
import IItemCreatedEventArgs from './IItemCreatedEventArgs';
import IItemSelectedEventArgs from './IItemSelectedEventArgs';
import ILayoutChangeEventArgs from './ILayoutChangeEventArgs';
import ILayoutEditorEventArgs from './ILayoutEditorEventArgs';

export const LayoutChangeEvent = 'layoutChanged';
export const TextChangedEvent = 'textChanged';
export const DateChangedEvent = 'dateChanged';
export const ChangedEvent = 'changed';
export const ItemDeleted = 'itemDeleted';
export const ItemCreated = 'itemCreated';
export const ItemSelected = 'itemSelected';
export const ItemUpdated = 'itemUpdated';
export const ItemCreationStarted = 'itemCreationStarted';
export const EditStarted = 'editStarted';
export const EditEnded = 'editEnded';
export const EditCanceled = 'editCanceled';
export const LayoutChangingEvent = 'layoutChanging';
export const ItemsVisibilityChanged = 'itemsVisibilityChanged';
export const ContextActivated = 'ContextActivated';
export const ContextDeactivated = 'ContextDeactivated';
export const ContextCreated = 'ContextCreated';

export default class EventManager implements IDisposable {
  private eventBus = new Vue();
  private context: InputModeContext;

  constructor(context: InputModeContext) {
    this.context = context;
  }

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    this.eventBus.$destroy();
    this.isDisposed = true;
  }

  public emit(eventType: string, payload: ILayoutEditorEventArgs): void {
    payload.context = this.context;
    payload.eventType = eventType;
    this.eventBus.$emit(eventType, payload);
    this.eventBus.$emit(ChangedEvent, payload);
  }

  public addEventListener(
    eventType: string | string[],
    callback: Function
  ): void {
    this.eventBus.$on(eventType, callback);
  }

  public removeEventListener(
    eventType: string | string[],
    callback: Function
  ): void {
    this.eventBus.$off(eventType, callback);
  }

  public onLayoutChanged(payload: ILayoutChangeEventArgs): void {
    this.emit(LayoutChangeEvent, payload);
  }

  public onLayoutChanging(payload: ILayoutChangeEventArgs): void {
    this.emit(LayoutChangingEvent, payload);
  }

  public addLayoutChangedListener(
    callback: (args: ILayoutChangeEventArgs) => void
  ): void {
    this.addEventListener(LayoutChangeEvent, callback);
  }

  public removeLayoutChangedListener(
    callback: (args: ILayoutChangeEventArgs) => void
  ): void {
    this.removeEventListener(LayoutChangeEvent, callback);
  }

  public addLayoutChangingListener(
    callback: (args: ILayoutChangeEventArgs) => void
  ): void {
    this.addEventListener(LayoutChangingEvent, callback);
  }

  public removeLayoutChangingListener(
    callback: (args: ILayoutChangeEventArgs) => void
  ): void {
    this.removeEventListener(LayoutChangingEvent, callback);
  }

  public onTextChanged(payload: IItemChangedEventArgs): void {
    this.emit(TextChangedEvent, payload);
  }

  public addTextChangedListener(callback: () => void): void {
    this.addEventListener(TextChangedEvent, callback);
  }

  public removeTextChangedListener(callback: () => void): void {
    this.removeEventListener(TextChangedEvent, callback);
  }

  public onDateChanged(payload: IItemChangedEventArgs): void {
    this.emit(DateChangedEvent, payload);
  }

  public addDateChangedListener(callback: () => void): void {
    this.addEventListener(DateChangedEvent, callback);
  }

  public removeDateChangedListener(callback: () => void): void {
    this.removeEventListener(DateChangedEvent, callback);
  }

  public addChangedListener(callback: (args: any) => void): void {
    this.addEventListener(ChangedEvent, callback);
  }

  public removeChangedListener(callback: () => void): void {
    this.removeEventListener(ChangedEvent, callback);
  }

  public removeItemSelectedListener(callback: () => void): void {
    this.removeEventListener(ItemSelected, callback);
  }

  public addItemSelectedListener(
    callback: (args: IItemSelectedEventArgs) => void
  ): void {
    this.addEventListener(ItemSelected, callback);
  }

  public onItemSelected(payload: IItemSelectedEventArgs): void {
    this.emit(ItemSelected, payload);
  }

  public removeItemDeletedListener(callback: () => void): void {
    this.removeEventListener(ItemDeleted, callback);
  }

  public addItemDeletedListener(callback: () => void): void {
    this.addEventListener(ItemDeleted, callback);
  }

  public onItemDeleted(payload: IItemChangedEventArgs): void {
    this.emit(ItemDeleted, payload);
  }

  public removeEditStartedListener(callback: (args) => void): void {
    this.removeEventListener(EditStarted, callback);
  }

  public addEditStartedListener(callback: (args) => void): void {
    this.addEventListener(EditStarted, callback);
  }

  public onEditStarted(payload: IItemChangedEventArgs): void {
    this.emit(EditStarted, payload);
  }

  public removeEditEndedListener(callback: (args) => void): void {
    this.removeEventListener(EditEnded, callback);
  }

  public addEditEndedListener(callback: (args) => void): void {
    this.addEventListener(EditEnded, callback);
  }

  public onEditEnded(payload: IItemChangedEventArgs): void {
    this.emit(EditEnded, payload);
  }

  public removeEditCanceledListener(callback: (args) => void): void {
    this.removeEventListener(EditCanceled, callback);
  }

  public addEditCanceledListener(callback: (args) => void): void {
    this.addEventListener(EditCanceled, callback);
  }

  public onEditCanceled(payload: IItemChangedEventArgs): void {
    this.emit(EditCanceled, payload);
  }

  public onStyleChanged(payload: IItemChangedEventArgs): void {
    this.emit(ChangedEvent, payload);
  }

  public addItemCreatedListener(
    callback: (args: IItemCreatedEventArgs) => void
  ): void {
    this.addEventListener(ItemCreated, callback);
  }

  public removeItemCreatedListener(
    callback: (args: IItemCreatedEventArgs) => void
  ): void {
    this.removeEventListener(ItemCreated, callback);
  }

  public onItemCreated(payload: IItemCreatedEventArgs): void {
    this.emit(ItemCreated, payload);
  }

  public addItemCreationStartedListener(callback: () => void): void {
    this.addEventListener(ItemCreationStarted, callback);
  }

  public removeItemCreationStartedListener(callback: () => void): void {
    this.removeEventListener(ItemCreationStarted, callback);
  }

  public onItemCreationStarted(payload: ILayoutEditorEventArgs): void {
    this.emit(ItemCreationStarted, payload);
  }
}
