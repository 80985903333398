import { INode } from 'yfiles';
import OwnershipParameter from '../parameters/OwnershipParameter';
import { ITypedMatcher } from './IMatcher';

export default class OwnershipMatcher
  implements ITypedMatcher<INode, OwnershipParameter>
{
  public canMatch(candidate: any, parameter: OwnershipParameter): boolean {
    return candidate instanceof INode;
  }

  public isMatch(candidate: INode, parameter: OwnershipParameter): boolean {
    // Ownership filters behave like metadata and should not affect the search results
    return true;
  }
}
